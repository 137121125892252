import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import Button from '../Button';

const HingeDetailsModal = ({
  isOpen,
  onCloseModal,
  className,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
    >
      <h2 className="headings-h2">{t('hingeDetailsModal.title')}</h2>

      <div className="choice-hinge-scroll">
        <div className="content-wrapper">
          <div className="details-item">
            <div className="details-title">
              <p>{t('hingeDetailsModal.scheme-1')}</p>
            </div>
            <img src="/src/client/assets/images/holes/scheme1.png" alt="scheme1" className="details-img" />
          </div>

          <div className="details-item">
            <div className="details-title">
              <p className="details-text">{t('hingeDetailsModal.scheme-2')}</p>
            </div>
            <img src="/src/client/assets/images/holes/scheme2.png" alt="scheme2" className="details-img" />
          </div>

          <div className="details-item">
            <div className="details-title">
              <p className="details-text">
                {t('hingeDetailsModal.scheme-3')}
                <span className="details-hint">{t('hingeDetailsModal.hint')}</span>
              </p>
              <div className="details-rb">{t('hingeDetailsModal.rb')}</div>
            </div>
            <img src="/src/client/assets/images/holes/scheme3.png" alt="scheme3" className="details-img" />
            <div className="details-rb mobile">{t('hingeDetailsModal.rb')}</div>
          </div>

          <div className="details-item">
            <div className="details-title">
              <p className="details-text">
                {t('hingeDetailsModal.scheme-4')}
                <span className="details-hint">{t('hingeDetailsModal.hint')}</span>
              </p>
              <div className="details-rb">{t('hingeDetailsModal.rb')}</div>
            </div>
            <img src="/src/client/assets/images/holes/scheme4.png" alt="scheme4" className="details-img" />
            <div className="details-rb mobile">{t('hingeDetailsModal.rb')}</div>
          </div>

          <div className="details-item">
            <div className="details-title">
              <p className="details-text">
                {t('hingeDetailsModal.scheme-5')}
                <span className="details-hint">{t('hingeDetailsModal.hint')}</span>
              </p>
              <div className="details-rb">{t('hingeDetailsModal.rb')}</div>
            </div>
            <img src="/src/client/assets/images/holes/scheme5.png" alt="scheme5" className="details-img" />
            <div className="details-rb mobile">{t('hingeDetailsModal.rb')}</div>
          </div>

          <div className="details-item">
            <div className="details-title">
              <p className="details-text">
                {t('hingeDetailsModal.scheme-6')}
                <span className="details-hint">{t('hingeDetailsModal.hint')}</span>
              </p>
              <div className="details-rb">{t('hingeDetailsModal.rb')}</div>
            </div>
            <img src="/src/client/assets/images/holes/scheme6.png" alt="scheme6" className="details-img" />
            <div className="details-rb mobile">{t('hingeDetailsModal.rb')}</div>
          </div>

          <div className="details-item">
            <div className="details-title">
              <p className="details-text">
                {t('hingeDetailsModal.scheme-7')}
                <span className="details-hint">{t('hingeDetailsModal.hint')}</span>
              </p>
              <div className="details-rb">{t('hingeDetailsModal.rb')}</div>
            </div>
            <img src="/src/client/assets/images/holes/scheme7.png" alt="scheme7" className="details-img" />
            <div className="details-rb mobile">{t('hingeDetailsModal.rb')}</div>
          </div>
        </div>
      </div>

      <div className="action-buttons">
        <div className="action-buttons-inner">
          <Button
            value={t('hingeDetailsModal.back')}
            type="rounded"
            onClick={onCloseModal}
            isDisabled={false}
          />
        </div>
      </div>
    </Modal>
  );
};

HingeDetailsModal.defaultProps = {
  isOpen: false,
  className: 'action-modal hinge-details',
};

HingeDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
};

export default HingeDetailsModal;
