import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setPearHoles: ['items'],
  setAmount: ['amount'],
  setItems: ['items'],
  setPlacementSide: ['side'],

  resetPearHoles: [null],

  toggleDrillingPearHoles: ['isOpen'],
});

export const DrillingPearHolesTypes = Types;
export default Creators;
