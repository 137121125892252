import React, { useEffect } from 'react';
import { useTitle } from 'hookrouter';
import { useDispatch } from 'react-redux';

import Main from './layout/Main';
import Menu from './layout/Menu';
import Header from './layout/Header';
import LandingText from './layout/LandingText';
import AuthService from '../services/authService';
import ProfileActions from '../redux/actions/profile';


const Landing = () => {
  const dispatch = useDispatch();

  useTitle('ADS');

  useEffect(() => {
    if (!AuthService.isLoggedIn()) return;
    dispatch(ProfileActions.getUserProfileRequest());
  }, []);

  return (
    <Main
      className="landing"
      hasHeader={false}
      hasFooter={false}
    >
      <Header
        canGoBack={false}
        burgerMenuColor="white"
      />
      <Menu />

      <LandingText />
    </Main>
  );
};

export default Landing;
