import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';

import { ConfigTypes } from '../actions/config';

const INITIAL_STATE = {
  isLoading: false,
  errorMessage: null,
  aluminiumColors: [],
  systemConctants: [],
  connectingProfiles: [],
  doorLatchMechanisms: [],
  sideProfiles: [],
  frameProfiles: [],
  mechanisms: [],
  filling: [],
  fillingFeatures: [],
  brushes: [],
  isConfigFetched: false,
  assemblyPrices: {
    vertical: 0,
    horizontal: 0,
    perDoorRetail: 0,
  },
  isOpenManageAluminiumColorsModal: false,
};

/** Connecting Profiles */

const getConnectingProfilesRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
});

const getConnectingProfilesSuccess = (state = INITIAL_STATE, { connectingProfiles }) => update(state, {
  isLoading: { $set: false },
  connectingProfiles: { $set: connectingProfiles || [] },
});

const getConnectingProfilesFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});

/** Side Profiles */

const getSideProfilesRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
});

const getSideProfilesSuccess = (state = INITIAL_STATE, { sideProfiles }) => update(state, {
  isLoading: { $set: false },
  sideProfiles: { $set: sideProfiles || [] },
});

const getSideProfilesFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});

/** All Config Data */

const getConfigRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  isConfigFetched: { $set: false },
});

const getConfigSuccess = (state = INITIAL_STATE, { data }) => update(state, {
  isLoading: { $set: false },
  aluminiumColors: { $set: data?.aluminiumColors || [] },
  systemConctants: { $set: data?.systemConctants || [] },
  connectingProfiles: { $set: data?.connectingProfiles || [] },
  doorLatchMechanisms: { $set: data?.doorLatchMechanisms || [] },
  sideProfiles: { $set: data?.sideProfiles || [] },
  frameProfiles: { $set: data?.frameProfiles || [] },
  mechanisms: { $set: data?.mechanisms || [] },
  filling: { $set: data?.filling || [] },
  fillingFeatures: { $set: data?.fillingFeatures || [] },
  brushes: { $set: data?.brushes || [] },
  isConfigFetched: { $set: true },
  assemblyPrices: { $set: data.assemblyPrices },
});

const getConfigFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
  isConfigFetched: { $set: false },
});

/** AluminiumColors  */

const getAluminiumColorsRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
});

const getAluminiumColorsSuccess = (state = INITIAL_STATE, { colors }) => update(state, {
  isLoading: { $set: false },
  aluminiumColors: { $set: colors || [] },
});

const getAluminiumColorsFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});

/** Create AluminiumColors  */

const createAluminiumColorRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
});

const createAluminiumColorSuccess = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: false },
});

const createAluminiumColorFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});

/** Edit AluminiumColors  */

const editAluminiumColorRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
});

const editAluminiumColorSuccess = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: false },
});

const editAluminiumColorFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});

/** Toggle Modal  */

const toggleManageAluminiumColorsModal = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isOpenManageAluminiumColorsModal: { $set: isOpen },
});

/**
 * Reducers
 */

export default createReducer(INITIAL_STATE, {

  /** Connecting Profiles */

  [ConfigTypes.GET_CONNECTING_PROFILES_REQUEST]: getConnectingProfilesRequest,
  [ConfigTypes.GET_CONNECTING_PROFILES_SUCCESS]: getConnectingProfilesSuccess,
  [ConfigTypes.GET_CONNECTING_PROFILES_FAILURE]: getConnectingProfilesFailure,

  /** Side Profiles */

  [ConfigTypes.GET_SIDE_PROFILES_REQUEST]: getSideProfilesRequest,
  [ConfigTypes.GET_SIDE_PROFILES_SUCCESS]: getSideProfilesSuccess,
  [ConfigTypes.GET_SIDE_PROFILES_FAILURE]: getSideProfilesFailure,

  /** All Config Data */

  [ConfigTypes.GET_CONFIG_REQUEST]: getConfigRequest,
  [ConfigTypes.GET_CONFIG_SUCCESS]: getConfigSuccess,
  [ConfigTypes.GET_CONFIG_FAILURE]: getConfigFailure,

  /** AluminiumColors  */

  [ConfigTypes.GET_ALUMINIUM_COLORS_REQUEST]: getAluminiumColorsRequest,
  [ConfigTypes.GET_ALUMINIUM_COLORS_SUCCESS]: getAluminiumColorsSuccess,
  [ConfigTypes.GET_ALUMINIUM_COLORS_FAILURE]: getAluminiumColorsFailure,

  /** Create AluminiumColors  */

  [ConfigTypes.CREATE_ALUMINIUM_COLOR_REQUEST]: createAluminiumColorRequest,
  [ConfigTypes.CREATE_ALUMINIUM_COLOR_SUCCESS]: createAluminiumColorSuccess,
  [ConfigTypes.CREATE_ALUMINIUM_COLOR_FAILURE]: createAluminiumColorFailure,

  /** Edit AluminiumColors  */

  [ConfigTypes.EDIT_ALUMINIUM_COLOR_REQUEST]: editAluminiumColorSuccess,
  [ConfigTypes.EDIT_ALUMINIUM_COLOR_SUCCESS]: editAluminiumColorFailure,
  [ConfigTypes.EDIT_ALUMINIUM_COLOR_FAILURE]: editAluminiumColorRequest,

  /** Toggle Modal  */

  [ConfigTypes.TOGGLE_MANAGE_ALUMINIUM_COLORS_MODAL]: toggleManageAluminiumColorsModal,
});

