import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import Modal from '../Modal';
import Button from '../Button';

const WarningInvalidCoordsModal = ({
  isOpen,
  className,
  invalidCoords,
  onCloseModal,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
    >
      <div className="modal-order-icon-wrapper">
        <ReactSVG
          wrapper="div"
          className="modal-order-icon"
          src="/src/client/assets/icons/price-package-icon.svg"
        />
      </div>
      <h2 className="headings-h2">{t('warningInvalidCoordsModal.title')}</h2>
      <div className="subtitle">{t('warningInvalidCoordsModal.subtitle')}</div>

      <div className="content">
        {invalidCoords?.length > 0 && invalidCoords.map((item) => (
          <div className="group-wrapper">
            <div className="group-name">
              &mdash;&nbsp;&nbsp;
              {t('warningInvalidCoordsModal.group')}
              <span>
                &quot;
                {item.groupName}
                &quot;
              </span>
              &#58;
            </div>

            <div className="holes-list">
              {item?.fasteningElementInvalidCoords?.length > 0 && (
                <div className="holes-item">
                  &bull;&nbsp;
                  {item.fasteningElementInvalidCoords.length > 1
                    ? t('warningInvalidCoordsModal.fastening-element-holes', { holesNumber: item.fasteningElementInvalidCoords.join(', ') })
                    : t('warningInvalidCoordsModal.fastening-element-hole', { holesNumber: item.fasteningElementInvalidCoords.join(', ') })}
                </div>
              )}
              {item?.holesForHandlesInvalidCoords?.length > 0 && (
                <div className="holes-item">
                  &bull;&nbsp;
                  {item.holesForHandlesInvalidCoords.length > 1
                    ? t('warningInvalidCoordsModal.holes-for-handles', { holesNumber: item.holesForHandlesInvalidCoords.join(', ') })
                    : t('warningInvalidCoordsModal.hole-for-handles', { holesNumber: item.holesForHandlesInvalidCoords.join(', ') })}
                </div>
              )}
              {item?.pearHolesInvalidCoords?.length > 0 && (
                <div className="holes-item">
                  &bull;&nbsp;
                  {item.pearHolesInvalidCoords.length > 1
                    ? t('warningInvalidCoordsModal.pear-holes', { holesNumber: item.pearHolesInvalidCoords.join(', ') })
                    : t('warningInvalidCoordsModal.pear-hole', { holesNumber: item.pearHolesInvalidCoords.join(', ') })}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="action-buttons">
        <div className="action-buttons-inner">
          <Button
            value={t('warningInvalidCoordsModal.return')}
            type="rounded"
            onClick={onCloseModal}
            isDisabled={false}
          />
        </div>
      </div>
    </Modal>
  );
};

WarningInvalidCoordsModal.defaultProps = {
  isOpen: false,
  className: 'action-modal warning-coords',
};

WarningInvalidCoordsModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  invalidCoords: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default WarningInvalidCoordsModal;
