import _ from 'lodash';
import React, { useState, useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'hookrouter';
import { useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import Collapsible from 'react-collapsible';
import { ReactSVG } from 'react-svg';
import moment from 'moment';

import notificationPropTypes from '../../helpers/propTypes/notificationPropTypes';
import {
  sampleCustomers,
  sampleItemsJson,
  sampleDeliveryJson,
  sampleStatuses,
  sampleSystemConstants,
  sampleSetOrdersEschanged,
  sampleSetOrdersStatusExchanged,
  sampleExchangeRatesJson,
  sampleDiscounts,
  sampleHingesJson,
  sampleSideProfilesBySystemType,
  sampleSetGluingFillingAndPolishingForFrameProfiles,
  sampleManagers,
} from '../../helpers/sampleJSONUploads';

import Button from '../../components/Button';
import Label from '../../components/Label';

import AdminActions from '../../redux/actions/admin';
import ConfigActions from '../../redux/actions/config';

import Main from '../layout/Main';
import API from '../../api';
import AuthService from '../../services/authService';
import Tabs from '../../components/Tabs';
import ManageAluminiumColorsModal from '../../components/ManageAluminiumColorsModal';


const FileUploadForm = ({ toastManager }) => {
  const { t } = useTranslation(['components']);
  const dispatch = useDispatch();
  const startDate = new Date('01/01/21');
  const currentDate = new Date();

  const FILE_MAX_SIZE = 20000000;
  const SITE_URL = process.env.SITE_URL || 'http://ads-calc-stage.herokuapp.com';
  const PORT = process.env.PORT || 80;
  const accessToken = AuthService.getAdminAccessToken();
  /* eslint-disable */
  const curlNewCustomers = `curl -X GET -G ${SITE_URL}:${PORT}/api/json/new-customers -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}' -d 'datestart=2023-01-12T00:00:00' -d  'dateend=2023-01-13T23:59:59'`
  const curlCustomers = `curl -X PUT ${SITE_URL}:${PORT}/api/json/customers -H 'Content-Type: application/json; charset=utf-8' -H 'Accept: application/json' -H 'Authorization: Bearer ${accessToken}' --data-binary '@/${t('jsonForm.file-path')}'`;
  const curlManagers = `curl -X PATCH ${SITE_URL}:${PORT}/api/json/managers -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlItemsJson = `curl -X PUT ${SITE_URL}:${PORT}/api/json/items-list -H 'Content-Type: application/json; charset=utf-8' -H 'Accept: application/json' -H 'Authorization: Bearer ${accessToken}' --data-binary '@/${t('jsonForm.file-path')}'`;
  const curlDeliveryJson = `curl -X PUT ${SITE_URL}:${PORT}/api/json/delivery -H 'Content-Type: application/json; charset=utf-8' -H 'Accept: application/json' -H 'Authorization: Bearer ${accessToken}' --data-binary '@/${t('jsonForm.file-path')}'`;
  const curlExchangeRatesJson = `curl -X PUT ${SITE_URL}:${PORT}/api/json/exchange-rates -H 'Content-Type: application/json; charset=utf-8' -H 'Accept: application/json' -H 'Authorization: Bearer ${accessToken}' -d '{ "USD": 29.26, "EUR": 33.2, "PLN": 6.8 }'`;

  const curlDiscountsJson = `curl -X PUT ${SITE_URL}:${PORT}/api/json/discounts -H 'Content-Type: application/json; charset=utf-8' -H 'Accept: application/json' -H 'Authorization: Bearer ${accessToken}' --data-binary '@/${t('jsonForm.file-path')}'`;

  const curlOrdersLviv = `curl -X GET ${SITE_URL}:${PORT}/api/orders/1c/in-processing/lviv -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlOrdersKyiv = `curl -X GET ${SITE_URL}:${PORT}/api/orders/1c/in-processing/kyiv -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlOrdersKharkiv = `curl -X GET ${SITE_URL}:${PORT}/api/orders/1c/in-processing/kharkiv -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlOrdersOdesa = `curl -X GET ${SITE_URL}:${PORT}/api/orders/1c/in-processing/odesa -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlOrdersPoland = `curl -X GET ${SITE_URL}:${PORT}/api/orders/1c/in-processing/poland -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlOrdersByStatusAndDate = `curl -X GET -G ${SITE_URL}:${PORT}/api/orders/1c/new -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}' -d 'datestart=2023-01-12T00:00:00' -d  'dateend=2023-01-13T23:59:59'`

  const curlStatusesAPI = `curl -X PUT ${SITE_URL}:${PORT}/api/orders/1c/statuses -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlOrdersRawAPI = `curl -X GET ${SITE_URL}:${PORT}/api/orders/1c/raw/lviv -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlOrderByNumber = `curl -X GET ${SITE_URL}:${PORT}/api/orders/1c/by-number/1740 -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlOrdersSetExchangedAPI = `curl -X PATCH ${SITE_URL}:${PORT}/api/orders/1c/set-exchanged -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlOrdersStatusExchangedAPI = `curl -X PATCH ${SITE_URL}:${PORT}/api/orders/1c/set-status -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlHingesExchangedAPI = `curl -X PATCH ${SITE_URL}:${PORT}/api/json/hinges -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlSystemConstantsAPI = `curl -X PUT ${SITE_URL}:${PORT}/api/system-constants/1c -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlAddSideProfilesBySystemTypeAPI = `curl -X PATCH ${SITE_URL}:${PORT}/api/config/side-profiles-by-system-type/add -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlDeleteSideProfilesBySystemTypeAPI = `curl -X PATCH ${SITE_URL}:${PORT}/api/config/side-profiles-by-system-type/delete -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  const curlSetGluingPolishingForFrameProfilesAPI = `curl -X PATCH ${SITE_URL}:${PORT}/api/config/frame-profiles-set-gluing-polishing -H 'Content-Type: application/json; charset=utf-8' -H 'Authorization: Bearer ${accessToken}'`;
  /* eslint-enable */

  const langTabs = [
    { title: 'Укр', id: 'labelUk' },
    { title: 'Рос', id: 'labelRu' },
    { title: 'Англ', id: 'labelEn' },
    { title: 'Пол', id: 'labelPl' },
  ];

  const uploadItemsAndPricesRef = React.createRef();
  const uploadCustomersRef = React.createRef();
  const uploadDiscountsRef = useRef();

  const [itemsAndPricesFilePath, setItemsAndPricesFilePath] = useState('');
  const [customersFilePath, setCustomersFilePath] = useState('');
  const [itemsAndPricesFileContent, setItemsAndPricesFileContent] = useState('');
  const [itemsAndPricesFileType, setItemsAndPricesFileType] = useState('');
  const [customersFileContent, setCustomersFileContent] = useState('');
  const [itemsAndPricesErrorMessage, setItemsAndPricesErrorMessage] = useState('');
  const [customersErrorMessage, setCustomersErrorMessage] = useState('');
  const [isItemsAndPricesProcessing, setIsItemsAndPricesProcessing] = useState(false);
  const [isCustomersProcessing, setIsCustomersProcessing] = useState(false);
  const [notificationId, setNotificationId] = useState('');
  const [from, setFrom] = useState(startDate);
  const [to, setTo] = useState(currentDate);
  const [selectedLang, setSelectedLang] = useState('labelUk');
  const [colorForEdit, setColorForEdit] = useState({});

  const [discountsFilePath, setDiscountsFilePath] = useState('');
  const [isDiscountsProcessing, setIsDiscountsProcessing] = useState(false);
  const [discountsErrorMessage, setDiscountsErrorMessage] = useState('');
  const [discountsFileContent, setDiscountsFileContent] = useState('');

  useTitle(t('routes.upload-json'));


  const {
    ordersCountTotal,
    ordersCountNew,
    ordersCountInProcessing,
    usersCountWithNewOrders,
    usersCountWithInProcessingOrders,
  } = useSelector(({ adminPageInfo }) => adminPageInfo);

  const {
    aluminiumColors,
    isOpenManageAluminiumColorsModal,
  } = useSelector(({ config }) => config);

  useEffect(() => {
    dispatch(ConfigActions.getAluminiumColorsRequest());
    dispatch(AdminActions.getAdminInfoRequest(from, to));
  }, []);

  useEffect(() => {
    dispatch(ConfigActions.getAluminiumColorsRequest());
  }, [isOpenManageAluminiumColorsModal]);


  const showToast = (message, appearance) => {
    const errorContent = <div className="toast-notification">{message}</div>;

    if (notificationId) toastManager.remove(notificationId);

    toastManager.add(errorContent, {
      appearance,
      autoDismiss: true,
    }, (id) => { setNotificationId(id); });
  };


  const checkItemsAndPricesFileSize = (size, type) => {
    if (!size) return;
    setItemsAndPricesErrorMessage(size > FILE_MAX_SIZE && type === 'application/json'
      ? t('errorMessages.please-compress-file')
      : '');
  };


  const checkCustomersFileSize = (size) => {
    if (!size) return;
    setCustomersErrorMessage(size > FILE_MAX_SIZE
      ? t('errorMessages.file-is-too-large')
      : '');
  };

  const checkDiscountsFileSize = (size) => {
    if (!size) return;
    setDiscountsErrorMessage(size > FILE_MAX_SIZE
      ? t('errorMessages.file-is-too-large')
      : '');
  };

  const onItemsAndPricesChange = async ({ target: { files } }) => {
    await setItemsAndPricesFilePath(files[0]?.name);
    await checkItemsAndPricesFileSize(files[0]?.size, files[0]?.type);
    await setItemsAndPricesFileType(files[0]?.type);

    if (itemsAndPricesErrorMessage) return;
    const isZip = files[0]?.type === 'application/zip';

    const reader = new FileReader();
    reader.onabort = () => console.log('File reading was aborted!');
    reader.onerror = () => console.log('File reading has failed!');
    reader.onload = () => setItemsAndPricesFileContent(isZip
      ? reader.result.replace('data:', '').replace(/^.+,/, '')
      : reader.result);

    if (isZip) {
      reader.readAsDataURL(files[0]);
      return;
    }

    reader.readAsText(files[0]);
  };


  const onCustomersChange = async ({ target: { files } }) => {
    await setCustomersFilePath(files[0]?.name);
    await checkCustomersFileSize(files[0]?.size);

    if (customersErrorMessage) return;

    const reader = new FileReader();
    reader.onabort = () => console.log('File reading was aborted!');
    reader.onerror = () => console.log('File reading has failed!');
    reader.onload = () => setCustomersFileContent(reader.result);
    reader.readAsText(files[0]);
  };

  const onDiscountsChange = async ({ target: { files } }) => {
    await setDiscountsFilePath(files[0]?.name);
    await checkDiscountsFileSize(files[0]?.size);

    if (discountsErrorMessage) return;

    const reader = new FileReader();
    reader.onabort = () => console.log('File reading was aborted!');
    reader.onerror = () => console.log('File reading has failed!');
    reader.onload = () => setDiscountsFileContent(reader.result);
    reader.readAsText(files[0]);
  };


  const resetItemsAndPricesFile = () => {
    setIsItemsAndPricesProcessing(false);
    setItemsAndPricesFilePath('');
    setItemsAndPricesFileType('');
    setItemsAndPricesFileContent('');
  };

  const resetDateFilter = () => {
    setFrom(startDate);
    setTo(currentDate);

    dispatch(AdminActions.getAdminInfoRequest(startDate, currentDate));
  };

  const onItemsAndPricesSubmit = async () => {
    setIsItemsAndPricesProcessing(true);

    const isZip = itemsAndPricesFileType === 'application/zip';
    const response = isZip
      ? await API.json.apply.itemsListAndPricesZIP(itemsAndPricesFileContent)
      : await API.json.apply.itemsListAndPricesJSON(itemsAndPricesFileContent);
    resetItemsAndPricesFile();

    if (_.isEmpty(response?.data)) {
      const msg = t('errorMessages.failed-uploading-file');
      setItemsAndPricesErrorMessage(msg);
      showToast(msg, 'error');
      return;
    }

    if (!response.ok) {
      setItemsAndPricesErrorMessage(t('errorMessages.failed-uploading-file'));
      const msg = response.data?.error?.message;
      showToast(msg ? t(`errorMessagesHelper.${msg}`) : t('errorMessages.something-went-wrong'), 'error');
      return;
    }
    showToast(t('successMessages.file-uploaded-successfully'), 'success');
    console.info('Uploaded:', response.data?.data);
  };


  const onCustomersSubmit = async () => {
    setIsCustomersProcessing(true);
    const response = await API.json.apply.customersJSON(customersFileContent);
    setCustomersFilePath('');
    setIsCustomersProcessing(false);

    if (!response.ok) {
      setCustomersErrorMessage(t('errorMessages.failed-uploading-file'));
      const msg = response.data?.error?.message;
      showToast(msg ? t(`errorMessagesHelper.${msg}`) : t('errorMessages.something-went-wrong'), 'error');
      return;
    }

    const {
      totalCustomersInFile,
      sanitizedCustomers,
      uniqPhones,
      upsertedUsers,
    } = response.data.data;
    const usertedCustomers = upsertedUsers
      ? `${t('jsonForm.uploaded')}: ${upsertedUsers}.`
      : '';

    showToast(`
      ${usertedCustomers}
      ${t('jsonForm.invalid-phone-numbers')}: ${totalCustomersInFile - sanitizedCustomers}.
      ${t('jsonForm.nonunique-phone-numbers')}: ${sanitizedCustomers - uniqPhones}.
    `, 'info');
    console.info('Uploaded file info:', response.data?.data);
  };

  const onDiscountsSubmit = async () => {
    setIsDiscountsProcessing(true);
    const response = await API.json.apply.discountsJSON(discountsFileContent);
    setDiscountsFilePath('');
    setDiscountsFileContent('');
    setIsDiscountsProcessing(false);
    uploadDiscountsRef.current.value = '';

    if (!response.ok) {
      setDiscountsErrorMessage(t('errorMessages.failed-uploading-file'));
      const msg = response.data?.error?.message;
      showToast(msg ? t(`errorMessagesHelper.${msg}`) : t('errorMessages.something-went-wrong'), 'error');
      return;
    }

    showToast(t('successMessages.file-uploaded-successfully'), 'success');
    console.info('Uploaded file info:', response.data?.data);
  };

  const normalizeDate = (date) => moment(date).format('YYYY-MM-DD');

  const trigger = (
    <div className="json-trigger-wrapper">
      <img
        className="trigger-image"
        src="src/client/assets/icons/json.svg"
        alt="json"
      />
      <p className="trigger-text">{t('jsonForm.example')}</p>
      <ReactSVG
        className="trigger-icon"
        wrapper="span"
        src="/src/client/assets/icons/angle-down-solid.svg"
      />
    </div>
  );

  const DatePickerInput = forwardRef(({ onClick, value, placeholder }, ref) => (
    <div className="date-picker">
      <input
        readOnly
        ref={ref}
        type="text"
        value={value}
        onClick={onClick}
        placeholder={placeholder}
        className="date-picker-input"
      />
      <ReactSVG
        className="date-picker-icon"
        wrapper="span"
        src="/src/client/assets/icons/calendar.svg"
      />
    </div>
  ));

  DatePickerInput.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
  };

  DatePickerInput.defaultProps = {
    onClick: () => { },
    value: '',
    placeholder: '',
  };

  // TODO: remove translations and keep just ukrainian - this is for dev purposes only and should be simple
  return (
    <Main
      className="json-form"
      hasFooter={false}
      canGoBack={false}
    >
      <div className="title-wrapper">
        <div className="title">{t('jsonForm.orders-info')}</div>
      </div>

      <div className="content-wrapper">
        <div className="content-wrapper-inner">
          <div className="filter-wrapper">
            <div className="date-picker">
              <input
                type="date"
                className="date-picker-input"
                max={normalizeDate(to)}
                value={normalizeDate(from)}
                onChange={(e) => setFrom(e.target.value)}
                required
              />
              <ReactSVG
                className="date-picker-icon"
                wrapper="span"
                src="/src/client/assets/icons/calendar.svg"
              />
            </div>
            <div className="date-picker">
              <input
                type="date"
                className="date-picker-input"
                min={normalizeDate(from)}
                max={normalizeDate(currentDate)}
                value={normalizeDate(to)}
                onChange={(e) => setTo(e.target.value)}
                required
              />
              <ReactSVG
                className="date-picker-icon"
                wrapper="span"
                src="/src/client/assets/icons/calendar.svg"
              />
            </div>
          </div>

          <div className="content-wrapper--action-buttons">
            <Button
              value={t('jsonForm.submit')}
              type="rounded"
              onClick={() => {
                dispatch(AdminActions.getAdminInfoRequest(from, to));
              }}
            />

            <Button
              value={t('jsonForm.clear')}
              onClick={resetDateFilter}
            />
          </div>
        </div>

        <div className="content-wrapper-info">
          <div className="info-row">
            {t('jsonForm.orders-count-total')}
            :&nbsp;
            <b>{ordersCountTotal}</b>
          </div>
          <div className="info-row">
            {t('jsonForm.orders-count-new')}
            :&nbsp;
            <b>{ordersCountNew}</b>
          </div>
          <div className="info-row">
            {t('jsonForm.orders-count-in-processing')}
            :&nbsp;
            <b>{ordersCountInProcessing}</b>
          </div>
          <div className="info-row">
            {t('jsonForm.users-count-with-new-orders')}
            :&nbsp;
            <b>{usersCountWithNewOrders}</b>
          </div>
          <div className="info-row">
            {t('jsonForm.users-count-with-in-processing-orders')}
            :&nbsp;
            <b>{usersCountWithInProcessingOrders}</b>
          </div>
        </div>
      </div>
      <br />


      <div className="title-wrapper">
        <div className="title">{t('jsonForm.upload-json-files')}</div>
      </div>

      <div className="content-wrapper">
        <div className="content-wrapper-inner">

          <div><p>{t('jsonForm.instruction-to-upload-json')}</p></div>

          <div>
            <Label
              value={t('jsonForm.file-with-items-list-and-price-list')}
              infoTagValue={t('jsonForm.compress-huge-file')}
              withInfoTag
            />
            <label
              className="label--upload-file"
              htmlFor="json-uploader-items"
            >
              <input
                type="file"
                id="json-uploader-items"
                name="json-uploader"
                accept={['.json', '.zip']}
                ref={uploadItemsAndPricesRef}
                onChange={onItemsAndPricesChange}
                disabled={isItemsAndPricesProcessing}
              />
              <span className="upload-custom">
                {itemsAndPricesFilePath || '.json / .zip файл'}
                <ReactSVG
                  className="upload-custom-icon"
                  wrapper="span"
                  src="/src/client/assets/icons/fillingMaterials/search-glass.svg"
                />
              </span>
            </label>
            <div className="label--error-message">
              <span>{itemsAndPricesErrorMessage}</span>
            </div>
          </div>

          <div className="content-wrapper--action-buttons">
            <Button
              value={t('jsonForm.submit')}
              type="rounded"
              onClick={async (e) => {
                e.preventDefault();
                onItemsAndPricesSubmit();
              }}
              isDisabled={!itemsAndPricesFilePath || !!itemsAndPricesErrorMessage || isItemsAndPricesProcessing}
              isProcessing={isItemsAndPricesProcessing}
            />

            <Button
              value={t('jsonForm.clear')}
              onClick={async (e) => {
                e.preventDefault();
                setItemsAndPricesFilePath('');
              }}
              isDisabled={isItemsAndPricesProcessing || !itemsAndPricesFilePath
                || !!itemsAndPricesErrorMessage || isItemsAndPricesProcessing}
            />
          </div>
          <br />

          <div>
            <p><code>{curlItemsJson}</code></p>
            <Collapsible trigger={trigger}>
              {sampleItemsJson()}
            </Collapsible>
          </div>
          <br />

          <div>
            <Label value={t('jsonForm.file-with-customers')} />
            <label
              className="label--upload-file"
              htmlFor="json-uploader-customers"
            >
              <input
                type="file"
                id="json-uploader-customers"
                name="json-uploader"
                accept=".json"
                ref={uploadCustomersRef}
                onChange={onCustomersChange}
                disabled={isCustomersProcessing}
              />
              <span className="upload-custom">
                {customersFilePath || '.json файл'}
                <ReactSVG
                  className="upload-custom-icon"
                  wrapper="span"
                  src="/src/client/assets/icons/fillingMaterials/search-glass.svg"
                />

              </span>
            </label>
            <div className="label--error-message">
              <span>{customersErrorMessage}</span>
            </div>
          </div>

          <div className="content-wrapper--action-buttons">
            <Button
              value={t('jsonForm.submit')}
              type="rounded"
              onClick={async (e) => {
                e.preventDefault();
                onCustomersSubmit();
              }}
              isDisabled={!customersFilePath || !!customersErrorMessage || isCustomersProcessing}
              isProcessing={isCustomersProcessing}
            />

            <Button
              value={t('jsonForm.clear')}
              onClick={async (e) => {
                e.preventDefault();
                setCustomersFilePath('');
              }}
              isDisabled={isCustomersProcessing || !customersFilePath || !!customersErrorMessage}
            />
          </div>
          <br />

          <div>
            <p><code>{curlCustomers}</code></p>
            <div><p>{t('jsonForm.instruction-to-set-and-remove-dealerId-from-user')}</p></div>
            <Collapsible trigger={trigger}>
              {sampleCustomers()}
            </Collapsible>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">{t('jsonForm.1с-orders-exchange')}</div>
          </div>

          <div>
            <p><code>{curlStatusesAPI}</code></p>
            <Collapsible trigger={trigger}>
              {sampleStatuses()}
            </Collapsible>
          </div>
          <br />

          <div>
            <p>
              {t('jsonForm.instruction-to-load-orders-by-region')}
              <span>:</span>
            </p>
            <p>
              <b>
                Львів
                <span>:</span>
                &nbsp;
              </b>
              <code>{curlOrdersLviv}</code>
            </p>
            <p>
              <b>
                Київ
                <span>:</span>
                &nbsp;
              </b>
              <code>{curlOrdersKyiv}</code>
            </p>
            <p>
              <b>
                Харків
                <span>:</span>
                &nbsp;
              </b>
              <code>{curlOrdersKharkiv}</code>
            </p>
            <p>
              <b>
                Одеса
                <span>:</span>
                &nbsp;
              </b>
              <code>{curlOrdersOdesa}</code>
            </p>
            <p>
              <b>
                Польща
                <span>:</span>
                &nbsp;
              </b>
              <code>{curlOrdersPoland}</code>
            </p>

            <p>
              {t('jsonForm.instruction-to-load-orders-by-status-and-date')}
              <span>:</span>
            </p>
            <p><code>{curlOrdersByStatusAndDate}</code></p>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">{t('jsonForm.1с-orders-exchange-raw')}</div>
          </div>

          <div>
            <p><code>{curlOrdersRawAPI}</code></p>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">{t('jsonForm.1с-order-by-number')}</div>
          </div>

          <div>
            <p><code>{curlOrderByNumber}</code></p>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">{t('jsonForm.1с-orders-set-exchanged')}</div>
          </div>

          <div>
            <p><code>{curlOrdersSetExchangedAPI}</code></p>
            <Collapsible trigger={trigger}>
              {sampleSetOrdersEschanged()}
            </Collapsible>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">{t('jsonForm.1с-orders-status-exchanged')}</div>
          </div>

          <div>
            <p><code>{curlOrdersStatusExchangedAPI}</code></p>
            <Collapsible trigger={trigger}>
              {sampleSetOrdersStatusExchanged()}
            </Collapsible>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">{t('jsonForm.1с-customers-exchange')}</div>
          </div>

          <div>
            <p>
              {t('jsonForm.instruction-to-load-new-customers')}
              <span>:</span>
            </p>
            <p><code>{curlNewCustomers}</code></p>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">{t('jsonForm.1с-hinges-exchange')}</div>
          </div>

          <div>
            <p><code>{curlHingesExchangedAPI}</code></p>
            <Collapsible trigger={trigger}>
              {sampleHingesJson()}
            </Collapsible>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">Обмін менеджерами</div>
          </div>

          <div>
            <p><code>{curlManagers}</code></p>
            <Collapsible trigger={trigger}>
              {sampleManagers()}
            </Collapsible>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">{t('jsonForm.1с-system-constants-exchange')}</div>
          </div>

          <div>
            <p><code>{curlSystemConstantsAPI}</code></p>
            <Collapsible trigger={trigger}>
              {sampleSystemConstants()}
            </Collapsible>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">{t('jsonForm.1с-delivery-exchange')}</div>
          </div>

          <div>
            <p><code>{curlDeliveryJson}</code></p>
            <Collapsible trigger={trigger}>
              {sampleDeliveryJson()}
            </Collapsible>
          </div>
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">Обмін даними про курс валют</div>
          </div>

          <div>
            <p><code>{curlExchangeRatesJson}</code></p>
            <Collapsible trigger={trigger}>
              {sampleExchangeRatesJson()}
            </Collapsible>
          </div>
          <br />
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">Встановлення значення поклейки наповнення та полірування для Рамкових профілів</div>
          </div>

          <div>
            <p><code>{curlSetGluingPolishingForFrameProfilesAPI}</code></p>
            <Collapsible trigger={trigger}>
              {sampleSetGluingFillingAndPolishingForFrameProfiles()}
            </Collapsible>
          </div>
          <br />
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">Додавання бокових профілів залежно від типу системи</div>
          </div>

          <div>
            <p><code>{curlAddSideProfilesBySystemTypeAPI}</code></p>
            <Collapsible trigger={trigger}>
              {sampleSideProfilesBySystemType()}
            </Collapsible>
          </div>
          <br />
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">Видалення бокових профілів залежно від типу системи</div>
          </div>

          <div>
            <p><code>{curlDeleteSideProfilesBySystemTypeAPI}</code></p>
            <Collapsible trigger={trigger}>
              {sampleSideProfilesBySystemType()}
            </Collapsible>
          </div>
          <br />
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">Обмін даними про знижки</div>
          </div>

          <div>
            <p><code>{curlDiscountsJson}</code></p>
            <Collapsible trigger={trigger}>
              {sampleDiscounts()}
            </Collapsible>
          </div>

          <div>
            <Label value={t('jsonForm.file-with-discounts')} />
            <label
              className="label--upload-file"
              htmlFor="json-uploader-discounts"
            >
              <input
                type="file"
                id="json-uploader-discounts"
                name="json-uploader"
                accept=".json"
                ref={uploadDiscountsRef}
                onChange={onDiscountsChange}
                disabled={isDiscountsProcessing}
              />
              <span className="upload-custom">
                {discountsFilePath || '.json файл'}
                <ReactSVG
                  className="upload-custom-icon"
                  wrapper="span"
                  src="/src/client/assets/icons/fillingMaterials/search-glass.svg"
                />

              </span>
            </label>
            <div className="label--error-message">
              <span>{discountsErrorMessage}</span>
            </div>
          </div>

          <div className="content-wrapper--action-buttons">
            <Button
              value={t('jsonForm.submit')}
              type="rounded"
              onClick={async (e) => {
                e.preventDefault();
                onDiscountsSubmit();
              }}
              isDisabled={!discountsFilePath || !!discountsErrorMessage || isDiscountsProcessing}
              isProcessing={isDiscountsProcessing}
            />

            <Button
              value={t('jsonForm.clear')}
              onClick={async (e) => {
                e.preventDefault();
                setDiscountsFilePath('');
                setDiscountsFileContent('');
                uploadDiscountsRef.current.value = '';
              }}
              isDisabled={!discountsFilePath || !!discountsErrorMessage || isDiscountsProcessing}
            />
          </div>
          <br />
          <br />

          <div className="title-wrapper-secondary">
            <div className="title">Керування кольорами</div>
          </div>

          <Label value="Переклад" />
          <Tabs tabs={langTabs} activeTab={selectedLang} setActiveTab={setSelectedLang} />
          <br />

          <Label value="Кольори" />
          <div className="colors-wrapper">
            {aluminiumColors && aluminiumColors.map((c) => (
              <div
                key={c.articleCode}
                role="button"
                tabIndex="0"
                className="color"
                onClick={() => {
                  setColorForEdit(c);
                  dispatch(ConfigActions.toggleManageAluminiumColorsModal(true));
                }}
              >
                <div
                  style={{ background: c.color }}
                  className="background"
                />
                <p className="title">{`${c[selectedLang]} (${c.articleCode})`}</p>
              </div>
            ))}
          </div>

          <br />

          <div className="content-wrapper--action-buttons">
            <Button
              value="Створити колір"
              type="rounded"
              onClick={async (e) => {
                e.preventDefault();
                dispatch(ConfigActions.toggleManageAluminiumColorsModal(true));
              }}
            />
          </div>

          <br />
          <br />

        </div>
      </div>

      <ManageAluminiumColorsModal
        isOpen={isOpenManageAluminiumColorsModal}
        colorForEdit={colorForEdit}
        onCloseModal={() => {
          setColorForEdit({});
          dispatch(ConfigActions.toggleManageAluminiumColorsModal(false));
        }}
        onEditColor={(articleCode, color) => {
          dispatch(ConfigActions.editAluminiumColorRequest(articleCode, color));
        }}
        onCreateColor={(color) => {
          dispatch(ConfigActions.createAluminiumColorRequest(color));
        }}
      />

    </Main>
  );
};

FileUploadForm.propTypes = {
  toastManager: PropTypes.shape(notificationPropTypes).isRequired,
};

export default withToastManager(FileUploadForm);
