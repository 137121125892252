import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setMinDoorsAmount: ['systemConctants', 'currentSystem'],

  setMaxDoorsAmount: ['systemConctants', 'currentSystem'],

  increaseDoorsAmountRequest: ['amountToAdd', 'systemConctants', 'fillings'],
  increaseDoorsAmountSuccess: null,
  increaseDoorsAmountFailure: ['errorMessage'],

  decreaseDoorsAmountRequest: ['amountToRemove', 'systemConctants', 'currentSystem', 'fillings'],
  decreaseDoorsAmountSuccess: null,
  decreaseDoorsAmountFailure: ['errorMessage'],

  updateMainDoor: ['field', 'systemConctants', 'currentSystem', 'fillings'],

  updateDoorsSizes: ['systemConctants', 'currentSystem', 'fillings'],

  addDoorRequest: ['systemConctants', 'currentSystem', 'fillings'],
  addDoorSuccess: null,
  addDoorFailure: ['errorMessage'],

  copyDoorRequest: ['doorIndexFrom', 'doorIndexesTo'],
  copyDoorSuccess: null,
  copyDoorFailure: ['errorMessage'],

  removeDoorRequest: ['doorIndex', 'systemConctants', 'currentSystem', 'fillings'],
  removeDoorSuccess: null,
  removeDoorFailure: ['errorMessage'],

  updateMainDoorFilling: ['filling'],

  updateMainSectionRequest: ['doorIndex', 'field', 'currentSystem', 'fillings'],
  updateMainSectionSuccess: null,
  updateMainSectionFailure: ['errorMessage'],

  updateSectionRequest: ['doorIndex', 'sectionIndex', 'field'],
  updateSectionSuccess: null,
  updateSectionFailure: ['errorMessage'],

  addSection: ['doorIndex', 'systemConctants', 'currentSystem', 'fillings'],

  copySectionRequest: ['doorIndex', 'sectionIndexFrom', 'sectionIndexesTo'],
  copySectionSuccess: null,
  copySectionFailure: ['errorMessage'],

  mergeSectionRequest: ['doorIndex', 'sectionIndexToRemove', 'mergeOption', 'systemConctants', 'currentSystem', 'fillings'],
  mergeSectionSuccess: null,
  mergeSectionFailure: ['errorMessage'],

  removeSectionsRequest: ['doorIndex', 'sectionIndexToRemove'],
  removeSectionsSuccess: null,
  removeSectionsFailure: ['errorMessage'],

  removeLastSectionRequest: ['doorIndex', 'systemConctants', 'currentSystem', 'fillings'],
  removeLastSectionSuccess: null,
  removeLastSectionFailure: ['errorMessage'],

  alignSections: ['doorIndex', 'systemConctants', 'currentSystem', 'fillings'],

  updateSectionsSizesOnEdit: ['doorIndex', 'sectionIndex', 'field', 'systemConctants', 'currentSystem', 'fillings'],

  updateMainSectionFilling: ['doorIndex', 'filling'],

  updateSectionFilling: ['doorIndex', 'sectionIndex', 'filling'],

  updateSideProfile: ['sideProfile', 'systemConctants', 'currentSystem', 'isCurrentColorAvailable', 'fillings'],

  clearFilling: ['doorIndex', 'sectionIndex'],

  toggleCopyDoorModal: ['isOpen'],
  toggleDeleteDoorModal: ['isOpen'],
  toggleCopySectionModal: ['isOpen'],
  toggleMergeSectionModal: ['isOpen'],
  toggleDeleteSectionModal: ['isOpen'],
  toggleZoomModal: ['isOpen'],

  setActiveDoor: ['number'],
  setActiveSection: ['number'],

  hightlightDoorOpeningInputs: ['labelKey'],

  updateDoorLatchMechanism: ['doorIndex', 'isOn'],

  setOrderBySnippet: ['doorsSnippet'],

  resetOrderDefaults: null,

  setDefaultsBySystemType: ['currentSystem'],

  toggleCarriageProfile: ['isOn'],
  toggleGuidanceProfile: ['isOn'],
  toggleMiddleDoorMechanism: ['isOn'],
  toggleStopper: ['isOn', 'sideProfile', 'systemConctants'],

  setFrameProfileHeight: ['activeFrame', 'profileHeight'],
  setFrameProfileWidth: ['activeFrame', 'profileWidth'],
  setFrameOpeningHeight: ['activeFrame', 'openingHeight'],
  setFrameOpeningWidth: ['activeFrame', 'openingWidth'],
  setFrameGroupName: ['activeFrame', 'groupName'],
  setOpeningSide: ['activeFrame', 'openingSide'],
  setFrameFilling: ['activeFrame', 'filling'],
  setFrameFillingTexture: ['activeFrame', 'fillingTexture'],
  setFrameProfile: ['activeFrame', 'frameProfile'],
  setFrameAluminiumColor: ['activeFrame', 'aluminiumColor'],
  setFacadeView: ['activeFrame', 'facadeView'],
  setMillingForReinforcingProfile: ['activeFrame', 'amount'],
  setStrainerForReinforcingProfile: ['activeFrame', 'amount'],
  setFrameConnectingCorner: ['activeFrame', 'connectingCorner'],
  setFrameSealing: ['activeFrame', 'sealing'],
  setFrameGlassPolishing: ['activeFrame', 'glassPolishing'],
  setFrameGluing: ['activeFrame', 'gluing'],
  setGuaranteeAgreement: ['activeFrame', 'agreement'],
  setFrameHeightGuaranteeAgreement: ['activeFrame', 'agreement'],
  setFrameWidthGuaranteeAgreement: ['activeFrame', 'agreement'],
  setFrameGuaranteeType: ['guaranteeType'],
  setFrameCreatingParams: ['params'],
  updateFrameCreatingParams: ['params'],
  clearFrameCreatingParams: null,
  setFrameCreatingStep: ['step'],
  clearFrameCreatingStep: null,
  setFrameInvalidCoords: ['invalidCoords'],
  setFrameActiveInput: ['params'],
  clearFrameActiveInput: null,
  setFrameImages: ['images'],

  setReinforcingProfileAmount: ['activeFrame', 'amount'],
  updateReinforcingProfileItems: ['activeFrame', 'items'],
  setHolesForHandles: ['activeFrame', 'holesForHandles'],
  setFasteningElement: ['activeFrame', 'fasteningElement'],
  setPearHoles: ['activeFrame', 'pearHoles'],

  setSameFramesAmount: ['activeFrame', 'amount'],

  setInvertedFrame: ['frameSequenceNumber', 'frameItem'],
  invertFrame: ['isInvert'],

  addFrame: ['frameItem'],
  deleteFrame: ['frameSequenceNumber'],
  clearMainFrameState: null,

  toggleFrameConfigurationView: ['view'],
  toggleShowSizes: ['activeFrame', 'isShow'],
  toggleGroupNameModal: ['isOpen'],
  toggleFrameParamsModal: ['isOpen'],
  toggleDeleteFrameModal: ['isOpen'],
  toggleChangeFrameFillingModal: ['isOpen'],
  toggleStrengthProfileModal: ['isOpen'],
  toggleWarningFrameSizeModal: ['isOpen'],
  toggleHingeDetailsModal: ['isOpen'],
  toggleTransparentFillingWarning: ['isOpen'],
  toggleWarningInvalidCoordsModal: ['isOpen'],
  toggleWarningIncompleteDataModal: ['isOpen'],
});

export const DoorTypes = Types;
export default Creators;
