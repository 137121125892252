import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const Tooltip = ({ content, hasTranslation, direction, children, className }) => {
  const { t } = useTranslation();
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className={clsx('tooltip-wrap', className)}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div className={`tooltip-tip ${direction || 'top'}`}>
          {hasTranslation
            ? t(content)
            : content}
        </div>
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  className: '',
  hasTranslation: false,
};

Tooltip.propTypes = {
  className: PropTypes.string,
  hasTranslation: PropTypes.bool,
  children: PropTypes.node.isRequired,
  direction: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Tooltip;
