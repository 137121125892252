import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import Modal from '../Modal';
import Button from '../Button';

const WarningHandleModal = ({
  isOpen,
  className,
  onCloseModal,
  onChangeHandleSide,
  onSaveWithoutHandle,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
    >
      <div className="modal-order-icon-wrapper">
        <ReactSVG
          wrapper="div"
          className="modal-order-icon"
          src="/src/client/assets/icons/price-package-icon.svg"
        />
      </div>
      <h2 className="headings-h2">{t('warningHandleModal.title')}</h2>

      <div className="action-buttons">
        <div className="action-buttons-inner">
          <Button
            value={t('warningHandleModal.cancel')}
            type="link-button"
            onClick={onChangeHandleSide}
            isDisabled={false}
          />
          <Button
            value={t('warningHandleModal.submit')}
            type="link-button"
            onClick={onSaveWithoutHandle}
            isDisabled={false}
          />
        </div>
      </div>
    </Modal>
  );
};

WarningHandleModal.defaultProps = {
  isOpen: false,
  className: 'action-modal warning-handle',
};

WarningHandleModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  onChangeHandleSide: PropTypes.func.isRequired,
  onSaveWithoutHandle: PropTypes.func.isRequired,
};

export default WarningHandleModal;
