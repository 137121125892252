import _ from 'lodash';

import { squareMillimetersValue } from '../../server/helpers/constants.mjs';
import { hasOpeningSide } from '../../server/helpers/validation.mjs';

import {
  defaultAluminiumColor,
  defaultTopProfile,
  defaultBottomProfile,
  defaultSealing,
  defaultAssemblyScrew,
  topDoorLatchMechanism,
  bottomDoorLatchMechanism,
  defaultMilling,
  packagingOfGuidanceProfile,
  defaultPackageName,
  defaultStrainer,
  hingedMiddleDoorMechanism,
  assemblingAdditionalMechanism,
  doorPositioningOptions,
  hasBottomLatchMechanism,
  constantsBySystemType,
  reinforcingProfileOptions,
  screwsOptions,
  sealingOptions,
  frameProfileParameters,
} from '../../server/helpers/priceHelperOptions.mjs';

export const isColorSideProfileAvailable = (color, sideProfile, prices) => {
  const spArticleCode = color ? `${color}-${sideProfile}` : sideProfile;
  const sideProfileDoc = prices?.find((s) => s.articleCode === spArticleCode && s.packageName === defaultPackageName);
  return !!sideProfileDoc?._id;
};

export const isMechanismAvailable = (mechanism, prices) => {
  const mechanismDoc = prices?.find((s) => s.articleCode === mechanism && s.packageName === defaultPackageName);
  return !!mechanismDoc?._id;
};


const getRoundedNumberMultipleOfNumber = (price, number) => {
  let roundedPrice = Math.ceil(price);

  for (let i = 0; ; i + 1) {
    if (roundedPrice % number === 0) {
      break;
    }
    roundedPrice += 1;
  }
  return roundedPrice;
};


const applyItemDiscounts = (
  { articleCode },
  totalPrice,
  currentSystem,
  sideProfile,
  aluminiumColor,
  items,
  { discounts },
  isDoorAssemblingOn = true,
) => {
  // TODO: REFACTOR THIS PART AFTER VALID JSON EXAMPLE WILL BE PROVIDED
  const compareSystemTypes = ({ systemType: type }) => type === currentSystem;

  const conditions = {
    sumMoreThan: ({ sumMoreThan }) => totalPrice > sumMoreThan,
    itemQuantityMoreThan: ({
      itemQuantityMoreThan: { itemArticleCode, quantity },
    }) => items.find((item) => item.articleCode === itemArticleCode && item.amount > quantity),
    unconditional: () => true,
    calculatorDiscount: () => true,
    // Commented out temporarily until the customer gives an answer on what to do with this check
    // orderType: ({ orderType: type }) => type === 'D - Двери', // TODO: CONSIDER ABOUT ADDING OTHER TYPES OR DELETING THIS FIELD AT ALL
    systemType: compareSystemTypes,
    combined: ({
      combined: { /* orderType, */ systemTypes, sideProfileTypes, colors },
    }) => {
      // Commented out temporarily until the customer gives an answer on what to do with this check
      // if (orderType && orderType !== 'D - Двери') return false; // TODO: CONSIDER ABOUT ADDING OTHER TYPES OR DELETING THIS FIELD AT ALL
      if (!_.isEmpty(systemTypes) && !systemTypes?.includes(currentSystem)) return false;
      if (!_.isEmpty(sideProfileTypes) && !sideProfileTypes?.includes(sideProfile)) return false;
      if (!_.isEmpty(colors) && !colors?.includes(aluminiumColor)) return false;

      return true;
    },
  };

  let relevantDiscounts = [];

  if (isDoorAssemblingOn) {
    relevantDiscounts = discounts.filter((discount) => {
      const { startDate, endDate, articles } = discount;
      const discountStart = new Date(startDate).getTime();
      const discountEnd = new Date(endDate).getTime();
      const currentDate = new Date().getTime();

      let isConditionFullfiled = false;

      Object.entries(conditions).every(([conditionName, conditionCheckFn]) => {
        if (!_.isEmpty(discount[conditionName])) {
          isConditionFullfiled = conditionCheckFn(discount);
          return false;
        }
        return true;
      });

      return currentDate >= discountStart && currentDate <= discountEnd && (articles === null || articles?.some(({ code }) => code === articleCode)) && isConditionFullfiled;
    });
  }

  if (!isDoorAssemblingOn) {
    relevantDiscounts = discounts.filter((discount) => discount.orderType === 'P - Порезка');
  }

  const maxDiscount = relevantDiscounts.reduce((acc, current) => {
    if (!_.isEmpty(current.articles)) {
      const currentArticle = current.articles.find(({ code }) => code === articleCode);
      if (!currentArticle) return acc;

      const currentDiscount = {
        discountPercentage: currentArticle?.discountPercentage,
        endDate: new Date(current.endDate),
      };

      if (currentArticle?.discountPercentage < 0) {
        return (acc.discountPercentage < currentArticle?.discountPercentage)
          ? acc
          : currentDiscount;
      }

      return (currentArticle?.discountPercentage > acc.discountPercentage && acc.discountPercentage >= 0)
        ? currentDiscount
        : acc;
    }

    const currentDiscount = {
      discountPercentage: current.discountPercentage,
      endDate: new Date(current.endDate),
    };

    if (current.discountPercentage < 0) {
      return (acc.discountPercentage < current.discountPercentage)
        ? acc
        : currentDiscount;
    }

    return (current.discountPercentage > acc.discountPercentage && acc.discountPercentage >= 0)
      ? currentDiscount
      : acc;
  }, {
    discountPercentage: 0,
    endDate: '',
  });

  return maxDiscount;
};

export function calculateFrameOrder(state, packageName) {
  const {
    priceList: {
      priceList,
      specification: specificationObject,
    },
    config: {
      aluminiumColors,
      filling: fillingMaterials,
      frameProfiles,
    },
    systems: { currentSystem },
    doorsAndSections,
    discounts,
  } = state;

  const {
    mainFrame,
    doors,
  } = doorsAndSections;

  const allFrame = [...(!_.isEmpty(mainFrame) ? [mainFrame] : []), ...doors];

  const generalSpecification = [];

  _.forEach(allFrame, (frame) => {
    const {
      sequenceNumber,
      frameOpeningHeight: { value: frameOpeningHeight },
      frameOpeningWidth: { value: frameOpeningWidth },
      frameAluminiumColor,
      frameProfile,
      connectingCorner,
      reinforcingProfile: {
        amount: reinforcingProfileAmount,
      },
      holesForHandles: {
        amount: holesForHandlesAmount,
        items: holesForHandlesItems,
      },
      fasteningElement: {
        type: fasteningElementType,
        amount: fasteningElementAmount,
        items: fasteningElementItems,
        hingeType: fasteningElementHingeType,
        drillingScheme: fasteningElementDrillingScheme,
        liftingMechanismType,
        liftingMechanismBottomType,
        withBottomModule,
      },
      pearHoles: {
        amount: pearHolesAmount,
      },
      millingForReinforcingProfile: { amount: millingAmount },
      strainerForReinforcingProfile: { amount: strainerAmount },
      filling,
      fillingTexture: { value: fillingTexture },
      sameFramesAmount,
    } = frame;

    const frameProfileValue = frameProfile?.value;
    const frameAluminiumColorValue = frameAluminiumColor?.value;
    const frameFilling = filling?.[0];
    const frameProfileParameter = frameProfileParameters[frameProfileValue];
    const framePerimeter = (liftingMechanismType === 'aventos_hf_top' && withBottomModule)
      ? (Number(frameOpeningHeight) + Number(frameOpeningWidth) * 2)
      : (Number(frameOpeningHeight) * 2 + Number(frameOpeningWidth) * 2);
    const frameFillingH = (liftingMechanismType === 'aventos_hf_top' && withBottomModule)
      ? (Number(frameOpeningHeight) / 2) - frameProfileParameter?.size
      : Number(frameOpeningHeight) - frameProfileParameter?.size;
    const frameFillingW = Number(frameOpeningWidth) - frameProfileParameter?.size;
    const frameFillingArea = frameFillingH * frameFillingW;
    const frameFillingAreaInMeters = frameFillingArea / squareMillimetersValue;
    const frameAreaInMeters = (liftingMechanismType === 'aventos_hf_top' && withBottomModule)
      ? ((Number(frameOpeningHeight) / 2) * Number(frameOpeningWidth)) / squareMillimetersValue
      : (Number(frameOpeningHeight) * Number(frameOpeningWidth)) / squareMillimetersValue;
    const framesAmount = sameFramesAmount?.value;
    const liftingMechanismModuleAmount = withBottomModule ? 2 : 1;

    const aluminiumColorRecord = frameAluminiumColorValue && aluminiumColors
      ? aluminiumColors[aluminiumColors?.findIndex((c) => c.articleCode === frameAluminiumColorValue)]
      : '';
    const fillingArticleCode = frameFilling?.mirrorType || frameFilling?.lacobelType || frameFilling?.glassType;
    const isTransparentFilling = fillingMaterials.find(({ articleCode }) => articleCode === fillingArticleCode)?.isTransparent;
    const isGluingFilling = frameProfiles.find(({ articleCode }) => articleCode === frameProfileValue)?.gluingFilling;
    const isPolishing = frameProfiles.find(({ articleCode }) => articleCode === frameProfileValue)?.isPolishing;

    if (!frameProfileValue || !frameAluminiumColorValue || !frameOpeningHeight || !frameOpeningWidth || _.isEmpty(frameFilling) || !framesAmount) {
      return generalSpecification.push({
        frameNumber: sequenceNumber || 0,
        doorOpeningHeight: frameOpeningHeight,
        doorOpeningWidth: frameOpeningWidth,
        sameFramesAmount: framesAmount,
        aluminiumColor: `${frameAluminiumColorValue} ${aluminiumColorRecord?.labelUk?.toLowerCase()}`,
        specification: null,
      });
    }

    const specification = {
      items: [],
      missingItems: [],
      totalPrice: 0,
      retailTotalPrice: 0,
    };

    const frameItems = [];

    const getFrameItemOptions = (name, type, itemAmount, moduleAmount, additionalOption = '', frameIndex = null) => {
      const itemName = name;

      if (type === 'profile') {
        return {
          itemName,
          itemCount: itemAmount * moduleAmount,
          get itemAmount() { return this.itemCount * framesAmount; },
          itemSize: additionalOption === 'side'
            ? Number(frameOpeningHeight) / moduleAmount
            : Number(frameOpeningWidth),
          get itemCalc() { return (this.itemSize / 1000) * this.itemAmount; },
        };
      }
      if (type === 'reinforcing profile') {
        return {
          itemName,
          itemCount: itemAmount * moduleAmount,
          get itemAmount() { return this.itemCount * framesAmount; },
          itemSize: frameOpeningWidth - frameProfileParameter?.reinforcingProfile,
          get itemCalc() { return (this.itemSize / 1000) * this.itemAmount; },
        };
      }
      if (type === 'amount') {
        return {
          itemName,
          itemCount: additionalOption === 'aventos_hf_top'
            ? itemAmount
            : itemAmount * moduleAmount,
          get itemAmount() { return this.itemCount * framesAmount; },
          itemSize: 0,
          get itemCalc() { return this.itemAmount; },
        };
      }

      if (type === 'perimeter') {
        return {
          itemName,
          itemCount: itemAmount * moduleAmount,
          get itemAmount() { return this.itemCount * framesAmount; },
          itemSize: framePerimeter,
          get itemCalc() { return (this.itemSize / 1000) * this.itemAmount; },
          ...(frameIndex ? { itemFrameIndex: frameIndex } : {}),
        };
      }

      if (type === 'fillingArea') {
        return {
          itemName,
          itemCount: itemAmount * moduleAmount,
          get itemAmount() { return this.itemCount * framesAmount; },
          itemSize: frameFillingArea,
          filingHeight: frameFillingH,
          filingWidth: frameFillingW,
          get itemCalc() { return this.itemAmount * frameFillingAreaInMeters; },
          ...(frameIndex ? { itemFrameIndex: frameIndex } : {}),
        };
      }
    };

    /** Рамковий Профіль (верх/низ) */

    frameItems.push({
      itemArticleCode: `${frameAluminiumColorValue}-${frameProfileValue}`,
      ...getFrameItemOptions('frame profile', 'profile', 2, liftingMechanismModuleAmount),
    });

    /** Рамковий Профіль (право/ліво) */

    frameItems.push({
      itemArticleCode: `${frameAluminiumColorValue}-${frameProfileValue}`,
      ...getFrameItemOptions('frame profile sides', 'profile', 2, liftingMechanismModuleAmount, 'side'),
    });

    /** Кутик */
    // Для з'єднання профіля в рамку використовується 4 кутика

    frameItems.push({
      itemArticleCode: connectingCorner?.type === 'wide' ? 'WA-31' : 'NA-32',
      ...getFrameItemOptions('corner', 'amount', connectingCorner?.cornersAmount, liftingMechanismModuleAmount),
    });


    /** Гвинт */
    // Для з'єднання профіля в рамку використовується 8 гвинтів

    frameItems.push({
      itemArticleCode: Object.entries(screwsOptions).find(([, values]) => values.some((color) => color === frameAluminiumColorValue))?.[0],
      ...getFrameItemOptions('screw', 'amount', connectingCorner?.screwsAmount, liftingMechanismModuleAmount),
    });

    /** Підсилюючий профіль */

    if (reinforcingProfileAmount) {
      frameItems.push({
        itemArticleCode: Object.entries(reinforcingProfileOptions).find(([, values]) => values.some((color) => color === frameAluminiumColorValue))?.[0],
        ...getFrameItemOptions('reinforcing profile', 'reinforcing profile', reinforcingProfileAmount, liftingMechanismModuleAmount),
      });
    }

    /** Фрезерування під підсилюючий профіль */
    // 2 фрезерування на кожен підсилюючий профіль

    if (reinforcingProfileAmount) {
      frameItems.push({
        itemArticleCode: 'frez_ramk_per',
        ...getFrameItemOptions('milling', 'amount', millingAmount, liftingMechanismModuleAmount),
      });
    }

    /** Стяжка під підсилюючий профіль */
    // 1 стяжка на кожен підсилюючий профіль

    if (reinforcingProfileAmount) {
      frameItems.push({
        itemArticleCode: 'konn_alprofile',
        ...getFrameItemOptions('strainer', 'amount', strainerAmount, liftingMechanismModuleAmount),
      });
    }

    /** Фрезерування під елемент кріплення */

    if (fasteningElementItems?.length) {
      const elementsAmount = liftingMechanismType === 'aventos_hf_top' ? fasteningElementItems?.slice(0, 4)?.length : fasteningElementItems?.length;
      frameItems.push({
        itemArticleCode: ((frameProfileValue.replace('-N', '') !== 'P-32' && fasteningElementDrillingScheme === 'frez_d35') && 'frez_d35')
          || ((frameProfileValue.replace('-N', '') !== 'P-32' && fasteningElementDrillingScheme === 'frez_d35_45_9') && 'frez_d35_45_9')
          || ((frameProfileValue.replace('-N', '') === 'P-32' && fasteningElementType !== 'lifting-mechanism') && 'frez_p32')
          || ((frameProfileValue.replace('-N', '') === 'P-32' && fasteningElementType === 'lifting-mechanism' && liftingMechanismType === 'aventos_hf_top') && 'frez_p32_cliptop')
          || ((frameProfileValue.replace('-N', '') === 'P-32' && fasteningElementType === 'lifting-mechanism'
            && (liftingMechanismType === 'aventos_hk_top' || liftingMechanismType === 'aventos_hk_s' || liftingMechanismType === 'aventos_hl')) && 'frez_p32_telmod'),
        ...getFrameItemOptions('milling', 'amount', elementsAmount, liftingMechanismModuleAmount, liftingMechanismType),
      });
    }

    if (fasteningElementItems?.length && fasteningElementType === 'lifting-mechanism' && withBottomModule && liftingMechanismBottomType === 'aventos_hf_bottom') {
      frameItems.push({
        itemArticleCode: 'frez_p32_telmod',
        ...getFrameItemOptions('milling', 'amount', 2, liftingMechanismModuleAmount, liftingMechanismType),
      });
    }

    if (fasteningElementItems?.length && fasteningElementDrillingScheme === 'frez_d35') {
      const itemAmount = 2 * fasteningElementAmount;
      frameItems.push({
        itemArticleCode: 'CB_4,0*16',
        ...getFrameItemOptions('self-tapping screw', 'amount', itemAmount, liftingMechanismModuleAmount, liftingMechanismType),
      });
    }

    if (fasteningElementItems?.length && fasteningElementDrillingScheme === 'frez_d35_45_9') {
      const itemAmount = 2 * fasteningElementAmount;
      frameItems.push({
        itemArticleCode: 'din_963',
        ...getFrameItemOptions('screw', 'amount', itemAmount, liftingMechanismModuleAmount, liftingMechanismType),
      });

      frameItems.push({
        itemArticleCode: 'gau_kl_M4',
        ...getFrameItemOptions('nut', 'amount', itemAmount, liftingMechanismModuleAmount, liftingMechanismType),
      });
    }

    /** Петлі */

    if (fasteningElementAmount && fasteningElementHingeType) {
      frameItems.push({
        itemArticleCode: fasteningElementHingeType,
        ...getFrameItemOptions('hinge', 'amount', fasteningElementAmount, liftingMechanismModuleAmount),
      });
    }

    /** Свердління отворів під ручки */

    if (holesForHandlesAmount) {
      frameItems.push({
        itemArticleCode: 'svrdl_07',
        ...getFrameItemOptions('drilling', 'amount', holesForHandlesAmount, liftingMechanismModuleAmount, liftingMechanismType),
      });
    }

    /** Втулки при свердлінні отворів під ручки */

    if (holesForHandlesAmount && holesForHandlesItems?.[0]?.bushing) {
      frameItems.push({
        itemArticleCode: 'PS_GLASS',
        ...getFrameItemOptions('bushing', 'amount', holesForHandlesAmount, liftingMechanismModuleAmount),
      });
    }

    /** Свердління грушковидних отворів */

    if (pearHolesAmount) {
      frameItems.push({
        itemArticleCode: 'svrdl_grushka',
        ...getFrameItemOptions('drilling', 'amount', pearHolesAmount, liftingMechanismModuleAmount),
      });
    }

    /** Ущільнювач */

    if (frameProfileValue?.replace('-N', '') !== 'P-34') {
      frameItems.push({
        itemArticleCode: Object.entries(sealingOptions).find(([, values]) => {
          const { colorsDependence, profilesDependence } = values;
          return colorsDependence.some((color) => color === frameAluminiumColorValue) && profilesDependence.some((profile) => profile === frameProfileValue?.replace('-N', ''));
        })?.[0],
        ...getFrameItemOptions('sealing', 'perimeter', 1, liftingMechanismModuleAmount),
      });
    }

    /** Ущільнювач додатковий */

    if (frameProfileValue === 'P-37-N') {
      frameItems.push({
        itemArticleCode: 'YA-302/160 AS',
        ...getFrameItemOptions('sealing', 'perimeter', 1, liftingMechanismModuleAmount),
      });
    }

    /** Підготовка до збірки */

    frameItems.push({
      itemArticleCode: 'pidzot_r_zbirka',
      ...getFrameItemOptions('prepare for work', 'amount', 1, liftingMechanismModuleAmount),
    });

    /** Робота */

    frameItems.push({
      itemArticleCode: (frameAreaInMeters <= 0.9 && 'zbirka_r')
        || ((frameAreaInMeters > 0.9 && frameAreaInMeters <= 1.5) && 'zbirka_r_to15')
        || (frameAreaInMeters > 1.5 && 'zbirka_r_un15'),
      ...getFrameItemOptions('work', 'amount', 1, liftingMechanismModuleAmount),
      itemLabelRu: 'Сборка',
      itemLabelUk: 'Збірка',
      itemLabelPl: 'Montaż',
      itemLabelEn: 'Assembly',
    });

    /** Пакування */

    frameItems.push({
      itemArticleCode: (frameAreaInMeters < 1.5 && 'pak_r') || (frameAreaInMeters >= 1.5 && 'pak_sl'),
      ...getFrameItemOptions('packaging', 'amount', 1, liftingMechanismModuleAmount),
    });

    /** Матеріал наповнення */

    if (!_.isEmpty(frameFilling) || frameFilling.material) {
      // Матеріал замовника

      if (frameFilling?.material === 'customers') {
        frameItems.push({
          itemArticleCode: 'customGlass',
          ...getFrameItemOptions('filling', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
          itemLabelRu: 'Материал заказчика: Стекло',
          itemLabelUk: 'Матеріал замовника: Скло',
          itemLabelPl: 'Materiał klienta: Szkło',
          itemLabelEn: 'Customer material: Glass',
          isCustomItem: true,
        });
      }

      if (frameFilling?.material && frameFilling?.material !== 'customers') {
        const itemArticleCode = frameFilling.mirrorType || frameFilling.lacobelType || frameFilling.glassType || frameFilling.dspOption;
        frameItems.push({
          itemArticleCode,
          ...getFrameItemOptions('filling', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
          itemTexture: isFillingHasTexture(fillingMaterials, itemArticleCode) ? fillingTexture : '',
        });
      }
      /** Additional Supplements */

      /** Ультрафіолетовий друк - Дзеркало */

      if (frameFilling?.isMirrorUVPrinting) {
        frameItems.push({
          itemArticleCode: frameFilling?.mirrorUvPrintType,
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Ультрафіолетовий друк - Скло */

      if (frameFilling?.isGlassUVPrinting) {
        frameItems.push({
          itemArticleCode: frameFilling?.glassUvPrintType,
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Ультрафіолетовий друк - Лакобель */

      if (frameFilling?.isLacobelUVPrinting) {
        frameItems.push({
          itemArticleCode: frameFilling?.lacobelUvPrintType,
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Матування по трафарету */

      if (frameFilling?.isMirrorMatted || frameFilling?.isGlassMatted || frameFilling?.isLacobelMatted) {
        frameItems.push({
          itemArticleCode: 'sten_mat',
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Тильне матування */

      if (frameFilling?.isMirrorRearMatted || frameFilling?.isLacobelRearMatted) {
        frameItems.push({
          itemArticleCode: 'rear_matt',
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Повне матування */

      if (frameFilling?.isMirrorFullMatted || frameFilling?.isLacobelFullMatted || frameFilling?.isGlassFullMatted) {
        frameItems.push({
          itemArticleCode: 'matt_compl',
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Дзеркало RAL */

      if (frameFilling?.isMirrorRearMatted && frameFilling?.mirrorColor) {
        frameItems.push({
          itemArticleCode: frameFilling.mirrorColor,
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Лакобель RAL */

      if (frameFilling?.isLacobelRearMatted && frameFilling?.lacobelColor) {
        frameItems.push({
          itemArticleCode: frameFilling.lacobelColor,
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Бронь плівка */

      if (frameFilling?.isMirrorArmoredFilm || frameFilling?.isLacobelArmoredFilm || frameFilling?.isGlassArmoredFilm) {
        frameItems.push({
          itemArticleCode: 'film_armor',
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Ламінування білою плівкою */

      if (frameFilling?.isMirrorLaminated || frameFilling?.isLacobelLaminated || frameFilling?.isGlassLaminated) {
        frameItems.push({
          itemArticleCode: 'lam_wfilm',
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Фотодрук на плівці - Скло */

      if (frameFilling?.isGlassPhotoPrinting && frameFilling?.glassPhotoPrintType) {
        frameItems.push({
          itemArticleCode: frameFilling?.glassPhotoPrintType,
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Скло - Фарбування в один колір або в два кольори */

      if (frameFilling?.isGlassOneColorPainted || frameFilling?.isGlassTwoColorsPainted) {
        frameItems.push({
          itemArticleCode: frameFilling?.isGlassOneColorPainted ? 'paint_1col' : 'paint_2col',
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      if (frameFilling?.isGlassTwoColorsPainted) {
        frameItems.push({
          itemArticleCode: 'sten_paint',
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }

      /** Glass RAL */

      if (frameFilling?.glassColors && frameFilling?.glassColors?.length) {
        _.forEach(frameFilling.glassColors, (articleCode) => {
          frameItems.push({
            itemArticleCode: articleCode,
            ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
          });
        });
      }

      /** Полірування - додатково */

      if (isPolishing) {
        frameItems.push({
          itemArticleCode: 'пол_4-5',
          ...getFrameItemOptions('fillingFeature', 'perimeter', 1, liftingMechanismModuleAmount, 'setHeightAndWidth', sequenceNumber),
        });
      }

      /** Ультрафіолетовий друк - додатково */

      if (isTransparentFilling && isGluingFilling) {
        frameItems.push({
          itemArticleCode: 'UF_Prin_ramka',
          ...getFrameItemOptions('fillingFeature', 'fillingArea', 1, liftingMechanismModuleAmount, '', sequenceNumber),
        });
      }
    }

    frameItems.forEach((f) => {
      const {
        itemName,
        itemArticleCode,
        itemCount,
        itemAmount,
        itemSize,
        filingHeight,
        filingWidth,
        itemCalc,
        itemLabelRu,
        itemLabelUk,
        itemLabelPl,
        itemLabelEn,
        isCustomItem,
        itemFrameIndex,
        itemTexture,
      } = f;

      const frameItemRetail = priceList?.find((as) => as.articleCode === itemArticleCode && as.packageName === defaultPackageName) || {};
      const frameItem = priceList?.find((as) => as.articleCode === itemArticleCode && as.packageName === packageName) || frameItemRetail;
      const frameItemCost = frameItem?.price || 0;
      const frameItemCostRetail = frameItemRetail?.price || 0;
      const frameItemTotalPrice = _.round(+(itemCalc * frameItemCost), 2);

      if (!_.isEmpty(frameItem) || isCustomItem) {
        specification.items.push({
          item: itemName,
          amount: itemAmount,
          size: itemSize,
          ...(itemCount && { itemAmount: itemCount }),
          ...(filingHeight && { height: filingHeight }),
          ...(filingWidth && { width: filingWidth }),
          unitPrice: frameItemCost,
          itemTotalPrice: frameItemTotalPrice,
          labelRu: itemLabelRu || frameItem?.labelRu || '',
          labelUk: itemLabelUk || frameItem?.labelUk || '',
          labelPl: itemLabelPl || frameItem?.labelPl || '',
          labelEn: itemLabelEn || frameItem?.labelEn || '',
          articleCode: itemArticleCode,
          texture: itemTexture || '',
          ...(itemFrameIndex ? { position: { doorIndex: itemFrameIndex } } : {}),
        });
        specification.totalPrice += frameItemTotalPrice;
        specification.retailTotalPrice += _.round(+(itemCalc * frameItemCostRetail), 2);
      } else {
        console.log(`${itemName}  ${itemArticleCode} is not found`);
        specification.missingItems.push({
          item: itemName,
          articleCode: itemArticleCode,
        });
      }
    });

    specification.totalPrice = _.round(specification.totalPrice, 2);
    specification.retailTotalPrice = _.round(specification.retailTotalPrice, 2);

    generalSpecification.push({
      frameNumber: sequenceNumber || 0,
      doorOpeningHeight: frameOpeningHeight,
      doorOpeningWidth: frameOpeningWidth,
      sameFramesAmount: framesAmount,
      aluminiumColor: `${frameAluminiumColorValue} ${aluminiumColorRecord?.labelUk?.toLowerCase()}`,
      specification: {
        ...specification,
        totalPrice: _.round(specification.items.reduce((acc, item) => acc + item.itemTotalPrice, 0), 2),
        framesAmount,
      },
    });
  });

  const getSpecificationWithDiscount = () => {
    const specTotalPrice = generalSpecification.filter(({ specification }) => !_.isEmpty(specification))
      .reduce((total, frame) => total + frame.specification.totalPrice, 0);

    const discountsEndDates = [];
    let itemPriceWithDiscount = 0;
    let itemPriceWithoutDiscount = 0;

    const specWithDiscont = generalSpecification.filter(({ specification }) => !_.isEmpty(specification))
      .map((spec, i) => {
        const profileColor = spec?.aluminiumColor.match(/^[^\s]+/)[0];
        const sanitazedProfile = spec?.specification?.items.find((specItem) => specItem?.item === 'frame profile')?.articleCode.replace(/^([A-Z]+-)(P-\d+)(-[A-Z]*)?$/, '$2$3');
        const specItems = spec?.specification?.items;

        const specItemsWithDiscount = specItems.map((item, i) => {
          if (item.item === 'work') {
            return {
              ...item,
              discountPercentage: 0,
              totalPriceWithoutDiscount: item.itemTotalPrice,
            };
          }

          const { discountPercentage, endDate } = applyItemDiscounts(
            item,
            specTotalPrice,
            currentSystem,
            sanitazedProfile,
            profileColor,
            specItems,
            discounts,
          );

          if (endDate) discountsEndDates.push(endDate);

          const { itemTotalPrice } = item;

          itemPriceWithDiscount += _.round(itemTotalPrice * (1 - discountPercentage / 100), 2);
          itemPriceWithoutDiscount += itemTotalPrice;

          return {
            ...item,
            discountPercentage,
            itemTotalPrice: _.round(itemTotalPrice * (1 - discountPercentage / 100), 2),
            totalPriceWithoutDiscount: itemTotalPrice,
          };
        });

        const specFrameTotalPrice = specItemsWithDiscount.reduce((total, item) => total += item?.itemTotalPrice, 0);

        return {
          ...spec,
          specification: {
            ...spec?.specification,
            items: specItemsWithDiscount,
            totalPrice: specFrameTotalPrice,
          },
        };
      });

    return {
      specificationByFrame: specWithDiscont,
      ...(discountsEndDates?.[0] && { earliestDiscountEndDate: discountsEndDates?.[0] }),
      totalPrice: _.round(specWithDiscont.reduce((total, frame) => {
        if (frame?.frameNumber === 0) return total;
        total += frame.specification.totalPrice;
        return total;
      }, 0), 2),
      retailTotalPrice: _.round(specWithDiscont.reduce((total, frame) => {
        if (frame?.frameNumber === 0) return total;
        total += frame.specification.retailTotalPrice;
        return total;
      }, 0), 2),
      priceWithDiscount: itemPriceWithDiscount < itemPriceWithoutDiscount,
    };
  };

  const getSpecificationWithRoundedPrice = () => {
    const specToUpdate = getSpecificationWithDiscount();

    if (!getSpecificationWithDiscount()?.totalPrice) return specToUpdate;

    const currentTotalPrice = getSpecificationWithDiscount()?.totalPrice;
    const roundedTotalPrice = getRoundedNumberMultipleOfNumber(getSpecificationWithDiscount()?.totalPrice, 3);

    if (currentTotalPrice === roundedTotalPrice) return specToUpdate;

    const priceDifference = _.round(roundedTotalPrice - currentTotalPrice, 2);
    const frameAmount = specToUpdate.specificationByFrame.filter(({ frameNumber }) => frameNumber !== 0).length;

    const preiceByFrame = _.round(priceDifference / frameAmount, 2);
    const excessPrice = _.round(priceDifference - preiceByFrame * frameAmount, 2);


    const updatedSpecByFrame = specToUpdate.specificationByFrame.map((spec, i) => {
      if (spec?.frameNumber === 0) return spec;

      let priceToAdd = preiceByFrame;

      const specItemsToUpdate = spec.specification.items.map((item) => {
        if (item.item === 'work' && i + 1 === frameAmount) {
          priceToAdd = _.round(preiceByFrame + excessPrice, 2);

          return {
            ...item,
            itemTotalPrice: _.round(item.itemTotalPrice + priceToAdd, 2),
            totalPriceWithoutDiscount: _.round(item.totalPriceWithoutDiscount + priceToAdd, 2),
          };
        }
        if (item.item === 'work') {
          priceToAdd = preiceByFrame;

          return {
            ...item,
            itemTotalPrice: _.round(item.itemTotalPrice + priceToAdd, 2),
            totalPriceWithoutDiscount: _.round(item.totalPriceWithoutDiscount + priceToAdd, 2),
          };
        }

        return item;
      });

      return {
        ...spec,
        specification: {
          ...spec.specification,
          items: specItemsToUpdate,
          totalPrice: _.round(specItemsToUpdate.reduce((total, { itemTotalPrice }) => {
            total += itemTotalPrice;
            return total;
          }, 0), 2),
          retailTotalPrice: _.round(spec.specification.retailTotalPrice + priceToAdd, 2),
        },
      };
    });

    return {
      ...specToUpdate,
      specificationByFrame: updatedSpecByFrame,
      totalPrice: roundedTotalPrice,
      retailTotalPrice: _.round(specToUpdate.retailTotalPrice + priceDifference, 2),
    };
  };

  const specificationByFrame = !_.isEmpty(getSpecificationWithRoundedPrice())
    ? [...getSpecificationWithRoundedPrice().specificationByFrame, ...generalSpecification.filter(({ specification }) => _.isEmpty(specification))]
      .sort((a, b) => a.frameNumber - b.frameNumber)
    : [];

  const specification = !_.isEmpty(getSpecificationWithRoundedPrice())
    ? getSpecificationWithRoundedPrice().specificationByFrame.reduce((acc, spec) => {
      if (spec.frameNumber === 0) return acc;

      acc = acc.concat(spec.specification.items);
      return acc;
    }, [])
    : [];

  const missingItems = !_.isEmpty(getSpecificationWithRoundedPrice())
    ? getSpecificationWithRoundedPrice().specificationByFrame.reduce((acc, spec) => {
      if (spec.frameNumber === 0) return acc;

      acc = acc.concat(spec.specification.missingItems);
      return acc;
    }, [])
    : [];

  const specificationToSend = {
    user: {
      fullName: specificationObject?.user?.fullName || '',
      phoneNumber: specificationObject?.user?.phoneNumber || '',
    },
    ...getSpecificationWithRoundedPrice(),
    missingItems,
    specification,
    specificationByFrame,
  };

  return specificationToSend;
}

export function calculateOrder(state, packageName) {
  const {
    priceList: {
      priceList,
      specification: specificationObject,
    },
    config: {
      brushes,
      filling: fillingConfig,
      systemConctants,
      aluminiumColors,
      assemblyPrices,
    },
    systems: { currentSystem },
    doorsAndSections,
    discounts,
  } = state;

  const {
    main: {
      aluminiumColor,
      sideProfile,
      doorsAmount: { value: doorsAmount },
      doorOpeningHeight: { value: doorOpeningHeight },
      doorOpeningWidth: { value: doorOpeningWidth },
      stopper: { value: stopper },
      doorPositioning,
      mechanism,
      sidewallThickness,
      isX2ProfileOn,
      isMiddleDoorMechanismOn,
      isX4ProfileOn,
      isStopperOn,
    },
    doors,
  } = doorsAndSections;
  const X14 = sidewallThickness?.value || 0;

  const systemDefaults = constantsBySystemType.find((c) => c.systemType === currentSystem) || {};
  const { defaultSideProfile } = systemDefaults;
  const aluminiumColorValue = aluminiumColor?.value || defaultAluminiumColor;
  const sideProfileValue = sideProfile?.value || defaultSideProfile;
  const isSlim = sideProfileValue === 'Slim';
  const spConstants = systemConctants?.filter((c) => c.sideProfile === sideProfileValue)[0] || {};
  const defaultGuidanceProfile = spConstants?.guidanceProfilesDependence
    ? spConstants.guidanceProfilesDependence[0]
    : '';

  const {
    topGap = 0,
    bottomGap = 0,
    topSealing = 0,
    bottomSealing = 0,
    sideSealing = 0,
    hiddingTopSize = 0,
    hiddingBottomSize = 0,
    X5 = 0,
    X12H = 0,
    X13W = 0,
    topProfilesDependence = [],
    bottomProfilesDependence = [],
    guidanceProfilesDependence = [],
    carriageProfilesDependence = [],
    standbyCarriagesProfilesDependence = [],
    defaultStopper = 'stopor_new',
  } = spConstants;

  // TODO: should be translated into user's language
  const aluminiumColorRecord = aluminiumColorValue && aluminiumColors
    ? aluminiumColors[aluminiumColors?.findIndex((c) => c.articleCode === aluminiumColor.value)]
    : '';

  const doorsHeight = doors[0]?.main?.doorHeight;
  const doorsWidth = doors[0]?.main?.doorWidth;
  const doorFillingHeight = isSlim
    ? doorsHeight + 2 - X12H - topSealing - bottomSealing - topGap - bottomGap
    : doorsHeight - X12H - topSealing - bottomSealing - topGap - bottomGap;
  const doorFillingHeightForChipboard = doorsHeight - X12H - topGap - bottomGap;
  const doorFillingWidth = isSlim
    ? doorsWidth + 2 - X13W - sideSealing * 2
    : doorsWidth - X13W - sideSealing * 2;
  const doorFillingWidthForChipboard = doorsWidth - X13W;
  const isDoorAssemblingOn = doors.some((door) => door.main.isDoorAssemblingOn.value);

  // Top, bottom, horizontal profiles

  // * horizontalProfilesSize value. Changes due to ADS specifications
  const horizontalProfilesSizeDown = Math.floor(doorsWidth - X5);
  const horizontalProfilesSizeUp = Math.ceil(doorsWidth - X5);
  const horizontalProfilesSizeRaw = doorsWidth - X5; // * Added for price calculation due to ADS specifications

  const doorsHeightInMeters = doorsHeight / 1000;
  const doorOpeningWidthInMeters = doorOpeningWidth / 1000;

  let guidanceProfileSize = 0;
  let doorPositioningLabel = doorPositioningOptions.find((item) => item.value === doorPositioning.value)?.label;
  if (currentSystem === 'hinged') {
    doorPositioningLabel = doorPositioning.value === 'left-front' ? 'Ліва попереду' : 'Ліва позаду';
  }

  const specification = {
    user: {
      fullName: specificationObject?.user?.fullName || '',
      phoneNumber: specificationObject?.user?.phoneNumber || '',
    },
    description: {
      doorOpeningHeight,
      doorOpeningWidth,
      doorsAmount,
      doorsHeight,
      doorsWidth,
      doorPositioning: doorPositioningLabel,
      aluminiumColor: `${aluminiumColorValue} ${aluminiumColorRecord?.labelUk?.toLowerCase()}`,
    },
    items: [],
    missingItems: [],
    totalPrice: 0,
    retailTotalPrice: 0,
  };

  try {
    /** Бокові або Вертикальні Профілі */

    const spArticleCode = aluminiumColorValue ? `${aluminiumColorValue}-${sideProfileValue}` : sideProfileValue;
    const selectedSideProfileRetail = priceList?.find((sp) => sp.articleCode === spArticleCode
      && sp.packageName === defaultPackageName) || {};
    const selectedSideProfile = priceList?.find((sp) => sp.articleCode === spArticleCode
        && sp.packageName === packageName) || selectedSideProfileRetail;
    const spCost = selectedSideProfile?.price || 0;
    const spCostRetail = selectedSideProfileRetail?.price || 0;

    let spAmount = doorsAmount * 2;

    if (currentSystem === 'hinged') {
      spAmount = doorsAmount === 2 || doorsAmount === 3
        ? doorsAmount + 1
        : doorsAmount === 4
          ? doorsAmount + 2
          : doorsAmount;

      const sp2Amount = doorsAmount === 3 || doorsAmount === 4 ? 2 : 1;

      // Note: 419с is written in Cyrillic
      const sp2ArticleCode = `${aluminiumColorValue}-419с`;
      const verticalProfileRetail = priceList?.find((sp) => sp.articleCode === sp2ArticleCode
        && sp.packageName === defaultPackageName) || {};
      const verticalProfile = priceList?.find((sp) => sp.articleCode === sp2ArticleCode
        && sp.packageName === packageName) || verticalProfileRetail;
      const sp2Cost = verticalProfile?.price || 0;
      const sp2CostRetail = verticalProfileRetail?.price || 0;
      const sp2TotalPrice = _.round(+(sp2Amount * doorsHeightInMeters * sp2Cost), 2);

      if (_.isEmpty(verticalProfile)) {
        console.log(`verticalProfile ${sp2ArticleCode} is not found`);
        specification.missingItems.push({
          item: 'verticalProfile',
          articleCode: sp2ArticleCode,
        });
      } else {
        specification.items.push({
          item: 'verticalProfile',
          amount: sp2Amount,
          size: doorsHeight,
          unitPrice: sp2Cost,
          itemTotalPrice: sp2TotalPrice,
          labelRu: verticalProfile?.labelRu || '',
          labelUk: verticalProfile?.labelUk || '',
          labelPl: verticalProfile?.labelPl || '',
          labelEn: verticalProfile?.labelEn || '',
          articleCode: sp2ArticleCode,
        });
        specification.totalPrice += sp2TotalPrice;
        specification.retailTotalPrice += _.round(+(sp2Amount * doorsHeightInMeters * sp2CostRetail), 2);
      }
    }

    const spTotalPrice = _.round(+(spAmount * doorsHeightInMeters * spCost), 2);

    if (_.isEmpty(selectedSideProfile)) {
      console.log(`sideProfile ${spArticleCode} is not found`);
      specification.missingItems.push({
        item: 'sideProfile',
        articleCode: spArticleCode,
      });
    } else {
      specification.items.push({
        item: 'sideProfile',
        amount: spAmount,
        size: doorsHeight,
        unitPrice: spCost,
        itemTotalPrice: spTotalPrice,
        labelRu: selectedSideProfile?.labelRu || '',
        labelUk: selectedSideProfile?.labelUk || '',
        labelPl: selectedSideProfile?.labelPl || '',
        labelEn: selectedSideProfile?.labelEn || '',
        articleCode: spArticleCode,
      });
      specification.totalPrice += spTotalPrice;
      specification.retailTotalPrice += _.round(+(spAmount * doorsHeightInMeters * spCostRetail), 2);
    }

    /** Верхні Профілі */

    const tpArticleCode = `${aluminiumColorValue}-${topProfilesDependence.length
      ? topProfilesDependence[0] : defaultTopProfile}`;
    const topProfileRetail = priceList?.find((tp) => tp.articleCode === tpArticleCode
      && tp.packageName === defaultPackageName) || {};
    const topProfile = priceList?.find((tp) => tp.articleCode === tpArticleCode && tp.packageName === packageName)
      || topProfileRetail;

    const tpCostRetail = topProfileRetail?.price || 0;
    const tpCost = topProfile?.price || 0;
    const tpAmount = doorsAmount;
    const tpSize = horizontalProfilesSizeRaw; // * Changed from horizontalProfilesSizeUp to horizontalProfilesSizeRaw
    const tpTotalPrice = _.round(+(tpAmount * (horizontalProfilesSizeRaw / 1000) * tpCost), 2);

    if (_.isEmpty(topProfile)) {
      console.log(`topProfile ${tpArticleCode} is not found`);
      specification.missingItems.push({
        item: currentSystem === 'assembling' ? 'bottomProfile' : 'topProfile',
        articleCode: tpArticleCode,
      });
    }

    specification.items.push({
      item: currentSystem === 'assembling' ? 'bottomProfile' : 'topProfile',
      amount: tpAmount,
      size: tpSize,
      unitPrice: tpCost,
      itemTotalPrice: tpTotalPrice,
      labelRu: topProfile?.labelRu || '',
      labelUk: topProfile?.labelUk || '',
      labelPl: topProfile?.labelPl || '',
      labelEn: topProfile?.labelEn || '',
      articleCode: tpArticleCode,
    });
    specification.totalPrice += tpTotalPrice;
    specification.retailTotalPrice += _.round(+(tpAmount * (horizontalProfilesSizeRaw / 1000) * tpCostRetail), 2);

    /** Нижні Профілі */

    const bpCode = `${aluminiumColorValue}-${bottomProfilesDependence.length
      ? bottomProfilesDependence[0] : defaultBottomProfile}`;
    const bpArticleCode = isSlim ? `${bpCode}-Slim` : bpCode;
    const bottomProfileRetail = priceList?.find((bp) => bp.articleCode === bpArticleCode
      && bp.packageName === defaultPackageName) || {};
    const bottomProfile = priceList?.find((bp) => bp.articleCode === bpArticleCode && bp.packageName === packageName)
      || bottomProfileRetail;
    const bpCostRetail = bottomProfileRetail?.price || 0;
    const bpCost = bottomProfile?.price || 0;
    const bpAmount = doorsAmount;
    const bpSize = horizontalProfilesSizeRaw; // * Changed from horizontalProfilesSizeUp to horizontalProfilesSizeRaw
    const bpTotalPrice = _.round(+(bpAmount * (horizontalProfilesSizeRaw / 1000) * bpCost), 2);
    const bpTotalPriceRetail = _.round(+(bpAmount * (horizontalProfilesSizeRaw / 1000) * bpCostRetail), 2);

    if (_.isEmpty(bottomProfile)) {
      console.log(`bottomProfile ${bpArticleCode} is not found`);
      specification.missingItems.push({
        item: 'bottomProfile',
        articleCode: bpArticleCode,
      });
    } else {
      specification.items.push({
        item: 'bottomProfile',
        amount: bpAmount,
        size: bpSize,
        unitPrice: bpCost,
        itemTotalPrice: bpTotalPrice,
        labelRu: bottomProfile?.labelRu || '',
        labelUk: bottomProfile?.labelUk || '',
        labelPl: bottomProfile?.labelPl || '',
        labelEn: bottomProfile?.labelEn || '',
        articleCode: bpArticleCode,
      });
      specification.totalPrice += bpTotalPrice;
      specification.retailTotalPrice += bpTotalPriceRetail;
    }

    /** Направляючі Профілі (X4) */

    if (isX4ProfileOn) {
      let gpArticleCode = `${aluminiumColorValue}-${guidanceProfilesDependence.length
        ? guidanceProfilesDependence[0] : defaultGuidanceProfile}`;
      if (currentSystem === 'hinged') {
        gpArticleCode = guidanceProfilesDependence ? guidanceProfilesDependence[0] : '';
      }
      const guidanceProfileRetail = priceList?.find((gp) => gp.articleCode === gpArticleCode
        && gp.packageName === defaultPackageName) || {};
      const guidanceProfile = priceList?.find((gp) => gp.articleCode === gpArticleCode
        && gp.packageName === packageName) || guidanceProfileRetail;
      const gpCostRetail = guidanceProfileRetail?.price || 0;
      const gpCost = guidanceProfile?.price || 0;
      const gpAmount = 1;
      const gpTotalPrice = _.round(+(gpAmount * doorOpeningWidthInMeters * gpCost), 2);
      guidanceProfileSize = currentSystem === 'hinged'
        ? doorOpeningWidth - X14 * 2
        : isSlim ? doorOpeningWidth - 1 : doorOpeningWidth;

      if (_.isEmpty(guidanceProfile)) {
        console.log(`guidanceProfile ${gpArticleCode} is not found`);
        specification.missingItems.push({
          item: 'guidanceProfile',
          articleCode: gpArticleCode,
        });
      }

      specification.items.push({
        item: 'guidanceProfile',
        amount: gpAmount,
        size: guidanceProfileSize,
        unitPrice: gpCost,
        itemTotalPrice: gpTotalPrice,
        labelRu: guidanceProfile?.labelRu || '',
        labelUk: guidanceProfile?.labelUk || '',
        labelPl: guidanceProfile?.labelPl || '',
        labelEn: guidanceProfile?.labelEn || '',
        articleCode: gpArticleCode,
      });
      specification.totalPrice += gpTotalPrice;
      specification.retailTotalPrice += _.round(+(gpAmount * doorOpeningWidthInMeters * gpCostRetail), 2);
    }

    /**  Ходові Профілі або Резервні Ходові Профілі (X2) */

    if (isX2ProfileOn) {
      const shouldUseStandbyX2 = hasBottomLatchMechanism(doorsAndSections);
      let x2ArticleCode = `${aluminiumColorValue}-${shouldUseStandbyX2
        ? standbyCarriagesProfilesDependence[0] : carriageProfilesDependence[0]}`;
      if (currentSystem === 'assembling') {
        x2ArticleCode = carriageProfilesDependence ? carriageProfilesDependence[0] : '';
      }
      if (currentSystem === 'hinged') {
        x2ArticleCode = `${defaultAluminiumColor}-${shouldUseStandbyX2
          ? standbyCarriagesProfilesDependence[0] : carriageProfilesDependence[0]}`;
      }

      const x2ProfileRetail = priceList?.find((p) => p.articleCode === x2ArticleCode
        && p.packageName === defaultPackageName) || {};
      const x2Profile = priceList?.find((p) => p.articleCode === x2ArticleCode && p.packageName === packageName)
        || x2ProfileRetail;
      const x2CostRetail = x2ProfileRetail?.price || 0;
      const x2Cost = x2Profile?.price || 0;
      const x2Amount = 1;
      const x2TotalPrice = _.round(+(x2Amount * doorOpeningWidthInMeters * x2Cost), 2);
      let x2Size = isSlim ? doorOpeningWidth - 1 : doorOpeningWidth;
      if (currentSystem === 'hinged') x2Size = guidanceProfileSize;

      if (_.isEmpty(x2Profile)) {
        console.log(`x2Profile ${x2ArticleCode} is not found`);
        specification.missingItems.push({
          item: 'x2Profile',
          articleCode: x2ArticleCode,
        });
      }

      specification.items.push({
        item: 'x2Profile',
        amount: x2Amount,
        size: x2Size,
        unitPrice: x2Cost,
        itemTotalPrice: x2TotalPrice,
        labelRu: x2Profile?.labelRu || '',
        labelUk: x2Profile?.labelUk || '',
        labelPl: x2Profile?.labelPl || '',
        labelEn: x2Profile?.labelEn || '',
        articleCode: x2ArticleCode,
      });
      specification.totalPrice += x2TotalPrice;
      specification.retailTotalPrice += _.round(+(x2Amount * doorOpeningWidthInMeters * x2CostRetail), 2);
    }

    /** З'єднувальні Профілі, Монтажні винти для них, Збірка дверей */

    // Монтажні винти додаються по 2 на кожен з'єднувальний профіль для:
    // 1. з'єднувальних профілів 31, 32, 231
    // 2. та для бокового профілю Slim (4шт - без секцій, з секціями - по 2 шт на з'єднувальний профіль)
    // Монтажні винти додаються по 4шт на двері для Складальної системи

    let cpCost = 0;
    let cpCostRetail = 0;
    let cpHorizontalAmount = 0;
    let cpStrengtheningAmount = 0;
    let cpVerticalAmount = 0;
    let assemblyScrewAmount = 0;
    let doorAssemblingAmount = 0;
    let connectingProfile = {}; // Connecting profile is the same for all doors
    let connectingProfileRetail = {};
    let cpArticleCode = '';
    let strainersAmount = 0;

    _.forEach(doors, (door) => {
      const {
        main: {
          connectingProfile: cProfile,
          directionOfSections: { value: directionOfSections },
          sectionsAmount: { value: sectionsAmount },
          isDoorAssemblingOn: { value: isDoorAssemblingOn },
        },
        sections,
      } = door;
      const cProfileValue = cProfile?.value;

      if (isDoorAssemblingOn) doorAssemblingAmount += 1;

      if ((isSlim && !sections.length) || hasOpeningSide(currentSystem)) assemblyScrewAmount += 4;

      if (!sections.length && currentSystem !== 'hinged') return;

      if (sections.length && cProfileValue !== 'invisible') {
        cpArticleCode = cProfileValue === 'Slim 03'
          ? `AS-${cProfileValue}` // use default color for Slim
          : aluminiumColorValue && cProfileValue
            ? `${aluminiumColorValue}-${cProfileValue}`
            : '';

        connectingProfileRetail = priceList?.find((cp) => cp.articleCode === cpArticleCode
          && cp.packageName === defaultPackageName) || {};
        connectingProfile = priceList?.find((cp) => cp.articleCode === cpArticleCode && cp.packageName === packageName)
          || connectingProfileRetail;

        cpCostRetail = connectingProfileRetail?.price || 0;
        cpCost = connectingProfile?.price || 0;

        if (_.isEmpty(connectingProfile)) {
          console.log(`connectingProfile ${cpArticleCode} is not found`);
          specification.missingItems.push({
            item: 'connectingProfile',
            articleCode: cpArticleCode,
          });
        }
      }

      if (sections.length && directionOfSections === 'vertical') cpVerticalAmount += +sectionsAmount - 1;
      if (sections.length && directionOfSections === 'horizontal') cpHorizontalAmount += +sectionsAmount - 1;

      // Навісна: Якщо конкретна дверка не має 403, то по замовчуванні додаємо 2 шт 431 профілю до цієї дверки.
      // Якщо конкретна дверка має хоча б один 403, то 431 не додаємо до цїєї дверки взагалі.

      // якщо конкретна дверка поділена на дві секції 431-ою перемичкою,
      // то потрібно додавати лише 1 шт 431 для цієї дверки,
      // тобто умова "Якщо конкретна дверка не має 403, то по замовчуванні додаємо 2 шт 431 профілю до цієї дверки"
      // не застосовується для цього випадку
      if (currentSystem === 'hinged' && !sections.length) cpStrengtheningAmount += 2;

      if ((cProfileValue === '31' || cProfileValue === '32' || cProfileValue === '231') || isSlim) {
        assemblyScrewAmount += +(sections.length - 1) * 2;
      }
    });

    if (cpVerticalAmount && !_.isEmpty(connectingProfile)) {
      const cpSize = doorsHeight + 2 - X12H - hiddingTopSize - hiddingBottomSize;
      const cpVerticalTotalPrice = _.round(+(cpVerticalAmount * (cpSize / 1000) * cpCost), 2);

      specification.items.push({
        item: 'connectingProfile',
        amount: cpVerticalAmount,
        size: cpSize,
        unitPrice: cpCost,
        itemTotalPrice: cpVerticalTotalPrice,
        labelRu: connectingProfile?.labelRu || '',
        labelUk: connectingProfile?.labelUk || '',
        labelPl: connectingProfile?.labelPl || '',
        labelEn: connectingProfile?.labelEn || '',
        articleCode: cpArticleCode,
      });
      specification.totalPrice += cpVerticalTotalPrice;
      specification.retailTotalPrice += _.round(+(cpVerticalAmount * (cpSize / 1000) * cpCostRetail), 2);
    }

    if (cpHorizontalAmount && !_.isEmpty(connectingProfile)) {
      let cpSize = horizontalProfilesSizeRaw; // * Changed from horizontalProfilesSizeUp to horizontalProfilesSizeRaw
      if (isSlim) cpSize = doorsWidth;

      const cpHorizontalTotalPrice = _.round(+(cpHorizontalAmount * (horizontalProfilesSizeRaw / 1000) * cpCost), 2);

      specification.items.push({
        item: 'connectingProfile',
        amount: cpHorizontalAmount,
        size: cpSize,
        unitPrice: cpCost,
        itemTotalPrice: cpHorizontalTotalPrice,
        labelRu: connectingProfile?.labelRu || '',
        labelUk: connectingProfile?.labelUk || '',
        labelPl: connectingProfile?.labelPl || '',
        labelEn: connectingProfile?.labelEn || '',
        articleCode: cpArticleCode,
      });
      specification.totalPrice += cpHorizontalTotalPrice;
      specification.retailTotalPrice
        += _.round(+(cpHorizontalAmount * (horizontalProfilesSizeRaw / 1000) * cpCostRetail), 2);
    }

    // Підсилюючий 431 профіль
    if (cpStrengtheningAmount) {
      const cpStrengtheningArticleCode = `${aluminiumColorValue}-431`;
      const cpStrengtheningRetail = priceList?.find((cp) => cp.articleCode === cpStrengtheningArticleCode
        && cp.packageName === defaultPackageName) || {};
      const cpStrengthening = priceList?.find((cp) => cp.articleCode === cpStrengtheningArticleCode
        && cp.packageName === packageName) || cpStrengtheningRetail;
      const cpStrengtheningCostRetail = cpStrengtheningRetail?.price || 0;
      const cpStrengtheningCost = cpStrengthening?.price || 0;
      const cpStrengtheningTotalPrice = _.round(+(cpStrengtheningAmount * (horizontalProfilesSizeRaw / 1000) * cpStrengtheningCost), 2);

      specification.items.push({
        item: 'connectingProfile',
        amount: cpStrengtheningAmount,
        size: horizontalProfilesSizeDown,
        unitPrice: cpStrengtheningCost,
        itemTotalPrice: cpStrengtheningTotalPrice,
        labelRu: cpStrengthening?.labelRu || '',
        labelUk: cpStrengthening?.labelUk || '',
        labelPl: cpStrengthening?.labelPl || '',
        labelEn: cpStrengthening?.labelEn || '',
        articleCode: cpStrengtheningArticleCode,
      });
      specification.totalPrice += cpStrengtheningTotalPrice;
      specification.retailTotalPrice += _.round(+(cpStrengtheningAmount * (horizontalProfilesSizeRaw / 1000)
        * cpStrengtheningCostRetail), 2);
    }

    /** Нижні Профілі для Slim (в залежності від наявності / відстутності з'єднувальних профілів) */

    // Додаються 2 додаткових Нижніх Профіля якщо не має поділу на секції
    // Довжина додаткового нижнього профілю === довжині дверей
    // Додаються по 1 додатковому Нижньому Профілю на з'єднувальний профіль якщо є секції

    if (isSlim && !_.isEmpty(bottomProfile)) {
      _.forEach(doors, (door) => {
        const { sections } = door;
        const bpArticleCode = `${aluminiumColorValue}-${bottomProfilesDependence.length
          ? bottomProfilesDependence[0] : defaultBottomProfile}`;
        const bottomProfileRetail = priceList?.find((bp) => bp.articleCode === bpArticleCode
          && bp.packageName === defaultPackageName) || {};
        const bottomProfile = priceList?.find((bp) => bp.articleCode === bpArticleCode && bp.packageName === packageName)
          || bottomProfileRetail;
        const bpCostRetail = bottomProfileRetail?.price || 0;
        const bpCost = bottomProfile?.price || 0;
        const bpAmount = doorsAmount;
        const bpTotalPrice = _.round(+(bpAmount * (horizontalProfilesSizeRaw / 1000) * bpCost), 2);
        const bpTotalPriceRetail = _.round(+(bpAmount * (horizontalProfilesSizeRaw / 1000) * bpCostRetail), 2);
        const bpSizeSlim = horizontalProfilesSizeUp; // * Changed from horizontalProfilesSizeDown to horizontalProfilesSizeUp

        // Нижні Профілі для Slim (є секції)
        if (sections.length) {
          const bpAmountSlim = sections.length - 1;

          specification.items.push({
            item: 'bottomProfile',
            amount: bpAmountSlim,
            size: bpSizeSlim,
            unitPrice: bpCost,
            itemTotalPrice: bpTotalPrice, // TODO: CHECK IF THIS PART WILL STACK WITH OTHER LOGIC (PDF GENERATION FOR EXPAMPLE)
            labelRu: bottomProfile?.labelRu || '',
            labelUk: bottomProfile?.labelUk || '',
            labelPl: bottomProfile?.labelPl || '',
            labelEn: bottomProfile?.labelEn || '',
            articleCode: bpArticleCode,
          });
          specification.totalPrice += bpTotalPrice;
          specification.retailTotalPrice += bpTotalPriceRetail;
        }

        // Нижні Профілі для Slim (без секцій)
        const bpAmountSlim = 2;
        const itemTotalPrice = _.round(+(bpAmountSlim * (horizontalProfilesSizeRaw / 1000) * bpCost), 2);

        specification.items.push({
          item: 'bottomProfile',
          amount: bpAmountSlim,
          size: bpSizeSlim,
          unitPrice: bpCost,
          itemTotalPrice,
          labelRu: bottomProfile?.labelRu || '',
          labelUk: bottomProfile?.labelUk || '',
          labelPl: bottomProfile?.labelPl || '',
          labelEn: bottomProfile?.labelEn || '',
          articleCode: bpArticleCode,
        });
        specification.totalPrice += itemTotalPrice;
        specification.retailTotalPrice += _.round(+(bpAmountSlim * (horizontalProfilesSizeRaw / 1000) * bpCostRetail), 2);
      });
    }

    /** Монтажні винти */

    if (assemblyScrewAmount) {
      const assemblyScrewRetail = priceList?.find((cp) => cp.articleCode === defaultAssemblyScrew
        && cp.packageName === defaultPackageName) || {};
      const assemblyScrew = priceList?.find((cp) => cp.articleCode === defaultAssemblyScrew
          && cp.packageName === packageName) || assemblyScrewRetail;
      const assemblyScrewPrice = assemblyScrew?.price || 0;
      const assemblyScrewPriceRetail = assemblyScrewRetail?.price || 0;
      const assemblyScrewTotalPrice = _.round(+(assemblyScrewPrice * assemblyScrewAmount), 2);

      specification.items.push({
        item: 'assemblyScrew',
        amount: assemblyScrewAmount,
        unitPrice: assemblyScrewPrice,
        itemTotalPrice: assemblyScrewTotalPrice,
        labelRu: assemblyScrew?.labelRu || '',
        labelUk: assemblyScrew?.labelUk || '',
        labelPl: assemblyScrew?.labelPl || '',
        labelEn: assemblyScrew?.labelEn || '',
        articleCode: defaultAssemblyScrew,
      });
      specification.totalPrice += assemblyScrewTotalPrice;
      specification.retailTotalPrice += _.round(+(assemblyScrewPriceRetail * assemblyScrewAmount), 2);
    }

    /** Ущільнювачі для скла, дзеркала та лакобелі. Для Slim не додаються */

    if (!isSlim) {
      // Note: Sealing is always default
      const sealingRetail = priceList?.find((s) => s.articleCode === defaultSealing
        && s.packageName === defaultPackageName) || {};
      const sealing = priceList?.find((s) => s.articleCode === defaultSealing && s.packageName === packageName)
        || sealingRetail;
      const sealingCostRetail = sealingRetail?.price || 0;
      const sealingCost = sealing?.price || 0;
      let sealingSize = 0;

      _.forEach(doors, (door) => {
        const {
          main: { filling: doorFiling },
          sections,
        } = door;
        const isChipboardDoor = isChipboard(doorFiling);

        const doorFillingH = isChipboardDoor
          ? _.round(+(doorFillingHeightForChipboard), 2)
          : _.round(+(doorFillingHeight), 2);
        const doorFillingW = isChipboardDoor
          ? _.round(+(doorFillingWidthForChipboard), 2)
          : _.round(+(doorFillingWidth), 2);

        // Sealing for door sections
        if (sections.length) {
          _.forEach(sections, (section) => {
            const {
              filling,
              fillingHeight: { value: fillingHeight = 1 },
              fillingWidth: { value: fillingWidth = 1 },
            } = section;

            if (_.isEmpty(filling)
              || !(filling?.material === 'mirror'
                || filling?.material === 'glass'
                || filling?.material === 'lacobel'
                || filling?.customersOption === 'glass')
            ) return;
            sealingSize += fillingHeight * 2 + fillingWidth * 2;
          });
        } else {
          // Sealing for the door without sections

          if (_.isEmpty(doorFiling)
            || !(doorFiling?.material === 'mirror'
              || doorFiling?.material === 'glass'
              || doorFiling?.material === 'lacobel'
              || doorFiling?.customersOption === 'glass')
          ) return;
          sealingSize += doorFillingH * 2 + doorFillingW * 2;
        }
      });

      if (sealingSize) {
        const sealingTotalPrice = _.round(+((sealingSize / 1000) * sealingCost), 2);

        specification.items.push({
          item: 'sealing',
          amount: 1,
          size: sealingSize,
          unitPrice: sealingCost,
          itemTotalPrice: sealingTotalPrice,
          labelRu: sealing?.labelRu || '',
          labelUk: sealing?.labelUk || '',
          labelPl: sealing?.labelPl || '',
          labelEn: sealing?.labelEn || '',
          articleCode: defaultSealing,
        });
        specification.totalPrice += sealingTotalPrice;
        specification.retailTotalPrice += _.round(+((sealingSize / 1000) * sealingCostRetail), 2);
      }
    }

    /** Щітка */

    if (currentSystem !== 'hinged') {
      const brushAmount = 1;
      const brush = brushes.find((br) => br.aluminiumColor === aluminiumColorValue
        && br.sideProfile === sideProfileValue);
      const brushArticleCode = brush?.articleCode || '';
      const brushFromPriceListRetail = priceList?.find((br) => br.articleCode === brushArticleCode
        && br.packageName === defaultPackageName) || {};
      const brushFromPriceList = priceList?.find((br) => br.articleCode === brushArticleCode
        && br.packageName === packageName) || brushFromPriceListRetail;
      const brushCost = +brushFromPriceList?.price || 0;
      const brushCostRetail = +brushFromPriceListRetail?.price || 0;
      const brushSize = spAmount * doorsHeight;
      const brushTotalPrice = _.round(+(brushAmount * (brushSize / 1000) * brushCost), 2);

      if (!brushArticleCode) {
        console.log(`brush ${brushArticleCode} is not found`);
        specification.missingItems.push({
          item: 'brush',
          articleCode: brushArticleCode,
        });
      }

      specification.items.push({
        item: 'brush',
        amount: brushAmount,
        size: brushSize,
        unitPrice: brushCost,
        itemTotalPrice: brushTotalPrice,
        labelRu: brushFromPriceList?.labelRu,
        labelUk: brushFromPriceList?.labelUk,
        labelPl: brushFromPriceList?.labelPl,
        labelEn: brushFromPriceList?.labelEn,
        articleCode: brushArticleCode,
      });
      specification.totalPrice += brushTotalPrice;
      specification.retailTotalPrice += _.round(+(brushAmount * (brushSize / 1000) * brushCostRetail), 2);
    }

    /** Стяжки */

    if (currentSystem === 'hinged') {
      const strainerFromPriceListRetail = priceList?.find((item) => item.articleCode === defaultStrainer
        && item.packageName === defaultPackageName) || {};
      const strainerFromPriceList = priceList?.find((item) => item.articleCode === defaultStrainer
        && item.packageName === packageName) || strainerFromPriceListRetail;
      const strainerCostRetail = +strainerFromPriceListRetail?.price || 0;
      const strainerCost = +strainerFromPriceList?.price || 0;

      if (!strainerFromPriceList) {
        console.log(`strainer ${defaultStrainer} is not found`);
        specification.missingItems.push({
          item: 'strainer',
          articleCode: defaultStrainer,
        });
        // return; // FIXME: uncomment this line if order items still empty
      }

      _.forEach(doors, (door) => {
        const { sections, main } = door;
        const cpValue = main?.connectingProfile?.value;
        strainersAmount += 6; // 4 for top profile and 2 for bottom profile

        // якщо є 403 зєднувальний профіль, то 2 стяжки до профілю
        // якщо є 431 підсилюючий профіль, то 2 стяжки до профілю
        if (sections.length && (cpValue === '403' || cpValue === '431')) {
          strainersAmount += (sections.length - 1) * 2; // per 2 for connecting profile
        }
      });

      // якщо є 431 підсилюючий профіль, то 2 стяжки до профілю
      if (cpStrengtheningAmount) strainersAmount += cpStrengtheningAmount * 2;

      const strainersTotalPrice = _.round(+(strainersAmount * strainerCost), 2);

      specification.items.push({
        item: 'strainer',
        amount: strainersAmount,
        size: 0,
        unitPrice: strainerCost,
        itemTotalPrice: strainersTotalPrice,
        labelRu: strainerFromPriceList?.labelRu,
        labelUk: strainerFromPriceList?.labelUk,
        labelPl: strainerFromPriceList?.labelPl,
        labelEn: strainerFromPriceList?.labelEn,
        articleCode: defaultStrainer,
      });
      specification.totalPrice += strainersTotalPrice;
      specification.retailTotalPrice += _.round(+(strainersAmount * strainerCostRetail), 2);
    }

    /** Механізми (Кріплення) */

    const mechanismArticleCode = mechanism?.value;
    const aMechanismRetail = priceList?.find((m) => m.articleCode === mechanismArticleCode
      && m.packageName === defaultPackageName) || {};
    const mechanismCostRetail = aMechanismRetail?.price || 0;
    const aMechanism = priceList?.find((m) => m.articleCode === mechanismArticleCode && m.packageName === packageName)
      || aMechanismRetail;
    const mechanismCost = aMechanism?.price || 0;

    let mechanismAmount = doorsAmount;
    if (currentSystem === 'assembling') mechanismAmount = doorsAmount / 2;
    if (currentSystem === 'hinged') mechanismAmount = doorsAmount === 4 ? 2 : 1;

    const mechanismTotalPrice = _.round(+(mechanismCost * mechanismAmount), 2);

    if (_.isEmpty(aMechanism)) {
      console.log(`mechanism ${mechanismArticleCode} is not found`);
      specification.missingItems.push({
        item: 'mechanism',
        articleCode: mechanismArticleCode,
      });
    }

    if (!_.isEmpty(aMechanism)) {
      specification.items.push({
        item: 'mechanism',
        amount: mechanismAmount,
        size: 0,
        unitPrice: mechanismCost,
        itemTotalPrice: mechanismTotalPrice,
        labelRu: aMechanism?.labelRu || '',
        labelUk: aMechanism?.labelUk || '',
        labelPl: aMechanism?.labelPl || '',
        labelEn: aMechanism?.labelEn || '',
        articleCode: mechanismArticleCode,
      });
      specification.totalPrice += mechanismTotalPrice;
      specification.retailTotalPrice += _.round(+(mechanismCostRetail * mechanismAmount), 2);
    }

    // Additional mechanisms for assembling system
    if (currentSystem === 'assembling') {
      const additionalMechanismRetail = priceList?.find((m) => m.articleCode === assemblingAdditionalMechanism
        && m.packageName === defaultPackageName) || {};
      const additionalMechanism = priceList?.find((m) => m.articleCode === assemblingAdditionalMechanism
        && m.packageName === packageName) || additionalMechanismRetail;
      const additionalMechanismCost = additionalMechanism?.price || 0;
      const additionalMechanismCostRetail = additionalMechanismRetail?.price || 0;
      const additionalMechanismTotalPrice = _.round(+(additionalMechanismCost * mechanismAmount), 2);

      if (_.isEmpty(additionalMechanism)) {
        console.log(`mechanism ${assemblingAdditionalMechanism} is not found`);
        specification.missingItems.push({
          item: 'mechanism',
          articleCode: assemblingAdditionalMechanism,
        });
      }

      if (!_.isEmpty(additionalMechanism)) {
        specification.items.push({
          item: 'mechanism',
          amount: mechanismAmount,
          size: 0,
          unitPrice: additionalMechanismCost,
          itemTotalPrice: additionalMechanismTotalPrice,
          labelRu: additionalMechanism?.labelRu || '',
          labelUk: additionalMechanism?.labelUk || '',
          labelPl: additionalMechanism?.labelPl || '',
          labelEn: additionalMechanism?.labelEn || '',
          articleCode: assemblingAdditionalMechanism,
        });
        specification.totalPrice += additionalMechanismTotalPrice;
        specification.retailTotalPrice += _.round(+(additionalMechanismCostRetail * mechanismAmount), 2);
      }
    }

    if (currentSystem === 'hinged' && isMiddleDoorMechanismOn) {
      const middleMechanismRetail = priceList?.find((m) => m.articleCode === hingedMiddleDoorMechanism
        && m.packageName === defaultPackageName) || {};
      const middleMechanism = priceList?.find((m) => m.articleCode === hingedMiddleDoorMechanism
        && m.packageName === packageName) || middleMechanismRetail;
      const hingedMiddleDoorMechanismCost = middleMechanism?.price ? _.round(+(middleMechanism.price), 2) : 0;
      const hingedMechanismCostRetail = middleMechanism?.price ? _.round(+(middleMechanism.price), 2) : 0;

      if (!_.isEmpty(middleMechanism)) {
        specification.items.push({
          item: 'mechanism',
          amount: 1,
          size: 0,
          unitPrice: hingedMiddleDoorMechanismCost,
          itemTotalPrice: hingedMiddleDoorMechanismCost,
          labelRu: middleMechanism?.labelRu || '',
          labelUk: middleMechanism?.labelUk || '',
          labelPl: middleMechanism?.labelPl || '',
          labelEn: middleMechanism?.labelEn || '',
          articleCode: hingedMiddleDoorMechanism,
        });
        specification.totalPrice += hingedMiddleDoorMechanismCost;
        specification.retailTotalPrice += hingedMechanismCostRetail;
      }
    }

    /** Дотяги дверей */

    let topLatchMechanismAmount = 0;
    let bottomLatchMechanismAmount = 0;

    const topDoorLatchMechanismRetail = priceList?.find((m) => m.articleCode === topDoorLatchMechanism
      && m.packageName === defaultPackageName) || {};
    const topLatchMechanismCostRetail = topDoorLatchMechanismRetail?.price || 0;

    const topDoorLatchMechanismDoc = priceList?.find((m) => m.articleCode === topDoorLatchMechanism
        && m.packageName === packageName) || topDoorLatchMechanismRetail;
    const topLatchMechanismCost = topDoorLatchMechanismDoc?.price || 0;

    const bottomDoorLatchMechanismRetail = priceList?.find((m) => m.articleCode === bottomDoorLatchMechanism
      && m.packageName === defaultPackageName) || {};
    const bottomLatchMechanismCostRetail = bottomDoorLatchMechanismRetail?.price || 0;

    const bottomDoorLatchMechanismDoc = priceList?.find((m) => m.articleCode === bottomDoorLatchMechanism
        && m.packageName === packageName) || bottomDoorLatchMechanismRetail;
    const bottomLatchMechanismCost = bottomDoorLatchMechanismDoc?.price || 0;

    _.forEach(doors, (door) => {
      const {
        main: {
          doorLatchMechanism,
          doorLatchMechanismPosition: latchMechanismPosition,
          isDoorLatchMechanismOn,
        },
      } = door;

      const doorLatchMechanismArticleCode = isDoorLatchMechanismOn?.value ? doorLatchMechanism?.value : '';
      const doorLatchMechanismPosition = latchMechanismPosition?.value || '';

      if (doorLatchMechanismArticleCode === topDoorLatchMechanism) {
        topLatchMechanismAmount += doorLatchMechanismPosition === 'both-sides' ? 2 : 1;
      }
      if (doorLatchMechanismArticleCode === bottomDoorLatchMechanism) {
        bottomLatchMechanismAmount += doorLatchMechanismPosition === 'both-sides' ? 2 : 1;
      }
    });

    if (topLatchMechanismAmount) {
      const topLatchMechanismTotalPrice = _.round(+(topLatchMechanismAmount * topLatchMechanismCost), 2);

      specification.items.push({
        item: 'doorLatchMechanism',
        amount: topLatchMechanismAmount,
        size: 0,
        unitPrice: topLatchMechanismCost,
        itemTotalPrice: topLatchMechanismTotalPrice,
        labelRu: topDoorLatchMechanismDoc?.labelRu || '',
        labelUk: topDoorLatchMechanismDoc?.labelUk || '',
        labelPl: topDoorLatchMechanismDoc?.labelPl || '',
        labelEn: topDoorLatchMechanismDoc?.labelEn || '',
        articleCode: topDoorLatchMechanism,
      });
      specification.totalPrice += topLatchMechanismTotalPrice;
      specification.retailTotalPrice += _.round(+(topLatchMechanismAmount * topLatchMechanismCostRetail), 2);
    }

    if (bottomLatchMechanismAmount) {
      const bottomLatchMechanismTotalPrice = _.round(+(bottomLatchMechanismAmount * bottomLatchMechanismCost), 2);

      specification.items.push({
        item: 'doorLatchMechanism',
        amount: bottomLatchMechanismAmount,
        size: 0,
        unitPrice: bottomLatchMechanismCost,
        itemTotalPrice: bottomLatchMechanismTotalPrice,
        labelRu: bottomDoorLatchMechanismDoc?.labelRu || '',
        labelUk: bottomDoorLatchMechanismDoc?.labelUk || '',
        labelPl: bottomDoorLatchMechanismDoc?.labelPl || '',
        labelEn: bottomDoorLatchMechanismDoc?.labelEn || '',
        articleCode: bottomDoorLatchMechanism,
      });
      specification.totalPrice += bottomLatchMechanismTotalPrice;
      specification.retailTotalPrice += _.round(+(bottomLatchMechanismAmount * bottomLatchMechanismCostRetail), 2);
    }

    /** Стопор */

    if ((currentSystem === 'extendable' || currentSystem === 'monorail') && isStopperOn) {
      const stopperToAdd = stopper || defaultStopper;
      const stopperRetail = priceList?.find((s) => s.articleCode === stopperToAdd
        && s.packageName === defaultPackageName) || {};
      const stopperDoc = priceList?.find((s) => s.articleCode === stopperToAdd && s.packageName === packageName)
        || stopperRetail;
      const stopperCost = +stopperDoc?.price || 0;
      const stopperCostRetail = +stopperRetail?.price || 0;
      const stopperTotalPrice = _.round(+(doorsAmount * stopperCost), 2);

      specification.items.push({
        item: 'stopper',
        amount: doorsAmount,
        size: 0,
        unitPrice: stopperCost,
        itemTotalPrice: stopperTotalPrice,
        labelRu: stopperDoc?.labelRu || '',
        labelUk: stopperDoc?.labelUk || '',
        labelPl: stopperDoc?.labelPl || '',
        labelEn: stopperDoc?.labelEn || '',
        articleCode: stopperToAdd,
      });
      specification.totalPrice += stopperTotalPrice;
      specification.retailTotalPrice += _.round(+(doorsAmount * stopperCostRetail), 2);
    }

    /** Заглушка до нижньої направляючої */

    if (currentSystem === 'extendable' && !_.some(['219', '119-L', '119-v.p.'], (sp) => sp === sideProfileValue)) {
      const bungArticleCode = `${aluminiumColorValue}-108-1`;
      const bungAmount = 1;
      const bungRetail = priceList?.find((s) => s.articleCode === bungArticleCode && s.packageName === defaultPackageName)
        || {};
      const bungDoc = priceList?.find((s) => s.articleCode === bungArticleCode && s.packageName === packageName)
        || bungRetail;
      const bungCost = +bungDoc?.price || 0;
      const bungCostRetail = +bungRetail?.price || 0;
      const bungTotalPrice = _.round(+(bungCost * (guidanceProfileSize / 1000) * bungAmount), 2);

      if (!_.isEmpty(bungDoc)) {
        specification.items.push({
          item: 'bung',
          amount: bungAmount,
          size: guidanceProfileSize,
          unitPrice: bungCost,
          itemTotalPrice: bungTotalPrice,
          labelRu: bungDoc?.labelRu || '',
          labelUk: bungDoc?.labelUk || '',
          labelPl: bungDoc?.labelPl || '',
          labelEn: bungDoc?.labelEn || '',
          articleCode: bungArticleCode,
        });
        specification.totalPrice += bungTotalPrice;
        specification.retailTotalPrice += _.round(+(bungCostRetail * (guidanceProfileSize / 1000) * bungAmount), 2);
      }
    }

    /** Робота */
    const cpVerticalAssembling = cpVerticalAmount * assemblyPrices.vertical;
    const cpHorizontalAssembling = cpHorizontalAmount * assemblyPrices.horizontal;

    let workArticle = null;
    let assemblingPricePerDoor = 0;

    if (['extendable', 'monorail'].includes(currentSystem) && sideProfileValue === 'Slim') {
      workArticle = 'work_slim';
    } else if (['extendable', 'monorail'].includes(currentSystem) && ['207', '220'].includes(sideProfileValue)) {
      workArticle = 'work_Ultra';
    } else if (currentSystem === 'hinged') {
      workArticle = 'work_upline';
    } else if (['extendable', 'monorail', 'opening', 'assembling'].includes(currentSystem)
      && packageName === defaultPackageName) {
      workArticle = 'work_perDoorRetail';
    }

    if (workArticle) {
      if (workArticle === 'work_perDoorRetail') {
        assemblingPricePerDoor = assemblyPrices.perDoorRetail;
      } else {
        const workRetail = priceList?.find((w) => w.articleCode === workArticle
        && w.packageName === defaultPackageName) || {};
        const workCost = priceList?.find((w) => w.articleCode === workArticle && w.packageName === packageName)
          || workRetail;
        assemblingPricePerDoor = +workCost?.price || 0;
      }
    }

    const doorAssemblingTotal = _.round(+(assemblingPricePerDoor * doorAssemblingAmount), 2);
    const workSummary = _.round(+(doorAssemblingTotal + cpVerticalAssembling + cpHorizontalAssembling), 2);

    specification.items.push({
      item: 'work',
      amount: 1,
      size: 0,
      unitPrice: workSummary,
      itemTotalPrice: workSummary,
      labelRu: 'Сборка',
      labelUk: 'Збірка',
      labelPl: 'Montaż',
      labelEn: 'Assembly',
      articleCode: '',
    });
    specification.totalPrice += workSummary;
    specification.retailTotalPrice += workSummary || _.round(assemblyPrices.perDoorRetail * doorAssemblingAmount, 2);

    /** Наповнення та Послуги до Наповнення  */

    let millingSize = 0;

    _.forEach(doors, (door, doorIndex) => {
      const {
        main: { filling: doorFiling, texture: doorTexture },
        sections,
      } = door;

      const isChipboardDoor = isChipboard(doorFiling);

      const hasPlanishingDoor = isSlim
        && (doorFiling?.material === 'mirror' || doorFiling?.material === 'glass'
        || doorFiling?.material === 'lacobel');

      /**
       * Calculate door filling without sections
       */

      if (!sections.length) {
        if (_.isEmpty(doorFiling) || !doorFiling.material) return;

        const fillingArticleCode = doorFiling.mirrorType || doorFiling.lacobelType
          || doorFiling.glassType || doorFiling.dspOption;
        const fillingItemWithPriceRetail = priceList?.find((f) => f.articleCode === fillingArticleCode
          && f.packageName === defaultPackageName) || {};
        const fillingItemWithPrice = priceList?.find((f) => f.articleCode === fillingArticleCode
          && f.packageName === packageName) || fillingItemWithPriceRetail;
        const fillingItem = fillingConfig?.find((m) => m.articleCode === fillingArticleCode) || {};
        const doorFillingH = isChipboardDoor
          ? _.round(+(doorFillingHeightForChipboard), 2)
          : _.round(+(doorFillingHeight), 2);
        const doorFillingW = isChipboardDoor
          ? _.round(+(doorFillingWidthForChipboard), 2)
          : _.round(+(doorFillingWidth), 2);
        const doorArea = _.round(+(doorFillingW * doorFillingH), 2);
        const textureD = isFillingHasTexture(fillingConfig, fillingArticleCode) ? doorTexture?.value : '';

        // Calculate milling size for our chipboard > 10mm or for custom chipboard
        const chipboardThickness = fillingItem?.chipboardThickness
          ? +(fillingItem.chipboardThickness)?.match(/([0-9]+[, .]?)+/)[0]
          : 0;
        if ((doorFiling?.dspOption && +chipboardThickness > 10)
          || (doorFiling?.customersOption === 'dsp-large' && doorFiling?.isMilling)) {
          const chipboardPerimeter = _.round(+(doorFillingWidthForChipboard * 2 + doorFillingHeightForChipboard * 2), 2);
          millingSize += chipboardPerimeter;
        }

        /** Filling Item */

        // Custom filling
        if (doorFiling?.material === 'customers') {
          const isGlass = doorFiling.customersOption === 'glass';
          const isLargeChipboard = doorFiling.customersOption === 'dsp-large';

          const labelRu = isGlass ? 'Стекло' : isLargeChipboard ? 'ДСП 10+мм' : 'ДСП 10мм';
          const labelUk = isGlass ? 'Скло' : isLargeChipboard ? 'ДСП 10+мм' : 'ДСП 10мм';
          const labelPl = isGlass ? 'Szkło' : isLargeChipboard ? 'DSP 10+mm' : 'DSP 10mm';
          const labelEn = isGlass ? 'Glass' : isLargeChipboard ? 'DSP 10+mm' : 'DSP 10mm';

          // Should be shown in specification (with no price)
          specification.items.push({
            item: 'filling',
            amount: 1,
            size: doorArea,
            height: doorFillingH,
            width: doorFillingW,
            labelRu: `Материал заказчика: ${labelRu}`,
            labelUk: `Матеріал замовника: ${labelUk}`,
            labelPl: `Materiał klienta: ${labelPl}`,
            labelEn: `Customer material: ${labelEn}`,
            articleCode: isGlass ? 'customGlass' : (isLargeChipboard ? 'customDspPlus' : 'customDsp'),
            unitPrice: 0,
            itemTotalPrice: 0,
            position: { doorIndex },
            texture: '',
          });
          return;
        }

        const doorFilingItemUnitPriceRetail = fillingItemWithPriceRetail?.price || 0;
        const doorFilingItemUnitPrice = fillingItemWithPrice?.price || 0;
        const doorFilingItemTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * doorFilingItemUnitPrice),
          2);

        specification.items.push({
          item: 'filling',
          amount: 1,
          size: doorArea,
          height: doorFillingH,
          width: doorFillingW,
          unitPrice: doorFilingItemUnitPrice,
          itemTotalPrice: doorFilingItemTotalPrice,
          labelRu: fillingItem?.labelRu,
          labelUk: fillingItem?.labelUk,
          labelPl: fillingItem?.labelPl,
          labelEn: fillingItem?.labelEn,
          articleCode: fillingArticleCode,
          position: { doorIndex },
          texture: textureD,
        });
        specification.totalPrice += doorFilingItemTotalPrice;
        specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
          * doorFilingItemUnitPriceRetail), 2);

        /** Additional Supplements */

        /** Ультрафіолетовий друк - ДСП */

        if (doorFiling?.isDspUVPrinting) {
          const chipboardUvPrintArticleCode = doorFiling?.dspUvPrintType?.startsWith('print_uv_wcb')
            ? 'print_uv_wcb'
            : doorFiling?.dspUvPrintType;
          const chipboardLayersAmount = chipboardUvPrintArticleCode === 'print_uv_wcb'
            && doorFiling?.dspUvPrintType?.length > chipboardUvPrintArticleCode.length
            ? doorFiling.dspUvPrintType.substr(doorFiling.dspUvPrintType.length - 1)
            : 1;
          const chipboardUvPrintTypeRetail = priceList?.find((c) => c.articleCode === chipboardUvPrintArticleCode
            && c.packageName === defaultPackageName) || {};
          const chipboardUvPrintType = priceList?.find((c) => c.articleCode === chipboardUvPrintArticleCode
            && c.packageName === packageName) || chipboardUvPrintTypeRetail;

          if (!_.isEmpty(chipboardUvPrintType)) {
            const chipboardUvPrintUnitPriceRetail = chipboardUvPrintTypeRetail?.price || 0;
            const chipboardUvPrintUnitPrice = chipboardUvPrintType?.price || 0;
            const chipboardUvPrintTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * chipboardLayersAmount * chipboardUvPrintUnitPrice), 2);
            const chipboardUvPrintTotalPriceRetail = _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * chipboardLayersAmount * chipboardUvPrintUnitPriceRetail), 2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: chipboardUvPrintUnitPrice,
              itemTotalPrice: chipboardUvPrintTotalPrice,
              labelRu: `${chipboardUvPrintType?.labelRu} ${chipboardLayersAmount} шаров`,
              labelUk: `${chipboardUvPrintType?.labelUk} ${chipboardLayersAmount} шарів`,
              labelPl: `${chipboardUvPrintType?.labelPl} ${chipboardLayersAmount} warstwy`,
              labelEn: `${chipboardUvPrintType?.labelEn} ${chipboardLayersAmount} layers`,
              articleCode: chipboardUvPrintArticleCode,
              position: { doorIndex },
            });
            specification.totalPrice += chipboardUvPrintTotalPrice;
            specification.retailTotalPrice += chipboardUvPrintTotalPriceRetail;
          } else {
            console.log(`Error: dspUvPrintType ${chipboardUvPrintArticleCode} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: chipboardUvPrintArticleCode,
            });
          }
        }

        /** Ультрафіолетовий друк - Дзеркало */

        if (doorFiling?.isMirrorUVPrinting) {
          const mirrorUvPrintTypeRetail = priceList?.find((m) => m.articleCode === doorFiling?.mirrorUvPrintType
            && m.packageName === defaultPackageName) || {};

          const mirrorUvPrintType = priceList?.find((m) => m.articleCode === doorFiling?.mirrorUvPrintType
            && m.packageName === packageName) || mirrorUvPrintTypeRetail;

          if (!_.isEmpty(mirrorUvPrintType)) {
            const mirrorUvPrintUnitPriceRetail = mirrorUvPrintTypeRetail?.price || 0;
            const mirrorUvPrintUnitPrice = mirrorUvPrintType?.price || 0;
            const mirrorUvPrintTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * mirrorUvPrintUnitPrice),
              2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: mirrorUvPrintUnitPrice,
              itemTotalPrice: mirrorUvPrintTotalPrice,
              labelRu: mirrorUvPrintType?.labelRu,
              labelUk: mirrorUvPrintType?.labelUk,
              labelPl: mirrorUvPrintType?.labelPl,
              labelEn: mirrorUvPrintType?.labelEn,
              articleCode: doorFiling?.mirrorUvPrintType,
              position: { doorIndex },
            });
            specification.totalPrice += mirrorUvPrintTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * mirrorUvPrintUnitPriceRetail), 2);
          } else {
            console.log(`Error: mirrorUvPrintType ${doorFiling?.mirrorUvPrintType} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: doorFiling?.mirrorUvPrintType,
            });
          }
        }

        /** Ультрафіолетовий друк - Скло */

        if (doorFiling?.isGlassUVPrinting) {
          const glassUvPrintTypeRetail = priceList?.find((g) => g.articleCode === doorFiling?.glassUvPrintType
              && g.packageName === defaultPackageName) || {};
          const glassUvPrintType = priceList?.find((g) => g.articleCode === doorFiling?.glassUvPrintType
            && g.packageName === packageName) || glassUvPrintTypeRetail;
          const glassUvPrintUnitPriceRetail = glassUvPrintTypeRetail?.price || 0;
          const glassUvPrintUnitPrice = glassUvPrintType?.price || 0;
          const glassUvPrintTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * glassUvPrintUnitPrice),
            2);

          if (!_.isEmpty(glassUvPrintType)) {
            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: glassUvPrintUnitPrice,
              itemTotalPrice: glassUvPrintTotalPrice,
              labelRu: glassUvPrintType?.labelRu,
              labelUk: glassUvPrintType?.labelUk,
              labelPl: glassUvPrintType?.labelPl,
              labelEn: glassUvPrintType?.labelEn,
              articleCode: doorFiling?.glassUvPrintType,
              position: { doorIndex },
            });
            specification.totalPrice += glassUvPrintTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * glassUvPrintUnitPriceRetail), 2);
          } else {
            console.log(`Error: glassUvPrintType ${doorFiling?.glassUvPrintType} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: doorFiling?.glassUvPrintType,
            });
          }
        }

        /** Ультрафіолетовий друк - Лакобель */

        if (doorFiling?.isLacobelUVPrinting) {
          const lacobelUvPrintTypeRetail = priceList?.find((l) => l.articleCode === doorFiling?.lacobelUvPrintType
            && l.packageName === defaultPackageName) || {};
          const lacobelUvPrintType = priceList?.find((l) => l.articleCode === doorFiling?.lacobelUvPrintType
            && l.packageName === packageName) || lacobelUvPrintTypeRetail;
          const lacobelUvPrintUnitPriceRetail = lacobelUvPrintTypeRetail?.price || 0;
          const lacobelUvPrintUnitPrice = lacobelUvPrintType?.price || 0;
          const lacobelUvPrintTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * lacobelUvPrintUnitPrice), 2);

          if (!_.isEmpty(lacobelUvPrintType)) {
            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: lacobelUvPrintUnitPrice,
              itemTotalPrice: lacobelUvPrintTotalPrice,
              labelRu: lacobelUvPrintType?.labelRu,
              labelUk: lacobelUvPrintType?.labelUk,
              labelPl: lacobelUvPrintType?.labelPl,
              labelEn: lacobelUvPrintType?.labelEn,
              articleCode: doorFiling?.lacobelUvPrintType,
              position: { doorIndex },
            });
            specification.totalPrice += lacobelUvPrintTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * lacobelUvPrintUnitPriceRetail), 2);
          } else {
            console.log(`Error: lacobelUvPrintType ${doorFiling?.lacobelUvPrintType} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: doorFiling?.lacobelUvPrintType,
            });
          }
        }

        /** Матування по трафарету */

        if (doorFiling?.isMirrorMatted || doorFiling?.isGlassMatted || doorFiling?.isLacobelMatted) {
          const mattedArticleCode = 'sten_mat';
          const mattedItemRetail = priceList?.find((m) => m.articleCode === mattedArticleCode
            && m.packageName === defaultPackageName) || {};
          const mattedItem = priceList?.find((m) => m.articleCode === mattedArticleCode
            && m.packageName === packageName) || mattedItemRetail;

          if (_.isEmpty(mattedItem)) {
            console.log(`Error: ${mattedArticleCode} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: mattedArticleCode,
            });
            return;
          }

          const mattedItemUnitPrice = mattedItem.price || 0;
          const mattedItemUnitPriceRetail = mattedItemRetail.price || 0;
          const mattedItemTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * mattedItemUnitPrice), 2);

          specification.items.push({
            item: 'fillingFeature',
            amount: 1,
            size: doorArea,
            height: doorFillingH,
            width: doorFillingW,
            unitPrice: mattedItemUnitPrice,
            itemTotalPrice: mattedItemTotalPrice,
            labelRu: mattedItem?.labelRu,
            labelUk: mattedItem?.labelUk,
            labelPl: mattedItem?.labelPl,
            labelEn: mattedItem?.labelEn,
            articleCode: mattedArticleCode,
            position: { doorIndex },
          });
          specification.totalPrice += mattedItemTotalPrice;
          specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
            * mattedItemUnitPriceRetail), 2);
        }

        /** Тильне матування */

        if (doorFiling?.isMirrorRearMatted || doorFiling?.isLacobelRearMatted) {
          const rearMattingArticleCode = 'rear_matt';
          const rearMattedItemRetail = priceList?.find((r) => r.articleCode === rearMattingArticleCode
            && r.packageName === defaultPackageName) || {};
          const rearMattedItem = priceList?.find((r) => r.articleCode === rearMattingArticleCode
            && r.packageName === packageName) || rearMattedItemRetail;

          if (!_.isEmpty(rearMattedItem)) {
            const rearMattingUnitPriceRetail = rearMattedItemRetail.price || 0;
            const rearMattingUnitPrice = rearMattedItem.price || 0;
            const rearMattingTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * rearMattingUnitPrice), 2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: rearMattingUnitPrice,
              itemTotalPrice: rearMattingTotalPrice,
              labelRu: rearMattedItem?.labelRu,
              labelUk: rearMattedItem?.labelUk,
              labelPl: rearMattedItem?.labelPl,
              labelEn: rearMattedItem?.labelEn,
              articleCode: rearMattingArticleCode,
              position: { doorIndex },
            });
            specification.totalPrice += rearMattingTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * rearMattingUnitPriceRetail), 2);
          } else {
            console.log(`Error: ${rearMattingArticleCode} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: rearMattingArticleCode,
            });
          }
        }

        /** Повне матування */

        if (doorFiling?.isMirrorFullMatted || doorFiling?.isLacobelFullMatted || doorFiling?.isGlassFullMatted) {
          const fullMattingArticleCode = 'matt_compl';
          const fullMattingItemRetail = priceList?.find((m) => m.articleCode === fullMattingArticleCode
            && m.packageName === defaultPackageName) || {};
          const fullMattingItem = priceList?.find((m) => m.articleCode === fullMattingArticleCode
            && m.packageName === packageName) || fullMattingItemRetail;

          if (!_.isEmpty(fullMattingItem)) {
            const fullMattingUnitPriceRetail = fullMattingItemRetail.price || 0;
            const fullMattingUnitPrice = fullMattingItem.price || 0;
            const fullMattingTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * fullMattingUnitPrice),
              2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: fullMattingUnitPrice,
              itemTotalPrice: fullMattingTotalPrice,
              labelRu: fullMattingItem?.labelRu,
              labelUk: fullMattingItem?.labelUk,
              labelPl: fullMattingItem?.labelPl,
              labelEn: fullMattingItem?.labelEn,
              articleCode: fullMattingArticleCode,
              position: { doorIndex },
            });
            specification.totalPrice += fullMattingTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2) * fullMattingUnitPriceRetail), 2);
          } else {
            console.log(`Error: ${fullMattingArticleCode} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: fullMattingArticleCode,
            });
          }
        }

        /** Дзеркало RAL */

        if (doorFiling?.isMirrorRearMatted && doorFiling?.mirrorColor) {
          const RALDocRetail = priceList?.find((r) => r.articleCode === doorFiling.mirrorColor
            && r.packageName === defaultPackageName) || {};
          const RALDoc = priceList?.find((r) => r.articleCode === doorFiling.mirrorColor
            && r.packageName === packageName) || RALDocRetail;

          if (!_.isEmpty(RALDoc)) {
            const RALDocUnitPriceRetail = RALDocRetail.price || 0;
            const RALDocUnitPrice = RALDoc.price || 0;
            const RALDocTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * RALDocUnitPrice),
              2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: RALDocUnitPrice,
              itemTotalPrice: RALDocTotalPrice,
              labelRu: RALDoc?.labelRu,
              labelUk: RALDoc?.labelUk,
              labelPl: RALDoc?.labelPl,
              labelEn: RALDoc?.labelEn,
              articleCode: doorFiling.mirrorColor,
              position: { doorIndex },
            });
            specification.totalPrice += RALDocTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2) * RALDocUnitPriceRetail),
              2);
          } else {
            console.log(`Error: Mirror RAL ${doorFiling.mirrorColor} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: doorFiling.mirrorColor,
            });
          }
        }

        /** Лакобель RAL */

        if (doorFiling?.isLacobelRearMatted && doorFiling?.lacobelColor) {
          const RALDocRetail = priceList?.find((r) => r.articleCode === doorFiling.lacobelColor
            && r.packageName === defaultPackageName) || {};
          const RALDoc = priceList?.find((r) => r.articleCode === doorFiling.lacobelColor
            && r.packageName === packageName) || RALDocRetail;

          if (!_.isEmpty(RALDoc)) {
            const RALDocUnitPriceRetail = RALDocRetail.price || 0;
            const RALDocUnitPrice = RALDoc.price || 0;
            const RALDocTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * RALDocUnitPrice),
              2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: RALDocUnitPrice,
              itemTotalPrice: RALDocTotalPrice,
              labelRu: RALDoc?.labelRu,
              labelUk: RALDoc?.labelUk,
              labelPl: RALDoc?.labelPl,
              labelEn: RALDoc?.labelEn,
              articleCode: doorFiling?.lacobelColor,
              position: { doorIndex },
            });
            specification.totalPrice += RALDocTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2) * RALDocUnitPriceRetail),
              2);
          } else {
            console.log(`Error: Lacobel RAL ${doorFiling?.lacobelColor} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: doorFiling?.lacobelColor,
            });
          }
        }

        /** Бронь плівка */

        if (doorFiling?.isMirrorArmoredFilm || doorFiling?.isLacobelArmoredFilm || doorFiling?.isGlassArmoredFilm) {
          const armoredFilmArticleCode = 'film_armor';
          const armoredFilmItemRetail = priceList?.find((a) => a.articleCode === armoredFilmArticleCode
            && a.packageName === defaultPackageName) || {};
          const armoredFilmItem = priceList?.find((a) => a.articleCode === armoredFilmArticleCode
            && a.packageName === packageName) || armoredFilmItemRetail;

          if (!_.isEmpty(armoredFilmItem)) {
            const armoredFilmItemUnitPriceRetail = armoredFilmItemRetail.price || 0;
            const armoredFilmItemUnitPrice = armoredFilmItem.price || 0;
            const armoredFilmItemTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * armoredFilmItemUnitPrice),
              2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: armoredFilmItemUnitPrice,
              itemTotalPrice: armoredFilmItemTotalPrice,
              labelRu: armoredFilmItem?.labelRu,
              labelUk: armoredFilmItem?.labelUk,
              labelPl: armoredFilmItem?.labelPl,
              labelEn: armoredFilmItem?.labelEn,
              articleCode: armoredFilmArticleCode,
              position: { doorIndex },
            });
            specification.totalPrice += armoredFilmItemTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * armoredFilmItemUnitPriceRetail), 2);
          } else {
            console.log(`Error: ${armoredFilmArticleCode} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: armoredFilmArticleCode,
            });
          }
        }

        /** Ламінування білою плівкою */

        if (doorFiling?.isMirrorLaminated || doorFiling?.isLacobelLaminated || doorFiling?.isGlassLaminated) {
          const laminatedArticleCode = 'lam_wfilm';
          const laminatedItemRetail = priceList?.find((l) => l.articleCode === laminatedArticleCode
            && l.packageName === defaultPackageName) || {};
          const laminatedItem = priceList?.find((l) => l.articleCode === laminatedArticleCode
            && l.packageName === packageName) || laminatedItemRetail;

          if (!_.isEmpty(laminatedItem)) {
            const laminatedItemUnitPriceRetail = laminatedItemRetail.price || 0;
            const laminatedItemUnitPrice = laminatedItem.price || 0;
            const laminatedItemTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * laminatedItemUnitPrice),
              2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: laminatedItemUnitPrice,
              itemTotalPrice: laminatedItemTotalPrice,
              labelRu: laminatedItem?.labelRu,
              labelUk: laminatedItem?.labelUk,
              labelPl: laminatedItem?.labelPl,
              labelEn: laminatedItem?.labelEn,
              articleCode: laminatedArticleCode,
              position: { doorIndex },
            });
            specification.totalPrice += laminatedItemTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * laminatedItemUnitPriceRetail), 2);
          } else {
            console.log(`Error: ${laminatedArticleCode} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: laminatedArticleCode,
            });
          }
        }

        /** Фотодрук на плівці - Скло */

        if (doorFiling?.isGlassPhotoPrinting && doorFiling?.glassPhotoPrintType) {
          const glassPhotoPrintTypeRetail = priceList?.find((g) => g.articleCode === doorFiling?.glassPhotoPrintType
            && g.packageName === defaultPackageName) || {};
          const glassPhotoPrintType = priceList?.find((g) => g.articleCode === doorFiling?.glassPhotoPrintType
            && g.packageName === packageName) || glassPhotoPrintTypeRetail;

          if (!_.isEmpty(glassPhotoPrintType)) {
            const glassPhotoPrintUnitPriceRetail = glassPhotoPrintTypeRetail.price || 0;
            const glassPhotoPrintUnitPrice = glassPhotoPrintType.price || 0;
            const glassPhotoPrintTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * glassPhotoPrintUnitPrice), 2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: glassPhotoPrintUnitPrice,
              itemTotalPrice: glassPhotoPrintTotalPrice,
              labelRu: glassPhotoPrintType?.labelRu,
              labelUk: glassPhotoPrintType?.labelUk,
              labelPl: glassPhotoPrintType?.labelPl,
              labelEn: glassPhotoPrintType?.labelEn,
              articleCode: doorFiling?.glassPhotoPrintType,
              position: { doorIndex },
            });
            specification.totalPrice += glassPhotoPrintTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * glassPhotoPrintUnitPriceRetail), 2);
          } else {
            console.log(`Error: glassPhotoPrintType ${doorFiling?.glassPhotoPrintType} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: doorFiling?.glassPhotoPrintType,
            });
          }
        }

        /** Скло - Фарбування в один колір або в два кольори */

        if (doorFiling?.isGlassOneColorPainted || doorFiling?.isGlassTwoColorsPainted) {
          const paintedArticleCode = doorFiling?.isGlassOneColorPainted ? 'paint_1col' : 'paint_2col';
          const paintedItemRetail = priceList?.find((m) => m.articleCode === paintedArticleCode
            && m.packageName === defaultPackageName) || {};
          const paintedItem = priceList?.find((m) => m.articleCode === paintedArticleCode
            && m.packageName === packageName) || paintedItemRetail;

          if (!_.isEmpty(paintedItem)) {
            const paintedItemUnitPriceRetail = paintedItemRetail.price || 0;
            const paintedItemUnitPrice = paintedItem.price || 0;
            const paintedItemTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * paintedItemUnitPrice),
              2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: paintedItemUnitPrice,
              itemTotalPrice: paintedItemTotalPrice,
              labelRu: paintedItem?.labelRu,
              labelUk: paintedItem?.labelUk,
              labelPl: paintedItem?.labelPl,
              labelEn: paintedItem?.labelEn,
              articleCode: paintedArticleCode,
              position: { doorIndex },
            });
            specification.totalPrice += paintedItemTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * paintedItemUnitPriceRetail), 2);
          } else {
            console.log(`Error: ${paintedArticleCode} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: paintedArticleCode,
            });
          }
        }

        if (doorFiling?.isGlassTwoColorsPainted) {
          const stenPaintArticleCode = 'sten_paint';
          const stenPaintedItemRetail = priceList?.find((m) => m.articleCode === stenPaintArticleCode
            && m.packageName === defaultPackageName) || {};
          const stenPaintedItem = priceList?.find((m) => m.articleCode === stenPaintArticleCode
            && m.packageName === packageName) || stenPaintedItemRetail;

          if (!_.isEmpty(stenPaintedItem)) {
            const stenPaintedItemUnitPriceRetail = stenPaintedItemRetail.price || 0;
            const stenPaintedItemUnitPrice = stenPaintedItem.price || 0;
            const stenPaintedItemTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * stenPaintedItemUnitPrice), 2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              size: doorArea,
              height: doorFillingH,
              width: doorFillingW,
              unitPrice: stenPaintedItemUnitPrice,
              itemTotalPrice: stenPaintedItemTotalPrice,
              labelRu: stenPaintedItem?.labelRu,
              labelUk: stenPaintedItem?.labelUk,
              labelPl: stenPaintedItem?.labelPl,
              labelEn: stenPaintedItem?.labelEn,
              articleCode: stenPaintArticleCode,
              position: { doorIndex },
            });
            specification.totalPrice += stenPaintedItemTotalPrice;
            specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
              * stenPaintedItemUnitPriceRetail), 2);
          } else {
            console.log(`Error: ${stenPaintArticleCode} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: stenPaintArticleCode,
            });
          }
        }

        /** Glass RAL */

        if (doorFiling?.glassColors && doorFiling?.glassColors?.length) {
          _.forEach(doorFiling.glassColors, (articleCode) => {
            const glassRALRetail = priceList?.find((m) => m.articleCode === articleCode
              && m.packageName === defaultPackageName) || {};
            const glassRAL = priceList?.find((m) => m.articleCode === articleCode && m.packageName === packageName)
              || glassRALRetail;

            if (!_.isEmpty(glassRAL)) {
              const RALDocUnitPriceRetail = glassRALRetail?.price || 0;
              const RALDocUnitPrice = glassRAL?.price || 0;
              const RALDocTotalPrice = _.round(+(_.round((doorArea / squareMillimetersValue), 2) * RALDocUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: doorArea,
                height: doorFillingH,
                width: doorFillingW,
                unitPrice: RALDocUnitPrice,
                itemTotalPrice: RALDocTotalPrice,
                labelRu: glassRAL?.labelRu,
                labelUk: glassRAL?.labelUk,
                labelPl: glassRAL?.labelPl,
                labelEn: glassRAL?.labelEn,
                articleCode,
                position: { doorIndex },
              });
              specification.totalPrice += RALDocTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((doorArea / squareMillimetersValue), 2)
                * RALDocUnitPriceRetail), 2);
            }
          });
        }

        /** Полірування */

        if (hasPlanishingDoor) {
          const planishingArticleCode = 'пол_4-5';
          const planishingItemRetail = priceList?.find((m) => m.articleCode === planishingArticleCode
            && m.packageName === defaultPackageName) || {};
          const planishingItem = priceList?.find((m) => m.articleCode === planishingArticleCode
            && m.packageName === packageName) || planishingItemRetail;

          if (!_.isEmpty(planishingItem)) {
            const planishingItemUnitPriceRetail = planishingItemRetail.price || 0;
            const planishingItemUnitPrice = planishingItem.price || 0;
            const planishingPerimeter = _.round(+((doorFillingH * 2 + doorFillingW * 2 - 4) / 1000), 2);
            const planishingItemTotalPrice = _.round(+(planishingPerimeter * planishingItemUnitPrice), 2);

            specification.items.push({
              item: 'fillingFeature',
              amount: 1,
              unitPrice: planishingItemUnitPrice,
              itemTotalPrice: planishingItemTotalPrice,
              labelRu: planishingItem?.labelRu,
              labelUk: planishingItem?.labelUk,
              labelPl: planishingItem?.labelPl,
              labelEn: planishingItem?.labelEn,
              articleCode: planishingArticleCode,
              position: { doorIndex },
            });
            specification.totalPrice += planishingItemTotalPrice;
            specification.retailTotalPrice += _.round(+(planishingPerimeter * planishingItemUnitPriceRetail), 2);
          } else {
            console.log(`Error: ${planishingArticleCode} is not found`);
            specification.missingItems.push({
              item: 'fillingFeature',
              articleCode: planishingArticleCode,
            });
          }
        }
      } else {
        /**
         * Calculate sections filling
         */

        _.forEach(sections, (section, sectionIndex) => {
          const {
            filling: sectionFilling,
            fillingHeight: { value: fillingHeight },
            fillingWidth: { value: fillingWidth },
            texture: sectionTexture,
          } = section;
          const fillingS = _.isEmpty(sectionFilling) ? doorFiling : sectionFilling;

          const hasPlanishingSection = isSlim
            && (fillingS?.material === 'mirror' || fillingS?.material === 'glass' || fillingS?.material === 'lacobel');

          const originFillingH = _.round(+(fillingHeight), 2);
          const originFillingW = _.round(+(fillingWidth), 2);
          const fillingH = isSlim ? originFillingH - 2 : originFillingH;
          const fillingW = isSlim ? originFillingW - 2 : originFillingW;
          // * - 2: The reason is that Slim additional supplements' sizes are without 1 mm per each side in ADS specifications

          if (_.isEmpty(fillingS) || !fillingS.material) return;

          const fillingArticleCode = fillingS.mirrorType || fillingS.lacobelType
            || fillingS.glassType || fillingS.dspOption;
          const fillingItemWithPriceRetail = priceList?.find((m) => m.articleCode === fillingArticleCode
            && m.packageName === defaultPackageName) || {};
          const fillingItemWithPrice = priceList?.find((m) => m.articleCode === fillingArticleCode
            && m.packageName === packageName) || fillingItemWithPriceRetail;
          const fillingItem = fillingConfig?.find((m) => m.articleCode === fillingArticleCode) || {};
          const sectionArea = _.round(+(fillingH * fillingW), 2);
          const originSectionArea = _.round(+(originFillingH * originFillingW), 2);
          const textureS = isFillingHasTexture(fillingConfig, fillingArticleCode) ? sectionTexture?.value : '';

          // Calculate milling size for our chipboard > 10mm or for custom material
          const chipboardThickness = fillingItem?.chipboardThickness
            ? +(fillingItem.chipboardThickness)?.match(/([0-9]+[, .]?)+/)[0]
            : 0;
          if ((fillingS?.dspOption && chipboardThickness > 10)
            || (fillingS?.customersOption === 'dsp-large' && fillingS?.isMilling)) {
            const chipboardPerimeter = _.round(+(fillingH * 2 + fillingW * 2), 2);
            millingSize += chipboardPerimeter;
          }

          /** Filling Item */

          // Custom filling
          if (fillingS?.material === 'customers') {
            const isGlass = fillingS.customersOption === 'glass';
            const isLargeChipboard = fillingS.customersOption === 'dsp-large';

            const labelRu = isGlass ? 'Стекло' : isLargeChipboard ? 'ДСП 10+мм' : 'ДСП 10мм';
            const labelUk = isGlass ? 'Скло' : isLargeChipboard ? 'ДСП 10+мм' : 'ДСП 10мм';
            const labelPl = isGlass ? 'Szkło' : isLargeChipboard ? 'DSP 10+mm' : 'DSP 10mm';
            const labelEn = isGlass ? 'Glass' : isLargeChipboard ? 'DSP 10+mm' : 'DSP 10mm';

            // Should be shown in specification (with no price)
            specification.items.push({
              item: 'filling',
              amount: 1,
              size: originSectionArea,
              height: fillingS.customersOption !== 'glass' ? originFillingH : originFillingH + 2,
              width: fillingS.customersOption !== 'glass' ? originFillingW : originFillingW + 2,
              labelRu: `Материал заказчика: ${labelRu}`,
              labelUk: `Матеріал замовника: ${labelUk}`,
              labelPl: `Materiał klienta: ${labelPl}`,
              labelEn: `Customer material: ${labelEn}`,
              articleCode: isGlass ? 'customGlass' : (isLargeChipboard ? 'customDspPlus' : 'customDsp'),
              unitPrice: 0,
              itemTotalPrice: 0,
              position: { doorIndex, sectionIndex },
              texture: '',
            });
            return;
          }

          const sectionFilingItemUnitPriceRetail = fillingItemWithPriceRetail?.price || 0;
          const sectionFilingItemUnitPrice = fillingItemWithPrice?.price || 0;
          const sectionFilingItemTotalPrice = _.round(+(_.round((originSectionArea / squareMillimetersValue), 2)
            * sectionFilingItemUnitPrice), 2);

          specification.items.push({
            item: 'filling',
            amount: 1,
            size: originSectionArea,
            height: originFillingH,
            width: originFillingW,
            unitPrice: sectionFilingItemUnitPrice,
            itemTotalPrice: sectionFilingItemTotalPrice,
            labelRu: fillingItem?.labelRu,
            labelUk: fillingItem?.labelUk,
            labelPl: fillingItem?.labelPl,
            labelEn: fillingItem?.labelEn,
            articleCode: fillingArticleCode,
            position: { doorIndex, sectionIndex },
            texture: textureS,
          });
          specification.totalPrice += sectionFilingItemTotalPrice;
          specification.retailTotalPrice += _.round(+(_.round((originSectionArea / squareMillimetersValue), 2)
          * sectionFilingItemUnitPriceRetail), 2);

          /** Additional Supplements */

          /** Ультрафіолетовий друк - ДСП */

          if (fillingS?.isDspUVPrinting) {
            const chipboardUvPrintArticleCode = fillingS?.dspUvPrintType?.startsWith('print_uv_wcb')
              ? 'print_uv_wcb'
              : fillingS?.dspUvPrintType;
            const chipboardLayersAmount = chipboardUvPrintArticleCode === 'print_uv_wcb'
              && fillingS?.dspUvPrintType?.length > chipboardUvPrintArticleCode.length
              ? fillingS.dspUvPrintType.substr(fillingS.dspUvPrintType.length - 1)
              : 1;
            const chipboardUvPrintTypeRetail = priceList?.find((m) => m.articleCode === chipboardUvPrintArticleCode
              && m.packageName === defaultPackageName) || {};
            const chipboardUvPrintType = priceList?.find((m) => m.articleCode === chipboardUvPrintArticleCode
              && m.packageName === packageName) || chipboardUvPrintTypeRetail;

            if (!_.isEmpty(chipboardUvPrintType)) {
              const chipboardUvPrintUnitPriceRetail = chipboardUvPrintTypeRetail?.price || 0;
              const chipboardUvPrintUnitPrice = chipboardUvPrintType?.price || 0;
              const chipboardUvPrintTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * chipboardLayersAmount * chipboardUvPrintUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: chipboardUvPrintUnitPrice,
                itemTotalPrice: chipboardUvPrintTotalPrice,
                labelRu: `${chipboardUvPrintType?.labelRu} ${chipboardLayersAmount} шаров`,
                labelUk: `${chipboardUvPrintType?.labelUk} ${chipboardLayersAmount} шарів`,
                labelPl: `${chipboardUvPrintType?.labelPl} ${chipboardLayersAmount} warstwy`,
                labelEn: `${chipboardUvPrintType?.labelEn} ${chipboardLayersAmount} layers`,
                articleCode: chipboardUvPrintArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += chipboardUvPrintTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
              * chipboardLayersAmount * chipboardUvPrintUnitPriceRetail), 2);
            } else {
              console.log(`Error: dspUvPrintType ${chipboardUvPrintArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: chipboardUvPrintArticleCode,
              });
            }
          }

          /** Ультрафіолетовий друк - Дзеркало */

          if (fillingS?.isMirrorUVPrinting) {
            const mirrorUvPrintTypeRetail = priceList?.find((m) => m.articleCode === fillingS?.mirrorUvPrintType
              && m.packageName === defaultPackageName) || {};
            const mirrorUvPrintType = priceList?.find((m) => m.articleCode === fillingS?.mirrorUvPrintType
              && m.packageName === packageName) || mirrorUvPrintTypeRetail;

            if (!_.isEmpty(mirrorUvPrintType)) {
              const mirrorUvPrintUnitPriceRetail = mirrorUvPrintTypeRetail?.price || 0;
              const mirrorUvPrintUnitPrice = mirrorUvPrintType?.price || 0;
              const mirrorUvPrintTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * mirrorUvPrintUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: mirrorUvPrintUnitPrice,
                itemTotalPrice: mirrorUvPrintTotalPrice,
                labelRu: mirrorUvPrintType?.labelRu,
                labelUk: mirrorUvPrintType?.labelUk,
                labelPl: mirrorUvPrintType?.labelPl,
                labelEn: mirrorUvPrintType?.labelEn,
                articleCode: fillingS?.mirrorUvPrintType,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += mirrorUvPrintTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * mirrorUvPrintUnitPriceRetail), 2);
            } else {
              console.log(`Error: mirrorUvPrintType ${fillingS?.mirrorUvPrintType} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: fillingS?.mirrorUvPrintType,
              });
            }
          }

          /** Ультрафіолетовий друк - Скло */

          if (fillingS?.isGlassUVPrinting) {
            const glassUvPrintTypeRetail = priceList?.find((m) => m.articleCode === fillingS?.glassUvPrintType
              && m.packageName === defaultPackageName) || {};
            const glassUvPrintType = priceList?.find((m) => m.articleCode === fillingS?.glassUvPrintType
              && m.packageName === packageName) || glassUvPrintTypeRetail;

            if (!_.isEmpty(glassUvPrintType)) {
              const glassUvPrintUnitPriceRetail = glassUvPrintTypeRetail?.price || 0;
              const glassUvPrintUnitPrice = glassUvPrintType?.price || 0;
              const glassUvPrintTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * glassUvPrintUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: glassUvPrintUnitPrice,
                itemTotalPrice: glassUvPrintTotalPrice,
                labelRu: glassUvPrintType?.labelRu,
                labelUk: glassUvPrintType?.labelUk,
                labelPl: glassUvPrintType?.labelPl,
                labelEn: glassUvPrintType?.labelEn,
                articleCode: fillingS?.glassUvPrintType,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += glassUvPrintTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
              * glassUvPrintUnitPriceRetail), 2);
            } else {
              console.log(`Error: glassUvPrintType ${fillingS?.glassUvPrintType} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: fillingS?.glassUvPrintType,
              });
            }
          }

          /** Ультрафіолетовий друк - Лакобель */

          if (fillingS?.isLacobelUVPrinting) {
            const lacobelUvPrintTypeRetail = priceList?.find((m) => m.articleCode === fillingS?.lacobelUvPrintType
              && m.packageName === defaultPackageName) || {};
            const lacobelUvPrintType = priceList?.find((m) => m.articleCode === fillingS?.lacobelUvPrintType
              && m.packageName === packageName) || lacobelUvPrintTypeRetail;

            if (!_.isEmpty(lacobelUvPrintType)) {
              const lacobelUvPrintUnitPriceRetail = lacobelUvPrintTypeRetail.price || 0;
              const lacobelUvPrintUnitPrice = lacobelUvPrintType.price || 0;
              const lacobelUvPrintTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * lacobelUvPrintUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: lacobelUvPrintUnitPrice,
                itemTotalPrice: lacobelUvPrintTotalPrice,
                labelRu: lacobelUvPrintType?.labelRu,
                labelUk: lacobelUvPrintType?.labelUk,
                labelPl: lacobelUvPrintType?.labelPl,
                labelEn: lacobelUvPrintType?.labelEn,
                articleCode: fillingS?.lacobelUvPrintType,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += lacobelUvPrintTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * lacobelUvPrintUnitPriceRetail), 2);
            } else {
              console.log(`Error: lacobelUvPrintType ${fillingS?.lacobelUvPrintType} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: fillingS?.lacobelUvPrintType,
              });
            }
          }

          /** Матування по трафарету */

          if (fillingS?.isMirrorMatted || fillingS?.isGlassMatted || fillingS?.isLacobelMatted) {
            const mattedArticleCode = 'sten_mat';
            const mattedItemRetail = priceList?.find((m) => m.articleCode === mattedArticleCode
              && m.packageName === defaultPackageName) || {};
            const mattedItem = priceList?.find((m) => m.articleCode === mattedArticleCode
              && m.packageName === packageName) || mattedItemRetail;

            if (!_.isEmpty(mattedItem)) {
              const mattedItemUnitPriceRetail = mattedItemRetail.price || 0;
              const mattedItemUnitPrice = mattedItem.price || 0;
              const mattedItemTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2) * mattedItemUnitPrice),
                2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: mattedItemUnitPrice,
                itemTotalPrice: mattedItemTotalPrice,
                labelRu: mattedItem?.labelRu,
                labelUk: mattedItem?.labelUk,
                labelPl: mattedItem?.labelPl,
                labelEn: mattedItem?.labelEn,
                articleCode: mattedArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += mattedItemTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * mattedItemUnitPriceRetail), 2);
            } else {
              console.log(`Error: ${mattedArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: mattedArticleCode,
              });
            }
          }

          /** Тильне матування */

          if (fillingS?.isMirrorRearMatted || fillingS?.isLacobelRearMatted) {
            const rearMattingArticleCode = 'rear_matt';
            const rearMattedItemRetail = priceList?.find((m) => m.articleCode === rearMattingArticleCode
              && m.packageName === defaultPackageName) || {};
            const rearMattedItem = priceList?.find((m) => m.articleCode === rearMattingArticleCode
              && m.packageName === packageName) || rearMattedItemRetail;

            if (!_.isEmpty(rearMattedItem)) {
              const rearMattingUnitPriceRetail = rearMattedItemRetail.price || 0;
              const rearMattingUnitPrice = rearMattedItem.price || 0;
              const rearMattingTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2) * rearMattingUnitPrice),
                2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: rearMattingUnitPrice,
                itemTotalPrice: rearMattingTotalPrice,
                labelRu: rearMattedItem?.labelRu,
                labelUk: rearMattedItem?.labelUk,
                labelPl: rearMattedItem?.labelPl,
                labelEn: rearMattedItem?.labelEn,
                articleCode: rearMattingArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += rearMattingTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * rearMattingUnitPriceRetail), 2);
            } else {
              console.log(`Error: ${rearMattingArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: rearMattingArticleCode,
              });
            }
          }

          /** Повне матування */

          if (fillingS?.isMirrorFullMatted || fillingS?.isLacobelFullMatted || fillingS?.isGlassFullMatted) {
            const fullMattingArticleCode = 'matt_compl';
            const fullMattingItemRetail = priceList?.find((m) => m.articleCode === fullMattingArticleCode
              && m.packageName === defaultPackageName) || {};
            const fullMattingItem = priceList?.find((m) => m.articleCode === fullMattingArticleCode
              && m.packageName === packageName) || fullMattingItemRetail;

            if (!_.isEmpty(fullMattingItem)) {
              const fullMattingUnitPriceRetail = fullMattingItemRetail.price || 0;
              const fullMattingUnitPrice = fullMattingItem.price || 0;
              const fullMattingTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2) * fullMattingUnitPrice),
                2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: fullMattingUnitPrice,
                itemTotalPrice: fullMattingTotalPrice,
                labelRu: fullMattingItem?.labelRu,
                labelUk: fullMattingItem?.labelUk,
                labelPl: fullMattingItem?.labelPl,
                labelEn: fullMattingItem?.labelEn,
                articleCode: fullMattingArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += fullMattingTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * fullMattingUnitPriceRetail), 2);
            } else {
              console.log(`Error: ${fullMattingArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: fullMattingArticleCode,
              });
            }
          }

          /** Дзеркало RAL */

          if (fillingS?.isMirrorRearMatted && fillingS?.mirrorColor) {
            const rearMattingArticleCode = 'rear_matt';
            const RALDocRetail = priceList?.find((m) => m.articleCode === fillingS.mirrorColor
              && m.packageName === defaultPackageName) || {};
            const RALDoc = priceList?.find((m) => m.articleCode === fillingS.mirrorColor
              && m.packageName === packageName) || RALDocRetail;
            const rearMattedItemRetail = priceList?.find((m) => m.articleCode === rearMattingArticleCode
              && m.packageName === defaultPackageName) || {};
            const rearMattedItem = priceList?.find((m) => m.articleCode === rearMattingArticleCode
              && m.packageName === packageName) || rearMattedItemRetail;

            if (!_.isEmpty(rearMattedItem)) {
              const rearMattedItemUnitPriceRetail = rearMattedItemRetail.price || 0;
              const rearMattedItemUnitPrice = rearMattedItem.price || 0;
              const rearMattedItemTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * rearMattedItemUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: rearMattedItemUnitPrice,
                itemTotalPrice: rearMattedItemTotalPrice,
                labelRu: rearMattedItem?.labelRu,
                labelUk: rearMattedItem?.labelUk,
                labelPl: rearMattedItem?.labelPl,
                labelEn: rearMattedItem?.labelEn,
                articleCode: rearMattingArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += rearMattedItemTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * rearMattedItemUnitPriceRetail), 2);
            } else {
              console.log(`Error: ${rearMattingArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: rearMattingArticleCode,
              });
            }

            if (!_.isEmpty(RALDoc)) {
              const RALDocUnitPriceRetail = RALDocRetail.price || 0;
              const RALDocUnitPrice = RALDoc.price || 0;
              const RALDocTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2) * RALDocUnitPrice),
                2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: RALDocUnitPrice,
                itemTotalPrice: RALDocTotalPrice,
                labelRu: RALDoc?.labelRu,
                labelUk: RALDoc?.labelUk,
                labelPl: RALDoc?.labelPl,
                labelEn: RALDoc?.labelEn,
                articleCode: fillingS.mirrorColor,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += RALDocTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * RALDocUnitPriceRetail), 2);
            } else {
              console.log(`Error: Mirror RAL ${fillingS.mirrorColor} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: fillingS.mirrorColor,
              });
            }
          }

          /** Лакобель RAL */

          if (fillingS?.isLacobelRearMatted && fillingS?.lacobelColor) {
            const RALDocRetail = priceList?.find((m) => m.articleCode === fillingS.lacobelColor
              && m.packageName === defaultPackageName) || {};
            const RALDoc = priceList?.find((m) => m.articleCode === fillingS.lacobelColor
              && m.packageName === packageName) || RALDocRetail;

            if (!_.isEmpty(RALDoc)) {
              const RALDocUnitPriceRetail = RALDocRetail.price || 0;
              const RALDocUnitPrice = RALDoc.price || 0;
              const RALDocTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2) * RALDocUnitPrice),
                2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: RALDocUnitPrice,
                itemTotalPrice: RALDocTotalPrice,
                labelRu: RALDoc?.labelRu,
                labelUk: RALDoc?.labelUk,
                labelPl: RALDoc?.labelPl,
                labelEn: RALDoc?.labelEn,
                articleCode: fillingS.lacobelColor,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += RALDocTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * RALDocUnitPriceRetail), 2);
            } else {
              console.log(`Error: Lacobel RAL ${fillingS.lacobelColor} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: fillingS.lacobelColor,
              });
            }
          }

          /** Бронь плівка */

          if (fillingS?.isMirrorArmoredFilm || fillingS?.isLacobelArmoredFilm || fillingS?.isGlassArmoredFilm) {
            const armoredFilmArticleCode = 'film_armor';
            const armoredFilmItemRetail = priceList?.find((a) => a.articleCode === armoredFilmArticleCode
              && a.packageName === defaultPackageName) || {};
            const armoredFilmItem = priceList?.find((a) => a.articleCode === armoredFilmArticleCode
              && a.packageName === packageName) || armoredFilmItemRetail;

            if (!_.isEmpty(armoredFilmItem)) {
              const armoredFilmItemUnitPriceRetail = armoredFilmItemRetail.price || 0;
              const armoredFilmItemUnitPrice = armoredFilmItem.price || 0;
              const armoredFilmItemTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * armoredFilmItemUnitPrice), 2);
              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: armoredFilmItemUnitPrice,
                itemTotalPrice: armoredFilmItemTotalPrice,
                labelRu: armoredFilmItem?.labelRu,
                labelUk: armoredFilmItem?.labelUk,
                labelPl: armoredFilmItem?.labelPl,
                labelEn: armoredFilmItem?.labelEn,
                articleCode: armoredFilmArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += armoredFilmItemTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * armoredFilmItemUnitPriceRetail), 2);
            } else {
              console.log(`Error: ${armoredFilmArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: armoredFilmArticleCode,
              });
            }
          }

          /** Ламінування білою плівкою */

          if (fillingS?.isMirrorLaminated || fillingS?.isLacobelLaminated || fillingS?.isGlassLaminated) {
            const laminatedArticleCode = 'lam_wfilm';
            const laminatedItemRetail = priceList?.find((m) => m.articleCode === laminatedArticleCode
              && m.packageName === defaultPackageName) || {};
            const laminatedItem = priceList?.find((m) => m.articleCode === laminatedArticleCode
              && m.packageName === packageName) || laminatedItemRetail;

            if (!_.isEmpty(laminatedItem)) {
              const laminatedItemUnitPriceRetail = laminatedItemRetail.price || 0;
              const laminatedItemUnitPrice = laminatedItem.price || 0;
              const laminatedItemTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * laminatedItemUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: laminatedItemUnitPrice,
                itemTotalPrice: laminatedItemTotalPrice,
                labelRu: laminatedItem?.labelRu,
                labelUk: laminatedItem?.labelUk,
                labelPl: laminatedItem?.labelPl,
                labelEn: laminatedItem?.labelEn,
                articleCode: laminatedArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += laminatedItemTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
              * laminatedItemUnitPriceRetail), 2);
            } else {
              console.log(`Error: ${laminatedArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: laminatedArticleCode,
              });
            }
          }

          /** Фотодрук на плівці - Скло */

          if (fillingS?.isGlassPhotoPrinting && fillingS?.glassPhotoPrintType) {
            const glassPhotoPrintTypeRetail = priceList?.find((m) => m.articleCode === fillingS?.glassPhotoPrintType
              && m.packageName === defaultPackageName) || {};
            const glassPhotoPrintType = priceList?.find((m) => m.articleCode === fillingS?.glassPhotoPrintType
              && m.packageName === packageName) || glassPhotoPrintTypeRetail;

            if (!_.isEmpty(glassPhotoPrintType)) {
              const glassPhotoPrintUnitPriceRetail = glassPhotoPrintTypeRetail.price || 0;
              const glassPhotoPrintUnitPrice = glassPhotoPrintType.price || 0;
              const glassPhotoPrintTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * glassPhotoPrintUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: glassPhotoPrintUnitPrice,
                itemTotalPrice: glassPhotoPrintTotalPrice,
                labelRu: glassPhotoPrintType?.labelRu,
                labelUk: glassPhotoPrintType?.labelUk,
                labelPl: glassPhotoPrintType?.labelPl,
                labelEn: glassPhotoPrintType?.labelEn,
                articleCode: fillingS?.glassPhotoPrintType,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += glassPhotoPrintTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
              * glassPhotoPrintUnitPriceRetail), 2);
            } else {
              console.log(`Error: glassPhotoPrintType ${fillingS?.glassPhotoPrintType} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: fillingS?.glassPhotoPrintType,
              });
            }
          }

          /** Фарбування в один колір або в два кольори */

          if (fillingS?.isGlassOneColorPainted || fillingS?.isGlassTwoColorsPainted) {
            const paintedArticleCode = fillingS?.isGlassOneColorPainted ? 'paint_1col' : 'paint_2col';
            const paintedItemRetail = priceList?.find((m) => m.articleCode === paintedArticleCode
              && m.packageName === defaultPackageName) || {};
            const paintedItem = priceList?.find((m) => m.articleCode === paintedArticleCode
              && m.packageName === packageName) || paintedItemRetail;

            if (!_.isEmpty(paintedItem)) {
              const paintedItemUnitPriceRetail = paintedItemRetail.price || 0;
              const paintedItemUnitPrice = paintedItem.price || 0;
              const paintedItemTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2) * paintedItemUnitPrice),
                2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: paintedItemUnitPrice,
                itemTotalPrice: paintedItemTotalPrice,
                labelRu: paintedItem?.labelRu,
                labelUk: paintedItem?.labelUk,
                labelPl: paintedItem?.labelPl,
                labelEn: paintedItem?.labelEn,
                articleCode: paintedArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += paintedItemTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * paintedItemUnitPriceRetail), 2);
            } else {
              console.log(`Error: ${paintedArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: paintedArticleCode,
              });
            }
          }

          if (fillingS?.isGlassTwoColorsPainted) {
            const stenPaintArticleCode = 'sten_paint';
            const stenPaintedItemRetail = priceList?.find((m) => m.articleCode === stenPaintArticleCode
              && m.packageName === defaultPackageName) || {};
            const stenPaintedItem = priceList?.find((m) => m.articleCode === stenPaintArticleCode
              && m.packageName === packageName) || stenPaintedItemRetail;

            if (!_.isEmpty(stenPaintedItem)) {
              const stenPaintedItemUnitPriceRetail = stenPaintedItemRetail.price || 0;
              const stenPaintedItemUnitPrice = stenPaintedItem.price || 0;
              const stenPaintedItemTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * stenPaintedItemUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: stenPaintedItemUnitPrice,
                itemTotalPrice: stenPaintedItemTotalPrice,
                labelRu: stenPaintedItem?.labelRu,
                labelUk: stenPaintedItem?.labelUk,
                labelPl: stenPaintedItem?.labelPl,
                labelEn: stenPaintedItem?.labelEn,
                articleCode: stenPaintArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += stenPaintedItemTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * stenPaintedItemUnitPriceRetail), 2);
            } else {
              console.log(`Error: ${stenPaintArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: stenPaintArticleCode,
              });
            }
          }

          // Glass RAL
          if (fillingS?.glassColors && fillingS?.glassColors?.length) {
            _.forEach(fillingS.glassColors, (articleCode) => {
              const glassRALRetail = priceList?.find((m) => m.articleCode === articleCode
                && m.packageName === defaultPackageName) || {};
              const glassRAL = priceList?.find((m) => m.articleCode === articleCode && m.packageName === packageName)
                || glassRALRetail;
              const RALDocUnitPriceRetail = glassRALRetail?.price || 0;
              const RALDocUnitPrice = glassRAL?.price || 0;
              const RALDocTotalPrice = _.round(+(_.round((sectionArea / squareMillimetersValue), 2) * RALDocUnitPrice),
                2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                size: sectionArea,
                height: fillingH,
                width: fillingW,
                unitPrice: RALDocUnitPrice,
                itemTotalPrice: RALDocTotalPrice,
                labelRu: glassRAL?.labelRu,
                labelUk: glassRAL?.labelUk,
                labelPl: glassRAL?.labelPl,
                labelEn: glassRAL?.labelEn,
                articleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += RALDocTotalPrice;
              specification.retailTotalPrice += _.round(+(_.round((sectionArea / squareMillimetersValue), 2)
                * RALDocUnitPriceRetail), 2);
            });
          }

          /** Полірування */

          if (hasPlanishingSection) {
            const planishingArticleCode = 'пол_4-5';
            const planishingItemRetail = priceList?.find((m) => m.articleCode === planishingArticleCode
              && m.packageName === defaultPackageName) || {};
            const planishingItem = priceList?.find((m) => m.articleCode === planishingArticleCode
              && m.packageName === packageName) || planishingItemRetail;

            if (!_.isEmpty(planishingItem)) {
              const planishingItemUnitPriceRetail = planishingItemRetail.price || 0;
              const planishingItemUnitPrice = planishingItem.price || 0;
              const planishingPerimeter = _.round(+((fillingH * 2 + fillingW * 2 - 4) / 1000), 2);
              const planishingItemTotalPrice = _.round(+(planishingPerimeter * planishingItemUnitPrice), 2);

              specification.items.push({
                item: 'fillingFeature',
                amount: 1,
                unitPrice: planishingItemUnitPrice,
                itemTotalPrice: planishingItemTotalPrice,
                labelRu: planishingItem?.labelRu,
                labelUk: planishingItem?.labelUk,
                labelPl: planishingItem?.labelPl,
                labelEn: planishingItem?.labelEn,
                articleCode: planishingArticleCode,
                position: { doorIndex, sectionIndex },
              });
              specification.totalPrice += planishingItemTotalPrice;
              specification.retailTotalPrice += _.round(+(planishingPerimeter * planishingItemUnitPriceRetail), 2);
            } else {
              console.log(`Error: ${planishingArticleCode} is not found`);
              specification.missingItems.push({
                item: 'fillingFeature',
                articleCode: planishingArticleCode,
              });
            }
          }
        });
      }
    });

    /** Фрезерування */

    if (millingSize) {
      const millingDocRetail = priceList?.find((m) => m.articleCode === defaultMilling
        && m.packageName === defaultPackageName) || {};
      const millingDoc = priceList?.find((m) => m.articleCode === defaultMilling && m.packageName === packageName)
        || millingDocRetail;
      const millingUnitPriceRetail = millingDocRetail?.price ? _.round(+(millingDocRetail.price), 2) : 0;
      const millingUnitPrice = millingDoc?.price ? _.round(+(millingDoc.price), 2) : 0;
      const millingTotalPrice = _.round(+((millingSize / 1000) * millingUnitPrice), 2);

      specification.items.push({
        item: 'milling',
        amount: 1,
        size: millingSize,
        unitPrice: millingUnitPrice,
        itemTotalPrice: millingTotalPrice,
        labelRu: millingDoc?.labelRu,
        labelUk: millingDoc?.labelUk,
        labelPl: millingDoc?.labelPl,
        labelEn: millingDoc?.labelEn,
        articleCode: defaultMilling,
      });
      specification.totalPrice += millingTotalPrice;
      specification.retailTotalPrice += _.round(+((millingSize / 1000) * millingUnitPriceRetail), 2);
    }

    /** Пакування */

    // Пакування направляючої
    const gpPackagingDocRetail = priceList?.find((m) => m.articleCode === packagingOfGuidanceProfile
      && m.packageName === defaultPackageName) || {};
    const gpPackagingDoc = priceList?.find((m) => m.articleCode === packagingOfGuidanceProfile
      && m.packageName === packageName) || gpPackagingDocRetail;
    const gpPackagingUnitPriceRetail = gpPackagingDocRetail?.price ? _.round(+(gpPackagingDocRetail.price), 2) : 0;
    const gpPackagingUnitPrice = gpPackagingDoc?.price ? _.round(+(gpPackagingDoc.price), 2) : 0;

    specification.items.push({
      item: 'gpPackaging',
      amount: 1,
      unitPrice: gpPackagingUnitPrice,
      itemTotalPrice: gpPackagingUnitPrice,
      labelRu: gpPackagingDoc?.labelRu,
      labelUk: gpPackagingDoc?.labelUk,
      labelPl: gpPackagingDoc?.labelPl,
      labelEn: gpPackagingDoc?.labelEn,
      articleCode: packagingOfGuidanceProfile,
    });
    specification.totalPrice += gpPackagingUnitPrice;
    specification.retailTotalPrice += gpPackagingUnitPriceRetail;

    // Пакування дверей
    const doorPackagingArticleCode = _.some(['Slim', '419'], (sp) => sp === sideProfileValue)
      ? 'pak_sl'
      : _.some(['119-L', '119-v.p.'], (sp) => sp === sideProfileValue)
        ? 'pak_dl'
        : _.some(['119', '06', '07', '120', '207', '20', '21', '22', '220', '117', '219'],
          (sp) => sp === sideProfileValue)
          ? 'pak_d'
          : '';

    if (doorPackagingArticleCode) {
      const doorPackagingDocRetail = priceList?.find((m) => m.articleCode === doorPackagingArticleCode
        && m.packageName === defaultPackageName) || {};
      const doorPackagingDoc = priceList?.find((m) => m.articleCode === doorPackagingArticleCode
        && m.packageName === packageName) || doorPackagingDocRetail;
      const doorPackagingUnitPriceRetail = doorPackagingDocRetail?.price
        ? _.round(+(doorPackagingDocRetail.price), 2)
        : 0;
      const doorPackagingUnitPrice = doorPackagingDoc?.price ? _.round(+(doorPackagingDoc.price), 2) : 0;
      const doorPackagingTotalPrice = _.round(+(doorPackagingUnitPrice * doorsAmount), 2);

      specification.items.push({
        item: 'doorPackaging',
        amount: doorsAmount,
        unitPrice: doorPackagingUnitPrice,
        itemTotalPrice: doorPackagingTotalPrice,
        labelRu: doorPackagingDoc?.labelRu,
        labelUk: doorPackagingDoc?.labelUk,
        labelPl: doorPackagingDoc?.labelPl,
        labelEn: doorPackagingDoc?.labelEn,
        articleCode: doorPackagingArticleCode,
      });
      specification.totalPrice += doorPackagingTotalPrice;
      specification.retailTotalPrice += _.round(+(doorPackagingUnitPriceRetail * doorsAmount), 2);
    }
  } catch (ex) { console.log(ex); }

  specification.totalPrice = _.round(specification.totalPrice, 2);
  specification.retailTotalPrice = _.round(specification.retailTotalPrice, 2);

  const discountsEndDates = [];
  let itemPriceWithDiscount = 0;
  let itemPriceWithoutDiscount = 0;

  specification.items = specification.items.map((item) => {
    if (item.item === 'work') {
      return {
        ...item,
        discountPercentage: 0,
        totalPriceWithoutDiscount: item.itemTotalPrice,
      };
    }

    const { discountPercentage, endDate } = applyItemDiscounts(
      item,
      specification.totalPrice,
      currentSystem,
      sideProfile?.value,
      aluminiumColor?.value,
      specification.items,
      discounts,
      isDoorAssemblingOn,
    );

    if (endDate) discountsEndDates.push(endDate);

    const { itemTotalPrice } = item;

    itemPriceWithDiscount += itemTotalPrice * (1 - discountPercentage / 100);
    itemPriceWithoutDiscount += itemTotalPrice;

    return {
      ...item,
      discountPercentage,
      itemTotalPrice: itemTotalPrice * (1 - discountPercentage / 100),
      totalPriceWithoutDiscount: itemTotalPrice,
    };
  });

  discountsEndDates.sort((a, b) => a - b);

  // TODO: NOT SURE THAT WE NEED TO SAVE PREVIOUS totalPrice LOGIC

  const orderTotalPrice = _.round(specification.items.reduce((acc, item) => acc + item.itemTotalPrice, 0), 2) || 0;
  const currentTotalPrice = orderTotalPrice;
  const roundedTotalPrice = getRoundedNumberMultipleOfNumber(orderTotalPrice, 3);

  if (currentTotalPrice !== roundedTotalPrice) {
    const priceDifference = _.round(roundedTotalPrice - currentTotalPrice, 2);

    specification.items = specification.items.map((item) => {
      if (item.item === 'work') {
        return {
          ...item,
          itemTotalPrice: _.round(item.itemTotalPrice + priceDifference, 2),
          totalPriceWithoutDiscount: _.round(item.totalPriceWithoutDiscount + priceDifference, 2),
        };
      }

      return item;
    });

    specification.retailTotalPrice = _.round(specification.retailTotalPrice + priceDifference, 2);
  }

  return {
    specification: {
      ...specification,
      ...(discountsEndDates?.[0] && { earliestDiscountEndDate: discountsEndDates?.[0] }),
      totalPrice: _.round(specification.items.reduce((acc, item) => acc + item.itemTotalPrice, 0), 2),
      priceWithDiscount: itemPriceWithDiscount < itemPriceWithoutDiscount,
    },
  };
}

export function isChipboard(filling) {
  return filling?.customersOption?.includes('dsp') || filling?.material === 'dsp';
}

export function isFillingHasTexture(fillings, fillingArticleCode) {
  return fillings.find((f) => f.articleCode === fillingArticleCode)?.isTexture;
}
