import React, { useEffect } from 'react';
import { useRoutes, useRedirect } from 'hookrouter';
import ReactGA from 'react-ga';

import AuthService from '../services/authService';

import routes from '../routes';
import NotFoundPage from './NotFoundPage';


if (process.env.GOOGLE_ANALYTICS_ID) ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);

const App = () => {
  const match = useRoutes(routes);

  useEffect(() => {
    if (process.env.GOOGLE_ANALYTICS_ID) ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (AuthService.getRole() !== 'admin') {
    useRedirect('/json-form', '/sign-in/admin');

    return match;
  }

  // The application will be redirected to home route for logged in users
  // when they try to visit the registration or login routes
  if (AuthService.isLoggedIn() && AuthService.isPhoneNumberVerified()) {
    useRedirect('/sign-in', '/');
    useRedirect('/sign-in/admin', '/');
    useRedirect('/sign-in/manager', '/');
    useRedirect('/sign-up-start', '/');
    useRedirect('/sign-up', '/');
    useRedirect('/reset-password', '/');

    return match;
  }

  return match || <NotFoundPage />;
};

export default App;
