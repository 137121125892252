import './styles/index.scss';
import './utils/i18next';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import configureStore from './redux/store';
import App from './containers/App';
import Loader from './components/Loader';

const store = configureStore();

if (process.env.ENV === 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DNS,
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <ToastProvider
          autoDismissTimeout={10000}
          transitionDuration={1000}
          placement="bottom-center"
        >
          <App />
        </ToastProvider>
      </Suspense>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
