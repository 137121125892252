import { createActions } from 'reduxsauce';

import API from '../../api';
import AuthService from '../../services/authService';


const { Types, Creators } = createActions({
  getMyAcceptedOrdersSuccess: ['orders', 'nextCursor', 'isOverwrite', 'role'],
  getMyAcceptedOrdersFailure: ['errorMessage'],
  getMyAcceptedOrdersRequest: (queryParams) => async (dispatch) => {
    try {
      const userId = AuthService.getUserId();

      if (!userId) {
        dispatch(Creators.getMyAcceptedOrdersFailure('USERID_NOT_FOUND'));
        return;
      }

      const response = await API.orders.list.acceptedByUserId(userId, queryParams);
      const { ok, problem, data } = response || {};
      const error = data?.error || {};
      const orders = response?.data?.orders || [];
      const role = response?.data?.role;
      const next = response?.data?.nextCursor || '';
      const isOverwrite = response?.data?.isOverwrite;
      const errorMessage = !ok
        ? (error?.message || data?.message || problem)
        : '';

      if (errorMessage) {
        dispatch(Creators.getMyAcceptedOrdersFailure(errorMessage));
        return;
      }

      dispatch(Creators.getMyAcceptedOrdersSuccess(orders, next, isOverwrite, role));
    } catch (error) {
      dispatch(Creators.getMyAcceptedOrdersFailure(error.message || error.reason));
    }
  },

  clearMyAcceptedOrders: null,
});

export const MyAcceptedOrdersTypes = Types;
export default Creators;
