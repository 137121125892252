import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactAwesomePopover from 'react-awesome-popover';

import AuthService from '../services/authService';

import ProfileActions from '../redux/actions/profile';

// move to redux store if the server provides a list of available languages and selected one
// now all settings are in i18next config
const languages = [
  {
    id: 0,
    title: 'УКР',
    code: 'uk',
  }, {
    id: 1,
    title: 'РOL',
    code: 'pl',
  },
  {
    id: 2,
    title: 'ENG',
    code: 'en',
  },
  // {
  //   id: 3,
  //   title: 'РУС',
  //   code: 'ru',
  // },
];

const Localization = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const userId = AuthService.getUserId();
  const changeLanguage = (lngCode) => i18n.changeLanguage(lngCode);
  const className = isMobile ? 'localization--mobile' : 'localization';
  const navigatorLang = ['uk', 'pl'].includes(navigator.language.slice(0, 2)) ? navigator.language.slice(0, 2) : 'en';
  const storageLanguage = localStorage.getItem('language') || navigatorLang;
  const { language } = useSelector(({ profile }) => profile);
  const [popoverLang, setPopoverLang] = useState({ uk: 'УКР', en: 'ENG', pl: 'POL', ru: 'РУС' }[storageLanguage]);
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const Popover = ReactAwesomePopover;

  useEffect(() => {
    if (!storageLanguage && navigatorLang) {
      localStorage.setItem('language', navigatorLang);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(ProfileActions.getUserProfileRequest());
    }
  }, [i18n.language]);

  useEffect(() => {
    i18n.changeLanguage(userId ? language : storageLanguage);
  }, [userId, language]);

  const handleChangeLanguage = (lngCode) => {
    localStorage.setItem('language', lngCode);
    changeLanguage(lngCode);
    dispatch(ProfileActions.setProfileLanguageRequest(lngCode));
  };

  if (isMobile) {
    return (
      <div className={className}>
        <img
          className={`${className}--icon`}
          src="src/client/assets/icons/mobile-planet.svg"
          alt="Planet"
        />
        {languages.map((lng) => {
          const isSelected = i18n.language === lng.code;
          return (
            <button
              type="button"
              key={lng.id}
              className={`${className}--btn ${isSelected && 'selected'}`}
              onClick={() => handleChangeLanguage(lng.code)}
            >
              <span>{lng.title}</span>
            </button>
          );
        })}
      </div>
    );
  } else {
    return (
      <Popover
        placement="bottom-center"
        overlayColor="rgba(0,0,0,0)"
        arrowProps={{ className: 'popover-arrow' }}
        open={isOpenPopover}
        action="click"
        className="popover"
        onClick={() => { setIsOpenPopover(!isOpenPopover); }}
      >
        <button className="popover-button" type="button">
          {popoverLang}
          <img
            className="localization--icon"
            src="src/client/assets/icons/triangle.svg"
            alt="triangle"
          />
        </button>
        <div className="popover-wrapper">
          {languages.map((lng) => {
            const isSelected = i18n.language === lng.code;
            return (
              <button
                type="button"
                key={lng.id}
                className={`${className}--btn ${isSelected && 'selected'}`}
                onClick={() => { handleChangeLanguage(lng.code); setPopoverLang(lng.title); }}
              >
                <span>{lng.title}</span>
              </button>
            );
          })}
        </div>
      </Popover>
    );
  }
};


Localization.propTypes = {
  isMobile: PropTypes.bool,
};

Localization.defaultProps = {
  isMobile: false,
};

export default Localization;
