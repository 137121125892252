import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';

import { FasteningElementTypes } from '../actions/fasteningElement';


const INITIAL_STATE = {
  fasteningElement: {
    type: '',
    amount: 0,
    items: [],
    useCurtain: false,
    hingeType: '',
    drillingScheme: '',
    liftingMechanismType: '',
    liftingMechanismBottomType: '',
    standardServoDriveType: '',
    withBottomModule: false,
    isUserChangedCoords: false,
  },

  prevFasteningElement: {},

  isFasteningElementOpen: false,
  isWarningDistanceModalOpen: false,
  isWarningHeightModalOpen: false,
  isWarningHandleModalOpen: false,
};

const setFasteningElement = (state = INITIAL_STATE, { params }) => update(state, {
  fasteningElement: { $set: params || INITIAL_STATE.fasteningElement },
});

const setPrevFasteningElement = (state = INITIAL_STATE, { params }) => update(state, {
  prevFasteningElement: { $set: params || INITIAL_STATE.prevFasteningElement },
});

const setType = (state = INITIAL_STATE, { elementType }) => update(state, {
  fasteningElement: { type: { $set: elementType || INITIAL_STATE.fasteningElement.type } },
});

const setAmount = (state = INITIAL_STATE, { amount }) => update(state, {
  fasteningElement: { amount: { $set: amount || INITIAL_STATE.fasteningElement.amount } },
});
const setItems = (state = INITIAL_STATE, { items }) => update(state, {
  fasteningElement: { items: { $set: items || INITIAL_STATE.fasteningElement.items } },
});

const setDrillingScheme = (state = INITIAL_STATE, { drillingScheme }) => update(state, {
  fasteningElement: { drillingScheme: { $set: drillingScheme || INITIAL_STATE.fasteningElement.drillingScheme } },
});

const setUseCurtain = (state = INITIAL_STATE, { isUse }) => update(state, {
  fasteningElement: { useCurtain: { $set: isUse || INITIAL_STATE.fasteningElement.useCurtain } },
});

const setHingeType = (state = INITIAL_STATE, { hingeType }) => update(state, {
  fasteningElement: { hingeType: { $set: hingeType || INITIAL_STATE.fasteningElement.hingeType } },
});

const setLiftingMechanismType = (state = INITIAL_STATE, { liftingMechanismType }) => update(state, {
  fasteningElement: { liftingMechanismType: { $set: liftingMechanismType || INITIAL_STATE.fasteningElement.liftingMechanismType } },
});

const setLiftingMechanismBottomType = (state = INITIAL_STATE, { liftingMechanismType }) => update(state, {
  fasteningElement: { liftingMechanismBottomType: { $set: liftingMechanismType || INITIAL_STATE.fasteningElement.liftingMechanismBottomType } },
});

const setStandardServoDriveType = (state = INITIAL_STATE, { standardServoDriveType }) => update(state, {
  fasteningElement: { standardServoDriveType: { $set: standardServoDriveType || INITIAL_STATE.fasteningElement.standardServoDriveType } },
});

const setWithBottomModule = (state = INITIAL_STATE, { isAdded }) => update(state, {
  fasteningElement: { withBottomModule: { $set: isAdded || INITIAL_STATE.fasteningElement.withBottomModule } },
});

const setUserChangedCoords = (state = INITIAL_STATE, { isChanged }) => update(state, {
  fasteningElement: { isUserChangedCoords: { $set: isChanged || INITIAL_STATE.fasteningElement.isUserChangedCoords } },
});

const resetFasteningElement = (state = INITIAL_STATE) => update(state, {
  fasteningElement: { $set: INITIAL_STATE.fasteningElement },
});

const toggleFasteningElementModal = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isFasteningElementOpen: { $set: isOpen || false },
});
const toggleWarningDistanceModal = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isWarningDistanceModalOpen: { $set: isOpen },
});
const toggleWarningHeightModal = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isWarningHeightModalOpen: { $set: isOpen },
});
const toggleWarningHandleModal = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isWarningHandleModalOpen: { $set: isOpen },
});



export default createReducer(INITIAL_STATE, {
  [FasteningElementTypes.SET_FASTENING_ELEMENT]: setFasteningElement,
  [FasteningElementTypes.SET_PREV_FASTENING_ELEMENT]: setPrevFasteningElement,
  [FasteningElementTypes.SET_TYPE]: setType,
  [FasteningElementTypes.SET_AMOUNT]: setAmount,
  [FasteningElementTypes.SET_ITEMS]: setItems,
  [FasteningElementTypes.SET_DRILLING_SCHEME]: setDrillingScheme,
  [FasteningElementTypes.SET_USE_CURTAIN]: setUseCurtain,
  [FasteningElementTypes.SET_HINGE_TYPE]: setHingeType,
  [FasteningElementTypes.SET_LIFTING_MECHANISM_TYPE]: setLiftingMechanismType,
  [FasteningElementTypes.SET_LIFTING_MECHANISM_BOTTOM_TYPE]: setLiftingMechanismBottomType,
  [FasteningElementTypes.SET_STANDARD_SERVO_DRIVE_TYPE]: setStandardServoDriveType,
  [FasteningElementTypes.SET_WITH_BOTTOM_MODULE]: setWithBottomModule,
  [FasteningElementTypes.SET_USER_CHANGED_COORDS]: setUserChangedCoords,
  [FasteningElementTypes.RESET_FASTENING_ELEMENT]: resetFasteningElement,

  [FasteningElementTypes.TOGGLE_FASTENING_ELEMENT_MODAL]: toggleFasteningElementModal,
  [FasteningElementTypes.TOGGLE_WARNING_DISTANCE_MODAL]: toggleWarningDistanceModal,
  [FasteningElementTypes.TOGGLE_WARNING_HEIGHT_MODAL]: toggleWarningHeightModal,
  [FasteningElementTypes.TOGGLE_WARNING_HANDLE_MODAL]: toggleWarningHandleModal,
});
