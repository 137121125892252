import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars';
import { ReactSVG } from 'react-svg';

import AuthService from '../../services/authService';

import DoorsActions from '../../redux/actions/doorsAndSections';
import DrillingHolesAction from '../../redux/actions/drillingHoles';
import DrillingPearHolesAction from '../../redux/actions/drillingPearHoles';
import FasteningElementAction from '../../redux/actions/fasteningElement';
import { numberWithSpaces } from '../../helpers/sanitizer';

const DoorsNavigation = ({ tabs }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';

  const frameTitleRefs = useRef([]);

  const {
    doors,
    main: {
      doorOpeningHeight,
      doorOpeningWidth,
    },
    activeDoor = 0,
    activeSection = 0,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);
  const { frameSpecification, currentOrderId } = useSelector(({ order }) => order);
  const { currentSystem } = useSelector(({ systems }) => systems);
  const { isOpenFillingModal } = useSelector(({ fillingMaterials }) => fillingMaterials);
  const { showPrice } = useSelector(({ profile }) => profile);

  const handleTabChange = (e, index) => {
    e.preventDefault();
    if (isOpenFillingModal) return;

    if (currentSystem === 'frame-facades') {
      dispatch(DrillingHolesAction.toggleDrillingHoles(false));
      dispatch(DrillingPearHolesAction.toggleDrillingPearHoles(false));
      dispatch(FasteningElementAction.toggleFasteningElementModal(false));
      dispatch(DoorsActions.setActiveDoor(index));
      return;
    }

    if (!(doorOpeningHeight?.value && doorOpeningWidth?.value)) {
      dispatch(DoorsActions.hightlightDoorOpeningInputs(labelKey));
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    dispatch(DoorsActions.setActiveDoor(index));
    dispatch(DoorsActions.setActiveSection(0));
  };

  const handleSectionChange = (e, doorIndex, sectionIndex) => {
    e.preventDefault();
    if (isOpenFillingModal) return;

    dispatch(DoorsActions.setActiveDoor(doorIndex));
    dispatch(DoorsActions.setActiveSection(sectionIndex));
  };

  const renderSectionsNav = (sections, doorIndex) => {
    if (!sections?.length || !doorIndex) return null;

    const sectionsLabels = sections.map(({ sequenceNumber }, i) => ({
      title: t('fillingMaterialsModal.section-n', { doorNumber: doorIndex, sectionNumber: i + 1 }),
      sequenceNumber,
    }));

    const nav = sectionsLabels.map((section, sectionIndex) => {
      const sectionClassName = clsx('sticky-menu-tab-section',
        activeDoor === doorIndex && activeSection === sectionIndex + 1 && 'active');

      return (
        <a
          href="/"
          key={section.sequenceNumber}
          className={sectionClassName}
          onClick={(e) => handleSectionChange(e, doorIndex, sectionIndex + 1)}
          tabIndex={0}
        >
          {section.title}
        </a>
      );
    });

    return nav;
  };

  const renderDoorsNav = (tabsTitle) => tabsTitle.map((tab, index) => {
    const doorClassName = clsx('sticky-menu-tab', activeDoor === index && 'active');
    const dividerClassName = clsx('doors-navigation--divider', activeDoor === index && 'active');
    const door = doors.length ? doors[index - 1] : {};
    const sections = door?.sections || [];

    return (
      <span key={`door-nav-${index + 1}`} className="sticky-menu-tab-wrapper">
        <a
          href="/"
          key={`door-${index + 1}`}
          className={doorClassName}
          onClick={(e) => handleTabChange(e, index)}
          tabIndex={0}
        >
          {tab.title}
        </a>
        {renderSectionsNav(sections, index)}
        <div className={dividerClassName} key={`divider-${index + 1}`} />
      </span>
    );
  });

  const renderFramesNav = (tabsTitle) => tabsTitle.map((tab, index) => {
    const doorClassName = clsx('frame-tab', activeDoor === index && 'active');
    const totalFramePrice = frameSpecification?.specificationByFrame?.find((s) => s.frameNumber === index)?.specification?.totalPrice;

    let isClipped = false;

    const titleRef = frameTitleRefs.current[index];
    if (titleRef) {
      const { scrollWidth } = titleRef;
      const { clientWidth } = titleRef;
      if (clientWidth < scrollWidth) isClipped = true;
    }

    return (

      <span key={`door-nav-${index + 1}`} className="sticky-menu-tab-wrapper frame">
        <div
          href="/"
          key={`door-${index + 1}`}
          className={doorClassName}
          onClick={(e) => handleTabChange(e, index)}
          role="button"
          tabIndex={0}
        >

          <span
            ref={(el) => { frameTitleRefs.current[index] = el; }}
            className="frame-title"
          >
            {tab.title}
          </span>

          {isClipped && (
            <span className="frame-tooltip">
              {tab.title}
            </span>
          )}

          {(index > 0 && totalFramePrice && (currentOrderId || showPrice || !AuthService.isLoggedIn()))
           && (
             <>
               <span className="frame-dash">
                 &ndash;
               </span>
               <ReactSVG
                 className={clsx('frame-icon', activeDoor === index && 'active')}
                 wrapper="div"
                 src="/src/client/assets/icons/currency-uah.svg"
               />
               <span className="frame-price">
                 {numberWithSpaces(totalFramePrice)}
               </span>
             </>
           )}
        </div>
      </span>
    );
  });

  return (
    <Scrollbars
      className="doors-scroll"
      autoHeight
      style={{ width: 210, minWidth: 210 }}
      autoHeightMin="10vh"
      autoHeightMax="90vh"
      renderTrackVertical={({ style }) => <div className="scrollbar-background" style={{ ...style }} />}
      renderThumbVertical={({ style }) => <div className="scrollbar-line" style={{ ...style }} />}
      renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" />}
      renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" />}
    >
      <div className="doors-navigation">
        <div className="doors-navigation--inner">
          {currentSystem === 'frame-facades'
            ? renderFramesNav(tabs)
            : renderDoorsNav(tabs)}
        </div>
      </div>
    </Scrollbars>
  );
};

DoorsNavigation.defaultProps = {
  tabs: null,
};

DoorsNavigation.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
  })),
};

export default DoorsNavigation;
