import React from 'react';
import { Line, Group, Label, Text, Tag } from 'react-konva';
import PropTypes from 'prop-types';

const SideSize = ({
  width,
  height,
  holes,
  modalHoles,
  isOpenDrillingHoles,
  isLiftingMechanism,
  isBottomModule,
  isScaled,
  paddingTop,
  paddingLeft,
  paddingBottom,
  fontSize,
  scale,
}) => {
  const WIDTH = width;
  const HEIGHT = height;
  const TOP_PADDING = 10;
  // const TOP_LEFT_PADDING = 160;
  const TOP_SIDELINE_TOP = 0;
  const TOP_SIDELINE_BOTTOM = 20;
  const LEFT_SIDELINE_TOP = 10;
  const LEFT_SIDELINE_BOTTOM = 20;
  const ZERO_POINT = 0;
  const WIDTH_TWO = WIDTH + paddingLeft;
  const BOTTOM_LINE = HEIGHT + paddingTop + paddingBottom;
  const BOTTOM_EDGE = 10;

  const holesArray = isOpenDrillingHoles ? modalHoles : holes;
  const place = holesArray.placementSide;
  const xPoint = isScaled ? -7 : ZERO_POINT;
  const distanceSize = {
    14: 20,
    17: 25,
    20: 30,
    23: 35,
    26: 40,
    29: 45,
    32: 50,
    35: 55,
  };

  const HorizontalLine = () => (
    !isLiftingMechanism && place !== 'top' ? (
      <Group>
        {/* horizontal top line */}
        <Line
          points={[paddingLeft, TOP_PADDING, paddingLeft + WIDTH / 2 - distanceSize[fontSize], TOP_PADDING]}
          stroke="black"
          strokeWidth={2}
        />

        <Line
          points={[paddingLeft + WIDTH / 2 + distanceSize[fontSize], TOP_PADDING, WIDTH_TWO, TOP_PADDING]}
          stroke="black"
          strokeWidth={2}
        />

        {/* left vertical line */}
        <Line
          points={[paddingLeft, TOP_SIDELINE_TOP, paddingLeft, TOP_SIDELINE_BOTTOM]}
          stroke="black"
          strokeWidth={1}
        />

        {/* right vertical line */}
        <Line
          points={[WIDTH + paddingLeft, TOP_SIDELINE_TOP, WIDTH + paddingLeft, TOP_SIDELINE_BOTTOM]}
          stroke="black"
          strokeWidth={1}
        />

        <Label x={paddingLeft + WIDTH / 2 - (distanceSize[fontSize] / 2.25) - (fontSize * scale)} y={-5}>
          <Tag fill="transparent" stroke="transparent" />
          <Text text={WIDTH} padding={10} fontSize={fontSize} />
        </Label>
      </Group>
    ) : (
      <Group>
        {/* horizontal bottom line */}
        <Line
          points={[paddingLeft, BOTTOM_LINE, paddingLeft + WIDTH / 2 - distanceSize[fontSize], BOTTOM_LINE]}
          stroke="black"
          strokeWidth={2}
        />

        <Line
          points={[paddingLeft + WIDTH / 2 + distanceSize[fontSize], BOTTOM_LINE, WIDTH_TWO, BOTTOM_LINE]}
          stroke="black"
          strokeWidth={2}
        />

        {/* left vertical line */}
        <Line
          points={[paddingLeft, BOTTOM_LINE - BOTTOM_EDGE, paddingLeft, BOTTOM_LINE + BOTTOM_EDGE]}
          stroke="black"
          strokeWidth={1}
        />
        {/* right vertical line */}
        <Line
          points={[
            WIDTH + paddingLeft,
            BOTTOM_LINE - BOTTOM_EDGE,
            WIDTH + paddingLeft,
            BOTTOM_LINE + BOTTOM_EDGE,
          ]}
          stroke="black"
          strokeWidth={1}
        />

        <Label x={paddingLeft + WIDTH / 2 - (distanceSize[fontSize] / 2.25) - (fontSize * scale)} y={BOTTOM_LINE - (TOP_PADDING / 2)}>
          <Tag fill="transparent" stroke="transparent" />
          <Text text={WIDTH} padding={10} fontSize={fontSize} />
        </Label>
      </Group>
    )
  );

  const VerticalLine = () => (
    <Group>
      <Line
        points={[LEFT_SIDELINE_TOP, paddingTop, LEFT_SIDELINE_TOP, HEIGHT / 2 - (distanceSize[fontSize]) + paddingTop]}
        stroke="black"
        strokeWidth={2}
      />

      <Line
        points={[
          LEFT_SIDELINE_TOP,
          HEIGHT / 2 + (distanceSize[fontSize]) + paddingTop,
          LEFT_SIDELINE_TOP,
          HEIGHT + paddingTop,
        ]}
        stroke="black"
        strokeWidth={2}
      />

      <Line
        points={[ZERO_POINT, paddingTop, LEFT_SIDELINE_BOTTOM, paddingTop]}
        stroke="black"
        strokeWidth={1}
      />

      <Line
        points={[ZERO_POINT, HEIGHT + paddingTop, LEFT_SIDELINE_BOTTOM, HEIGHT + paddingTop]}
        stroke="black"
        strokeWidth={1}
      />

      <Label x={xPoint} y={HEIGHT / 2 + paddingTop + (distanceSize[fontSize] / 2) + (fontSize * scale)} rotation={270}>
        <Tag fill="transparent" stroke="transparent" />
        <Text text={HEIGHT} padding={5} fontSize={fontSize} />
      </Label>
    </Group>
  );

  const BottomModuleHeight = () => (
    <Group>
      {/* 1 part */}

      <Line
        points={[
          LEFT_SIDELINE_TOP + 40,
          paddingTop,
          LEFT_SIDELINE_TOP + 40,
          (HEIGHT / 4 - 20) + paddingTop,
        ]}
        stroke="black"
        strokeWidth={2}
      />

      <Line
        points={[
          LEFT_SIDELINE_TOP + 40,
          (HEIGHT / 4 + 20) + paddingTop,
          LEFT_SIDELINE_TOP + 40,
          (HEIGHT / 2 - 2) + paddingTop,
        ]}
        stroke="black"
        strokeWidth={2}
      />

      <Line
        points={[ZERO_POINT + 40, paddingTop, LEFT_SIDELINE_BOTTOM + 40, paddingTop]}
        stroke="black"
        strokeWidth={1}
      />

      <Line
        points={[ZERO_POINT + 40,
        (HEIGHT / 2 - 2) + paddingTop,
        LEFT_SIDELINE_BOTTOM + 40,
        (HEIGHT / 2 - 2) + paddingTop]}
        stroke="black"
        strokeWidth={1}
      />

      <Label x={ZERO_POINT + 40} y={HEIGHT / 4 + paddingTop + 13} rotation={270}>
        <Text text={HEIGHT / 2} padding={5} />
      </Label>

      {/* 2 part */}

      <Line
        points={[
          LEFT_SIDELINE_TOP + 40,
          (HEIGHT / 2 + 2) + paddingTop,
          LEFT_SIDELINE_TOP + 40,
          (HEIGHT / 2 + 2) + (HEIGHT / 4) + paddingTop - 20,
        ]}
        stroke="black"
        strokeWidth={2}
      />

      <Line
        points={[
          LEFT_SIDELINE_TOP + 40,
          (HEIGHT / 2 + 2) + (HEIGHT / 4) + paddingTop + 20,
          LEFT_SIDELINE_TOP + 40,
          HEIGHT + paddingTop,
        ]}
        stroke="black"
        strokeWidth={2}
      />

      <Line
        points={[ZERO_POINT + 40,
        (HEIGHT / 2 + 2) + paddingTop,
        LEFT_SIDELINE_BOTTOM + 40, (HEIGHT / 2 + 2) + paddingTop]}
        stroke="black"
        strokeWidth={1}
      />

      <Line
        points={[ZERO_POINT + 40, HEIGHT + paddingTop, LEFT_SIDELINE_BOTTOM + 40, HEIGHT + paddingTop]}
        stroke="black"
        strokeWidth={1}
      />

      <Label x={ZERO_POINT + 40} y={(HEIGHT / 4) * 3 + paddingTop + 13} rotation={270}>
        <Text text={HEIGHT / 2} padding={5} />
      </Label>
    </Group>
  );

  return (
    <>
      <HorizontalLine />
      <VerticalLine />
      {isBottomModule && (<BottomModuleHeight />)}
    </>
  );
};

SideSize.defaultProps = {
  modalHoles: {},
  holes: {},
  isLiftingMechanism: false,
  isBottomModule: false,
  isScaled: false,
  paddingTop: 0,
  paddingLeft: 0,
  paddingBottom: 0,
  fontSize: 0,
  scale: null,
};

SideSize.propTypes = {
  height: PropTypes.number.isRequired,
  holes: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isOpenDrillingHoles: PropTypes.bool.isRequired,
  modalHoles: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  width: PropTypes.number.isRequired,
  isLiftingMechanism: PropTypes.bool,
  isBottomModule: PropTypes.bool,
  isScaled: PropTypes.bool,
  paddingLeft: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  fontSize: PropTypes.number,
  scale: PropTypes.number,
};

export default SideSize;
