import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setFasteningElement: ['params'],
  setPrevFasteningElement: ['params'],

  setType: ['elementType'],
  setAmount: ['amount'],
  setDrillingScheme: ['drillingScheme'],
  setItems: ['items'],
  setUseCurtain: ['isUse'],
  setHingeType: ['hingeType'],
  setLiftingMechanismType: ['liftingMechanismType'],
  setLiftingMechanismBottomType: ['liftingMechanismType'],
  setStandardServoDriveType: ['standardServoDriveType'],
  setWithBottomModule: ['isAdded'],
  setUserChangedCoords: ['isChanged'],

  resetFasteningElement: [null],

  toggleFasteningElementModal: ['isOpen'],
  toggleWarningDistanceModal: ['isOpen'],
  toggleWarningHeightModal: ['isOpen'],
  toggleWarningHandleModal: ['isOpen'],
});

export const FasteningElementTypes = Types;
export default Creators;
