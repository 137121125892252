import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';


const DeleteDoorModal = ({
  isOpen,
  onCloseModal,
  onDeleteDoor,
  className,
  doorNumber,
  currentSystem,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleDeleteDoor = () => {
    onDeleteDoor(doorNumber - 1);
    onCloseModal();
  };

  const handleShowTitle = (door) => {
    if (door % 2 === 0) {
      const firstDoorNumber = door - 1;
      const secondDoorNumber = door;

      return t('deleteDoorModal.assembling-system-title', {
        firstDoorNumber,
        secondDoorNumber,
      });
    }

    const firstDoorNumber = door;
    const secondDoorNumber = door + 1;

    return t('deleteDoorModal.assembling-system-title', {
      firstDoorNumber,
      secondDoorNumber,
    });
  };

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
    >
      <h2 className="headings-h2">
        {currentSystem === 'assembling'
          ? handleShowTitle(doorNumber)
          : t('deleteDoorModal.title', { doorNumber })}
      </h2>
      <div className="content-wrapper">
        <div className="content-wrapper-inner">
          <span>{t('deleteDoorModal.subtitle')}</span>
        </div>
      </div>
      <div className="action-buttons">
        <div className="action-buttons-inner">
          <button
            type="button"
            className="link-button"
            onClick={onCloseModal}
          >
            <span>{t('deleteDoorModal.cancel')}</span>
          </button>
          <button
            type="button"
            className="blue-button"
            onClick={handleDeleteDoor}
          >
            <span>{t('deleteDoorModal.submit')}</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

DeleteDoorModal.defaultProps = {
  isOpen: false,
  className: 'action-modal delete-door',
};

DeleteDoorModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  onDeleteDoor: PropTypes.func.isRequired,
  doorNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  currentSystem: PropTypes.string.isRequired,
};

export default DeleteDoorModal;
