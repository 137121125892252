import { createReducer } from 'reduxsauce';

import { AuthTypes } from '../actions/auth';
import AuthService from '../../services/authService';

const INITIAL_STATE = {
  isLoggedIn: AuthService.isLoggedIn() && AuthService.isPhoneNumberVerified(),
  token: null,
};

const loginSuccess = (token) => ({
  token,
  isLoggedIn: AuthService.isLoggedIn() && AuthService.isPhoneNumberVerified(),
});

const logout = () => ({
  token: null,
  isLoggedIn: false,
});

export default createReducer(INITIAL_STATE, {
  [AuthTypes.LOGIN_SUCCESS]: loginSuccess,
  [AuthTypes.LOGOUT]: logout,
});
