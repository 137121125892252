import React from 'react';
import { Line } from 'react-konva';
import PropTypes from 'prop-types';

const CornerLines = ({
  width,
  height,
  toothWidth,
  frameWidthDistance,
  paddingTop,
  paddingLeft,
}) => {
  const WIDTH = width + paddingLeft;
  const HEIGHT = height + paddingTop;

  return (
    <>
      {/* Lines top left */}
      <Line
        points={[
          paddingLeft + toothWidth,
          paddingTop + toothWidth,
          paddingLeft + frameWidthDistance,
          paddingTop + frameWidthDistance,
        ]}
        stroke="black"
        strokeWidth={1}
      />

      {/* Lines top right */}
      <Line
        points={[
          WIDTH - toothWidth,
          paddingTop + toothWidth,
          WIDTH - frameWidthDistance,
          paddingTop + frameWidthDistance,
        ]}
        stroke="black"
        strokeWidth={1}
      />

      {/* Lines bottom right */}
      <Line
        points={[
          WIDTH - toothWidth,
          HEIGHT - toothWidth,
          WIDTH - frameWidthDistance,
          HEIGHT - frameWidthDistance,
        ]}
        stroke="black"
        strokeWidth={1}
      />

      {/* Lines bottom left */}
      <Line
        points={[
          paddingLeft + toothWidth,
          HEIGHT - toothWidth,
          paddingLeft + frameWidthDistance,
          HEIGHT - frameWidthDistance,
        ]}
        stroke="black"
        strokeWidth={1}
      />
    </>
  );
};

CornerLines.defaultProps = {
  frameWidthDistance: 0,
  toothWidth: 0,
  paddingLeft: 0,
  paddingTop: 0,
};

CornerLines.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  toothWidth: PropTypes.number,
  frameWidthDistance: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingTop: PropTypes.number,
};

export default CornerLines;
