import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleSelect } from 'react-selectize';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Label from '../Label';

const Dropdown = ({
  id,
  className,
  label,
  infoTagValue,
  withInfoTag,
  options,
  onChange,
  placeholder,
  value,
  isDisabled,
  isClearable,
  isSearchable,
  translationNs,
  hasInternalTranslation,
  hideResetButton,
}) => {
  const { t, i18n } = useTranslation(['options']);
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';
  const simpleSelectRef = useRef(null);
  const [error, setError] = useState(null);
  const simpleSelectClassName = clsx(className, (error && !value) && 'error');

  const emptyImg = '/src/client/assets/images/no-image.jpg';
  const onImageError = (e) => {
    e.target.src = emptyImg;
  };

  let valueToDisplay = value;
  let formattedOptions = [];

  if (translationNs) {
    formattedOptions = options.map((opt) => ({
      ...opt,
      label: t(`${translationNs}.${opt.value}`),
    }));

    const selectedOpt = value && options.find((op) => op.value === value.value || op.value === value.articleCode);

    valueToDisplay = value
      ? {
        image: value.image,
        value: value.value || value.articleCode,
        label: (selectedOpt && t(`${translationNs}.${selectedOpt.value}`)) || value.label,
      } : null;
  } else if (hasInternalTranslation) {
    formattedOptions = options.map((opt) => ({
      ...opt,
      label: opt[labelKey],
    }));

    const selectedOpt = value && options.find((op) => op.value === value.value || op.value === value.articleCode);

    valueToDisplay = value
      ? {
        image: value.image,
        value: value.value || value.articleCode,
        label: selectedOpt && selectedOpt[labelKey] ? selectedOpt[labelKey] : value.label,
      } : null;
  } else {
    formattedOptions = options;
  }

  const handleValueChange = (selectedOption) => {
    onChange(selectedOption);
    simpleSelectRef.current.blur();
  };

  return (
    <>
      {label && (
        <Label
          htmlFor="react-selectize"
          value={label}
          infoTagValue={infoTagValue}
          withInfoTag={withInfoTag}
        />
      )}
      <div className="dropdown-wrapper">
        <SimpleSelect
          id={id}
          className={simpleSelectClassName}
          ref={simpleSelectRef}
          value={valueToDisplay}
          theme="bootstrap3"
          placeholder={placeholder}
          onValueChange={handleValueChange}
          options={formattedOptions}
          isDisabled={isDisabled}
          isClearable={isClearable}
          hideResetButton={hideResetButton}
          onFocus={({ originalEvent }) => {
            const { target } = originalEvent;
            if (target && !isSearchable) {
              target.readOnly = true;
            }
          }}
          onBlur={({ value: selectedValue }) => {
            setError(
              selectedValue ? '' : t('components:errorMessages.empty-field'),
            );
          }}
          renderValue={(item) => (
            <div className="dropdown-value">
              {(!item.image && !item.color)
                || (item.image && (
                  <img
                    alt={item.label}
                    src={item.image}
                    className="dropdown-value-image"
                    onError={onImageError}
                  />
                ))
              || (item.color && (
                <div
                  className="dropdown-value-image"
                  style={{ background: item?.color }}
                />
              ))}
              <span className="dropdown-value-label">
                {item.label || item[labelKey]}
              </span>
            </div>
          )}
          renderOption={(item) => {
            const selectedValue = simpleSelectRef?.current?.value()?.value
              || simpleSelectRef?.current?.value()?.articleCode;
            const isSelected = !!selectedValue && selectedValue === item.value;

            return (
              <div
                className={clsx('dropdown-option', isSelected && 'selected')}
              >
                {(!item.image && !item.color)
                || (item.image && (
                  <img
                    alt={item.label}
                    src={item.image}
                    className="dropdown-option-image"
                    onError={onImageError}
                  />
                ))
              || (item.color && (
                <div
                  className="dropdown-option-image"
                  style={{ background: item?.color }}
                />
              ))}
                <span className="dropdown-option-label">{item.label}</span>
              </div>
            );
          }}
          renderNoResultsFound={() => (
            <div className="no-results-found">{t('options:no-data')}</div>
          )}
          menuPlacement="auto"
          backspaceRemovesValue={false}
          captureMenuScroll
          blurInputOnSelect
          transitionEnter
          transitionLeave
        />
        {(error && !value) && <span className="dropdown-error">{error}</span>}
      </div>
    </>
  );
};


Dropdown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
  value: PropTypes.shape({
    image: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    articleCode: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  withInfoTag: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  infoTagValue: PropTypes.string,
  translationNs: PropTypes.string,
  hasInternalTranslation: PropTypes.bool,
  hideResetButton: PropTypes.bool,
};

Dropdown.defaultProps = {
  id: '',
  className: '',
  label: null,
  value: null,
  placeholder: null,
  withInfoTag: false,
  isDisabled: false,
  isClearable: true,
  isSearchable: false,
  infoTagValue: '',
  translationNs: null,
  hasInternalTranslation: false,
  hideResetButton: true,
};

export default Dropdown;
