
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'hookrouter';
import { useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';

import SystemsActions from '../../redux/actions/systems';
import ConfigActions from '../../redux/actions/config';
import DoorsActions from '../../redux/actions/doorsAndSections';
import FillingActions from '../../redux/actions/fillingMaterials';
import DiscountsActions from '../../redux/actions/discounts';
import PriceListActions from '../../redux/actions/priceList';
import ProfileActions from '../../redux/actions/profile';
import DrillingHolesAction from '../../redux/actions/drillingHoles';
import DrillingPearHolesAction from '../../redux/actions/drillingPearHoles';
import FasteningElementAction from '../../redux/actions/fasteningElement';

import DoorsNavigation from '../../components/DoorsNavigation';
import StickyMenu from '../../components/StickyMenu';
import Visualisation from '../../components/Visualisation';
import VisualisationFacades from '../../components/VisualisationFacades';
import ZoomVisualisationModal from '../../components/ZoomVisualisationModal';

import AuthService from '../../services/authService';

import Main from '../layout/Main';
import FrameFacadesList from '../../components/SimplifiedFrameConfiguration/FrameFacadesList';

const FrameFacadesSystem = ({
  orderID,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useTitle(t('routes.frame-facades'));

  const [isDesktopView, setIsDesktopView] = useState(false);

  const { holesForHandles } = useSelector(({ drillingHoles }) => drillingHoles);
  const { pearHoles } = useSelector(({ drillingPearHoles }) => drillingPearHoles);
  const { fasteningElement: fasteningElementItem } = useSelector(({ fasteningElement }) => fasteningElement);
  const { mainFrame, doors, frameConfigurationView, activeDoor } = useSelector((
    { doorsAndSections },
  ) => doorsAndSections);
  const fillingMaterialsState = useSelector(({ fillingMaterials }) => fillingMaterials);
  const { activeTrigger, isOpenFillingModal } = fillingMaterialsState;

  useEffect(() => {
    dispatch(DiscountsActions.getDiscountsRequest());
    dispatch(PriceListActions.getPriceListRequest());
    if (AuthService.isLoggedIn()) dispatch(ProfileActions.getUserProfileRequest());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window || {};
      setIsDesktopView(innerWidth >= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      dispatch(DoorsActions.clearMainFrameState());
      dispatch(DoorsActions.toggleFrameConfigurationView('extended'));
      dispatch(DrillingHolesAction.toggleDrillingHoles(false));
      dispatch(FasteningElementAction.toggleFasteningElementModal(false));
      dispatch(DrillingPearHolesAction.toggleDrillingPearHoles(false));
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (_.isEmpty(orderID)) {
      dispatch(ConfigActions.getConfigRequest('frame-facades'));
      dispatch(SystemsActions.setCurrentSystem('frame-facades'));
    }
  }, []);

  const tabsLabels = doors.map(({ groupName }) => ({ title: groupName?.value }));
  tabsLabels.unshift({ title: t('frameFacades.basic') });

  const handleSubmitFilling = () => {
    const fillingMaterialToSet = {
      ...fillingMaterialsState[activeTrigger],
      ...{ material: activeTrigger },
    };

    dispatch(DoorsActions.setFrameFilling(activeDoor, [fillingMaterialToSet]));
    closeFillingChoise();
  };

  const handleSubmitDrillingHoles = () => {
    dispatch(DoorsActions.setHolesForHandles(activeDoor, holesForHandles));
    closeFillingChoise();
  };

  const handleSubmitDrillingPearHoles = () => {
    dispatch(DoorsActions.setPearHoles(activeDoor, pearHoles));
    closeFillingChoise();
  };
  const handleSubmitFasteningElement = () => {
    const { placementSide, amount } = activeDoor ? doors[activeDoor - 1]?.holesForHandles : mainFrame?.holesForHandles;

    if (fasteningElementItem?.type === 'lifting-mechanism' && (
      (placementSide === 'left' || placementSide === 'right' || placementSide === 'top') && amount > 0)) {
      dispatch(FasteningElementAction.toggleWarningHandleModal(true));
      return;
    }

    if (fasteningElementItem?.type === 'lifting-mechanism') {
      dispatch(DoorsActions.setOpeningSide(activeDoor, 'top'));

      if (amount === 0) {
        dispatch(DoorsActions.setHolesForHandles(activeDoor, {
          amount: 0,
          items: [],
          placementSide: 'bottom',
        }));
      }
    }

    if (fasteningElementItem?.type !== 'lifting-mechanism' && (
      (placementSide === 'top' || placementSide === 'bottom'))) {
      dispatch(DoorsActions.setOpeningSide(activeDoor, 'left'));
    }

    dispatch(DoorsActions.setFasteningElement(activeDoor, fasteningElementItem));
    closeFillingChoise();
  };

  const closeFillingChoise = () => {
    dispatch(FillingActions.resetFillingMaterialModal());
    dispatch(FillingActions.toggleFillingMaterialModal(false));
    dispatch(DrillingHolesAction.resetHoles());
    dispatch(DrillingHolesAction.toggleDrillingHoles(false));
    dispatch(FasteningElementAction.resetFasteningElement());
    dispatch(FasteningElementAction.toggleFasteningElementModal(false));
    dispatch(DrillingPearHolesAction.resetPearHoles());
    dispatch(DrillingPearHolesAction.toggleDrillingPearHoles(false));
  };

  return (
    <div className="frame-facades-page systems-page">
      <div className="sticky-container">
        <Main
          footerClassName={isOpenFillingModal && !isDesktopView ? 'back' : null}
          showBreadcrumbsDesktop
          onSubmitFilling={handleSubmitFilling}
          onSubmitDrilling={handleSubmitDrillingHoles}
          onSubmitDrillingPear={handleSubmitDrillingPearHoles}
          onSubmitFasteningElement={handleSubmitFasteningElement}
          canGoBack={false}
        >
          {frameConfigurationView === 'extended' ? (
            <div className="main-wrapper--page-content">
              <DoorsNavigation tabs={tabsLabels} />
              <Visualisation />
              <VisualisationFacades />
            </div>
          )
            : <FrameFacadesList />}

        </Main>
        {frameConfigurationView === 'extended' && <StickyMenu tabs={tabsLabels} />}
      </div>
      <ZoomVisualisationModal />

    </div>
  );
};

FrameFacadesSystem.defaultProps = {
  orderID: null,
};

FrameFacadesSystem.propTypes = {
  orderID: PropTypes.string,
};

export default withToastManager(FrameFacadesSystem);
