import React from 'react';
import { Image, Rect } from 'react-konva';
import PropTypes from 'prop-types';

import _ from 'lodash';
import useImage from '../../helpers/useImage';

import {
  dspListWithWhiteMargins,
  GLASS_GRADIENT,
  IMAGES_PATH,
  MIRROR_GRADIENT,
} from '../../helpers/visualisationHelper';
import fillingMaterials from '../../redux/actions/fillingMaterials';

const Background = ({
  width,
  height,
  filling = {},
  frameWidthDistance,
  isBottomModule,
  paddingTop,
  paddingLeft,
}) => {
  const RECTANGLE_PADDING = 2; // padding 1 and 2 rect
  const WIDTH_RECTANGLE = frameWidthDistance; // padding 2 and 3 rect
  const imgWidth = width - (RECTANGLE_PADDING * 2) - (frameWidthDistance * 2);
  const imgHeight = height - (RECTANGLE_PADDING * 2) - (frameWidthDistance * 2);
  const basicPointX = paddingLeft + WIDTH_RECTANGLE + RECTANGLE_PADDING;
  const basicPointY = paddingTop + WIDTH_RECTANGLE + RECTANGLE_PADDING;
  const bottomY = paddingTop + (WIDTH_RECTANGLE * 2) + (RECTANGLE_PADDING * 2) + imgHeight / 2 + 2;
  const halfHeight = imgHeight / 2 - 3 - WIDTH_RECTANGLE;

  let fillingImageSrc = '';
  const isCustomChipboard = filling[0]?.material === 'customers' && filling?.customersOption?.includes('dsp');
  const isADSChipboard = filling[0]?.material === 'dsp' && filling?.dspOption;
  const isMirror = filling[0]?.material === 'mirror' || filling[0]?.material === 'lacobel';
  const isGlass = (filling[0]?.material === 'customers' && filling[0]?.customersOption === 'glass')
    || filling[0]?.material === 'glass';
  const hasHorizontalRotation = filling[0]?.material !== 'customers';


  if (isCustomChipboard) { fillingImageSrc = IMAGES_PATH.chipboardPath; }

  if (isADSChipboard) {
    const { dspOption } = filling;
    const chipboardPath = dspOption && _.some(dspListWithWhiteMargins, (item) => item === dspOption)
      ? IMAGES_PATH.chipboardPath
      : dspOption
        ? fillingMaterials?.find((f) => f.articleCode === dspOption)?.image
        : '';

    fillingImageSrc = chipboardPath || IMAGES_PATH.chipboardPath;
  }

  const [fillingImageTwo] = useImage(fillingImageSrc);

  const RectBackground = () => (
    isBottomModule ? (
      <>
        <Rect
          x={basicPointX}
          y={basicPointY}
          width={imgWidth}
          height={halfHeight}
          fill="#E5E5E5"
          stroke="#E5E5E5"
        />

        <Rect
          x={basicPointX}
          y={bottomY}
          width={imgWidth}
          height={halfHeight}
          fill="#E5E5E5"
          stroke="#E5E5E5"
        />
      </>
    ) : (
      <Rect
        x={basicPointX}
        y={basicPointY}
        width={imgWidth}
        height={imgHeight}
        fill="#E5E5E5"
        stroke="#E5E5E5"
      />
    )
  );

  const ImageBackground = () => (
    isBottomModule ? (
      <>
        <Image
          x={basicPointX}
          y={basicPointY}
          width={imgWidth}
          height={halfHeight}
          visible={fillingImageTwo || isMirror || isGlass}
          fillPatternImage={isMirror || isGlass ? null : fillingImageTwo}
          fillPatternOffsetX={paddingLeft + WIDTH_RECTANGLE + RECTANGLE_PADDING}
          fillPatternRepeat="repeat"
          fillPatternRotation={hasHorizontalRotation ? 90 : 0}
          fillLinearGradientStartPoint={isMirror || isGlass ? { x: 0, y: 0 } : null}
          fillLinearGradientEndPoint={isMirror || isGlass ? { x: width, y: height } : null}
          fillLinearGradientColorStops={isMirror ? MIRROR_GRADIENT : isGlass ? GLASS_GRADIENT : []}
        />

        <Image
          x={basicPointX}
          y={bottomY}
          width={imgWidth}
          height={halfHeight}
          visible={fillingImageTwo || isMirror || isGlass}
          fillPatternImage={isMirror || isGlass ? null : fillingImageTwo}
          fillPatternOffsetX={paddingLeft + WIDTH_RECTANGLE + RECTANGLE_PADDING}
          fillPatternRepeat="repeat"
          fillPatternRotation={hasHorizontalRotation ? 90 : 0}
          fillLinearGradientStartPoint={isMirror || isGlass ? { x: 0, y: 0 } : null}
          fillLinearGradientEndPoint={isMirror || isGlass ? { x: width, y: height } : null}
          fillLinearGradientColorStops={isMirror ? MIRROR_GRADIENT : isGlass ? GLASS_GRADIENT : []}
        />
      </>
    ) : (
      <Image
        x={basicPointX}
        y={basicPointY}
        width={imgWidth}
        height={imgHeight}
        visible={fillingImageTwo || isMirror || isGlass}
        fillPatternImage={isMirror || isGlass ? null : fillingImageTwo}
        fillPatternOffsetX={paddingLeft + WIDTH_RECTANGLE + RECTANGLE_PADDING}
        fillPatternRepeat="repeat"
        fillPatternRotation={hasHorizontalRotation ? 90 : 0}
        fillLinearGradientStartPoint={isMirror || isGlass ? { x: 0, y: 0 } : null}
        fillLinearGradientEndPoint={isMirror || isGlass ? { x: width, y: height } : null}
        fillLinearGradientColorStops={isMirror ? MIRROR_GRADIENT : isGlass ? GLASS_GRADIENT : []}
      />
    )
  );

  return (
    _.isEmpty(filling) ? (
      <RectBackground />
    ) : (
      <ImageBackground />
    )
  );
};

Background.defaultProps = {
  frameWidthDistance: 0,
  isBottomModule: false,
  paddingTop: 0,
  paddingLeft: 0,
};

Background.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  frameWidthDistance: PropTypes.number,
  isBottomModule: PropTypes.bool,
  filling: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.instanceOf(Array),
  ]).isRequired,
  paddingTop: PropTypes.number,
  paddingLeft: PropTypes.number,
};

export default Background;
