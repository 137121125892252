export const profilesAxisValidation = {
  'P-31': {
    materialForDrilling: 'glass',
    holeDiameter: 7,
    min: 11,
    max: 35,
    defaultValue: 25,
    bushing: true,
  },
  'P-32': {
    materialForDrilling: 'profile',
    holeDiameter: 5,
    min: 10,
    max: 10,
    defaultValue: 10,
    bushing: false,
  },
  'P-33': {
    additionalValue: 8,
    min: 20,
    max: 35,
    defaultValue: 30,
  },
  'P-34': {
    materialForDrilling: 'glass',
    holeDiameter: 7,
    min: 10,
    max: 35,
    defaultValue: 22,
    bushing: true,
  },
  'P-36': {
    materialForDrilling: 'glass',
    holeDiameter: 7,
    min: 10,
    max: 35,
    defaultValue: 22,
    bushing: true,
  },
  'P-37': {
    materialForDrilling: 'profile',
    holeDiameter: 5,
    min: 10,
    max: 40,
    defaultValue: 25,
    bushing: false,
  },
};

export const fasteningElementsOptions = {
  'P-31': {
    drillingSchemes: [
      {
        value: 'frez_d35_45_9',
        label: 'Фрезерування D35 (Схема 45/9,5)',
      }, {
        value: 'frez_d35',
        label: 'Фрезерування D35 (лише чашка)',
      },
    ],
  },
  'P-32': {
    drillingSchemes: [
      {
        value: 'Blum 95',
        label: 'Blum 95',
      }, {
        value: 'Blum Clip top 120',
        label: 'Blum Clip top 120',
      }, {
        value: 'Grass Tiomos 110AL',
        label: 'Grass Tiomos 110AL',
      }, {
        value: 'Hettich Sensys 8638i',
        label: 'Hettich Sensys 8638i',
      }, {
        value: 'hinge-ADS',
        label: 'Петля ADS',
      },
    ],
  },
  'P-33': {
    drillingSchemes: [
      {
        value: 'frez_d35_45_9',
        label: 'Фрезерування D35 (Схема 45/9,5)',
      }, {
        value: 'frez_d35',
        label: 'Фрезерування D35 (лише чашка)',
      },
    ],
  },
  'P-34': {
    drillingSchemes: [
      {
        value: 'frez_d35_45_9',
        label: 'Фрезерування D35 (Схема 45/9,5)',
      }, {
        value: 'frez_d35',
        label: 'Фрезерування D35 (лише чашка)',
      },
    ],
  },
  'P-36': {
    drillingSchemes: [
      {
        value: 'frez_d35_45_9',
        label: 'Фрезерування D35 (Схема 45/9,5)',
      }, {
        value: 'frez_d35',
        label: 'Фрезерування D35 (лише чашка)',
      },
    ],
  },
  'P-37': {
    drillingSchemes: [
      {
        value: 'frez_d35_45_9',
        label: 'Фрезерування D35 (Схема 45/9,5)',
      }, {
        value: 'frez_d35',
        label: 'Фрезерування D35 (лише чашка)',
      },
    ],
  },
  'P-38': {
    drillingSchemes: [
      {
        value: 'frez_d35_45_9',
        label: 'Фрезерування D35 (Схема 45/9,5)',
      }, {
        value: 'frez_d35',
        label: 'Фрезерування D35 (лише чашка)',
      },
    ],
  },
};

export const liftingMechanismTypes = [
  {
    value: 'aventos_hf_top',
    label: 'aventos_hf_top',
  }, {
    value: 'aventos_hf_bottom',
    label: 'aventos_hf_bottom',
  }, {
    value: 'aventos_hk_top',
    label: 'Aventos HK Top',
  }, {
    value: 'aventos_hk_s',
    label: 'Aventos HK S',
  }, {
    value: 'aventos_hl',
    label: 'Aventos HL',
  },
];

export const standardServoDriveOptions = [
  {
    value: '20L3200_06_21L3200_01',
    label: '20L3200.06 / 21L3200.01',
  }, {
    value: '20L3500_06_21L3500_01',
    label: '20L3500.06 / 21L3500.01',
  }, {
    value: '20L3800_06_21L3800_01',
    label: '20L3800.06 / 21L3800.01',
  }, {
    value: '20L3900_06_21L3900_01',
    label: '20L3900.06 / 21L3900.01',
  },
];

export const standardServoDriveDistance = {
  '20L3200_06_21L3200_01': 153,
  '20L3500_06_21L3500_01': 203,
  '20L3800_06_21L3800_01': 253,
  '20L3900_06_21L3900_01': 303,
};

export const liftingMechanismSizes = {
  aventos_hf_top: {
    minHeight: 480,
    maxHeight: 1040,
    minWidth: 215,
    maxWidth: 650,
  },
  aventos_hk_top: {
    minHeight: 205,
    maxHeight: 500,
    minWidth: 215,
    maxWidth: 650,
  },
  aventos_hk_s: {
    minHeight: 205,
    maxHeight: 500,
    minWidth: 215,
    maxWidth: 650,
  },
  aventos_hl: {
    minHeight: 205,
    maxHeight: 500,
    minWidth: 215,
    maxWidth: 650,
  },
};
