import _ from 'lodash';

export const sanitizeValueToNumber = (value) => Number(value.replace(/\D/g, ''));

export const sanitizeValueToNumberStringLike = (value) => value.replace(/[^0-9+]/g, '');

export const capitalizeFirstLetter = (word) => {
  if (_.isEmpty(word)) return '';

  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const removeLeadingZero = (value) => (value.indexOf(0) === 0 ? value.substring(1) : value);

export const blockInvalidChar = (event, invalidChars) => invalidChars.includes(event.key) && event.preventDefault();

export const numberWithSpaces = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const formatPhoneNumber = (phone) => {
  const startsWith = phone.slice(0, 3);
  const number = phone.slice(3);

  if (phone.includes('+38')) {
    return number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, `${startsWith} ($1) $2 $3 $4`);
  }

  if (phone.includes('+48')) {
    return number.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/g, `${startsWith} ($1) $2 $3 $4`);
  }
};
