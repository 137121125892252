import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import Modal from '../Modal';
import Button from '../Button';

const WarningInsufficientHeightModal = ({
  isOpen,
  className,
  content,
  actionType,
  onCloseModal,
  onSubmit,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const btnAction = {
    change: 'submit-change',
    increase: 'submit-increase',
    decrease: 'submit-decrease',
  };

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
    >
      <div className="modal-order-icon-wrapper">
        <ReactSVG
          wrapper="div"
          className="modal-order-icon"
          src="/src/client/assets/icons/price-package-icon.svg"
        />
      </div>
      <h2 className="headings-h2">{content}</h2>

      <div className="action-buttons">
        <div className="action-buttons-inner">
          <Button
            value={t('warningInsufficientHeightModal.return')}
            type="link-button"
            onClick={onCloseModal}
            isDisabled={false}
          />
          <Button
            value={t(`warningInsufficientHeightModal.${btnAction[actionType]}`)}
            type="rounded"
            onClick={onSubmit}
            isDisabled={false}
          />
        </div>
      </div>
    </Modal>
  );
};

WarningInsufficientHeightModal.defaultProps = {
  isOpen: false,
  className: 'action-modal warning-height',
};

WarningInsufficientHeightModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  actionType: PropTypes.oneOf(['change', 'increase', 'decrease']).isRequired,
  content: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default WarningInsufficientHeightModal;
