import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { dspListWithWhiteMargins } from '../../helpers/visualisationHelper';

import Button from '../Button';
import Label from '../Label';
import { getFillingDescription, getFillingImagePath } from '../../helpers/fillingHelper';

const FillingMaterialsControl = ({
  filling,
  label,
  onClick,
  className,
}) => {
  const { t, i18n } = useTranslation(['components']);
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';

  const { filling: fillingMaterials } = useSelector(({ config }) => config);

  const renderParams = () => filling.map((material, i) => {
    if (!material?.material) return null;

    const itemKey = material?.customersOption || material?.dspOption || material?.mirrorType
      || material?.lacobelType || material?.glassType;

    return (
      <div className="filling-materails-control__item" key={itemKey}>
        <div className="filling-materails-control--image">
          {renderIcon(material)}
        </div>
        <div
          key={`filling-${i + 1}`}
          className="filling-materails-control--item"
        >
          <div className="filling-materails-control--params-wrapper-title">
            {getFillingDescription(fillingMaterials, material, t, labelKey)?.title}
          </div>
          <div className="filling-materails-control--params-wrapper-text">
            {getFillingDescription(fillingMaterials, material, t, labelKey)?.option}
          </div>
        </div>
      </div>
    );
  });

  const renderIcon = (material) => (
    <div className="filling-materails-control--image-wrapper-inner">
      <img
        alt="img"
        src={getFillingImagePath(fillingMaterials, material, dspListWithWhiteMargins)}
      />
    </div>
  );

  return (
    <div className={className}>
      <div className="filling-materails-control-label-wrapper">
        <Label
          value={label}
          infoTagValue={t('tooltips.filling-material')}
          withInfoTag
        />
        <Button
          value={filling[0] && filling[0]?.material
            ? t('fillingMaterialsControl.change')
            : t('fillingMaterialsControl.choose')}
          onClick={onClick}
          type="outlined-white"
        />
      </div>
      <div className="filling-materails-control">
        {!_.isEmpty(filling[0] && filling[0]?.material) && (
          <div className="filling-materails-control--params-wrapper">
            {renderParams()}
          </div>
        )}
      </div>
    </div>
  );
};

FillingMaterialsControl.propTypes = {
  filling: PropTypes.arrayOf(PropTypes.shape({
    material: PropTypes.string,
  })).isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

FillingMaterialsControl.defaultProps = {
  className: '',
  label: '',
  onClick: () => {},
};

export default FillingMaterialsControl;
