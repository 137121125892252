import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';

import { OrderTypes } from '../actions/order';

const INITIAL_STATE = {
  isLoading: false,
  errorMessage: '',
  successMessage: '',
  specification: {},
  frameSpecification: {},
  region: '',
  title: '',
  currentOrderId: '',
  currentOrderNumber: '',
  shouldShowOrderPage: false,
  isOrderAccepted: false, // TODO: rename
  isOrderTitleModalOpen: false,
  isFetched: false,
  delivery: {
    code1C: '',
    type: '',
    postOffice: '',
    addressLine: '',
    city: '',
    region: '',
  },
  currentOrderModalData: {
    orderID: '',
    orderOrigin: {},
    orderRecalculated: {},
    isPackageChanged: false,
    isTotalPriceChanged: false,
    isDealerPriceChanged: false,
    isMissingItems: false,
    isDealeeOrder: false,
  },
};

/**
 * Reducers handlers
 */

const calculateOrderRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});

const calculateOrderSuccess = (state = INITIAL_STATE, { specification }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: '' },
  specification: {
    description: { $set: specification?.description || state.description },
    items: { $set: specification?.items || state.items },
    totalPrice: { $set: specification?.totalPrice || state.totalPrice },
    retailTotalPrice: { $set: specification?.retailTotalPrice || state.retailTotalPrice },
    user: { $set: specification?.user || state.user },
    earliestDiscountEndDate: { $set: specification?.earliestDiscountEndDate || state.earliestDiscountEndDate },
    priceWithDiscount: { $set: specification?.priceWithDiscount || false },
  },
});

const calculateFrameOrderFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});

const calculateFrameOrderRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});

const calculateFrameOrderSuccess = (state = INITIAL_STATE, { specification }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: '' },
  frameSpecification: {
    description: { $set: specification?.description || state.description },
    specification: { $set: specification?.specification || state.specification },
    specificationByFrame: { $set: specification?.specificationByFrame || state.specificationByFrame },
    totalPrice: { $set: specification?.totalPrice || state.totalPrice },
    retailTotalPrice: { $set: specification?.retailTotalPrice || state.retailTotalPrice },
    user: { $set: specification?.user || state.user },
    earliestDiscountEndDate: { $set: specification?.earliestDiscountEndDate || state.earliestDiscountEndDate },
    priceWithDiscount: { $set: specification?.priceWithDiscount || false },
  },
});

const calculateOrderFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});


const putOrderIntoWorkRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});

const putOrderIntoWorkSuccess = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: false },
  successMessage: { $set: 'successMessages.order-in-work' },
  errorMessage: { $set: '' },
  currentOrderModalData: {
    orderID: { $set: '' },
    orderOrigin: { $set: {} },
    orderRecalculated: { $set: {} },
    isPackageChanged: { $set: false },
    isTotalPriceChanged: { $set: false },
    isDealerPriceChanged: { $set: false },
  },
});

const putOrderIntoWorkFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});


const deleteOrderRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});

const deleteOrderSuccess = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: false },
  successMessage: { $set: 'successMessages.order-deleted' },
});

const deleteOrderFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});


const copyOrderRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});

const copyOrderSuccess = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: false },
  successMessage: { $set: 'successMessages.order-copied' },
});

const copyOrderClearSuccess = (state = INITIAL_STATE) => update(state, {
  successMessage: { $set: '' },
});

const copyOrderFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});


const updateOrderTitleRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});

const updateOrderTitleSuccess = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: false },
  successMessage: { $set: 'successMessages.order-updated' },
});

const updateOrderTitleFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});

const updateOrderPriceByDealerRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});

const updateOrderPriceByDealerSuccess = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: false },
  successMessage: { $set: 'successMessages.price-is-set' },
});

const updateOrderPriceByDealerFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});

const saveOrderRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});

const saveOrderSuccess = (state = INITIAL_STATE, { orderId, orderNumber }) => update(state, {
  isLoading: { $set: false },
  currentOrderId: { $set: orderId || state.currentOrderId },
  currentOrderNumber: { $set: orderNumber || state.currentOrderNumber },
  successMessage: { $set: 'successMessages.order-saved' },
});

const saveOrderFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});


const toggleShouldShowOrderPage = (state = INITIAL_STATE, { shouldOpen }) => update(state, {
  shouldShowOrderPage: { $set: shouldOpen },
});


const resetCurrentOrderData = (state = INITIAL_STATE) => update(state, {
  currentOrderId: { $set: '' },
  currentOrderNumber: { $set: '' },
  isOrderAccepted: { $set: false },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
  specification: { $set: INITIAL_STATE.specification },
  region: { $set: INITIAL_STATE.region },
  title: { $set: INITIAL_STATE.title },
});


const fetchOrderDataRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  isFetched: { $set: false },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});

const fetchOrderDataSuccess = (state = INITIAL_STATE, { orderId, orderNumber, title }) => update(state, {
  isLoading: { $set: false },
  isFetched: { $set: true },
  currentOrderId: { $set: orderId || state.currentOrderId },
  currentOrderNumber: { $set: orderNumber || state.currentOrderNumber },
  title: { $set: title || state.title },
});

const fetchOrderDataFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  isFetched: { $set: true },
  errorMessage: { $set: errorMessage },
});



const fetchOrderModalDataRequest = (state = INITIAL_STATE, { orderID }) => update(state, {
  currentOrderModalData: { orderID: { $set: orderID } },
});

const fetchOrderModalDataSuccess = (state = INITIAL_STATE, {
  orderID,
  orderOrigin,
  orderRecalculated,
  isPackageChanged,
  isTotalPriceChanged,
  isDealerPriceChanged,
  isMissingItems,
  isDealeeOrder,
}) => update(state, {
  currentOrderModalData: {
    orderID: { $set: orderID },
    orderOrigin: { $set: orderOrigin },
    orderRecalculated: { $set: orderRecalculated },
    isPackageChanged: { $set: isPackageChanged },
    isTotalPriceChanged: { $set: isTotalPriceChanged },
    isDealerPriceChanged: { $set: isDealerPriceChanged },
    isMissingItems: { $set: isMissingItems },
    isDealeeOrder: { $set: isDealeeOrder },
  },
});

const fetchOrderModalDataFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  errorMessage: { $set: errorMessage },
});


const setSpecificationBySnippet = (state = INITIAL_STATE, { specification }) => update(state, {
  specification: {
    description: { $set: specification?.description || state.description },
    items: { $set: specification?.items || state.items },
    totalPrice: { $set: specification?.totalPrice || state.totalPrice },
    totalPriceUAH: { $set: specification?.totalPriceUAH || state.totalPriceUAH },
    retailTotalPrice: { $set: specification?.retailTotalPrice || state.retailTotalPrice },
    retailTotalPriceUAH: { $set: specification?.retailTotalPriceUAH || state.retailTotalPriceUAH },
    userPrice: { $set: specification?.userPrice || state.userPrice },
    userPriceUAH: { $set: specification?.userPriceUAH || state.userPriceUAH },
    user: { $set: specification?.user || state.user },
  },
  isOrderAccepted: { $set: specification.status === 'in-processing' },
  successMessage: { $set: '' },
});

const setFrameSpecificationBySnippet = (state = INITIAL_STATE, { frameSpecification }) => update(state, {
  frameSpecification: {
    items: { $set: frameSpecification?.items || state.items },
    itemsByFrame: { $set: frameSpecification?.itemsByFrame || state.itemsByFrame },
    totalPrice: { $set: frameSpecification?.totalPrice || state.totalPrice },
    totalPriceUAH: { $set: frameSpecification?.totalPriceUAH || state.totalPriceUAH },
    retailTotalPrice: { $set: frameSpecification?.retailTotalPrice || state.retailTotalPrice },
    retailTotalPriceUAH: { $set: frameSpecification?.retailTotalPriceUAH || state.retailTotalPriceUAH },
    userPrice: { $set: frameSpecification?.userPrice || state.userPrice },
    userPriceUAH: { $set: frameSpecification?.userPriceUAH || state.userPriceUAH },
    user: { $set: frameSpecification?.user || state.user },
  },
  isOrderAccepted: { $set: frameSpecification.status === 'in-processing' },
  successMessage: { $set: '' },
});


const resetSpecification = (state = INITIAL_STATE) => update(state, {
  specification: { $set: INITIAL_STATE.specification },
  frameSpecification: { $set: INITIAL_STATE.frameSpecification },
  region: { $set: INITIAL_STATE.region },
  currentOrderId: { $set: INITIAL_STATE.currentOrderId },
  currentOrderNumber: { $set: INITIAL_STATE.currentOrderNumber },
  isOrderAccepted: { $set: INITIAL_STATE.isOrderAccepted },
  errorMessage: { $set: '' },
  successMessage: { $set: '' },
});


const toggleOrderTitleModal = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isOrderTitleModalOpen: { $set: isOpen },
});


const updateOrderTitle = (state = INITIAL_STATE, { title }) => update(state, {
  title: { $set: title },
});


const showErrorMessage = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  errorMessage: { $set: errorMessage },
});


/**
 * Reducers
 */

export default createReducer(INITIAL_STATE, {
  [OrderTypes.CALCULATE_ORDER_REQUEST]: calculateOrderRequest,
  [OrderTypes.CALCULATE_ORDER_SUCCESS]: calculateOrderSuccess,
  [OrderTypes.CALCULATE_ORDER_FAILURE]: calculateOrderFailure,

  [OrderTypes.CALCULATE_FRAME_ORDER_REQUEST]: calculateFrameOrderRequest,
  [OrderTypes.CALCULATE_FRAME_ORDER_SUCCESS]: calculateFrameOrderSuccess,
  [OrderTypes.CALCULATE_FRAME_ORDER_FAILURE]: calculateFrameOrderFailure,

  [OrderTypes.PUT_ORDER_INTO_WORK_REQUEST]: putOrderIntoWorkRequest,
  [OrderTypes.PUT_ORDER_INTO_WORK_SUCCESS]: putOrderIntoWorkSuccess,
  [OrderTypes.PUT_ORDER_INTO_WORK_FAILURE]: putOrderIntoWorkFailure,

  [OrderTypes.SAVE_ORDER_REQUEST]: saveOrderRequest,
  [OrderTypes.SAVE_ORDER_SUCCESS]: saveOrderSuccess,
  [OrderTypes.SAVE_ORDER_FAILURE]: saveOrderFailure,

  [OrderTypes.DELETE_ORDER_REQUEST]: deleteOrderRequest,
  [OrderTypes.DELETE_ORDER_SUCCESS]: deleteOrderSuccess,
  [OrderTypes.DELETE_ORDER_FAILURE]: deleteOrderFailure,

  [OrderTypes.COPY_ORDER_REQUEST]: copyOrderRequest,
  [OrderTypes.COPY_ORDER_SUCCESS]: copyOrderSuccess,
  [OrderTypes.COPY_ORDER_CLEAR_SUCCESS]: copyOrderClearSuccess,
  [OrderTypes.COPY_ORDER_FAILURE]: copyOrderFailure,

  [OrderTypes.UPDATE_ORDER_TITLE_REQUEST]: updateOrderTitleRequest,
  [OrderTypes.UPDATE_ORDER_TITLE_SUCCESS]: updateOrderTitleSuccess,
  [OrderTypes.UPDATE_ORDER_TITLE_FAILURE]: updateOrderTitleFailure,

  [OrderTypes.UPDATE_ORDER_PRICE_BY_DEALER_REQUEST]: updateOrderPriceByDealerRequest,
  [OrderTypes.UPDATE_ORDER_PRICE_BY_DEALER_SUCCESS]: updateOrderPriceByDealerSuccess,
  [OrderTypes.UPDATE_ORDER_PRICE_BY_DEALER_FAILURE]: updateOrderPriceByDealerFailure,

  [OrderTypes.TOGGLE_SHOULD_SHOW_ORDER_PAGE]: toggleShouldShowOrderPage,

  [OrderTypes.RESET_CURRENT_ORDER_DATA]: resetCurrentOrderData,

  [OrderTypes.FETCH_ORDER_DATA_REQUEST]: fetchOrderDataRequest,
  [OrderTypes.FETCH_ORDER_DATA_SUCCESS]: fetchOrderDataSuccess,
  [OrderTypes.FETCH_ORDER_DATA_FAILURE]: fetchOrderDataFailure,

  [OrderTypes.FETCH_ORDER_MODAL_DATA_REQUEST]: fetchOrderModalDataRequest,
  [OrderTypes.FETCH_ORDER_MODAL_DATA_SUCCESS]: fetchOrderModalDataSuccess,
  [OrderTypes.FETCH_ORDER_MODAL_DATA_FAILURE]: fetchOrderModalDataFailure,

  [OrderTypes.SET_SPECIFICATION_BY_SNIPPET]: setSpecificationBySnippet,
  [OrderTypes.SET_FRAME_SPECIFICATION_BY_SNIPPET]: setFrameSpecificationBySnippet,

  [OrderTypes.RESET_SPECIFICATION]: resetSpecification,

  [OrderTypes.TOGGLE_ORDER_TITLE_MODAL]: toggleOrderTitleModal,

  [OrderTypes.UPDATE_ORDER_TITLE]: updateOrderTitle,

  [OrderTypes.SHOW_ERROR_MESSAGE]: showErrorMessage,
});
