import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle, navigate } from 'hookrouter';
import { useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import clsx from 'clsx';

import Label from '../../components/Label';
import Input from '../../components/Input';
import Button from '../../components/Button';
import InputPhone from '../../components/InputPhone';

import {
  isValidPassword,
  isValidMobilePhone,
} from '../../helpers/validation';
import { sanitizeValueToNumberStringLike } from '../../helpers/sanitizer';
import notificationPropTypes from '../../helpers/propTypes/notificationPropTypes';
import { countriesList } from '../../../server/helpers/constants.mjs';

import AuthService from '../../services/authService';

import API from '../../api';

import ForgotPasswordActions from '../../redux/actions/forgotPasswordForm';
import ProfileActions from '../../redux/actions/profile';

import Main from '../layout/Main';
import LandingText from '../layout/LandingText';
import Timer from '../../components/timer';


const ForgotPassword = ({
  toastManager,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['components']);
  const userId = AuthService.getUserId();
  const storageCountry = localStorage.getItem('country');
  const initialSelectedCountry = countriesList.find((c) => c.country === storageCountry);

  useTitle(t('routes.reset-password'));

  const {
    phone,
    confirmationCode,
    password,
    repeatedPassword,
  } = useSelector(({ forgotPasswordForm }) => forgotPasswordForm);

  const { primaryRegion } = useSelector(({ profile }) => profile);

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
  const [isConfirmationCodeSent, setIsConfirmationCodeSent] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [notificationId, setNotificationId] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(initialSelectedCountry);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [disableClick, setDisableClick] = useState(false);
  const [isTimeCounting, setIsTimeCounting] = useState(false);
  const [timeToStop, setTimeToStop] = useState(null);

  useEffect(() => {
    const currentTime = Date.now();
    const deltaTime = timeToStop - currentTime;

    if (deltaTime > 0) setIsTimeCounting(true);
  }, [timeToStop]);

  useEffect(() => {
    dispatch(ForgotPasswordActions.resetForgotPasswordForm());
    dispatch(ProfileActions.getUserProfileRequest());
  }, []);

  useEffect(() => {
    setPhoneNumber('');
    dispatch(ForgotPasswordActions.updateForgotPasswordField({
      name: 'phone',
      value: selectedCountry.code,
    }));
  }, [selectedCountry]);

  const showToast = (message, appearance) => {
    const errorContent = <div className="toast-notification">{message}</div>;

    if (notificationId) toastManager.remove(notificationId);

    toastManager.add(errorContent, {
      appearance,
      autoDismiss: true,
    }, (id) => { setNotificationId(id); });
  };

  const updatePassword = ({ target: { name, value } }) => {
    dispatch(ForgotPasswordActions.updateForgotPasswordField({
      name,
      value,
    }));
  };

  const validatePassword = ({ target: { name, value } }) => {
    const isInvalid = !isValidPassword(value);
    const isPasswordsMatch = password?.value?.toString() === repeatedPassword?.value?.toString();
    let error = '';

    if (isInvalid) error = t('errorMessages.invalid-password');
    if (_.isEmpty(value)) error = t('errorMessages.empty-value');
    if (name === 'repeatedPassword' && !isPasswordsMatch) error = t('errorMessages.password-doesnt-match');

    setHasErrors(!_.isEmpty(error));
    dispatch(ForgotPasswordActions.updateForgotPasswordField({
      name,
      value,
      error,
    }));
  };

  const updatePhone = ({ target: { name, value } }) => {
    setPhoneNumber(sanitizeValueToNumberStringLike(value));
    const fullNumber = `${selectedCountry.code}${phoneNumber}`;

    if (phone?.value === fullNumber) return;

    dispatch(ForgotPasswordActions.updateForgotPasswordField({
      name,
      value: fullNumber,
    }));
  };

  const validatePhone = ({ target: { name, value } }) => {
    setPhoneNumber(sanitizeValueToNumberStringLike(value));
    const fullNumber = `${selectedCountry.code}${phoneNumber}`;
    const isValid = isValidMobilePhone(fullNumber)
      && fullNumber.slice(1).length === selectedCountry.numberOfDigits;
    const error = isValid ? '' : t('errorMessages.incorrect-number');

    setHasErrors(!_.isEmpty(error));
    dispatch(ForgotPasswordActions.updateForgotPasswordField({
      name,
      value: fullNumber,
      error,
    }));
  };

  const renderPhoneInput = () => (
    <>
      <Label value={t('forgotPassword.phone')} />
      <InputPhone
        value={phoneNumber}
        error={phone.error}
        selectedCountry={selectedCountry}
        countries={countriesList}
        onChange={updatePhone}
        onBlur={validatePhone}
        setSelectedCountry={setSelectedCountry}
        name="phone"
      />

      <Button
        value={t('forgotPassword.send-code')}
        type="rounded"
        onClick={async (e) => {
          e.preventDefault();
          if (phone.error || !phone?.value) return;

          const res = await API.user.tfa.resetPasswordRequest(phone.value);
          const { error, isConfirmationCodeSent: isSent } = res.data;

          if (!res.ok || !isSent) {
            const msg = res.data?.error?.message;
            showToast(msg ? t(`errorMessagesHelper.${msg}`) : t('errorMessages.something-went-wrong'), 'error');
          }
          if (error?.message === 'USER_NOT_FOUND') {
            showToast(t('errorMessagesHelper.USER_NOT_FOUND'), 'error');
            navigate('/sign-up-start');
          }

          if (userId && !primaryRegion) {
            navigate('/sign-up');
          }

          setIsConfirmationCodeSent(isSent);

          const time = new Date().getTime() + 30000;
          setTimeToStop(time);
        }}
        isDisabled={!!phone.error || !phoneNumber}
      />
    </>
  );

  const resetPassData = [phone?.value, password?.value, confirmationCode?.value];
  const isPassConfirmationValid = repeatedPassword?.value === password?.value;
  const isFieldsEmpty = password.value && confirmationCode.value && repeatedPassword.value && isPassConfirmationValid;
  const renderPasswordInputs = () => (
    <>
      <Label value={t('tfa.code')} />
      <Input
        value={confirmationCode?.value ?? ''}
        error={confirmationCode?.error}
        onChange={({ target: { name, value } }) => {
          dispatch(ForgotPasswordActions.updateForgotPasswordField({ name, value }));
        }}
        placeholder={t('tfa.code')}
        name="confirmationCode"
      />

      <Label value={t('forgotPassword.password')} />
      <Input
        value={password?.value ?? ''}
        error={password.error}
        onChange={updatePassword}
        onBlur={validatePassword}
        name="password"
        placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
        type={showPassword ? 'text' : 'password'}
        withIcon
        iconClassName={clsx('icon-at-right', 'password', showPassword && 'active')}
        iconSrc="/src/client/assets/icons/password-eye.svg"
        onIconClick={() => setShowPassword(!showPassword)}
      />

      <Label value={t('forgotPassword.repeat-password')} />
      <Input
        value={repeatedPassword?.value ?? ''}
        error={repeatedPassword.error}
        onChange={updatePassword}
        onBlur={validatePassword}
        name="repeatedPassword"
        placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
        type={showRepeatedPassword ? 'text' : 'password'}
        withIcon
        iconClassName={clsx('icon-at-right', 'password', showRepeatedPassword && 'active')}
        iconSrc="/src/client/assets/icons/password-eye.svg"
        onIconClick={() => setShowRepeatedPassword(!showRepeatedPassword)}
      />

      <Button
        value={t('forgotPassword.submit')}
        type="rounded"
        onClick={(e) => {
          e.preventDefault();
          setDisableClick(true);
          if (hasErrors || resetPassData.find((val) => !val) || !isPassConfirmationValid) return;

          API.user.profile.resetPassword(...resetPassData).then((res) => {
            setDisableClick(false);
            if (!res.ok || !res.data.isConfirmationCodeValid || !res.data.isPasswordUpdated) {
              const msg = res.data?.error?.message;
              showToast(msg ? t(`errorMessagesHelper.${msg}`) : t('errorMessages.something-went-wrong'), 'error');
              return;
            }
            showToast(t('forgotPassword.successfully-reset-password'), 'success');
            navigate('/sign-in');
          });
        }}
        isDisabled={disableClick || resetPassData.find((val) => !val) || !isFieldsEmpty}
      />

      <div className="sm-bottom-text">
        <div className="sm-bottom-wrapper">
          {isTimeCounting && (
            <Timer
              timeToStop={timeToStop}
              setIsTimeCounting={() => {
                setTimeToStop(null);
                setIsTimeCounting(false);
              }}
              className="timer-margin"
            />
          )}
          <div className="sm-btn-wrapper">
            <Button
              value={t('tfa.send-confirmation-again-sms')}
              isDisabled={_.isEmpty(phone?.value) || isTimeCounting}
              onClick={async (e) => {
                e.preventDefault();

                const res = await API.user.tfa.sendConfirmationCode(userId, phone.value, false);
                dispatch(ForgotPasswordActions.updateConfirmationCode({ value: '' }));

                if (!res.ok) {
                  const msg = res.data?.error?.message;
                  showToast(msg ? t(`errorMessagesHelper.${msg}`)
                    : t('errorMessages.something-went-wrong'), 'error');
                  return;
                }

                const time = new Date().getTime() + 30000;
                setTimeToStop(time);
              }}
            />
            <Button
              value={t('tfa.send-confirmation-again-viber')}
              isDisabled={_.isEmpty(phone?.value) || isTimeCounting}
              onClick={async (e) => {
                e.preventDefault();

                const res = await API.user.tfa.sendConfirmationCodeViaViber(userId, phone.value, false);
                dispatch(ForgotPasswordActions.updateConfirmationCode({ value: '' }));

                if (!res.ok) {
                  const msg = res.data?.error?.message;
                  showToast(msg ? t(`errorMessagesHelper.${msg}`)
                    : t('errorMessages.something-went-wrong'), 'error');
                  return;
                }

                const time = new Date().getTime() + 30000;
                setTimeToStop(time);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="sign-up-wrap">
      <LandingText hasOverlay />
      <Main
        className="forgot-password sign-up"
        hasFooter={false}
        classNameHeader="gray"
      >
        <div className="title-wrapper">
          <div className="title">{t('forgotPassword.setup-password')}</div>
        </div>

        <div className="content-wrapper">
          <div className="content-wrapper-inner">
            {isConfirmationCodeSent ? renderPasswordInputs() : renderPhoneInput()}
          </div>
        </div>
      </Main>
    </div>
  );
};

ForgotPassword.propTypes = {
  toastManager: PropTypes.shape(notificationPropTypes).isRequired,
};

export default withToastManager(ForgotPassword);
