import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const InputPhone = ({
  value,
  selectedCountry,
  setSelectedCountry,
  countries,
  onChange,
  onBlur,
  onKeyDown,
  name,
  placeholder,
  error,
  isDisabled,
  inputRef,
}) => {
  const { t } = useTranslation(['components']);
  const ref = inputRef || useRef(null);
  const selectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const inputClassName = clsx('phone', !_.isEmpty(error) && 'invalid');
  const inputSelectClassName = clsx(
    'phone-select',
    isOpen && 'open',
    isDisabled && 'disabled',
  );
  const inputOptionsClassName = clsx('phone-options', isOpen && 'open');

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };

  const handleBlur = (e) => {
    if (!onBlur) return;
    onBlur(e);
  };

  return (
    <div className="phone--container">
      <div className="phone--wrapper">
        <div
          ref={selectRef}
          className={inputSelectClassName}
          onClick={!isDisabled ? handleIsOpen : null}
          role="button"
          tabIndex={0}
        >
          <div className="phone-select-image--wrapper">
            <img
              className="phone-select-image"
              src={selectedCountry?.src}
              alt={`${selectedCountry?.country} flag`}
            />
          </div>
          <span className="phone-select-code">{selectedCountry?.code}</span>
          <span className="phone-select-icon" />
        </div>
        <div className={inputOptionsClassName}>
          {countries
            && countries.map((c) => (
              <div
                key={c.country}
                className="phone-option"
                onClick={() => handleSelectCountry(c)}
                role="button"
                tabIndex={0}
              >
                <div className="phone-option-image--wrapper">
                  <img
                    className="phone-option-image"
                    src={c.src}
                    alt="country flag"
                  />
                </div>
                <div className="phone-option-country">
                  {t(`countries.${c.country}`)}
                </div>
                <div className="phone-option-code">{c.code}</div>
              </div>
            ))}
        </div>

        <input
          ref={ref}
          className={inputClassName}
          onChange={onChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          value={value}
          name={name}
          type="text"
          placeholder={placeholder}
          disabled={isDisabled}
        />
      </div>
      {error && error.length > 0 && (
        <span className="phone--error">{error}</span>
      )}
    </div>
  );
};

InputPhone.propTypes = {
  value: PropTypes.string,
  selectedCountry: PropTypes.shape({
    country: PropTypes.string,
    src: PropTypes.string,
    code: PropTypes.string,
    numberOfDigits: PropTypes.number,
  }),
  setSelectedCountry: PropTypes.func,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string,
      src: PropTypes.string,
      code: PropTypes.string,
      numberOfDigits: PropTypes.number,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  inputRef: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
};

InputPhone.defaultProps = {
  value: '',
  selectedCountry: null,
  setSelectedCountry: null,
  countries: null,
  onBlur: null,
  onKeyDown: null,
  name: null,
  placeholder: null,
  error: null,
  inputRef: null,
  isDisabled: false,
};

export default InputPhone;
