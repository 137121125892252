import _ from 'lodash';

const getFillingDescription = (fillingMaterials, material, t, labelKey) => {
  const title = t(`fillingMaterialsModal.${material?.material}`);

  const selectedManufacturerOption = material?.dspOption
    ? fillingMaterials.find((f) => f.articleCode === material.dspOption)?.[labelKey]
    : '';

  let customFilling = '';
  let glassColors = '';

  if (material?.material === 'customers') {
    customFilling = material?.customersOption === 'glass'
      ? t('fillingMaterialsModal.glass')
      : `${t('fillingMaterialsModal.dsp')} ${material?.customersOption === 'dsp-small' ? '10mm' : '10+mm'}`;
  }

  if (material?.glassColors?.length) {
    material.glassColors.forEach((item, j) => {
      const color = item ? item.replace('_', ' ').toUpperCase() : '';
      glassColors += `${color}${material.glassColors.length > 1 && !j ? ', ' : ''}`;
    });
  }

  const option = `${customFilling}
          ${material?.isMilling ? t('fillingMaterialsModal.milling') : ''}

          ${material?.manufacturer || ''} ${selectedManufacturerOption}
          ${material?.lacobelType ? t(`options:lacobelTypes.${material.lacobelType}`) : ''}
          ${material?.glassType ? t(`options:glassTypes.${material.glassType}`) : ''}
          ${material?.mirrorType ? t(`options:mirrorTypes.${material.mirrorType}`) : ''}

          ${material?.isDspUVPrinting || material?.isGlassUVPrinting
            || material?.isMirrorUVPrinting || material?.isLacobelUVPrinting
    ? t('fillingMaterialsModal.uv-print')
    : ''}

          ${material?.isDspUVPrinting && material?.dspUvPrintType
      ? t(`options:chipboardUVPrintingTypes.${material.dspUvPrintType}`).toLowerCase()
      : ''}

          ${material?.isMirrorUVPrinting && material?.mirrorUvPrintType
        ? t(`options:mirrorUVPrintingTypes.${material.mirrorUvPrintType}`).toLowerCase()
        : ''}

          ${material?.isLacobelUVPrinting && material?.lacobelUvPrintType
          ? t(`options:lacobelUVPrintingTypes.${material.lacobelUvPrintType}`).toLowerCase()
          : ''}

          ${material?.isGlassUVPrinting && material?.glassUvPrintType
            ? t(`options:glassUVPrintingTypes.${material.glassUvPrintType}`).toLowerCase()
            : ''}
                
          ${material?.isMirrorArmoredFilm || material?.isLacobelArmoredFilm || material?.isGlassArmoredFilm
              ? t('fillingMaterialsModal.armored-film')
              : ''}
        
          ${material?.isMirrorLaminated || material?.isLacobelLaminated || material?.isGlassLaminated
                ? t('fillingMaterialsModal.white-lamination')
                : ''}
          ${material?.isMirrorMatted || material?.isLacobelMatted || material?.isGlassMatted
                  ? t('fillingMaterialsModal.stencil-matting')
                  : ''}

          ${material?.isMirrorRearMatted || material?.isLacobelRearMatted
                    ? t('fillingMaterialsModal.rear-matting')
                    : ''}

          ${material?.isMirrorFullMatted || material?.isLacobelFullMatted || material?.isGlassFullMatted
                      ? t('fillingMaterialsModal.full-matting')
                      : ''}

          ${material?.isGlassPhotoPrinting ? t('fillingMaterialsModal.photo-print-on-cover') : ''}
          ${material?.isGlassPhotoPrinting && material?.glassPhotoPrintType
                        ? t(`options:glassPhotoPrintingTypes.${material.glassPhotoPrintType}`).toLowerCase()
                        : ''}

          ${material?.mirrorColor
                          ? material?.mirrorColor.replace('_', ' ').toUpperCase()
                          : ''}
          ${material?.lacobelColor
                            ? material?.lacobelColor.replace('_', ' ').toUpperCase()
                            : ''}

          ${material?.isGlassOneColorPainted ? t('fillingMaterialsModal.painting-in-one-colors') : ''}
          ${material?.isGlassTwoColorsPainted ? t('fillingMaterialsModal.painting-in-two-colors') : ''}
          ${material?.glassColors?.length ? glassColors : ''}`;

  return { title, option };
};


const getFillingImagePath = (fillingMaterials, fillingItem, dspListWithWhiteMargins) => {
  const imagesPath = {
    chipboardPath: '/src/client/assets/images/visualisation/chipboard-texture.jpg',
    glassPath: '/src/client/assets/images/fillingMaterials/glass.jpg',
    mirrorPath: '/src/client/assets/images/fillingMaterials/mirror.jpg',
  };

  const { dspOption } = fillingItem;
  const isChipboard = fillingItem?.customersOption?.includes('dsp') || fillingItem?.material === 'dsp';

  const glass = (fillingItem?.material === 'customers' && fillingItem?.customersOption === 'glass')
    || fillingItem?.material === 'glass'
    ? imagesPath.glassPath
    : '';

  const chipboard = isChipboard && dspOption && _.some(dspListWithWhiteMargins, (item) => item === dspOption)
    ? imagesPath.chipboardPath
    : isChipboard && dspOption
      ? fillingMaterials?.find((f) => f.articleCode === dspOption)?.image
      : imagesPath.chipboardPath;

  const mirrorOrLacobel = fillingItem?.material === 'mirror' || fillingItem?.material === 'lacobel'
    ? imagesPath.mirrorPath
    : '';

  return glass || mirrorOrLacobel || chipboard;
};

export { getFillingDescription, getFillingImagePath };
