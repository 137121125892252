import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Switch from '../Switch';

const FrameMobileMenu = ({
  increaseScale,
  decreaseScale,
  onMirrorHorizontalClick,
  onMirrorVerticalClick,
  setToggleValue,
  showSize,
}) => {
  const { t } = useTranslation(['components', 'options']);

  const [showMenu, setShowMenu] = useState(false);
  const [showClose, setShowClose] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowClose(!showClose);
  };

  return (
    <div className="frame-mobile-menu">
      <div
        className="menu-item item-zoom"
        role="button"
        tabIndex="0"
        onClick={increaseScale}
      >
        <ReactSVG
          wrapper="span"
          src="/src/client/assets/icons/mobileMenu/mobile-zoom.svg"
        />
      </div>
      <div
        className="menu-item item-zoom item-zoom-minus"
        role="button"
        tabIndex="0"
        onClick={decreaseScale}
      >
        <ReactSVG
          wrapper="span"
          src="/src/client/assets/icons/mobileMenu/mobile-zoom-minus.svg"
        />
      </div>
      {!showClose && (
        <div
          onClick={toggleMenu}
          className="menu-item item-dots"
          role="button"
          tabIndex="0"
        >
          <ReactSVG
            wrapper="span"
            src="/src/client/assets/icons/mobileMenu/mobile-dots.svg"
          />
        </div>
      )}

      {showMenu && (
        <>
          <div className="menu-item item-size">
            <span
              className="item-text"
            >
              {t('frameMobileMenu.size')}
            </span>
            <Switch
              className="door-latch-mechanism"
              isToggled={showSize}
              setToggleValue={setToggleValue}
              isDisabled={false}
            />
          </div>
          <div
            onClick={onMirrorVerticalClick}
            className="menu-item item-mirror-vertical"
            role="button"
            tabIndex="0"
          >
            <ReactSVG
              wrapper="span"
              src="/src/client/assets/icons/mobileMenu/mobile-mirror-vertical.svg"
            />
          </div>
          <div
            onClick={onMirrorHorizontalClick}
            className="menu-item item-mirror-horizontal"
            role="button"
            tabIndex="0"
          >
            <ReactSVG
              wrapper="span"
              src="/src/client/assets/icons/mobileMenu/mobile-mirror-horizontal.svg"
            />
          </div>
          <div
            onClick={toggleMenu}
            role="button"
            tabIndex="0"
            className="menu-item item-close"
          >
            <ReactSVG
              wrapper="span"
              src="/src/client/assets/icons/mobileMenu/mobile-close.svg"
            />
          </div>
        </>
      )}
    </div>
  );
};

FrameMobileMenu.defaultProps = {
  increaseScale: () => {},
  decreaseScale: () => {},
  onMirrorHorizontalClick: () => {},
  onMirrorVerticalClick: () => {},
  setToggleValue: () => {},
  showSize: false,
};

FrameMobileMenu.propTypes = {
  increaseScale: PropTypes.func,
  decreaseScale: PropTypes.func,
  onMirrorHorizontalClick: PropTypes.func,
  onMirrorVerticalClick: PropTypes.func,
  setToggleValue: PropTypes.func,
  showSize: PropTypes.bool,
};

export default FrameMobileMenu;
