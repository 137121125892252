import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle, navigate } from 'hookrouter';
import { useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import clsx from 'clsx';

import {
  isValidMobilePhone,
  isValidPassword,
} from '../../helpers/validation';
import { sanitizeValueToNumberStringLike } from '../../helpers/sanitizer';
import notificationPropTypes from '../../helpers/propTypes/notificationPropTypes';
import { countriesList } from '../../../server/helpers/constants.mjs';

import SigninFormActions from '../../redux/actions/signinForm';
import AuthActions from '../../redux/actions/auth';

import Label from '../../components/Label';
import Input from '../../components/Input';
import Button from '../../components/Button';

import Main from '../layout/Main';
import LandingText from '../layout/LandingText';
import BackArrowBtn from '../../components/BackArrowBtn';
import InputPhone from '../../components/InputPhone';


const Signin = ({
  toastManager,
}) => {
  const { t } = useTranslation(['components']);
  const dispatch = useDispatch();

  useTitle(t('routes.sign-in'));

  const {
    phone,
    password,
  } = useSelector(({ signinForm }) => signinForm);

  const [hasErrors, setHasErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [notificationId, setNotificationId] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(countriesList[0]);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    dispatch(SigninFormActions.resetSigninForm());
  }, []);

  useEffect(() => {
    localStorage.setItem('country', selectedCountry.country);
    setPhoneNumber('');
    dispatch(SigninFormActions.updateSigninField({
      name: 'phone',
      value: selectedCountry.code,
    }));
  }, [selectedCountry]);

  const showToast = (message, appearance) => {
    const errorContent = <div className="toast-notification">{message}</div>;

    if (notificationId) toastManager.remove(notificationId);

    toastManager.add(errorContent, {
      appearance,
      autoDismiss: true,
    }, (id) => { setNotificationId(id); });
  };

  const updatePassword = ({ target: { name, value } }) => {
    dispatch(SigninFormActions.updateSigninField({
      name,
      value,
    }));
  };

  const updatePhone = ({ target: { name, value } }) => {
    setPhoneNumber(sanitizeValueToNumberStringLike(value));
    const fullNumber = `${selectedCountry.code}${phoneNumber}`;

    if (phone?.value === fullNumber) return;

    dispatch(SigninFormActions.updateSigninField({
      name,
      value: fullNumber,
    }));
  };

  const validatePhone = ({ target: { name, value } }) => {
    setPhoneNumber(sanitizeValueToNumberStringLike(value));
    const fullNumber = `${selectedCountry.code}${phoneNumber}`;
    const isValid = isValidMobilePhone(fullNumber)
      && fullNumber.slice(1).length === selectedCountry.numberOfDigits;
    const error = isValid ? '' : t('errorMessages.incorrect-number');

    setHasErrors(!_.isEmpty(error));
    dispatch(SigninFormActions.updateSigninField({
      name,
      value: fullNumber,
      error,
    }));
  };

  const validatePassword = ({ target: { name, value } }) => {
    const isInvalid = !isValidPassword(value);
    let error = '';

    if (_.isEmpty(value)) error = t('errorMessages.empty-value');
    if (isInvalid) error = t('errorMessages.invalid-password');

    setHasErrors(!_.isEmpty(error));
    dispatch(SigninFormActions.updateSigninField({
      name,
      value,
      error,
    }));
  };

  const isBtnDisable = hasErrors
  || !(
    password?.value
    && phone?.value
    && /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phone?.value)
  );

  const handleSignIn = async () => {
    if (hasErrors || !(password?.value && phone?.value)) return;

    const res = await dispatch(AuthActions.loginRequest());

    if (!res.ok) {
      const msg = res.data?.error?.message;
      showToast(msg ? t(`errorMessagesHelper.${msg}`) : res.data?.message, 'error');
    }
  };

  return (
    <div className="sign-up-wrap">

      <LandingText hasOverlay />

      <Main
        className="sign-in"
        hasFooter={false}
        classNameHeader="gray"
      >
        <div className="title-wrapper">
          <div className="title">
            <BackArrowBtn />
            {t('signIn.sign-in')}
          </div>
        </div>
        <div className="enter-link">
          <span className="label">{t('signIn.no-account')}</span>
          &nbsp; &nbsp;
          <Button
            value={t('signIn.sign-up')}
            onClick={() => navigate('/sign-up-start')}
          />
        </div>
        <div className="content-wrapper">
          <div
            className="content-wrapper-inner"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSignIn();
              }
            }}
            role="button"
            tabIndex="0"
          >
            <Label value={t('signIn.phone')} />
            <InputPhone
              value={phoneNumber}
              error={phone.error}
              selectedCountry={selectedCountry}
              countries={countriesList}
              onChange={updatePhone}
              onBlur={validatePhone}
              setSelectedCountry={setSelectedCountry}
              name="phone"
            />
            <Label value={t('signIn.password')} />
            <Input
              value={password?.value ?? ''}
              error={password.error}
              onChange={updatePassword}
              onBlur={validatePassword}
              name="password"
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
              type={showPassword ? 'text' : 'password'}
              withIcon
              iconClassName={clsx('icon-at-right', 'password', showPassword && 'active')}
              iconSrc="/src/client/assets/icons/password-eye.svg"
              onIconClick={() => setShowPassword(!showPassword)}
            />

            <Button
              value={t('signIn.to-sign-in')}
              type="rounded"
              onClick={(e) => {
                e.preventDefault();

                handleSignIn();
              }}
              isDisabled={isBtnDisable}
            />
            <div className="sm-bottom-text">
              <Button
                value={t('signIn.reset-password')}
                onClick={() => navigate('/reset-password')}
              />
            </div>
          </div>
        </div>
      </Main>
    </div>
  );
};

Signin.propTypes = {
  toastManager: PropTypes.shape(notificationPropTypes).isRequired,
};

export default withToastManager(Signin);
