import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setHoles: ['items'],
  setHolesAmount: ['amount'],
  setPlacementSide: ['side'],
  updateHolesItems: ['items'],

  resetHoles: [null],

  toggleDrillingHoles: ['isOpen'],
});

export const DrillingHolesTypes = Types;
export default Creators;
