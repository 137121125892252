export const onMirrorHorizontal = (frame) => {
  const {
    frameOpeningWidth,
    openingSide,
    holesForHandles,
    pearHoles,
  } = frame;

  const invertedOpeningSide = openingSide?.value === 'left' ? 'right' : 'left';
  const invertHandlesPlacementSide = ((holesForHandles?.placementSide === 'top' || holesForHandles?.placementSide === 'bottom') && holesForHandles?.placementSide)
      || (holesForHandles?.placementSide === 'right' && 'left') || (holesForHandles?.placementSide === 'left' && 'right');

  const holesForHandlesItemsToUpdate = ((holesForHandles?.placementSide === 'top' || holesForHandles?.placementSide === 'bottom') && holesForHandles?.items?.length > 0)
    ? holesForHandles?.items.map((item) => ({ ...item, coords: { ...item.coords, x: Number(frameOpeningWidth?.value) - Number(item.coords.x) } }))
    : holesForHandles?.items;

  const pearHolesToUpdate = (pearHoles?.placementSide === 'top' && pearHoles?.items?.length > 0)
    ? pearHoles?.items.map((item) => ({ ...item, coords: { ...item.coords, x: Number(frameOpeningWidth?.value) - Number(item.coords.x) } }))
    : pearHoles?.items;

  const mirroredFrame = {
    ...frame,
    openingSide: { value: invertedOpeningSide },
    holesForHandles: { ...holesForHandles, placementSide: invertHandlesPlacementSide, items: holesForHandlesItemsToUpdate },
    pearHoles: { ...pearHoles, items: pearHolesToUpdate },
  };

  return mirroredFrame;
};

export const onMirrorVertical = (frame) => {
  const {
    frameOpeningHeight,
    fasteningElement,
    holesForHandles,
    pearHoles,
  } = frame;

  const fasteningElementItemsToUpdate = fasteningElement?.items?.length > 0
    ? fasteningElement?.items.map((item) => ({ ...item, coords: { ...item.coords, y: Number(frameOpeningHeight?.value) - Number(item.coords.y) } }))
    : fasteningElement?.items;

  const invertHandlesPlacementSide = ((holesForHandles?.placementSide === 'right' || holesForHandles?.placementSide === 'left') && holesForHandles?.placementSide)
      || (holesForHandles?.placementSide === 'top' && 'bottom') || (holesForHandles?.placementSide === 'bottom' && 'top');

  const holesForHandlesItemsToUpdate = ((holesForHandles?.placementSide === 'left' || holesForHandles?.placementSide === 'right') && holesForHandles?.items?.length > 0)
    ? holesForHandles?.items.map((item) => ({ ...item, coords: { ...item.coords, y: Number(frameOpeningHeight?.value) - Number(item.coords.y) } }))
    : holesForHandles?.items;

  const pearHolesToUpdate = (pearHoles?.placementSide === 'left-right' && pearHoles?.items?.length > 0)
    ? pearHoles?.items.map((item) => ({ ...item, coords: { ...item.coords, y: Number(frameOpeningHeight?.value) - Number(item.coords.y) } }))
    : pearHoles?.items;

  const mirroredFrame = {
    ...frame,
    fasteningElement: { ...fasteningElement, items: fasteningElementItemsToUpdate },
    holesForHandles: { ...holesForHandles, placementSide: invertHandlesPlacementSide, items: holesForHandlesItemsToUpdate },
    pearHoles: { ...pearHoles, items: pearHolesToUpdate },
  };

  return mirroredFrame;
};

export const validateAventosHF = (currentWidth, prevWidth, fasteningElement) => {
  if (fasteningElement?.liftingMechanismType !== 'aventos_hf_top') return fasteningElement?.items;

  const indentFromEdge = 85;
  const differenceBetweenWidth = prevWidth - currentWidth;
  const startElementCoord = fasteningElement?.items[0]?.coords?.x;
  const endElementCoord = fasteningElement?.items[2]?.coords?.x;
  const distanceFromEdgeToStartElement = startElementCoord - indentFromEdge;
  const distanceFromEdgeToEndElement = prevWidth - endElementCoord - indentFromEdge;
  const distanceBetweenStartEndElement = endElementCoord - startElementCoord;

  const differenceForStartCoord = Math.ceil(differenceBetweenWidth / 2);
  const differenceForEndCoord = differenceBetweenWidth - differenceForStartCoord;

  let startCoordToSet = 0;
  let endCoordToSet = 0;

  const startExcess = distanceFromEdgeToStartElement - differenceForStartCoord;
  const endExcess = distanceFromEdgeToEndElement - differenceForEndCoord;

  if (Math.abs(startExcess) !== differenceForStartCoord && Math.abs(endExcess) !== differenceForEndCoord) {
    startCoordToSet = startElementCoord - differenceForStartCoord;
    endCoordToSet = endElementCoord - differenceForEndCoord;
  }

  if (startExcess <= 0 && endExcess <= 0) {
    startCoordToSet = indentFromEdge;
    endCoordToSet = currentWidth - indentFromEdge;
  }

  if (Math.abs(startExcess) === differenceForStartCoord && Math.abs(endExcess) === differenceForEndCoord) {
    startCoordToSet = indentFromEdge;
    endCoordToSet = currentWidth - indentFromEdge;
  }

  if (Math.abs(startExcess) === differenceForStartCoord && Math.abs(endExcess) !== differenceForEndCoord
      && (currentWidth - indentFromEdge >= endElementCoord)) {
    startCoordToSet = indentFromEdge;
    endCoordToSet = endElementCoord;
  }

  if (Math.abs(startExcess) === differenceForStartCoord && Math.abs(endExcess) !== differenceForEndCoord
      && (currentWidth - indentFromEdge < endElementCoord)) {
    startCoordToSet = indentFromEdge;
    endCoordToSet = currentWidth - indentFromEdge;
  }

  if (Math.abs(endExcess) === differenceForEndCoord && Math.abs(startExcess) !== differenceForStartCoord
      && (differenceBetweenWidth <= distanceFromEdgeToStartElement)) {
    startCoordToSet = startElementCoord - differenceBetweenWidth;
    endCoordToSet = currentWidth - indentFromEdge;
  }

  if (Math.abs(endExcess) === differenceForEndCoord && Math.abs(startExcess) !== differenceForStartCoord
      && (differenceBetweenWidth > distanceFromEdgeToStartElement)) {
    startCoordToSet = indentFromEdge;
    endCoordToSet = currentWidth - indentFromEdge;
  }

  if (Number(currentWidth) > Number(prevWidth) && startElementCoord === indentFromEdge && endElementCoord === prevWidth - indentFromEdge) {
    startCoordToSet = indentFromEdge;
    endCoordToSet = currentWidth - indentFromEdge;
  }

  if (Number(currentWidth) > Number(prevWidth) && startElementCoord === indentFromEdge && endElementCoord !== prevWidth - indentFromEdge) {
    startCoordToSet = indentFromEdge;
    endCoordToSet = endElementCoord;
  }

  if (Number(currentWidth) > Number(prevWidth) && startElementCoord !== indentFromEdge && endElementCoord === prevWidth - indentFromEdge) {
    startCoordToSet = currentWidth - indentFromEdge - distanceBetweenStartEndElement;
    endCoordToSet = currentWidth - indentFromEdge;
  }

  const coordsToUpdate = fasteningElement?.items.map(({ coords, sequenceNumber }) => {
    if ([1, 2, 5].includes(sequenceNumber)) {
      return {
        sequenceNumber,
        coords: {
          ...coords,
          x: startCoordToSet,
        },
      };
    }

    return {
      sequenceNumber,
      coords: {
        ...coords,
        x: endCoordToSet,
      },
    };
  });

  return coordsToUpdate;
};
