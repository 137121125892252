import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { DiscountsTypes } from '../actions/discounts';

const INITIAL_STATE = {
  isLoading: false,
  errorMessage: null,
  discounts: [],
};

const getDiscountsRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
});

const getDiscountsSuccess = (state = INITIAL_STATE, { discounts }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: '' },
  discounts: { $set: discounts || [] },
});

const getDiscountsFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  errorMessage: { $set: errorMessage },
});

export default createReducer(INITIAL_STATE, {
  [DiscountsTypes.GET_DISCOUNTS_REQUEST]: getDiscountsRequest,
  [DiscountsTypes.GET_DISCOUNTS_SUCCESS]: getDiscountsSuccess,
  [DiscountsTypes.GET_DISCOUNTS_FAILURE]: getDiscountsFailure,
});
