import { createActions } from 'reduxsauce';
import API from '../../api';

const { Types, Creators } = createActions({
  getDiscountsSuccess: ['discounts'],
  getDiscountsFailure: ['errorMessage'],
  getDiscountsRequest: () => async (dispatch) => {
    try {
      const response = await API.discounts.get();
      const { ok, problem, data } = response || {};
      const error = data?.error || {};
      const discounts = response?.data?.discounts || [];
      const errorMessage = !ok
        ? (error?.message || data?.message || problem)
        : '';

      if (errorMessage) {
        dispatch(Creators.getDiscountsFailure(errorMessage));
        return;
      }

      dispatch(Creators.getDiscountsSuccess(discounts));
    } catch (error) {
      dispatch(Creators.getDiscountsFailure(error.message || error.reason));
    }
  },
});

export const DiscountsTypes = Types;

export default Creators;
