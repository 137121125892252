import React from 'react';
import PropTypes from 'prop-types';
import { Circle, Group, Label, Line, Tag, Text, Rect } from 'react-konva';
import { randomKey } from '../../helpers/visualisationHelper';

const Fastening = ({
  openingSide,
  width,
  height,
  fasteningMain,
  fasteningModal,
  isFasteningElementOpen,
  activeHole,
  isLiftingMechanism,
  isBottomModule,
  isScaled,
  paddingTop,
  paddingLeft,
  fontSize,
}) => {
  const circleSize = 30;
  const rectSize = 10;
  const smallCircle = 2;
  const squareSize = 5;
  const hingeArray = isFasteningElementOpen ? fasteningModal.items : fasteningMain.items;
  const place = openingSide.value;
  const liftingMechanism = isFasteningElementOpen
    ? fasteningModal.liftingMechanismType : fasteningMain.liftingMechanismType;

  const minIndent = 85;
  const maxIndent = height - minIndent;

  let copyArray = [];

  if (isLiftingMechanism) {
    const newYValue = height - 9;
    const newXValue = width - 10;

    if (hingeArray.length === 4 && liftingMechanism === 'aventos_hf_top') {
      copyArray = hingeArray.map((object, index) => {
        if (index % 2 === 1) {
          return {
            ...object,
            coords: {
              ...object.coords,
              y: newYValue,
            },
          };
        } else {
          return {
            ...object,
            coords: {
              ...object.coords,
              y: 9,
            },
          };
        }
      });
    }

    if (liftingMechanism !== 'aventos_hf_top') {
      copyArray = hingeArray.map((object, index) => {
        if (index % 2 === 1) {
          return {
            ...object,
            coords: {
              ...object.coords,
              x: newXValue,
            },
          };
        } else {
          return {
            ...object,
            coords: {
              ...object.coords,
              x: 2,
            },
          };
        }
      });
    }

    if (hingeArray.length === 6 && liftingMechanism === 'aventos_hf_top') {
      // eslint-disable-next-line array-callback-return
      copyArray = hingeArray.map((item) => {
        if (item.sequenceNumber === 1 || item.sequenceNumber === 3) {
          return {
            ...item,
            coords: {
              ...item.coords,
              y: height - 9,
            },
          };
        } else if (item.sequenceNumber === 2 || item.sequenceNumber === 4) {
          return {
            ...item,
            coords: {
              ...item.coords,
              y: height / 2 - 11,
            },
          };
        } else if (item.sequenceNumber === 5 || item.sequenceNumber === 6) {
          return {
            ...item,
            coords: {
              ...item.coords,
              y: height / 2 + 11,
            },
          };
        }
      });
    }
  }

  const DrillsCircles = () => hingeArray.map((e, i) => {
    const xCord = place === 'right'
      ? paddingLeft + width - Number(e.coords.x) - (circleSize / 2)
      : Number(e.coords.x) + paddingLeft + (circleSize / 2);
    const yCord = paddingTop + height - Number(e.coords.y);
    const item = i + 1;
    const activeField = activeHole === item ? '#3A84E3' : '#979797';
    const activeStroke = activeHole === item ? '#004F9E' : 'black';

    return (
      <Circle
        key={randomKey()}
        x={xCord}
        y={yCord}
        width={circleSize}
        height={circleSize}
        stroke={activeStroke}
        strokeWidth={1}
        fill={activeField}
      />
    );
  });


  const DrillingLines = () => (
    <>
      {hingeArray.map((e, i) => {
        const paddingSize = isScaled ? 50 : 40;
        const xPoint = place === 'left' ? paddingLeft : paddingLeft + width;
        const yPoint = paddingTop + height - Number(e.coords.y);
        const cordY = Number(e.coords.y);
        const length = isFasteningElementOpen ? fasteningModal.amount : fasteningMain.amount;
        const firstPointHorizontal = place === 'left'
          ? xPoint - ((length - i) * 30)
          : xPoint + (30 * (length - i));

        const xPointVertical = place === 'left' ? firstPointHorizontal + 10 : firstPointHorizontal - 10;
        const xScale = isScaled ? xPointVertical - 15 : xPointVertical - 7;

        const isValidValue = Number(e.coords.y) > maxIndent || Number(e.coords.y) < minIndent;

        const item = i + 1;
        const activeField = activeHole === item;
        const strokeColor = activeField ? '#3A84E3' : isValidValue ? '#ff0000' : '#000';

        const textLength = () => {
          const textSize = e.coords.y.toString().length;

          if (isScaled) {
            switch (textSize) {
              case 2:
                return 20;
              case 3:
                return 25;
              case 4:
                return 35;
              default:
                return 0;
            }
          } else {
            switch (textSize) {
              case 2:
                return 5;
              case 3:
                return 15;
              case 4:
                return 25;
              default:
                return 0;
            }
          }
        };

        const calculatedTextLength = textLength();
        const sideTextYCoordinate = height - (Number(e.coords.y) / 2) + paddingTop + calculatedTextLength;
        const leftRightVerticalLine = height + paddingTop - cordY / 2;

        return (
          <Group
            key={randomKey()}
          >
            {/* horizontal line */}
            <Line
              points={[xPoint, yPoint, firstPointHorizontal, yPoint]}
              stroke={strokeColor}
              strokeWidth={1}
            />

            {(leftRightVerticalLine - paddingSize > yPoint || leftRightVerticalLine + paddingSize < height + paddingTop)
              && (
                <>
                  {/* vertical line */}
                  <Line
                    points={[
                      xPointVertical,
                      yPoint,
                      xPointVertical,
                      leftRightVerticalLine - paddingSize,
                    ]}
                    stroke={strokeColor}
                    strokeWidth={1}
                  />
                  <Line
                    points={[
                      xPointVertical,
                      leftRightVerticalLine + paddingSize,
                      xPointVertical,
                      height + paddingTop,
                    ]}
                    stroke={strokeColor}
                    strokeWidth={1}
                  />
                </>
              )}

            {/* bottom line */}
            {i === 0 && (
              <Line
                points={[xPoint, height + paddingTop, firstPointHorizontal, height + paddingTop]}
                stroke={strokeColor}
                strokeWidth={1}
              />
            )}

            <Label
              x={xScale}
              y={sideTextYCoordinate}
              rotation={270}
            >
              <Tag fill="transparent" stroke="transparent" />
              <Text fill={strokeColor} text={e.coords.y} padding={0} fontSize={fontSize} />
            </Label>
          </Group>
        );
      })}
    </>
  );

  const DrillsSquare = () => copyArray.map((e, i) => {
    const isHfTop = liftingMechanism === 'aventos_hf_top';
    const xCord = place === 'right'
      ? paddingLeft + width - Number(e.coords.x) - (rectSize / 2)
      : Number(e.coords.x) + paddingLeft + (rectSize / 2);
    const yCord = isHfTop ? paddingTop + height - Number(e.coords.y) : paddingTop + Number(e.coords.y) - 2;
    const item = i + 1;
    const xCircleOne = liftingMechanism !== 'aventos_hf_top' ? 0 : -4;
    const yCircleOne = liftingMechanism !== 'aventos_hf_top' ? -4 : 0;
    const xCircleTwo = liftingMechanism !== 'aventos_hf_top' ? 0 : 10;
    const yCircleTwo = liftingMechanism !== 'aventos_hf_top' ? 10 : 0;
    const xRect = liftingMechanism !== 'aventos_hf_top' ? -2.5 : 0;
    const yRect = liftingMechanism !== 'aventos_hf_top' ? 0 : -2.5;
    const doubleHoles = liftingMechanism !== 'aventos_hf_top' && liftingMechanism !== 'aventos_hk_s';

    const activeField = (((activeHole === 2 && [1, 2, 5].includes(item))
      || (activeHole === 4 && [3, 4, 6].includes(item))) && isHfTop ? '#3A84E3' : '#979797');

    const activeStroke = (((activeHole === 2 && [1, 2, 5].includes(item))
      || (activeHole === 4 && [3, 4, 6].includes(item))) && isHfTop ? '#004F9E' : 'black');


    return (
      <>
        <Group
          key={randomKey()}
          x={xCord}
          y={yCord}
        >
          <Circle
            x={xCircleOne}
            y={yCircleOne}
            width={smallCircle}
            height={smallCircle}
            fill={activeField}
            stroke={activeStroke}
            strokeWidth={1}
          />
          <Rect
            x={xRect}
            y={yRect}
            width={squareSize}
            height={squareSize}
            fill={activeField}
            stroke={activeStroke}
            strokeWidth={1}
          />
          <Circle
            x={xCircleTwo}
            y={yCircleTwo}
            width={smallCircle}
            height={smallCircle}
            fill={activeField}
            stroke={activeStroke}
            strokeWidth={1}
          />
        </Group>
        {doubleHoles && (
          <Group
            key={randomKey()}
            x={xCord}
            y={yCord + 64}
          >
            <Circle
              x={xCircleOne}
              y={yCircleOne}
              width={smallCircle}
              height={smallCircle}
              fill="#979797"
              stroke="black"
              strokeWidth={1}
            />
            <Rect
              x={xRect}
              y={yRect}
              width={squareSize}
              height={squareSize}
              fill="#979797"
              stroke="black"
              strokeWidth={1}
            />
            <Circle
              x={xCircleTwo}
              y={yCircleTwo}
              width={smallCircle}
              height={smallCircle}
              fill="#979797"
              stroke="black"
              strokeWidth={1}
            />
          </Group>
        )}
      </>
    );
  });


  const DrillingMechanismLines = () => {
    const leftHoleX = hingeArray[0]?.coords.x;
    const rightHoleX = hingeArray[2]?.coords.x;
    const leftHoleY = hingeArray[0]?.coords.y;

    const doubleHoles = liftingMechanism !== 'aventos_hf_top'
      && liftingMechanism !== 'aventos_hk_s' && hingeArray.length !== 0;

    const leftPoint = paddingLeft + leftHoleX + 8;
    const rightPoint = paddingLeft + rightHoleX + 8;
    const isLift = liftingMechanism !== 'aventos_hf_top';

    return (
      <>
        {(isLift && liftingMechanism !== '') && (
          <>
            {/* dashed line */}
            <Line
              points={[
                paddingLeft + 6,
                paddingTop + leftHoleY - 4,
                paddingLeft + width - 4,
                paddingTop + leftHoleY - 4,
              ]}
              stroke={activeHole === 2 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
              dash={[4, 4]}
            />

            {/* left dashed line part */}
            <Line
              points={[paddingLeft - 26, paddingTop + leftHoleY - 4, paddingLeft, paddingTop + leftHoleY - 4]}
              stroke={activeHole === 2 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
            />

            {/* top dashed line part */}
            <Line
              points={[paddingLeft - 26, paddingTop, paddingLeft, paddingTop]}
              stroke={activeHole === 2 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
            />

            <Label
              x={paddingLeft - 25}
              y={leftHoleY / 2 + paddingTop + 10}
              rotation={270}
              fontSize={fontSize}
            >
              <Text fill={activeHole === 2 ? '#3A84E3' : '#201720'} text={leftHoleY} padding={2} />
            </Label>

          </>
        )}

        {doubleHoles && (
          <>
            {/* top line */}
            <Line
              points={[paddingLeft - 26, paddingTop + leftHoleY, paddingLeft, paddingTop + leftHoleY]}
              stroke="#201720"
              strokeWidth={1}
            />

            {/* bottom line */}
            <Line
              points={[paddingLeft - 26, paddingTop + leftHoleY + 64, paddingLeft, paddingTop + leftHoleY + 64]}
              stroke="#201720"
              strokeWidth={1}
            />

            <Label
              x={paddingLeft - 25}
              y={leftHoleY + (64 / 2) + paddingTop + 10}
              rotation={270}
              fontSize={fontSize}
            >
              <Text fill="#201720" text={64} padding={2} />
            </Label>
          </>
        )}

        {!isLift && (
          <>
            {/* left dashed line */}
            <Line
              points={[leftPoint, paddingTop + 8, leftPoint, paddingTop + height - 8]}
              stroke={activeHole === 2 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
              dash={[4, 4]}
            />

            {/* left line top */}
            <Line
              points={[leftPoint, paddingTop, leftPoint, paddingTop - 30]}
              stroke={activeHole === 2 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
            />

            {/* left edge line */}
            <Line
              points={[paddingLeft, paddingTop - 50, paddingLeft, paddingTop]}
              stroke={activeHole === 2 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
            />

            {/* left hole text */}
            <Label
              x={paddingLeft + leftHoleX / 2}
              y={paddingTop - 20}
            >
              <Text fill={activeHole === 2 ? '#3A84E3' : '#201720'} text={leftHoleX} padding={2} />
            </Label>

            {/* right dashed line */}
            <Line
              points={[rightPoint, paddingTop + 8, rightPoint, paddingTop + height - 8]}
              stroke={activeHole === 4 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
              dash={[4, 4]}
            />

            {/* right vertical line top */}
            <Line
              points={[paddingLeft, paddingTop - 40, rightPoint - rightHoleX / 2 - 20, paddingTop - 40]}
              stroke={activeHole === 4 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
            />

            <Line
              points={[rightPoint - rightHoleX / 2 + 20, paddingTop - 40, rightPoint, paddingTop - 40]}
              stroke={activeHole === 4 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
            />

            {/* right edge line */}
            <Line
              points={[rightPoint, paddingTop - 50, rightPoint, paddingTop]}
              stroke={activeHole === 4 ? '#3A84E3' : '#201720'}
              strokeWidth={1}
            />

            {/* right hole text */}
            <Label
              x={paddingLeft + rightHoleX / 2 - 5}
              y={paddingTop - 48}
            >
              <Text fill={activeHole === 4 ? '#3A84E3' : '#201720'} text={rightHoleX} padding={4} />
            </Label>
          </>
        )}
      </>
    );
  };

  const calculateDistance = () => {
    if (height >= 480 && height <= 549) return 54;
    if (height >= 550 && height <= 1040) return 31;
    return 0;
  };

  const SideHinge = () => {
    const distance = calculateDistance(height);
    const sectionCenter = paddingTop + (height - height / 4);
    const yPoint = sectionCenter + distance - 4;
    const xPointLeft = paddingLeft + 6;
    const xPointRight = paddingLeft + width - 11;

    return (
      distance ? (
        <>
          {/* left */}
          <Rect
            x={xPointLeft}
            y={yPoint}
            width="5"
            height="8"
            fill="#979797"
            stroke="#201720"
            strokeWidth={1}
          />

          {/* right */}
          <Rect
            x={xPointRight}
            y={yPoint}
            width="5"
            height="8"
            fill="#979797"
            stroke="#201720"
            strokeWidth={1}
          />

          {/* dashed line */}
          <Line
            points={[xPointLeft - 25, yPoint + 4, xPointLeft - 5, yPoint + 4]}
            stroke="#201720"
            strokeWidth={1}
          />

          <Line
            points={[xPointLeft + 5, yPoint + 4, xPointRight - 3, yPoint + 4]}
            stroke="#201720"
            strokeWidth={1}
            dash={[4, 4]}
          />

          {/* center line */}
          <Line
            points={[xPointLeft - 25, sectionCenter, xPointRight + 25, sectionCenter]}
            stroke="#201720"
            strokeWidth={1}
          />

          <Label
            x={xPointLeft - 25}
            y={sectionCenter + distance / 2 + 10}
            rotation={270}
          >
            <Text fill="#201720" text={distance} padding={2} />
          </Label>
        </>
      ) : null
    );
  };

  return (
    <>
      {isLiftingMechanism ? (
        <>
          <DrillingMechanismLines />
          <DrillsSquare />
          {isBottomModule && (
            <SideHinge />
          )}
        </>
      ) : (
        <>
          <DrillingLines />
          <DrillsCircles />
        </>
      )}
    </>
  );
};


Fastening.defaultProps = {
  fasteningMain: {},
  fasteningModal: {},
  activeHole: null,
  isLiftingMechanism: false,
  isBottomModule: false,
  isScaled: false,
  paddingTop: 0,
  paddingLeft: 0,
  fontSize: 0,
};

Fastening.propTypes = {
  fasteningMain: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  fasteningModal: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  openingSide: PropTypes.objectOf(PropTypes.string).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isFasteningElementOpen: PropTypes.bool.isRequired,
  activeHole: PropTypes.number,
  isLiftingMechanism: PropTypes.bool,
  isBottomModule: PropTypes.bool,
  isScaled: PropTypes.bool,
  paddingTop: PropTypes.number,
  paddingLeft: PropTypes.number,
  fontSize: PropTypes.number,
};

export default Fastening;
