import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

import DoorsActions from '../../redux/actions/doorsAndSections';
import DrillingPearHolesAction from '../../redux/actions/drillingPearHoles';
import FasteningElementAction from '../../redux/actions/fasteningElement';

import { blockInvalidChar } from '../../helpers/sanitizer';

import PlusMinusControl from '../PlusMinusControl';
import Button from '../Button';
import Input from '../Input';
import WarningDistanceModal from '../WarningDistanceModal';
import WarningInsufficientHeightModal from '../WarningInsufficientHeightModal';


const DrillingPearHoles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRefX = useRef(null);
  const inputRefY = useRef(null);
  const prevPlacementSideRef = useRef();
  const indentFromEdge = 80;
  const distanceBetweenCoords = 100;
  const invalidChars = ['e', 'E', '+', '-', '.', ','];

  const { activeDoor, mainFrame, doors } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const {
    isWarningDistanceModalOpen,
    isWarningHeightModalOpen,
  } = useSelector(({ fasteningElement }) => fasteningElement);

  const [isInvertAxis, setIsInvertAxis] = useState(false);
  const [profileWidth, setProfileWidth] = useState('');
  const [frameProfile, setFrameProfile] = useState('');
  const [frameHeight, setFrameHeight] = useState(null);
  const [frameWidth, setFrameWidth] = useState(null);
  const [warningModalContent, setWarningModalContent] = useState('');

  const [pearHolesPlacementSide, setPearHolesPlacementSide] = useState('');
  const [pearHolesAmount, setPearHolesAmount] = useState(0);
  const [pearHolesItems, setPearHolesItems] = useState([]);
  const [prevPearHolesItems, setPrevPearHolesItems] = useState([]);

  useEffect(() => {
    prevPlacementSideRef.current = pearHolesPlacementSide;
  });

  const prevPlacementSide = prevPlacementSideRef.current;

  useEffect(() => {
    document.querySelector('.pear-hole').scrollIntoView({ block: 'start', behavior: 'auto' });
  }, []);

  useEffect(() => {
    if (activeDoor === 0) {
      setProfileWidth(mainFrame?.profileWidth?.width);
      setFrameProfile(mainFrame?.frameProfile?.value);
      setFrameHeight(mainFrame?.frameOpeningHeight?.value);
      setFrameWidth(mainFrame?.frameOpeningWidth?.value);
      setPearHolesPlacementSide(mainFrame?.pearHoles?.placementSide);
      setPearHolesAmount(mainFrame?.pearHoles?.amount);
      setPearHolesItems(mainFrame?.pearHoles?.items);
      setPrevPearHolesItems(mainFrame?.pearHoles?.items);
      setIsInvertAxis(mainFrame?.pearHoles?.placementSide === 'top');
      return;
    }

    setProfileWidth(doors[activeDoor - 1]?.profileWidth?.width);
    setFrameProfile(doors[activeDoor - 1]?.frameProfile?.value);
    setFrameHeight(doors[activeDoor - 1]?.frameOpeningHeight?.value);
    setFrameWidth(doors[activeDoor - 1]?.frameOpeningWidth?.value);
    setPearHolesPlacementSide(doors[activeDoor - 1]?.pearHoles?.placementSide);
    setPearHolesAmount(doors[activeDoor - 1]?.pearHoles?.amount);
    setPearHolesItems(doors[activeDoor - 1]?.pearHoles?.items);
    setPrevPearHolesItems(doors[activeDoor - 1]?.pearHoles?.items);
    setIsInvertAxis(doors[activeDoor - 1]?.pearHoles?.placementSide === 'top');
  }, [activeDoor, mainFrame, doors]);

  useEffect(() => {
    if (prevPlacementSide === 'top' && pearHolesPlacementSide === 'left-right') {
      setPearHolesAmount(0);
      setPearHolesItems([]);
      setIsInvertAxis(false);
    }

    if (prevPlacementSide === 'left-right' && pearHolesPlacementSide === 'top') {
      setPearHolesAmount(0);
      setPearHolesItems([]);
      setIsInvertAxis(true);
    }
    dispatch(DrillingPearHolesAction.setPlacementSide(pearHolesPlacementSide));
  }, [pearHolesPlacementSide]);

  useEffect(() => {
    dispatch(DrillingPearHolesAction.setAmount(pearHolesAmount));
  }, [pearHolesAmount]);

  useEffect(() => {
    dispatch(DrillingPearHolesAction.setItems(pearHolesItems));
  }, [pearHolesItems]);

  useEffect(() => {
    setWarningModalContent(
      t(`warningInsufficientHeightModal.${!isInvertAxis ? 'title-height-pear' : 'title-width-pear'}`,
        { distance: 160 }),
    );
  }, [isInvertAxis]);


  const onPearHolesAmountChange = (value) => {
    const maxValidHolesByWidth = Math.floor((frameWidth - (indentFromEdge * 2)) / distanceBetweenCoords) + 1;
    const maxAvailableHolesByWidth = 5;
    const maxAvailableAmountByWidth = maxValidHolesByWidth > maxAvailableHolesByWidth
      ? maxAvailableHolesByWidth : maxValidHolesByWidth;
    const maxAvailableAmountByHeight = Math.floor(frameHeight - (indentFromEdge * 2)) < 0 ? 0 : 2;
    const itemsLength = pearHolesItems?.length;
    const defaultValue = _.round(profileWidth / 2);
    const yInitialDistance = _.round(frameHeight / 2);
    const xInitialDistance = _.round(frameWidth / 2);
    const maxAmount = pearHolesPlacementSide === 'left-right'
      ? maxAvailableAmountByHeight : maxAvailableAmountByWidth;

    if (
      maxAmount <= 0 && pearHolesItems?.length === 0 && value > 0
    ) return dispatch(FasteningElementAction.toggleWarningHeightModal(true));

    const getValidCoords = (amount) => {
      const startCoord = (
        (Number(frameWidth) - (indentFromEdge * 2)) - (distanceBetweenCoords * (amount - 1))) / 2 + indentFromEdge;
      const coords = [];

      for (let i = 0; i < amount; i += 1) {
        coords.push({
          sequenceNumber: i + 1,
          coords: {
            x: _.round(startCoord + distanceBetweenCoords * (i)),
            y: defaultValue,
          },
        });
      }

      return coords;
    };

    if (pearHolesAmount < value && value <= maxAmount) {
      setPearHolesAmount(value);

      if (pearHolesPlacementSide === 'left-right') {
        const pearHolesItemsToSet = [{
          sequenceNumber: 1,
          coords: {
            x: defaultValue,
            y: yInitialDistance,
          },
        },
        {
          sequenceNumber: 2,
          coords: {
            x: defaultValue,
            y: yInitialDistance,
          },
        },
        ];
        setPearHolesItems(pearHolesItemsToSet);
        setPrevPearHolesItems(pearHolesItemsToSet);
      } else {
        const pearHolesItemsToSet = [...pearHolesItems, {
          sequenceNumber: value,
          coords: {
            x: xInitialDistance,
            y: defaultValue,
          },
        }];
        setPearHolesItems(pearHolesItemsToSet);
        setPrevPearHolesItems(pearHolesItemsToSet);
      }
      if (pearHolesPlacementSide === 'top' && value > 1) {
        setPearHolesItems(getValidCoords(value));
        setPrevPearHolesItems(getValidCoords(value));
      }
    }

    if (pearHolesAmount > value && !(value < 0)) {
      setPearHolesAmount(value);

      if (pearHolesPlacementSide === 'left-right') {
        return setPearHolesItems([]);
      }

      if (pearHolesPlacementSide === 'top' && pearHolesAmount > maxAmount) {
        return setPearHolesItems(pearHolesItems.slice(0, itemsLength - 1));
      }

      if (pearHolesPlacementSide === 'top' && value > 1) {
        setPearHolesItems(getValidCoords(value));
      } else {
        setPearHolesItems(pearHolesItems.slice(0, itemsLength - 1));
      }
    }
  };


  const getXRanges = (number) => {
    const ranges = pearHolesItems.map(({ sequenceNumber, coords }) => (
      { sequenceNumber, min: (coords.x - 99) <= 80 ? 0 : coords.x - 99, max: coords.x + 99 }))
      .filter(({ sequenceNumber }) => sequenceNumber !== number);

    return ranges;
  };


  const getItemsToUpdate = (itemValue, number, axis, isDouble = false) => pearHolesItems.map((item) => {
    if (isDouble || item.sequenceNumber === number) {
      return { ...item, coords: { ...item.coords, [axis]: Number(itemValue) } };
    }
    return item;
  });


  const handlesHolesCoords = (value, sequenceNumber, axis) => {
    if (value < 0) return;

    if (!isInvertAxis && axis === 'y' && value > (Number(frameHeight) - indentFromEdge)) return;

    if (isInvertAxis && axis === 'x' && value > (Number(frameWidth) - indentFromEdge)) return;

    if (!isInvertAxis && axis === 'y') return setPearHolesItems(getItemsToUpdate(value, sequenceNumber, axis, true));

    setPearHolesItems(getItemsToUpdate(value, sequenceNumber, axis));
  };


  const sanitizeHolesCoords = (value, sequenceNumber, axis) => {
    const inRange = getXRanges(sequenceNumber).some(({ min, max }) => (value >= min && value <= max));

    if (!isInvertAxis && axis === 'y' && value < indentFromEdge) {
      value = indentFromEdge;
      setPearHolesItems(getItemsToUpdate(value, sequenceNumber, axis, true));
      setPrevPearHolesItems(getItemsToUpdate(value, sequenceNumber, axis, true));
    }

    if (isInvertAxis && axis === 'x' && value < indentFromEdge) {
      value = indentFromEdge;
      setPearHolesItems(getItemsToUpdate(value, sequenceNumber, axis));
    }

    if (
      isInvertAxis && axis === 'x' && inRange
    ) return dispatch(FasteningElementAction.toggleWarningDistanceModal(true));

    if (!isInvertAxis && axis === 'y') {
      setPrevPearHolesItems(getItemsToUpdate(value, sequenceNumber, axis, true));
    }

    if (isInvertAxis && axis === 'x') {
      setPrevPearHolesItems(getItemsToUpdate(value, sequenceNumber, axis));
    }
  };


  const clearParams = () => {
    setPearHolesAmount(0);
    setPearHolesItems([]);
    setPrevPearHolesItems([]);
    if (frameProfile?.replace('-N', '') === 'P-32') return setPearHolesPlacementSide('left-right');
    setPearHolesPlacementSide('top');
  };


  const onWarningDistanceClose = () => {
    setPearHolesItems(prevPearHolesItems);
    dispatch(FasteningElementAction.toggleWarningDistanceModal(false));
  };


  const onWarningHeightWidthSubmit = () => {
    if (!isInvertAxis) dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 160 }));
    if (isInvertAxis) dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 160 }));
    dispatch(DoorsActions.setPearHoles(activeDoor, {
      amount: 0,
      items: [],
      placementSide: pearHolesPlacementSide,
    }));

    dispatch(FasteningElementAction.toggleWarningHeightModal(false));
  };


  const removeFieldInvalidFromCoords = (number, coordX, coordY) => {
    const itemsToUpdate = pearHolesItems.map((item) => ((item.sequenceNumber === number
      && (
        (isInvertAxis && (frameWidth >= indentFromEdge * 2)) || (!isInvertAxis && (frameHeight >= indentFromEdge * 2))))
      ? { ..._.omit(item, ['isInvalid']), coords: { x: coordX, y: coordY } }
      : item));

    setPearHolesItems(itemsToUpdate);
  };


  const renderPlacementSide = () => {
    const placementSides = frameProfile?.replace('-N', '') === 'P-32'
      ? [{ id: 'left-right' }]
      : [{ id: 'top' }, { id: 'left-right' }];
    return (
      <div className="side-list">
        {placementSides.map(({ id }) => (
          <button
            type="button"
            key={id}
            id={id}
            className={clsx('side-item', id === pearHolesPlacementSide && 'active')}
            onClick={() => setPearHolesPlacementSide(id)}
          >
            {id === 'left-right'
              ? (
                <>
                  <span className={clsx('circle', 'left')} />
                  <span className={clsx('circle', 'right')} />
                </>
              )
              : <span className={clsx('circle', 'top')} />}
            <span className="side-label">{t(`drillingPearHoles.${id}`)}</span>
          </button>

        ))}
      </div>
    );
  };

  const renderHolesCoords = () => {
    const indentFromStartInHeight = indentFromEdge;
    const indentFromEndInHeight = (Number(frameHeight) - indentFromEdge);
    const indentFromStartInWidth = indentFromEdge;
    const indentFromEndInWidth = (Number(frameWidth) - indentFromEdge);

    const axisYRange = t('drillingPearHoles.range', {
      min: indentFromStartInHeight,
      max: indentFromEndInHeight,
    });
    const axisXRange = t('drillingPearHoles.range', {
      min: indentFromStartInWidth,
      max: indentFromEndInWidth,
    });

    const heightDistance = indentFromEndInHeight - indentFromStartInHeight;
    const widthDistance = indentFromEndInWidth - indentFromStartInWidth;


    const isDisabledInput = (axis, isInvalid) => Boolean(
      (isInvertAxis && axis === 'y') || (!isInvertAxis && axis === 'x')
      || (isInvertAxis && axis === 'x' && isInvalid && widthDistance < 0)
      || (!isInvertAxis && axis === 'y' && isInvalid && heightDistance < 0),
    );

    const isInvalidDisabledInput = (axis, isInvalid) => {
      if ((isInvertAxis && axis === 'x' && isInvalid && widthDistance < 0)
        || (!isInvertAxis && axis === 'y' && isInvalid && heightDistance < 0)) return 'invalid-disabled';
      if ((isInvertAxis && axis === 'x' && isInvalid && widthDistance >= 0)
        || (!isInvertAxis && axis === 'y' && isInvalid && heightDistance >= 0)) return 'invalid';
      if ((isInvertAxis && axis === 'y') || (!isInvertAxis && axis === 'x')) return 'disabled';
      return '';
    };

    const itemsToRender = pearHolesPlacementSide === 'left-right' ? pearHolesItems.slice(1) : pearHolesItems;

    return (
      <div className="pear-hole-coords">
        {itemsToRender?.map(({ sequenceNumber, coords: { x, y }, isInvalid }) => (
          <div key={`coords-for-hole-${sequenceNumber}`} className="pear-hole-coords-wrapper">
            <div className="pear-hole-coords-title">
              {pearHolesPlacementSide === 'left-right'
                ? t('drillingPearHoles.holes-coord-double')
                : t('drillingPearHoles.holes-coords', { sequenceNumber })}
            </div>

            <div className="pear-hole-coords-items">
              <div className="pear-hole-coords-item">
                <div className="pear-hole-coords-item-wrapper">
                  <div className="pear-hole-coords-item-symbol">X</div>
                  <div className={clsx('pear-hole-coords-input-wrapper', isInvalidDisabledInput('x', isInvalid))}>
                    <Input
                      inputRef={inputRefX}
                      className="small"
                      type="number"
                      placeholder="0"
                      direction="rtl"
                      value={x.toString()}
                      onFocus={() => {
                        removeFieldInvalidFromCoords(sequenceNumber, x, y);
                        dispatch(DoorsActions.setFrameActiveInput({ name: 'pearHoles', number: sequenceNumber }));
                      }}
                      onChange={(e) => handlesHolesCoords(e.target.value, sequenceNumber, 'x')}
                      onBlur={(e) => {
                        sanitizeHolesCoords(e.target.value, sequenceNumber, 'x');
                        dispatch(DoorsActions.clearFrameActiveInput());
                      }}
                      onKeyDown={(e) => {
                        blockInvalidChar(e, invalidChars);
                        if (e.keyCode === 13) {
                          inputRefX.current.blur();
                          inputRefY.current.focus();
                        }
                      }}
                      key={`holesForHandlesItemX-${sequenceNumber}`}
                      name={`holesForHandlesItemX-${sequenceNumber}`}
                      isDisabled={isDisabledInput('x', isInvalid)}
                    />

                    {(isInvertAxis && (frameWidth >= indentFromEdge * 2)) && (
                      <div className="pear-hole-coords-input-range">
                        {axisXRange}
                      </div>
                    )}

                    <div className="pear-hole-coords-input-tooltip">
                      {t('drillingPearHoles.tooltip')}
                      <span />
                    </div>
                    <div className="pear-hole-coords-input-tooltip2">
                      {t('drillingPearHoles.tooltip-height')}
                      <span />
                    </div>

                  </div>
                </div>
              </div>

              <div className="pear-hole-coords-item">
                <div className="pear-hole-coords-item-wrapper">
                  <div className="pear-hole-coords-item-symbol">Y</div>
                  <div className={clsx('pear-hole-coords-input-wrapper', isInvalidDisabledInput('y', isInvalid))}>
                    <Input
                      inputRef={inputRefY}
                      className="small"
                      type="number"
                      placeholder="0"
                      direction="rtl"
                      value={y.toString()}
                      onFocus={() => {
                        removeFieldInvalidFromCoords(sequenceNumber, x, y);
                        dispatch(DoorsActions.setFrameActiveInput({ name: 'pearHoles', number: sequenceNumber }));
                      }}
                      onChange={(e) => handlesHolesCoords(e.target.value, sequenceNumber, 'y')}
                      onBlur={(e) => {
                        sanitizeHolesCoords(e.target.value, sequenceNumber, 'y');
                        dispatch(DoorsActions.clearFrameActiveInput());
                      }}
                      onKeyDown={(e) => {
                        blockInvalidChar(e, invalidChars);
                        if (e.keyCode === 13) {
                          inputRefY.current.blur();
                        }
                      }}
                      key={`holesForHandlesItemY-${sequenceNumber}`}
                      name={`holesForHandlesItemY-${sequenceNumber}`}
                      isDisabled={isDisabledInput('y', isInvalid)}
                    />

                    {(!isInvertAxis && (frameHeight >= indentFromEdge * 2)) && (
                      <div className="pear-hole-coords-input-range">
                        {axisYRange}
                      </div>
                    )}

                    <div className="pear-hole-coords-input-tooltip">
                      {t('drillingPearHoles.tooltip')}
                      <span />
                    </div>
                    <div className="pear-hole-coords-input-tooltip2">
                      {t('drillingPearHoles.tooltip-width')}
                      <span />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };



  return (
    <div className="pear-hole">
      <div className="pear-hole-title">
        {t('stickyMenu.desktop.drilling-pear')}
      </div>
      <button
        className="pear-hole-close modal-close-button"
        type="button"
        onClick={() => dispatch(DrillingPearHolesAction.toggleDrillingPearHoles(false))}
      >
        <div className="modal-close-button-cross" />
      </button>
      <div className="pear-hole-warning">
        <div className="pear-hole-warning-content">
          {t('drillingPearHoles.warning')}
        </div>
        <ReactSVG
          wrapper="div"
          className="pear-hole-warning-icon"
          src="/src/client/assets/icons/icon-warning.svg"
        />
      </div>
      <div className="pear-hole-placement">
        <div className="pear-hole-placement-title">
          {t('drillingPearHoles.placement-side')}
        </div>
        {renderPlacementSide()}
      </div>
      <div className="pear-hole-amount">
        <div className="pear-hole-amount-title">
          {t('drillingPearHoles.holes-amount')}
        </div>

        <PlusMinusControl
          step={pearHolesPlacementSide === 'left-right' ? 2 : 1}
          amount={pearHolesAmount || 0}
          name="holesForHandlesAmount"
          setAmount={onPearHolesAmountChange}
        />
      </div>

      {renderHolesCoords()}

      <Button
        value={t('drillingPearHoles.clear-params')}
        className="pear-hole-clear-button"
        onClick={() => clearParams()}
      />

      <WarningDistanceModal
        isOpen={isWarningDistanceModalOpen}
        distance={distanceBetweenCoords}
        onCloseModal={onWarningDistanceClose}
        onSubmit={onWarningDistanceClose}
      />

      <WarningInsufficientHeightModal
        isOpen={isWarningHeightModalOpen}
        content={warningModalContent}
        onCloseModal={() => dispatch(FasteningElementAction.toggleWarningHeightModal(false))}
        onSubmit={onWarningHeightWidthSubmit}
      />
    </div>
  );
};

export default DrillingPearHoles;
