import React from 'react';


const sampleCustomers = () => (
  <>
    <code>
      {String('{ "Контрагенты": [ {')}

      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Регион": "Львов",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Код": "000000000",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Наименование": "Прізвище  Ім\'я",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ТипЦен": "Розничная",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ТипЦенПеревод": {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Укр": "Розничная Укр",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Рос": "Розничная Рос",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Пол": "Розничная Пол",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Англ": "Розничная Англ"')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('}')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ВалютаДоговора": "PLN",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Роль": "Дилер",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ТелефонДилера": "380638425364",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ПочтаМенеджера": "ads-manager@mail.com",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ОтображатьЦену": true,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Телефоны": [ ')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"380680000000"')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String(' ],')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ДоступныеПакеты": [ {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ТипЦен": "Розничная USD",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Укр": "Розничная Укр",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Рос": "Розничная Рос",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Пол": "Розничная Пол",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Англ": "Розничная Англ"')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('} ]')}
      <br />
      {String('} ] }')}
    </code>
  </>
);


const sampleManagers = () => (
  <>
    <code>
      {String('{ "Менеджеры": [ {')}

      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"GUID": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Код": "000000000",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Наименование": "Прізвище  Ім\'я",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"НомерТелефона": "380680000000",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Почта": "ads-manager@gmail.com",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Пароль": "пароль123",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Валюта": "UAH",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Язык": "uk",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Контрагенты": [ ')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('380680000000,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('380680000001')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String(' ],')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"СсылкаНаФото": ""')}
      <br />
      {String('} ] }')}
    </code>
  </>
);


const sampleItemsJson = () => (
  <>
    <code>
      {String('{ "Номенклатура": [ {')}

      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Регион": "Львов",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Код": "000000000",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Наименование": "AS-119-L Ручка бокова лайт",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"НаименованиеНаСайтУкр": "AS-119-L Ручка бокова лайт",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"НаименованиеНаСайтРос": "AS-119-L Ручка боковая лайт",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"НаименованиеНаСайтАнг": "AS-119-L Side handle lite",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"НаименованиеНаСайтПол": "AS-119-L Uchwyt boczny lite",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Артикул": "AS-119-L",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ЕдиницаХранения": "пог. м",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ТипНоменклатуры": "Профиль",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допТипНаполнения": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"СсылкаНаКартинку": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"КатегорияНаполнения": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ТолщинаДСП": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Недоступен": false,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Прозрачность": true,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ЄТекстура": true,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"МаксимальнаВисотаДляСтруктури": 1800,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Цены": [ {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Код": "000000000",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Наименование": "Розничная",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Цена": "36,45"')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('} ]')}
      <br />

      {String('} ] }')}
    </code>
  </>
);

const sampleExchangeRatesJson = () => (
  <>
    <code>
      {String('{')}

      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"USD": 29.26,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"EUR": 33.2,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"PLN": 6.8')}
      <br />
      {String('}')}
    </code>
  </>
);

const sampleSideProfilesBySystemType = () => (
  <>
    <code>
      {String('{')}

      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"extendable": ["119", "117", "219", "207"],')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"opening": ["119", "07"],')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"assembling": ["119", "07"]')}
      <br />
      {String('}')}
    </code>
  </>
);

const sampleSetGluingFillingAndPolishingForFrameProfiles = () => (
  <>
    <code>
      {String('{')}
      <br />

      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Поклейка": {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"P-34": true,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"P-38": false,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"P-33-N": true')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('},')}
      <br />

      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Полировка": {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"P-34": true,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"P-38": false,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"P-33-N": true')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('}')}
      <br />

      {String('}')}
    </code>
  </>
);

const sampleDeliveryJson = () => (
  <>
    <code>
      {String('{ "Доставки": [ {')}

      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Регион": "Львов",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Код": "000000000",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"МістоДляСайтаУкр": "Львів",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"МістоДляСайтаРус": "Львов",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"АдресаДляСайтаУкр": "вул. Луганська 6, ТЦ \'Гора\', офіс ADS",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"АдресаДляСайтаРус": "ул. Луганская 6, ТЦ \'Гора\', офис ADS",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Офис": false,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Недоступен": false,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ТипДоставки": "Самовивіз"')}

      <br />
      {String('} ] }')}
    </code>
  </>
);


const sampleHingesJson = () => (
  <>
    <code>
      {String('{ "Петли": [ {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Профиль": "P-31",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Накладная петля": {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Артикулы": ["311.90.020"]')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('},')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Полунакладная петля": {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Артикулы": ["311.04.000"]')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('},')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Внутренняя петля": {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Артикулы": ["71B3550 MB V250 ONS"]')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('},')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Равнолежащая петля": {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Артикулы": ["71B3550 MB V250 NI"]')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('}')}
      <br />
      {String('} ] }')}
    </code>
  </>
);


const sampleStatuses = () => (
  <>
    <code>
      {String('{ "Заказы": [ {')}

      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"СсылкаЗаказа": "60abac0c4fb54c36b58e0970",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Статус": "На опрацюванні",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Номер1С": "123123ab",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Ссылка": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Источник": "Л",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ТипЗаказа": "D - Двери",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Направление": "Львов",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Номер": 111,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Сумма": 4289.55,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Контрагент": "380680000000",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Комментарий": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"КомментарийМенеджера": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допПроемВысота": 3200,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допПроемШирина": 3222,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допДлинаНапр": 3222,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допБП": "119",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допЦвет": "AB",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допДверьВысота": 3157,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допДверьШирина": 1091.333,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допКолДверей": 3,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допКолСборка": 3,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"адсКонструктор": false,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допНеБронировать": false,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допСтруктураДСП": false,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допОстаткиКлиента": false,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допСуммаСборки": 0,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допНаправлениеВнешний": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допПунктВнешний": "",')}

      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Товары": [ {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Код": "000000148",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допКодЛьвов": "000000148",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Наименование": "AB-119 Ручка бокова широкий паз",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Артикул": "AB-119",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Размеры": "3157x6",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Количество": 18.942,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ИД": "nbac01w6-bp74-hd09-b854-xz96o6s5w3uw",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ФИД": ""00000000-0000-0000-0000-000000000000,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Цена": 88.87,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Сумма": 1683.38')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('} ],')}

      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Услуги": [ {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Код": "000000716",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"допКодЛьвов": "000000716",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Наименование": "Робота",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Артикул": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Размеры": "",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Количество": 1,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ИД": "mcd6ypa7-zp7d-69i8-pobb-362aak1n35ts",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"ФИД": "00000000-0000-0000-0000-000000000000",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Цена": 60,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Сумма": 60')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('} ]')}

      <br />
      {String('} ] }')}
    </code>
  </>
);


const sampleSystemConstants = () => (
  <>
    <code>
      {String('[ {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Тип системы": "Раздвижная",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Код": 1,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Наименование": "A06",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Парам для расчета высоты двери": 38,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Парам для расчета ширины двери": 32,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Парам для расчета нижнего профиля": 64,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Парам для расчета верхнего профиля": 64,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Высота наполнения (стекло)": 60,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Высота наполнения": 60,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Ширина наполнения (стекло)": 49,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Ширина наполнения": 49,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Числовой код": 6,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Высота верхнего профиля": 13,')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Тип БП": 6')}

      <br />
      {String('} ]')}
    </code>
  </>
);

const sampleSetOrdersEschanged = () => (
  <>
    <code>
      {String('{ "Заказы": [ {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"СсылкаЗаказа": "61bb6bd7bb6bd9004b7833b4",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Номер1С": "МА000099232"')}
      <br />
      {String('} ] }')}
    </code>
  </>
);

const sampleSetOrdersStatusExchanged = () => (
  <>
    <code>
      {String('{ "Заказы": [ {')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"СсылкаЗаказа": "61bb6bd7bb6bd9004b7833b4",')}
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {String('"Статус": "in-processing"')}
      <br />
      {String('} ] }')}
    </code>
  </>
);

const sampleDiscounts = () => (
  <>
    <code>
      <pre>
        {
`{
  "Знижки": [
    {
      "Код": "000000000",
      "Регіон": "Львов",
      "НомериТелефонів": [380970102030, 380970102035],
      "Артикули": [
        {
          "Назва": "AS-113-L",
          "ВідсотокЗнижки": 10
        },
        {
          "Назва": "AS-116-L",
          "ВідсотокЗнижки": 15
        }
      ],
      "ПочатковаДата": "",
      "КінцеваДата": "",
      "ВідсотокЗнижки": 10,
      "СумаДокументуБільшаНіж": 100,
      "КількістьОдногоТоваруБільшаНіж": {
        "Артикул": "AS-111-L",
        "Кількість": 5
      },
      "Безумовна": true,
      "ТипЗамовлення": "",
      "ТипСистеми": "",
      "ЗнижкаДляКалькулятора": true,
      "Комбінована": {
        "ТипЗамовлення": "",
        "ТипиСистем": [""],
        "ТипиБоковихПрофілів": [""],
        "КольориСистем": [""],
      }
    }
  ]  
}`
        }
      </pre>
    </code>
  </>
);

export {
  sampleCustomers,
  sampleManagers,
  sampleItemsJson,
  sampleDeliveryJson,
  sampleHingesJson,
  sampleExchangeRatesJson,
  sampleSetGluingFillingAndPolishingForFrameProfiles,
  sampleSideProfilesBySystemType,
  sampleStatuses,
  sampleSystemConstants,
  sampleSetOrdersEschanged,
  sampleSetOrdersStatusExchanged,
  sampleDiscounts,
};
