export const defaultAluminiumColor = 'AS';
export const defaultTopProfile = '02';
export const defaultBottomProfile = '01';
export const defaultSealing = 'YA-113';
export const defaultAssemblyScrew = 'D50';
export const topDoorLatchMechanism = 'дотяг_верх_OPK';
export const bottomDoorLatchMechanism = 'дотяг_низ';
export const defaultMilling = 'frez_DSP';
export const packagingOfGuidanceProfile = 'pak_n';
export const defaultPackageName = 'Розничная USD';
export const defaultStrainer = 'TD017A стяж';
export const hingedMiddleDoorMechanism = 'дотяг_Tutti';
export const assemblingAdditionalMechanism = '10100217301';
export const defaultBung = 'AS-108-1';
export const doorPositioningOptions = [
  {
    value: 'chessboard',
    label: 'Шахматна',
    iconPath: '/src/client/assets/icons/chessboard-shaped.svg',
  }, {
    value: 'symmetrical',
    label: 'Симметрична',
    iconPath: '/src/client/assets/icons/symmetrically-shaped.svg',
  },
];

export const constantsBySystemType = [
  {
    systemType: 'extendable', // Розсувна
    defaultDoorsAmount: 2,
    defaultMinDoorsAmount: 2,
    defaultMaxDoorsAmount: 8,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '119',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'chessboard',
    minDoorOpeningWidth: 600,
    maxDoorOpeningWidth: 5400,
    minDoorOpeningHeight: 300,
    maxDoorOpeningHeight: 3200,
    minDoorWidth: 300,
    maxDoorWidth: 1500,
  }, {
    systemType: 'opening', // Відкривна
    defaultMinDoorsAmount: 1,
    defaultMaxDoorsAmount: 2,
    defaultDoorsAmount: 1,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '119',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'chessboard',
    minDoorOpeningWidth: 200,
    maxDoorOpeningWidth: 600,
    minDoorOpeningHeight: 300,
    maxDoorOpeningHeight: 2500,
    minDoorWidth: 200,
    maxDoorWidth: 600,
  }, {
    systemType: 'monorail', // Монорельс
    defaultDoorsAmount: 1,
    defaultMinDoorsAmount: 1,
    defaultMaxDoorsAmount: 10,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '119',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'chessboard',
    minDoorOpeningWidth: 300,
    maxDoorOpeningWidth: 5400,
    minDoorOpeningHeight: 300,
    maxDoorOpeningHeight: 3200,
    minDoorWidth: 300,
    maxDoorWidth: 1400,
  }, {
    systemType: 'assembling', // Складальна
    defaultMinDoorsAmount: 2,
    defaultMaxDoorsAmount: 4,
    defaultDoorsAmount: 2,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '119',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'chessboard',
    minDoorOpeningWidth: 300,
    maxDoorOpeningWidth: 1500,
    minDoorOpeningHeight: 300,
    maxDoorOpeningHeight: 2600,
    minDoorWidth: 300,
    maxDoorWidth: 1500,
  }, {
    systemType: 'hinged', // Навісна
    defaultMinDoorsAmount: 2,
    defaultMaxDoorsAmount: 4,
    defaultDoorsAmount: 2,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '419',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'left-front',
    defaultSidewallThickness: 18,
    minSidewallThickness: 16,
    maxSidewallThickness: 36,
    minDoorOpeningWidth: 300,
    maxDoorOpeningWidth: 5400,
    minDoorOpeningHeight: 400,
    maxDoorOpeningHeight: 2700,
    minDoorWidth: 300,
    maxDoorWidth: 1400,
  }, {
    systemType: 'suspended', // Підвісна
    defaultMinDoorsAmount: 1,
    defaultMaxDoorsAmount: 8,
  },
];

export const sealingOptions = {
  'YA-122/110': {
    colorsDependence: ['AC', 'AG', 'AN', 'ANM', 'AS', 'BC', 'BCM', 'BO', 'FY', 'GB', 'GBM', 'GR', 'PL', 'WG', 'WH', 'AO', 'LB'],
    profilesDependence: ['P-32'],
  },
  'YA-122/110_blk': {
    colorsDependence: ['BB', 'BBM', 'BM', 'BW', 'WM', 'AB'],
    profilesDependence: ['P-32'],
  },
  'YA-121/155': {
    colorsDependence: ['AC', 'AG', 'AN', 'ANM', 'AS', 'BC', 'BCM', 'BO', 'FY', 'GB', 'GBM', 'GR', 'PL', 'WG', 'WH', 'AO', 'LB'],
    profilesDependence: ['P-31', 'P-33', 'P-37', 'P-38'],
  },
  'YA-121/155_blk': {
    colorsDependence: ['BB', 'BBM', 'BM', 'BW', 'WM', 'AB'],
    profilesDependence: ['P-31', 'P-33', 'P-37', 'P-38'],
  },
  'YA-131': {
    colorsDependence: ['AC', 'AG', 'AN', 'ANM', 'AS', 'BC', 'BCM', 'BO', 'FY', 'GB', 'GBM', 'GR', 'PL', 'WG', 'WH', 'AO', 'BB', 'BBM', 'BM', 'BW', 'WM', 'AB', 'LB'],
    profilesDependence: ['P-36'],
  },
};

export const screwsOptions = {
  din_965_zn: ['AC', 'AG', 'AN', 'ANM', 'AS', 'BC', 'BCM', 'BO', 'FY', 'GB', 'GBM', 'GR', 'PL', 'WG', 'WH', 'AO', 'LB'],
  din_965_blk: ['BB', 'BBM', 'BM', 'BW', 'WM', 'AB'],
};

export const reinforcingProfileOptions = {
  'AS-P-3496': ['AS', 'BO', 'GB', 'GBM', 'GR', 'PL'],
  'AN-P-3496': ['AC', 'AG', 'AN', 'ANM', 'BC', 'BCM', 'FY', 'WG', 'AO', 'LB'],
  'WH-P-3496': ['WH'],
  'BM-P-3496': ['BB', 'BBM', 'BM', 'BW', 'WM', 'AB'],
};

export const frameProfileParameters = {
  'P-31': {
    size: 6,
    reinforcingProfile: 88,
  },
  'P-32': {
    size: 30,
    reinforcingProfile: 38,
  },
  'P-32-N': {
    size: 27,
    reinforcingProfile: 38,
  },
  'P-33': {
    size: 28,
    reinforcingProfile: 90,
  },
  'P-33-N': {
    size: 25,
    reinforcingProfile: 88,
  },
  'P-34': {
    size: 1,
    reinforcingProfile: 88,
  },
  'P-36': {
    size: 1,
    reinforcingProfile: 88,
  },
  'P-37': {
    size: 92,
    reinforcingProfile: 100,
  },
  'P-37-N': {
    size: 89,
    reinforcingProfile: null,
  },
  'P-38': {
    size: 91,
    reinforcingProfile: null,
  },
};

export const hasBottomLatchMechanism = ({ doors }) => {
  const doorsWithBottomLanchMechanism = doors.filter((d) => d.main.isDoorLatchMechanismOn?.value === true
    && d.main.doorLatchMechanism?.value === 'дотяг_низ');

  return doorsWithBottomLanchMechanism.length > 0;
};
