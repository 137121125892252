import React from 'react';
import PropTypes from 'prop-types';
import Signin from '../../containers/Signin';

import Modal from '../Modal';



const LoginModal = ({ isOpen, className, onCloseModal }) => {
  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      key="order-title-modal"
      shouldDisableBodyScroll
    >
      <Signin />
    </Modal>
  );
};

LoginModal.defaultProps = {
  isOpen: false,
  className: 'action-modal',
};

LoginModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
};

export default LoginModal;
