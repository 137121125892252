export default [
  {
    profile: 'P-31',
    profileHeight: {
      height: 20,
      tooth: 7,
    },
    profileWidth: {
      width: 44,
      tooth: 7,
    },
    minFrameOpeningWidth: 130,
    maxFrameOpeningWidth: 750,
    minFrameOpeningHeight: 130,
    maxFrameOpeningHeightWithoutReinforcingProfile: 2000,
    maxFrameOpeningHeightWithReinforcingProfile: 2300,
    connectingCorner: {
      type: 'wide',
      cornersAmount: 4,
      screwsAmount: 8,
    },
  }, {
    profile: 'P-32',
    profileHeight: {
      height: 20,
      tooth: 0,
    },
    profileWidth: {
      width: 18,
      tooth: 0,
    },
    minFrameOpeningWidth: 130,
    maxFrameOpeningWidth: 650,
    minFrameOpeningHeight: 130,
    maxFrameOpeningHeightWithoutReinforcingProfile: 1600,
    maxFrameOpeningHeightWithReinforcingProfile: 2000,
    connectingCorner: {
      type: 'narrow',
      cornersAmount: 4,
      screwsAmount: 8,
    },
  }, {
    profile: 'P-33',
    profileHeight: {
      height: 20,
      tooth: 7,
    },
    profileWidth: {
      width: 44,
      tooth: 16,
    },
    minFrameOpeningWidth: 130,
    maxFrameOpeningWidth: 750,
    minFrameOpeningHeight: 130,
    maxFrameOpeningHeightWithoutReinforcingProfile: 2000,
    maxFrameOpeningHeightWithReinforcingProfile: 2300,
    connectingCorner: {
      type: 'wide',
      cornersAmount: 4,
      screwsAmount: 8,
    },
  }, {
    profile: 'P-34',
    profileHeight: {
      height: 19,
      tooth: 0,
    },
    profileWidth: {
      width: 44,
      tooth: 0,
    },
    minFrameOpeningWidth: 130,
    maxFrameOpeningWidth: 800,
    minFrameOpeningHeight: 130,
    maxFrameOpeningHeightWithoutReinforcingProfile: 2300,
    maxFrameOpeningHeightWithReinforcingProfile: 2500,
    connectingCorner: {
      type: 'wide',
      cornersAmount: 4,
      screwsAmount: 8,
    },
  }, {
    profile: 'P-36',
    profileHeight: {
      height: 20,
      tooth: 6,
    },
    profileWidth: {
      width: 44,
      tooth: 2,
    },
    minFrameOpeningWidth: 130,
    maxFrameOpeningWidth: 650,
    minFrameOpeningHeight: 130,
    maxFrameOpeningHeightWithoutReinforcingProfile: 1200,
    maxFrameOpeningHeightWithReinforcingProfile: 2300,
    connectingCorner: {
      type: 'wide',
      cornersAmount: 4,
      screwsAmount: 8,
    },
  }, {
    profile: 'P-37',
    profileHeight: {
      height: 20,
      tooth: 0,
    },
    profileWidth: {
      width: 50,
      tooth: 0,
    },
    minFrameOpeningWidth: 250,
    maxFrameOpeningWidth: 750,
    minFrameOpeningHeight: 250,
    maxFrameOpeningHeightWithoutReinforcingProfile: 2300,
    maxFrameOpeningHeightWithReinforcingProfile: 2300,
    connectingCorner: {
      type: 'wide',
      cornersAmount: 4,
      screwsAmount: 8,
    },
  },
  {
    profile: 'P-38',
    profileHeight: {
      height: 20,
      tooth: 0,
    },
    profileWidth: {
      width: 50,
      tooth: 0,
    },
    minFrameOpeningWidth: 250,
    maxFrameOpeningWidth: 750,
    minFrameOpeningHeight: 250,
    maxFrameOpeningHeightWithoutReinforcingProfile: 2300,
    maxFrameOpeningHeightWithReinforcingProfile: 2300,
    connectingCorner: {
      type: 'wide',
      cornersAmount: 4,
      screwsAmount: 8,
    },
  },
];
