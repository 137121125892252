import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';

import { DrillingPearHolesTypes } from '../actions/drillingPearHoles';


const INITIAL_STATE = {
  pearHoles: {
    amount: 0,
    items: [],
    placementSide: 'top',
  },

  isOpenDrillingPearHoles: false,
};

const setPearHoles = (state = INITIAL_STATE, { items }) => update(state, {
  pearHoles: { $set: items || INITIAL_STATE.pearHoles },
});

const setAmount = (state = INITIAL_STATE, { amount }) => update(state, {
  pearHoles: { amount: { $set: amount || INITIAL_STATE.pearHoles.amount } },
});
const setItems = (state = INITIAL_STATE, { items }) => update(state, {
  pearHoles: { items: { $set: items || INITIAL_STATE.pearHoles.items } },
});

const setPlacementSide = (state = INITIAL_STATE, { side }) => update(state, {
  pearHoles: { placementSide: { $set: side || INITIAL_STATE.pearHoles.placementSide } },
});

const resetPearHoles = (state = INITIAL_STATE) => update(state, {
  pearHoles: { $set: INITIAL_STATE.pearHoles },
});

const toggleDrillingPearHoles = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isOpenDrillingPearHoles: { $set: isOpen || false },
});


export default createReducer(INITIAL_STATE, {
  [DrillingPearHolesTypes.SET_PEAR_HOLES]: setPearHoles,
  [DrillingPearHolesTypes.SET_AMOUNT]: setAmount,
  [DrillingPearHolesTypes.SET_ITEMS]: setItems,
  [DrillingPearHolesTypes.SET_PLACEMENT_SIDE]: setPlacementSide,

  [DrillingPearHolesTypes.RESET_PEAR_HOLES]: resetPearHoles,

  [DrillingPearHolesTypes.TOGGLE_DRILLING_PEAR_HOLES]: toggleDrillingPearHoles,
});
