import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';

import { ClientListTypes } from '../actions/clientList';

const INITIAL_STATE = {
  clientList: [],
  isLoading: false,
  isComplete: false,
  errorMessage: '',
  manageeInfo: {},
  isChangePackageModalOpen: false,
  isCreateOrderModalOpen: false,
};

const getClientsRequest = (state = INITIAL_STATE) => update(state, {
  isLoading: { $set: true },
  isComplete: { $set: false },
  errorMessage: { $set: '' },
});

const getClientsSuccess = (state = INITIAL_STATE, { clientList }) => update(state, {
  isLoading: { $set: false },
  isComplete: { $set: true },
  clientList: { $set: clientList?.length ? clientList : [] },
});

const getClientsFailure = (state = INITIAL_STATE, { errorMessage }) => update(state, {
  isLoading: { $set: false },
  isComplete: { $set: true },
  errorMessage: { $set: errorMessage },
});

const setManageeInfo = (state = INITIAL_STATE, { manageeInfo }) => update(state, {
  manageeInfo: { $set: manageeInfo || {} },
});

const resetManageeInfo = (state = INITIAL_STATE) => update(state, {
  manageeInfo: { $set: INITIAL_STATE.manageeInfo },
});

const toggleChangePackageModal = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isChangePackageModalOpen: { $set: isOpen },
});

const toggleCreateOrderModal = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isCreateOrderModalOpen: { $set: isOpen },
});

export default createReducer(INITIAL_STATE, {
  [ClientListTypes.GET_CLIENTS_REQUEST]: getClientsRequest,
  [ClientListTypes.GET_CLIENTS_SUCCESS]: getClientsSuccess,
  [ClientListTypes.GET_CLIENTS_FAILURE]: getClientsFailure,
  [ClientListTypes.SET_MANAGEE_INFO]: setManageeInfo,
  [ClientListTypes.RESET_MANAGEE_INFO]: resetManageeInfo,
  [ClientListTypes.TOGGLE_CHANGE_PACKAGE_MODAL]: toggleChangePackageModal,
  [ClientListTypes.TOGGLE_CREATE_ORDER_MODAL]: toggleCreateOrderModal,
});
