import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import Modal from '../Modal';
import Button from '../Button';

const WarningDistanceModal = ({
  isOpen,
  className,
  distance,
  onCloseModal,
  onSubmit,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
    >
      <div className="modal-order-icon-wrapper">
        <ReactSVG
          wrapper="div"
          className="modal-order-icon"
          src="/src/client/assets/icons/price-package-icon.svg"
        />
      </div>
      <h2 className="headings-h2">{t('warningDistanceModal.title', { distance })}</h2>

      <div className="action-buttons">
        <div className="action-buttons-inner">
          <Button
            value={t('warningDistanceModal.return')}
            type="rounded"
            onClick={onSubmit}
            isDisabled={false}
          />
        </div>
      </div>
    </Modal>
  );
};

WarningDistanceModal.defaultProps = {
  isOpen: false,
  className: 'action-modal warning-distance',
};

WarningDistanceModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  distance: PropTypes.number.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default WarningDistanceModal;
