import React from 'react';
import PropTypes from 'prop-types';

const PlusMinusControl = ({
  step,
  amount,
  setAmount,
}) => {
  const handleSetAmount = (type) => {
    if (type === 'plus') setAmount(+amount + (step || 1));
    if (type === 'minus') setAmount(+amount - (step || 1));
  };

  return (
    <div className="plus-minus-control">
      <button type="button" className="minus-control" onClick={() => handleSetAmount('minus')}>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.4999 1.99998C21.4035 1.99998 26.9999 7.59634 26.9999 14.4999C26.9999 21.4035 21.4035 27 14.4999 27C7.59632 27 1.99987 21.4035 1.99987 14.4999C1.99987 7.59634 7.59632 1.99998 14.4999 1.99998Z" fill="#F2F3F4"/> {/* eslint-disable-line */}
          <path fillRule="evenodd" clipRule="evenodd" d="M10.1243 15.6811H18.7526V14.3562H10.1243V15.6811Z" fill="#201720"/> {/* eslint-disable-line */}
        </svg>
      </button>
      <div className="plus-minus-control-amount">{amount}</div>
      <button type="button" className="plus-control" onClick={() => handleSetAmount('plus')}>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M13.5 1.99998C20.4036 1.99998 26 7.59634 26 14.4999C26 21.4035 20.4036 27 13.5 27C6.59646 27 1.00002 21.4035 1.00002 14.4999C1.00002 7.59634 6.59646 1.99998 13.5 1.99998Z" fill="#F2F3F4"/> {/* eslint-disable-line */}
          <path fillRule="evenodd" clipRule="evenodd" d="M8.38934 15.0434H13.1182V19.4117H14.078V15.0434H18.8064V14.1313H14.078V9.76283H13.1182V14.1313H8.38934V15.0434Z" fill="#201720"/> {/* eslint-disable-line */}
        </svg>
      </button>
    </div>
  );
};

PlusMinusControl.propTypes = {
  step: PropTypes.number,
  amount: PropTypes.number,
  setAmount: PropTypes.func,
};

PlusMinusControl.defaultProps = {
  step: 1,
  amount: 0,
  setAmount: () => {},
};

export default PlusMinusControl;
