import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import DoorsActions from '../../../redux/actions/doorsAndSections';
import DrillingHolesAction from '../../../redux/actions/drillingHoles';
import DrillingPearHolesAction from '../../../redux/actions/drillingPearHoles';
import FasteningElementAction from '../../../redux/actions/fasteningElement';

const Tabs = ({
  tabs,
  onChange,
  className,
  activeTabIndex,
}) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';
  const rootClassName = clsx('sticky-menu-tabs', className);
  const isMobile = window.innerWidth < 768;

  const { currentSystem } = useSelector(({ systems }) => systems);
  const { isOpenFillingModal } = useSelector(({ fillingMaterials }) => fillingMaterials);
  const {
    main: {
      doorOpeningHeight,
      doorOpeningWidth,
    },
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const handleChange = (e, index) => {
    e.preventDefault();

    if (isOpenFillingModal) return;

    if (currentSystem === 'frame-facades') {
      dispatch(DrillingHolesAction.toggleDrillingHoles(false));
      dispatch(DrillingPearHolesAction.toggleDrillingPearHoles(false));
      dispatch(FasteningElementAction.toggleFasteningElementModal(false));
      dispatch(DoorsActions.setActiveDoor(index));
      return;
    }

    if (!(doorOpeningHeight?.value && doorOpeningWidth?.value)) {
      dispatch(DoorsActions.hightlightDoorOpeningInputs(labelKey));
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    onChange(index);
  };

  return (
    <div className={rootClassName}>
      {tabs.map((tab, index) => {
        const itemClassName = clsx('sticky-menu-tab', activeTabIndex === index && 'active');
        const shortTabName = `${tab.title.slice(0, 1)} ${tab.title.slice(4)}`;
        return (
          <a
            href="/"
            // (TODO: FIX eslint)
            key={String(index)} // eslint-disable-line react/no-array-index-key
            className={itemClassName}
            onClick={(e) => handleChange(e, index)}
            tabIndex={0}
          >
            {
              isMobile && index === 0 && tabs.length > 6 && currentSystem !== 'frame-facades'
                ? `${tab.title.slice(0, 5)}.`
                : isMobile && tabs.length > 6 && currentSystem !== 'frame-facades'
                  ? shortTabName
                  : currentSystem === 'frame-facades' && index > 0
                    ? t('stickyMenu.mainDoorTab.frame-number', { number: index })
                    : tab.title
            }
          </a>
        );
      })}
    </div>
  );
};

Tabs.defaultProps = {
  tabs: [],
  className: null,
  activeTabIndex: 0,
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
  })),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  activeTabIndex: PropTypes.number,
};

export default Tabs;
