import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';

import { DrillingHolesTypes } from '../actions/drillingHoles';


const INITIAL_STATE = {
  holesForHandles: {
    amount: 0,
    items: [],
    placementSide: 'left',
  },

  isOpenDrillingHoles: false,
};

const setHoles = (state = INITIAL_STATE, { items }) => update(state, {
  holesForHandles: { $set: items || INITIAL_STATE.holesForHandles },
});

const setHolesAmount = (state = INITIAL_STATE, { amount }) => update(state, {
  holesForHandles: { amount: { $set: amount || INITIAL_STATE.holesForHandles.amount } },
});
const updateHolesItems = (state = INITIAL_STATE, { items }) => update(state, {
  holesForHandles: { items: { $set: items || INITIAL_STATE.holesForHandles.items } },
});

const setPlacementSide = (state = INITIAL_STATE, { side }) => update(state, {
  holesForHandles: { placementSide: { $set: side || INITIAL_STATE.holesForHandles.placementSide } },
});

const resetHoles = (state = INITIAL_STATE) => update(state, {
  holesForHandles: { $set: INITIAL_STATE.holesForHandles },
});

const toggleDrillingHoles = (state = INITIAL_STATE, { isOpen }) => update(state, {
  isOpenDrillingHoles: { $set: isOpen || false },
});


export default createReducer(INITIAL_STATE, {
  [DrillingHolesTypes.SET_HOLES]: setHoles,

  [DrillingHolesTypes.SET_HOLES_AMOUNT]: setHolesAmount,
  [DrillingHolesTypes.UPDATE_HOLES_ITEMS]: updateHolesItems,
  [DrillingHolesTypes.SET_PLACEMENT_SIDE]: setPlacementSide,

  [DrillingHolesTypes.RESET_HOLES]: resetHoles,

  [DrillingHolesTypes.TOGGLE_DRILLING_HOLES]: toggleDrillingHoles,

});
