import React from 'react';
import PropTypes from 'prop-types';
import { Group, Label, Line, Text, Rect, Circle } from 'react-konva';
import { randomKey } from '../../helpers/visualisationHelper';
// import useImage from '../../helpers/useImage';

const FixedHoles = ({
  width,
  height,
  pearHoles,
  modalPearHoles,
  isOpenDrillingPearHoles,
  activeHole,
  paddingTop,
  paddingLeft,
  fontSize,
  isScaled,
}) => {
  const holeSize = 35;
  const pearArray = isOpenDrillingPearHoles ? modalPearHoles : pearHoles;
  const { placementSide, items, amount } = pearArray;
  const minIndentWidth = 80;
  const maxIndentWidth = width - minIndentWidth;
  const maxIndentHeight = height - minIndentWidth;

  // const [pearImage] = useImage('/src/client/assets/icons/loops/pear.svg');

  // eslint-disable-next-line react/prop-types
  const PearKonva = ({ x, y, active }) => {
    const fillColor = active ? '#3A84E3' : '#979797';
    const strokeColor = active ? '#3A84E3' : '#979797';

    return (
      <Group
        x={x}
        y={y}
        offsetX={-17}
        offsetY={-17}
      >
        <Circle
          x={0}
          y={0}
          radius={17}
          fill={fillColor}
          opacity={0.3}
        />
        <Rect
          x={-0.5}
          y={-12}
          width={2}
          height={15}
          cornerRadius={10}
          fill={fillColor}
          stroke={strokeColor}
        />
        <Circle
          x={0}
          y={6}
          radius={5}
          fill={fillColor}
          stroke={strokeColor}
        />
      </Group>
    );
  };

  const CenterPear = () => items.map((e, i) => {
    const yPoint = paddingTop + Number(e.coords.y);
    const yTopPoint = paddingTop;
    const xTopPoint = paddingLeft;
    const firstPointVertical = yTopPoint - ((amount - i) * 35);
    const xCenterPoint = Number(e.coords.x) + paddingLeft;
    const cordX = Number(e.coords.x);
    const isValid = Number(e.coords.x) < minIndentWidth || Number(e.coords.x) > maxIndentWidth;
    const item = i + 1;
    const activeField = activeHole === item;
    const strokeColor = activeField ? '#3A84E3' : isValid ? '#ff0000' : '#000';
    const textSize = e.coords.x.toString().length;
    const textDistanceScaled = textSize > 2 ? 30 : 20;
    const textDistanceNormal = textSize > 2 ? 15 : 10;
    const textDistance = isScaled ? textDistanceScaled : textDistanceNormal;
    const verticalNumber = isScaled ? 6 : -2;

    return (
      <Group
        key={randomKey()}
      >
        <Group>
          {/* horizontal line */}
          <Line
            points={[xTopPoint, firstPointVertical + 10, xTopPoint + cordX / 2 - 40, firstPointVertical + 10]}
            stroke={strokeColor}
            strokeWidth={1}
          />

          <Line
            points={[xTopPoint + cordX / 2 + 40, firstPointVertical + 10, xCenterPoint, firstPointVertical + 10]}
            stroke={strokeColor}
            strokeWidth={1}
          />

          {/* central line */}
          <Line
            points={[xCenterPoint, paddingTop, xCenterPoint, firstPointVertical]}
            stroke={strokeColor}
            strokeWidth={1}
          />

          {/* left vertical line */}
          {i === 0 && (
            <Line
              points={[xTopPoint, paddingTop + 5, xTopPoint, firstPointVertical]}
              stroke={strokeColor}
              strokeWidth={1}
            />
          )}

          <Label
            x={paddingLeft + (Number(e.coords.x) / 2) - textDistance}
            y={firstPointVertical - verticalNumber}
          >
            <Text fill={strokeColor} text={e.coords.x} padding={2} fontSize={fontSize} />
          </Label>
        </Group>

        {/* <Image */}
        {/*   x={xCenterPoint - (holeSize / 2)} */}
        {/*   y={yPoint - (holeSize / 2)} */}
        {/*   width={holeSize} */}
        {/*   height={holeSize} */}
        {/*   fillPatternRepeat="no-repeat" */}
        {/*   visible */}
        {/*   fillPatternImage={pearImage} */}
        {/* /> */}

        <PearKonva
          x={xCenterPoint - (holeSize / 2)}
          y={yPoint - (holeSize / 2)}
          active={activeField}
        />
      </Group>
    );
  });

  const SidePear = () => items.map((e, i) => {
    const xPoint = paddingLeft;
    const yPoint = paddingTop + height - Number(e.coords.y);
    const coordY = Number(e.coords.y);
    const firstPointHorizontal = xPoint - ((amount - i) * 20);
    const xPointVertical = firstPointHorizontal + 10;
    const isValid = Number(e.coords.y) < minIndentWidth || Number(e.coords.y) > maxIndentHeight;
    const strokeColor = activeHole ? '#3A84E3' : isValid ? '#ff0000' : '#000';

    return (
      <Group
        key={randomKey()}
      >
        <Group>
          {/* horizontal line top */}
          {i === 0 && (
            <Line
              points={[xPoint, yPoint, firstPointHorizontal, yPoint]}
              stroke={strokeColor}
              strokeWidth={1}
            />
          )}

          {/* vertical line */}
          {i === 0 && (
            <>
              <Line
                points={[xPointVertical, yPoint, xPointVertical, yPoint + coordY / 2 - 20]}
                stroke={strokeColor}
                strokeWidth={1}
              />

              <Line
                points={[xPointVertical, yPoint + coordY / 2 + 20, xPointVertical, height + paddingTop]}
                stroke={strokeColor}
                strokeWidth={1}
              />
            </>
          )}

          {/* bottom line */}
          {i === 0 && (
            <Line
              points={[xPoint, height + paddingTop, firstPointHorizontal, height + paddingTop]}
              stroke={strokeColor}
              strokeWidth={1}
            />
          )}

          {i === 0 && (
            <Label
              x={xPointVertical - 7}
              y={(height - (Number(e.coords.y) / 2)) + paddingTop + 10}
              rotation={270}
            >
              <Text fill={strokeColor} text={e.coords.y} padding={2} fontSize={fontSize} />
            </Label>
          )}
        </Group>

        {/* left side */}
        {/* <Image */}
        {/*   x={PADDING + Number(e.coords.x)} */}
        {/*   y={PADDING + (height - Number(e.coords.y)) - (holeSize / 2)} */}
        {/*   width={holeSize} */}
        {/*   height={holeSize} */}
        {/*   fillPatternRepeat="no-repeat" */}
        {/*   visible */}
        {/*   fillPatternImage={pearImage} */}
        {/* /> */}
        <PearKonva
          x={paddingLeft + Number(e.coords.x) - holeSize / 2}
          y={paddingTop + (height - Number(e.coords.y)) - (holeSize / 2)}
          active={activeHole}
        />

        {/* right side */}
        {/* <Image */}
        {/*   x={paddingLeft + width - Number(e.coords.x) - holeSize} */}
        {/*   y={paddingTop + (height - Number(e.coords.y)) - (holeSize / 2)} */}
        {/*   width={holeSize} */}
        {/*   height={holeSize} */}
        {/*   fillPatternRepeat="no-repeat" */}
        {/*   visible */}
        {/*   fillPatternImage={pearImage} */}
        {/* /> */}

        <PearKonva
          x={paddingLeft + width - Number(e.coords.x) - holeSize / 2}
          y={paddingTop + (height - Number(e.coords.y)) - (holeSize / 2)}
          active={activeHole}
        />
      </Group>
    );
  });

  return (
    <>
      {placementSide === 'top' ? (
        <CenterPear />
      ) : (
        <SidePear />
      )}
    </>
  );
};

FixedHoles.defaultProps = {
  pearHoles: {},
  modalPearHoles: {},
  isOpenDrillingPearHoles: false,
  activeHole: null,
  paddingLeft: 0,
  paddingTop: 0,
  fontSize: 0,
  isScaled: false,
};

FixedHoles.propTypes = {
  pearHoles: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  modalPearHoles: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isOpenDrillingPearHoles: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  activeHole: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingTop: PropTypes.number,
  fontSize: PropTypes.number,
  isScaled: PropTypes.bool,
};

export default FixedHoles;
