import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getPath, getWorkingPath, navigate } from 'hookrouter';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactSVG } from 'react-svg';
import AuthService from '../../services/authService';

import ProfileActions from '../../redux/actions/profile';
import SystemsActions from '../../redux/actions/systems';

import { menuNavigation, menuNavigationAdmin, menuNavigationDealer, menuNavigationManager } from '../../menuNavigation';

import NavigationActions from '../../redux/actions/navigation';
import DoorsActions from '../../redux/actions/doorsAndSections';
import { ADSSystemTypesList } from '../../helpers/constants';

import { Localization } from '../../components';
import Breadcrumbs from '../../components/Breadcrumbs';
import LoginModal from '../../components/LoginModal';
import SignUpByDealerModal from '../../components/SignUpByDealerModal';
import { renderViewSwitcher } from '../../components/SimplifiedFrameConfiguration/FrameFacadesList';
import { formatPhoneNumber } from '../../helpers/sanitizer';

const menuNavigationOptions = {
  admin: menuNavigationAdmin,
  dealer: menuNavigationDealer,
  manager: menuNavigationManager,
};

const Header = ({
  canGoBack,
  className,
  burgerMenuColor,
  showBreadcrumbsDesktop,
}) => {
  const { t } = useTranslation(['components']);
  const dispatch = useDispatch();

  const {
    firstName,
    lastName,
    role,
    primaryRegion,
    packagesAvailable,
    managerId,
    managerInfo,
  } = useSelector(({ profile }) => profile);

  const {
    manageeInfo,
  } = useSelector(({ clientList }) => clientList);

  const { frameConfigurationView } = useSelector(({ doorsAndSections }) => doorsAndSections);

  useEffect(() => {
    if (AuthService.isLoggedIn() && AuthService.isPhoneNumberVerified() && !firstName.value && !lastName.value) {
      dispatch(ProfileActions.getUserProfileRequest());
    }
  }, []);

  const {
    hasChanges,
    main: { doorOpeningHeight, doorOpeningWidth },
  } = useSelector(({ doorsAndSections }) => doorsAndSections);
  const { currentSystem } = useSelector(({ systems }) => systems);
  const { isOrderAccepted, currentOrderId } = useSelector(({ order }) => order);

  const [desktopView, setDesktopView] = useState(false);
  const [desktopMiddleView, setDesktopMiddleView] = useState(false);
  const [hasHistory, setHasHistory] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [signUpByDealerModalOpen, setSignUpByDealerModalOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [initialName, setInitialName] = useState(null);
  const [userRole, setUserRole] = useState('');

  const pathname = window?.location?.pathname;
  const pathArray = pathname.split('/');
  const isSystemPage = _.some(ADSSystemTypesList, (route) => route === pathArray[1]);
  const isOrderPage = pathArray[1] === 'order';
  const isSavedOrdersPage = pathArray[1] === 'saved-orders';
  const isMyOrdersPage = pathArray[1] === 'my-orders';

  const nav = (userRole ? menuNavigationOptions[userRole] : menuNavigation)
    .filter((item) => !isLoggedIn ? item.isPublic : item.isPublic || !item.isPublic); // eslint-disable-line

  const openBurgerMenu = (e) => {
    e.preventDefault();

    dispatch(NavigationActions.openNavigation());
  };

  useEffect(() => { setUserRole(role); }, [role]);

  useEffect(() => {
    if (AuthService.isLoggedIn() && AuthService.isPhoneNumberVerified()) {
      setIsLoggedIn(true);
    }
    if (!AuthService.isLoggedIn() && !AuthService.isPhoneNumberVerified()) {
      setUserRole('');
    }
    if (window.location.href.endsWith('/profile') || window.location.href.endsWith('/clients-list')) {
      dispatch(SystemsActions.resetCurrentSystem());
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window || {};

      setDesktopView(innerWidth >= 768);
      setDesktopMiddleView(innerWidth >= 768 && innerWidth < 950);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    setHasHistory(getPath() !== getWorkingPath());

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleModalLogin = () => {
    if (window.location.href.endsWith('/sign-up-start')) {
      navigate('/sign-in');
    } else {
      setLoginModalOpen(!loginModalOpen);
      setDisable(!disable);
    }
  };

  const handleSignUpByDealerModal = () => {
    setSignUpByDealerModalOpen(!signUpByDealerModalOpen);
  };

  const handleNavigation = (path) => {
    const navigationPath = isOrderPage && currentOrderId && isOrderAccepted
      ? '/my-orders'
      : isOrderPage && !isOrderAccepted
        ? `/${currentSystem}/edit`
        : isSystemPage ? '/systems' : '/';
    const hasMinimalRequiredInfo = Boolean(doorOpeningHeight?.value && doorOpeningWidth?.value);

    if (isSystemPage && hasChanges && hasMinimalRequiredInfo) {
      dispatch(NavigationActions.toggleLeavePageModal(true, path || navigationPath));
      return;
    }
    navigate(path || navigationPath);
  };

  const handleNavigateToSignIn = () => {
    navigate('/sign-in');
  };

  const onSwitcherClick = (value) => {
    dispatch(DoorsActions.toggleFrameConfigurationView(value));
  };

  const notLoginPage = !window.location.href.endsWith('/sign-in');
  const notOrderPage = !window.location.href.includes('/order');

  const showLoginBtn = !isLoggedIn && notLoginPage;

  useEffect(() => {
    if (isLoggedIn) {
      setLoginModalOpen(false);
      setInitialName(firstName.value.charAt(0) + lastName.value.charAt(0));
    }
  }, [isLoggedIn, firstName, lastName]);

  const renderClientInfo = () => (
    <div
      type="button"
      className="button button-add client"
    >
      <img
        className="header-add"
        src="src/client/assets/icons/white-label.svg"
        title={t('header.client')}
        alt={t('header.client')}
      />
      <span>
        {t('header.client')}
      </span>
      <div className="client">
        <span className="client-item">{`${manageeInfo.firstName} ${manageeInfo.lastName}`}</span>
        <span className="client-item extra">{manageeInfo.packageName}</span>
        <span className="client-item extra">{formatPhoneNumber(`+${manageeInfo.phone}`)}</span>
      </div>
    </div>
  );

  const renderManagerInfo = () => {
    const { email, managerPhone, firstName: managerFN, lastName: managerLN, managerImgUrl } = managerInfo;
    return (
      <div
        type="button"
        className="button button-add client"
      >
        <img
          className="header-add"
          src="src/client/assets/icons/manager.svg"
          title={t('header.manager')}
          alt={t('header.manager')}
        />
        <span>
          {t('header.manager')}
        </span>
        <div className="client">
          <span className="client-item">
            {managerImgUrl ? (
              <div className="image">
                <img
                  alt="img"
                  src={managerImgUrl}
                />
              </div>
            )
              : (
                <ReactSVG
                  className="icon"
                  wrapper="div"
                  src="/src/client/assets/icons/manager.svg"
                />
              )}
            <span className="label">
              {`${managerFN} ${managerLN}`}
            </span>
          </span>
          <span className="client-item extra">{formatPhoneNumber(`+${managerPhone}`)}</span>
          <span className="client-item extra">{email}</span>
        </div>
      </div>
    );
  };

  return (
    <header
      role="banner"
      className={clsx('header', className)}
    >
      <div className="header--inner">
        {!showBreadcrumbsDesktop
          ? (
            <a
              href="https://ads-decor.ua/"
              className="header--logo-button"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="src/client/assets/images/logo.svg"
                alt="ADS"
                width="76"
                height="27"
              />
            </a>
          ) : null}
        {showBreadcrumbsDesktop ? <Breadcrumbs /> : null}

        {window.innerWidth < 1024 && notLoginPage && currentSystem === 'frame-facades' && notOrderPage && (
          <div className="header-frame-switcher">
            {renderViewSwitcher(frameConfigurationView, onSwitcherClick)}
          </div>
        )}

        {desktopView && (
          <div className="header--desktop">
            {nav.map(({ key, path, img, showIcon, isGuest }) => {
              if (desktopMiddleView) {
                return (
                  <div
                    className={clsx('header--desktop-nav-item',
                      pathname === path && 'selected',
                      isLoggedIn === isGuest && 'guest')}
                    key={key}
                  >
                    <button
                      type="button"
                      onClick={() => handleNavigation(path)}
                    >
                      {key === 'profile'
                        ? (
                          <span className="profile-icon">
                            {initialName}
                          </span>
                        )
                        : (
                          <img
                            className="header--desktop-nav-icon"
                            src={`src/client/assets/icons/header/${img}-blue.svg`}
                            title={t(`header.${key}`)}
                            alt={t(`header.${key}`)}
                          />
                        )}
                    </button>
                  </div>
                );
              }
              return (
                <div
                  className={clsx('header--desktop-nav-item',
                    pathname === path && 'selected',
                    key === 'discount' && 'green-bg',
                    isLoggedIn === isGuest && 'guest')}
                  key={key}
                >
                  <button
                    type="button"
                    onClick={() => handleNavigation(path)}
                  >
                    {showIcon && (
                      <img
                        className="header--desktop-nav-icon"
                        src={`src/client/assets/icons/header/${img}-blue.svg`}
                        title={t(`header.${key}`)}
                        alt={t(`header.${key}`)}
                      />
                    )}
                    <span className={key === 'profile' ? 'profile-item' : undefined}>
                      {key === 'profile'
                        && (
                          <span className="profile-icon">
                            {initialName}
                          </span>
                        )}
                      {t(`header.${key}`)}
                    </span>
                  </button>
                </div>
              );
            })}
            {!isLoggedIn && <Localization />}
          </div>
        )}

        {userRole === 'dealer' && (
          <button
            type="button"
            className="button button-add"
            onClick={() => handleSignUpByDealerModal()}
          >
            <img
              className="header-add"
              src="src/client/assets/icons/header/plus.svg"
              title={t('header.add-client')}
              alt={t('header.add-client')}
            />
            <span>
              {t('header.add-client')}
            </span>
          </button>
        )}

        {(userRole === 'manager' && !_.isEmpty(manageeInfo) && !isSavedOrdersPage && !isMyOrdersPage) && renderClientInfo()}

        {managerId && renderManagerInfo()}

        {!isLoggedIn && (
          <button
            className="button rounded header-login-btn"
            type="button"
            disabled={disable || !showLoginBtn}
            onClick={handleNavigateToSignIn}
          >
            <span>{t('landing.sign-in')}</span>
          </button>
        )}

        {!desktopView && (
          <div className={hasHistory && canGoBack
            ? 'header--mobile'
            : 'header--mobile single-button'}
          >
            <div className="header--mobile-button">
              <a
                href="/"
                onClick={openBurgerMenu}
              >
                <img
                  src={burgerMenuColor === 'white'
                    ? '/src/client/assets/icons/header/burger-menu-white.svg'
                    : '/src/client/assets/icons/header/burger-menu.svg'}
                  alt="Open menu"
                />
              </a>
            </div>
          </div>
        )}
      </div>

      <LoginModal
        isOpen={loginModalOpen}
        onCloseModal={() => handleModalLogin()}
        className="action-modal login-modal"
      />
      <SignUpByDealerModal
        isOpen={signUpByDealerModalOpen}
        onCloseModal={() => handleSignUpByDealerModal()}
        packages={packagesAvailable}
        primaryRegion={primaryRegion}
      />
    </header>
  );
};

Header.propTypes = {
  canGoBack: PropTypes.bool,
  showBreadcrumbsDesktop: PropTypes.bool,
  burgerMenuColor: PropTypes.string,
  className: PropTypes.string,
};

Header.defaultProps = {
  canGoBack: true,
  showBreadcrumbsDesktop: false,
  burgerMenuColor: 'gray',
  className: '',
};

export default Header;
