import React from 'react';
import { Circle, Group, Label, Line, Tag, Text } from 'react-konva';
import PropTypes from 'prop-types';
import { randomKey } from '../../helpers/visualisationHelper';

const Handles = ({
  width,
  height,
  holes,
  modalHoles,
  isOpenDrillingHoles,
  activeHole,
  isScaled,
  paddingTop,
  paddingLeft,
  paddingBottom,
  fontSize,
}) => {
  // const PADDING = 155;
  const holesArray = isOpenDrillingHoles ? modalHoles : holes;
  const place = holesArray.placementSide;
  const minIndentHeight = 65;
  const minIndentWidth = 11;
  const maxIndentWidth = width - minIndentWidth;
  const maxIndentHeight = height - minIndentHeight;
  const paddingSize = isScaled ? 50 : 40;

  const HandleCircles = () => holesArray.items.map((e, i) => {
    const xCord = place === 'right' ? paddingLeft + width - Number(e.coords.x) : Number(e.coords.x) + paddingLeft;
    const yCord = place === 'top' ? paddingTop + Number(e.coords.y) : paddingTop + height - Number(e.coords.y);
    const item = i + 1;
    const activeField = activeHole === item ? '#3A84E3' : '#201720';

    return (
      <Circle
        key={randomKey()}
        x={xCord}
        y={yCord}
        width={e.holeDiameter}
        height={e.holeDiameter}
        fill={activeField}
      />
    );
  });

  const HandleLines = () => (
    <Group>
      {holesArray.items.map((e, i) => {
        const xPoint = place === 'left' ? paddingLeft : paddingLeft + width;
        const yPoint = place === 'bottom'
          ? paddingBottom + height - Number(e.coords.y)
          : paddingTop + height - Number(e.coords.y);
        const coordY = Number(e.coords.y);
        const coordX = Number(e.coords.x);
        const { length } = holesArray.items;
        const isInvalid = place === 'left' || place === 'right'
          ? Number(e.coords.y) < minIndentHeight || Number(e.coords.y) > maxIndentHeight
          : Number(e.coords.x) < minIndentWidth || Number(e.coords.x) > maxIndentWidth;
        const bottomLabelX = isScaled ? 25 : 10;

        const textLength = () => {
          const textSize = e.coords.y.toString().length;

          if (isScaled) {
            switch (textSize) {
              case 2:
                return -45;
              case 3:
                return -50;
              case 4:
                return -60;
              default:
                return 0;
            }
          } else {
            switch (textSize) {
              case 2:
                return -30;
              case 3:
                return -35;
              case 4:
                return -45;
              default:
                return 0;
            }
          }
        };

        const calculatedTextLength = textLength();
        const sideTextYCoordinate = height - (height - yPoint) / 2 - calculatedTextLength;


        // for left and right
        const firstPointHorizontal = place === 'left' ? xPoint - ((length - i) * 30) : xPoint + (30 * (length - i));
        const xPointVertical = place === 'left' ? firstPointHorizontal + 10 : firstPointHorizontal - 10;

        // for top and bottom
        const xTopPoint = paddingLeft;
        const firstPointVertical = place === 'top'
          ? paddingTop - ((length - i) * 20)
          : paddingTop + height + ((length - i) * 35);
        const xCenterPoint = Number(e.coords.x) + paddingLeft;

        const item = i + 1;
        const activeField = activeHole === item;
        const strokeColor = activeField ? '#3A84E3' : isInvalid ? '#ff0000' : '#000';
        const xPlace = isScaled ? xPointVertical - 12 : xPointVertical - 7;
        const yPlaceBottom = isScaled ? firstPointVertical - 14 : firstPointVertical - 10;
        const yPlaceTop = isScaled ? firstPointVertical - 8 : firstPointVertical + 2;
        const leftRightVerticalLine = height + paddingTop - coordY / 2;

        return (
          <Group
            key={randomKey()}
          >
            {place === 'left' || place === 'right' ? (
              <>
                {/* top horizontal line */}
                <Line
                  points={[xPoint, yPoint, firstPointHorizontal, yPoint]}
                  stroke={strokeColor}
                  strokeWidth={1}
                />

                {/* vertical line */}
                {(leftRightVerticalLine - paddingSize > yPoint
                    || leftRightVerticalLine + paddingSize < height + paddingTop)
                  && (
                    <>
                      <Line
                        points={[
                          xPointVertical,
                          yPoint,
                          xPointVertical,
                          leftRightVerticalLine - paddingSize,
                        ]}
                        stroke={strokeColor}
                        strokeWidth={1}
                      />

                      <Line
                        points={[
                          xPointVertical,
                          leftRightVerticalLine + paddingSize,
                          xPointVertical,
                          height + paddingTop,
                        ]}
                        stroke={strokeColor}
                        strokeWidth={1}
                      />
                    </>
                  )}

                {/* bottom line */}
                {i === 0 && (
                  <Line
                    points={[xPoint, height + paddingTop, firstPointHorizontal, height + paddingTop]}
                    stroke={strokeColor}
                    strokeWidth={1}
                  />
                )}

                <Label
                  x={xPlace}
                  y={sideTextYCoordinate}
                  rotation={270}
                >
                  <Tag fill="transparent" stroke="transparent" />
                  <Text fill={strokeColor} text={e.coords.y} padding={0} fontSize={fontSize} />
                </Label>
              </>
            ) : place === 'bottom' ? (
              <>
                {/* horizontal line */}
                <Line
                  points={[
                    xTopPoint,
                    firstPointVertical,
                    xCenterPoint - coordX / 2 - paddingSize,
                    firstPointVertical,
                  ]}
                  stroke={strokeColor}
                  strokeWidth={1}
                />

                <Line
                  points={[
                    xCenterPoint - coordX / 2 + paddingSize,
                    firstPointVertical,
                    xCenterPoint,
                    firstPointVertical,
                  ]}
                  stroke={strokeColor}
                  strokeWidth={1}
                />

                {/* central line */}
                <Line
                  points={[xCenterPoint, paddingTop + height, xCenterPoint, firstPointVertical + 10]}
                  stroke={strokeColor}
                  strokeWidth={1}
                />

                {/* left vertical line */}
                {i === 0 && (
                  <Line
                    points={[xTopPoint, paddingTop + height, xTopPoint, firstPointVertical + 10]}
                    stroke={strokeColor}
                    strokeWidth={1}
                  />
                )}

                <Label
                  x={paddingLeft + (Number(e.coords.x) / 2) - bottomLabelX}
                  y={yPlaceBottom}
                >
                  <Text fill={strokeColor} text={e.coords.x} padding={2} fontSize={fontSize} />
                </Label>
              </>
            ) : (
              <>
                {/* horizontal line */}
                <Line
                  points={[
                    xTopPoint,
                    firstPointVertical + 10,
                    xCenterPoint - coordX / 2 - paddingSize,
                    firstPointVertical + 10,
                  ]}
                  stroke={strokeColor}
                  strokeWidth={1}
                />

                <Line
                  points={[
                    xCenterPoint - coordX / 2 + paddingSize,
                    firstPointVertical + 10,
                    xCenterPoint,
                    firstPointVertical + 10,
                  ]}
                  stroke={strokeColor}
                  strokeWidth={1}
                />

                {/* central line */}
                <Line
                  points={[xCenterPoint, paddingTop, xCenterPoint, firstPointVertical]}
                  stroke={strokeColor}
                  strokeWidth={1}
                />

                {/* left vertical line */}
                {i === 0 && (
                  <Line
                    points={[xTopPoint, paddingTop, xTopPoint, firstPointVertical]}
                    stroke={strokeColor}
                    strokeWidth={1}
                  />
                )}

                <Label
                  x={paddingLeft + (Number(e.coords.x) / 2) - 10}
                  y={yPlaceTop}
                >
                  <Text fill={strokeColor} text={e.coords.x} padding={2} fontSize={fontSize} />
                </Label>
              </>
            )}

          </Group>
        );
      })}
    </Group>
  );

  return (
    <>
      <HandleCircles />
      <HandleLines />
    </>
  );
};

Handles.defaultProps = {
  modalHoles: {},
  holes: {},
  activeHole: null,
  isScaled: false,
  paddingTop: 0,
  paddingLeft: 0,
  paddingBottom: 0,
  fontSize: 0,
};

Handles.propTypes = {
  modalHoles: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  holes: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isOpenDrillingHoles: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  activeHole: PropTypes.number,
  isScaled: PropTypes.bool,
  paddingTop: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingBottom: PropTypes.number,
  fontSize: PropTypes.number,
};

export default Handles;
