import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { navigate } from 'hookrouter';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../Modal';
import Button from '../Button';
import DoorsActions from '../../redux/actions/doorsAndSections';

const WarningIncompleteDataModal = ({
  isOpen,
  className,
  onCloseModal,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { currentSystem } = useSelector(({ systems }) => systems);

  const {
    doors,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const {
    currentOrderId,
  } = useSelector(({ order }) => order);

  if (!isOpen) return null;

  const handleSubmit = () => {
    window.history.pushState({ path: currentOrderId ? `/order/${currentOrderId}/edit` : '/order' }, null);
    navigate(currentOrderId ? `/order/${currentOrderId}/edit` : '/order');
    onCloseModal();
  };

  const getFrameWithoutFastenersOrHoles = () => doors.find((door) => !door?.fasteningElement?.amount && !door?.pearHoles?.amount && !door?.holesForHandles?.amount)?.sequenceNumber;

  const handleCancel = () => {
    if (currentOrderId) {
      navigate(`/${currentSystem}/${currentOrderId}/edit`);
      dispatch(DoorsActions.setActiveDoor(getFrameWithoutFastenersOrHoles()));
      return;
    }

    dispatch(DoorsActions.toggleFrameConfigurationView('extended'));
    dispatch(DoorsActions.setActiveDoor(getFrameWithoutFastenersOrHoles()));
    onCloseModal();
  };

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
    >
      <div className="modal-order-icon-wrapper">
        <ReactSVG
          wrapper="div"
          className="modal-order-icon"
          src="/src/client/assets/icons/order-icon.svg"
        />
      </div>
      <h2 className="headings-h2">{t('warningIncompleteDataModal.title')}</h2>

      <div className="action-buttons">
        <div className="action-buttons-inner">
          <Button
            value={t('warningIncompleteDataModal.cancel')}
            type="link-button"
            onClick={handleCancel}
            isDisabled={false}
          />
          <Button
            value={t('warningIncompleteDataModal.submit')}
            type="rounded"
            onClick={handleSubmit}
            isDisabled={false}
          />
        </div>
      </div>
    </Modal>
  );
};

WarningIncompleteDataModal.defaultProps = {
  isOpen: false,
  className: 'action-modal warning-coords',
};

WarningIncompleteDataModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
};

export default WarningIncompleteDataModal;
