import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { navigate, getWorkingPath } from 'hookrouter';
import { withToastManager } from 'react-toast-notifications';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import 'core-js/features/array/flat-map';

import DoorsActions from '../../redux/actions/doorsAndSections';
import PriceListActions from '../../redux/actions/priceList';
import OrderActions from '../../redux/actions/order';
import MySavedOrdersActions from '../../redux/actions/mySavedOrders';
import FillingActions from '../../redux/actions/fillingMaterials';
import FasteningElementAction from '../../redux/actions/fasteningElement';

import AuthService from '../../services/authService';

import { ADSSystemTypesList } from '../../helpers/constants';
import useCurrency from '../../helpers/useCurrency';
import notificationPropTypes from '../../helpers/propTypes/notificationPropTypes';

import Button from '../Button';
import OrderTitleModal from '../OrderTitleModal';
import WarningInvalidCoordsModal from '../WarningInvalidCoordsModal';
import WarningIncompleteDataModal from '../WarningIncompleteDataModal';
import { numberWithSpaces } from '../../helpers/sanitizer';
import WarningHandleModal from '../WarningHandleModal';
import { changeHandleSide, saveWithoutChangeHandleSide } from '../FasteningElement';
import { checkIsSameCoords } from '../DrillingHolesForHandles';

export const canSaveAnotherSystemOrder = (doors, doorOpeningHeight, doorOpeningWidth, aluminiumColor) => {
  const doorsAmountWithoutSections = doors
    .filter((door) => !door.sections.length)?.length;

  const doorsFillingWithoutSections = doors
    .filter((door) => !door.sections.length && !_.isEmpty(door?.main?.filling?.material))
    .map((door) => door?.main?.filling);

  const sectionsFilling = _.flattenDeep(doors
    .filter((door) => !_.isEmpty(door?.sections))
    .map((door) => door.sections
      .filter((section) => !_.isEmpty(section.filling?.material))
      .map((s) => s?.filling)));

  let sectionsAmount = 0;
  doors
    .filter((door) => door?.main?.sectionsAmount?.value)
    .map((door) => {
      sectionsAmount += door?.main?.sectionsAmount?.value;
      return door;
    });

  return Boolean(doorsFillingWithoutSections.length === doorsAmountWithoutSections
    && sectionsFilling.length === sectionsAmount
    && doorOpeningHeight?.value && doorOpeningWidth?.value
    && aluminiumColor);
};

export const canSaveFrameFacadesOrder = (doors) => {
  const isDoorsWithEmptyField = () => doors.some((door) => (!door.frameOpeningHeight.value
    || !door.frameOpeningWidth.value
    || !door.frameAluminiumColor.value
    || !door.sameFramesAmount.value
    || !door.frameProfile.value
    || _.isEmpty(door.filling)));

  return Boolean(!isDoorsWithEmptyField() && doors.length > 0);
};

export const areDoorsAndSectionsWithoutFilling = (doors) => {
  const doorsWithoutFilling = doors
    .filter((door) => !door.sections.length && _.isEmpty(door?.main?.filling?.material));

  const sectionsWithoutFilling = doors
    .flatMap((door) => door.sections.filter((section) => _.isEmpty(section.filling?.material)));

  return Boolean(!_.isEmpty(doorsWithoutFilling) || !_.isEmpty(sectionsWithoutFilling));
};

const Footer = ({
  className,
  onSubmitFilling,
  onSubmitDrilling,
  onSubmitDrillingPear,
  onSubmitFasteningElement,
  toastManager,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';
  const state = useSelector(({ footer }) => ({ footer }));
  const { isFooterShown } = state.footer;
  const isOrderPage = getWorkingPath().includes('/order');
  const isAcceptedOrders = getWorkingPath().includes('/view');
  const pathArray = getWorkingPath().split('/');
  const isSystemPage = _.some(ADSSystemTypesList, (route) => route === pathArray[1]);
  const currentCurrency = useCurrency();

  const { showPrice } = useSelector(({ profile }) => profile);

  const {
    isLoading,
    isOrderAccepted,
    currentOrderId,
    shouldShowOrderPage,
    isOrderTitleModalOpen,
    specification: { totalPrice = '' },
    frameSpecification,
    title,
  } = useSelector(({ order }) => order);

  const {
    doors,
    main: {
      doorOpeningHeight = {},
      doorOpeningWidth = {},
      sideProfile: { value: sideProfile } = {},
      aluminiumColor = {},
      filling = {},
    } = {},
    mainFrame: {
      filling: frameFilling = {},
    } = {},
    activeDoor,
    activeSection,
    frameConfigurationView,
    frameInvalidCoords,
    isOpenWarningInvalidCoordsModal,
    isOpenWarningIncompleteDataModal,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const {
    holesForHandles,
    isOpenDrillingHoles,
  } = useSelector(({ drillingHoles }) => drillingHoles);

  const {
    isOpenDrillingPearHoles,
  } = useSelector(({ drillingPearHoles }) => drillingPearHoles);

  const {
    fasteningElement: fasteningElementParams,
    isFasteningElementOpen,
    isWarningHandleModalOpen,
  } = useSelector(({ fasteningElement }) => fasteningElement);

  const { currentSystem } = useSelector(({ systems }) => systems);

  const {
    isOpenFillingModal,
    activeTrigger,
    customers: {
      customersOption,
    },
    dsp: {
      isDspUVPrinting,
      dspOption,
      dspUvPrintType,
    },
    mirror: {
      mirrorType,
      isMirrorRearMatted,
      mirrorColor,
    },
    lacobel: {
      lacobelType,
      isLacobelRearMatted,
      lacobelColor,
    },
    glass: {
      glassType,
      isGlassOneColorPainted,
      isGlassTwoColorsPainted,
      glassColors,
    },
  } = useSelector(({ fillingMaterials }) => fillingMaterials);

  const [notificationId, setNotificationId] = useState('');

  const footerClassName = clsx('configurator-footer',
    (frameConfigurationView === 'simplified' && currentSystem === 'frame-facades') && 'simplified-footer', className);

  useEffect(() => {
    if (AuthService.isLoggedIn()) { return; }
    dispatch(PriceListActions.getPriceListRequest());
  }, []);

  useEffect(() => {
    if (!(currentOrderId && shouldShowOrderPage)) return;
    dispatch(OrderActions.toggleShouldShowOrderPage(false));
    navigate(`/order/${currentOrderId}/edit`);
  }, [shouldShowOrderPage]);

  useEffect(() => {
    if (!isOpenFillingModal) return;

    const calculateOrderFilling = () => {
      if (activeDoor && activeSection) return doors[activeDoor - 1]?.sections[activeSection - 1]?.filling;

      const isFrameFacades = currentSystem === 'frame-facades';
      if (!activeDoor) return isFrameFacades ? frameFilling[0] : filling;
      return isFrameFacades ? doors[activeDoor - 1]?.filling[0] : doors[activeDoor - 1]?.main?.filling;
    };
    const orderFilling = calculateOrderFilling();

    const fillingAction = {
      customers: () => dispatch(FillingActions.setCustom(orderFilling)),
      dsp: () => dispatch(FillingActions.setDsp(orderFilling)),
      mirror: () => dispatch(FillingActions.setMirror(orderFilling)),
      lacobel: () => dispatch(FillingActions.setLacobel(orderFilling)),
      glass: () => dispatch(FillingActions.setGlass(orderFilling)),
    }[orderFilling?.material];

    if (fillingAction) {
      fillingAction();
    }
  }, [isOpenFillingModal, activeDoor, activeSection, activeTrigger]);

  if (!isFooterShown) return null;

  const showToast = (message, appearance) => {
    const errorContent = <div className="toast-notification">{message}</div>;

    if (notificationId) toastManager.remove(notificationId);

    toastManager.add(errorContent, {
      appearance,
      autoDismiss: true,
    }, (id) => { setNotificationId(id); });
  };

  const checkEmptyField = () => doors.some((door) => (!door.frameOpeningHeight.value || !door.frameOpeningWidth.value || !door.frameAluminiumColor.value || !door.sameFramesAmount.value));

  const setInvalidField = () => {
    doors.forEach(({ frameOpeningHeight, frameOpeningWidth, frameAluminiumColor, sameFramesAmount, sequenceNumber }) => {
      if (!frameOpeningHeight.value) {
        dispatch(DoorsActions.setFrameOpeningHeight(sequenceNumber, {
          ...frameOpeningHeight,
          isInvalid: true,
        }));
      }

      if (!frameOpeningWidth.value) {
        dispatch(DoorsActions.setFrameOpeningWidth(sequenceNumber, {
          ...frameOpeningWidth,
          isInvalid: true,
        }));
      }

      if (!frameAluminiumColor.value) {
        dispatch(DoorsActions.setFrameAluminiumColor(sequenceNumber, {
          ...frameAluminiumColor,
          isInvalid: true,
        }));
      }

      if (!sameFramesAmount.value) {
        dispatch(DoorsActions.setSameFramesAmount(sequenceNumber, {
          ...sameFramesAmount,
          isInvalid: true,
        }));
      }
    });
  };

  const checkFrameWithoutFastenersOrHoles = () => doors.some((door) => !door?.fasteningElement?.amount && !door?.pearHoles?.amount && !door?.holesForHandles?.amount);

  const checkIsValidCoords = () => doors?.some((door) => (
    door?.pearHoles?.items?.some((item) => (door.frameProfile.value.replace('-N', '') === 'P-32' && item.coords.y > Number(door.frameOpeningHeight.value - 80))
      || item.coords.x > Number(door.frameOpeningWidth.value - 80))
    || door?.fasteningElement?.items?.some((item) => item.coords.y > Number(door.frameOpeningHeight.value - 85))
    || door?.holesForHandles?.items?.some((item) => ((door.holesForHandles.placementSide === 'right' || door.holesForHandles.placementSide === 'left')
      && item.coords.y > Number(door.frameOpeningHeight.value - 65))
      || ((door.holesForHandles.placementSide === 'top' || door.holesForHandles.placementSide === 'bottom')
      && item.coords.x > Number(door.frameOpeningWidth.value - 65)))
  ));

  const setInvalidCoords = () => {
    const fasteningElementIntend = 85;
    const holesForHandlesIntend = 65;
    const pearHolesIntend = 80;
    const invalidCoords = [];

    doors.forEach((door) => {
      const {
        sequenceNumber,
        groupName,
        frameProfile: { value: frameP },
        frameOpeningHeight: { value: frameH },
        frameOpeningWidth: { value: frameW },
        holesForHandles: holes,
        fasteningElement,
        pearHoles,
      } = door;

      const fasteningElementInvalidCoords = [];
      const holesForHandlesInvalidCoords = [];
      const pearHolesInvalidCoords = [];

      const pearHolesItemsToUpdate = pearHoles?.items?.map((item) => {
        if ((frameP?.replace('-N', '') === 'P-32' && item?.coords?.y > Number(frameH - pearHolesIntend))
          || item?.coords?.x > Number(frameW - pearHolesIntend)) {
          pearHolesInvalidCoords.push(item?.sequenceNumber);
          return ({ ...item, isInvalid: true });
        }

        return item;
      });

      const fasteningElementItemsToUpdate = fasteningElement?.items?.map((item) => {
        if (item?.coords?.y > Number(frameH - fasteningElementIntend)) {
          fasteningElementInvalidCoords.push(item?.sequenceNumber);
          return ({ ...item, isInvalid: true });
        }
        return item;
      });

      const holesForHandlesItemsToUpdate = holes?.items?.map((item) => {
        if (((holes?.placementSide === 'right' || holes?.placementSide === 'left')
            && item?.coords?.y > Number(frameH - holesForHandlesIntend))
          || ((holes?.placementSide === 'top' || holes?.placementSide === 'bottom')
            && item?.coords?.x > Number(frameW - holesForHandlesIntend))) {
          holesForHandlesInvalidCoords.push(item?.sequenceNumber);
          return ({ ...item, isInvalid: true });
        }

        return item;
      });

      if (fasteningElementInvalidCoords?.length > 0
        || holesForHandlesInvalidCoords?.length > 0
        || pearHolesInvalidCoords?.length > 0) {
        invalidCoords.push({
          doorNumber: sequenceNumber,
          groupName: groupName?.value,
          ...(fasteningElementInvalidCoords?.length > 0 && { fasteningElementInvalidCoords }),
          ...(holesForHandlesInvalidCoords?.length > 0 && { holesForHandlesInvalidCoords }),
          ...(pearHolesInvalidCoords?.length > 0 && { pearHolesInvalidCoords }),
        });
      }

      dispatch(DoorsActions.setHolesForHandles(sequenceNumber,
        { ...holes, items: holesForHandlesItemsToUpdate }));
      dispatch(DoorsActions.setFasteningElement(sequenceNumber,
        { ...fasteningElement, items: fasteningElementItemsToUpdate }));
      dispatch(DoorsActions.setPearHoles(sequenceNumber, { ...pearHoles, items: pearHolesItemsToUpdate }));
    });

    if (invalidCoords.length > 0) {
      dispatch(DoorsActions.setFrameInvalidCoords(invalidCoords));
      dispatch(DoorsActions.toggleWarningInvalidCoordsModal(true));
    }
  };

  const getErrorMessageAboutDoorsAndSectionsWithoutFilling = (doorsArray) => {
    const doorsWithoutFilling = doorsArray
      .filter((door) => !door.sections.length && _.isEmpty(door?.main?.filling?.material))
      .map((door) => door?.sequenceNumber).join(', ');

    const sectionsWithoutFilling = doorsArray
      .flatMap((door) => door.sections.filter((section) => _.isEmpty(section.filling?.material))
        .map((s) => `${door?.sequenceNumber}.${s?.sequenceNumber}`)).join(', ');

    if (doorsWithoutFilling && !sectionsWithoutFilling) {
      return t('errorMessages.doors-filling-is-missing', { doorsWithoutFilling });
    }

    if (!doorsWithoutFilling && sectionsWithoutFilling) {
      return t('errorMessages.sections-filling-is-missing', { sectionsWithoutFilling });
    }

    return t('errorMessages.doors-and-sections-filling-is-missing', { doorsWithoutFilling, sectionsWithoutFilling });
  };

  const canSaveOrder = (orderDoors, doorOpeningH, doorOpeningW, color, system) => {
    if (system === 'frame-facades') return canSaveFrameFacadesOrder(orderDoors);
    return canSaveAnotherSystemOrder(orderDoors, doorOpeningH, doorOpeningW, color);
  };

  const isSubmitFillingDisabled = () => {
    const isSlim = sideProfile?.value === 'Slim';

    if (!activeTrigger) return true;

    return (isSlim && activeTrigger === 'dsp')
      || (isSlim && activeTrigger === 'customers' && customersOption?.includes('dsp'))
      || !((activeTrigger === 'customers' && customersOption)
        || (activeTrigger === 'dsp' && dspOption && isDspUVPrinting && !_.isEmpty(dspUvPrintType))
        || (activeTrigger === 'dsp' && dspOption)
        || (activeTrigger === 'mirror' && !_.isEmpty(mirrorType))
        || (activeTrigger === 'lacobel' && !_.isEmpty(lacobelType))
        || (activeTrigger === 'glass' && !_.isEmpty(glassType))
      )
      || (activeTrigger === 'mirror' && isMirrorRearMatted && !mirrorColor)
      || (activeTrigger === 'lacobel' && isLacobelRearMatted && !lacobelColor)
      || (activeTrigger === 'glass' && isGlassOneColorPainted && !glassColors.length)
      || (activeTrigger === 'glass' && isGlassTwoColorsPainted && glassColors.length < 2);
  };

  const isSubmitFasteningElementDisabled = () => {
    const {
      type,
      amount,
      useCurtain,
      hingeType,
      drillingScheme,
      liftingMechanismType,
      standardServoDriveType,
      withBottomModule,
    } = fasteningElementParams;

    if (((type && type !== 'lifting-mechanism' && !useCurtain) && (!amount || !drillingScheme))
      || ((type && type !== 'lifting-mechanism' && useCurtain) && (!amount || !drillingScheme || !hingeType))
      || (type && type === 'lifting-mechanism' && !liftingMechanismType)
      || ((type && type === 'lifting-mechanism' && liftingMechanismType === 'aventos_hf_top') && (!amount || !withBottomModule || !drillingScheme))
      || ((type && type === 'lifting-mechanism' && liftingMechanismType === 'aventos_hl') && (!amount || !standardServoDriveType || !drillingScheme))
      || ((type && type === 'lifting-mechanism' && (liftingMechanismType === 'aventos_hk_top' || liftingMechanismType === 'aventos_hk_s')) && (!amount || !drillingScheme))) {
      return true;
    }
    return false;
  };

  const handleProceed = () => {
    if ((!AuthService.isLoggedIn()
      && canSaveOrder(doors, doorOpeningHeight, doorOpeningWidth, aluminiumColor.value, currentSystem))
      || (!AuthService.isPhoneNumberVerified()
      && canSaveOrder(doors, doorOpeningHeight, doorOpeningWidth, aluminiumColor.value, currentSystem))) {
      window.history.pushState({ path: '/order' }, null);
      navigate('/sign-in');
      return;
    }

    if (currentSystem === 'frame-facades' && frameConfigurationView === 'simplified' && checkEmptyField()) {
      setInvalidField();
      showToast(t('errorMessages.simplified-empty-field'), 'error');
      return;
    }

    if (currentSystem === 'frame-facades' && frameConfigurationView === 'simplified' && checkFrameWithoutFastenersOrHoles()) {
      return dispatch(DoorsActions.toggleWarningIncompleteDataModal(true));
    }

    if (currentSystem === 'frame-facades' && checkIsValidCoords()) {
      setInvalidCoords();
      return;
    }

    if (currentSystem === 'frame-facades' && !canSaveOrder(doors, doorOpeningHeight, doorOpeningWidth, aluminiumColor.value, currentSystem)) {
      return;
    }

    if (currentSystem !== 'frame-facades' && !(doorOpeningHeight?.value && doorOpeningWidth?.value)) {
      dispatch(DoorsActions.hightlightDoorOpeningInputs(labelKey));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (currentSystem !== 'frame-facades' && !canSaveOrder(doors, doorOpeningHeight, doorOpeningWidth, aluminiumColor.value, currentSystem)
      && isSystemPage && areDoorsAndSectionsWithoutFilling(doors)) {
      if (doorOpeningHeight?.value && doorOpeningWidth?.value) {
        showToast(getErrorMessageAboutDoorsAndSectionsWithoutFilling(doors), 'error');
      }
      return;
    }

    window.history.pushState({ path: currentOrderId ? `/order/${currentOrderId}/edit` : '/order' }, null);
    navigate(currentOrderId ? `/order/${currentOrderId}/edit` : '/order');
  };


  const onSaveOrder = () => {
    dispatch(OrderActions.saveOrderRequest());
    dispatch(MySavedOrdersActions.getMySavedOrdersRequest());
    setTimeout(() => {
      navigate('/saved-orders');
      window.location.reload();
    }, 4000);
  };


  const handleSaveButtonClick = () => {
    if (currentOrderId) {
      onSaveOrder();
      return;
    }
    dispatch(OrderActions.toggleOrderTitleModal(true));
  };

  const isSubmitDrilling = () => {
    if (checkIsSameCoords(holesForHandles?.items)) return showToast(t('errorMessages.same-coords'), 'error');
    return onSubmitDrilling();
  };

  const getOnSubmitFunc = () => (isOpenFillingModal && onSubmitFilling)
    || (isOpenDrillingHoles && isSubmitDrilling)
    || (isFasteningElementOpen && onSubmitFasteningElement)
    || (isOpenDrillingPearHoles && onSubmitDrillingPear);

  const getOnDisableButton = () => (isOpenFillingModal && isSubmitFillingDisabled()) || (isFasteningElementOpen && isSubmitFasteningElementDisabled());

  const renderFrameOrderPrice = () => {
    const frameTotalPrice = frameSpecification?.totalPrice || 0;

    return (
      <div className="configurator-footer--frame-price">
        {Boolean(!isOpenFillingModal && !isOpenDrillingHoles && !isFasteningElementOpen && !isOpenDrillingPearHoles && frameTotalPrice
          && (currentOrderId || showPrice || !AuthService.isLoggedIn()))
        && (
          <>
            <div className="configurator-footer--frame-price-title">
              {t('stickyMenu.footer.project-cost')}
            </div>
            <div className="configurator-footer--frame-price-wrapper">
              <ReactSVG
                className={clsx('configurator-footer--frame-price-icon')}
                wrapper="div"
                src="/src/client/assets/icons/currency-uah.svg"
              />
              <span className="configurator-footer--frame-price-value">
                {numberWithSpaces(frameTotalPrice)}
              </span>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderOrderPrice = () => (
    <div className="configurator-footer--price">
      {!(doorOpeningHeight?.value && doorOpeningWidth?.value && (currentOrderId || showPrice || !AuthService.isLoggedIn())
        && canSaveOrder(doors, doorOpeningHeight, doorOpeningWidth, aluminiumColor.value, currentSystem) && !isOpenFillingModal)
        || (
          <span className="configurator-footer--cost-value">
            {numberWithSpaces(totalPrice) || 0}
            &nbsp;
            {currentCurrency}
          </span>
        )}
    </div>
  );

  return (
    <>
      <div className={footerClassName}>
        <div className="configurator-footer--inner">
          {currentSystem === 'frame-facades'
            ? renderFrameOrderPrice()
            : renderOrderPrice()}
          {!isAcceptedOrders && isOrderPage && (
            <Button
              value={t('stickyMenu.footer.save')}
              onClick={handleSaveButtonClick}
              isDisabled={window.location.href.endsWith('/view') || isOrderAccepted}
              isProcessing={isLoading}
              type="button-blue"
            />
          )}
          {!isAcceptedOrders && !isOrderPage && (isOpenFillingModal || isOpenDrillingHoles || isFasteningElementOpen || isOpenDrillingPearHoles ? (
            <Button
              value={t('fillingMaterialsModal.choose')}
              type={getOnDisableButton() ? 'button-light-blue' : 'button-blue'}
              onClick={getOnSubmitFunc()}
              isDisabled={getOnDisableButton() || false}
              isProcessing={isLoading}
            />
          ) : (
            <Button
              value={t('stickyMenu.footer.process')}
              type={canSaveOrder(doors, doorOpeningHeight, doorOpeningWidth, aluminiumColor.value, currentSystem)
                ? 'button-blue'
                : 'button-light-blue'}
              onClick={handleProceed}
              className={
                canSaveOrder(doors, doorOpeningHeight, doorOpeningWidth, aluminiumColor.value, currentSystem)
                  ? ''
                  : 'disabled'
              }
              isProcessing={isLoading}
            />
          ))}
        </div>

        <OrderTitleModal
          isOpen={isOrderTitleModalOpen}
          onCloseModal={() => dispatch(OrderActions.toggleOrderTitleModal(false))}
          onSubmit={onSaveOrder}
          title={title}
          className="action-modal order-title"
        />
      </div>
      <WarningInvalidCoordsModal
        isOpen={isOpenWarningInvalidCoordsModal}
        invalidCoords={frameInvalidCoords}
        onCloseModal={() => {
          dispatch(DoorsActions.toggleWarningInvalidCoordsModal(false));
          dispatch(DoorsActions.setFrameInvalidCoords([]));
        }}
      />
      <WarningIncompleteDataModal
        isOpen={isOpenWarningIncompleteDataModal}
        onCloseModal={() => {
          dispatch(DoorsActions.toggleWarningIncompleteDataModal(false));
        }}
      />
      <WarningHandleModal
        isOpen={isWarningHandleModalOpen}
        onCloseModal={() => dispatch(FasteningElementAction.toggleWarningHandleModal(false))}
        onChangeHandleSide={() => changeHandleSide(activeDoor, fasteningElementParams, dispatch)}
        onSaveWithoutHandle={() => saveWithoutChangeHandleSide(activeDoor, fasteningElementParams, dispatch)}
      />
    </>
  );
};

Footer.propTypes = {
  toastManager: PropTypes.shape(notificationPropTypes).isRequired,
  className: PropTypes.string,
  onSubmitFilling: PropTypes.func,
  onSubmitDrilling: PropTypes.func,
  onSubmitDrillingPear: PropTypes.func,
  onSubmitFasteningElement: PropTypes.func,
};

Footer.defaultProps = {
  className: null,
  onSubmitFilling: () => { },
  onSubmitDrilling: () => { },
  onSubmitDrillingPear: () => { },
  onSubmitFasteningElement: () => { },
};

export default withToastManager(Footer);
