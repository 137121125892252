import { createActions } from 'reduxsauce';
import { navigate } from 'hookrouter';
import API from '../../api';
import AuthService from '../../services/authService';

const { Types, Creators } = createActions({
  loginSuccess: ['token'],
  loginFailure: ['errorMessage'],
  loginRequest: () => async (dispatch, getState) => {
    try {
      const state = getState();
      const res = await API.user.signIn(state.signinForm.phone.value, state.signinForm.password.value);
      const { ok, problem, data } = res || {};
      const error = data?.error || {};
      const errorMessage = !ok
        ? (error?.message || data?.message || problem)
        : '';
      const token = res?.data?.jwtToken || null;
      if (token) {
        AuthService.setToken(res.data.jwtToken);
        navigate(state.systems.currentSystem ? `/${state.systems.currentSystem}/edit` : '/systems');
      }

      if (errorMessage) {
        dispatch(Creators.loginFailure(errorMessage));
        return res;
      }

      dispatch(Creators.loginSuccess(token));
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return res;
    } catch (error) {
      dispatch(Creators.loginFailure(error.message || error.reason));
    }
  },
  logout: () => {
    AuthService.logout();
    API.user.signOut();
    return { type: 'LOGOUT' };
  },
});

export const AuthTypes = Types;
export default Creators;
