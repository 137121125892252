import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

import Collapsible from 'react-collapsible';
import AuthService from '../../../services/authService';

import DoorsActions from '../../../redux/actions/doorsAndSections';
import FillingActions from '../../../redux/actions/fillingMaterials';
import OrderActions from '../../../redux/actions/order';

import { blockInvalidChar, numberWithSpaces, removeLeadingZero } from '../../../helpers/sanitizer';
import { getFillingImagePath, getFillingDescription } from '../../../helpers/fillingHelper';
import { dspListWithWhiteMargins } from '../../../helpers/visualisationHelper';
import constantsForFrameProfiles from '../../../helpers/constantsForFrameProfiles';

import SetFrameParamsModal from '../SetFrameParamsModal';
import Input from '../../Input';
import Dropdown from '../../Dropdown';
import StrengthProfileModal from '../../StrengthProfileModal';
import TransparentFillingWarning from '../../TransparentFillingWarning';
import Tooltip from '../../Tooltip';
import { liftingMechanismSizes } from '../../../helpers/frameProfilesOptions';
import { validateAventosHF } from '../../../helpers/frameHelper';
import { isColorSideProfileAvailable } from '../../../helpers/priceHelper.mjs';
import WarningFrameSizeModal from '../../WarningFrameSizeModal';
import { frameProfileParameters } from '../../../../server/helpers/priceHelperOptions.mjs';
import { materialType } from '../../../helpers/options';
import { canUseHorizontalTexture } from '../../../helpers/validation';


export const renderViewSwitcher = (view, onClick) => (
  <div className="view-switcher">
    <Tooltip
      content="tooltips.table-view"
      direction="bottom"
      hasTranslation
    >
      <button
        className={clsx('switcher', 'simplified', view === 'simplified' && 'active')}
        type="button"
        onClick={() => onClick('simplified')}
      >
        <ReactSVG
          wrapper="span"
          src={view === 'simplified'
            ? '/src/client/assets/icons/simplified-view-active.svg'
            : '/src/client/assets/icons/simplified-view.svg'}
        />
      </button>
    </Tooltip>


    <Tooltip
      content="tooltips.detailed-view"
      direction="bottom"
      hasTranslation
    >
      <button
        className={clsx('switcher', 'extended', view === 'extended' && 'active')}
        type="button"
        onClick={() => onClick('extended')}
      >
        <ReactSVG
          wrapper="span"
          src={view === 'extended'
            ? '/src/client/assets/icons/extended-view-active.svg'
            : '/src/client/assets/icons/extended-view.svg'}
        />
      </button>
    </Tooltip>
  </div>
);


const FrameFacadesList = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';

  const prevFrameNumberRef = useRef();
  const inputFrameNameRefs = useRef([]);
  const prevTransparentFillingModalRef = useRef();

  const { priceList: prices } = useSelector(({ priceList }) => priceList);

  const {
    activeDoor,
    doors,
    frameConfigurationView,
    frameCreatingParams,
    isOpenFrameParamsModal,
    isOpenDeleteFrameModal,
    isOpenStrengthProfileModal,
    isOpenWarningFrameSizeModal,
    isOpenTransparentFillingWarning,
    isOpenChangeFrameFillingModal,
    frameGuaranteeType,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const {
    aluminiumColors,
    frameProfiles,
    filling: fillingMaterials,
  } = useSelector(({ config }) => config);

  const { frameSpecification, currentOrderId } = useSelector(({ order }) => order);

  const { showPrice } = useSelector(({ profile }) => profile);

  const [isOpenTransparentFillingWarningModal, setIsOpenTransparentFillingWarningModal] = useState(false);

  const [hoveredField, setHoveredField] = useState('');
  const [currentFrameProfileSizes, setCurrentFrameProfileSizes] = useState(null);
  const [currentLiftingMechanismSizes, setCurrentLiftingMechanismSizes] = useState({});

  const [currentFrameNumber, setCurrentFrameNumber] = useState(null);
  const [currentFrameName, setCurrentFrameName] = useState('');
  const [currentFrameHeight, setCurrentFrameHeight] = useState('');
  const [currentFrameWidth, setCurrentFrameWidth] = useState('');
  const [currentFrameProfile, setCurrentFrameProfile] = useState('');
  const [currentFrameAluminiumColor, setCurrentFrameAluminiumColor] = useState('');
  const [currentFrameAmount, setCurrentFrameAmount] = useState(0);
  const [currentFrameFasteningElement, setCurrentFrameFasteningElement] = useState({});
  const [currentFrameFasteningElementForChecking, setCurrentFrameFasteningElementForChecking] = useState({});
  const [currentFrameFilling, setCurrentFrameFilling] = useState({});
  const [currentFrameFillingTexture, setCurrentFrameFillingTexture] = useState('');
  const [reinforcingProfileAgreement, setReinforcingProfileAgreement] = useState({});
  const [heightLimitExceededAgreement, setHeightLimitExceededAgreement] = useState({});
  const [widthLimitExceededAgreement, setWidthLimitExceededAgreement] = useState({});

  const [frameNumberWithLimitExceeded, setFrameNumberWithLimitExceeded] = useState(null);
  const [prevFrameHeight, setPrevFrameHeight] = useState(0);
  const [prevFrameWidth, setPrevFrameWidth] = useState(0);
  const [prevReinforcingProfileAmount, setPrevReinforcingProfileAmount] = useState(0);
  const [prevFrameFillingTexture, setPrevFrameFillingTexture] = useState('');

  const [isDesktopView, setIsDesktopView] = useState(false);
  const [activeTrigger, setActiveTrigger] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window || {};
      setIsDesktopView(innerWidth >= 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(OrderActions.calculateFrameOrderRequest());
  }, [doors, frameConfigurationView]);

  useEffect(() => {
    prevFrameNumberRef.current = currentFrameNumber;
    prevTransparentFillingModalRef.current = isOpenTransparentFillingWarningModal;
  });

  const prevFrameNumber = prevFrameNumberRef.current;
  const prevTransparentFillingModal = prevTransparentFillingModalRef.current;

  useEffect(() => {
    setIsOpenTransparentFillingWarningModal(isOpenTransparentFillingWarning);
  }, [isOpenTransparentFillingWarning]);

  useEffect(() => {
    if (prevFrameNumber !== currentFrameNumber) {
      return;
    }
    onMouseLeave();
  }, [currentFrameProfile, currentFrameNumber, currentFrameAluminiumColor?.value]);

  useEffect(() => {
    dispatch(DoorsActions.setFrameOpeningHeight(currentFrameNumber, {
      value: currentFrameHeight?.value,
      ...(currentFrameHeight?.isInvalid ? { isInvalid: true } : {}),
    }));
  }, [currentFrameHeight?.value]);

  useEffect(() => {
    dispatch(DoorsActions.setFrameOpeningWidth(currentFrameNumber, {
      value: currentFrameWidth?.value,
      ...(currentFrameWidth?.isInvalid ? { isInvalid: true } : {}),
    }));
  }, [currentFrameWidth?.value]);

  useEffect(() => {
    dispatch(DoorsActions.setFrameGroupName(currentFrameNumber, currentFrameName));
  }, [currentFrameName]);

  useEffect(() => {
    dispatch(DoorsActions.setSameFramesAmount(currentFrameNumber, {
      value: Number(currentFrameAmount?.value),
      ...(currentFrameAmount?.isInvalid ? { isInvalid: true } : {}),
    }));
  }, [currentFrameAmount?.value]);

  useEffect(() => {
    const sanitizedCurrentFrameProfile = currentFrameProfile?.replace('-N', '');
    const sizes = constantsForFrameProfiles.find((p) => p.profile === sanitizedCurrentFrameProfile);
    setCurrentFrameProfileSizes(sizes);

    dispatch(DoorsActions.setFrameProfileHeight(currentFrameNumber, sizes?.profileHeight));
    dispatch(DoorsActions.setFrameProfileWidth(currentFrameNumber, sizes?.profileWidth));
    dispatch(DoorsActions.setFrameConnectingCorner(currentFrameNumber, sizes?.connectingCorner));
    dispatch(DoorsActions.setFrameProfile(currentFrameNumber, currentFrameProfile));
  }, [currentFrameProfile]);

  useEffect(() => {
    dispatch(DoorsActions.setFrameAluminiumColor(currentFrameNumber, {
      value: currentFrameAluminiumColor?.value,
      ...(currentFrameAluminiumColor?.isInvalid ? { isInvalid: true } : {}),
    }));
  }, [currentFrameAluminiumColor?.value]);

  useEffect(() => {
    const { type, liftingMechanismType } = currentFrameFasteningElement;
    if (type === 'lifting-mechanism') {
      setCurrentLiftingMechanismSizes(liftingMechanismSizes[liftingMechanismType]);
    }
  }, [currentFrameFasteningElement]);

  useEffect(() => removeIsInvalidField(), [frameConfigurationView]);

  const modalClassName = (isOpenDeleteFrameModal && 'action-modal delete-frame') || 'action-modal frame-params';

  const invalidChars = ['e', 'E', '+', '-', '.', ','];

  const headings = [{
    label: '№',
  }, {
    label: t('frameFacadesList.group-name'),
  }, {
    label: t('frameFacadesList.profile'),
  }, {
    label: t('frameFacadesList.height'),
  }, {
    label: t('frameFacadesList.width'),
  }, {
    label: t('frameFacadesList.amount'),
  }, {
    label: t('frameFacadesList.color'),
  }, {
    label: t('frameFacadesList.material'),
  }, ...((currentOrderId || showPrice || !AuthService.isLoggedIn())
    ? [{ label: t('frameFacadesList.price') }]
    : []
  ), {
    label: t('frameFacadesList.add-button'),
    withButton: true,
  }];


  const removeIsInvalidField = () => {
    doors.forEach(({
      frameOpeningHeight, frameOpeningWidth, frameAluminiumColor, sameFramesAmount, sequenceNumber }) => {
      dispatch(DoorsActions.setFrameOpeningHeight(sequenceNumber, {
        ..._.omit(frameOpeningHeight, ['isInvalid']),
      }));

      dispatch(DoorsActions.setFrameOpeningWidth(sequenceNumber, {
        ..._.omit(frameOpeningWidth, ['isInvalid']),
      }));

      dispatch(DoorsActions.setFrameAluminiumColor(sequenceNumber, {
        ..._.omit(frameAluminiumColor, ['isInvalid']),
      }));

      dispatch(DoorsActions.setSameFramesAmount(sequenceNumber, {
        ..._.omit(sameFramesAmount, ['isInvalid']),
      }));
    });
  };

  const handleUpdateCurrentFrameHeight = ({ target: { value } }, frameNumber) => {
    value = removeLeadingZero(value);

    if (value < 0 || (currentFrameFasteningElement?.type === 'lifting-mechanism' && value > currentLiftingMechanismSizes?.maxHeight)) return;

    setCurrentFrameHeight({
      value,
      frameNumber,
    });
  };

  const handleUpdateCurrentFrameWidth = ({ target: { value } }, frameNumber) => {
    value = removeLeadingZero(value);

    if (value < 0 || (currentFrameFasteningElement?.type === 'lifting-mechanism' && value > currentLiftingMechanismSizes?.maxWidth)) return;

    setCurrentFrameWidth({
      value,
      frameNumber,
    });
  };

  const handleUpdateCurrentFrameAmount = ({ target: { value } }) => {
    if (value < 0) return;

    setCurrentFrameAmount({ value });
  };

  const sanitizeAndSaveFrameHeight = (value, frameNumber) => {
    const {
      minFrameOpeningHeight,
      maxFrameOpeningHeightWithoutReinforcingProfile,
      maxFrameOpeningHeightWithReinforcingProfile,
    } = currentFrameProfileSizes;

    const { agreementNotToUseReinforcingProfile, needToUseReinforcingProfile } = reinforcingProfileAgreement;
    const { agreement: heightAgreement, heightLimitExceeded } = heightLimitExceededAgreement;
    const { agreement: widthAgreement, widthLimitExceeded } = widthLimitExceededAgreement;

    const { minHeight, maxHeight } = currentLiftingMechanismSizes;

    if (!value) return;

    if (currentFrameFasteningElement?.type === 'lifting-mechanism' && value < minHeight) {
      dispatch(DoorsActions.setFrameOpeningHeight(frameNumber, { value: minHeight }));
      return;
    }

    if (currentFrameFasteningElement?.type === 'lifting-mechanism' && value > maxHeight) {
      dispatch(DoorsActions.setFrameOpeningHeight(frameNumber, { value: maxHeight }));
      return;
    }

    if (value < minFrameOpeningHeight) {
      if (widthLimitExceeded && !widthAgreement) {
        dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumber, {
          widthLimitExceeded: true,
          agreement: true,
        }));
      }
      dispatch(DoorsActions.setFrameOpeningHeight(frameNumber, { value: minFrameOpeningHeight }));
      dispatch(DoorsActions.setMillingForReinforcingProfile(frameNumber, 0));
      dispatch(DoorsActions.setStrainerForReinforcingProfile(frameNumber, 0));
      dispatch(DoorsActions.setReinforcingProfileAmount(frameNumber, 0));
      dispatch(DoorsActions.updateReinforcingProfileItems(frameNumber, []));
      dispatch(DoorsActions.setGuaranteeAgreement(frameNumber, {
        needToUseReinforcingProfile: false,
        agreementNotToUseReinforcingProfile: false,
      }));
      dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumber, {
        heightLimitExceeded: false,
        agreement: false,
      }));
      setPrevFrameHeight(minFrameOpeningHeight);
      setPrevReinforcingProfileAmount(0);
      return;
    }


    if (value > maxFrameOpeningHeightWithReinforcingProfile) {
      if (!agreementNotToUseReinforcingProfile && !widthAgreement && !heightAgreement) {
        if (!doors[currentFrameNumber - 1]?.reinforcingProfile?.amount) {
          dispatch(DoorsActions.setMillingForReinforcingProfile(frameNumber, 2));
          dispatch(DoorsActions.setStrainerForReinforcingProfile(frameNumber, 1));
          dispatch(DoorsActions.setReinforcingProfileAmount(frameNumber, 1));
          dispatch(DoorsActions.updateReinforcingProfileItems(frameNumber, [{
            sequenceNumber: 1,
            distance: _.round(Number(value) / 2),
          }]));
        }
        dispatch(DoorsActions.setGuaranteeAgreement(frameNumber, {
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: false,
        }));
        dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumber, {
          heightLimitExceeded: true,
          agreement: false,
        }));
        setFrameNumberWithLimitExceeded(frameNumber);
        dispatch(DoorsActions.setFrameGuaranteeType('height'));
        dispatch(DoorsActions.toggleWarningFrameSizeModal(true));
      }

      if (!needToUseReinforcingProfile && !heightLimitExceeded && widthAgreement) {
        dispatch(DoorsActions.setMillingForReinforcingProfile(frameNumber, 2));
        dispatch(DoorsActions.setStrainerForReinforcingProfile(frameNumber, 1));
        dispatch(DoorsActions.setReinforcingProfileAmount(frameNumber, 1));
        dispatch(DoorsActions.updateReinforcingProfileItems(frameNumber, [{
          sequenceNumber: 1,
          distance: _.round(Number(value) / 2),
        }]));
        dispatch(DoorsActions.setGuaranteeAgreement(frameNumber, {
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: false,
        }));
        dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumber, {
          heightLimitExceeded: true,
          agreement: false,
        }));
      }

      if ((agreementNotToUseReinforcingProfile || widthAgreement) && !heightAgreement) {
        dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumber, {
          heightLimitExceeded: true,
          agreement: false,
        }));
      }

      dispatch(DoorsActions.setFrameOpeningHeight(frameNumber, { value }));
      return;
    }


    if (value > maxFrameOpeningHeightWithoutReinforcingProfile) {
      if (!doors[currentFrameNumber - 1]?.reinforcingProfile?.amount
      && !agreementNotToUseReinforcingProfile && !heightAgreement) {
        dispatch(DoorsActions.setMillingForReinforcingProfile(frameNumber, 2));
        dispatch(DoorsActions.setStrainerForReinforcingProfile(frameNumber, 1));
        dispatch(DoorsActions.setReinforcingProfileAmount(frameNumber, 1));
        dispatch(DoorsActions.updateReinforcingProfileItems(frameNumber, [{
          sequenceNumber: 1,
          distance: _.round(Number(value) / 2),
        }]));
        dispatch(DoorsActions.setGuaranteeAgreement(frameNumber, {
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: false,
        }));
        setPrevFrameHeight(value);
        setPrevReinforcingProfileAmount(1);
      }

      if (!doors[currentFrameNumber - 1]?.reinforcingProfile?.amount && heightAgreement && !widthAgreement) {
        dispatch(DoorsActions.setGuaranteeAgreement(frameNumber, {
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: true,
        }));
        dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumber, {
          heightLimitExceeded: false,
          agreement: false,
        }));
      }

      if (doors[currentFrameNumber - 1]?.reinforcingProfile?.amount) {
        if (doors[currentFrameNumber - 1]?.reinforcingProfile?.items.some(({ distance }) => Number(distance) > Number(value))) {
          dispatch(DoorsActions.setMillingForReinforcingProfile(frameNumber, 2));
          dispatch(DoorsActions.setStrainerForReinforcingProfile(frameNumber, 1));
          dispatch(DoorsActions.setReinforcingProfileAmount(frameNumber, 1));
          dispatch(DoorsActions.updateReinforcingProfileItems(frameNumber, [{
            sequenceNumber: 1,
            distance: _.round(Number(value) / 2),
          }]));
          setPrevFrameHeight(value);
          setPrevReinforcingProfileAmount(1);
        }
      }

      if (doors[currentFrameNumber - 1]?.reinforcingProfile?.amount && !agreementNotToUseReinforcingProfile && (!widthAgreement && widthLimitExceeded) && heightAgreement) {
        dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumber, {
          widthLimitExceeded: true,
          agreement: true,
        }));
      }

      dispatch(DoorsActions.setFrameOpeningHeight(frameNumber, { value }));
    }


    if (value >= minFrameOpeningHeight
      && value <= maxFrameOpeningHeightWithoutReinforcingProfile) {
      if (widthLimitExceeded && (agreementNotToUseReinforcingProfile || heightAgreement)) {
        dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumber, {
          widthLimitExceeded: true,
          agreement: true,
        }));
      }

      dispatch(DoorsActions.setMillingForReinforcingProfile(frameNumber, 0));
      dispatch(DoorsActions.setStrainerForReinforcingProfile(frameNumber, 0));
      dispatch(DoorsActions.setReinforcingProfileAmount(frameNumber, 0));
      dispatch(DoorsActions.updateReinforcingProfileItems(frameNumber, []));
      dispatch(DoorsActions.setGuaranteeAgreement(frameNumber, {
        needToUseReinforcingProfile: false,
        agreementNotToUseReinforcingProfile: false,
      }));
      setPrevFrameHeight(value);
      setPrevReinforcingProfileAmount(0);
      dispatch(DoorsActions.setFrameOpeningHeight(frameNumber, { value }));
    }

    if (value >= minFrameOpeningHeight
      && value <= maxFrameOpeningHeightWithReinforcingProfile) {
      dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumber, {
        heightLimitExceeded: false,
        agreement: false,
      }));
      setPrevFrameHeight(value);
    }
  };

  const sanitizeAndSaveFrameWidth = (value, frameNumber) => {
    const { minFrameOpeningWidth, maxFrameOpeningWidth } = currentFrameProfileSizes;
    const { minWidth, maxWidth } = currentLiftingMechanismSizes;
    const { agreementNotToUseReinforcingProfile, needToUseReinforcingProfile } = reinforcingProfileAgreement;
    const { agreement: heightAgreement, heightLimitExceeded } = heightLimitExceededAgreement;
    const { agreement: widthAgreement } = widthLimitExceededAgreement;

    if (!value) return;

    if (currentFrameFasteningElement?.type === 'lifting-mechanism' && value < minWidth) {
      dispatch(DoorsActions.setFrameOpeningWidth(frameNumber, { value: minWidth }));
      dispatch(DoorsActions.setFasteningElement(frameNumber,
        { ...currentFrameFasteningElement,
          items: validateAventosHF(minWidth, prevFrameWidth, currentFrameFasteningElement) }));
      return;
    }

    if (currentFrameFasteningElement?.type === 'lifting-mechanism' && value > maxWidth) {
      dispatch(DoorsActions.setFrameOpeningWidth(frameNumber, { value: maxWidth }));
      dispatch(DoorsActions.setFasteningElement(frameNumber,
        { ...currentFrameFasteningElement,
          items: validateAventosHF(maxWidth, prevFrameWidth, currentFrameFasteningElement) }));
      return;
    }


    if (value < minFrameOpeningWidth) {
      dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumber, {
        widthLimitExceeded: false,
        agreement: false,
      }));
      setPrevFrameWidth(minFrameOpeningWidth);
      dispatch(DoorsActions.setFrameOpeningWidth(frameNumber, { value: minFrameOpeningWidth }));

      if (widthAgreement && heightLimitExceeded) {
        dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumber, {
          heightLimitExceeded: true,
          agreement: true,
        }));
        return;
      }

      if (widthAgreement && (needToUseReinforcingProfile && !doors[currentFrameNumber - 1]?.reinforcingProfile?.amount)) {
        dispatch(DoorsActions.setGuaranteeAgreement(frameNumber, {
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: true,
        }));
        return;
      }

      return;
    }


    if (value > maxFrameOpeningWidth) {
      if (!agreementNotToUseReinforcingProfile && !heightAgreement && !widthAgreement) {
        dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumber, {
          widthLimitExceeded: true,
          agreement: false,
        }));
        setFrameNumberWithLimitExceeded(frameNumber);
        dispatch(DoorsActions.setFrameGuaranteeType('width'));
        dispatch(DoorsActions.toggleWarningFrameSizeModal(true));
      }

      if (agreementNotToUseReinforcingProfile || heightAgreement) {
        dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumber, {
          widthLimitExceeded: true,
          agreement: false,
        }));
      }
      dispatch(DoorsActions.setFrameOpeningWidth(frameNumber, { value }));
      return;
    }


    if (value >= minFrameOpeningWidth && value <= maxFrameOpeningWidth) {
      if (heightLimitExceeded && !heightAgreement && widthAgreement) {
        dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumber, {
          heightLimitExceeded: true,
          agreement: true,
        }));
      }

      if ((needToUseReinforcingProfile && !agreementNotToUseReinforcingProfile && !doors[currentFrameNumber - 1]?.reinforcingProfile?.amount) && !heightLimitExceeded && widthAgreement) {
        dispatch(DoorsActions.setGuaranteeAgreement(frameNumber, {
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: true,
        }));
      }
      dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumber, {
        widthLimitExceeded: false,
        agreement: false,
      }));
      setPrevFrameWidth(value);
      dispatch(DoorsActions.setFrameOpeningWidth(frameNumber, { value }));
    }


    setPrevFrameWidth(value);
    dispatch(DoorsActions.setFrameOpeningWidth(frameNumber, { value }));
    dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumber, {
      widthLimitExceeded: false,
      agreement: false,
    }));
    dispatch(DoorsActions.setFasteningElement(frameNumber,
      { ...currentFrameFasteningElement,
        items: validateAventosHF(value, prevFrameWidth, currentFrameFasteningElement) }));
  };

  const getAvailableColors = (profile) => {
    const frameProfileColors = frameProfiles
      .find(({ articleCode }) => articleCode === profile)?.colorsDependence
      .filter((color) => isColorSideProfileAvailable(color, profile, prices)) || [];

    return aluminiumColors
      .filter(({ articleCode }) => frameProfileColors.includes(articleCode))
      .map((c) => ({
        ...c,
        labelEn: `${c.labelEn} (${c.articleCode})`,
        labelPl: `${c.labelPl} (${c.articleCode})`,
        labelRu: `${c.labelRu} (${c.articleCode})`,
        labelUk: `${c.labelUk} (${c.articleCode})`,
      }));
  };

  const changeFrameMaterialTexture = (height, frameNumber) => {
    const fillingArticleCode = currentFrameFilling?.[materialType?.[currentFrameFilling?.material]];
    if (!currentFrameFilling?.material || !fillingMaterials.find((f) => f.articleCode === fillingArticleCode)?.isTexture) return;

    const frameProfileParameter = frameProfileParameters[currentFrameProfile];
    const doorFillingHeight = (currentFrameFasteningElementForChecking?.liftingMechanismType === 'aventos_hf_top' && currentFrameFasteningElementForChecking?.withBottomModule)
      ? (Number(height) / 2) - frameProfileParameter?.size
      : Number(height) - frameProfileParameter?.size;

    const updatedTexture = currentFrameFillingTexture === 'horizontal' && !canUseHorizontalTexture(fillingMaterials, fillingArticleCode, doorFillingHeight)
      ? 'vertical'
      : currentFrameFillingTexture;

    dispatch(DoorsActions.setFrameFillingTexture(frameNumber, updatedTexture));
  };

  const onCopyFrame = (frameNumber) => {
    const copiedFrame = doors[frameNumber - 1];

    dispatch(DoorsActions.addFrame({
      ...copiedFrame,
      sequenceNumber: doors.length + 1,
      groupName: { value: `${copiedFrame.groupName.value} ${t('frameFacadesList.copy')}` },
    }));
  };

  const onDeleteFrame = (frameNumber) => {
    dispatch(DoorsActions.deleteFrame(frameNumber));
  };

  const onChangeFrameFilling = (frameNumber, filling, texture) => {
    dispatch(DoorsActions.setFrameFilling(frameNumber, filling));
    dispatch(DoorsActions.setFrameFillingTexture(frameNumber, texture));
    dispatch(FillingActions.resetFillingMaterialModal());
  };

  const onSwitcherClick = (value) => {
    dispatch(DoorsActions.toggleFrameConfigurationView(value));
  };

  const onDeleteClick = (frameNumber, frameName) => {
    setCurrentFrameNumber(frameNumber);
    setCurrentFrameName(frameName);
    dispatch(DoorsActions.toggleDeleteFrameModal(true));
    dispatch(DoorsActions.toggleFrameParamsModal(true));
  };

  const onChangeFillingClick = (frameNumber) => {
    setCurrentFrameNumber(frameNumber);
    dispatch(DoorsActions.toggleChangeFrameFillingModal(true));
    dispatch(DoorsActions.toggleFrameParamsModal(true));
  };

  const onEditClick = (frameNumber) => {
    dispatch(DoorsActions.toggleFrameConfigurationView('extended'));
    dispatch(DoorsActions.setActiveDoor(frameNumber));
  };

  const onMouseEnter = (field, frameNumber, frameProfile, fasteningElement, frameWidth = 0) => {
    setHoveredField(field);
    setCurrentFrameNumber(frameNumber);
    setCurrentFrameProfile(frameProfile);
    setCurrentFrameFasteningElement(fasteningElement);
    setPrevFrameWidth(frameWidth);
  };

  const onMouseLeave = () => {
    setHoveredField('');
    setCurrentFrameNumber(null);
    setCurrentFrameFasteningElement({});
    setCurrentLiftingMechanismSizes({});
  };

  const onSubmitGuaranteeAgreement = () => {
    const itemToUpdate = {
      reinforcingProfile: {
        amount: 0,
        items: [],
      },
      millingForReinforcingProfile: { amount: 0 },
      strainerForReinforcingProfile: { amount: 0 },
      guaranteeAgreement: {
        needToUseReinforcingProfile: true,
        agreementNotToUseReinforcingProfile: true,
      },
    };
    dispatch(DoorsActions.updateFrameCreatingParams(itemToUpdate));
    dispatch(DoorsActions.toggleStrengthProfileModal(false));
    dispatch(DoorsActions.toggleFrameParamsModal(true));
  };

  const onSubmitFrameSizeGuaranteeAgreement = () => {
    if (frameNumberWithLimitExceeded) {
      if (frameGuaranteeType === 'height') {
        dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumberWithLimitExceeded, {
          ...doors[frameNumberWithLimitExceeded - 1]?.frameHeightGuaranteeAgreement,
          agreement: true,
        }));
      } else {
        dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumberWithLimitExceeded, {
          ...doors[frameNumberWithLimitExceeded - 1]?.frameWidthGuaranteeAgreement,
          agreement: true,
        }));
      }
      setFrameNumberWithLimitExceeded(null);
      dispatch(DoorsActions.toggleWarningFrameSizeModal(false));
      dispatch(DoorsActions.setFrameGuaranteeType(''));
    } else {
      if (frameCreatingParams?.frameHeightGuaranteeAgreement?.heightLimitExceeded) {
        dispatch(DoorsActions.setFrameCreatingStep({ step: 2, stepsAmount: 5 }));
      } else {
        dispatch(DoorsActions.setFrameCreatingStep({ step: 2, stepsAmount: 4 }));
      }

      dispatch(DoorsActions.toggleWarningFrameSizeModal(false));
      dispatch(DoorsActions.toggleFrameParamsModal(true));
    }
  };

  const onCloseWarningFrameSizeModal = () => {
    if (frameNumberWithLimitExceeded) {
      if (frameGuaranteeType === 'height') {
        const sanitizedCurrentFrameProfile = doors[frameNumberWithLimitExceeded - 1]?.frameProfile?.value?.replace('-N', '');
        const sizes = constantsForFrameProfiles.find((p) => p.profile === sanitizedCurrentFrameProfile);

        if (!prevReinforcingProfileAmount) {
          dispatch(DoorsActions.setMillingForReinforcingProfile(frameNumberWithLimitExceeded, 0));
          dispatch(DoorsActions.setStrainerForReinforcingProfile(frameNumberWithLimitExceeded, 0));
          dispatch(DoorsActions.setReinforcingProfileAmount(frameNumberWithLimitExceeded, 0));
          dispatch(DoorsActions.updateReinforcingProfileItems(frameNumberWithLimitExceeded, []));
        }

        if (prevFrameHeight < sizes?.maxFrameOpeningHeightWithoutReinforcingProfile) {
          dispatch(DoorsActions.setGuaranteeAgreement(frameNumberWithLimitExceeded, {
            needToUseReinforcingProfile: false,
            agreementNotToUseReinforcingProfile: false,
          }));
        }

        dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(frameNumberWithLimitExceeded, {
          heightLimitExceeded: false,
          agreement: false,
        }));
        dispatch(DoorsActions.setFrameFillingTexture(frameNumberWithLimitExceeded, prevFrameFillingTexture));
        dispatch(DoorsActions.setFrameOpeningHeight(frameNumberWithLimitExceeded, { value: prevFrameHeight }));
      } else {
        dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(frameNumberWithLimitExceeded, {
          widthLimitExceeded: false,
          agreement: false,
        }));
        dispatch(DoorsActions.setFrameOpeningWidth(frameNumberWithLimitExceeded, { value: prevFrameWidth }));
      }

      dispatch(DoorsActions.toggleWarningFrameSizeModal(false));
      dispatch(DoorsActions.setFrameGuaranteeType(''));
    } else {
      dispatch(DoorsActions.clearFrameCreatingParams());
      dispatch(DoorsActions.toggleWarningFrameSizeModal(false));
      dispatch(DoorsActions.toggleFrameParamsModal(true));
    }
  };

  const renderAddButton = (title, isHeaderBtn) => {
    const buttonClassName = clsx('add-button', isHeaderBtn ? 'table' : 'no-content');
    return (
      <button
        className={buttonClassName}
        type="button"
        onClick={() => dispatch(DoorsActions.toggleFrameParamsModal(true))}
      >
        <img
          className="add-button-icon"
          src="src/client/assets/icons/header/plus.svg"
          title={title}
          alt={title}
        />
        <span>{title}</span>
      </button>
    );
  };

  const renderActionsButton = (sequenceNumber, frameName) => (
    <div className="action-buttons-wrapper">
      <button
        type="button"
        className="circle"
        id="delete-btn"
        title={t('frameFacadesList.delete-group')}
        onClick={() => onDeleteClick(sequenceNumber, frameName)}
      >
        <ReactSVG
          wrapper="span"
          src="/src/client/assets/icons/trash-blue.svg"
        />
      </button>
      <button
        type="button"
        className="circle"
        title={t('frameFacadesList.copy-group')}
        onClick={() => onCopyFrame(sequenceNumber)}
      >
        <ReactSVG
          wrapper="span"
          src="/src/client/assets/icons/copy.svg"
        />
      </button>
      <button
        type="button"
        className="circle"
        title={t('frameFacadesList.edit-group')}
        onClick={() => onEditClick(sequenceNumber)}
      >
        <ReactSVG
          wrapper="span"
          src="/src/client/assets/icons/edit-pencil.svg"
        />
      </button>
    </div>
  );

  return (
    <div className="frame-container">
      <div className="frame-switcher">
        {isDesktopView && (
          <>
            <p className="frame-switcher-title">{t('frameFacadesList.title')}</p>
            <div className="frame-switcher-wrapper">
              {renderViewSwitcher(frameConfigurationView, onSwitcherClick)}
            </div>
          </>
        )}

        {!isDesktopView && doors.length > 0 && (
          <>
            <span className="mobile-head-title">{t('frameFacadesList.mobile-table')}</span>
            <button
              className="add-button frame-mobile-button"
              type="button"
              onClick={() => dispatch(DoorsActions.toggleFrameParamsModal(true))}
            >
              <img
                className="add-button-icon"
                src="src/client/assets/icons/header/plus.svg"
                title={t('frameFacadesList.no-content-button')}
                alt={t('frameFacadesList.no-content-button')}
              />
              <span>{t('frameFacadesList.no-content-button')}</span>
            </button>
          </>
        )}
      </div>


      {doors.length > 0 ? (
        <div className="frame-table-wrapper">
          <div className="frame-table">

            {isDesktopView && (
              <div className="frame-table-thead">
                <div className={
                  clsx('frame-table-tr',
                    (!currentOrderId && !showPrice && AuthService.isLoggedIn()) && 'without-price')
                }
                >
                  {
                    headings.map((item, i) => {
                      const tableThClassName = clsx(
                        'frame-table-th', (i === 3 || i === 4 || i === 5 || i === 8) && 'rtl',
                      );
                      return (
                        <div key={item.label} className={tableThClassName}>
                          {item?.withButton
                            ? renderAddButton(item.label, true)
                            : item.label}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            )}

            <div className="frame-table-tbody">
              {doors.map((door, doorIndex) => {
                const currentProfile = frameProfiles.find((p) => p.articleCode === door?.frameProfile?.value);
                const currentColor = aluminiumColors.find((c) => c.articleCode === door?.frameAluminiumColor?.value);
                const currentAvailableColors = getAvailableColors(currentProfile?.articleCode);
                const totalFramePrice = _.round(frameSpecification?.specificationByFrame?.find(
                  (s) => s.frameNumber === door?.sequenceNumber,
                )?.specification?.totalPrice, 2) || 0;
                const {
                  guaranteeAgreement,
                  frameHeightGuaranteeAgreement,
                  frameWidthGuaranteeAgreement,
                  fasteningElement,
                  fillingTexture: { value: fillingTexture },
                  filling,
                } = door;
                const isOpen = activeTrigger === doorIndex;

                const frameOpeningHeightLabel = currentFrameFasteningElement?.type === 'lifting-mechanism'
                  ? t(
                    'stickyMenu.mainDoorTab.from-min-to-max',
                    {
                      min: currentLiftingMechanismSizes?.minHeight,
                      max: currentLiftingMechanismSizes?.maxHeight,
                    },
                  )
                  : (t('stickyMenu.mainDoorTab.from-min',
                    {
                      min: currentFrameProfileSizes?.minFrameOpeningHeight,
                    }));

                const frameOpeningWidthLabel = currentFrameFasteningElement?.type === 'lifting-mechanism'
                  ? t(
                    'stickyMenu.mainDoorTab.from-min-to-max',
                    {
                      min: currentLiftingMechanismSizes?.minWidth,
                      max: currentLiftingMechanismSizes?.maxWidth,
                    },
                  )
                  : t(
                    'stickyMenu.mainDoorTab.from-min',
                    {
                      min: currentFrameProfileSizes?.minFrameOpeningWidth,
                    },
                  );

                return (
                  <div
                    key={door?.sequenceNumber}
                    className={
                      clsx('frame-table-tr', (!currentOrderId && !showPrice && AuthService.isLoggedIn())
                      && 'without-price')
                    }
                  >
                    {isDesktopView ? (
                      <>
                        <div className="frame-table-td">
                          <div className="param">
                            {door?.sequenceNumber}
                          </div>
                        </div>
                        <div className="frame-table-td edit">
                          <div
                            className={clsx('param',
                              !door?.groupName?.value && 'empty',
                              (currentFrameNumber === door?.sequenceNumber && hoveredField === 'name') && 'hovered')}
                            onMouseEnter={() => {
                              onMouseEnter('name', door?.sequenceNumber, door?.frameProfile?.value, fasteningElement);
                              setCurrentFrameName(door?.groupName?.value);
                            }}
                            onMouseLeave={() => {
                              onMouseLeave();
                              setCurrentFrameName('');
                              if (inputFrameNameRefs.current[door?.sequenceNumber]) {
                                inputFrameNameRefs.current[door?.sequenceNumber].blur();
                              }
                            }}
                          >
                            {(hoveredField === 'name' && currentFrameNumber === door?.sequenceNumber)
                            || !door?.groupName?.value
                              ? (
                                <Input
                                  inputRef={(el) => { inputFrameNameRefs.current[door?.sequenceNumber] = el; }}
                                  value={(currentFrameNumber === door?.sequenceNumber && currentFrameName) || ''}
                                  placeholder={t('frameFacadesList.specify-name')}
                                  onChange={(e) => setCurrentFrameName(e.target.value)}
                                />
                              )
                              : <span className="label">{door?.groupName?.value}</span>}
                          </div>
                        </div>

                        <div className="frame-table-td dropdown">
                          <div
                            className={clsx('param', 'dropdown',
                              (currentFrameNumber === door?.sequenceNumber && hoveredField === 'profile') && 'hovered')}
                            onMouseEnter={() => {
                              onMouseEnter(
                                'profile', door?.sequenceNumber, door?.frameProfile?.value, fasteningElement,
                              );
                              setCurrentFrameProfile(door?.frameProfile?.value);
                            }}
                            onMouseLeave={onMouseLeave}
                          >
                            {hoveredField === 'profile' && currentFrameNumber === door?.sequenceNumber
                              ? (
                                <Dropdown
                                  options={frameProfiles
                                    .filter((sp) => sp.colorsDependence.length)
                                    .map((sp) => ({
                                      value: sp.articleCode,
                                      label: sp.articleCode,
                                      image: sp.image,
                                    }))}
                                  onChange={(selectedOption) => {
                                    if (!selectedOption?.value) return;

                                    const profileAvailableColors = getAvailableColors(
                                      selectedOption?.value,
                                    ).map(({ articleCode }) => articleCode);

                                    if (currentColor?.articleCode
                                      && !profileAvailableColors.includes(currentColor.articleCode)) {
                                      dispatch(DoorsActions.setFrameAluminiumColor(currentFrameNumber, { value: '' }));
                                    }

                                    dispatch(DoorsActions.setOpeningSide(currentFrameNumber, 'left'));
                                    dispatch(DoorsActions.setHolesForHandles(currentFrameNumber, {
                                      amount: 0,
                                      items: [],
                                      placementSide: 'right',
                                    }));
                                    dispatch(DoorsActions.setFasteningElement(currentFrameNumber, {
                                      type: '',
                                      amount: 0,
                                      items: [],
                                      useCurtain: false,
                                      hingeType: '',
                                      drillingScheme: '',
                                      liftingMechanismType: '',
                                      liftingMechanismBottomType: '',
                                      standardServoDriveType: '',
                                      withBottomModule: false,
                                    }));
                                    dispatch(DoorsActions.setPearHoles(currentFrameNumber, {
                                      amount: 0,
                                      items: [],
                                      placementSide:
                                        selectedOption?.value?.replace('-N', '') === 'P-32' ? 'left-right' : 'top',
                                    }));
                                    dispatch(DoorsActions.setFrameOpeningHeight(currentFrameNumber, { value: '' }));
                                    dispatch(DoorsActions.setFrameOpeningWidth(currentFrameNumber, { value: '' }));
                                    dispatch(DoorsActions.setReinforcingProfileAmount(currentFrameNumber, 0));
                                    dispatch(DoorsActions.updateReinforcingProfileItems(currentFrameNumber, []));
                                    dispatch(DoorsActions.setMillingForReinforcingProfile(currentFrameNumber, 0));
                                    dispatch(DoorsActions.setStrainerForReinforcingProfile(currentFrameNumber, 0));
                                    dispatch(DoorsActions.setGuaranteeAgreement(currentFrameNumber, {
                                      needToUseReinforcingProfile: false,
                                      agreementNotToUseReinforcingProfile: false,
                                    }));
                                    setCurrentFrameProfile(selectedOption?.value);
                                  }}
                                  value={
                                    frameProfiles.length && currentFrameProfile
                                      ? frameProfiles.find((item) => item.articleCode === currentFrameProfile)
                                      : null
                                  }
                                />
                              )
                              : (
                                <>
                                  <div className="image">
                                    <img
                                      alt="img"
                                      src={currentProfile?.image}
                                    />
                                  </div>
                                  <span className="label">{door?.frameProfile?.value}</span>
                                </>
                              )}
                          </div>
                        </div>

                        <div className="frame-table-td rtl edit">
                          <div
                            className={clsx('param', 'rtl',
                              !door?.frameOpeningHeight?.value && 'empty',
                              (currentFrameNumber === door?.sequenceNumber && hoveredField === 'height') && 'hovered',
                              door?.frameOpeningHeight?.isInvalid && 'invalid')}
                            onMouseEnter={() => {
                              onMouseEnter('height', door?.sequenceNumber, door?.frameProfile?.value, fasteningElement);
                              setCurrentFrameHeight({
                                frameNumber: door?.sequenceNumber,
                                value: door?.frameOpeningHeight?.value,
                                ...(door?.frameOpeningHeight?.isInvalid ? { isInvalid: true } : {}),
                              });
                              setCurrentFrameFasteningElementForChecking(fasteningElement);
                              setCurrentFrameFillingTexture(fillingTexture);
                              setCurrentFrameFilling(filling?.[0]);
                              setReinforcingProfileAgreement(guaranteeAgreement);
                              setHeightLimitExceededAgreement(frameHeightGuaranteeAgreement);
                              setWidthLimitExceededAgreement(frameWidthGuaranteeAgreement);
                              setPrevFrameHeight(door?.frameOpeningHeight?.value);
                              setPrevReinforcingProfileAmount(door?.reinforcingProfile?.amount);
                            }}
                            onMouseLeave={() => {
                              onMouseLeave();
                              sanitizeAndSaveFrameHeight(currentFrameHeight?.value, door?.sequenceNumber);
                              setPrevFrameFillingTexture(fillingTexture);
                              changeFrameMaterialTexture(currentFrameHeight?.value, door?.sequenceNumber);
                              setCurrentFrameHeight('');
                              setReinforcingProfileAgreement({});
                              setHeightLimitExceededAgreement({});
                              setWidthLimitExceededAgreement({});
                            }}
                          >
                            {(hoveredField === 'height' && currentFrameNumber === door?.sequenceNumber)
                            || (!door?.frameOpeningHeight?.value && door?.frameOpeningHeight?.value !== 0)
                              ? (
                                <Input
                                  type="number"
                                  direction="rtl"
                                  placeholder={t('frameFacadesList.specify-height')}
                                  value={currentFrameHeight.frameNumber === door?.sequenceNumber
                                    && currentFrameHeight?.value}
                                  onChange={(e) => handleUpdateCurrentFrameHeight(e, door?.sequenceNumber)}
                                  onKeyDown={(e) => {
                                    blockInvalidChar(e, invalidChars);
                                  }}
                                />
                              )
                              : <>{door?.frameOpeningHeight?.value}</>}
                          </div>
                          <div className="tooltip">
                            {frameOpeningHeightLabel}
                          </div>
                        </div>

                        <div className="frame-table-td rtl edit">
                          <div
                            className={clsx('param', 'rtl',
                              !door?.frameOpeningWidth?.value && 'empty',
                              (currentFrameNumber === door?.sequenceNumber && hoveredField === 'width') && 'hovered',
                              door?.frameOpeningWidth?.isInvalid && 'invalid')}
                            onMouseEnter={() => {
                              onMouseEnter(
                                'width',
                                door?.sequenceNumber,
                                door?.frameProfile?.value, fasteningElement, door?.frameOpeningWidth?.value,
                              );
                              setCurrentFrameWidth({
                                frameNumber: door?.sequenceNumber,
                                value: door?.frameOpeningWidth?.value,
                                ...(door?.frameOpeningWidth?.isInvalid ? { isInvalid: true } : {}),
                              });
                              setReinforcingProfileAgreement(guaranteeAgreement);
                              setHeightLimitExceededAgreement(frameHeightGuaranteeAgreement);
                              setWidthLimitExceededAgreement(frameWidthGuaranteeAgreement);
                              setPrevReinforcingProfileAmount(door?.reinforcingProfile?.amount);
                              setPrevFrameWidth(door?.frameOpeningWidth?.value);
                            }}
                            onMouseLeave={() => {
                              onMouseLeave();
                              sanitizeAndSaveFrameWidth(currentFrameWidth?.value, door?.sequenceNumber);
                              setCurrentFrameWidth('');
                              setReinforcingProfileAgreement({});
                              setHeightLimitExceededAgreement({});
                              setWidthLimitExceededAgreement({});
                            }}
                          >
                            {(hoveredField === 'width' && currentFrameNumber === door?.sequenceNumber)
                            || !door?.frameOpeningWidth?.value
                              ? (
                                <Input
                                  type="number"
                                  direction="rtl"
                                  placeholder={t('frameFacadesList.specify-width')}
                                  value={
                                    currentFrameWidth.frameNumber === door?.sequenceNumber && currentFrameWidth?.value
                                  }
                                  onChange={(e) => handleUpdateCurrentFrameWidth(e, door?.sequenceNumber)}
                                  onKeyDown={(e) => {
                                    blockInvalidChar(e, invalidChars);
                                  }}
                                />
                              )
                              : <>{door?.frameOpeningWidth?.value}</>}
                          </div>
                          <div className="tooltip">
                            {frameOpeningWidthLabel}
                          </div>
                        </div>

                        <div className="frame-table-td rtl edit">
                          <div
                            className={clsx('param', 'rtl',
                              (currentFrameNumber === door?.sequenceNumber && hoveredField === 'amount') && 'hovered',
                              door?.sameFramesAmount?.isInvalid && 'invalid')}
                            onMouseEnter={() => {
                              onMouseEnter('amount', door?.sequenceNumber, door?.frameProfile?.value, fasteningElement);
                              setCurrentFrameAmount({
                                value: door?.sameFramesAmount?.value || 0,
                                ...(door?.sameFramesAmount?.isInvalid ? { isInvalid: true } : {}),
                              });
                            }}
                            onMouseLeave={onMouseLeave}
                          >
                            {hoveredField === 'amount' && currentFrameNumber === door?.sequenceNumber
                              ? (
                                <Input
                                  type="number"
                                  direction="rtl"
                                  value={currentFrameAmount?.value || 0}
                                  onChange={handleUpdateCurrentFrameAmount}
                                  onKeyDown={(e) => {
                                    blockInvalidChar(e, invalidChars);
                                  }}
                                />
                              )
                              : <>{door?.sameFramesAmount?.value}</>}

                          </div>
                        </div>

                        <div className="frame-table-td dropdown">
                          <div
                            className={clsx('param', 'dropdown',
                              !currentColor?.articleCode && 'empty',
                              (currentFrameNumber === door?.sequenceNumber && hoveredField === 'color') && 'hovered',
                              door?.frameAluminiumColor?.isInvalid && 'invalid')}
                            onMouseEnter={() => {
                              onMouseEnter('color', door?.sequenceNumber, door?.frameProfile?.value, fasteningElement);
                              setCurrentFrameAluminiumColor({
                                value: currentColor?.articleCode,
                                ...(door?.frameAluminiumColor?.isInvalid ? { isInvalid: true } : {}),
                              });
                              setCurrentFrameProfile(door?.frameProfile?.value);
                            }}
                            onMouseLeave={onMouseLeave}
                          >
                            {(hoveredField === 'color' && currentFrameNumber === door?.sequenceNumber)
                            || !currentColor?.articleCode
                              ? (
                                <Dropdown
                                  placeholder={t('frameFacadesList.specify-color')}
                                  options={currentAvailableColors
                                    .map((c) => ({
                                      value: c.articleCode,
                                      label: c[labelKey],
                                      color: c.color,
                                    }))}
                                  onChange={(selectedOption) => {
                                    if (!selectedOption?.value) return;
                                    setCurrentFrameAluminiumColor({ value: selectedOption?.value });
                                  }}
                                  value={
                                    currentFrameNumber === door?.sequenceNumber && currentAvailableColors.length
                                    && currentColor?.articleCode
                                      ? currentAvailableColors
                                        .find((item) => item.articleCode === currentFrameAluminiumColor.value)
                                      : null
                                  }
                                />
                              )
                              : (
                                <>
                                  <div className="image" style={{ background: currentColor?.color }} />
                                  <span className="label">
                                    {`${currentColor[labelKey]} (${currentColor?.articleCode || ''})`}
                                  </span>
                                </>
                              )}
                          </div>
                        </div>

                        <div className="frame-table-td edit">
                          <div
                            className={clsx('param', 'filling', _.isEmpty(door?.filling[0]) && 'empty')}
                            role="button"
                            tabIndex="0"
                            onClick={() => {
                              onChangeFillingClick(door?.sequenceNumber, door?.filling);
                              dispatch(DoorsActions.setActiveDoor(door?.sequenceNumber));
                            }}
                          >
                            {!_.isEmpty(door?.filling[0]) ? (
                              <>
                                <div className="image">
                                  <img
                                    alt="img"
                                    src={
                                      getFillingImagePath(fillingMaterials, door.filling[0], dspListWithWhiteMargins)
                                    }
                                  />
                                </div>
                                <span className="label">
                                  {getFillingDescription(fillingMaterials, door.filling[0], t, labelKey)?.title}
                                    &nbsp;
                                  {getFillingDescription(fillingMaterials, door.filling[0], t, labelKey)?.option}
                                </span>
                              </>
                            )
                              : (
                                <div className="empty-filling">
                                  {t('frameFacadesList.specify-material')}
                                </div>
                              )}
                          </div>
                        </div>

                        {(currentOrderId || showPrice || !AuthService.isLoggedIn()) && (
                          <div className="frame-table-td rtl">
                            <div className={clsx('param', 'rtl', !totalFramePrice && 'no-price')}>
                              {numberWithSpaces(totalFramePrice)}
                            </div>
                          </div>
                        )}

                        <div className="frame-table-td">
                          <div className="param buttons">
                            {renderActionsButton(door?.sequenceNumber, door?.groupName?.value)}
                          </div>
                        </div>
                      </>
                    ) : (
                      <Collapsible
                        key={`container-${doorIndex + 1}`}
                        trigger={(
                          <div className="frame-mobile-head">
                            <span className="mobile-title">
                              <span className="mobile-title-number">{door?.sequenceNumber}</span>
                              <span>{door.groupName.value}</span>
                            </span>
                            <img
                              className="trigger-image"
                              src={isOpen
                                ? '/src/client/assets/icons/mobileMenu/arrow-top.svg'
                                : '/src/client/assets/icons/mobileMenu/arrow-right.svg'}
                              alt="arrow"
                            />
                          </div>
                        )}
                        onTriggerOpening={() => {
                          setActiveTrigger(doorIndex);
                        }}
                        onTriggerClosing={() => {
                          setActiveTrigger(null);
                        }}
                        disabled={!isOpen}
                        open={isOpen}
                      >
                        <div className="frame-mobile-collapse">
                          <div className="collapse-items">
                            <div className="frame-mobile-name">{t('frameFacadesList.profile')}</div>
                            <div className="frame-mobile-value">
                              <img
                                className="profile-img"
                                alt="img"
                                src={currentProfile?.image}
                              />
                              {door?.frameProfile?.value}
                            </div>
                            <div className="frame-mobile-name">{t('frameFacadesList.height')}</div>
                            <div className="frame-mobile-value">{door?.frameOpeningHeight?.value}</div>
                            <div className="frame-mobile-name">{t('frameFacadesList.width')}</div>
                            <div className="frame-mobile-value">{door?.frameOpeningWidth?.value}</div>
                            <div className="frame-mobile-name">{t('frameFacadesList.amount')}</div>
                            <div className="frame-mobile-value">{door?.sameFramesAmount?.value}</div>
                            <div className="frame-mobile-name">{t('frameFacadesList.color')}</div>
                            <div className="frame-mobile-value">
                              <div className="image" style={{ background: currentColor?.color }} />
                              <span className="label">
                                {`${currentColor[labelKey]} (${currentColor?.articleCode || ''})`}
                              </span>
                            </div>
                            <div className="frame-mobile-name">{t('frameFacadesList.material')}</div>
                            <div className="frame-mobile-value">
                              {' '}
                              {getFillingDescription(fillingMaterials, door.filling[0], t, labelKey)?.title}
                              &nbsp;
                              {getFillingDescription(fillingMaterials, door.filling[0], t, labelKey)?.option}
                            </div>
                          </div>
                          <div className="collapse-buttons">
                            {renderActionsButton(door?.sequenceNumber, door?.groupName?.value)}
                          </div>
                        </div>
                      </Collapsible>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )
        : (
          <div className="frame-no-content">
            <div className="frame-no-content-icon-wrapper">
              <div className="frame-no-content-icon-background" />
              <ReactSVG
                className="frame-no-content-icon"
                wrapper="span"
                src="/src/client/assets/icons/no-list.svg"
              />
            </div>

            <div className="frame-no-content-title">
              {t('frameFacadesList.no-content-title')}
            </div>

            {renderAddButton(t('frameFacadesList.no-content-button'))}
          </div>
        )}

      {(isOpenFrameParamsModal || isOpenChangeFrameFillingModal)
        && (
          <SetFrameParamsModal
            isOpen={isOpenFrameParamsModal || isOpenChangeFrameFillingModal}
            prevWarningModalValue={prevTransparentFillingModal}
            className={modalClassName}
            availableProfiles={frameProfiles}
            aluminiumColors={aluminiumColors}
            prices={prices}
            currentFrameNumber={currentFrameNumber}
            currentFrameName={currentFrameName}
            onDeleteFrame={onDeleteFrame}
            onChangeFilling={onChangeFrameFilling}
            onCloseModal={() => {
              setCurrentFrameNumber(null);
              setCurrentFrameName('');
              dispatch(DoorsActions.toggleFrameParamsModal(false));
              dispatch(DoorsActions.toggleDeleteFrameModal(false));
              dispatch(DoorsActions.toggleChangeFrameFillingModal(false));
              dispatch(FillingActions.resetFillingMaterialModal());
            }}
          />
        )}

      {isOpenStrengthProfileModal && (
        <StrengthProfileModal
          isOpen={isOpenStrengthProfileModal}
          onCloseModal={() => {
            dispatch(DoorsActions.toggleStrengthProfileModal(false));
            dispatch(DoorsActions.toggleFrameParamsModal(true));
          }}
          onSubmit={() => onSubmitGuaranteeAgreement()}
        />
      )}


      {
        (
          <WarningFrameSizeModal
            isOpen={isOpenWarningFrameSizeModal}
            onCloseModal={() => onCloseWarningFrameSizeModal()}
            onSubmit={() => onSubmitFrameSizeGuaranteeAgreement()}
          />
        )
      }

      <TransparentFillingWarning
        isOpen={isOpenTransparentFillingWarning}
        onCloseModal={() => {
          dispatch(DoorsActions.toggleTransparentFillingWarning(false));
          if (activeDoor === 0) return dispatch(DoorsActions.toggleFrameParamsModal(true));

          dispatch(DoorsActions.toggleChangeFrameFillingModal(true));
        }}
        onSubmit={(() => {
          dispatch(DoorsActions.toggleTransparentFillingWarning(false));
          dispatch(FillingActions.resetFillingMaterialModal());
          if (activeDoor === 0) return dispatch(DoorsActions.toggleFrameParamsModal(true));

          dispatch(DoorsActions.toggleChangeFrameFillingModal(true));
        })}
      />

    </div>
  );
};

export default FrameFacadesList;
