import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';

import { countriesList, countriesPhoneCode, regionsList } from '../../../server/helpers/constants.mjs';
import { isValidFirstOrLastName, isValidMobilePhone } from '../../helpers/validation.js';
import { capitalizeFirstLetter, sanitizeValueToNumberStringLike } from '../../helpers/sanitizer.js';
import notificationPropTypes from '../../helpers/propTypes/notificationPropTypes.js';

import ClientListActions from '../../redux/actions/clientList';

import Modal from '../Modal';
import Input from '../Input';
import InputPhone from '../InputPhone';
import Dropdown from '../Dropdown';
import Button from '../Button';

import API from '../../api';


const SignUpByDealerModal = ({ toastManager, isOpen, className, onCloseModal, packages, primaryRegion }) => {
  const { t, i18n } = useTranslation(['components']);
  const dispatch = useDispatch();

  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelUk';

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [packageName, setPackageName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(countriesList[0]);
  const [fullPhoneNumber, setFullPhoneNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [notificationId, setNotificationId] = useState('');

  const packagesList = packages.map((p) => ({
    value: p.packageName,
    label: p[labelKey],
  }));

  const region = regionsList.find((r) => Object.values(r).includes(primaryRegion));

  const reset = () => {
    setFirstName('');
    setLastName('');
    setFirstNameError('');
    setLastNameError('');
    setPackageName('');
    setSelectedCountry(countriesList[0]);
    setPhoneNumber('');
    setPhoneNumberError('');
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  const showToast = (message, appearance) => {
    const errorContent = <div className="toast-notification">{message}</div>;

    if (notificationId) toastManager.remove(notificationId);

    toastManager.add(errorContent, {
      appearance,
      autoDismiss: true,
    }, (id) => { setNotificationId(id); });
  };

  const updateFirstName = ({ target: { value } }) => {
    setFirstName(value);
  };

  const validateFirstName = ({ target: { value } }) => {
    const isInvalid = !isValidFirstOrLastName(value, 2, 30);
    const error = isInvalid ? t('errorMessages.invalid-name') : '';

    setFirstName(capitalizeFirstLetter(value));
    setFirstNameError(error);
  };

  const updateLastName = ({ target: { value } }) => {
    setLastName(value);
  };

  const validateLastName = ({ target: { value } }) => {
    const isInvalid = !isValidFirstOrLastName(value, 2, 30);
    const error = isInvalid ? t('errorMessages.invalid-name') : '';

    setLastName(capitalizeFirstLetter(value));
    setLastNameError(error);
  };

  const updatePhone = ({ target: { value } }) => {
    setPhoneNumber(sanitizeValueToNumberStringLike(value));
    const fullNumber = `${selectedCountry.code}${phoneNumber}`;

    setFullPhoneNumber(fullNumber);
  };

  const validatePhone = ({ target: { value } }) => {
    setPhoneNumber(sanitizeValueToNumberStringLike(value));
    const fullNumber = `${selectedCountry.code}${phoneNumber}`;
    const isValid = isValidMobilePhone(fullNumber)
      && fullNumber.slice(1).length === selectedCountry.numberOfDigits;
    const error = isValid ? '' : t('errorMessages.incorrect-number');

    setPhoneNumberError(error);
    setFullPhoneNumber(fullNumber);
  };

  const updatePackageName = (selectedOption) => {
    setPackageName(selectedOption);
  };

  const handleSubmit = async () => {
    const clientLanguage = _.findKey(countriesPhoneCode, (code) => fullPhoneNumber.includes(code));
    const packageNameTranslation = _.omit(packages.find((p) => p.packageName === packageName), ['packageName', '_id']);

    const deliveryData = {
      type: 'Самовивіз',
      city: region[clientLanguage],
    };

    const response = await API.user.signupByDealer(
      firstName,
      lastName,
      fullPhoneNumber,
      region.ru,
      deliveryData,
      packageName,
      packageNameTranslation,
    );

    if (!response.ok) {
      const msg = response.data?.error?.message;
      return showToast(msg ? t(`errorMessagesHelper.${msg}`) : response.data?.message, 'error');
    }

    showToast(t('signUpByDealerModal.success-message'), 'success');
    reset();
    onCloseModal();
    dispatch(ClientListActions.getDealerUsersRequest());
  };

  const isSignUpDisabled = () => Boolean(!firstName || !lastName || !packageName || !fullPhoneNumber
    || !phoneNumber || firstNameError || lastNameError || phoneNumberError);

  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      key="order-title-modal"
      shouldDisableBodyScroll
    >
      <div className="modal-wrapper">
        <div className="title-wrapper">
          <div className="title">{t('signUpByDealerModal.title')}</div>
        </div>
        <div className="form-wrapper">
          <div className="data-wrapper">
            <span className="label">{t('signUpByDealerModal.client-first-name')}</span>
            <Input
              value={firstName ?? ''}
              error={firstNameError}
              onChange={updateFirstName}
              onBlur={validateFirstName}
              placeholder={t('signUpByDealerModal.enter-first-name')}
            />
          </div>
          <div className="data-wrapper">
            <span className="label">{t('signUpByDealerModal.client-last-name')}</span>
            <Input
              value={lastName ?? ''}
              error={lastNameError}
              onChange={updateLastName}
              onBlur={validateLastName}
              placeholder={t('signUpByDealerModal.enter-last-name')}
            />
          </div>
          <div className="data-wrapper">
            <span className="label">{t('signUpByDealerModal.phone')}</span>
            <InputPhone
              value={phoneNumber}
              error={phoneNumberError}
              onChange={updatePhone}
              onBlur={validatePhone}
              countries={countriesList}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              name="phone"
            />
          </div>
          <div className="data-wrapper">
            <span className="label">{t('signUpByDealerModal.price-package')}</span>
            <Dropdown
              options={packagesList?.length ? packagesList : []}
              onChange={(selectedOption) => {
                if (!selectedOption?.value) return;
                updatePackageName(selectedOption.value);
              }}
              value={packagesList.find((item) => item.value === packageName)}
            />
          </div>
          <div className="data-wrapper">
            <span className="label">{t('signUpByDealerModal.region')}</span>
            <div className="region-box">{region[i18n.language]}</div>
          </div>

          <Button
            type="rounded"
            value={t('signUpByDealerModal.register')}
            onClick={handleSubmit}
            isDisabled={isSignUpDisabled()}
          />
        </div>
      </div>
    </Modal>
  );
};

SignUpByDealerModal.defaultProps = {
  isOpen: false,
  className: 'action-modal signup-by-dealer-modal',
};

SignUpByDealerModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(PropTypes.string).isRequired,
  primaryRegion: PropTypes.string.isRequired,
  toastManager: PropTypes.shape(notificationPropTypes).isRequired,
};

export default withToastManager(SignUpByDealerModal);
