import React from 'react';
import { navigate, useTitle } from 'hookrouter';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import OrderActions from '../../redux/actions/order';
import DoorsActions from '../../redux/actions/doorsAndSections';
import AuthService from '../../services/authService';
import AuthActions from '../../redux/actions/auth';
import FillingActions from '../../redux/actions/fillingMaterials';

const LandingText = ({ hasOverlay }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useTitle('ADS');

  const onLogoutClick = () => {
    dispatch(AuthActions.logout());
    dispatch(FillingActions.resetFillingMaterialModal());
    dispatch(OrderActions.resetSpecification());
    dispatch(DoorsActions.resetOrderDefaults());
    navigate('/sign-in');
  };

  return (
    <div className="landing-text">
      <section className="landing--title">
        <p>{t('landing.title')}</p>
      </section>

      <section className="landing--text-info">
        <p>{`${t('landing.advantages')}:`}</p>
        <ul>
          <li>{`${t('landing.advantage-1')};`}</li>
          <li>{`${t('landing.advantage-2')};`}</li>
          <li>{`${t('landing.advantage-3')};`}</li>
          <li>{`${t('landing.advantage-4')};`}</li>
          <li>{`${t('landing.advantage-5')}.`}</li>
        </ul>
        <div className="landing--buttons">
          <Button
            type="rounded"
            value={t('landing.to-systems')}
            onClick={() => {
              navigate('/systems');
              dispatch(OrderActions.resetSpecification());
              dispatch(DoorsActions.resetOrderDefaults());
            }}
          />

          { !(AuthService.isLoggedIn() && AuthService.isPhoneNumberVerified())
            ? (
              <Button
                className="white-btn"
                value={t('landing.sign-in')}
                onClick={() => navigate('/sign-in')}
              />
            ) : (
              <Button
                className="white-btn"
                value={t('landing.logout')}
                onClick={onLogoutClick}
              />
            )}
        </div>
      </section>

      {hasOverlay && (
        <div
          className="landing-overlay"
          onClick={() => navigate('/')}
          role="button"
          onKeyPress={null}
          tabIndex={0}
        />
      )}
    </div>
  );
};

LandingText.propTypes = {
  hasOverlay: PropTypes.bool,
};

LandingText.defaultProps = {
  hasOverlay: false,
};

export default LandingText;
