/**
 *
 * Main Tab with settings for all doors
 *
 */

import _ from 'lodash';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import clsx from 'clsx';

import { ReactSVG } from 'react-svg';

import AuthService from '../../../services/authService';

import {
  doorPositioningOptions,
  hingedDoorPositioningOptions,
  textures,
  materialType,
  stoppers,
  facadeViews,
} from '../../../helpers/options';

import {
  minWidthForSingleOpeningDoor,
  maxWidthForSingleOpeningDoor,
  minWidthForTwoOpeningDoors,
  maxWidthForTwoOpeningDoors,
  minWidthFor4AssemblingDoors,
  maxWidthFor4AssemblingDoors,
  assemblingAdditionalMechanism,
} from '../../../helpers/constants';

import { isColorSideProfileAvailable, isMechanismAvailable } from '../../../helpers/priceHelper.mjs';
import { isValidNumberField, canUseHorizontalTexture } from '../../../helpers/validation';
import constantsBySystemType from '../../../helpers/constantsBySystemType';
import constantsForFrameProfiles from '../../../helpers/constantsForFrameProfiles';
import notificationPropTypes from '../../../helpers/propTypes/notificationPropTypes';
import { doorFillingHeightForChipboard } from '../../../helpers/sizesCalculation';
import { removeLeadingZero, blockInvalidChar, numberWithSpaces } from '../../../helpers/sanitizer';
import { onMirrorHorizontal, onMirrorVertical, validateAventosHF } from '../../../helpers/frameHelper';

import useWindowSize from '../../../hooks/findWindowSize';

import DoorsActions from '../../../redux/actions/doorsAndSections';
import FillingActions from '../../../redux/actions/fillingMaterials';
import OrderActions from '../../../redux/actions/order';
import DrillingHolesAction from '../../../redux/actions/drillingHoles';
import FasteningElementAction from '../../../redux/actions/fasteningElement';
import DrillingPearHolesAction from '../../../redux/actions/drillingPearHoles';

import Checkbox from '../../Checkbox';
import RadioGroup from '../../RadioGroup';
import RadioOption from '../../RadioOption';
import Input from '../../Input';
import PlusMinusControl from '../../PlusMinusControl';
import Label from '../../Label';
import Dropdown from '../../Dropdown';
import FillingMaterialsModal from '../../FillingMaterialsModal';
import FillingMaterialsControl from '../../FillingMaterialsControl';
import Switch from '../../Switch';
import Button from '../../Button';
import SetGroupNameModal from '../../SetGroupNameModal';
import StrengthProfileModal from '../../StrengthProfileModal';
import Tooltip from '../../Tooltip';
import { liftingMechanismSizes } from '../../../helpers/frameProfilesOptions';
import { areDoorsAndSectionsWithoutFilling } from '../Footer';
import WarningFrameSizeModal from '../../WarningFrameSizeModal';
import { frameProfileParameters } from '../../../../server/helpers/priceHelperOptions.mjs';

const DoorMainTab = ({ toastManager }) => {
  const { t, i18n } = useTranslation(['components', 'options']);
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const inputRefH = useRef(null);
  const inputRefW = useRef(null);
  const inputRefMonoW = useRef(null);
  const inputRefSidewallThickness = useRef(null);
  const copyDropdownRef = useRef();
  const prevActiveDoorRef = useRef();
  const prevFrameProfileRef = useRef();

  const [canChooseSymmetrical, setCanChooseSymmetrical] = useState(false);
  const [availableColors, setAvailableColors] = useState([]);
  const [availableFrameColors, setAvailableFrameColors] = useState([]);
  const [availableMechanisms, setAvailableMechanisms] = useState([]);
  const [deprecatedMechanisms, setDeprecatedMechanisms] = useState([]);
  const [filling, setFilling] = useState([{}]);
  const [shouldHighlight, setShouldHighlight] = useState(false);
  const [isEmptyHeight, setIsEmptyHeight] = useState(false);
  const [isEmptyWidth, setIsEmptyWidth] = useState(false);

  const { showPrice } = useSelector(({ profile }) => profile);

  const { currentOrderId } = useSelector(({ order }) => order);

  const { currentSystem } = useSelector(({ systems }) => systems);

  const { frameSpecification } = useSelector(({ order }) => order);

  const { isFasteningElementOpen } = useSelector(({ fasteningElement }) => fasteningElement);

  const { isOpenDrillingPearHoles } = useSelector(({ drillingPearHoles }) => drillingPearHoles);

  const systemDefaults = constantsBySystemType.find((c) => c.systemType === currentSystem) || {};

  const { isOpenDrillingHoles } = useSelector(({ drillingHoles }) => drillingHoles);

  const {
    defaultSideProfile,
    minDoorOpeningHeight = 0,
    maxDoorOpeningHeight = 0,
    minDoorOpeningWidth = 0,
    maxDoorOpeningWidth = 0,
    maxDoorWidth = 0,
    minDoorWidth = 0,
    minSidewallThickness = 16,
    maxSidewallThickness = 36,
  } = systemDefaults;

  const {
    isConfigFetched,
    aluminiumColors,
    systemConctants,
    sideProfiles,
    frameProfiles,
    mechanisms,
    filling: allAvailableFilling,
  } = useSelector(({ config }) => config);

  const { priceList: prices, isHidden: isHiddenPrice } = useSelector(({ priceList }) => priceList);

  const {
    minDoorsAmount,
    maxDoorsAmount,
    main: {
      filling: mainDoorFilling,
      mechanism,
      sideProfile,
      doorOpeningHeight,
      doorOpeningWidth,
      monorailSingleDoorWidth,
      doorsAmount,
      doorPositioning,
      aluminiumColor = {},
      stopper,
      texture,
      sidewallThickness,
      isX2ProfileOn,
      isX4ProfileOn,
      isMiddleDoorMechanismOn,
      isStopperOn,
    },
    mainFrame,
    activeDoor,
    isGroupNameModalOpen,
    isOpenStrengthProfileModal,
    isOpenWarningFrameSizeModal,
    isOpenTransparentFillingWarning,
    doors,
    isInvertFrame,
    frameConfigurationView,
    frameGuaranteeType,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const {
    isShowSizes,
    profileHeight,
    profileWidth,
    connectingCorner,
    frameOpeningHeight,
    frameOpeningWidth,
    frameProfile: frameP,
    frameAluminiumColor: frameAC,
    facadeView,
    openingSide,
    filling: mainFrameFilling,
    fillingTexture,
    reinforcingProfile,
    holesForHandles,
    pearHoles,
    fasteningElement,
    millingForReinforcingProfile,
    strainerForReinforcingProfile,
    sameFramesAmount,
    guaranteeAgreement,
    frameHeightGuaranteeAgreement,
    frameWidthGuaranteeAgreement,
  } = mainFrame;
  const doorsHeight = doors[0]?.main?.doorHeight;

  const [isOpenCopy, setIsOpenCopy] = useState(false);

  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [minDoorOpeningW, setMinDoorOpeningW] = useState(0);
  const [maxDoorOpeningW, setMaxDoorOpeningW] = useState(0);
  const [maxMonorailDoorWidth, setMaxMonorailDoorWidth] = useState(0);
  const [monorailDoorWidth, setMonorailDoorWidth] = useState(null);
  const [sidewallThicknessValue, setSidewallThicknessValue] = useState(null);
  const [currentFrameProfileSizes, setCurrentFrameProfileSizes] = useState(null);
  const [currentLiftingMechanismSizes, setCurrentLiftingMechanismSizes] = useState(null);

  const [frameActiveDoor, setFrameActiveDoor] = useState(null);
  const [isShowAllSizes, setIsShowAllSizes] = useState(true);
  const [frameProfileHeight, setFrameProfileHeight] = useState({});
  const [frameProfileWidth, setFrameProfileWidth] = useState({});
  const [frameConnectingCorner, setFrameConnectingCorner] = useState({});
  const [frameOpeningH, setFrameOpeningH] = useState(null);
  const [frameOpeningW, setFrameOpeningW] = useState(null);
  const [frameReinforcingProfileAmount, setFrameReinforcingProfileAmount] = useState(0);
  const [frameReinforcingProfileItems, setFrameReinforcingProfileItems] = useState([]);
  const [frameHolesForHandles, setFrameHolesForHandles] = useState({});
  const [frameFasteningElement, setFrameFasteningElement] = useState({});
  const [framePearHoles, setFramePearHoles] = useState({});
  const [frameMilling, setFrameMilling] = useState(0);
  const [frameStrainer, setFrameStrainer] = useState(0);
  const [frameProfile, setFrameProfile] = useState('');
  const [frameAluminiumColor, setFrameAluminiumColor] = useState('');
  const [frameOpeningSide, setFrameOpeningSide] = useState('left');
  const [frameFilling, setFrameFilling] = useState({});
  const [frameFillingTexture, setFrameFillingTexture] = useState('');
  const [frameFacadeView, setFrameFacadeView] = useState('opening');
  const [sameFrame, setSameFrame] = useState(0);
  const [reinforcingProfileAgreement, setReinforcingProfileAgreement] = useState({});
  const [frameHeightAgreement, setFrameHeightAgreement] = useState({});
  const [frameWidthAgreement, setFrameWidthAgreement] = useState({});
  const [framePrice, setFramePrice] = useState({ total: 0, perFrame: 0 });
  const [frameName, setFrameName] = useState('');

  const [prevFrameWidth, setPrevFrameWidth] = useState(0);
  const [prevFrameHeight, setPrevFrameHeight] = useState(0);
  const [prevReinforcingProfileAmount, setPrevReinforcingProfileAmount] = useState(0);
  const [prevFrameFillingTexture, setPrevFrameFillingTexture] = useState('');

  useEffect(() => {
    window.addEventListener('click', handleClickOutside, true);

    return () => {
      window.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  const handleClickOutside = (e) => {
    if (copyDropdownRef.current && !copyDropdownRef.current.contains(e.target)) {
      setIsOpenCopy(false);
    }
  };


  useEffect(() => {
    dispatch(OrderActions.calculateFrameOrderRequest());
  }, [mainFrame, doors, frameConfigurationView]);

  useEffect(() => {
    const totalFramePrice = _.round(
      frameSpecification?.specificationByFrame?.find(
        (s) => s.frameNumber === activeDoor,
      )?.specification?.totalPrice, 2,
    ) || 0;
    const pricePerFrame = _.round(totalFramePrice / Number(sameFrame), 2) || 0;
    setFramePrice({
      total: totalFramePrice,
      perFrame: pricePerFrame,
    });
  }, [frameSpecification, sameFrame]);

  const isDisabledCreateGroup = !(frameProfile && frameOpeningH && frameOpeningW && frameAluminiumColor
    && sameFrame && !_.isEmpty(frameFilling));

  const errorMessage = () => {
    const message = [
      !frameProfile && t('errorMessages.empty-profile'),
      !frameOpeningH && t('errorMessages.empty-height'),
      !frameOpeningW && t('errorMessages.empty-width'),
      !frameAluminiumColor && t('errorMessages.empty-color'),
      _.isEmpty(frameFilling) && t('errorMessages.empty-filling'),
      !sameFrame && t('errorMessages.empty-amount'),
    ].filter((item) => item).map((item, i, arr) => (i === arr.length - 1 ? item : `${item},`)).join(' ');

    return `${t('errorMessages.empty-text')} - ${message}.`;
  };

  const [notificationId, setNotificationId] = useState('');
  const showToast = (message, appearance) => {
    const errorContent = <div className="toast-notification">{message}</div>;

    if (notificationId) toastManager.remove(notificationId);

    toastManager.add(errorContent, {
      appearance,
      autoDismiss: true,
    }, (id) => { setNotificationId(id); });
  };

  const checkIsValidCoords = () => {
    const fasteningElementIntend = 85;
    const holesForHandlesIntend = 65;
    const pearHolesIntend = 80;

    const pearHolesItemsToUpdate = framePearHoles?.items?.map((item) => {
      if ((frameProfile?.replace('-N', '') === 'P-32' && item?.coords?.y > Number(frameOpeningH - pearHolesIntend))
        || item?.coords?.x > Number(frameOpeningW - pearHolesIntend)) {
        return ({ ...item, isInvalid: true });
      }

      if ((frameProfile?.replace('-N', '') === 'P-32' && item?.coords?.y <= Number(frameOpeningH - pearHolesIntend))
        || item?.coords?.x < Number(frameOpeningW - pearHolesIntend)) {
        return (_.omit(item, ['isInvalid']));
      }

      return item;
    });

    const fasteningElementItemsToUpdate = frameFasteningElement?.items?.map((item) => {
      if (item?.coords?.y > Number(frameOpeningH - fasteningElementIntend)) {
        return ({ ...item, isInvalid: true });
      }

      if (item?.coords?.y <= Number(frameOpeningH - fasteningElementIntend)) {
        return (_.omit(item, ['isInvalid']));
      }

      return item;
    });

    const holesForHandlesItemsToUpdate = frameHolesForHandles?.items?.map((item) => {
      if (((holesForHandles?.placementSide === 'right' || holesForHandles?.placementSide === 'left')
        && item?.coords?.y > Number(frameOpeningH - holesForHandlesIntend))
        || ((holesForHandles?.placementSide === 'top' || holesForHandles?.placementSide === 'bottom')
          && item?.coords?.x > Number(frameOpeningW - holesForHandlesIntend))) {
        return ({ ...item, isInvalid: true });
      }

      if (((holesForHandles?.placementSide === 'right' || holesForHandles?.placementSide === 'left')
        && item?.coords?.y <= Number(frameOpeningH - holesForHandlesIntend))
        || ((holesForHandles?.placementSide === 'top' || holesForHandles?.placementSide === 'bottom')
          && item?.coords?.x <= Number(frameOpeningW - holesForHandlesIntend))) {
        return (_.omit(item, ['isInvalid']));
      }

      return item;
    });

    setFramePearHoles({ ...framePearHoles, items: pearHolesItemsToUpdate });
    setFrameFasteningElement({ ...frameFasteningElement, items: fasteningElementItemsToUpdate });
    setFrameHolesForHandles({ ...frameHolesForHandles, items: holesForHandlesItemsToUpdate });
  };

  const getFrameProfileColors = (profile) => {
    const colors = frameProfiles.find((fp) => fp.articleCode === profile)?.colorsDependence
      .filter((color) => isColorSideProfileAvailable(color, profile, prices)) || [];
    return colors;
  };

  const getColor = (profile) => {
    const frameColors = getFrameProfileColors(profile);
    const colorToSet = aluminiumColors
      .filter(({ articleCode }) => frameColors.includes(articleCode))
      .map(({ articleCode }) => articleCode)[0];

    return colorToSet;
  };

  useEffect(() => {
    prevFrameProfileRef.current = frameProfile;
    prevActiveDoorRef.current = frameActiveDoor;
  });

  const prevFrameProfile = prevFrameProfileRef.current;
  const prevActiveDoor = prevActiveDoorRef.current;


  useEffect(() => {
    setFrameProfile(frameProfiles.map(({ articleCode }) => articleCode)[0]);
  }, [frameProfiles, doors.length]);

  useEffect(() => {
    setFrameActiveDoor(activeDoor);

    if (activeDoor === 0) {
      setIsShowAllSizes(isShowSizes);
      setFrameProfileHeight(profileHeight);
      setFrameProfileWidth(profileWidth);
      setFrameConnectingCorner(connectingCorner);
      setFrameOpeningH(frameOpeningHeight.value);
      setFrameOpeningW(frameOpeningWidth.value);
      setFrameReinforcingProfileAmount(reinforcingProfile.amount);
      setFrameReinforcingProfileItems(reinforcingProfile.items);
      setFrameMilling(millingForReinforcingProfile?.amount);
      setFrameStrainer(strainerForReinforcingProfile?.amount);
      setFrameProfile(frameP?.value || frameProfiles.map(({ articleCode }) => articleCode)[0]);
      setFrameAluminiumColor(frameAC?.value
        || getColor(frameP?.value || frameProfiles.map(({ articleCode }) => articleCode)[0]));
      setFrameOpeningSide(openingSide.value);
      setSameFrame(sameFramesAmount.value || 0);
      setFrameFilling(mainFrameFilling);
      setFrameFillingTexture(fillingTexture?.value);
      setFrameFacadeView(facadeView?.value);
      setFrameHolesForHandles(holesForHandles);
      setFrameFasteningElement(fasteningElement);
      setFramePearHoles(pearHoles);
      setReinforcingProfileAgreement(guaranteeAgreement);
      setFrameHeightAgreement(frameHeightGuaranteeAgreement);
      setFrameWidthAgreement(frameWidthGuaranteeAgreement);
      setPrevFrameWidth(frameOpeningWidth.value);
      setPrevFrameHeight(frameOpeningHeight.value);
      setPrevReinforcingProfileAmount(reinforcingProfile.amount);
      return;
    }

    setIsShowAllSizes(doors[activeDoor - 1]?.isShowSizes);
    setFrameProfileHeight(doors[activeDoor - 1]?.profileHeight);
    setFrameProfileWidth(doors[activeDoor - 1]?.profileWidth);
    setFrameConnectingCorner(doors[activeDoor - 1]?.connectingCorner);
    setFrameOpeningH(doors[activeDoor - 1]?.frameOpeningHeight.value);
    setFrameOpeningW(doors[activeDoor - 1]?.frameOpeningWidth.value);
    setFrameReinforcingProfileAmount(doors[activeDoor - 1]?.reinforcingProfile.amount);
    setFrameReinforcingProfileItems(doors[activeDoor - 1]?.reinforcingProfile.items);
    setFrameMilling(doors[activeDoor - 1]?.millingForReinforcingProfile?.amount);
    setFrameStrainer(doors[activeDoor - 1]?.strainerForReinforcingProfile?.amount);
    setFrameProfile(doors[activeDoor - 1]?.frameProfile?.value);
    setFrameAluminiumColor(doors[activeDoor - 1]?.frameAluminiumColor?.value);
    setFrameOpeningSide(doors[activeDoor - 1]?.openingSide.value);
    setSameFrame(doors[activeDoor - 1]?.sameFramesAmount.value);
    setFrameFilling(doors[activeDoor - 1]?.filling);
    setFrameFillingTexture(doors[activeDoor - 1]?.fillingTexture?.value);
    setFrameFacadeView(doors[activeDoor - 1]?.facadeView.value);
    setFrameHolesForHandles(doors[activeDoor - 1]?.holesForHandles);
    setFrameFasteningElement(doors[activeDoor - 1]?.fasteningElement);
    setFramePearHoles(doors[activeDoor - 1]?.pearHoles);
    setReinforcingProfileAgreement(doors[activeDoor - 1]?.guaranteeAgreement);
    setFrameHeightAgreement(doors[activeDoor - 1]?.frameHeightGuaranteeAgreement);
    setFrameWidthAgreement(doors[activeDoor - 1]?.frameWidthGuaranteeAgreement);
    setPrevFrameWidth(doors[activeDoor - 1]?.frameOpeningWidth.value);
    setPrevFrameHeight(doors[activeDoor - 1]?.frameOpeningHeight.value);
    setPrevReinforcingProfileAmount(doors[activeDoor - 1]?.reinforcingProfile.amount);
    setFrameName(doors[activeDoor - 1]?.groupName?.value);

    dispatch(DoorsActions.invertFrame(false));
  }, [activeDoor, mainFrameFilling, frameProfiles, doors.length, doors[activeDoor - 1]?.filling,
    isInvertFrame, isFasteningElementOpen, isOpenDrillingPearHoles, isOpenDrillingHoles, prices]);

  useEffect(() => {
    dispatch(DoorsActions.toggleShowSizes(activeDoor, isShowAllSizes));
  }, [isShowAllSizes]);

  useEffect(() => {
    dispatch(DoorsActions.setFrameProfileHeight(activeDoor, frameProfileHeight));
    dispatch(DoorsActions.setFrameProfileWidth(activeDoor, frameProfileWidth));
  }, [frameProfileHeight, frameProfileWidth]);

  useEffect(() => {
    dispatch(DoorsActions.setFrameConnectingCorner(activeDoor, frameConnectingCorner));
  }, [frameConnectingCorner]);

  useEffect(() => {
    if (frameOpeningH) checkIsValidCoords();
  }, [frameOpeningH]);

  useEffect(() => {
    if (frameOpeningW) checkIsValidCoords();
  }, [frameOpeningW]);

  useEffect(() => {
    dispatch(DoorsActions.setReinforcingProfileAmount(activeDoor, frameReinforcingProfileAmount));
  }, [frameReinforcingProfileAmount]);

  useEffect(() => {
    dispatch(DoorsActions.updateReinforcingProfileItems(activeDoor, frameReinforcingProfileItems));
  }, [frameReinforcingProfileItems]);

  useEffect(() => {
    dispatch(DoorsActions.setMillingForReinforcingProfile(activeDoor, frameMilling));
  }, [frameMilling]);

  useEffect(() => {
    dispatch(DoorsActions.setStrainerForReinforcingProfile(activeDoor, frameStrainer));
  }, [frameReinforcingProfileItems]);

  useEffect(() => {
    if (currentSystem !== 'frame-facades') return;

    dispatch(DoorsActions.setHolesForHandles(activeDoor, frameHolesForHandles));
  }, [frameHolesForHandles]);

  useEffect(() => {
    if (currentSystem !== 'frame-facades') return;

    const { type, liftingMechanismType } = frameFasteningElement;
    if (type === 'lifting-mechanism') {
      setCurrentLiftingMechanismSizes(liftingMechanismSizes[liftingMechanismType]);
    }
    dispatch(DoorsActions.setFasteningElement(activeDoor, frameFasteningElement));
  }, [frameFasteningElement]);

  useEffect(() => {
    if (currentSystem !== 'frame-facades') return;

    dispatch(DoorsActions.setPearHoles(activeDoor, framePearHoles));
  }, [framePearHoles]);

  useEffect(() => {
    if (currentSystem !== 'frame-facades') return;

    dispatch(DoorsActions.setOpeningSide(activeDoor, frameOpeningSide));
  }, [frameOpeningSide]);

  useEffect(() => {
    if (currentSystem !== 'frame-facades') return;

    dispatch(DoorsActions.setFrameFillingTexture(activeDoor, frameFillingTexture));
  }, [frameFillingTexture]);


  useEffect(() => {
    const sanitizedFrameProfile = frameProfile?.replace('-N', '');
    const sizes = constantsForFrameProfiles.find((p) => p.profile === sanitizedFrameProfile);

    if (prevActiveDoor === frameActiveDoor && prevFrameProfile && prevFrameProfile !== frameProfile) {
      setCurrentLiftingMechanismSizes(null);
      setFrameOpeningH('');
      setFrameOpeningW('');
      dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: '' }));
      dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: '' }));
      setFrameMilling(0);
      setFrameStrainer(0);
      setFrameOpeningSide('left');
      setFrameReinforcingProfileAmount(0);
      setFrameReinforcingProfileItems([]);
      setReinforcingProfileAgreement({
        needToUseReinforcingProfile: false,
        agreementNotToUseReinforcingProfile: false,
      });
      setFrameHeightAgreement({
        heightLimitExceeded: false,
        agreement: false,
      });
      setFrameWidthAgreement({
        widthLimitExceeded: false,
        agreement: false,
      });
      setSameFrame(0);

      resetFasteningElement();
      resetHolesForHandles('left');
      resetPearHoles();
    }

    setCurrentFrameProfileSizes(sizes);
    setFrameProfileHeight(sizes?.profileHeight);
    setFrameProfileWidth(sizes?.profileWidth);
    setFrameConnectingCorner(sizes?.connectingCorner);
    setAvailableFrameColors(getFrameProfileColors(frameProfile));
    dispatch(DoorsActions.setFrameProfile(activeDoor, frameProfile));
  }, [frameProfile, prices]);

  useEffect(() => {
    dispatch(DoorsActions.setFrameAluminiumColor(activeDoor, { value: frameAluminiumColor }));
  }, [frameAluminiumColor]);

  useEffect(() => {
    dispatch(DoorsActions.setFacadeView(activeDoor, frameFacadeView));
  }, [frameFacadeView]);

  useEffect(() => {
    dispatch(DoorsActions.setSameFramesAmount(activeDoor, { value: Number(sameFrame) }));
  }, [sameFrame]);

  useEffect(() => {
    dispatch(DoorsActions.setGuaranteeAgreement(activeDoor, reinforcingProfileAgreement));
  }, [reinforcingProfileAgreement]);

  useEffect(() => {
    dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(activeDoor, frameHeightAgreement));
  }, [frameHeightAgreement]);

  useEffect(() => {
    dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(activeDoor, frameWidthAgreement));
  }, [frameWidthAgreement]);

  const resetFasteningElement = () => {
    setFrameFasteningElement({
      type: '',
      amount: 0,
      items: [],
      useCurtain: false,
      hingeType: '',
      drillingScheme: '',
      liftingMechanismType: '',
      liftingMechanismBottomType: '',
      standardServoDriveType: '',
      withBottomModule: false,
      isUserChangedCoords: false,
    });
  };

  const resetHolesForHandles = (frameSide) => {
    setFrameHolesForHandles({
      amount: 0,
      items: [],
      placementSide: frameSide === 'left' ? 'right' : 'left',
    });
  };

  const resetPearHoles = () => {
    setFramePearHoles({
      amount: 0,
      items: [],
      placementSide: frameProfile?.replace('-N', '') === 'P-32' ? 'left-right' : 'top',
    });
  };

  const fillingMaterialsState = useSelector(({ fillingMaterials }) => fillingMaterials);
  const {
    activeTrigger,
    customers: {
      customersOption,
      isMilling,
    },
    dsp: {
      manufacturer,
      isDspUVPrinting,
      searchField,
      dspOption,
      dspUvPrintType,
    },
    mirror: {
      mirrorType,
      isMirrorMatted,
      isMirrorRearMatted,
      isMirrorFullMatted,
      mirrorColor,
      isMirrorUVPrinting,
      mirrorUvPrintType,
      isMirrorArmoredFilm,
      isMirrorLaminated,
      mirrorSearch,
    },
    lacobel: {
      lacobelType,
      isLacobelMatted,
      isLacobelRearMatted,
      isLacobelFullMatted,
      lacobelColor,
      isLacobelUVPrinting,
      lacobelUvPrintType,
      isLacobelArmoredFilm,
      isLacobelLaminated,
      lacobelSearch,
    },
    glass: {
      glassType,
      isGlassMatted,
      isGlassArmoredFilm,
      isGlassLaminated,
      isGlassOneColorPainted,
      isGlassTwoColorsPainted,
      glassColors,
      isGlassUVPrinting,
      glassUvPrintType,
      isGlassPhotoPrinting,
      glassPhotoPrintType,
      glassSearch,
      isGlassFullMatted,
    },
    isOpenFillingModal,
  } = fillingMaterialsState;


  useEffect(() => {
    const currentFilling = fillingMaterialsState[activeTrigger];

    if (frameConfigurationView !== 'simplified' && !isOpenFillingModal && !isOpenTransparentFillingWarning
      && (_.isEmpty(frameFilling) || !_.isEqual(frameFilling[0], currentFilling)) && windowSize?.width < 1024) {
      dispatch(FillingActions.resetFillingMaterialModal());
    }
  }, [!isOpenFillingModal, activeDoor, frameFilling]);

  useEffect(() => setHeight(doorOpeningHeight), [doorOpeningHeight.value]);
  useEffect(() => setWidth(doorOpeningWidth), [doorOpeningWidth.value]);
  useEffect(() => setMinDoorOpeningW(minDoorOpeningWidth), [minDoorOpeningWidth]);
  useEffect(() => setMaxDoorOpeningW(maxDoorOpeningWidth), [maxDoorOpeningWidth]);
  useEffect(() => setMaxMonorailDoorWidth(maxDoorWidth), [maxDoorWidth]);
  useEffect(() => setMonorailDoorWidth(monorailSingleDoorWidth), [monorailSingleDoorWidth?.value]);
  useEffect(() => setSidewallThicknessValue(sidewallThickness), [sidewallThickness?.value]);


  const doorOpeningHeightLabel = t(
    'stickyMenu.mainDoorTab.from-min-to-max',
    { min: minDoorOpeningHeight, max: maxDoorOpeningHeight },
  );
  const doorOpeningWidthLabel = t(
    'stickyMenu.mainDoorTab.from-min-to-max',
    { min: minDoorOpeningW, max: maxDoorOpeningW },
  );
  const monorailDoorWidthLabel = t(
    'stickyMenu.mainDoorTab.from-min-to-max',
    { min: minDoorWidth, max: maxMonorailDoorWidth },
  );
  const sidewallThicknessLabel = t(
    'stickyMenu.mainDoorTab.from-min-to-max',
    { min: minSidewallThickness, max: maxSidewallThickness },
  );
  const doorsAmountLabel = currentSystem === 'assembling'
    ? t('stickyMenu.mainDoorTab.min-or-max', { min: minDoorsAmount, max: maxDoorsAmount })
    : t('stickyMenu.mainDoorTab.from-min-to-max', { min: minDoorsAmount, max: maxDoorsAmount });

  const frameOpeningHeightLabel = frameFasteningElement?.type === 'lifting-mechanism'
    ? t(
      'stickyMenu.mainDoorTab.from-min-to-max',
      {
        min: currentLiftingMechanismSizes?.minHeight,
        max: currentLiftingMechanismSizes?.maxHeight,
      },
    )
    : (t('stickyMenu.mainDoorTab.from-min',
      {
        min: currentFrameProfileSizes?.minFrameOpeningHeight,
      }));

  const frameOpeningWidthLabel = frameFasteningElement?.type === 'lifting-mechanism'
    ? t(
      'stickyMenu.mainDoorTab.from-min-to-max',
      {
        min: currentLiftingMechanismSizes?.minWidth,
        max: currentLiftingMechanismSizes?.maxWidth,
      },
    )
    : t(
      'stickyMenu.mainDoorTab.from-min',
      {
        min: currentFrameProfileSizes?.minFrameOpeningWidth,
      },
    );

  const invalidChars = ['e', 'E', '+', '-', '.', ','];

  const fasteningElementIcon = {
    'lifting-mechanism': 'src/client/assets/icons/loops/lifting-mechanism.svg',
    'parallel-hinge': 'src/client/assets/icons/loops/parallel-loop.svg',
    'inner-hinge': 'src/client/assets/icons/loops/inner-loop.svg',
    'half-overlap-hinge': 'src/client/assets/icons/loops/half-overlap-loop.svg',
    'overlay-hinge': 'src/client/assets/icons/loops/overlay-loop.svg',
  };


  // Show filling materials of Main Door tab and Doors tabs in one place
  useEffect(() => {
    if (!sideProfile?.value && currentSystem !== 'frame-facades') return;

    const doorsFilling = doors
      .filter((door) => !_.isEmpty(door?.main?.filling))
      .map((door) => door?.main?.filling);

    const sectionsFilling = _.flattenDeep(doors
      .filter((door) => !_.isEmpty(door?.sections))
      .map((door) => door.sections
        .filter((section) => !_.isEmpty(section.filling))
        .map((s) => s?.filling)));

    const allFilling = currentSystem === 'frame-facades'
      ? frameFilling
      : [...doorsFilling, ...sectionsFilling];

    const groupedFilling = !_.isEmpty(allFilling[0]) ? _.groupBy(allFilling, 'material') : {};

    const dspMaterials = _.uniqBy(groupedFilling.dsp, 'dspOption');
    const glassMaterials = _.uniqWith(groupedFilling.glass, _.isEqual);
    const mirrorMaterials = _.uniqBy(groupedFilling.mirror, 'mirrorType');
    const lacobelMaterials = _.uniqBy(groupedFilling.lacobel, 'lacobelType');
    const customersMaterials = _.uniqBy(groupedFilling.customers, 'customersOption');

    setFilling([
      ...dspMaterials,
      ...glassMaterials,
      ...mirrorMaterials,
      ...lacobelMaterials,
      ...customersMaterials,
    ]);

    dispatch(OrderActions.calculateOrderRequest());
  }, [mainDoorFilling, frameFilling, sideProfile?.value]);


  // Set ability to choose symmetrical schema
  useEffect(() => {
    setCanChooseSymmetrical(doorsAmount.value === 4 || doorsAmount.value === 6 || doorsAmount.value === 8);
  }, [
    doorsAmount.value,
    doorPositioning.value,
  ]);


  // Set Monorail max door width
  useEffect(() => {
    if (currentSystem !== 'monorail') return;
    if (doorOpeningWidth?.value && doorOpeningWidth.value < maxDoorWidth) {
      setMaxMonorailDoorWidth(doorOpeningWidth.value);
    }
    if (doorOpeningWidth?.value && doorOpeningWidth.value > maxDoorWidth) {
      setMaxMonorailDoorWidth(maxDoorWidth);
    }
  }, [doorOpeningWidth.value, currentSystem]);


  // Set Side Profile by default
  useEffect(() => {
    if (currentSystem === 'frame-facades') return;
    if (isConfigFetched && !currentOrderId) {
      const spToSet = sideProfile?.value || defaultSideProfile;
      const isCurrentColorAvailable = isColorSideProfileAvailable(aluminiumColor?.value, spToSet, prices);
      dispatch(DoorsActions.updateSideProfile(spToSet, systemConctants, currentSystem, isCurrentColorAvailable, allAvailableFilling));
      dispatch(DoorsActions.updateDoorsSizes(systemConctants, currentSystem, allAvailableFilling));
    }
  }, [currentSystem, systemConctants, isConfigFetched]);

  // Set/Reset values dependent on side profile
  useEffect(() => {
    if (!systemConctants.length) return;
    const colorsToSet = sideProfiles.find((sp) => sp.articleCode === sideProfile?.value)?.colorsDependence || [];
    const mechanismsToSet = systemConctants
      .find((c) => c.sideProfile === sideProfile?.value)?.mechanismsDependence || [];

    setAvailableColors(colorsToSet.filter((color) => isColorSideProfileAvailable(color, sideProfile?.value, prices)));
    setAvailableMechanisms(mechanismsToSet.filter((item) => isMechanismAvailable(item, prices)));

    dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
  }, [sideProfile?.value, prices, systemConctants]);


  // Set values dependent on currentSystem
  useEffect(() => {
    if (currentSystem === 'assembling') {
      setDeprecatedMechanisms([assemblingAdditionalMechanism]);
    }
    if (currentSystem === 'hinged' && doorsAmount.value !== 3) {
      setDeprecatedMechanisms(['дотяг_Tutti', '3_двер_Tutti']);
      dispatch(DoorsActions.toggleMiddleDoorMechanism(false));
      dispatch(DoorsActions
        .updateMainDoor({ name: 'mechanism', value: '2_двер_Tutti' }, systemConctants, currentSystem, allAvailableFilling));
    }
    if (currentSystem === 'hinged' && doorsAmount.value === 3) {
      setDeprecatedMechanisms(['дотяг_Tutti', '2_двер_Tutti']);
      dispatch(DoorsActions
        .updateMainDoor({ name: 'mechanism', value: '3_двер_Tutti' }, systemConctants, currentSystem, allAvailableFilling));
    }
  }, [currentSystem, doorsAmount?.value, sideProfile?.value]);


  // Recalculate min max doors amount because of changing constants
  // dependent on currentSystem, sideProfile, doorPositioning, doorOpeningWidth
  useEffect(() => {
    if (!systemConctants || !systemConctants.length) return;

    dispatch(DoorsActions.setMinDoorsAmount(systemConctants, currentSystem));
    dispatch(DoorsActions.setMaxDoorsAmount(systemConctants, currentSystem));
  }, [
    doorPositioning.value,
    doorOpeningWidth.value,
    systemConctants.length,
    currentSystem,
  ]);


  // Handle doors amount to set it within the range limits specified by the min and max
  useEffect(() => {
    if (!currentSystem || currentSystem !== 'extendable') return;

    // Increase doors amount
    if (doorsAmount.value < minDoorsAmount) {
      dispatch(DoorsActions
        .increaseDoorsAmountRequest(minDoorsAmount - doorsAmount.value, systemConctants, currentSystem, allAvailableFilling));
      dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
      dispatch(OrderActions.calculateOrderRequest());
      return;
    }

    // Decrease doors amount
    if (doorsAmount.value > maxDoorsAmount) {
      dispatch(DoorsActions
        .decreaseDoorsAmountRequest(doorsAmount.value - maxDoorsAmount, systemConctants, currentSystem, allAvailableFilling));
      dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
      dispatch(OrderActions.calculateOrderRequest());
    }
  }, [
    currentSystem,
    minDoorsAmount,
    maxDoorsAmount,
    doorsAmount.value,
  ]);

  // Handle doors position change dependent on doors amount
  useEffect(() => {
    if (doorPositioning.value === 'symmetrical'
      && (doorsAmount.value !== 4 && doorsAmount.value !== 6 && doorsAmount.value !== 8)) {
      dispatch(DoorsActions.updateMainDoor(
        { name: 'doorPositioning', value: 'chessboard' },
        systemConctants,
        currentSystem,
        allAvailableFilling,
      ));
      dispatch(OrderActions.calculateOrderRequest());
    }
  }, [doorsAmount.value]);


  // Handle min and max values for doorOpeningWidth by systemType
  useEffect(() => {
    if (currentSystem === 'opening' && doorsAmount.value === 1) {
      setMinDoorOpeningW(minWidthForSingleOpeningDoor);
      setMaxDoorOpeningW(maxWidthForSingleOpeningDoor);
    }
    if (currentSystem === 'opening' && doorsAmount.value === 2) {
      setMinDoorOpeningW(minWidthForTwoOpeningDoors);
      setMaxDoorOpeningW(maxWidthForTwoOpeningDoors);
    }

    if (currentSystem === 'assembling' && doorsAmount.value === 2 && doorOpeningWidth.value) {
      setMinDoorOpeningW(minDoorOpeningWidth);
      setMaxDoorOpeningW(maxDoorOpeningWidth);
    }
    if (currentSystem === 'assembling' && doorsAmount.value === 4) {
      setMinDoorOpeningW(minWidthFor4AssemblingDoors);
      setMaxDoorOpeningW(maxWidthFor4AssemblingDoors);
    }
  }, [doorsAmount.value]);


  const onDoorsAmountChange = (value) => {
    if (!(doorOpeningHeight?.value && doorOpeningWidth?.value)
      && !_.some(['assembling', 'opening'], (item) => item === currentSystem)) {
      dispatch(DoorsActions.hightlightDoorOpeningInputs(labelKey));
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    if (value < minDoorsAmount || value > maxDoorsAmount) {
      setShouldHighlight(true);
      setTimeout(() => { setShouldHighlight(false); }, 2000);
      return;
    }
    setShouldHighlight(false);

    if (value < doorsAmount.value) {
      dispatch(DoorsActions.removeDoorRequest(doors.length - 1, systemConctants, currentSystem, allAvailableFilling));
      dispatch(OrderActions.calculateOrderRequest());
      return;
    }
    dispatch(DoorsActions.addDoorRequest(systemConctants, currentSystem, currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const handleOptions = (name, options, index) => {
    dispatch(DoorsActions.updateMainDoor({
      name,
      value: options[index]?.value || options[index],
    }, systemConctants, currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const handleFrameOptions = (canChangeHorizontalTexture, texture) => {
    if (canChangeHorizontalTexture) setFrameFillingTexture(texture);
  };

  const handleColorOptions = (articleCode) => {
    dispatch(DoorsActions.updateMainDoor(
      { name: 'aluminiumColor', value: articleCode },
      systemConctants,
      currentSystem,
      allAvailableFilling,
    ));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const handleDropdown = (name, selectedOption) => {
    dispatch(DoorsActions.updateMainDoor({
      name,
      value: selectedOption?.value || '',
    }, systemConctants, currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const handleSubmitMainDoorFilling = () => {
    const mainDoorMaterialToSet = {
      ...fillingMaterialsState[activeTrigger],
      ...{ material: activeTrigger },
    };

    if (currentSystem === 'frame-facades') {
      dispatch(DoorsActions.setFrameFilling(activeDoor, [mainDoorMaterialToSet]));
    } else {
      dispatch(DoorsActions.updateMainDoorFilling(mainDoorMaterialToSet));
      dispatch(OrderActions.calculateOrderRequest());
    }

    dispatch(FillingActions.resetFillingMaterialModal());
  };

  const handleUpdateHeight = ({ target: { value } }) => {
    value = removeLeadingZero(value);
    if (value < 0 || value > maxDoorOpeningHeight) return;

    setHeight({ value });
  };

  const handleUpdateFrameOpeningHeight = ({ target: { value } }) => {
    value = removeLeadingZero(value);

    if (value < 0
      || (frameFasteningElement?.type === 'lifting-mechanism' && value > currentLiftingMechanismSizes?.maxHeight)) return;
    setIsEmptyHeight(false);
    setFrameOpeningH(value);
  };


  const handleUpdateWidth = ({ target: { value } }) => {
    value = removeLeadingZero(value);
    if (value < 0 || value > maxDoorOpeningW) return;

    setWidth({ value });
  };

  const handleUpdateFrameOpeningWidth = ({ target: { value } }) => {
    value = removeLeadingZero(value);

    if (value < 0 || (frameFasteningElement?.type === 'lifting-mechanism' && value > currentLiftingMechanismSizes?.maxWidth)) return;
    setIsEmptyWidth(false);
    setFrameOpeningW(value);
  };

  const handleUpdateMonorailDoorWidth = ({ target: { value } }) => {
    value = removeLeadingZero(value);
    if (value < 0 || value > maxMonorailDoorWidth) return;

    setMonorailDoorWidth({ value });
  };

  const handleUpdateSidewallThickness = ({ target: { value } }) => {
    value = removeLeadingZero(value);
    if (value < 0 || value > maxSidewallThickness) return;

    setSidewallThicknessValue({ value });
  };

  const handleReinforcingProfileItemDistance = (value, sequenceNumber) => {
    value = removeLeadingZero(value);
    const nextReinforcingProfileDistance = (
      frameReinforcingProfileItems[sequenceNumber]?.distance - 100) || frameOpeningH - 100;

    if (value < 0 || value > nextReinforcingProfileDistance) return;

    const itemsToUpdate = frameReinforcingProfileItems.map((item) => {
      if (item.sequenceNumber === sequenceNumber) {
        return { ...item, distance: value };
      }

      return item;
    });

    setFrameReinforcingProfileItems(itemsToUpdate);
  };

  const handleUpdateFrameName = ({ target: { value } }) => {
    setFrameName(value);
  };

  const handleOnBlurFrameName = ({ target: { value } }) => {
    dispatch(DoorsActions.setFrameGroupName(activeDoor, value));
  };

  const onReinforcingProfileAmountChange = (value) => {
    const itemsLength = frameReinforcingProfileItems.length;
    const maxReinforcingProfile = 5;
    const minReinforcingProfile = 0;
    const reinforcingProfileDistance = frameOpeningH / (value + 1);

    if (value > maxReinforcingProfile || value < minReinforcingProfile) return;

    if (itemsLength === 1 && value === 0 && !frameHeightAgreement.agreement && !frameWidthAgreement.agreement && !reinforcingProfileAgreement?.agreementNotToUseReinforcingProfile) {
      return dispatch(DoorsActions.toggleStrengthProfileModal(true));
    }

    if (frameReinforcingProfileAmount < value) {
      const updatedReinforcingProfileItems = frameReinforcingProfileItems
        .map((item, i) => ({ ...item, distance: _.round(reinforcingProfileDistance * (i + 1)) }));

      setFrameReinforcingProfileItems([...updatedReinforcingProfileItems, {
        sequenceNumber: value,
        distance: _.round(reinforcingProfileDistance * (value)),
      }]);
    }

    if (frameReinforcingProfileAmount > value) {
      const updatedReinforcingProfileItems = frameReinforcingProfileItems.slice(0, itemsLength - 1)
        .map((item, i) => ({ ...item, distance: _.round(reinforcingProfileDistance * (i + 1)) }));

      setFrameReinforcingProfileItems(updatedReinforcingProfileItems);
    }

    if (prevReinforcingProfileAmount) {
      setPrevReinforcingProfileAmount(value);
    }

    setFrameMilling(value * 2);
    setFrameStrainer(value);
    setFrameReinforcingProfileAmount(value);
  };

  const onSameFramesAmount = (value) => {
    if (value < 0) return;

    setSameFrame(value);
  };

  const clearFrameState = () => {
    setIsShowAllSizes(true);
    setFrameProfileHeight({});
    setFrameProfileWidth({});
    setFrameConnectingCorner({});
    setFrameOpeningH('');
    setFrameOpeningW('');
    setFrameReinforcingProfileAmount(0);
    setFrameReinforcingProfileItems([]);
    setFrameHolesForHandles({});
    setFrameFasteningElement({});
    setFramePearHoles({});
    setFrameMilling(0);
    setFrameStrainer(0);
    setFrameProfile('');
    setFrameAluminiumColor('');
    setFrameFacadeView('opening');
    setFrameOpeningSide('left');
    setSameFrame(0);
    setReinforcingProfileAgreement({
      needToUseReinforcingProfile: false,
      agreementNotToUseReinforcingProfile: false,
    });
    setFrameHeightAgreement({
      heightLimitExceeded: false,
      agreement: false,
    });
    setFrameWidthAgreement({
      widthLimitExceeded: false,
      agreement: false,
    });
    dispatch(DoorsActions.clearMainFrameState());
    dispatch(FillingActions.resetFillingMaterialModal());
  };

  const onCreateFrames = (groupName) => {
    const existingGroups = doors.length;

    const itemToCreate = {
      sequenceNumber: existingGroups + 1,
      groupName: { value: groupName || t('frameFacades.group-n', { number: existingGroups + 1 }) },
      isShowSizes: isShowAllSizes,
      profileHeight: frameProfileHeight,
      profileWidth: frameProfileWidth,
      connectingCorner: frameConnectingCorner,
      frameOpeningHeight: { value: frameOpeningH },
      frameOpeningWidth: { value: frameOpeningW },
      frameProfile: { value: frameProfile },
      frameAluminiumColor: { value: frameAluminiumColor },
      facadeView: { value: frameFacadeView },
      openingSide: { value: frameOpeningSide },
      filling,
      fillingTexture: { value: frameFillingTexture },
      reinforcingProfile: {
        amount: frameReinforcingProfileAmount,
        items: frameReinforcingProfileItems,
      },
      holesForHandles: frameHolesForHandles,
      pearHoles: framePearHoles,
      fasteningElement: frameFasteningElement,
      millingForReinforcingProfile: { amount: frameMilling },
      strainerForReinforcingProfile: { amount: frameStrainer },
      sameFramesAmount: { value: sameFrame },
      guaranteeAgreement: reinforcingProfileAgreement,
      frameHeightGuaranteeAgreement: frameHeightAgreement,
      frameWidthGuaranteeAgreement: frameWidthAgreement,
    };

    dispatch(DoorsActions.addFrame(itemToCreate));
  };

  const onSubmitGuaranteeAgreement = () => {
    setFrameMilling(0);
    setFrameStrainer(0);
    setFrameReinforcingProfileAmount(0);
    setFrameReinforcingProfileItems([]);
    setReinforcingProfileAgreement({
      ...reinforcingProfileAgreement,
      agreementNotToUseReinforcingProfile: true,
    });
    setPrevReinforcingProfileAmount(0);
    dispatch(DoorsActions.toggleStrengthProfileModal(false));
  };

  const onSubmitFrameSizeGuaranteeAgreement = () => {
    if (frameGuaranteeType === 'height') {
      setFrameHeightAgreement({
        heightLimitExceeded: true,
        agreement: true,
      });
    } else {
      setFrameWidthAgreement({
        widthLimitExceeded: true,
        agreement: true,
      });
    }

    dispatch(DoorsActions.toggleWarningFrameSizeModal(false));
    dispatch(DoorsActions.setFrameGuaranteeType(''));
  };

  const onCloseWarningFrameSizeModal = () => {
    if (frameGuaranteeType === 'height') {
      if (!prevReinforcingProfileAmount) {
        setFrameMilling(0);
        setFrameStrainer(0);
        setFrameReinforcingProfileAmount(0);
        setFrameReinforcingProfileItems([]);
      }

      if (prevFrameHeight < currentFrameProfileSizes?.maxFrameOpeningHeightWithoutReinforcingProfile) {
        setReinforcingProfileAgreement({
          needToUseReinforcingProfile: false,
          agreementNotToUseReinforcingProfile: false,
        });
      }

      setFrameHeightAgreement({
        heightLimitExceeded: false,
        agreement: false,
      });
      dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: prevFrameHeight }));
      setFrameFillingTexture(prevFrameFillingTexture);
      setFrameOpeningH(prevFrameHeight);
    } else {
      setFrameWidthAgreement({
        widthLimitExceeded: false,
        agreement: false,
      });
      dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: prevFrameWidth }));
      setFrameOpeningW(prevFrameWidth);
    }

    dispatch(DoorsActions.toggleWarningFrameSizeModal(false));
    dispatch(DoorsActions.setFrameGuaranteeType(''));
  };

  const sanitizeAndSaveHeight = ({ target: { name, value } }) => {
    const isValid = isValidNumberField(+value, minDoorOpeningHeight, maxDoorOpeningHeight + 1);
    let sanitizedValue = value;

    if (value && isValid) sanitizedValue = +value;
    if (!isValid) {
      sanitizedValue = +value < minDoorOpeningHeight
        ? minDoorOpeningHeight
        : maxDoorOpeningHeight;
      setHeight({ value: sanitizedValue.toString() });
    }

    dispatch(DoorsActions.updateMainDoor({ name, value: sanitizedValue }, systemConctants, currentSystem, allAvailableFilling));
    dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const sanitizeAndSaveWidth = ({ target: { name, value } }) => {
    const isValid = isValidNumberField(+value, minDoorOpeningW, maxDoorOpeningW + 1);
    let sanitizedValue = value;

    if (value && isValid) sanitizedValue = +value;
    if (!isValid) {
      sanitizedValue = +value < minDoorOpeningW
        ? minDoorOpeningW
        : maxDoorOpeningW;
      setWidth({ value: sanitizedValue.toString() });
    }

    dispatch(DoorsActions.updateMainDoor({ name, value: sanitizedValue }, systemConctants, currentSystem, allAvailableFilling));
    dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const sanitizeAndSaveMonorailDoorWidth = ({ target: { name, value } }) => {
    const isValid = isValidNumberField(+value, minDoorOpeningW, maxMonorailDoorWidth + 1);
    let sanitizedValue = value;

    if (value && isValid) sanitizedValue = +value;
    if (!isValid) {
      sanitizedValue = +value < minDoorOpeningW
        ? minDoorOpeningW
        : maxMonorailDoorWidth;

      setMonorailDoorWidth({ value: sanitizedValue.toString() });
    }

    dispatch(DoorsActions.updateMainDoor({ name, value: sanitizedValue }, systemConctants, currentSystem, allAvailableFilling));
    dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const sanitizeAndSaveFrameHeight = ({ target: { value } }) => {
    const {
      minFrameOpeningHeight,
      maxFrameOpeningHeightWithoutReinforcingProfile,
      maxFrameOpeningHeightWithReinforcingProfile,
    } = currentFrameProfileSizes;
    const { minHeight } = currentLiftingMechanismSizes || {};
    const { agreementNotToUseReinforcingProfile, needToUseReinforcingProfile } = reinforcingProfileAgreement;
    const { agreement: heightAgreement, heightLimitExceeded } = frameHeightAgreement;
    const { agreement: widthAgreement, widthLimitExceeded } = frameWidthAgreement;
    setIsEmptyHeight(false);

    if (frameFasteningElement?.type === 'lifting-mechanism' && value < minHeight) {
      setFrameOpeningH(minHeight);
      dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: minHeight }));
      return;
    }

    if (value < minFrameOpeningHeight) {
      if (widthLimitExceeded && !widthAgreement) {
        setFrameWidthAgreement({
          widthLimitExceeded: true,
          agreement: true,
        });
      }

      setFrameOpeningH(minFrameOpeningHeight);
      dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: minFrameOpeningHeight }));
      setFrameMilling(0);
      setFrameStrainer(0);
      setFrameReinforcingProfileAmount(0);
      setFrameReinforcingProfileItems([]);
      setReinforcingProfileAgreement({
        needToUseReinforcingProfile: false,
        agreementNotToUseReinforcingProfile: false,
      });
      setFrameHeightAgreement({
        heightLimitExceeded: false,
        agreement: false,
      });
      setPrevFrameHeight(minFrameOpeningHeight);
      setPrevReinforcingProfileAmount(0);
      return;
    }


    if (value > maxFrameOpeningHeightWithReinforcingProfile) {
      if (!agreementNotToUseReinforcingProfile && !widthAgreement && !heightAgreement) {
        if (!frameReinforcingProfileAmount) {
          onReinforcingProfileAmountChange(1);
        }
        setReinforcingProfileAgreement({
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: false,
        });
        setFrameHeightAgreement({
          heightLimitExceeded: true,
          agreement: false,
        });
        dispatch(DoorsActions.setFrameGuaranteeType('height'));
        dispatch(DoorsActions.toggleWarningFrameSizeModal(true));
      }


      if (!needToUseReinforcingProfile && !heightLimitExceeded && widthAgreement) {
        onReinforcingProfileAmountChange(1);
        setReinforcingProfileAgreement({
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: false,
        });
        setFrameHeightAgreement({
          heightLimitExceeded: true,
          agreement: false,
        });
      }

      if ((agreementNotToUseReinforcingProfile || widthAgreement) && !heightAgreement) {
        setFrameHeightAgreement({
          heightLimitExceeded: true,
          agreement: false,
        });
      }

      dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value }));

      return;
    }


    if (value > maxFrameOpeningHeightWithoutReinforcingProfile) {
      if (!frameReinforcingProfileAmount && !agreementNotToUseReinforcingProfile && !heightAgreement) {
        onReinforcingProfileAmountChange(1);
        setReinforcingProfileAgreement({
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: false,
        });
        setPrevFrameHeight(value);
        setPrevReinforcingProfileAmount(1);
      }
      if (!frameReinforcingProfileAmount && heightAgreement && !widthAgreement) {
        setReinforcingProfileAgreement({
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: true,
        });
        setFrameHeightAgreement({
          heightLimitExceeded: false,
          agreement: false,
        });
      }

      if (frameReinforcingProfileAmount && !agreementNotToUseReinforcingProfile && (!widthAgreement && widthLimitExceeded) && heightAgreement) {
        setFrameWidthAgreement({
          widthLimitExceeded: true,
          agreement: true,
        });
      }

      dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value }));
    }


    if (value >= minFrameOpeningHeight
      && value <= maxFrameOpeningHeightWithoutReinforcingProfile) {
      if (widthLimitExceeded && (agreementNotToUseReinforcingProfile || heightAgreement)) {
        setFrameWidthAgreement({
          widthLimitExceeded: true,
          agreement: true,
        });
      }
      setFrameMilling(0);
      setFrameStrainer(0);
      setFrameReinforcingProfileAmount(0);
      setFrameReinforcingProfileItems([]);
      setReinforcingProfileAgreement({
        needToUseReinforcingProfile: false,
        agreementNotToUseReinforcingProfile: false,
      });
      setPrevReinforcingProfileAmount(0);
      dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value }));
    }

    if (value >= minFrameOpeningHeight
      && value <= maxFrameOpeningHeightWithReinforcingProfile) {
      setFrameHeightAgreement({
        heightLimitExceeded: false,
        agreement: false,
      });
      setPrevFrameHeight(value);
      dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value }));
    }
  };

  const sanitizeAndSaveFrameWidth = ({ target: { value } }) => {
    const { minFrameOpeningWidth, maxFrameOpeningWidth } = currentFrameProfileSizes;
    const { minWidth } = currentLiftingMechanismSizes || {};
    const { agreementNotToUseReinforcingProfile, needToUseReinforcingProfile } = reinforcingProfileAgreement;
    const { agreement: heightAgreement, heightLimitExceeded } = frameHeightAgreement;
    const { agreement: widthAgreement } = frameWidthAgreement;

    if (frameFasteningElement?.type === 'lifting-mechanism' && value < minWidth) {
      setFrameFasteningElement({
        ...frameFasteningElement,
        items: validateAventosHF(minWidth, prevFrameWidth, frameFasteningElement),
      });
      setPrevFrameWidth(minWidth);
      setFrameOpeningW(minWidth);
      dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: minWidth }));
      return;
    }


    if (value < minFrameOpeningWidth) {
      setFrameWidthAgreement({
        widthLimitExceeded: false,
        agreement: false,
      });
      setIsEmptyWidth(false);
      setPrevFrameWidth(minFrameOpeningWidth);
      setFrameOpeningW(minFrameOpeningWidth);
      dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: minFrameOpeningWidth }));

      if (widthAgreement && heightLimitExceeded) {
        setFrameHeightAgreement({
          heightLimitExceeded: true,
          agreement: true,
        });
        return;
      }

      if (widthAgreement && (needToUseReinforcingProfile && !frameReinforcingProfileAmount)) {
        setReinforcingProfileAgreement({
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: true,
        });
        return;
      }

      return;
    }


    if (value > maxFrameOpeningWidth) {
      if (!agreementNotToUseReinforcingProfile && !heightAgreement && !widthAgreement) {
        setFrameWidthAgreement({
          widthLimitExceeded: true,
          agreement: false,
        });
        setIsEmptyWidth(false);
        dispatch(DoorsActions.setFrameGuaranteeType('width'));
        dispatch(DoorsActions.toggleWarningFrameSizeModal(true));
      }

      if (agreementNotToUseReinforcingProfile || heightAgreement) {
        setFrameWidthAgreement({
          widthLimitExceeded: true,
          agreement: false,
        });
        setIsEmptyWidth(false);
      }

      dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value }));

      return;
    }


    if (value >= minFrameOpeningWidth && value <= maxFrameOpeningWidth) {
      if (heightLimitExceeded && !heightAgreement && widthAgreement) {
        setFrameHeightAgreement({
          heightLimitExceeded: true,
          agreement: true,
        });
      }

      if ((needToUseReinforcingProfile && !agreementNotToUseReinforcingProfile && !frameReinforcingProfileAmount) && !heightLimitExceeded && widthAgreement) {
        setReinforcingProfileAgreement({
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: true,
        });
      }
      setFrameWidthAgreement({
        widthLimitExceeded: false,
        agreement: false,
      });
      setPrevFrameWidth(value);
      dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value }));
    }

    setFrameFasteningElement({
      ...frameFasteningElement,
      items: validateAventosHF(value, prevFrameWidth, frameFasteningElement),
    });
  };

  const sanitizeAndSaveSidewallThickness = ({ target: { name, value } }) => {
    const isValid = isValidNumberField(+value, minSidewallThickness, maxSidewallThickness + 1);
    let sanitizedValue = value;

    if (value && isValid) sanitizedValue = +value;
    if (!isValid) {
      sanitizedValue = +value < minSidewallThickness
        ? minSidewallThickness
        : maxSidewallThickness;

      setSidewallThicknessValue({ value: sanitizedValue.toString() });
    }

    dispatch(DoorsActions.updateMainDoor({ name, value: sanitizedValue }, systemConctants, currentSystem, allAvailableFilling));
    dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const sanitizeReinforcingProfileItemDistance = (value, sequenceNumber) => {
    const currentProfileNumber = sequenceNumber - 1;
    const prevReinforcingProfileDistance = (
      Number(frameReinforcingProfileItems[currentProfileNumber - 1]?.distance) + 100) || 100;
    const nextReinforcingProfileDistance = (
      frameReinforcingProfileItems[currentProfileNumber + 1]?.distance - 100) || frameOpeningH - 100;

    if (value < prevReinforcingProfileDistance) {
      value = prevReinforcingProfileDistance;
    }

    if (value > nextReinforcingProfileDistance) {
      value = nextReinforcingProfileDistance;
    }

    const itemsToUpdate = frameReinforcingProfileItems.map((item) => (
      item.sequenceNumber === sequenceNumber ? { ...item, distance: value } : item));

    setFrameReinforcingProfileItems(itemsToUpdate);
  };

  const handleIsX2ProfileOn = () => {
    dispatch(DoorsActions.toggleCarriageProfile(!isX2ProfileOn));
    dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const handleIsX4ProfileOn = () => {
    dispatch(DoorsActions.toggleGuidanceProfile(!isX4ProfileOn));
    dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  const handleIsMiddleDoorMechanismOn = () => {
    dispatch(DoorsActions.toggleMiddleDoorMechanism(!isMiddleDoorMechanismOn));
    dispatch(DoorsActions.updateDoorsSizes(systemConctants || [], currentSystem, allAvailableFilling));
    dispatch(OrderActions.calculateOrderRequest());
  };

  // Check if all chipboards are horizontal or vertical
  const textureChecker = (textureVal) => {
    const checker = doors.map((d) => {
      if (!d.sections?.length) return d.main.texture?.value === textureVal;
      else {
        const materialS = d.sections.filter((s) => allAvailableFilling.find((f) => f.articleCode === s.filling?.[materialType?.[s.filling?.material]])?.isTexture)?.length;
        const materialSTexture = d.sections
          .filter((s) => allAvailableFilling.find((f) => f.articleCode === s.filling?.[materialType?.[s.filling?.material]])?.isTexture && s.texture?.value === textureVal)?.length;
        return materialSTexture === materialS;
      }
    });

    return !_.some(checker, (s) => s === false);
  };

  const isMainFillingHasTexture = () => (currentSystem === 'frame-facades'
    ? allAvailableFilling.find((f) => f.articleCode === frameFilling[0]?.[materialType?.[frameFilling[0]?.material]])?.isTexture
    : allAvailableFilling.find((f) => f.articleCode === mainDoorFilling?.[materialType?.[mainDoorFilling?.material]])?.isTexture);

  const atLeastOneMaterialWithTexture = () => {
    const materialsWithTexture = doors.map((d) => {
      if (!d.sections?.length) {
        return allAvailableFilling.find((f) => f.articleCode === d.main?.filling[materialType[d.main?.filling?.material]])?.isTexture;
      }

      return d.sections.filter((s) => allAvailableFilling.find((f) => f.articleCode === s.filling[materialType[s.filling?.material]])?.isTexture).length > 0;
    });

    return _.some(materialsWithTexture, (s) => s === true);
  };

  const changeFrameMaterialTexture = (height) => {
    const fillingArticleCode = frameFilling[0]?.[materialType?.[frameFilling[0]?.material]];

    if (!frameFilling[0]?.material || !allAvailableFilling.find((f) => f.articleCode === fillingArticleCode)?.isTexture) return;

    const frameProfileParameter = frameProfileParameters[frameProfile];
    const doorFillingHeight = (frameFasteningElement?.liftingMechanismType === 'aventos_hf_top' && frameFasteningElement?.withBottomModule)
      ? (Number(height) / 2) - frameProfileParameter?.size
      : Number(height) - frameProfileParameter?.size;

    const updatedTexture = frameFillingTexture === 'horizontal' && !canUseHorizontalTexture(allAvailableFilling, fillingArticleCode, doorFillingHeight)
      ? 'vertical'
      : frameFillingTexture;

    setFrameFillingTexture(updatedTexture);
  };

  const doorPositioningChoice = () => {
    if (currentSystem === 'hinged') {
      return hingedDoorPositioningOptions.map((item, index) => {
        const isChecked = doorPositioning?.value === item;

        return (
          <RadioOption
            key={item}
            className={clsx(
              'text-button',
              isChecked && 'checked',
              index === 0 ? 'left' : 'right',
            )}
            name={`${item}-doors-position`}
            label={t(`options:hingedDoorPositioningOptions.${item}`)}
            checked={isChecked}
            isDisabled={doorsAmount?.value > 2}
            onChange={() => handleOptions('doorPositioning', hingedDoorPositioningOptions, index)}
          />
        );
      });
    }

    if (currentSystem === 'frame-facades') {
      const openingSideToRender = ['left', 'right'];

      return openingSideToRender.map((item) => {
        const isChecked = frameOpeningSide === item;

        return (
          <RadioOption
            key={item}
            className={clsx(
              'text-button',
              isChecked && 'checked',
            )}
            name={`${item}-frame-opening-side`}
            label={t(`options:frameFacadesOpeningSide.${item}`)}
            checked={isChecked}
            onChange={() => {
              setFrameOpeningSide(item);
              if (frameHolesForHandles.placementSide !== 'top' && frameHolesForHandles.placementSide !== 'bottom') {
                setFrameHolesForHandles({ ...frameHolesForHandles, placementSide: item === 'left' ? 'right' : 'left' });
              }
            }}
          />
        );
      });
    }

    return doorPositioningOptions.map((item, index) => {
      const isChecked = doorPositioning?.value === item.value;

      return (
        <RadioOption
          key={item.value}
          className={clsx(
            'icon',
            isChecked && 'checked',
            item.value === 'symmetrical' && !canChooseSymmetrical && 'disabled',
            index === 0 ? 'chessboard' : 'symmetrically',
          )}
          iconPath={item.iconPath}
          name={`${item.value}-doors-position`}
          label={t(`options:doorPositioningOptions.${item.value}`)}
          checked={isChecked}
          isDisabled={item.value === 'symmetrical' && !canChooseSymmetrical}
          onChange={() => handleOptions('doorPositioning', doorPositioningOptions, index)}
        />
      );
    });
  };

  const textureChoice = textures.map((item, index) => {
    const isChecked = currentSystem === 'frame-facades'
      ? frameFillingTexture === item.value
      : index === 0
        ? texture?.value === item.value && textureChecker('vertical')
        : texture?.value === item.value && textureChecker('horizontal');

    const frameProfileParameter = frameProfileParameters[frameProfile];

    const doorFillingHeight = currentSystem !== 'frame-facades'
      ? doorFillingHeightForChipboard(sideProfile?.value, doorsHeight, systemConctants, currentSystem)
      : (frameFasteningElement?.liftingMechanismType === 'aventos_hf_top' && frameFasteningElement?.withBottomModule)
        ? (Number(frameOpeningH) / 2) - frameProfileParameter?.size
        : Number(frameOpeningH) - frameProfileParameter?.size;

    const hasDoorsWithSections = _.some(doors, (d) => d.sections?.length);
    const someDoorHasProperSection = _.some(doors, (d) => {
      if (!d.sections?.length) return false;
      return _.some(d.sections, (s) => {
        const fillingArticleCode = s?.filling?.[materialType?.[s?.filling?.material]];
        return allAvailableFilling.find((f) => f.articleCode === fillingArticleCode)?.isTexture
          && canUseHorizontalTexture(allAvailableFilling, fillingArticleCode, s?.fillingHeight?.value);
      });
    });

    const canChangeHorizontalTexture = () => doors
      .filter((d) => {
        const fillingArticleCode = d.main?.filling?.[materialType?.[d.main?.filling?.material]];
        return allAvailableFilling.find((f) => f.articleCode === fillingArticleCode)?.isTexture && canUseHorizontalTexture(allAvailableFilling, fillingArticleCode, doorFillingHeight);
      })?.length >= 1;

    const canChangeHorizontalTextureForFrame = () => {
      const fillingArticleCode = frameFilling[0]?.[materialType?.[frameFilling[0]?.material]];
      return canUseHorizontalTexture(allAvailableFilling, fillingArticleCode, doorFillingHeight);
    };

    const isDisabledTextureChoice = () => (currentSystem === 'frame-facades'
      ? !canChangeHorizontalTextureForFrame()
      : hasDoorsWithSections
        ? !someDoorHasProperSection
        : !canChangeHorizontalTexture());

    return (
      <RadioOption
        key={item.value}
        className={clsx(
          'icon',
          isChecked && 'checked',
          index === 0 ? 'vertical' : 'horizontal',
        )}
        iconPath={item.iconPath}
        name={`texture-${item.value}`}
        label={t(`options:textures.${item.value}`)}
        checked={isChecked}
        onChange={() => (currentSystem === 'frame-facades'
          ? handleFrameOptions(canChangeHorizontalTextureForFrame(), item.value)
          : handleOptions('texture', textures, index))}
        isDisabled={index > 0 && (isDisabledTextureChoice())}
      />
    );
  });

  const stopperChoice = stoppers.map((item, index) => {
    const isChecked = stopper?.value === item.value;

    return (
      <RadioOption
        key={item.value}
        className={clsx(
          'text-button',
          isChecked && 'checked',
          index === 0 ? 'left' : 'right',
        )}
        name={`stopper-${index}`}
        label={t(`options:stoppers.${item.value}`)}
        checked={isChecked}
        onChange={() => handleOptions('stopper', stoppers, index)}
      />
    );
  });

  useEffect(() => {
    if (currentSystem === 'frame-facades') return; // temporary solution
    if (aluminiumColor.value) {
      return handleColorOptions(aluminiumColor.value);
    }
    const defaultColorCode = aluminiumColors.find(({ articleCode }) => availableColors.includes(articleCode))
      ?.articleCode;
    handleColorOptions(defaultColorCode);
  }, [aluminiumColors, availableColors, aluminiumColor.value]);

  const aluminiumColorChoice = aluminiumColors
    .filter(({ articleCode }) => availableColors.includes(articleCode))
    .map((item) => {
      const articleCode = item?.articleCode;
      const isChecked = aluminiumColor?.value === articleCode;
      const isDisabled = !availableColors.includes(articleCode);

      return (
        <RadioOption
          key={articleCode}
          className={clsx(
            'color',
            isChecked && !isDisabled && 'checked',
            isDisabled && 'disabled',
          )}
          backgroundColor={item.color}
          name={`aluminium-color-${articleCode}`}
          label={item[labelKey]}
          checked={isChecked && !isDisabled}
          onChange={() => handleColorOptions(articleCode)}
          isDisabled={isDisabled}
        />
      );
    });


  const frameAluminiumColorChoice = aluminiumColors
    .filter(({ articleCode }) => availableFrameColors.includes(articleCode))
    .map((item) => {
      const articleCode = item?.articleCode;
      const isChecked = frameAluminiumColor === articleCode;
      const isDisabled = !availableFrameColors.includes(articleCode);

      return (
        <RadioOption
          key={articleCode}
          className={clsx(
            'color',
            isChecked && !isDisabled && 'checked',
            isDisabled && 'disabled',
          )}
          backgroundColor={item.color}
          name={`aluminium-color-${articleCode}`}
          label={item[labelKey]}
          checked={isChecked && !isDisabled}
          isDisabled={isDisabled}
          onChange={() => setFrameAluminiumColor(articleCode)}
        />
      );
    });


  const renderMonorailDoorWidth = () => {
    if (currentSystem !== 'monorail') return null;

    return (
      <div className="main-tab--item-group">
        <div className="main-tab--item-group-caption">
          <p className="main-tab--item-group-caption-title">
            {t('stickyMenu.mainDoorTab.monorail-door-width')}
          </p>
          <p className="main-tab--item-group-caption-subtitle">{monorailDoorWidthLabel}</p>
        </div>
        <Input
          inputRef={inputRefMonoW}
          className="small"
          type="number"
          min={Number(minDoorOpeningWidth)}
          max={Number(maxMonorailDoorWidth)}
          placeholder="0"
          direction="rtl"
          value={monorailDoorWidth?.value ?? ''}
          onChange={handleUpdateMonorailDoorWidth}
          onBlur={sanitizeAndSaveMonorailDoorWidth}
          onKeyDown={(e) => {
            blockInvalidChar(e, invalidChars);
            if (e.keyCode === 13) inputRefMonoW.current.blur();
          }}
          key="monorailSingleDoorWidth"
          name="monorailSingleDoorWidth"
          error={monorailSingleDoorWidth?.error}
          isDisabled={!doorOpeningWidth.value}
        />
      </div>
    );
  };

  const renderSidewallThickness = () => {
    if (currentSystem !== 'hinged') return null;

    return (
      <div className="main-tab--item-group">
        <div className="main-tab--item-group-caption">
          <p className="main-tab--item-group-caption-title">
            {t('stickyMenu.mainDoorTab.sidewall-thickness')}
          </p>
          <p className="main-tab--item-group-caption-subtitle">{sidewallThicknessLabel}</p>
        </div>
        <Input
          inputRef={inputRefSidewallThickness}
          className="small"
          type="number"
          min={minSidewallThickness}
          max={maxSidewallThickness}
          placeholder="0"
          direction="rtl"
          value={sidewallThicknessValue?.value ?? ''}
          onChange={handleUpdateSidewallThickness}
          onBlur={sanitizeAndSaveSidewallThickness}
          onKeyDown={(e) => {
            blockInvalidChar(e, invalidChars);
            if (e.keyCode === 13) inputRefSidewallThickness.current.blur();
          }}
          key="sidewallThickness"
          name="sidewallThickness"
          error={sidewallThickness?.error}
        />
      </div>
    );
  };

  const renderMonorailCheckboxes = () => {
    if (currentSystem !== 'monorail') return null;

    return (
      <>
        <div className="main-tab--item-group">
          <Checkbox
            isChecked={isX2ProfileOn}
            label={t('stickyMenu.mainDoorTab.x2Profile')}
            onChange={handleIsX2ProfileOn}
            key="isX2ProfileOn"
            name="isX2ProfileOn"
          />
        </div>
        <div className="main-tab--item-group">
          <Checkbox
            isChecked={isX4ProfileOn}
            label={t('stickyMenu.mainDoorTab.x4Profile')}
            onChange={handleIsX4ProfileOn}
            key="isX4ProfileOn"
            name="isX4ProfileOn"
          />
        </div>
      </>
    );
  };

  const filteredOptions = useMemo(
    () => sideProfiles.filter(
      ({ colorsDependence, articleCode, isHidden }) => colorsDependence.some((item) => (!isHidden
        ? isColorSideProfileAvailable(item, articleCode, prices)
        : null)),
    ),
    [sideProfiles, prices, isHiddenPrice],
  );

  const inputClassNameWidth = clsx('small', isEmptyWidth && 'isEmpty');
  const inputClassNameHeight = clsx('small', isEmptyHeight && 'isEmpty');
  const inputClassNameFrameName = clsx(!frameName && 'isEmpty');
  const checkInput = () => {
    if (!frameOpeningH && !frameOpeningW) {
      setIsEmptyHeight(true);
      setIsEmptyWidth(true);
    } else if (!frameOpeningH) {
      setIsEmptyHeight(true);
    } else {
      setIsEmptyWidth(true);
    }
  };

  const renderDistanceToReinforcingProfile = () => {
    if (currentSystem !== 'frame-facades') return null;

    return (
      <>
        {frameReinforcingProfileItems?.map(({ sequenceNumber, distance }) => (
          <div key={`reinforcing-profile${sequenceNumber}`} className={clsx('main-tab--item-group facades')}>
            <div className="main-tab--item-group-caption">
              <p className="main-tab--item-group-caption-title">
                {t('stickyMenu.mainDoorTab.distance-to-reinforcing-profile')}
              </p>
            </div>
            <Input
              inputRef={inputRefH}
              className="small"
              type="number"
              placeholder="0"
              direction="rtl"
              value={distance || ''}
              onChange={(e) => handleReinforcingProfileItemDistance(e.target.value, sequenceNumber)}
              onBlur={(e) => {
                sanitizeReinforcingProfileItemDistance(e.target.value, sequenceNumber);
                dispatch(DoorsActions.clearFrameActiveInput());
              }}
              onFocus={() => dispatch(DoorsActions.setFrameActiveInput(
                { name: 'reinforcingProfile', number: sequenceNumber },
              ))}
              onKeyDown={(e) => {
                blockInvalidChar(e, invalidChars);
                if (e.keyCode === 13) {
                  inputRefH.current.blur();
                  inputRefW.current.focus();
                }
              }}
              key={`reinforcingProfileItem-${sequenceNumber}`}
              name={`reinforcingProfileItem-${sequenceNumber}`}
            />
          </div>
        ))}
      </>
    );
  };

  const renderActionButtons = () => {
    const { type } = frameFasteningElement || {};

    const copyItems = [
      {
        value: t('stickyMenu.door.copy'),
        id: 'copy',
      },
      {
        value: t('stickyMenu.door.copy-vertical'),
        id: 'vertical',
      },
      {
        value: t('stickyMenu.door.copy-horizontal'),
        id: 'horizontal',
      },
    ];

    const onCopyFrame = (copyType, frame) => {
      let frameToUpdate = frame;

      if (copyType === 'vertical') {
        frameToUpdate = onMirrorVertical(frame);
      }

      if (copyType === 'horizontal') {
        frameToUpdate = onMirrorHorizontal(frame);
      }

      dispatch(DoorsActions.invertFrame(true));
      dispatch(DoorsActions.addFrame({
        ...frameToUpdate,
        sequenceNumber: doors?.length + 1,
        groupName: { value: `${frameToUpdate?.groupName?.value} ${t('stickyMenu.door.copy-label')}` },
      }));
    };

    return (
      <>
        <p className="title">
          {t('stickyMenu.mainDoorTab.frame-number', { number: activeDoor })}
        </p>

        <div className="buttons-wrapper">
          <div className="copy">
            <button
              className="copy-button"
              type="button"
              onClick={() => {
                if (type === 'lifting-mechanism') {
                  onCopyFrame('copy', doors[activeDoor - 1]);
                  return;
                }
                setIsOpenCopy(!isOpenCopy);
              }}
            >
              <ReactSVG
                wrapper="div"
                className="copy-icon"
                src="/src/client/assets/icons/copy.svg"
              />
              <span
                className="copy-title"
              >
                {t('stickyMenu.door.copy')}
              </span>
              <ReactSVG
                wrapper="div"
                className={clsx('copy-triangle', isOpenCopy && 'open', type === 'lifting-mechanism' && 'hide')}
                src="/src/client/assets/icons/triangle.svg"
              />
            </button>
            <div className={clsx('copy-list', isOpenCopy && 'open')} ref={copyDropdownRef}>
              {copyItems.map((item) => (
                <span
                  key={item.id}
                  role="button"
                  tabIndex="0"
                  className="copy-item"
                  onClick={() => {
                    onCopyFrame(item.id, doors[activeDoor - 1]);
                    setIsOpenCopy(false);
                  }}
                >
                  {item.value}
                </span>
              ))}
            </div>
          </div>

          <button
            className="delete-button"
            type="button"
            onClick={() => {
              dispatch(DoorsActions.setActiveDoor(activeDoor - 1));
              dispatch(DoorsActions.deleteFrame(activeDoor));
            }}
          >
            <ReactSVG
              wrapper="div"
              src="/src/client/assets/icons/trash.svg"
              className="delete-icon"
            />
          </button>
        </div>
      </>
    );
  };

  return (
    <div
      className={clsx('tab-content', 'main-tab',
        (currentSystem === 'frame-facades' && windowSize?.width < 1024) && 'extended')}
      key="door-main-tab"
    >
      <div className="main-tab--inner">
        {currentSystem !== 'frame-facades'
          ? (
            <>
              <div className="main-tab--item-group">
                <div className="main-tab--item-group-caption">
                  <p className="main-tab--item-group-caption-title">
                    {t('stickyMenu.mainDoorTab.aperture-height')}
                  </p>
                  <p className="main-tab--item-group-caption-subtitle">{doorOpeningHeightLabel}</p>
                </div>
                <Input
                  inputRef={inputRefH}
                  className="small"
                  type="number"
                  min={minDoorOpeningHeight}
                  max={maxDoorOpeningHeight}
                  placeholder="0"
                  direction="rtl"
                  value={height?.value ?? ''}
                  onChange={handleUpdateHeight}
                  onBlur={sanitizeAndSaveHeight}
                  onKeyDown={(e) => {
                    blockInvalidChar(e, invalidChars);
                    if (e.keyCode === 13) {
                      inputRefH.current.blur();
                      inputRefW.current.focus();
                    }
                  }}
                  key="doorOpeningHeight"
                  name="doorOpeningHeight"
                  error={doorOpeningHeight?.error}
                />
              </div>
              <div className="main-tab--item-group">
                <div className="main-tab--item-group-caption">
                  <p className="main-tab--item-group-caption-title">
                    {t('stickyMenu.mainDoorTab.aperture-width')}
                  </p>
                  <p className="main-tab--item-group-caption-subtitle">{doorOpeningWidthLabel}</p>
                </div>
                <Input
                  inputRef={inputRefW}
                  className="small"
                  type="number"
                  min={minDoorOpeningW}
                  max={maxDoorOpeningW}
                  placeholder="0"
                  direction="rtl"
                  value={width?.value ?? ''}
                  onChange={handleUpdateWidth}
                  onBlur={sanitizeAndSaveWidth}
                  onKeyDown={(e) => {
                    blockInvalidChar(e, invalidChars);
                    if (e.keyCode !== 13) return;
                    inputRefW.current.blur();
                    if (currentSystem === 'monorail') inputRefMonoW.current.focus();
                  }}
                  key="doorOpeningWidth"
                  name="doorOpeningWidth"
                  error={doorOpeningWidth?.error}
                />
              </div>
              {renderMonorailDoorWidth()}
              {renderSidewallThickness()}
              <div className="main-tab--item-group">
                <div className="main-tab--item-group-caption">
                  <p className="main-tab--item-group-caption-title">
                    {t('stickyMenu.mainDoorTab.number-of-doors')}
                  </p>
                  <p className={clsx('main-tab--item-group-caption-subtitle', shouldHighlight && 'highlighted')}>
                    {doorsAmountLabel}
                  </p>
                </div>
                <PlusMinusControl
                  amount={doorsAmount.value}
                  name="doorsAmount"
                  setAmount={onDoorsAmountChange}
                />
              </div>

              {!_.some(['monorail', 'assembling'], (item) => item === currentSystem)
                && (
                  <>
                    <Label
                      value={t('stickyMenu.mainDoorTab.door-layout')}
                      infoTagValue={t('tooltips.door-layout')}
                      htmlFor="radio-group icons"
                      withInfoTag
                    />
                    <RadioGroup className={currentSystem === 'hinged' ? 'text-button' : 'icons'}>
                      {doorPositioningChoice()}
                    </RadioGroup>
                  </>
                )}

              <Label
                value={t('stickyMenu.mainDoorTab.side-profile')}
                infoTagValue={t('tooltips.side-profile')}
                withInfoTag
              />
              <Dropdown
                placeholder={t('stickyMenu.mainDoorTab.choose-profile')}
                isClearable={false}
                options={filteredOptions
                  .filter((sp) => sp.colorsDependence.length)
                  .map((sp) => ({
                    value: sp.articleCode,
                    label: sp.articleCode,
                    image: sp.image,
                  }))}
                onChange={(selectedOption) => {
                  if (!selectedOption?.value) return;
                  const isCurrentColorAvailable = isColorSideProfileAvailable(
                    aluminiumColor?.value,
                    selectedOption?.value,
                    prices,
                  );
                  dispatch(DoorsActions
                    .updateSideProfile(selectedOption?.value, systemConctants, currentSystem, isCurrentColorAvailable, allAvailableFilling));
                  handleColorOptions('');
                  dispatch(OrderActions.calculateOrderRequest());
                }}
                value={
                  filteredOptions.length && sideProfile?.value
                    ? sideProfiles.find((item) => item.articleCode === sideProfile?.value)
                    : null
                }
              />

              <Label
                value={t('stickyMenu.mainDoorTab.aluminum-profile-color')}
                infoTagValue={t('tooltips.aluminum-profile-color')}
                withInfoTag
              />
              {
                aluminiumColorChoice.length
                  ? (
                    <RadioGroup className="color">{aluminiumColorChoice}</RadioGroup>
                  ) : (
                    <div className="label--secondary">
                      {t('stickyMenu.mainDoorTab.no-available-aluminum-profiles')}
                    </div>
                  )
              }

              {renderMonorailCheckboxes()}

              <Label
                value={t('stickyMenu.mainDoorTab.mechanism')}
                infoTagValue={t('tooltips.mechanism')}
                withInfoTag
              />
              <Dropdown
                placeholder={t('stickyMenu.mainDoorTab.choose-mechanism')}
                hasInternalTranslation
                isDisabled={!sideProfile?.value}
                options={mechanisms?.length
                  ? mechanisms
                    .filter((m) => availableMechanisms.indexOf(m.articleCode) !== -1)
                    .filter((m) => deprecatedMechanisms.indexOf(m.articleCode) === -1)
                    .map((m) => ({
                      value: m.articleCode,
                      labelRu: m.labelRu || '',
                      labelUk: m.labelUk || '',
                      labelPl: m.labelPl || '',
                      labelEn: m.labelEn || '',
                      image: m.image || '',
                    }))
                  : []}
                onChange={(selectedOption) => {
                  if (!selectedOption?.value) return;
                  handleDropdown('mechanism', selectedOption);
                }}
                value={
                  (mechanism.value && mechanisms
                    && mechanisms[mechanisms?.findIndex((m) => m.articleCode === mechanism.value)]
                  ) || null
                }
              />
              {currentSystem === 'hinged' && doorsAmount.value === 3
                ? (
                  <>
                    <br />
                    <div className="main-tab--item-group">
                      <Checkbox
                        isChecked={isMiddleDoorMechanismOn}
                        label={t('stickyMenu.mainDoorTab.middle-door-mechanism')}
                        onChange={handleIsMiddleDoorMechanismOn}
                        key="isMiddleDoorMechanismOn"
                        name="isMiddleDoorMechanismOn"
                      />
                    </div>
                  </>
                ) : null}

              {(currentSystem === 'extendable' || currentSystem === 'monorail')
                && (
                  <div className="main-section--row-space-between">
                    <Label value={t('stickyMenu.mainDoorTab.stopper')} />
                    <Switch
                      className="door-latch-mechanism"
                      isToggled={isStopperOn}
                      setToggleValue={() => {
                        dispatch(DoorsActions.toggleStopper(!isStopperOn, sideProfile?.value, systemConctants));
                        dispatch(OrderActions.calculateOrderRequest());
                      }}
                    />
                  </div>
                )}

              {(currentSystem === 'extendable' || currentSystem === 'monorail') && isStopperOn
                ? (
                  <RadioGroup className="text-button">{stopperChoice}</RadioGroup>
                ) : null}

              <FillingMaterialsControl
                label={t('stickyMenu.mainDoorTab.filling-material')}
                filling={filling}
                onClick={() => {
                  if (!areDoorsAndSectionsWithoutFilling(doors)) {
                    dispatch(DoorsActions.setActiveDoor(1));
                  }
                  dispatch(FillingActions.toggleFillingMaterialModal(true));
                }}
              />

              {isMainFillingHasTexture() || atLeastOneMaterialWithTexture()
                ? (
                  <>
                    <Label
                      value={t('stickyMenu.mainDoorTab.texture')}
                      infoTagValue={t('tooltips.texture')}
                      withInfoTag
                    />
                    <RadioGroup className="icons">
                      {textureChoice}
                    </RadioGroup>
                  </>
                ) : null}
            </>
          )
          : (
            <>
              <div className={clsx('main-tab--item-group facades desktop')}>
                <Label
                  value={t('stickyMenu.mainDoorTab.show-all-sizes')}
                  infoTagValue={isShowAllSizes ? t('tooltips.size-on') : t('tooltips.size-off')}
                  withInfoTag
                />
                <Switch
                  className="door-latch-mechanism"
                  isToggled={isShowAllSizes}
                  setToggleValue={() => { setIsShowAllSizes(!isShowAllSizes); }}
                />
              </div>

              {activeDoor !== 0 && (
                <>
                  <div className={clsx('main-tab--mobile-group')}>
                    {renderActionButtons()}
                  </div>

                  <div className={clsx('main-tab--item-group facades name')}>
                    <Label
                      value={t('stickyMenu.mainDoorTab.frame-name')}
                    />
                    <Input
                      className={inputClassNameFrameName}
                      value={frameName}
                      onChange={handleUpdateFrameName}
                      onBlur={handleOnBlurFrameName}
                      key="frameName"
                      name="frameName"
                    />
                  </div>
                </>
              )}

              <div className={clsx('main-tab--item-group facades with-item')}>
                <Label
                  value={t('stickyMenu.mainDoorTab.profile')}
                  infoTagValue={t('tooltips.profile')}
                  withInfoTag
                />
                <Dropdown
                  placeholder={t('stickyMenu.mainDoorTab.select-profile')}
                  isClearable={false}
                  options={frameProfiles
                    .filter((sp) => sp.colorsDependence.length)
                    .map((sp) => ({
                      value: sp.articleCode,
                      label: sp.articleCode,
                      image: sp.image,
                    }))}
                  onChange={(selectedOption) => {
                    if (!selectedOption?.value) return;
                    setFrameProfile(selectedOption?.value);
                    setFrameAluminiumColor(getColor(selectedOption?.value));
                  }}
                  value={
                    frameProfiles.length && frameProfile
                      ? frameProfiles.find((item) => item.articleCode === frameProfile)
                      : null
                  }
                />
              </div>

              <div className={clsx('main-tab--item-group facades')}>
                <div className="main-tab--item-group-caption">
                  <p className="main-tab--item-group-caption-title">
                    {t('stickyMenu.mainDoorTab.frame-height')}
                  </p>
                  <p className="main-tab--item-group-caption-subtitle">
                    {frameOpeningHeightLabel}
                  </p>
                </div>
                <Input
                  inputRef={inputRefH}
                  className={inputClassNameHeight}
                  type="number"
                  min={frameFasteningElement?.type === 'lifting-mechanism'
                    ? currentLiftingMechanismSizes?.minHeight
                    : currentFrameProfileSizes?.minFrameOpeningHeight}
                  max={frameFasteningElement?.type === 'lifting-mechanism' && currentLiftingMechanismSizes?.maxHeight}
                  placeholder="0"
                  direction="rtl"
                  value={frameOpeningH ?? ''}
                  onChange={handleUpdateFrameOpeningHeight}
                  onBlur={(e) => {
                    setPrevFrameFillingTexture(frameFillingTexture);
                    changeFrameMaterialTexture(frameOpeningH);
                    sanitizeAndSaveFrameHeight(e);
                  }}
                  onKeyDown={(e) => {
                    blockInvalidChar(e, invalidChars);
                    if (e.keyCode === 13) {
                      inputRefH.current.blur();
                      inputRefW.current.focus();
                    }
                  }}
                  key="frameOpeningHeight"
                  name="frameOpeningHeight"
                />
              </div>

              {frameReinforcingProfileAmount > 0 && (
                <>
                  <div className={clsx('main-tab--item-group facades')}>
                    <div className="main-tab--item-group-caption">
                      <Label
                        value={t('stickyMenu.mainDoorTab.reinforcing-profile')}
                        infoTagValue={t('tooltips.reinforcing-profile')}
                        withInfoTag
                      />
                      <p className="main-tab--item-group-caption-subtitle">
                        {t('stickyMenu.mainDoorTab.max-amount', { maxAmount: 5 })}
                      </p>
                    </div>
                    <PlusMinusControl
                      amount={frameReinforcingProfileAmount}
                      name="reinforcingProfileAmount"
                      setAmount={onReinforcingProfileAmountChange}
                    />
                  </div>

                  {renderDistanceToReinforcingProfile()}
                </>
              )}

              <div className={clsx('main-tab--item-group facades')}>
                <div className="main-tab--item-group-caption">
                  <p className="main-tab--item-group-caption-title">
                    {t('stickyMenu.mainDoorTab.frame-width')}
                  </p>
                  <p className="main-tab--item-group-caption-subtitle">
                    {frameOpeningWidthLabel}
                  </p>
                </div>
                <Input
                  inputRef={inputRefW}
                  className={inputClassNameWidth}
                  type="number"
                  min={frameFasteningElement?.type === 'lifting-mechanism'
                    ? currentLiftingMechanismSizes?.minWidth
                    : currentFrameProfileSizes?.minFrameOpeningWidth}
                  max={frameFasteningElement?.type === 'lifting-mechanism' && currentLiftingMechanismSizes?.maxWidth}
                  placeholder="0"
                  direction="rtl"
                  value={frameOpeningW ?? ''}
                  onChange={handleUpdateFrameOpeningWidth}
                  onBlur={sanitizeAndSaveFrameWidth}
                  onKeyDown={(e) => {
                    blockInvalidChar(e, invalidChars);
                    if (e.keyCode === 13) {
                      inputRefW.current.blur();
                    }
                  }}
                  key="frameOpeningWidth"
                  name="frameOpeningWidth"
                />
              </div>
              <div className={clsx('main-tab--item-group facades with-item')}>
                <Label
                  value={t('stickyMenu.mainDoorTab.profile-color')}
                  infoTagValue={t('stickyMenu.mainDoorTab.profile-color')}
                  withInfoTag
                />
                {
                  frameAluminiumColorChoice.length
                    ? (
                      <RadioGroup className="color">{frameAluminiumColorChoice}</RadioGroup>
                    ) : (
                      <div className={clsx('label--secondary facades')}>
                        {t('stickyMenu.mainDoorTab.no-available-profiles')}
                      </div>
                    )
                }

              </div>

              <FillingMaterialsControl
                label={t('stickyMenu.mainDoorTab.filling-material')}
                filling={filling}
                onClick={() => dispatch(FillingActions.toggleFillingMaterialModal(true))}
              />

              {isMainFillingHasTexture()
                ? (
                  <>
                    <Label
                      value={t('stickyMenu.mainDoorTab.texture')}
                      infoTagValue={t('tooltips.texture')}
                      withInfoTag
                    />
                    <RadioGroup className="icons">
                      {textureChoice}
                    </RadioGroup>
                  </>
                ) : null}

              {frameFacadeView !== 'fixed'
                && (
                  <>
                    <div className={clsx('main-tab--item-group facades')}>
                      <Label
                        value={t('stickyMenu.mainDoorTab.opening-side')}
                        infoTagValue={t('stickyMenu.mainDoorTab.opening-side')}
                        htmlFor="radio-group icons"
                      />
                      <Tooltip content={t('tooltips.open-side')} direction="left">
                        <ReactSVG
                          wrapper="div"
                          className="opening-side-icon"
                          src={frameFasteningElement?.type === 'lifting-mechanism'
                            ? '/src/client/assets/icons/arrow-split-vertical.svg'
                            : '/src/client/assets/icons/arrow-split-horizontal.svg'}
                        />
                      </Tooltip>
                    </div>

                    {frameFasteningElement?.type === 'lifting-mechanism'
                      ? (
                        <div className="main-tab--warning">
                          <div className="main-tab--warning-content">
                            {t('stickyMenu.mainDoorTab.warning-opening-side')}
                          </div>
                          <ReactSVG
                            wrapper="div"
                            className="main-tab--warning-icon"
                            src="/src/client/assets/icons/icon-warning.svg"
                          />
                        </div>
                      )
                      : (
                        <RadioGroup className={(currentSystem === 'hinged' || currentSystem === 'frame-facades')
                          ? 'text-button'
                          : 'icons'}
                        >
                          {doorPositioningChoice()}
                        </RadioGroup>
                      )}
                  </>
                )}

              <div className={clsx('main-tab--item-group facades with-item')}>
                <Label
                  value={t('stickyMenu.mainDoorTab.facade-view')}
                  infoTagValue={t('tooltips.facade')}
                  withInfoTag
                />
                <Dropdown
                  placeholder="Фасад"
                  options={facadeViews}
                  onChange={(option) => {
                    if (!option?.value) return;
                    if (option?.value === 'fixed') {
                      resetHolesForHandles('left');
                    }
                    if (option?.value === 'fixed' || option?.value === 'pullout') {
                      resetFasteningElement();
                    }
                    if (option?.value === 'opening' || option?.value === 'pullout') {
                      resetPearHoles();
                    }

                    setFrameFacadeView(option?.value);
                  }}
                  translationNs="options:facadeViews"
                  value={facadeViews.find((item) => item.value === frameFacadeView)}
                  isClearable={false}
                />
              </div>

              {frameFacadeView === 'opening' && (
                <div className={clsx('main-tab--item-group facades', frameFasteningElement?.type && 'with-hinge')}>
                  <Label
                    value={t('stickyMenu.mainDoorTab.fastening-element')}
                    infoTagValue={t('tooltips.fastening-element')}
                    withInfoTag
                  />
                  <Button
                    onClick={() => (frameOpeningH && frameOpeningW
                      ? dispatch(FasteningElementAction.toggleFasteningElementModal(true))
                      : checkInput()
                    )}
                    value={frameFasteningElement?.type
                      ? t('fillingMaterialsControl.change')
                      : t('fillingMaterialsControl.choose')}
                    type="outlined-white"
                  />
                </div>
              )}

              {(frameFasteningElement?.type && frameFasteningElement?.items?.length > 0)
                && (
                  <div className="hinge-wrapper">
                    <ReactSVG
                      className={clsx('hinge-icon',
                        frameFasteningElement?.type === 'parallel-hinge' && 'parallel',
                        frameFasteningElement?.type === 'half-overlap-hinge' && 'half-overlap')}
                      wrapper="span"
                      src={fasteningElementIcon[frameFasteningElement?.type]}
                    />
                    <p className="hinge-label">{t(`fasteningElement.${frameFasteningElement?.type}`)}</p>
                  </div>
                )}

              {((frameFacadeView === 'opening' || frameFacadeView === 'pullout')
                && frameProfile?.replace('-N', '') !== 'P-38')
                && (
                  <div className={clsx('main-tab--item-group facades', frameFasteningElement?.type && 'with-hinge')}>
                    <Label
                      value={t('stickyMenu.mainDoorTab.holes-for-handles')}
                      infoTagValue={t('tooltips.holes-handles')}
                      withInfoTag
                    />
                    <Button
                      value={t('fillingMaterialsControl.set')}
                      type="outlined-white"
                      onClick={() => (frameOpeningH && frameOpeningW
                        ? dispatch(DrillingHolesAction.toggleDrillingHoles(true))
                        : checkInput()
                      )}
                    />
                  </div>
                )}

              {frameFacadeView === 'fixed' && (
                <div className={clsx('main-tab--item-group facades', frameFasteningElement?.type && 'with-hinge')}>
                  <Label
                    value={t('stickyMenu.mainDoorTab.pear-hole')}
                    infoTagValue={t('stickyMenu.mainDoorTab.pear-hole')}
                    withInfoTag
                  />
                  <Button
                    value={t('fillingMaterialsControl.choose')}
                    type="outlined-white"
                    onClick={() => (frameOpeningH && frameOpeningW
                      ? dispatch(DrillingPearHolesAction.toggleDrillingPearHoles(true))
                      : checkInput()
                    )}
                  />
                </div>
              )}

              <div className={clsx('main-tab--item-group facades')}>
                <div className="main-tab--item-group-caption">
                  <p className="main-tab--item-group-caption-title">
                    {t('stickyMenu.mainDoorTab.number-of-frames-of-the-same-type')}
                  </p>
                </div>
                <PlusMinusControl
                  amount={sameFrame || 0}
                  name="sameFrame"
                  setAmount={onSameFramesAmount}
                />
              </div>

              {(currentOrderId || showPrice || !AuthService.isLoggedIn()) && (
                <>
                  <div className={clsx('main-tab--item-group facades')}>
                    <div className="main-tab--item-group-caption">
                      <p className="main-tab--item-group-caption-title">
                        {t('stickyMenu.mainDoorTab.price-per-page')}
                      </p>
                    </div>
                    <div className="main-tab--item-group-price">
                      <ReactSVG
                        className={clsx('main-tab--item-group-price-icon', !framePrice?.perFrame && 'empty')}
                        wrapper="div"
                        src="/src/client/assets/icons/currency-uah.svg"
                      />
                      <p className={clsx('main-tab--item-group-price-value', !framePrice?.perFrame && 'empty')}>
                        {numberWithSpaces(framePrice?.perFrame)}
                      </p>
                    </div>
                  </div>

                  <div className={clsx('main-tab--item-group facades')}>
                    <div className="main-tab--item-group-caption">
                      <p className="main-tab--item-group-caption-title bold">
                        {t('stickyMenu.mainDoorTab.total-price')}
                      </p>
                    </div>
                    <div className="main-tab--item-group-price">
                      <ReactSVG
                        className={clsx('main-tab--item-group-price-icon', !framePrice?.total && 'empty')}
                        wrapper="div"
                        src="/src/client/assets/icons/currency-uah.svg"
                      />
                      <p className={clsx('main-tab--item-group-price-value', !framePrice?.total && 'empty')}>
                        {numberWithSpaces(framePrice?.total)}
                      </p>
                    </div>
                  </div>
                </>
              )}


              {activeDoor === 0 && (
                <div className="main-tab--bottom-nav">
                  <button
                    type="button"
                    className={clsx('main-tab--bottom-nav-button', isDisabledCreateGroup && 'disabled')}
                    onClick={() => (isDisabledCreateGroup
                      ? showToast(errorMessage(), 'error')
                      : dispatch(DoorsActions.toggleGroupNameModal(true))
                    )}
                  >
                    <span className="main-tab--bottom-nav-title">{t('stickyMenu.mainDoorTab.createGroup')}</span>
                    <ReactSVG
                      src="/src/client/assets/icons/rounded-arrow-next.svg"
                      wrapper="span"
                      className="main-tab--bottom-nav-icon"
                    />
                  </button>
                </div>

              )}
            </>
          )}

      </div>
      <br />

      <SetGroupNameModal
        isOpen={isGroupNameModalOpen}
        onCloseModal={() => dispatch(DoorsActions.toggleGroupNameModal(false))}
        onSubmit={(groupName) => { onCreateFrames(groupName); clearFrameState(); }}
        currentFrameAmount={doors.length}
      />

      <StrengthProfileModal
        isOpen={isOpenStrengthProfileModal}
        onCloseModal={() => dispatch(DoorsActions.toggleStrengthProfileModal(false))}
        onSubmit={() => onSubmitGuaranteeAgreement()}
      />

      <WarningFrameSizeModal
        isOpen={isOpenWarningFrameSizeModal}
        onCloseModal={() => onCloseWarningFrameSizeModal()}
        onSubmit={() => onSubmitFrameSizeGuaranteeAgreement()}
      />

      <FillingMaterialsModal
        isMainFilling
        isOpen={isOpenFillingModal}
        onCloseModal={() => dispatch(FillingActions.toggleFillingMaterialModal(false))}
        activeTrigger={activeTrigger || mainDoorFilling?.material || frameFilling[0]?.material || ''}
        setActiveTrigger={(trigger) => dispatch(FillingActions.setActiveTrigger(trigger))}
        onSubmit={handleSubmitMainDoorFilling}
        clearFilling={() => {
          dispatch(DoorsActions.clearFilling(null, null));
          dispatch(OrderActions.calculateOrderRequest());
          dispatch(FillingActions.resetFillingMaterialModal());
        }}
        //
        setCustomersOption={(option) => dispatch(FillingActions.setCustomersOption(option))}
        customersOption={customersOption || mainDoorFilling?.customersOption || frameFilling[0]?.customersOption || ''}
        isMilling={isMilling}
        setCustomDSPMilling={(isOn) => dispatch(FillingActions.setCustomDSPMilling(isOn))}
        dspOption={dspOption || mainDoorFilling?.dspOption || frameFilling[0]?.dspOption || ''}
        setDspOption={(option) => dispatch(FillingActions.setDspOption(option))}
        dspManufacturer={manufacturer || mainDoorFilling?.manufacturer || frameFilling[0]?.manufacturer || ''}
        setDspManufacturer={(manufacture) => dispatch(FillingActions.setDspManufacturer(manufacture))}
        dspSearch={searchField}
        setDspSearch={(search) => dispatch(FillingActions.setDspSearch(search))}
        isDspUVPrinting={isDspUVPrinting}
        setDspUvPrinting={(isOn) => dispatch(FillingActions.setDspUvPrinting(isOn))}
        dspUvPrintType={dspUvPrintType || mainDoorFilling?.dspUvPrintType || frameFilling[0]?.dspUvPrintType || ''}
        setDspUvPrintType={(printType) => dispatch(FillingActions.setDspUvPrintType(printType))}
        //
        mirrorType={mirrorType || mainDoorFilling?.mirrorType || frameFilling[0]?.mirrorType || ''}
        setMirrorType={(type) => dispatch(FillingActions.setMirrorType(type))}
        isMirrorMatted={isMirrorMatted}
        setMirrorMatting={(isOn) => dispatch(FillingActions.setMirrorMatting(isOn))}
        isMirrorRearMatted={isMirrorRearMatted}
        setMirrorRearMatting={(isOn) => dispatch(FillingActions.setMirrorRearMatting(isOn))}
        setMirrorFullMatting={(isOn) => dispatch(FillingActions.setMirrorFullMatting(isOn))}
        isMirrorFullMatted={isMirrorFullMatted}
        mirrorColor={mirrorColor || mainDoorFilling?.mirrorColor || frameFilling[0]?.mirrorColor || ''}
        setMirrorPaintingColor={(color) => dispatch(FillingActions.setMirrorPaintingColor(color))}
        isMirrorUVPrinting={isMirrorUVPrinting}
        setMirrorUvPrinting={(isOn) => dispatch(FillingActions.setMirrorUvPrinting(isOn))}
        mirrorUvPrintType={mirrorUvPrintType || mainDoorFilling?.mirrorUvPrintType || frameFilling[0]?.mirrorUvPrintType || ''}
        setMirrorUvPrintType={(printType) => dispatch(FillingActions.setMirrorUvPrintType(printType))}
        isMirrorArmoredFilm={isMirrorArmoredFilm}
        setMirrorArmoredFilm={(isOn) => dispatch(FillingActions.setMirrorArmoredFilm(isOn))}
        isMirrorLaminated={isMirrorLaminated}
        setMirrorLamination={(isOn) => dispatch(FillingActions.setMirrorLamination(isOn))}
        mirrorSearch={mirrorSearch}
        setMirrorSearch={(search) => dispatch(FillingActions.setMirrorSearch(search))}
        //
        lacobelType={lacobelType || mainDoorFilling?.lacobelType || frameFilling[0]?.lacobelType || ''}
        setLacobelType={(type) => dispatch(FillingActions.setLacobelType(type))}
        isLacobelMatted={isLacobelMatted}
        setLacobelMatting={(isOn) => dispatch(FillingActions.setLacobelMatting(isOn))}
        isLacobelRearMatted={isLacobelRearMatted}
        setLacobelRearMatting={(isOn) => dispatch(FillingActions.setLacobelRearMatting(isOn))}
        isLacobelFullMatted={isLacobelFullMatted}
        setLacobelFullMatting={(isOn) => dispatch(FillingActions.setLacobelFullMatting(isOn))}
        lacobelColor={lacobelColor || mainDoorFilling?.lacobelColor || frameFilling[0]?.lacobelColor || ''}
        setLacobelPaintingColor={(color) => dispatch(FillingActions.setLacobelPaintingColor(color))}
        isLacobelUVPrinting={isLacobelUVPrinting}
        setLacobelUvPrinting={(isOn) => dispatch(FillingActions.setLacobelUvPrinting(isOn))}
        lacobelUvPrintType={lacobelUvPrintType || mainDoorFilling?.lacobelUvPrintType || frameFilling[0]?.lacobelUvPrintType || ''}
        setLacobelUvPrintType={(printType) => dispatch(FillingActions.setLacobelUvPrintType(printType))}
        isLacobelArmoredFilm={isLacobelArmoredFilm}
        setLacobelArmoredFilm={(isOn) => dispatch(FillingActions.setLacobelArmoredFilm(isOn))}
        isLacobelLaminated={isLacobelLaminated}
        setLacobelLamination={(isOn) => dispatch(FillingActions.setLacobelLamination(isOn))}
        lacobelSearch={lacobelSearch}
        setLacobelSearch={(search) => dispatch(FillingActions.setLacobelSearch(search))}
        //
        glassType={glassType || mainDoorFilling?.glassType || frameFilling[0]?.glassType || ''}
        setGlassType={(type) => dispatch(FillingActions.setGlassType(type))}
        isGlassMatted={isGlassMatted}
        setGlassMatting={(isOn) => dispatch(FillingActions.setGlassMatting(isOn))}
        isGlassFullMatted={isGlassFullMatted}
        setGlassFullMatting={(isOn) => dispatch(FillingActions.setGlassFullMatting(isOn))}
        isGlassOneColorPainted={isGlassOneColorPainted}
        isGlassTwoColorsPainted={isGlassTwoColorsPainted}
        setGlassIsOneColorPainted={(type) => dispatch(FillingActions.setGlassIsOneColorPainted(type))}
        setGlassIsTwoColorsPainted={(type) => dispatch(FillingActions.setGlassIsTwoColorsPainted(type))}
        glassColors={glassColors || mainDoorFilling?.glassColors || frameFilling[0]?.glassColors || []}
        setGlassPaintingColors={(colors) => dispatch(FillingActions.setGlassPaintingColors(colors))}
        isGlassUVPrinting={isGlassUVPrinting}
        setGlassUvPrinting={(isOn) => dispatch(FillingActions.setGlassUvPrinting(isOn))}
        isGlassPhotoPrinting={isGlassPhotoPrinting}
        setGlassPhotoPrinting={(isOn) => dispatch(FillingActions.setGlassPhotoPrinting(isOn))}
        glassUvPrintType={glassUvPrintType || mainDoorFilling?.glassUvPrintType || frameFilling[0]?.glassUvPrintType || ''}
        setGlassUvPrintType={(printType) => dispatch(FillingActions.setGlassUvPrintType(printType))}
        glassPhotoPrintType={glassPhotoPrintType || mainDoorFilling?.glassPhotoPrintType || frameFilling[0]?.glassPhotoPrintType || ''}
        setGlassPhotoPrintType={(printType) => dispatch(FillingActions.setGlassPhotoPrintType(printType))}
        glassSearch={glassSearch}
        setGlassSearch={(search) => dispatch(FillingActions.setGlassSearch(search))}
        isGlassArmoredFilm={isGlassArmoredFilm}
        setGlassArmoredFilm={(isOn) => dispatch(FillingActions.setGlassArmoredFilm(isOn))}
        isGlassLaminated={isGlassLaminated}
        setGlassLamination={(isOn) => dispatch(FillingActions.setGlassLamination(isOn))}
      />
    </div>
  );
};

DoorMainTab.propTypes = {
  toastManager: PropTypes.shape(notificationPropTypes).isRequired,
};

export default withToastManager(DoorMainTab);
