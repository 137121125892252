// hook for image in canvas from https://github.com/konvajs/use-image
import { useLayoutEffect, useRef, useState } from 'react';

const useImage = (url, crossOrigin, referrerpolicy) => {
  const statusRef = useRef('loading');
  const imageRef = useRef();

  // need to just to trigger state update
  const [_, setState] = useState(0);

  const oldUrl = useRef();
  const oldCrossOrigin = useRef();
  const oldReferrerPolicy = useRef();
  if (oldUrl.current !== url || oldCrossOrigin.current !== crossOrigin || oldReferrerPolicy.current !== referrerpolicy) {
    statusRef.current = 'loading';
    imageRef.current = undefined;
    oldUrl.current = url;
    oldCrossOrigin.current = crossOrigin;
    oldReferrerPolicy.current = referrerpolicy;
  }

  useLayoutEffect(() => {
    if (!url) return;
    const img = document.createElement('img');

    function onload() {
      statusRef.current = 'loaded';
      imageRef.current = img;
      setState(Math.random());
    }

    function onerror() {
      statusRef.current = 'failed';
      imageRef.current = undefined;
      setState(Math.random());
    }

    img.addEventListener('load', onload);
    img.addEventListener('error', onerror);
    if (crossOrigin) {
      img.crossOrigin = crossOrigin;
    }
    if (referrerpolicy) {
      img.referrerpolicy = referrerpolicy;
    }
    img.src = url;

    return function cleanup() {
      img.removeEventListener('load', onload);
      img.removeEventListener('error', onerror);
    };
  },
  [url, crossOrigin, referrerpolicy]);

  return [imageRef.current, statusRef.current];
};

export default useImage;
