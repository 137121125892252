import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

import DoorsActions from '../../redux/actions/doorsAndSections';
import DrillingHolesAction from '../../redux/actions/drillingHoles';

import { blockInvalidChar } from '../../helpers/sanitizer';
import { profilesAxisValidation } from '../../helpers/frameProfilesOptions';

import PlusMinusControl from '../PlusMinusControl';
import Button from '../Button';
import Input from '../Input';


export const checkIsSameCoords = (coordsToCheck = []) => {
  const coords = [];

  return coordsToCheck.some((item) => {
    const coordsValue = `${item.coords.x}-${item.coords.y}`;

    if (coords.includes(coordsValue)) return true;

    coords.push(coordsValue);
    return false;
  });
};


const DrillingHolesForHandles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRefX = useRef(null);
  const inputRefY = useRef(null);
  const prevPlacementSideRef = useRef();
  const invalidChars = ['e', 'E', '+', '-', '.', ','];

  const { activeDoor, mainFrame, doors } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const [axisValidation, setAxisValidation] = useState({});
  const [isInvertAxisValidation, setIsInvertAxisValidation] = useState(false);
  const [frameProfile, setFrameProfile] = useState('');
  const [frameHeight, setFrameHeight] = useState(null);
  const [frameWidth, setFrameWidth] = useState(null);
  const [frameOpeningSide, setFrameOpeningSide] = useState('left');
  const [handlePlacementSide, setHandlePlacementSide] = useState('');
  const [holesForHandlesAmount, setHolesForHandlesAmount] = useState(0);
  const [holesForHandlesItems, setHolesForHandlesItems] = useState([]);

  useEffect(() => {
    document.querySelector('.drilling').scrollIntoView({ block: 'start', behavior: 'auto' });
  }, []);

  useEffect(() => {
    prevPlacementSideRef.current = handlePlacementSide;
  });

  const prevPlacementSide = prevPlacementSideRef.current;

  useEffect(() => {
    if ((prevPlacementSide === 'right' || prevPlacementSide === 'left')
      && (handlePlacementSide === 'top' || handlePlacementSide === 'bottom')) {
      setHolesForHandlesAmount(0);
      setHolesForHandlesItems([]);
      setIsInvertAxisValidation(true);
    }

    if ((prevPlacementSide === 'top' || prevPlacementSide === 'bottom')
      && (handlePlacementSide === 'right' || handlePlacementSide === 'left')) {
      setHolesForHandlesAmount(0);
      setHolesForHandlesItems([]);
      setIsInvertAxisValidation(false);
    }
  }, [handlePlacementSide]);


  useEffect(() => {
    setAxisValidation(profilesAxisValidation[frameProfile?.replace('-N', '')]);
  }, [frameProfile]);

  useEffect(() => {
    if (activeDoor === 0) {
      setFrameProfile(mainFrame?.frameProfile?.value);
      setFrameHeight(mainFrame?.frameOpeningHeight?.value);
      setFrameWidth(mainFrame?.frameOpeningWidth?.value);
      setFrameOpeningSide(mainFrame?.openingSide?.value);
      setHandlePlacementSide(mainFrame?.holesForHandles?.placementSide);
      setHolesForHandlesAmount(mainFrame?.holesForHandles?.amount);
      setHolesForHandlesItems(mainFrame?.holesForHandles?.items);

      return;
    }

    setFrameProfile(doors[activeDoor - 1]?.frameProfile?.value);
    setFrameHeight(doors[activeDoor - 1]?.frameOpeningHeight?.value);
    setFrameWidth(doors[activeDoor - 1]?.frameOpeningWidth?.value);
    setFrameOpeningSide(doors[activeDoor - 1]?.openingSide?.value);
    setHandlePlacementSide(doors[activeDoor - 1]?.holesForHandles?.placementSide);
    setHolesForHandlesAmount(doors[activeDoor - 1]?.holesForHandles?.amount);
    setHolesForHandlesItems(doors[activeDoor - 1]?.holesForHandles?.items);
  }, [activeDoor, mainFrame, doors]);


  useEffect(() => {
    if (handlePlacementSide === 'top' || handlePlacementSide === 'bottom') setIsInvertAxisValidation(true);

    dispatch(DrillingHolesAction.setPlacementSide(handlePlacementSide));
  }, [handlePlacementSide]);

  useEffect(() => {
    dispatch(DrillingHolesAction.setHolesAmount(holesForHandlesAmount));
  }, [holesForHandlesAmount]);


  useEffect(() => {
    dispatch(DrillingHolesAction.updateHolesItems(holesForHandlesItems));
  }, [holesForHandlesItems]);


  const onHolesForHandlesAmountChange = (value) => {
    const itemsLength = holesForHandlesItems?.length;
    const { defaultValue } = axisValidation;
    const yInitialDistance = _.round(frameHeight / 2);
    const xInitialDistance = _.round(frameWidth / 2);

    if (value < 0) return;

    if (holesForHandlesAmount < value) {
      if (value === 1) {
        setHolesForHandlesItems([...holesForHandlesItems, {
          sequenceNumber: value,
          coords: {
            x: isInvertAxisValidation ? xInitialDistance : defaultValue,
            y: isInvertAxisValidation ? defaultValue : yInitialDistance,
          },
          ...getDrillingOptions(defaultValue),
        }]);
      } else {
        const { coords: { x, y } } = holesForHandlesItems[0];
        setHolesForHandlesItems([...holesForHandlesItems, {
          sequenceNumber: value,
          coords: {
            x: isInvertAxisValidation ? xInitialDistance : x,
            y: isInvertAxisValidation ? y : yInitialDistance,
          },
          ...(isInvertAxisValidation ? getDrillingOptions(y) : getDrillingOptions(x)),
        }]);
      }
    }

    if (holesForHandlesAmount > value) {
      setHolesForHandlesItems(holesForHandlesItems.slice(0, itemsLength - 1));
    }

    setHolesForHandlesAmount(value);
  };


  const getDrillingConditionForP33 = (value, invertedValue) => (frameProfile?.replace('-N', '') === 'P-33'
      && ((!isInvertAxisValidation && Number(value) === axisValidation?.additionalValue)
        || (isInvertAxisValidation && Number(invertedValue) === axisValidation?.additionalValue)));


  const getDrillingOptions = (value) => {
    const materialForDrillingForP33 = getDrillingConditionForP33(value, value) ? 'profile' : 'glass';
    const holeDiameterForP33 = getDrillingConditionForP33(value, value) ? 5 : 7;
    const bushingForP33 = !getDrillingConditionForP33(value, value);

    return ({
      materialForDrilling: axisValidation?.materialForDrilling || materialForDrillingForP33,
      holeDiameter: axisValidation?.holeDiameter || holeDiameterForP33,
      bushing: frameProfile?.replace('-N', '') === 'P-33' ? bushingForP33 : axisValidation?.bushing,
    });
  };


  const getItemsToUpdate = (itemValue, number, axis, options) => holesForHandlesItems.map((item) => (
    item.sequenceNumber === number
      ? { ...item, coords: { ...item.coords, [axis]: Number(itemValue) }, ...options }
      : item));


  const handlesHolesCoords = (value, sequenceNumber, axis) => {
    if (value < 0) return;

    if (!isInvertAxisValidation && axis === 'y' && value > (Number(frameHeight) - 65)) return;
    if (isInvertAxisValidation && axis === 'y' && value > axisValidation.max) return;

    if (!isInvertAxisValidation && axis === 'x' && value > axisValidation.max) return;
    if (isInvertAxisValidation && axis === 'x' && value > (Number(frameWidth) - 65)) return;

    setHolesForHandlesItems(getItemsToUpdate(value, sequenceNumber, axis, getDrillingOptions(value)));
  };


  const sanitizeHolesCoords = (value, sequenceNumber, axis) => {
    if (!isInvertAxisValidation && axis === 'y' && value < 65) {
      value = 65;
      setHolesForHandlesItems(getItemsToUpdate(value, sequenceNumber, axis, getDrillingOptions(value)));
    }

    if (isInvertAxisValidation && axis === 'y' && value < axisValidation.min) {
      value = frameProfile?.replace('-N', '') === 'P-33' && Number(value) === axisValidation.additionalValue
        ? axisValidation.additionalValue
        : axisValidation.min;

      const holesForUpdate = holesForHandlesItems.map((item) => (
        { ...item, coords: { ...item.coords, [axis]: value }, ...getDrillingOptions(value) }));
      setHolesForHandlesItems(holesForUpdate);
    }

    if (isInvertAxisValidation && axis === 'y') {
      const holesForUpdate = holesForHandlesItems.map((item) => (
        { ...item, coords: { ...item.coords, [axis]: value }, ...getDrillingOptions(value) }));
      setHolesForHandlesItems(holesForUpdate);
    }

    if (isInvertAxisValidation && axis === 'x' && value < 65) {
      value = 65;
      setHolesForHandlesItems(getItemsToUpdate(value, sequenceNumber, axis, getDrillingOptions(value)));
    }

    if (!isInvertAxisValidation && axis === 'x' && value < axisValidation.min) {
      value = frameProfile?.replace('-N', '') === 'P-33' && Number(value) === axisValidation.additionalValue
        ? axisValidation.additionalValue
        : axisValidation.min;

      const holesForUpdate = holesForHandlesItems.map((item) => (
        { ...item, coords: { ...item.coords, [axis]: value }, ...getDrillingOptions(value) }));
      setHolesForHandlesItems(holesForUpdate);
    }

    if (!isInvertAxisValidation && axis === 'x') {
      const holesForUpdate = holesForHandlesItems.map((item) => (
        { ...item, coords: { ...item.coords, [axis]: value }, ...getDrillingOptions(value) }));
      setHolesForHandlesItems(holesForUpdate);
    }
  };


  const removeFieldInvalidFromCoords = (number, coordX, coordY) => {
    const itemsToUpdate = holesForHandlesItems.map((item) => (item.sequenceNumber === number
      ? { ..._.omit(item, ['isInvalid']), coords: { x: coordX, y: coordY } }
      : item));

    setHolesForHandlesItems(itemsToUpdate);
  };


  const clearParams = () => {
    setHandlePlacementSide(frameOpeningSide === 'left' ? 'right' : 'left');
    setHolesForHandlesAmount(0);
    setHolesForHandlesItems([]);
  };


  const renderHandlePlacementSide = () => {
    const placementSides = frameOpeningSide === 'left' || frameOpeningSide === 'right'
      ? [{ id: frameOpeningSide === 'left' ? 'right' : 'left' }, { id: 'top' }, { id: 'bottom' }]
      : [{ id: 'bottom' }];

    return (
      <div className="side-list">
        {placementSides.map(({ id }) => (
          <>
            <button
              type="button"
              key={id}
              id={id}
              className={clsx('side-item', id === handlePlacementSide && 'active')}
              onClick={() => setHandlePlacementSide(id)}
            >
              <span className={clsx('circle', [id])} />
              <span className="side-label">{t(`drillingHolesForHandles.${id}`)}</span>
            </button>
          </>
        ))}
      </div>
    );
  };

  const renderHolesCoords = () => {
    const { min = 0, max = 0 } = axisValidation || {};
    const indentFromStartInHeight = 65;
    const indentFromEndInHeight = (Number(frameHeight) - 65);
    const indentFromStartInWidth = 65;
    const indentFromEndInWidth = (Number(frameWidth) - 65);

    const axisRange = frameProfile?.replace('-N', '') === 'P-33'
      ? t('drillingHolesForHandles.range-P33', { additional: axisValidation?.additionalValue, min, max })
      : t('drillingHolesForHandles.range', { min, max });
    const axisYRange = t('drillingHolesForHandles.range', {
      min: indentFromStartInHeight,
      max: indentFromEndInHeight,
    });
    const axisXRange = t('drillingHolesForHandles.range', {
      min: indentFromStartInWidth,
      max: indentFromEndInWidth,
    });

    const rangeDistance = max - min;
    const heightDistance = indentFromEndInHeight - indentFromStartInHeight;
    const widthDistance = indentFromEndInWidth - indentFromStartInWidth;

    const isDisabledInput = (axis, isInvalid) => Boolean(
      (!isInvertAxisValidation && axis === 'x' && rangeDistance === 0)
      || (isInvertAxisValidation && axis === 'y' && rangeDistance === 0)
      || (isInvertAxisValidation && axis === 'x' && isInvalid && widthDistance < 0)
      || (!isInvertAxisValidation && axis === 'y' && isInvalid && heightDistance < 0),
    );

    const isInvalidDisabledInput = (axis, isInvalid) => {
      if ((isInvertAxisValidation && axis === 'x' && isInvalid && widthDistance >= 0)
        || (!isInvertAxisValidation && axis === 'y' && isInvalid && heightDistance >= 0)) return 'invalid';
      if ((isInvertAxisValidation && axis === 'y' && rangeDistance === 0)
        || (!isInvertAxisValidation && axis === 'x' && rangeDistance === 0)) return 'disabled';
      return '';
    };

    return (
      <div className="drilling-coords">
        {holesForHandlesItems?.map(({ sequenceNumber, coords: { x, y }, isInvalid }) => (
          <div key={`coords-for-hole-${sequenceNumber}`} className="drilling-coords-wrapper">
            <div className="drilling-coords-title">
              {t('drillingHolesForHandles.holes-coords', { sequenceNumber })}
            </div>
            <div className="drilling-coords-items">
              <div className="drilling-coords-item">
                <div className="drilling-coords-item-wrapper">
                  <div className="drilling-coords-item-symbol">X</div>
                  <div className={clsx('drilling-coords-input-wrapper', isInvalidDisabledInput('x', isInvalid))}>
                    <Input
                      inputRef={inputRefX}
                      className="small"
                      type="number"
                      placeholder="0"
                      direction="rtl"
                      value={x.toString()}
                      onFocus={() => {
                        removeFieldInvalidFromCoords(sequenceNumber, x, y);
                        dispatch(DoorsActions.setFrameActiveInput({ name: 'holesForHandles', number: sequenceNumber }));
                      }}
                      onChange={(e) => handlesHolesCoords(e.target.value, sequenceNumber, 'x')}
                      onBlur={(e) => {
                        sanitizeHolesCoords(e.target.value, sequenceNumber, 'x');
                        dispatch(DoorsActions.clearFrameActiveInput());
                      }}
                      onKeyDown={(e) => {
                        blockInvalidChar(e, invalidChars);
                        if (e.keyCode === 13) {
                          inputRefX.current.blur();
                          inputRefY.current.focus();
                        }
                      }}
                      key={`holesForHandlesItemX-${sequenceNumber}`}
                      name={`holesForHandlesItemX-${sequenceNumber}`}
                      isDisabled={isDisabledInput('x', isInvalid)}
                    />
                    <div className="drilling-coords-input-range">
                      {isInvertAxisValidation ? axisXRange : axisRange}
                    </div>
                    <div className="drilling-coords-input-tooltip">
                      {t('drillingHolesForHandles.tooltip')}
                      <span />
                    </div>
                  </div>
                </div>
              </div>
              <div className="drilling-coords-item">
                <div className="drilling-coords-item-wrapper">
                  <div className="drilling-coords-item-symbol">Y</div>
                  <div className={clsx('drilling-coords-input-wrapper', isInvalidDisabledInput('y', isInvalid))}>
                    <Input
                      inputRef={inputRefY}
                      className="small"
                      type="number"
                      placeholder="0"
                      direction="rtl"
                      value={y.toString()}
                      onFocus={() => {
                        removeFieldInvalidFromCoords(sequenceNumber, x, y);
                        dispatch(DoorsActions.setFrameActiveInput({ name: 'holesForHandles', number: sequenceNumber }));
                      }}
                      onChange={(e) => handlesHolesCoords(e.target.value, sequenceNumber, 'y')}
                      onBlur={(e) => {
                        sanitizeHolesCoords(e.target.value, sequenceNumber, 'y');
                        dispatch(DoorsActions.clearFrameActiveInput());
                      }}
                      onKeyDown={(e) => {
                        blockInvalidChar(e, invalidChars);
                        if (e.keyCode === 13) {
                          inputRefY.current.blur();
                        }
                      }}
                      key={`holesForHandlesItemY-${sequenceNumber}`}
                      name={`holesForHandlesItemY-${sequenceNumber}`}
                      isDisabled={isDisabledInput('y', isInvalid)}
                    />
                    <div className="drilling-coords-input-range">
                      {isInvertAxisValidation ? axisRange : axisYRange }
                    </div>
                    <div className="drilling-coords-input-tooltip">
                      {t('drillingHolesForHandles.tooltip')}
                      <span />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderWarningContent = () => {
    const materialForDrillingForP33 = getDrillingConditionForP33(
      holesForHandlesItems?.[0]?.coords?.x, holesForHandlesItems?.[0]?.coords?.y,
    )
      ? 'profile'
      : 'glass';

    const warningMessage = t(
      `drillingHolesForHandles.warning-${axisValidation?.materialForDrilling || materialForDrillingForP33}`,
    );

    return (
      <div className="drilling-warning">
        <div className="drilling-warning-content">
          {warningMessage}
        </div>
        <ReactSVG
          wrapper="div"
          className="drilling-warning-icon"
          src="/src/client/assets/icons/icon-warning.svg"
        />
      </div>
    );
  };


  return (
    <div className="drilling">
      <div className="drilling-title">
        {t('stickyMenu.desktop.drilling')}
      </div>
      <button
        className="drilling-close modal-close-button"
        type="button"
        onClick={() => {
          dispatch(DrillingHolesAction.resetHoles());
          dispatch(DrillingHolesAction.toggleDrillingHoles(false));
        }}
      >
        <div className="modal-close-button-cross" />
      </button>
      {renderWarningContent()}
      <div className="drilling-placement">
        <div className="drilling-placement-title">
          {t('drillingHolesForHandles.handle-side')}
        </div>
        {renderHandlePlacementSide()}
      </div>
      <div className="drilling-amount">
        <div className="drilling-amount-title">
          {t('drillingHolesForHandles.holes-amount')}
        </div>

        <PlusMinusControl
          amount={holesForHandlesAmount || 0}
          name="holesForHandlesAmount"
          setAmount={onHolesForHandlesAmountChange}
        />
      </div>

      {renderHolesCoords()}

      <Button
        value={t('drillingHolesForHandles.clear-params')}
        className="drilling-clear-button"
        onClick={() => clearParams()}
      />
    </div>
  );
};

export default DrillingHolesForHandles;
