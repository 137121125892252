import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle, navigate } from 'hookrouter';
import { useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import clsx from 'clsx';

import { regionsList, mainRegion, countriesPhoneCode } from '../../../server/helpers/constants.mjs';

import notificationPropTypes from '../../helpers/propTypes/notificationPropTypes';
import { capitalizeFirstLetter } from '../../helpers/sanitizer';

import Label from '../../components/Label';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';

import {
  isValidFirstOrLastName,
  isValidPassword,
} from '../../helpers/validation';

import AuthService from '../../services/authService';

import API from '../../api';

import tfaFormActions from '../../redux/actions/tfaForm';
import SignupFormActions from '../../redux/actions/signupForm';
import ProfileActions from '../../redux/actions/profile';

import Main from '../layout/Main';
import LandingText from '../layout/LandingText';
import BackArrowBtn from '../../components/BackArrowBtn';
import Timer from '../../components/timer';


const Signup = ({
  toastManager,
}) => {
  const { i18n, t } = useTranslation(['components']);
  const dispatch = useDispatch();
  const userId = AuthService.getUserId();

  useTitle(t('routes.sign-up'));

  const {
    firstName,
    lastName,
    primaryRegion,
    password,
    phone,
    repeatedPassword,
    errorMessage,
    successMessage,
  } = useSelector(({ signupForm }) => signupForm);

  const {
    confirmationCode,
    isLoading,
    isConfirmationCodeSent,
    isConfirmationCodeValid,
    isPhoneNumberVerified,
  } = useSelector(({ tfaForm }) => tfaForm);

  const {
    regionsList: userRegionsList,
  } = useSelector(({ profile }) => profile);

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
  const [notificationId, setNotificationId] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [isTimeCounting, setIsTimeCounting] = useState(false);
  const [timeToStop, setTimeToStop] = useState(null);

  const regions = userRegionsList?.length
    ? userRegionsList.map((region) => ({
      value: region,
      labelRu: region,
      labelUk: region,
      labelPl: region,
      labelEn: region,
    }))
    : regionsList.map((region) => ({
      value: region.ru,
      labelRu: region.ru,
      labelUk: region.uk,
      labelPl: region.pl,
      labelEn: region.en,
    }));

  useEffect(() => {
    setTimeToStop(localStorage.getItem('signupSmsBlockedUntill'));
    const currentTime = Date.now();
    const deltaTime = timeToStop - currentTime;

    if (deltaTime > 0) setIsTimeCounting(true);
  }, [timeToStop]);

  useEffect(() => {
    dispatch(SignupFormActions.resetPassword());
    dispatch(tfaFormActions.resetTFAForm());
  }, []);

  useEffect(() => {
    dispatch(SignupFormActions.updateLanguage({
      language: i18n.language,
    }));
  }, [i18n.language]);

  useEffect(() => {
    if (phone?.value) {
      const country = Object.entries(countriesPhoneCode).find((c) => phone?.value.includes(c[1]))[0];
      return updateRegion(mainRegion[country]);
    }

    updateRegion(mainRegion.uk);
  }, []);

  useEffect(() => {
    if (primaryRegion) {
      return setSelectedRegion(primaryRegion);
    }
  }, [primaryRegion]);

  useEffect(() => {
    if (!userId) navigate('/sign-up-start');
    if (userId && isConfirmationCodeSent && !(isConfirmationCodeValid && isPhoneNumberVerified)) {
      navigate('/sign-up-start');
    }
  }, [isConfirmationCodeSent, isConfirmationCodeValid, isPhoneNumberVerified]);

  useEffect(() => {
    if (errorMessage) showToast(errorMessage, 'error');
    if (successMessage) showToast(successMessage, 'success');
  }, [
    errorMessage,
    successMessage,
  ]);

  const showToast = (message, appearance) => {
    const errorContent = <div className="toast-notification">{message}</div>;

    if (notificationId) toastManager.remove(notificationId);

    toastManager.add(errorContent, {
      appearance,
      autoDismiss: true,
    }, (id) => { setNotificationId(id); });
  };

  const updateConfirmationCode = ({ target: { value } }) => {
    dispatch(tfaFormActions.updateConfirmationCode({ value }));
  };

  const updateName = ({ target: { name, value } }) => {
    dispatch(SignupFormActions.updateField({
      name,
      value,
    }));
  };

  const updateRegion = (selectedOption) => {
    dispatch(SignupFormActions.updateRegion(selectedOption));
  };

  const validateName = ({ target: { name, value } }) => {
    const isInvalid = !isValidFirstOrLastName(value, 2, 30);
    const error = isInvalid ? t('errorMessages.invalid-name') : '';

    dispatch(SignupFormActions.updateField({
      name,
      value: capitalizeFirstLetter(value),
      error,
    }));
  };

  const updatePassword = ({ target: { name, value } }) => {
    dispatch(SignupFormActions.updateField({
      name,
      value,
    }));
  };

  const validatePassword = ({ target: { name, value } }) => {
    const isInvalid = !isValidPassword(value);
    const isPasswordsMatch = password?.value?.toString() === repeatedPassword?.value?.toString();
    let error = '';

    if (isInvalid) error = t('errorMessages.invalid-password');
    if (_.isEmpty(value)) error = t('errorMessages.empty-value');
    if (name === 'repeatedPassword' && !isPasswordsMatch) error = t('errorMessages.password-doesnt-match');

    dispatch(SignupFormActions.updateField({
      name,
      value,
      error,
    }));
  };

  const isSignUpDisabled = () => {
    const isPasswordsMatch = password?.value?.toString() === repeatedPassword?.value?.toString();

    return Boolean(!primaryRegion || !isPasswordsMatch || !confirmationCode?.value
      || !firstName.value || !lastName.value || !password.value || !repeatedPassword.value
      || firstName.error || lastName.error || password.error || repeatedPassword.error);
  };

  return (
    <div className="sign-up-wrap">
      <LandingText hasOverlay />
      <Main
        className="sign-up"
        hasFooter={false}
        classNameHeader="gray"
      >
        <div className="title-wrapper">
          <div className="title">
            <BackArrowBtn />
            {t('signUp.title')}
          </div>
        </div>
        <div className="enter-link">
          <span className="label">{t('signUp.already-have-account')}</span>
          &nbsp; &nbsp;
          <Button
            value={t('signUp.to-sign-in')}
            onClick={() => navigate('/sign-in')}
          />
        </div>
        <div className="content-wrapper">
          <div className="content-wrapper-inner">
            <div className="content-wrapper-top-section">
              <Label value={t('signUp.sms-code')} />
              <Input
                value={confirmationCode?.value ?? ''}
                error={confirmationCode?.error}
                onChange={updateConfirmationCode}
                placeholder={t('tfa.code')}
                name="confirmationCode"
              />

              <Label value={t('signUp.first-name')} />
              <Input
                onChange={updateName}
                onBlur={validateName}
                placeholder={t('signUp.first-name')}
                name="firstName"
                value={firstName?.value ?? ''}
                error={firstName.error}
              />

              <Label value={t('signUp.last-name')} />
              <Input
                value={lastName?.value ?? ''}
                error={lastName.error}
                onChange={updateName}
                onBlur={validateName}
                placeholder={t('signUp.last-name')}
                name="lastName"
              />

              <Label value={t('signUp.region')} />
              <Dropdown
                hasInternalTranslation
                isDisabled={!_.isEmpty(primaryRegion)}
                options={regions?.length ? regions : []}
                onChange={(selectedOption) => {
                  if (!selectedOption?.value) return;
                  updateRegion(selectedOption.value);
                }}
                value={primaryRegion
                  ? regions.find((item) => item.value === selectedRegion)
                  : null}
              />

              <Label value={t('signUp.password')} />
              <Input
                value={password?.value ?? ''}
                error={password.error}
                onChange={updatePassword}
                onBlur={validatePassword}
                name="password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
                type={showPassword ? 'text' : 'password'}
                withIcon
                iconClassName={clsx('icon-at-right', 'password', showPassword && 'active')}
                iconSrc="/src/client/assets/icons/password-eye.svg"
                onIconClick={() => setShowPassword(!showPassword)}
              />

              <Label value={t('signUp.repeat-password')} />
              <Input
                value={repeatedPassword?.value ?? ''}
                error={repeatedPassword.error}
                onChange={updatePassword}
                onBlur={validatePassword}
                name="repeatedPassword"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
                type={showRepeatedPassword ? 'text' : 'password'}
                withIcon
                iconClassName={clsx('icon-at-right', 'password', showRepeatedPassword && 'active')}
                iconSrc="/src/client/assets/icons/password-eye.svg"
                onIconClick={() => setShowRepeatedPassword(!showRepeatedPassword)}
              />
            </div>

            <div className="content-wrapper-bottom-section">
              <Button
                value={t('signUp.sign-up')}
                type="rounded"
                onClick={async (e) => {
                  e.preventDefault();

                  if (isSignUpDisabled()) return;

                  const resTfa = await API.user.tfa.verifyConfirmationCode(userId, confirmationCode.value);

                  if (!resTfa.ok || !resTfa.data?.isConfirmationCodeValid) {
                    const msg = resTfa.data?.error?.message;
                    showToast(msg ? t(`errorMessagesHelper.${msg}`) : t('errorMessages.something-went-wrong'), 'error');
                    return;
                  }

                  const deliveryData = {
                    type: 'Самовивіз',
                    city: primaryRegion,
                  };

                  const res = await API.user
                    .setPassword(userId, firstName.value, lastName.value, password.value, primaryRegion, deliveryData);

                  if (res?.data?.jwtToken) {
                    AuthService.setToken(res.data.jwtToken);
                    dispatch(ProfileActions.getUserProfileSuccess(res.data?.profile));
                    showToast(res.data?.message || t('successMessages.data-updated-successfully'), 'success');
                    navigate('/profile');
                  } else {
                    const msg = res.data?.error?.message;
                    showToast(msg ? t(`errorMessagesHelper.${msg}`) : t('errorMessages.something-went-wrong'), 'error');
                  }
                }}
                isDisabled={isSignUpDisabled()}
              />

              <div className="sm-bottom-text">
                <div className="sm-bottom-wrapper">
                  {isTimeCounting && (
                    <Timer
                      timeToStop={timeToStop}
                      setIsTimeCounting={() => {
                        localStorage.setItem('signupSmsBlockedUntill', null);
                        setTimeToStop(null);
                        setIsTimeCounting(false);
                      }}
                      className="timer-margin"
                    />
                  )}
                  <div className="sm-btn-wrapper">
                    <Button
                      value={t('tfa.send-confirmation-again-sms')}
                      isDisabled={_.isEmpty(phone?.value) || isTimeCounting}
                      isProcessing={isLoading}
                      onClick={async (e) => {
                        e.preventDefault();

                        const res = await API.user.tfa.sendConfirmationCode(userId, phone.value, true);
                        dispatch(tfaFormActions.updateConfirmationCode({ value: '' }));

                        if (!res.ok) {
                          const msg = res.data?.error?.message;
                          showToast(msg ? t(`errorMessagesHelper.${msg}`)
                            : t('errorMessages.something-went-wrong'), 'error');
                          return;
                        }

                        const time = new Date().getTime() + 30000;
                        localStorage.setItem('signupSmsBlockedUntill', time);
                        setTimeToStop(time);
                      }}
                    />
                    <Button
                      value={t('tfa.send-confirmation-again-viber')}
                      isDisabled={_.isEmpty(phone?.value) || isTimeCounting}
                      isProcessing={isLoading}
                      onClick={async (e) => {
                        e.preventDefault();

                        const res = await API.user.tfa.sendConfirmationCodeViaViber(userId, phone.value, true);
                        dispatch(tfaFormActions.updateConfirmationCode({ value: '' }));

                        if (!res.ok) {
                          const msg = res.data?.error?.message;
                          showToast(msg ? t(`errorMessagesHelper.${msg}`)
                            : t('errorMessages.something-went-wrong'), 'error');
                          return;
                        }

                        const time = new Date().getTime() + 30000;
                        localStorage.setItem('signupSmsBlockedUntill', time);
                        setTimeToStop(time);
                      }}
                    />
                  </div>
                </div>

                <span>{t('signUp.by-clicking-you-accept-the-terms')}</span>
                <a
                  href="/user-agreement"
                  className="button text-blue"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('signUp.of-user-agreement')}

                </a>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  );
};

Signup.propTypes = {
  toastManager: PropTypes.shape(notificationPropTypes).isRequired,
};

export default withToastManager(Signup);
