export default [
  {
    systemType: 'extendable', // Розсувна
    defaultDoorsAmount: 2,
    defaultMinDoorsAmount: 2,
    defaultMaxDoorsAmount: 8,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '119',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'chessboard',
    minDoorOpeningWidth: 600,
    maxDoorOpeningWidth: 5400,
    minDoorOpeningHeight: 300,
    maxDoorOpeningHeight: 3200,
    minDoorWidth: 300,
    maxDoorWidth: 1500,
  }, {
    systemType: 'opening', // Відкривна
    defaultMinDoorsAmount: 1,
    defaultMaxDoorsAmount: 2,
    defaultDoorsAmount: 1,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '119',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'chessboard',
    minDoorOpeningWidth: 200,
    maxDoorOpeningWidth: 600,
    minDoorOpeningHeight: 300,
    maxDoorOpeningHeight: 2500,
    minDoorWidth: 200,
    maxDoorWidth: 600,
  }, {
    systemType: 'monorail', // Монорельс
    defaultDoorsAmount: 1,
    defaultMinDoorsAmount: 1,
    defaultMaxDoorsAmount: 10,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '119',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'chessboard',
    minDoorOpeningWidth: 300,
    maxDoorOpeningWidth: 5400,
    minDoorOpeningHeight: 300,
    maxDoorOpeningHeight: 3200,
    minDoorWidth: 300,
    maxDoorWidth: 1400,
  }, {
    systemType: 'assembling', // Складальна
    defaultMinDoorsAmount: 2,
    defaultMaxDoorsAmount: 4,
    defaultDoorsAmount: 2,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '119',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'chessboard',
    minDoorOpeningWidth: 300,
    maxDoorOpeningWidth: 1500,
    minDoorOpeningHeight: 300,
    maxDoorOpeningHeight: 2600,
    minDoorWidth: 300,
    maxDoorWidth: 1500,
  }, {
    systemType: 'hinged', // Навісна
    defaultMinDoorsAmount: 2,
    defaultMaxDoorsAmount: 4,
    defaultDoorsAmount: 2,
    defaultMinSectionsAmount: 0,
    defaultMaxSectionsAmount: 7,
    defaultSideProfile: '419',
    defaultDirectionOfSections: 'horizontal',
    defaultDoorPositioning: 'left-front',
    defaultSidewallThickness: 18,
    minSidewallThickness: 16,
    maxSidewallThickness: 36,
    minDoorOpeningWidth: 300,
    maxDoorOpeningWidth: 5400,
    minDoorOpeningHeight: 400,
    maxDoorOpeningHeight: 2700,
    minDoorWidth: 300,
    maxDoorWidth: 1400,
  }, {
    systemType: 'suspended', // Підвісна
    defaultMinDoorsAmount: 1,
    defaultMaxDoorsAmount: 8,
  },
];
