import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'hookrouter';
import Collapsible from 'react-collapsible';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import useCurrency from '../../helpers/useCurrency';

import AuthService from '../../services/authService';

import MyAcceptedOrdersActions from '../../redux/actions/myAcceptedOrders';
import DoorsActions from '../../redux/actions/doorsAndSections';

import { getFilling } from '../EditOrder';
import Label from '../Label';
import { numberWithSpaces } from '../../helpers/sanitizer';


const EditOrderFacades = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['components']);
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';
  const currentCurrency = useCurrency();

  const [activeTrigger, setActiveTrigger] = useState(null);

  const { showPrice } = useSelector(({ profile }) => profile);

  const { aluminiumColors } = useSelector(({ config }) => config);

  const { orders } = useSelector(({ myAcceptedOrders }) => myAcceptedOrders);

  const {
    activeDoor = 0,
    doors,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const {
    currentOrderId,
    isOrderAccepted,
    frameSpecification,
  } = useSelector(({ order }) => order);

  useEffect(() => {
    dispatch(MyAcceptedOrdersActions.getMyAcceptedOrdersRequest());
    dispatch(DoorsActions.setActiveDoor(1));
  }, []);

  const { currentSystem } = useSelector(({ systems }) => systems);
  useEffect(() => { if (activeDoor) setActiveTrigger(activeDoor - 1); }, [activeDoor]);

  const onEditDoorClick = (doorIndex) => {
    if (currentOrderId) {
      navigate(`/${currentSystem}/${currentOrderId}/edit`);
      dispatch(DoorsActions.setActiveDoor(doorIndex));
      return;
    }
    navigate(`/${currentSystem}/edit`);
    dispatch(DoorsActions.setActiveDoor(doorIndex));
  };

  const triggers = [];

  const statusName = orders.find(({ _id }) => _id === currentOrderId)?.status;

  useEffect(() => {
    if (statusName !== 'new' && typeof statusName !== 'undefined') navigate(`/order/${currentOrderId}/view`);
  });

  doors.map((door, doorIndex) => {
    const isOpen = activeTrigger === doorIndex;
    const triggerClassName = clsx(
      'edit-order--trigger-wrapper',
      isOpen && 'open',
    );

    triggers[doorIndex] = (
      <div
        className={triggerClassName}
        key={`trigger-${doorIndex + 1}`}
      >
        <p className="trigger-text">
          <span className="trigger-name">{door.groupName.value}</span>
          { !isOrderAccepted
            ? (
              <span>
                <button
                  type="button"
                  className={window.location.href.endsWith('/view') ? 'disabled' : 'rectangle '}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditDoorClick(doorIndex + 1);
                  }}
                >
                  <ReactSVG
                    wrapper="span"
                    src="/src/client/assets/icons/edit-pencil.svg"
                  />
                  <span className="button-label">
                    &nbsp;
                    {t('fillingMaterialsControl.change')}
                  </span>
                </button>
              </span>
            )
            : null }
        </p>

        <img
          className="trigger-image"
          src={isOpen
            ? '/src/client/assets/icons/orderPage/white-arrow-down.svg'
            : '/src/client/assets/icons/orderPage/white-arrow-up.svg'}
          alt="arrow"
        />
      </div>
    );
    return door;
  });

  const renderCollapsibleBlocks = () => doors.map((door, doorIndex) => {
    const isOpen = activeTrigger === doorIndex;
    const index = doorIndex;

    const {
      sequenceNumber,
      height = door.frameOpeningHeight.value,
      width = door.frameOpeningWidth.value,
      frameAluminiumColor,
      filling,
      facadeType = door.facadeView.value,
      openingSide,
      fasteningElement,
      handles = door.holesForHandles.amount,
      sameFramesAmount,
      pearHoles,
    } = door;

    const getDrillingSchemeValue = () => {
      const { drillingScheme = '' } = fasteningElement;
      if (drillingScheme === 'frez_d35_45_9' || drillingScheme === 'frez_d35' || drillingScheme === 'hinge-ADS') return (t(`fasteningElement.${drillingScheme}`));
      return drillingScheme;
    };

    const totalFramePrice = _.round(frameSpecification?.specificationByFrame?.find((s) => s.frameNumber === sequenceNumber)?.specification?.totalPrice, 2) || 0;
    const pricePerFrame = _.round(totalFramePrice / Number(sameFramesAmount.value), 2) || 0;

    const matchingAluminiumColor = aluminiumColors.find((c) => c.articleCode === frameAluminiumColor.value);
    const aluminiumColorLabel = frameAluminiumColor?.value && aluminiumColors
      ? `${(matchingAluminiumColor || {})[labelKey] || ''} (${frameAluminiumColor?.value})`
      : '';
    const openingSideLabel = openingSide?.value ? t(`options:openingSides.${openingSide.value}`) : '';

    return (
      <Collapsible
        key={`container-${doorIndex + 1}`}
        trigger={triggers[doorIndex]}
        containerElementProps={isOpen ? { index } : null}
        onTriggerOpening={() => {
          setActiveTrigger(doorIndex);
          dispatch(DoorsActions.setActiveDoor(doorIndex + 1));
        }}
        onTriggerClosing={() => {
          setActiveTrigger(null);
          dispatch(DoorsActions.setActiveDoor(0));
        }}
        disabled={!isOpen}
        open={activeTrigger === doorIndex}
      >
        <>
          <div className="edit-order--block">

            <div className="edit-order--block-row">
              <div className="edit-order--block-column">
                <Label value={t('editOrder.system-type')} />
              </div>
              <div className="edit-order--block-column">
                <div className="label--wrapper value">
                  <div className="label">{t(`systemsChoise.${currentSystem}`)}</div>
                </div>
              </div>
            </div>

            <div className="edit-order--block-row">
              <div className="edit-order--block-column">
                <Label value={t('editOrder.frame-height')} />
              </div>
              <div className="edit-order--block-column">
                <div className="label--wrapper value">
                  <div className="label">
                    {height}
                    {t('editOrder.millimeters')}
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-order--block-row">
              <div className="edit-order--block-column">
                <Label value={t('editOrder.frame-width')} />
              </div>
              <div className="edit-order--block-column">
                <div className="label--wrapper value">
                  <div className="label">
                    {width}
                    {t('editOrder.millimeters')}
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-order--block-row">
              <div className="edit-order--block-column">
                <Label value={t('editOrder.profile')} />
              </div>
              <div className="edit-order--block-column">
                <div className="label--wrapper value">
                  <div className="label">
                    {door.frameProfile.value}
                  </div>
                </div>
              </div>
            </div>

            {door.reinforcingProfile.amount ? (
              <div className="edit-order--block-row">
                <div className="edit-order--block-column">
                  <Label value={t('editOrder.reinforcing-profile')} />
                </div>
                <div className="edit-order--block-column">
                  <div className="label--wrapper value">
                    <div className="label">
                      {door.reinforcingProfile.amount}
                      {t('editOrder.door-count')}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="edit-order--block-row">
              <div className="edit-order--block-column">
                <Label value={t('editOrder.profile-color')} />
              </div>
              <div className="edit-order--block-column">
                <div className="label--wrapper value">
                  <div className="label">
                    {aluminiumColorLabel}
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-order--block-row">
              <div className="edit-order--block-column">
                <Label value={t('editOrder.section-filling')} />
              </div>
              <div className="edit-order--block-column">
                <div className="label--wrapper value">
                  <div className="label">
                    {getFilling(filling[0])}
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-order--block-row">
              <div className="edit-order--block-column">
                <Label value={t('editOrder.facade-type')} />
              </div>
              <div className="edit-order--block-column">
                <div className="label--wrapper value">
                  <div className="label">{t(`editOrder.${facadeType}`)}</div>
                </div>
              </div>
            </div>

            {!pearHoles.amount && facadeType !== 'fixed' ? (
              <div className="edit-order--block-row">
                <div className="edit-order--block-column">
                  <Label value={t('editOrder.opening-side')} />
                </div>
                <div className="edit-order--block-column">
                  <div className="label--wrapper value">
                    <div className="label">{openingSideLabel}</div>
                  </div>
                </div>
              </div>
            ) : null }

            {fasteningElement.amount ? (
              <div className="edit-order--block-row">
                <div className="edit-order--block-column">
                  <Label value={t('editOrder.fastening-element')} />
                </div>
                <div className="edit-order--block-column">
                  <div className="label--wrapper value">
                    <div className="label">
                      {t(`fasteningElement.${fasteningElement.type}`)}
                    </div>
                  </div>
                  <div className="label--wrapper value">
                    <div className="label">
                      {fasteningElement.amount}
                      {t('editOrder.door-count')}
                    </div>
                  </div>
                  <div className="label--wrapper value">
                    <div className="label">
                      {getDrillingSchemeValue()}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {pearHoles.amount ? (
              <div className="edit-order--block-row">
                <div className="edit-order--block-column">
                  <Label value={t('editOrder.fastening-element')} />
                </div>
                <div className="edit-order--block-column">
                  <div className="label--wrapper value">
                    <div className="label">
                      {t('editOrder.pear-hole')}
                    </div>
                  </div>
                  <div className="label--wrapper value">
                    <div className="label">
                      {pearHoles.amount}
                      {t('editOrder.door-count')}
                    </div>
                  </div>
                  <div className="label--wrapper value">
                    <div className="label">
                      {t(`drillingPearHoles.${pearHoles.placementSide}`)}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {handles ? (
              <div className="edit-order--block-row">
                <div className="edit-order--block-column">
                  <Label value={t('editOrder.holes-for-handles')} />
                </div>
                <div className="edit-order--block-column">
                  <div className="label--wrapper value">
                    <div className="label">
                      {handles}
                      {t('editOrder.door-count')}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {sameFramesAmount.value ? (
              <div className="edit-order--block-row">
                <div className="edit-order--block-column">
                  <Label value={t('editOrder.number-of-frames')} />
                </div>
                <div className="edit-order--block-column">
                  <div className="label--wrapper value">
                    <div className="label">
                      {sameFramesAmount.value}
                      {t('editOrder.door-count')}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {sameFramesAmount.value && (currentOrderId || showPrice || !AuthService.isLoggedIn()) ? (
              <div className="edit-order--block-row">
                <div className="edit-order--block-column">
                  <Label value={t('editOrder.price-per-frame')} />
                </div>
                <div className="edit-order--block-column">
                  <div className="label--wrapper value">
                    <div className="label">
                      {`${numberWithSpaces(pricePerFrame)} ${currentCurrency}`}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {sameFramesAmount.value && (currentOrderId || showPrice || !AuthService.isLoggedIn()) ? (
              <div className="edit-order--block-row">
                <div className="edit-order--block-column">
                  <Label value={t('editOrder.total')} />
                </div>
                <div className="edit-order--block-column">
                  <div className="label--wrapper value">
                    <div className="label">
                      {`${numberWithSpaces(totalFramePrice)} ${currentCurrency}`}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

          </div>
        </>
      </Collapsible>
    );
  });


  return (
    <div className="edit-order--wrapper">
      <div className="edit-order--doors-collapsible">
        {renderCollapsibleBlocks()}
      </div>
    </div>
  );
};

export default EditOrderFacades;
