/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactPortal from '../../helpers/portal';

const Modal = ({
  children,
  opened,
  closeModal,
  type,
  className,
  shouldDisableBodyScroll,
  isOutsideClickDelayed,
}) => {
  const modalClassName = clsx('modal', type, className, opened && 'open');
  const modalRef = React.createRef();
  const contentRef = React.createRef();
  const {
    isWarningHeightModalOpen,
    isWarningDistanceModalOpen,
  } = useSelector(({ fasteningElement }) => fasteningElement); // TODO: remove this line (temporary solution, after opening the WarningDistanceModal, the function handleClickOutside  is called and the modal closes immediately)

  const {
    isOpenWarningFrameSizeModal,
  } = useSelector(({ doorsAndSections }) => doorsAndSections); // TODO: remove this line (temporary solution, after opening the WarningFrameSizeModal, the function handleClickOutside  is called and the modal closes immediately)

  useEffect(() => {
    if (opened && shouldDisableBodyScroll) disableBodyScroll(modalRef.current);
    else clearAllBodyScrollLocks();

    return () => clearAllBodyScrollLocks();
  }, [opened]);

  useEffect(() => {
    if (isOutsideClickDelayed) {
      setTimeout(() => window.addEventListener('click', handleClickOutside, true), 1000);
    } else {
      window.addEventListener('click', handleClickOutside, true);
    }

    window.addEventListener('keydown', handleEscapeKeydown);

    return () => {
      window.removeEventListener('keydown', handleEscapeKeydown);
      window.removeEventListener('click', handleClickOutside, true);
    };
  }, [closeModal]);

  const handleEscapeKeydown = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  const handleClickOutside = (e) => {
    if ((isWarningDistanceModalOpen && e.target.tagName === 'BODY')
    || (isOpenWarningFrameSizeModal && e.target.tagName === 'BODY')) return; // TODO: remove this condition (temporary solution, after opening the WarningDistanceModal, the function handleClickOutside  is called and the modal closes immediately)
    if ((isWarningHeightModalOpen && e.target.className === 'dropdown-option-label')
    || (isWarningHeightModalOpen && e.target.className === 'dropdown-option')) return; // TODO: remove this condition (temporary solution)

    if (contentRef.current && !contentRef.current.contains(e.target)) {
      closeModal();
    }
  };

  return (!opened || (
    <ReactPortal>
      <div ref={modalRef} className={modalClassName}>
        <div ref={contentRef} className="modal-wrapper">
          <div className="modal-inner">
            <div className="modal-close">
              <button className="modal-close-button" type="button" onClick={closeModal}>
                <div className="modal-close-button-cross" />
              </button>
            </div>
            <div className="modal-inner-children">
              {children}
            </div>
          </div>
        </div>
      </div>
    </ReactPortal>
  )
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  type: PropTypes.oneOf(['bottom', 'full-size', '']),
  opened: PropTypes.bool,
  closeModal: PropTypes.func,
  className: PropTypes.string,
  shouldDisableBodyScroll: PropTypes.bool,
  isOutsideClickDelayed: PropTypes.bool,
};

Modal.defaultProps = {
  type: '',
  opened: false,
  closeModal: () => {},
  className: null,
  shouldDisableBodyScroll: false,
  isOutsideClickDelayed: false,
};

export default Modal;
