import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import Input from '../Input';
import Label from '../Label';


const ManageAluminiumColorsModal = ({
  isOpen,
  className,
  colorForEdit,
  onCloseModal,
  onEditColor,
  onCreateColor,
}) => {
  const [articleCode, setArticleCode] = useState('');
  const [color, setColor] = useState('');
  const [labelUk, setLabelUk] = useState('');
  const [labelRu, setLabelRu] = useState('');
  const [labelEn, setLabelEn] = useState('');
  const [labelPl, setLabelPl] = useState('');
  const [popularity, setPopularity] = useState('');

  useEffect(() => {
    setArticleCode(colorForEdit.articleCode);
    setColor(colorForEdit.color);
    setLabelUk(colorForEdit.labelUk);
    setLabelRu(colorForEdit.labelRu);
    setLabelEn(colorForEdit.labelEn);
    setLabelPl(colorForEdit.labelPl);
    setPopularity(colorForEdit.popularity);
  }, [colorForEdit]);

  const isDisabledCreating = () => Boolean(!articleCode || !color
    || !labelUk || !labelRu || !labelEn || !labelPl || !popularity);
  const isDisabledEditing = () => Boolean(!_.isEmpty(colorForEdit)
    && (colorForEdit.articleCode === articleCode && colorForEdit.color === color
        && colorForEdit.labelUk === labelUk && colorForEdit.labelRu === labelRu && colorForEdit.labelEn === labelEn
        && colorForEdit.labelPl === labelPl && colorForEdit.popularity === popularity));


  const onSubmit = () => {
    if (_.isEmpty(colorForEdit)) {
      return onCreateColor({
        articleCode,
        color,
        labelUk,
        labelRu,
        labelEn,
        labelPl,
        popularity,
      });
    }
    return onEditColor(articleCode, {
      color,
      labelUk,
      labelRu,
      labelEn,
      labelPl,
      popularity,
    });
  };

  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
    >
      <h2 className="title">{_.isEmpty(colorForEdit) ? 'Створення кольору' : 'Редагування кольору'}</h2>

      <div className="content-wrapper">
        {!colorForEdit.articleCode && (
          <div className="item-wrapper">
            <Label value="Введіть артикль" />
            <Input
              type="text"
              placeholder="AO"
              value={articleCode}
              onChange={(e) => setArticleCode(e.target.value)}
              name="articleCode"
            />
          </div>
        )}

        <div className="item-wrapper">
          <Label value="Введіть колір" />
          <Input
            type="text"
            placeholder="linear-gradient(180deg, #B2A390 0%, #A0917C 100%)"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            name="color"
          />
        </div>

        <div className="item-wrapper">
          <Label value="Введіть український переклад" />
          <Input
            type="text"
            placeholder="Світла бронза"
            value={labelUk}
            onChange={(e) => setLabelUk(e.target.value)}
            name="labelUk"
          />
        </div>

        <div className="item-wrapper">
          <Label value="Введіть російський переклад" />
          <Input
            type="text"
            placeholder="Светлая бронза"
            value={labelRu}
            onChange={(e) => setLabelRu(e.target.value)}
            name="labelRu"
          />
        </div>

        <div className="item-wrapper">
          <Label value="Введіть англійський переклад" />
          <Input
            type="text"
            placeholder="Light bronze"
            value={labelEn}
            onChange={(e) => setLabelEn(e.target.value)}
            name="labelEn"
          />
        </div>

        <div className="item-wrapper">
          <Label value="Введіть польський переклад" />
          <Input
            type="text"
            placeholder="Jasny brąz"
            value={labelPl}
            onChange={(e) => setLabelPl(e.target.value)}
            name="labelPl"
            shouldHideFooter={false}
            maxLength={50}
          />
        </div>

        <div className="item-wrapper">
          <Label value="Введіть популярність" />
          <Input
            type="number"
            placeholder="0"
            value={popularity}
            onChange={(e) => setPopularity(e.target.value)}
            name="popularity"
          />
        </div>

      </div>
      <div className="action-buttons">
        <div className="action-buttons-inner">
          <button
            type="button"
            className="link-button"
            onClick={() => {
              onCloseModal();
            }}
          >
            <span>Відмінити</span>
          </button>
          <button
            type="button"
            className="blue-button"
            onClick={() => {
              onSubmit();
              onCloseModal();
            }}
            disabled={_.isEmpty(colorForEdit) ? isDisabledCreating() : isDisabledEditing()}
          >
            <span>{_.isEmpty(colorForEdit) ? 'Створити' : 'Редагувати'}</span>
          </button>
        </div>
      </div>

    </Modal>
  );
};

ManageAluminiumColorsModal.defaultProps = {
  isOpen: false,
  className: 'action-modal manage-color',
  colorForEdit: null,
  onEditColor: () => {},
  onCreateColor: () => {},
};

ManageAluminiumColorsModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  colorForEdit: PropTypes.shape({
    articleCode: PropTypes.string,
    color: PropTypes.string,
    labelUk: PropTypes.string,
    labelRu: PropTypes.string,
    labelEn: PropTypes.string,
    labelPl: PropTypes.string,
    popularity: PropTypes.string,
  }),
  onCloseModal: PropTypes.func.isRequired,
  onEditColor: PropTypes.func,
  onCreateColor: PropTypes.func,
};

export default ManageAluminiumColorsModal;
