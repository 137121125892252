import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactSVG } from 'react-svg';
import Modal from '../Modal';
import Checkbox from '../Checkbox';

const WarningFrameSizeModal = ({
  isOpen,
  onCloseModal,
  className,
  onSubmit,
}) => {
  const [isAgree, setIsAgree] = useState(false);
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={() => { onCloseModal(); setIsAgree(false); }}
      className={className}
      shouldDisableBodyScroll
      isOutsideClickDelayed
    >
      <div className="modal-order-icon-wrapper">
        <ReactSVG
          wrapper="div"
          className="modal-order-icon"
          src="/src/client/assets/icons/order-icon.svg"
        />
      </div>
      <h2 className="headings-h2">{t('WarningFrameSizeModal.title')}</h2>
      <div className="content-wrapper">
        <Checkbox
          isChecked={isAgree}
          label={t('strengthProfileModal.subtitle')}
          onChange={() => setIsAgree(!isAgree)}
          key="strengthProfileModal"
          name="strengthProfileModal"
        />
      </div>
      <div className="action-buttons">
        <div className="action-buttons-inner">
          <button
            type="button"
            className="link-button"
            onClick={() => { onCloseModal(); setIsAgree(false); }}
          >
            <span>{t('strengthProfileModal.cancel')}</span>
          </button>
          <button
            type="button"
            className="blue-button"
            disabled={!isAgree}
            onClick={() => { onSubmit(); setIsAgree(false); }}
          >
            <span>{t('strengthProfileModal.submit')}</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

WarningFrameSizeModal.defaultProps = {
  isOpen: false,
  className: 'action-modal strength-profile',
  onSubmit: () => {},
};

WarningFrameSizeModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default WarningFrameSizeModal;
