import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import Modal from '../Modal';

const TransparentFillingWarning = ({
  isOpen,
  onCloseModal,
  onSubmit,
  className,
}) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
      isOutsideClickDelayed
    >
      <div className="modal-order-icon-wrapper">
        <ReactSVG
          wrapper="div"
          className="modal-order-icon"
          src="/src/client/assets/icons/order-icon.svg"
        />
      </div>
      <h2 className="headings-h2">{t('transparentFillingWarning.title')}</h2>
      <div className="action-buttons">
        <div className="action-buttons-inner">
          <button
            type="button"
            className="link-button"
            onClick={onCloseModal}
          >
            <span>{t('transparentFillingWarning.submit')}</span>
          </button>
          <button
            type="button"
            className="blue-button"
            onClick={onSubmit}
          >
            <span>{t('transparentFillingWarning.cancel')}</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

TransparentFillingWarning.defaultProps = {
  isOpen: false,
  className: 'action-modal transparent-filling-warning',
};

TransparentFillingWarning.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TransparentFillingWarning;
