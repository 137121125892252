import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Timer = ({
  timeToStop,
  setIsTimeCounting,
  className,
}) => {
  const [timeLeft, setTimeLeft] = useState(30);
  const [isCounting, setIsCounting] = useState(false);

  const getPadTime = (value) => String(value).padStart(2, '0');

  const minutes = getPadTime(Math.floor(timeLeft / 60));
  const seconds = getPadTime(Math.floor(timeLeft - minutes * 60));

  useEffect(() => {
    const currentTime = Date.now();
    const deltaTime = (timeToStop - currentTime) / 1000;

    if (deltaTime) {
      setTimeLeft(deltaTime);
      setIsCounting(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isCounting) {
        setTimeLeft((prev) => (prev >= 1 ? prev - 1 : 0));
      }
    }, 1000);
    if (timeLeft === 0) {
      setIsCounting(false);
      setIsTimeCounting();
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timeLeft, isCounting]);

  const timerClassName = clsx('timer', className);

  return (
    <div
      className={timerClassName}
    >
      <span className="minutes">{minutes}</span>
      <span className="separator">&nbsp;&#58;&nbsp;</span>
      <span className="seconds">{seconds}</span>
    </div>
  );
};

Timer.propTypes = {
  timeToStop: PropTypes.number.isRequired,
  setIsTimeCounting: PropTypes.func,
  className: PropTypes.string,
};

Timer.defaultProps = {
  setIsTimeCounting: () => { },
  className: '',
};

export default Timer;
