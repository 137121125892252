import React from 'react';
import { Group, Label, Line, Rect, Tag, Text } from 'react-konva';
import PropTypes from 'prop-types';
import { randomKey } from '../../helpers/visualisationHelper';

const StrengthProfile = ({
  reinforcingProfile,
  width,
  height,
  activeProfile,
  isScaled,
  paddingLeft,
  paddingTop,
  fontSize,
}) => {
  const RECTANGLE_PADDING = 4; // padding 1 and 2 rect
  const WIDTH_RECTANGLE = 10; // padding 2 and 3 rect
  const RECTANGLE_HEIGHT = 5;
  const distanceGap = 60;

  return reinforcingProfile.items.map((e, i) => {
    const textLength = e.distance.toString().length;

    const yCoordinate = height + paddingTop - RECTANGLE_HEIGHT / 2 - e.distance;
    const xCoordinate = (width / (reinforcingProfile.items.length + 1)) * e.sequenceNumber + paddingLeft;

    const rectangleWidth = width - (RECTANGLE_PADDING * 2 + WIDTH_RECTANGLE * 2) + 6; // 6 borders width
    const item = i + 1;
    const strokeColor = activeProfile === item ? '#3A84E3' : '#201720';
    const xPoint = isScaled ? xCoordinate - WIDTH_RECTANGLE - 10 : xCoordinate - WIDTH_RECTANGLE;
    const yCordFirstBreak = (height - yCoordinate) / 2 + yCoordinate - distanceGap;
    const yCordSecondBreak = (height - yCoordinate) / 2 + yCoordinate + distanceGap;

    const yTextCoordinate = height - (height - yCoordinate) / 2 + (textLength * 10);

    return (
      e.distance ? (
        <Group key={randomKey()}>
          <Line
            points={[
              xCoordinate,
              yCoordinate + RECTANGLE_HEIGHT / 2,
              xCoordinate,
              yCordFirstBreak,
            ]}
            stroke={strokeColor}
            strokeWidth={1}
          />

          <Line
            points={[
              xCoordinate,
              yCordSecondBreak,
              xCoordinate,
              paddingTop + height,
            ]}
            stroke={strokeColor}
            strokeWidth={1}
          />

          <Label
            x={xPoint}
            y={yTextCoordinate}
            rotation={270}
          >
            <Tag fill="transparent" stroke="transparent" />
            <Text
              text={e.distance}
              padding={5}
              stroke={strokeColor}
              fontSize={fontSize}
            />
          </Label>

          <Rect
            key={e.sequenceNumber}
            x={paddingLeft + WIDTH_RECTANGLE}
            y={yCoordinate}
            width={rectangleWidth}
            height={RECTANGLE_HEIGHT}
            stroke={strokeColor}
            strokeWidth={1}
            dash={[4, 4]}
          />
        </Group>
      ) : null
    );
  });
};

StrengthProfile.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  activeProfile: PropTypes.number,
  isScaled: PropTypes.bool,
  paddingLeft: PropTypes.number,
  paddingTop: PropTypes.number,
  fontSize: PropTypes.number,
};

export default StrengthProfile;
