import _ from 'lodash';
import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Stage, Layer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';

import SideSize from './SideSize';
import Rectangles from './Rectangles';
import StrengthProfile from './StrengthProfile';
import CornerLines from './CornerLines';
import Background from './Background';
import Handles from './Handles';
import FixedHoles from './FixedHoles';
import Fastening from './Fastening';
import FrameMobileMenu from '../FrameMobileMenu';
import { onMirrorHorizontal, onMirrorVertical } from '../../helpers/frameHelper';
import DoorsActions from '../../redux/actions/doorsAndSections';

const VisualisationFacades = forwardRef(({ frameNumber }, ref) => {
  const dispatch = useDispatch();

  const {
    activeDoor,
    doors,
    frameActiveInput,
    mainFrame,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const {
    frameProfile,
    frameOpeningHeight,
    frameOpeningWidth,
    openingSide,
    reinforcingProfile,
    frameAluminiumColor: { value: color } = {},
    filling: mainFilling,
    isShowSizes,
    profileWidth: {
      width: profileFrameWidth,
      tooth: toothWidth,
    },
    facadeView: { value: facadeType },
    holesForHandles,
    fasteningElement: fasteningMain,
    pearHoles,
  } = mainFrame;

  const { holesForHandles: modalHoles, isOpenDrillingHoles } = useSelector(({ drillingHoles }) => drillingHoles);
  const {
    fasteningElement: fasteningModal,
    isFasteningElementOpen,
    isWarningHeightModalOpen,
  } = useSelector(({ fasteningElement }) => fasteningElement);

  const {
    isOpenDrillingPearHoles,
    pearHoles: modalPearHoles,
  } = useSelector(({ drillingPearHoles }) => drillingPearHoles);

  const {
    aluminiumColors,
  } = useSelector(({ config }) => config);

  const { innerWidth, innerHeight } = window || {};

  const isMobile = innerWidth < 1024;
  const [scaleMobileCanvas, setScaleMobileCanvas] = useState(1);

  const increaseScale = () => {
    setScaleMobileCanvas(scaleMobileCanvas + 0.1);
  };

  const decreaseScale = () => {
    setScaleMobileCanvas(scaleMobileCanvas - 0.1);
  };

  const onMirrorHorizontalClick = () => {
    const frameToUpdate = onMirrorHorizontal(activeDoor ? doors[activeDoor - 1] : mainFrame);

    dispatch(DoorsActions.invertFrame(true));
    dispatch(DoorsActions.setInvertedFrame(activeDoor, frameToUpdate));
  };

  const onMirrorVerticalClick = () => {
    const frameToUpdate = onMirrorVertical(activeDoor ? doors[activeDoor - 1] : mainFrame);

    dispatch(DoorsActions.invertFrame(true));
    dispatch(DoorsActions.setInvertedFrame(activeDoor, frameToUpdate));
  };

  const setToggleValue = () => {
    dispatch(DoorsActions.toggleShowSizes(activeDoor, !showSize));
  };

  const activeFrame = frameNumber || activeDoor;

  const width = activeFrame
    ? Number(doors[activeFrame - 1]?.frameOpeningWidth?.value) : Number(frameOpeningWidth?.value);
  const height = activeFrame
    ? Number(doors[activeFrame - 1]?.frameOpeningHeight?.value) : Number(frameOpeningHeight?.value);

  const rfProfile = activeFrame ? doors[activeFrame - 1]?.reinforcingProfile : reinforcingProfile;
  const sideOpen = activeFrame ? doors[activeFrame - 1]?.openingSide : openingSide;
  const profile = activeFrame ? doors[activeFrame - 1]?.frameProfile : frameProfile;
  const showSize = activeFrame ? doors[activeFrame - 1]?.isShowSizes : isShowSizes;
  const widthProfile = activeFrame ? doors[activeFrame - 1]?.profileWidth?.width : profileFrameWidth;
  const toothProfile = activeFrame ? doors[activeFrame - 1]?.profileWidth?.tooth : toothWidth;
  const handleHoles = activeFrame ? doors[activeFrame - 1]?.holesForHandles : holesForHandles;
  const pearHolesArr = activeFrame ? doors[activeFrame - 1]?.pearHoles : pearHoles;
  const isFacadeType = activeFrame ? doors[activeFrame - 1]?.facadeView?.value : facadeType;
  const fasteningHoles = activeFrame ? doors[activeFrame - 1]?.fasteningElement : fasteningMain;
  const isOpeningFacade = isFacadeType === 'opening';
  // const isPulloutFacade = facadeType === 'pullout';
  const isFixedFacade = isFacadeType === 'fixed';

  const isLiftingMechanism = isFasteningElementOpen
    ? fasteningModal.type === 'lifting-mechanism' : fasteningHoles.type === 'lifting-mechanism';
  const isBottomModule = isFasteningElementOpen ? fasteningModal.withBottomModule : fasteningHoles.withBottomModule;

  const canvasHeight = innerHeight - 70 - 20; // 70 header height, 20 bottom padding
  const canvasWidth = innerWidth - 80 - 230 - 433; // 80 side padding, 230 left sidebar, 433 right sidebar

  const isRightSideOpen = sideOpen.value === 'right';
  const isLeftSideOpen = sideOpen.value === 'left';
  const rightFasteningPadding = isRightSideOpen && isFasteningElementOpen
    ? fasteningModal.amount * 30 : (isRightSideOpen && fasteningHoles.amount) ? fasteningHoles.amount * 30 : 0;
  const rightHandlePadding = modalHoles.amount && modalHoles.placementSide === 'right' ? modalHoles.amount * 30
    : handleHoles.amount && handleHoles.placementSide === 'right' ? handleHoles.amount * 30 : 0;

  const leftFasteningPadding = isLeftSideOpen && isFasteningElementOpen
    ? fasteningModal.amount * 30 : isLeftSideOpen && fasteningHoles.amount ? fasteningHoles.amount * 30 : 0;
  const leftHandlePadding = modalHoles.amount && modalHoles.placementSide === 'left' ? modalHoles.amount * 30
    : handleHoles.amount && handleHoles.placementSide === 'left' ? handleHoles.amount * 30 : 0;
  const topHandlePadding = modalHoles.amount && modalHoles.placementSide === 'top' ? modalHoles.amount * 20
    : handleHoles.amount && handleHoles.placementSide === 'top' ? handleHoles.amount * 20 : 0;
  const bottomHandlePadding = modalHoles.amount && modalHoles.placementSide === 'bottom' ? modalHoles.amount * 20
    : handleHoles.amount && handleHoles.placementSide === 'bottom' ? handleHoles.amount * 20 : 0;
  const topPearPadding = isOpenDrillingPearHoles && modalPearHoles.amount && modalPearHoles.placementSide === 'top'
    ? modalPearHoles.amount * 30
    : pearHolesArr.amount && pearHolesArr.placementSide === 'top' ? pearHolesArr.amount * 30
      : 0;
  const leftPearPadding = isOpenDrillingPearHoles && modalPearHoles.amount
    && modalPearHoles.placementSide === 'left-right'
    ? modalPearHoles.amount * 30
    : pearHolesArr.amount && pearHolesArr.placementSide === 'left-right' ? pearHolesArr.amount * 30
      : 0;
  const leftDoubleModule = isBottomModule ? 40 : 0;

  const basicDistance = 50;
  const paddingTop = basicDistance + topHandlePadding + topPearPadding;
  const paddingBottom = bottomHandlePadding + 40;
  const paddingLeft = basicDistance + leftFasteningPadding + leftHandlePadding
    + leftPearPadding + leftDoubleModule;
  const paddingRight = rightHandlePadding + rightFasteningPadding;

  const calcWidth = width + 10 + paddingRight + paddingLeft; // 10px - for right side show edge fixed hole 160
  const calcHeight = height + paddingTop + paddingBottom + basicDistance;
  const colorDoor = activeFrame ? doors[activeFrame - 1]?.frameAluminiumColor?.value : color;
  const frameColor = colorDoor && aluminiumColors?.find((c) => c.articleCode === colorDoor)?.color;
  const fillingFrame = activeFrame ? doors[activeFrame - 1]?.filling : mainFilling;

  const scaleHeight = canvasHeight - 160 < height ? canvasHeight / (height + 300) : 1;
  const scaleWidth = canvasWidth < width ? canvasWidth / (width + 100) : 1;
  const scaleDesktop = scaleHeight > scaleWidth ? scaleWidth : scaleHeight;

  const isScaled = Boolean(!Number(scaleDesktop.toFixed(0)));

  const scaleWidthMobile = canvasWidth < width - 50 ? Math.abs(canvasWidth / (width + 100)) : 1;
  const scaleHeightMobile = canvasHeight - 160 < height ? Math.abs(canvasHeight / (height + 300)) : 1;

  const scaleMobile = scaleWidthMobile > scaleHeightMobile ? scaleHeightMobile : scaleWidthMobile;

  const fitWidth = isMobile ? calcWidth * scaleMobile : calcWidth * scaleHeight;
  const fitHeight = isMobile ? calcHeight * scaleMobile : calcHeight * scaleHeight;
  const scale = isMobile ? scaleMobile : scaleDesktop;

  const sideSizeHoles = pearHolesArr.amount ? pearHolesArr : handleHoles;
  const sideModalHoles = modalHoles.amount ? modalHoles : modalPearHoles;
  const isOpenHoles = isOpenDrillingHoles || isOpenDrillingPearHoles;

  const getFontSizeByDimensions = (frameHeight, frameWidth) => {
    const ranges = [
      { min: 1000, max: 1199, size: 17, widthCondition: true },
      { min: 1200, max: 1399, size: 20 },
      { min: 1400, max: 1599, size: 23 },
      { min: 1600, max: 1799, size: 26 },
      { min: 1800, max: 1999, size: 29 },
      { min: 2000, max: 2199, size: 32 },
      { min: 2200, max: Infinity, size: 35 },
    ];

    const size = ranges.reduce((currentSize, range) => {
      if ((frameHeight >= range.min && frameHeight <= range.max) || (range.widthCondition && frameWidth > 700)) {
        return range.size;
      }
      return currentSize;
    }, 14);

    return size;
  };

  const fontSize = getFontSizeByDimensions(height, width);

  const activeHole = {
    fasteningElement: 0,
    pearHoles: 0,
    holesForHandles: 0,
    reinforcingProfile: 0,
  };

  activeHole[frameActiveInput.name] = frameActiveInput.number;
  const imageSrc = '/src/client/assets/icons/loops/axis.svg';

  const mobileStyle = {
    transform: `scale(${scaleMobileCanvas})`,
    width: '100%',
    overflow: 'auto',
    transformOrigin: 'top left',
  };

  return (
    width && height ? (
      <div className="canvas-wrapper">
        <div style={mobileStyle}>
          <Stage
            ref={ref}
            className="facades-stage"
            width={fitWidth}
            height={fitHeight}
            scale={{
              x: scale,
              y: scale,
            }}
          >
            <Layer>
              {/* Horizontal and vertical size lines */}
              {showSize && (
                <SideSize
                  width={width}
                  height={height}
                  holes={sideSizeHoles}
                  modalHoles={sideModalHoles}
                  isOpenDrillingHoles={isOpenHoles}
                  isScaled={isScaled}
                  isLiftingMechanism={isLiftingMechanism}
                  isBottomModule={isBottomModule}
                  paddingTop={paddingTop}
                  paddingLeft={paddingLeft}
                  paddingBottom={paddingBottom}
                  fontSize={fontSize}
                  scale={scale}
                />
              )}

              {/* Frame */}
              <Rectangles
                width={width}
                height={height}
                frameColor={frameColor}
                frameProfile={profile}
                toothWidth={toothProfile}
                frameWidthDistance={widthProfile}
                isBottomModule={isBottomModule}
                paddingTop={paddingTop}
                paddingLeft={paddingLeft}
              />

              {/* Lines from edges rectangles */}
              <CornerLines
                width={width}
                height={height}
                frameProfile={profile}
                toothWidth={toothProfile}
                frameWidthDistance={widthProfile}
                paddingTop={paddingTop}
                paddingLeft={paddingLeft}
              />

              {/* Frame image */}
              <Background
                width={width}
                height={height}
                filling={fillingFrame}
                frameWidthDistance={widthProfile}
                isBottomModule={isBottomModule}
                paddingTop={paddingTop}
                paddingLeft={paddingLeft}
              />

              {/* Dashed rectangle in center */}
              {rfProfile.items && showSize
                && (
                  <StrengthProfile
                    width={width}
                    height={height}
                    reinforcingProfile={rfProfile}
                    activeProfile={activeHole?.reinforcingProfile}
                    isScaled={isScaled}
                    paddingTop={paddingTop}
                    paddingLeft={paddingLeft}
                    fontSize={fontSize}
                  />
                )}

              {showSize && isOpeningFacade && !isWarningHeightModalOpen && !_.isEmpty(fasteningHoles) && (
                <Fastening
                  width={width}
                  height={height}
                  openingSide={sideOpen}
                  fasteningMain={fasteningHoles}
                  fasteningModal={fasteningModal}
                  isFasteningElementOpen={isFasteningElementOpen}
                  activeHole={activeHole?.fasteningElement}
                  isScaled={isScaled}
                  isLiftingMechanism={isLiftingMechanism}
                  isBottomModule={isBottomModule}
                  paddingTop={paddingTop}
                  paddingLeft={paddingLeft}
                  fontSize={fontSize}
                />
              )}

              {/* Line for drills size */}
              {showSize && !_.isEmpty(handleHoles) && (
                <Handles
                  width={width}
                  height={height}
                  openingSide={sideOpen}
                  holes={handleHoles}
                  modalHoles={modalHoles}
                  isOpenDrillingHoles={isOpenDrillingHoles}
                  activeHole={activeHole?.holesForHandles}
                  isScaled={isScaled}
                  paddingTop={paddingTop}
                  paddingLeft={paddingLeft}
                  paddingBottom={paddingBottom}
                  fontSize={fontSize}
                />
              )}

              {showSize && isFixedFacade && !_.isEmpty(pearHolesArr) && (
                <FixedHoles
                  width={width}
                  height={height}
                  pearHoles={pearHolesArr}
                  modalPearHoles={modalPearHoles}
                  isOpenDrillingPearHoles={isOpenDrillingPearHoles}
                  activeHole={activeHole?.pearHoles}
                  isScaled={isScaled}
                  paddingTop={paddingTop}
                  paddingLeft={paddingLeft}
                  fontSize={fontSize}
                />
              )}

            </Layer>
          </Stage>
        </div>
        {
          innerWidth >= 1024 && (
            <img
              alt="axis"
              src={imageSrc}
              className="axis-img"
            />
          )
        }

        {
          innerWidth < 1024 && (
            <FrameMobileMenu
              increaseScale={increaseScale}
              decreaseScale={decreaseScale}
              onMirrorVerticalClick={onMirrorVerticalClick}
              onMirrorHorizontalClick={onMirrorHorizontalClick}
              setToggleValue={setToggleValue}
              showSize={showSize}
            />
          )
        }
      </div>
    ) : null
  );
});

VisualisationFacades.defaultProps = {
  frameNumber: 0,
};

VisualisationFacades.propTypes = {
  frameNumber: PropTypes.number,
};

export default VisualisationFacades;
