import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import VisualisationFacades from '../VisualisationFacades';
import { randomKey } from '../../helpers/visualisationHelper';

import DoorsActions from '../../redux/actions/doorsAndSections';

const FakeFacadesVisualization = ({ doors }) => {
  const dispatch = useDispatch();
  const canvasRefs = useRef([]);

  useEffect(() => {
    if (!_.isEmpty(canvasRefs.current)) {
      const frameImages = canvasRefs.current.map((ref, i) => ({
        sequenceNumber: i + 1,
        url: ref.toDataURL(),
        width: ref.attrs.width,
        height: ref.attrs.height,
      }));
      dispatch(DoorsActions.setFrameImages(frameImages));
    }
  }, []);

  return (
    <div className="fake-visualization" style={{ zIndex: -1 }}>
      {doors.map(({ sequenceNumber }, index) => (
        <div
          className="fake-visualization-item"
          style={{ zIndex: `-${sequenceNumber + 1}` }}
          key={randomKey}
        >
          <VisualisationFacades
            ref={(el) => { canvasRefs.current[index] = el; }}
            frameNumber={sequenceNumber}
          />
        </div>
      ))}

    </div>
  );
};

FakeFacadesVisualization.propTypes = {
  doors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FakeFacadesVisualization;
