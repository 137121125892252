import React from 'react';
import { Rect } from 'react-konva';
import PropTypes from 'prop-types';
import { frameColorPick } from '../../helpers/visualisationHelper';

const Rectangles = ({
  width,
  height,
  frameColor,
  toothWidth,
  frameWidthDistance,
  isBottomModule,
  paddingTop,
  paddingLeft,
}) => {
  const WIDTH = width;
  const HEIGHT = height;
  const frameColorToFill = frameColorPick(frameColor);

  return (
    (!isBottomModule ? (
      <>
        {/* Outer rectangle */}
        <Rect
          x={paddingLeft}
          y={paddingTop}
          width={WIDTH}
          height={HEIGHT}
          stroke="black"
          strokeWidth={1}
          fill={frameColorToFill}
        />

        {/* Middle rectangle */}
        {toothWidth > 0 && (
          <Rect
            x={paddingLeft + toothWidth}
            y={paddingTop + toothWidth}
            width={WIDTH - (toothWidth * 2)}
            height={HEIGHT - (toothWidth * 2)}
            stroke="black"
            strokeWidth={1}
          />
        )}

        {/* Inner rectangle */}
        <Rect
          x={paddingLeft + frameWidthDistance}
          y={paddingTop + frameWidthDistance}
          width={WIDTH - (frameWidthDistance * 2)}
          height={HEIGHT - (frameWidthDistance * 2)}
          stroke="black"
          strokeWidth={1}
        />
      </>
    ) : (
      <>
        {/* Outer rectangle */}
        <Rect
          x={paddingLeft}
          y={paddingTop}
          width={WIDTH}
          height={HEIGHT / 2 - 2}
          stroke="black"
          strokeWidth={1}
          fill={frameColorToFill}
        />

        {/* Middle rectangle */}
        {toothWidth > 0 && (
          <Rect
            x={paddingLeft + toothWidth}
            y={paddingTop + toothWidth}
            width={WIDTH - (toothWidth * 2)}
            height={(HEIGHT / 2 - 2) - (toothWidth * 2)}
            stroke="black"
            strokeWidth={1}
          />
        )}

        {/* Inner rectangle */}
        <Rect
          x={paddingLeft + frameWidthDistance}
          y={paddingTop + frameWidthDistance}
          width={WIDTH - (frameWidthDistance * 2)}
          height={(HEIGHT / 2 - 2) - (frameWidthDistance * 2)}
          stroke="black"
          strokeWidth={1}
        />

        {/* Outer rectangle */}
        <Rect
          x={paddingLeft}
          y={paddingTop + HEIGHT / 2 + 2}
          width={WIDTH}
          height={HEIGHT / 2 - 2}
          stroke="black"
          strokeWidth={1}
          fill={frameColorToFill}
        />

        {/* Middle rectangle */}
        {toothWidth > 0 && (
          <Rect
            x={paddingLeft + toothWidth}
            y={paddingTop + toothWidth + HEIGHT / 2 + 2}
            width={WIDTH - (toothWidth * 2)}
            height={(HEIGHT / 2 - 2) - (toothWidth * 2)}
            stroke="black"
            strokeWidth={1}
          />
        )}

        {/* Inner rectangle */}
        <Rect
          x={paddingLeft + frameWidthDistance}
          y={paddingTop + frameWidthDistance + HEIGHT / 2 + 2}
          width={WIDTH - (frameWidthDistance * 2)}
          height={(HEIGHT / 2 - 2) - (frameWidthDistance * 2)}
          stroke="black"
          strokeWidth={1}
        />
      </>
    ))
  );
};

Rectangles.defaultProps = {
  frameColor: '',
  frameWidthDistance: 0,
  toothWidth: 0,
  paddingTop: 0,
  paddingLeft: 0,
  isBottomModule: false,
};

Rectangles.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  frameColor: PropTypes.string,
  toothWidth: PropTypes.number,
  frameWidthDistance: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingLeft: PropTypes.number,
  isBottomModule: PropTypes.bool,
};

export default Rectangles;
