import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import Modal from '../Modal';
import Input from '../Input';


const SetGroupNameModal = ({
  isOpen,
  className,
  onCloseModal,
  currentFrameAmount,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [groupName, setGroupName] = useState('');

  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={onCloseModal}
      className={className}
      shouldDisableBodyScroll
    >
      <div className="modal-order-icon-wrapper">
        <ReactSVG
          wrapper="div"
          className="modal-order-icon"
          src="/src/client/assets/icons/order-icon.svg"
        />
      </div>
      <h2 className="headings-h2">{t('setGroupNameModal.title')}</h2>

      <div className="content-wrapper">
        <Input
          type="text"
          placeholder={t('setGroupNameModal.placeholder', { number: currentFrameAmount + 1 })}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          name="groupName"
          shouldHideFooter={false}
          maxLength={50}
        />
      </div>
      <div className="action-buttons">
        <div className="action-buttons-inner">
          <button
            type="button"
            className="link-button"
            onClick={() => { onCloseModal(); setGroupName(''); }}
          >
            <span>{t('setGroupNameModal.cancel')}</span>
          </button>
          <button
            type="button"
            className="blue-button"
            onClick={() => { onSubmit(groupName); onCloseModal(); setGroupName(''); }}
          >
            <span>{t('setGroupNameModal.submit')}</span>
          </button>
        </div>
      </div>

    </Modal>
  );
};

SetGroupNameModal.defaultProps = {
  isOpen: false,
  className: 'action-modal set-group-name',
};

SetGroupNameModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  currentFrameAmount: PropTypes.number.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,

};

export default SetGroupNameModal;
