import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ReactSVG } from 'react-svg';
import { removeLeadingZero } from '../../../helpers/sanitizer';

import DoorsActions from '../../../redux/actions/doorsAndSections';
import FillingActions from '../../../redux/actions/fillingMaterials';

import constantsForFrameProfiles from '../../../helpers/constantsForFrameProfiles';

import Modal from '../../Modal';
import Input from '../../Input';
import Label from '../../Label';
import Dropdown from '../../Dropdown';
import PlusMinusControl from '../../PlusMinusControl';
import FillingMaterialsChoice from '../../FillingMaterialsChoice';
import RadioOption from '../../RadioOption';
import RadioGroup from '../../RadioGroup';
import { isColorSideProfileAvailable } from '../../../helpers/priceHelper.mjs';
import { materialType } from '../../../helpers/options';
import { canUseHorizontalTexture } from '../../../helpers/validation';
import { frameProfileParameters } from '../../../../server/helpers/priceHelperOptions.mjs';

const SetFrameParamsModal = ({
  isOpen,
  prevWarningModalValue,
  className,
  availableProfiles,
  aluminiumColors,
  prices,
  currentFrameNumber,
  currentFrameName,
  onDeleteFrame,
  onChangeFilling,
  onCloseModal,
}) => {
  const { t, i18n } = useTranslation();
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';
  const dispatch = useDispatch();

  const {
    frameCreatingParams,
    frameCreatingStep,
    doors,
    isOpenFrameParamsModal,
    isOpenDeleteFrameModal,
    isOpenChangeFrameFillingModal,
    isOpenTransparentFillingWarning,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const { filling: allAvailableFilling } = useSelector(({ config }) => config);

  const fillingMaterialsState = useSelector(({ fillingMaterials }) => fillingMaterials);

  const {
    activeTrigger,
    customers: {
      customersOption,
      isMilling,
      isCustomTransparent,
    },
    dsp: {
      manufacturer,
      isDspUVPrinting,
      searchField,
      dspOption,
      dspUvPrintType,
    },
    mirror: {
      mirrorType,
      isMirrorMatted,
      isMirrorRearMatted,
      isMirrorFullMatted,
      isMirrorTransparent,
      mirrorColor,
      isMirrorUVPrinting,
      mirrorUvPrintType,
      isMirrorArmoredFilm,
      isMirrorLaminated,
      mirrorSearch,
    },
    lacobel: {
      lacobelType,
      isLacobelMatted,
      isLacobelRearMatted,
      isLacobelFullMatted,
      isLacobelTransparent,
      lacobelColor,
      isLacobelUVPrinting,
      lacobelUvPrintType,
      isLacobelArmoredFilm,
      isLacobelLaminated,
      lacobelSearch,
    },
    glass: {
      glassType,
      isGlassMatted,
      isGlassArmoredFilm,
      isGlassLaminated,
      isGlassOneColorPainted,
      isGlassTwoColorsPainted,
      glassColors,
      isGlassUVPrinting,
      glassUvPrintType,
      isGlassPhotoPrinting,
      isGlassTransparent,
      glassPhotoPrintType,
      glassSearch,
      isGlassFullMatted,
    },
    isOpenFillingModal,
  } = fillingMaterialsState;

  const [stepsAmount, setStepsAmount] = useState(4);
  const [step, setStep] = useState(1);
  const [currentFrameProfileSizes, setCurrentFrameProfileSizes] = useState(null);
  const [frameColorsDependence, setFrameColorsDependence] = useState([]);

  const [frameName, setFrameName] = useState('');
  const [frameProfileHeight, setFrameProfileHeight] = useState({});
  const [frameProfileWidth, setFrameProfileWidth] = useState({});
  const [frameConnectingCorner, setFrameConnectingCorner] = useState({});
  const [frameOpeningH, setFrameOpeningH] = useState('');
  const [frameOpeningW, setFrameOpeningW] = useState('');
  const [frameReinforcingProfileAmount, setFrameReinforcingProfileAmount] = useState(0);
  const [frameReinforcingProfileItems, setFrameReinforcingProfileItems] = useState([]);
  const [frameMilling, setFrameMilling] = useState(0);
  const [frameStrainer, setFrameStrainer] = useState(0);
  const [frameProfile, setFrameProfile] = useState('');
  const [frameAluminiumColor, setFrameAluminiumColor] = useState('');
  const [frameFilling, setFrameFilling] = useState({});
  const [frameFillingTexture, setFrameFillingTexture] = useState('');
  const [sameFrame, setSameFrame] = useState(0);
  const [reinforcingProfileAgreement, setReinforcingProfileAgreement] = useState({
    needToUseReinforcingProfile: false,
    agreementNotToUseReinforcingProfile: false,
  });
  const [heightGuaranteeAgreement, setHeightGuaranteeAgreement] = useState({
    heightLimitExceeded: false,
    agreement: false,
  });
  const [widthGuaranteeAgreement, setWidthGuaranteeAgreement] = useState({
    widthLimitExceeded: false,
    agreement: false,
  });

  useEffect(() => {
    if (!_.isEmpty(frameCreatingParams)) {
      setCurrentFrameProfileSizes(frameCreatingParams?.currentFrameProfileSizes);
      setFrameName(frameCreatingParams?.groupName?.value);
      setFrameProfileHeight(frameCreatingParams?.profileHeight);
      setFrameProfileWidth(frameCreatingParams?.profileWidth);
      setFrameConnectingCorner(frameCreatingParams?.connectingCorner);
      setFrameOpeningH(frameCreatingParams?.frameOpeningHeight?.value);
      setFrameOpeningW(frameCreatingParams?.frameOpeningWidth?.value);
      setFrameReinforcingProfileAmount(frameCreatingParams?.reinforcingProfile?.amount);
      setFrameReinforcingProfileItems(frameCreatingParams?.reinforcingProfile?.items);
      setFrameMilling(frameCreatingParams?.millingForReinforcingProfile?.amount);
      setFrameStrainer(frameCreatingParams?.strainerForReinforcingProfile?.amount);
      setFrameProfile(frameCreatingParams?.frameProfile?.value);
      setFrameAluminiumColor(frameCreatingParams?.frameAluminiumColor?.value);
      setFrameFilling(frameCreatingParams?.filling);
      setFrameFillingTexture(frameCreatingParams?.fillingTexture?.value);
      setSameFrame(frameCreatingParams?.sameFramesAmount?.value);
      setReinforcingProfileAgreement(frameCreatingParams?.guaranteeAgreement);
      setHeightGuaranteeAgreement(frameCreatingParams?.frameHeightGuaranteeAgreement);
      setWidthGuaranteeAgreement(frameCreatingParams?.frameWidthGuaranteeAgreement);

      setFrameColorsDependence(getFrameProfileColors(frameCreatingParams?.frameProfile?.value));
    }

    if (frameCreatingStep?.step && frameCreatingStep?.stepsAmount) {
      setStep(frameCreatingStep?.step);
      setStepsAmount(frameCreatingStep?.stepsAmount);
    }
  }, []);

  useEffect(() => {
    if (fillingMaterialsState?.activeTrigger && stepsAmount === 5 && step === 4) {
      dispatch(DoorsActions.setFrameCreatingParams({ ...getItemToCreate(), currentFrameProfileSizes }));
      dispatch(DoorsActions.setFrameCreatingStep({ step, stepsAmount }));
    }
  }, [fillingMaterialsState?.activeTrigger]);

  useEffect(() => {
    if ((frameCreatingStep?.stepsAmount === 5 && frameCreatingStep?.step === 2 && !_.isEmpty(frameCreatingParams))
      || (frameCreatingStep?.stepsAmount === 5 && frameCreatingStep?.step === 4 && !_.isEmpty(frameCreatingParams))) return;

    setFrameProfile(availableProfiles.map(({ articleCode }) => articleCode)[0]);
  }, [isOpenFrameParamsModal]);

  useEffect(() => {
    const sanitizedFrameProfile = frameProfile?.replace('-N', '');
    const sizes = constantsForFrameProfiles.find((p) => p.profile === sanitizedFrameProfile);

    if ((frameCreatingStep?.stepsAmount === 5 && frameCreatingStep?.step === 2 && !_.isEmpty(frameCreatingParams))
      || (frameCreatingStep?.stepsAmount === 4 && frameCreatingStep?.step === 2 && !_.isEmpty(frameCreatingParams))
      || (frameCreatingStep?.stepsAmount === 5 && frameCreatingStep?.step === 4 && !_.isEmpty(frameCreatingParams))) return;

    setFrameOpeningH('');
    setFrameOpeningW('');
    setFrameReinforcingProfileAmount(0);
    setFrameReinforcingProfileItems([]);
    setCurrentFrameProfileSizes(sizes);
    setFrameProfileHeight(sizes?.profileHeight);
    setFrameProfileWidth(sizes?.profileWidth);
    setFrameConnectingCorner(sizes?.connectingCorner);
    setFrameColorsDependence(getFrameProfileColors(frameProfile));
  }, [frameProfile]);

  useEffect(() => {
    if ((frameCreatingStep?.stepsAmount === 5 && frameCreatingStep?.step === 2 && !_.isEmpty(frameCreatingParams))
      || (frameCreatingStep?.stepsAmount === 5 && frameCreatingStep?.step === 4 && !_.isEmpty(frameCreatingParams))) return;

    const colorToSet = aluminiumColors.filter(({ articleCode }) => frameColorsDependence
      ?.includes(articleCode)).map(({ articleCode }) => articleCode)[0];

    setFrameAluminiumColor(colorToSet);
  }, [frameColorsDependence, frameProfile]);

  useEffect(() => {
    if (!isOpenChangeFrameFillingModal
      || (!isOpenTransparentFillingWarning && prevWarningModalValue)) return;

    const orderFilling = doors[currentFrameNumber - 1]?.filling[0];

    const fillingAction = {
      customers: () => dispatch(FillingActions.setCustom(orderFilling)),
      mirror: () => dispatch(FillingActions.setMirror(orderFilling)),
      lacobel: () => dispatch(FillingActions.setLacobel(orderFilling)),
      glass: () => dispatch(FillingActions.setGlass(orderFilling)),
    }[orderFilling?.material];

    if (fillingAction) {
      dispatch(FillingActions.setActiveTrigger(orderFilling?.material));
      fillingAction();
    }
  }, [isOpenChangeFrameFillingModal]);

  const frameOpeningHeightLabel = t(
    'stickyMenu.mainDoorTab.from-min',
    {
      min: currentFrameProfileSizes?.minFrameOpeningHeight,
    },
  );

  const frameOpeningWidthLabel = t(
    'stickyMenu.mainDoorTab.from-min',
    {
      min: currentFrameProfileSizes?.minFrameOpeningWidth,
    },
  );

  const getFrameProfileColors = (profile) => {
    const colors = availableProfiles.find((fp) => fp.articleCode === profile)?.colorsDependence
      .filter((color) => isColorSideProfileAvailable(color, profile, prices)) || [];
    return colors;
  };

  const handleFrameOpeningHeight = ({ target: { value } }) => {
    value = removeLeadingZero(value);
    if (value < 0) return;

    setFrameOpeningH(value);
  };


  const handleFrameOpeningWidth = ({ target: { value } }) => {
    value = removeLeadingZero(value);
    if (value < 0) return;

    setFrameOpeningW(value);
  };

  const handleSameFramesAmount = (value) => {
    if (value < 0) return;

    setSameFrame(value);
  };

  const handleReinforcingProfileAmountChange = (value) => {
    const itemsLength = frameReinforcingProfileItems.length;
    const maxReinforcingProfile = 5;
    const minReinforcingProfile = 0;
    const reinforcingProfileDistance = frameOpeningH / (value + 1);

    if (value > maxReinforcingProfile || value < minReinforcingProfile) return;

    if (itemsLength === 1 && value === 0 && !heightGuaranteeAgreement.agreement && !widthGuaranteeAgreement.agreement) {
      dispatch(DoorsActions.toggleStrengthProfileModal(true));
      dispatch(DoorsActions.toggleFrameParamsModal(false));
      dispatch(DoorsActions.setFrameCreatingStep({ step, stepsAmount }));
      dispatch(DoorsActions.setFrameCreatingParams({ ...getItemToCreate(), currentFrameProfileSizes }));
      return;
    }

    if (frameReinforcingProfileAmount < value) {
      const updatedReinforcingProfileItems = frameReinforcingProfileItems
        .map((item, i) => ({ ...item, distance: _.round(reinforcingProfileDistance * (i + 1)) }));

      setReinforcingProfileAgreement({
        needToUseReinforcingProfile: true,
        agreementNotToUseReinforcingProfile: false,
      });
      setFrameReinforcingProfileItems([...updatedReinforcingProfileItems, {
        sequenceNumber: value,
        distance: _.round(reinforcingProfileDistance * (value)),
      }]);
    }

    if (frameReinforcingProfileAmount > value) {
      const updatedReinforcingProfileItems = frameReinforcingProfileItems.slice(0, itemsLength - 1)
        .map((item, i) => ({ ...item, distance: _.round(reinforcingProfileDistance * (i + 1)) }));

      setFrameReinforcingProfileItems(updatedReinforcingProfileItems);
    }

    setFrameMilling(value * 2);
    setFrameStrainer(value);
    setFrameReinforcingProfileAmount(value);
  };

  const sanitizeAndSaveFrameHeight = ({ target: { value } }) => {
    const {
      minFrameOpeningHeight,
      maxFrameOpeningHeightWithoutReinforcingProfile,
      maxFrameOpeningHeightWithReinforcingProfile,
    } = currentFrameProfileSizes;

    if (value < minFrameOpeningHeight) {
      setFrameOpeningH(minFrameOpeningHeight);
      setReinforcingProfileAgreement({
        needToUseReinforcingProfile: false,
        agreementNotToUseReinforcingProfile: false,
      });
      setHeightGuaranteeAgreement({
        heightLimitExceeded: false,
        agreement: false,
      });
    }

    if (value >= minFrameOpeningHeight
      && value <= maxFrameOpeningHeightWithReinforcingProfile) {
      setHeightGuaranteeAgreement({
        heightLimitExceeded: false,
        agreement: false,
      });
    }

    if (value >= minFrameOpeningHeight
      && value <= maxFrameOpeningHeightWithoutReinforcingProfile) {
      setReinforcingProfileAgreement({
        needToUseReinforcingProfile: false,
        agreementNotToUseReinforcingProfile: false,
      });
    }
  };

  const sanitizeAndSaveFrameWidth = ({ target: { value } }) => {
    const { minFrameOpeningWidth, maxFrameOpeningWidth } = currentFrameProfileSizes;

    if (value < minFrameOpeningWidth) {
      setFrameOpeningW(minFrameOpeningWidth);
      setWidthGuaranteeAgreement({
        widthLimitExceeded: false,
        agreement: false,
      });
    }

    if (value >= minFrameOpeningWidth && value <= maxFrameOpeningWidth) {
      setWidthGuaranteeAgreement({
        widthLimitExceeded: false,
        agreement: false,
      });
    }
  };

  const onPrevStep = () => {
    if ((stepsAmount === 4 && step === 4) || (stepsAmount === 5 && step === 5)) return setSameFrame(0);
    if ((stepsAmount === 4 && step === 3) || (stepsAmount === 5 && step === 4)) {
      dispatch(FillingActions.resetFillingMaterialModal());
      setFrameFilling(null);
      setFrameFillingTexture('');
      return;
    }
    if ((stepsAmount === 4 && step === 2) || (stepsAmount === 5 && step === 3)) {
      if (stepsAmount === 4 && step === 2) {
        dispatch(DoorsActions.clearFrameCreatingStep());
      }
      const colorToSet = aluminiumColors.filter(({ articleCode }) => frameColorsDependence
        ?.includes(articleCode)).map(({ articleCode }) => articleCode)[0];

      return setFrameAluminiumColor(colorToSet);
    }
    if (stepsAmount === 5 && step === 2) {
      setFrameMilling(0);
      setFrameStrainer(0);
      setFrameReinforcingProfileAmount(0);
      setFrameReinforcingProfileItems([]);
      setStepsAmount(4);
      dispatch(DoorsActions.clearFrameCreatingParams());
      dispatch(DoorsActions.clearFrameCreatingStep());
    }
  };

  const onNextStep = () => {
    if (isOpenDeleteFrameModal) {
      onDeleteFrame(currentFrameNumber);
      closeModal();
      return;
    }
    if (isOpenChangeFrameFillingModal) {
      onSetFilling();
      closeModal();
      dispatch(DoorsActions.setActiveDoor(0));
      return;
    }
    if ((stepsAmount === 4 && step === 4) || (stepsAmount === 5 && step === 5)) return onCreateFrames();
    if ((stepsAmount === 4 && step === 3) || (stepsAmount === 5 && step === 4)) onSetFilling();
    if (stepsAmount === 5 && step === 3 && frameReinforcingProfileAmount > 0) dispatch(DoorsActions.setFrameCreatingParams(getItemToCreate()));
    if (step === 1 && (frameOpeningH > currentFrameProfileSizes?.maxFrameOpeningHeightWithoutReinforcingProfile)) {
      setFrameMilling(2);
      setFrameStrainer(1);
      setFrameReinforcingProfileAmount(1);
      setFrameReinforcingProfileItems([{
        sequenceNumber: 1,
        distance: _.round(frameOpeningH / 2),
      }]);
      setReinforcingProfileAgreement({
        needToUseReinforcingProfile: true,
        agreementNotToUseReinforcingProfile: false,
      });
      setStepsAmount(5);
    }

    if (step === 1 && (frameOpeningH > currentFrameProfileSizes?.maxFrameOpeningHeightWithReinforcingProfile
      || frameOpeningW > currentFrameProfileSizes?.maxFrameOpeningWidth)) {
      const itemToUpdateH = {
        reinforcingProfile: {
          amount: 1,
          items: [{
            sequenceNumber: 1,
            distance: _.round(Number(frameOpeningH) / 2),
          }],
        },
        millingForReinforcingProfile: { amount: 2 },
        strainerForReinforcingProfile: { amount: 1 },
        guaranteeAgreement: {
          needToUseReinforcingProfile: true,
          agreementNotToUseReinforcingProfile: false,
        },
        frameHeightGuaranteeAgreement: {
          heightLimitExceeded: true,
          agreement: true,
        },
      };

      const itemToUpdateW = {
        frameWidthGuaranteeAgreement: {
          widthLimitExceeded: true,
          agreement: true,
        },
      };

      dispatch(DoorsActions.setFrameCreatingParams({ ...getItemToCreate(), currentFrameProfileSizes }));

      if (frameOpeningH > currentFrameProfileSizes?.maxFrameOpeningHeightWithReinforcingProfile
        && frameOpeningW > currentFrameProfileSizes?.maxFrameOpeningWidth) {
        const frameWidthGuaranteeParam = {
          frameWidthGuaranteeAgreement: {
            widthLimitExceeded: true,
            agreement: false,
          },
        };
        dispatch(DoorsActions.setFrameCreatingParams({ ...getItemToCreate(), ...itemToUpdateH, ...frameWidthGuaranteeParam, currentFrameProfileSizes }));
      }

      if (frameOpeningH > currentFrameProfileSizes?.maxFrameOpeningHeightWithReinforcingProfile
        && !(frameOpeningW > currentFrameProfileSizes?.maxFrameOpeningWidth)) {
        dispatch(DoorsActions.setFrameCreatingParams({ ...getItemToCreate(), ...itemToUpdateH, currentFrameProfileSizes }));
      }

      if (frameOpeningW > currentFrameProfileSizes?.maxFrameOpeningWidth
        && !(frameOpeningH > currentFrameProfileSizes?.maxFrameOpeningHeightWithReinforcingProfile)) {
        dispatch(DoorsActions.setFrameCreatingParams({ ...getItemToCreate(), ...itemToUpdateW, currentFrameProfileSizes }));
      }

      dispatch(DoorsActions.toggleWarningFrameSizeModal(true));
      dispatch(DoorsActions.toggleFrameParamsModal(false));
    }

    setStep(step + 1);
  };

  const clearState = () => {
    setStepsAmount(4);
    setStep(1);
    setFrameName('');
    setFrameOpeningH('');
    setFrameOpeningW('');
    setFrameMilling(0);
    setFrameStrainer(0);
    setFrameProfile('');
    setFrameAluminiumColor('');
    setFrameFilling({});
    setFrameFillingTexture('');
    setFrameReinforcingProfileAmount(0);
    setFrameReinforcingProfileItems([]);
    setSameFrame(0);
    setReinforcingProfileAgreement({
      needToUseReinforcingProfile: false,
      agreementNotToUseReinforcingProfile: false,
    });
  };

  const closeModal = () => {
    dispatch(DoorsActions.clearFrameCreatingParams());
    dispatch(DoorsActions.clearFrameCreatingStep());
    dispatch(FillingActions.resetFillingMaterialModal());
    clearState();
    onCloseModal();
  };

  const getFillingMaterial = () => ({
    ...fillingMaterialsState[activeTrigger],
    ...{ material: activeTrigger },
  });

  const setFillingTexture = (articleCode) => {
    if (!allAvailableFilling.find((f) => f.articleCode === articleCode)?.isTexture) return '';

    const frameProfileParameter = frameProfileParameters[frameProfile];
    const frameFillingHeight = Number(frameOpeningH) - frameProfileParameter?.size;
    const canUseTexture = canUseHorizontalTexture(allAvailableFilling, articleCode, frameFillingHeight);

    return canUseTexture ? 'vertical' : '';
  };

  const onSetFilling = () => {
    const fillingMaterialToSet = getFillingMaterial();

    const articleCode = fillingMaterialToSet?.[materialType?.[fillingMaterialToSet?.material]];
    const texture = setFillingTexture(articleCode);

    setFrameFilling(activeTrigger ? fillingMaterialToSet : {});
    setFrameFillingTexture(texture);
    onChangeFilling(currentFrameNumber, [activeTrigger ? fillingMaterialToSet : {}], texture);
  };

  const getItemToCreate = () => {
    const existingGroups = doors.length;

    const itemToCreate = {
      sequenceNumber: existingGroups + 1,
      groupName: { value: frameName || t('frameFacades.group-n', { number: existingGroups + 1 }) },
      isShowSizes: true,
      profileHeight: frameProfileHeight,
      profileWidth: frameProfileWidth,
      connectingCorner: frameConnectingCorner,
      frameOpeningHeight: { value: frameOpeningH },
      frameOpeningWidth: { value: frameOpeningW },
      frameProfile: { value: frameProfile },
      frameAluminiumColor: { value: frameAluminiumColor },
      facadeView: { value: 'opening' },
      openingSide: { value: 'left' },
      filling: [frameFilling],
      fillingTexture: { value: frameFillingTexture },
      reinforcingProfile: {
        amount: frameReinforcingProfileAmount,
        items: frameReinforcingProfileItems,
      },
      holesForHandles: {
        amount: 0,
        items: [],
        placementSide: 'right',
      },
      pearHoles: {
        amount: 0,
        items: [],
        placementSide: frameProfile.replace('-N', '') === 'P-32' ? 'left-right' : 'top',
      },
      fasteningElement: {
        type: '',
        amount: 0,
        items: [],
        useCurtain: false,
        hingeType: '',
        drillingScheme: '',
      },
      millingForReinforcingProfile: { amount: frameMilling },
      strainerForReinforcingProfile: { amount: frameStrainer },
      sameFramesAmount: { value: Number(sameFrame) },
      guaranteeAgreement: reinforcingProfileAgreement,
      frameHeightGuaranteeAgreement: heightGuaranteeAgreement,
      frameWidthGuaranteeAgreement: widthGuaranteeAgreement,
    };

    return itemToCreate;
  };

  const onCreateFrames = () => {
    dispatch(DoorsActions.addFrame(getItemToCreate()));
    closeModal();
  };

  const isNextStepDisabled = () => !((step === 1 && frameOpeningH && frameOpeningW && frameProfile)
    || (stepsAmount === 4 && step === 2 && frameAluminiumColor) || (stepsAmount === 5 && step === 2)
    || (stepsAmount === 5 && step === 3 && frameAluminiumColor) || (stepsAmount === 4 && step === 3 && !isSubmitFillingDisabled())
    || (stepsAmount === 5 && step === 4 && !isSubmitFillingDisabled()) || (stepsAmount === 4 && step === 4 && sameFrame)
    || (stepsAmount === 5 && step === 5 && sameFrame) || (isOpenChangeFrameFillingModal && !isSubmitFillingDisabled())
    || isOpenDeleteFrameModal);

  const isSubmitFillingDisabled = () => {
    if (!activeTrigger) return true;

    return !((activeTrigger === 'customers' && customersOption)
        || (activeTrigger === 'dsp' && dspOption && isDspUVPrinting && !_.isEmpty(dspUvPrintType))
        || (activeTrigger === 'dsp' && dspOption)
        || (activeTrigger === 'mirror' && !_.isEmpty(mirrorType))
        || (activeTrigger === 'lacobel' && !_.isEmpty(lacobelType))
        || (activeTrigger === 'glass' && !_.isEmpty(glassType))
    )
      || (activeTrigger === 'mirror' && isMirrorRearMatted && !mirrorColor)
      || (activeTrigger === 'lacobel' && isLacobelRearMatted && !lacobelColor)
      || (activeTrigger === 'glass' && isGlassOneColorPainted && !glassColors.length)
      || (activeTrigger === 'glass' && isGlassTwoColorsPainted && glassColors.length < 2);
  };

  const frameAluminiumColorChoice = aluminiumColors
    .filter(({ articleCode }) => frameColorsDependence?.findIndex((color) => color === articleCode) !== -1)
    .map((item) => {
      const articleCode = item?.articleCode;
      const isChecked = frameAluminiumColor === articleCode;
      const isDisabled = frameColorsDependence?.findIndex((color) => color === articleCode) === -1;

      return (
        <RadioOption
          key={articleCode}
          className={clsx(
            'color',
            isChecked && !isDisabled && 'checked',
            isDisabled && 'disabled',
          )}
          backgroundColor={item.color}
          name={`aluminium-color-${articleCode}`}
          label={item[labelKey]}
          checked={isChecked && !isDisabled}
          isDisabled={isDisabled}
          onChange={() => setFrameAluminiumColor(articleCode)}
        />
      );
    });

  const renderModalTitle = () => {
    if (isOpenDeleteFrameModal) return t('setFrameParamsModal.title-delete', { frameName: currentFrameName });
    if (isOpenChangeFrameFillingModal) return t('setFrameParamsModal.title-filling');
    if (step === 1) return t('setFrameParamsModal.title-params');
    if (stepsAmount === 5 && step === 2) return t('setFrameParamsModal.title-reinforcing-profile');
    if ((stepsAmount === 4 && step === 2) || (stepsAmount === 5 && step === 3)) return t('setFrameParamsModal.title-color');
    if ((stepsAmount === 4 && step === 3) || (stepsAmount === 5 && step === 4)) return t('setFrameParamsModal.title-filling');
    return t('setFrameParamsModal.title-amount');
  };

  const renderRightButtonLabel = () => {
    if (isOpenDeleteFrameModal) return t('setFrameParamsModal.delete');
    if (isOpenChangeFrameFillingModal) return t('setFrameParamsModal.choose');
    if ((stepsAmount === 4 && step === 4) || (stepsAmount === 5 && step === 5)) return t('setFrameParamsModal.confirm');
    return t('setFrameParamsModal.next-step');
  };

  if (!isOpen) return null;

  return (
    <Modal
      opened={isOpen}
      closeModal={() => {
        dispatch(DoorsActions.setActiveDoor(0));
        closeModal();
      }}
      className={className}
      shouldDisableBodyScroll
    >
      <div className="modal-order-icon-wrapper">
        <ReactSVG
          wrapper="div"
          className="modal-order-icon"
          src="/src/client/assets/icons/order-icon.svg"
        />
      </div>
      <h2 className="title">{renderModalTitle()}</h2>

      <div className="content-wrapper">
        <div className="params-wrapper">
          {(step === 1 && !isOpenChangeFrameFillingModal)
            && (
              <>
                <div className="item-wrapper">
                  <Label
                    value={t('setFrameParamsModal.frame-name')}
                  />

                  <Input
                    type="text"
                    placeholder={t('setFrameParamsModal.add-name')}
                    value={frameName}
                    onChange={(e) => setFrameName(e.target.value)}
                    name="frameName"
                    maxLength={50}
                  />
                </div>

                <div className="item-wrapper">
                  <Label
                    value={t('setFrameParamsModal.profile')}
                    infoTagValue={t('etFrameParamsModal.profile')}
                    withInfoTag
                  />

                  <Dropdown
                    placeholder={t('setFrameParamsModal.choose-profile')}
                    isClearable={false}
                    options={availableProfiles
                      .filter((sp) => sp.colorsDependence.length)
                      .map((sp) => ({
                        value: sp.articleCode,
                        label: sp.articleCode,
                        image: sp.image,
                      }))}
                    onChange={(selectedOption) => {
                      if (!selectedOption?.value) return;
                      setFrameProfile(selectedOption?.value);
                    }}
                    value={
                      availableProfiles.length && frameProfile
                        ? availableProfiles.find((item) => item.articleCode === frameProfile)
                        : null
                    }
                  />
                </div>

                <div className="group-wrapper">
                  <div className="item-wrapper size">
                    <Label
                      value={t('setFrameParamsModal.frame-height')}
                      infoTagValue={t('setFrameParamsModal.frame-height')}
                      withInfoTag
                    />

                    <p className="item-subtitle">{frameOpeningHeightLabel}</p>

                    <Input
                      type="number"
                      placeholder="0"
                      value={frameOpeningH}
                      onChange={handleFrameOpeningHeight}
                      onBlur={sanitizeAndSaveFrameHeight}
                      name="frameHeight"
                    />
                  </div>
                  <div className="item-wrapper size">
                    <Label
                      value={t('setFrameParamsModal.frame-width')}
                      infoTagValue={t('setFrameParamsModal.frame-width')}
                      withInfoTag
                    />

                    <p className="item-subtitle">{frameOpeningWidthLabel}</p>

                    <Input
                      type="number"
                      placeholder="0"
                      value={frameOpeningW}
                      onChange={handleFrameOpeningWidth}
                      onBlur={sanitizeAndSaveFrameWidth}
                      name="frameWidth"
                    />
                  </div>
                </div>
              </>
            )}

          {(stepsAmount === 5 && step === 2) && !isOpenChangeFrameFillingModal && (
            <div className="item-wrapper amount-control">
              <div className="item-title">
                <Label
                  value={t('setFrameParamsModal.reinforcing-profile')}
                />
                <span className="item-subtitle">
                  {
                    t('setFrameParamsModal.reinforcing-profile-tooltip',
                      {
                        height: currentFrameProfileSizes?.maxFrameOpeningHeightWithoutReinforcingProfile,
                      })
                  }
                </span>
              </div>
              <PlusMinusControl
                amount={frameReinforcingProfileAmount || 0}
                name="reinforcingProfile"
                setAmount={handleReinforcingProfileAmountChange}
              />
            </div>
          )}

          {(((stepsAmount === 4 && step === 2) || (stepsAmount === 5 && step === 3)) && !isOpenChangeFrameFillingModal) && (
            <div className="item-wrapper">
              <Label
                value={t('stickyMenu.mainDoorTab.aluminum-profile-color')}
                infoTagValue={t('tooltips.aluminum-profile-color')}
                withInfoTag
              />

              {frameAluminiumColorChoice.length
                ? (
                  <RadioGroup className="color">{frameAluminiumColorChoice}</RadioGroup>
                ) : (
                  <div className="label--secondary">
                    {t('stickyMenu.mainDoorTab.no-available-aluminum-profiles')}
                  </div>
                )}
            </div>
          )}

          {(((stepsAmount === 4 && step === 3) || (stepsAmount === 5 && step === 4)) || isOpenChangeFrameFillingModal) && (
            <FillingMaterialsChoice
              className="add-frame-step"
              isOpen={isOpenFillingModal}
              onCloseModal={() => dispatch(FillingActions.toggleFillingMaterialModal(false))}
              activeTrigger={activeTrigger || frameFilling?.material || null}
              setActiveTrigger={(trigger) => dispatch(FillingActions.setActiveTrigger(trigger))}
              setCustomersOption={(option) => dispatch(FillingActions.setCustomersOption(option))}
              customersOption={customersOption || frameFilling?.customersOption || ''}
              isMilling={isMilling}
              setCustomDSPMilling={(isOn) => dispatch(FillingActions.setCustomDSPMilling(isOn))}
              isCustomTransparent={isCustomTransparent}
              setCustomTransparent={(isOn) => dispatch(FillingActions.setCustomTransparent(isOn))}
              dspOption={dspOption || frameFilling?.dspOption || ''}
              setDspOption={(option) => dispatch(FillingActions.setDspOption(option))}
              dspManufacturer={manufacturer || frameFilling?.manufacturer || ''}
              setDspManufacturer={(manufacture) => dispatch(FillingActions.setDspManufacturer(manufacture))}
              dspSearch={searchField}
              setDspSearch={(search) => dispatch(FillingActions.setDspSearch(search))}
              isDspUVPrinting={isDspUVPrinting}
              setDspUvPrinting={(isOn) => dispatch(FillingActions.setDspUvPrinting(isOn))}
              dspUvPrintType={dspUvPrintType || frameFilling?.dspUvPrintType || ''}
              setDspUvPrintType={(printType) => dispatch(FillingActions.setDspUvPrintType(printType))}
              mirrorType={mirrorType || frameFilling?.mirrorType || ''}
              setMirrorType={(type) => dispatch(FillingActions.setMirrorType(type))}
              isMirrorMatted={isMirrorMatted}
              setMirrorMatting={(isOn) => dispatch(FillingActions.setMirrorMatting(isOn))}
              isMirrorRearMatted={isMirrorRearMatted}
              setMirrorRearMatting={(isOn) => dispatch(FillingActions.setMirrorRearMatting(isOn))}
              isMirrorFullMatted={isMirrorFullMatted}
              setMirrorFullMatting={(isOn) => dispatch(FillingActions.setMirrorFullMatting(isOn))}
              isMirrorTransparent={isMirrorTransparent}
              setMirrorTransparent={(isOn) => dispatch(FillingActions.setMirrorTransparent(isOn))}
              mirrorColor={mirrorColor || frameFilling?.mirrorColor || ''}
              setMirrorPaintingColor={(color) => dispatch(FillingActions.setMirrorPaintingColor(color))}
              isMirrorUVPrinting={isMirrorUVPrinting}
              setMirrorUvPrinting={(isOn) => dispatch(FillingActions.setMirrorUvPrinting(isOn))}
              mirrorUvPrintType={mirrorUvPrintType || frameFilling?.mirrorUvPrintType || ''}
              setMirrorUvPrintType={(printType) => dispatch(FillingActions.setMirrorUvPrintType(printType))}
              isMirrorArmoredFilm={isMirrorArmoredFilm}
              setMirrorArmoredFilm={(isOn) => dispatch(FillingActions.setMirrorArmoredFilm(isOn))}
              isMirrorLaminated={isMirrorLaminated}
              setMirrorLamination={(isOn) => dispatch(FillingActions.setMirrorLamination(isOn))}
              mirrorSearch={mirrorSearch}
              setMirrorSearch={(search) => dispatch(FillingActions.setMirrorSearch(search))}
              lacobelType={lacobelType || frameFilling?.lacobelType || ''}
              setLacobelType={(type) => dispatch(FillingActions.setLacobelType(type))}
              isLacobelMatted={isLacobelMatted}
              setLacobelMatting={(isOn) => dispatch(FillingActions.setLacobelMatting(isOn))}
              isLacobelRearMatted={isLacobelRearMatted}
              setLacobelRearMatting={(isOn) => dispatch(FillingActions.setLacobelRearMatting(isOn))}
              isLacobelFullMatted={isLacobelFullMatted}
              setLacobelFullMatting={(isOn) => dispatch(FillingActions.setLacobelFullMatting(isOn))}
              isLacobelTransparent={isLacobelTransparent}
              setLacobelTransparent={(isOn) => dispatch(FillingActions.setLacobelTransparent(isOn))}
              lacobelColor={lacobelColor || frameFilling?.lacobelColor || ''}
              setLacobelPaintingColor={(color) => dispatch(FillingActions.setLacobelPaintingColor(color))}
              isLacobelUVPrinting={isLacobelUVPrinting}
              setLacobelUvPrinting={(isOn) => dispatch(FillingActions.setLacobelUvPrinting(isOn))}
              lacobelUvPrintType={lacobelUvPrintType || frameFilling?.lacobelUvPrintType || ''}
              setLacobelUvPrintType={(printType) => dispatch(FillingActions.setLacobelUvPrintType(printType))}
              isLacobelArmoredFilm={isLacobelArmoredFilm}
              setLacobelArmoredFilm={(isOn) => dispatch(FillingActions.setLacobelArmoredFilm(isOn))}
              isLacobelLaminated={isLacobelLaminated}
              setLacobelLamination={(isOn) => dispatch(FillingActions.setLacobelLamination(isOn))}
              lacobelSearch={lacobelSearch}
              setLacobelSearch={(search) => dispatch(FillingActions.setLacobelSearch(search))}
              glassType={glassType || frameFilling?.glassType || ''}
              setGlassType={(type) => dispatch(FillingActions.setGlassType(type))}
              isGlassMatted={isGlassMatted}
              setGlassMatting={(isOn) => dispatch(FillingActions.setGlassMatting(isOn))}
              isGlassFullMatted={isGlassFullMatted}
              setGlassFullMatting={(isOn) => dispatch(FillingActions.setGlassFullMatting(isOn))}
              isGlassOneColorPainted={isGlassOneColorPainted}
              isGlassTwoColorsPainted={isGlassTwoColorsPainted}
              setGlassIsOneColorPainted={(type) => dispatch(FillingActions.setGlassIsOneColorPainted(type))}
              setGlassIsTwoColorsPainted={(type) => dispatch(FillingActions.setGlassIsTwoColorsPainted(type))}
              glassColors={glassColors || frameFilling?.glassColors || []}
              setGlassPaintingColors={(colors) => dispatch(FillingActions.setGlassPaintingColors(colors))}
              isGlassUVPrinting={isGlassUVPrinting}
              setGlassUvPrinting={(isOn) => dispatch(FillingActions.setGlassUvPrinting(isOn))}
              isGlassPhotoPrinting={isGlassPhotoPrinting}
              setGlassPhotoPrinting={(isOn) => dispatch(FillingActions.setGlassPhotoPrinting(isOn))}
              isGlassTransparent={isGlassTransparent}
              setGlassTransparent={(isOn) => dispatch(FillingActions.setGlassTransparent(isOn))}
              glassUvPrintType={glassUvPrintType || frameFilling?.glassUvPrintType || ''}
              setGlassUvPrintType={(printType) => dispatch(FillingActions.setGlassUvPrintType(printType))}
              glassPhotoPrintType={glassPhotoPrintType || frameFilling?.glassPhotoPrintType || ''}
              setGlassPhotoPrintType={(printType) => dispatch(FillingActions.setGlassPhotoPrintType(printType))}
              glassSearch={glassSearch}
              setGlassSearch={(search) => dispatch(FillingActions.setGlassSearch(search))}
              isGlassArmoredFilm={isGlassArmoredFilm}
              setGlassArmoredFilm={(isOn) => dispatch(FillingActions.setGlassArmoredFilm(isOn))}
              isGlassLaminated={isGlassLaminated}
              setGlassLamination={(isOn) => dispatch(FillingActions.setGlassLamination(isOn))}
            />
          )}

          {(((stepsAmount === 4 && step === 4) || (stepsAmount === 5 && step === 5)) && !isOpenChangeFrameFillingModal) && (
            <div className="item-wrapper amount-control">
              <Label
                value={t('setFrameParamsModal.same-frames-amount')}
              />
              <PlusMinusControl
                amount={sameFrame || 0}
                name="sameFrame"
                setAmount={handleSameFramesAmount}
              />
            </div>
          )}

        </div>
        <div className={clsx('steps-list', isOpenChangeFrameFillingModal && 'hidden')}>
          {Array.from({ length: stepsAmount }, (v, i) => i + 1).map((i) => {
            const stepClassName = clsx('steps-item', step === i && 'active');
            return <div key={`step-${i}`} className={stepClassName} />;
          }) }
        </div>
      </div>
      <div className="action-buttons">
        <div className="action-buttons-inner">
          <button
            type="button"
            className="link-button"
            onClick={() => {
              if (step === 1 || isOpenDeleteFrameModal || isOpenChangeFrameFillingModal) return closeModal();
              onPrevStep();
              setStep(step - 1);
            }}
          >
            <span>
              {step === 1 || isOpenDeleteFrameModal || isOpenChangeFrameFillingModal
                ? t('setFrameParamsModal.cancel')
                : t('setFrameParamsModal.prev-step')}
            </span>
          </button>
          <button
            type="button"
            className="blue-button"
            onClick={() => {
              onNextStep();
            }}
            disabled={isNextStepDisabled()}
          >
            <span>
              {renderRightButtonLabel()}
            </span>
          </button>
        </div>
      </div>

    </Modal>
  );
};

SetFrameParamsModal.defaultProps = {
  isOpen: false,
  prevWarningModalValue: false,
  className: 'action-modal frame-params',
  availableProfiles: [],
  aluminiumColors: [],
  currentFrameNumber: null,
  currentFrameName: '',
  onDeleteFrame: () => {},
  onChangeFilling: () => {},
};

SetFrameParamsModal.propTypes = {
  isOpen: PropTypes.bool,
  prevWarningModalValue: PropTypes.bool,
  className: PropTypes.string,
  availableProfiles: PropTypes.arrayOf(PropTypes.shape({})),
  aluminiumColors: PropTypes.arrayOf(PropTypes.shape({})),
  prices: PropTypes.arrayOf(PropTypes.shape({})),
  currentFrameNumber: PropTypes.number,
  currentFrameName: PropTypes.string,
  onDeleteFrame: PropTypes.func,
  onChangeFilling: PropTypes.func,
  onCloseModal: PropTypes.func.isRequired,
};

export default SetFrameParamsModal;
