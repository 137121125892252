import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FillingActions from '../../redux/actions/fillingMaterials';
import DrillingHolesAction from '../../redux/actions/drillingHoles';
import FasteningElementAction from '../../redux/actions/fasteningElement';
import DrillingPearHolesAction from '../../redux/actions/drillingPearHoles';
import { setPrevFasteningElementParams } from '../FasteningElement';

const StickyHeaderDesktop = ({ titleKey }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { activeDoor, mainFrame, doors } = useSelector(({ doorsAndSections }) => doorsAndSections);
  const { prevFasteningElement, isFasteningElementOpen } = useSelector(({ fasteningElement }) => fasteningElement);

  const currentDoor = activeDoor ? doors[activeDoor - 1] : mainFrame;

  const renderBackButton = () => (
    <div className="">
      <div className="header--back-icon">
        <img
          src="src/client/assets/icons/arrow-back-white.svg"
          alt="Back"
          width="18"
          height="18"
          onClick={() => {
            dispatch(FillingActions.toggleFillingMaterialModal(false));
            dispatch(DrillingHolesAction.toggleDrillingHoles(false));
            dispatch(FasteningElementAction.toggleFasteningElementModal(false));
            dispatch(DrillingPearHolesAction.toggleDrillingPearHoles(false));
            setPrevFasteningElementParams(prevFasteningElement, currentDoor, activeDoor, isFasteningElementOpen, dispatch);
          }}
          role="button" // eslint-disable-line
          tabIndex="0"
        />
      </div>
    </div>
  );

  return (
    <div className="tab-content--title-wrapper filling-desktop">
      {renderBackButton()}
      <div className="tab-content--title">
        <span>{t(titleKey)}</span>
      </div>
    </div>
  );
};

StickyHeaderDesktop.propTypes = {
  titleKey: PropTypes.string.isRequired,
};

export default StickyHeaderDesktop;
