import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import Collapsible from 'react-collapsible';
import clsx from 'clsx';

import Button from '../Button';
import Input from '../Input';
import PlusMinusControl from '../PlusMinusControl';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import Label from '../Label';

import DoorsActions from '../../redux/actions/doorsAndSections';
import FasteningElementAction from '../../redux/actions/fasteningElement';
import DrillingHolesAction from '../../redux/actions/drillingHoles';

import { blockInvalidChar } from '../../helpers/sanitizer';
import {
  fasteningElementsOptions,
  liftingMechanismTypes,
  standardServoDriveOptions,
  standardServoDriveDistance,
} from '../../helpers/frameProfilesOptions';
import HingeDetailsModal from '../HingeDetailsModal';
import WarningDistanceModal from '../WarningDistanceModal';
import WarningInsufficientHeightModal from '../WarningInsufficientHeightModal';


export const setPrevFasteningElementParams = (prevParams, currentParams, activeDoor, isModalOpen, dispatch) => {
  const {
    frameOpeningHeight: { value: height = '' } = {},
    frameOpeningWidth: { value: width = '' } = {},
    fasteningElement: { type = '' } = {},
  } = currentParams;

  if (!isModalOpen || (!prevParams?.type && !type)) return;

  if (Number(prevParams?.height) !== Number(height) || Number(prevParams?.width) !== Number(width)) {
    dispatch(DoorsActions.setFasteningElement(activeDoor, prevParams));
    dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: Number(prevParams?.height) }));
    dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: Number(prevParams?.width) }));
    dispatch(FasteningElementAction.setPrevFasteningElement({}));
  }
};


export const changeHandleSide = (activeDoor, fasteningElement, dispatch) => {
  dispatch(DoorsActions.setOpeningSide(activeDoor, 'top'));
  dispatch(DoorsActions.setHolesForHandles(activeDoor, {
    amount: 0,
    items: [],
    placementSide: 'bottom',
  }));
  dispatch(DoorsActions.setFasteningElement(activeDoor, fasteningElement));
  dispatch(FasteningElementAction.resetFasteningElement());
  dispatch(FasteningElementAction.toggleFasteningElementModal(false));
  dispatch(FasteningElementAction.toggleWarningHandleModal(false));
  dispatch(DrillingHolesAction.toggleDrillingHoles(true));
};

export const saveWithoutChangeHandleSide = (activeDoor, fasteningElement, dispatch) => {
  dispatch(DoorsActions.setOpeningSide(activeDoor, 'top'));
  dispatch(DoorsActions.setHolesForHandles(activeDoor, {
    amount: 0,
    items: [],
    placementSide: 'bottom',
  }));
  dispatch(DoorsActions.setFasteningElement(activeDoor, fasteningElement));
  dispatch(FasteningElementAction.resetFasteningElement());
  dispatch(FasteningElementAction.toggleFasteningElementModal(false));
  dispatch(FasteningElementAction.toggleWarningHandleModal(false));
};


const FasteningElement = () => {
  const { t, i18n } = useTranslation();
  const labelKey = { ru: 'labelRu', uk: 'labelUk', pl: 'labelPl', en: 'labelEn' }[i18n.language] || 'labelEn';
  const dispatch = useDispatch();
  const inputRefX = useRef(null);
  const inputRefY = useRef(null);
  const prevLiftingMechanismTypeRef = useRef();
  const standardServoDriveTypeRef = useRef();
  const fasteningElementTypeRef = useRef();
  const isWarningHeightModalOpenRef = useRef();
  const invalidChars = ['e', 'E', '+', '-', '.', ','];
  const indentFromEdge = 85;
  const distanceBetweenCoords = 45;
  const intendByHingeType = {
    'inner-hinge': 4,
    'parallel-hinge': 4,
    'overlay-hinge': 5,
    'half-overlap-hinge': 5,
  };

  const {
    activeDoor,
    mainFrame,
    doors,
    isOpenHingeDetailsModal,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const {
    frameProfiles,
  } = useSelector(({ config }) => config);

  const {
    fasteningElement: fasteningElementOptions,
    prevFasteningElement: prevFE,
    isFasteningElementOpen,
    isWarningDistanceModalOpen,
    isWarningHeightModalOpen,
  } = useSelector(({ fasteningElement }) => fasteningElement);

  const {
    type,
    amount,
    items,
    useCurtain,
    hingeType,
    drillingScheme,
    liftingMechanismType: lmType,
    liftingMechanismBottomType: lmBottomType,
    standardServoDriveType: standardType,
    withBottomModule,
    isUserChangedCoords,
  } = fasteningElementOptions;

  const [frameProfile, setFrameProfile] = useState('');
  const [frameHeight, setFrameHeight] = useState(null);
  const [frameWidth, setFrameWidth] = useState(null);
  const [warningFrameSizeOptions, setWarningFrameSizeOption] = useState({});
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [resetBottomModule, setResetBottomModule] = useState(false);

  const [drillingSchemes, setDrillingSchemes] = useState([]);
  const [liftingMechanisms, setLiftingMechanisms] = useState([]);
  const [liftingMechanismsBottom, setLiftingMechanismsBottom] = useState([]);
  const [liftingMechanismValidationParam, setLiftingMechanismValidationParam] = useState({});
  const [standardsServoDrive, setStandardsServoDrive] = useState([]);
  const [isAddedBottomModule, setIsAddedBottomModule] = useState(false);
  const [hingeTypes, setHingeTypes] = useState([]);
  const [indent, setIndent] = useState(null);
  const [hasData, setHasData] = useState(true);
  const [fasteningElementType, setFasteningElementType] = useState('');
  const [fasteningElementAmount, setFasteningElementAmount] = useState(0);
  const [fasteningElementItems, setFasteningElementItems] = useState([]);
  const [fasteningElementDrillingScheme, setFasteningElementDrillingScheme] = useState('');
  const [liftingMechanismType, setLiftingMechanismType] = useState('');
  const [liftingMechanismBottomType, setLiftingMechanismBottomType] = useState('');
  const [standardServoDriveType, setStandardServoDriveType] = useState('');
  const [isUseCurtain, setIsUseCurtain] = useState(false);
  const [fasteningElementHingeType, setFasteningElementHingeType] = useState('');
  const [prevFasteningElementItems, setPrevFasteningElementItems] = useState([]);
  const [prevFasteningElement, setPrevFasteningElement] = useState({});

  useEffect(() => {
    prevLiftingMechanismTypeRef.current = liftingMechanismType;
    standardServoDriveTypeRef.current = standardServoDriveType;
    fasteningElementTypeRef.current = fasteningElementType;
    isWarningHeightModalOpenRef.current = isOpenWarningModal;
  });

  const prevLiftingMechanismType = prevLiftingMechanismTypeRef.current;
  const prevStandardServoDriveType = standardServoDriveTypeRef.current;
  const prevFasteningElementType = fasteningElementTypeRef.current;
  const prevIsWarningHeightModalOpen = isWarningHeightModalOpenRef.current;


  useEffect(() => {
    document.querySelector('.fastening-element').scrollIntoView({ block: 'start', behavior: 'auto' });
    setHasData(Boolean(mainFrame?.fasteningElement?.items?.length) || Boolean(doors[activeDoor - 1]?.fasteningElement?.items?.length) || false);
  }, []);

  useEffect(() => {
    const {
      frameOpeningHeight: { value: initialHeight },
      frameOpeningWidth: { value: initialWidth },
      fasteningElement: initialFE,
    } = activeDoor ? doors[activeDoor - 1] : mainFrame;

    dispatch(FasteningElementAction.setPrevFasteningElement({
      height: initialHeight,
      width: initialWidth,
      ...initialFE,
    }));
  }, []);

  useEffect(() => {
    if (isWarningHeightModalOpen) return;

    const getValidItems = (prevItems, fasteningElItems, frameItems) => {
      if (!_.isEmpty(prevItems)) return prevItems;
      if (!_.isEmpty(fasteningElItems)) return fasteningElItems;
      if (!_.isEmpty(frameItems)) return frameItems;
      return [];
    };

    const getValidParam = (prevParam, fasteningElParam, frameParam, defaultParam) => {
      if (!hasData && !prevIsWarningHeightModalOpen && isOpenWarningModal) {
        return prevParam || defaultParam;
      }
      return prevParam || fasteningElParam || frameParam || defaultParam;
    };

    const getValidParamV2 = (prevParam, fasteningElParam, frameParam, defaultParam) => {
      if (!hasData && ((!prevIsWarningHeightModalOpen && isOpenWarningModal) || resetBottomModule)) {
        return prevParam || defaultParam;
      }
      return prevParam || fasteningElParam || frameParam || defaultParam;
    };


    if (activeDoor === 0) {
      setIndent(intendByHingeType[type] || intendByHingeType[mainFrame?.fasteningElement?.type] || '');
      setFrameProfile(mainFrame?.frameProfile?.value || '');
      setFrameHeight(mainFrame?.frameOpeningHeight?.value || null);
      setFrameWidth(mainFrame?.frameOpeningWidth?.value || null);
      setFasteningElementType(prevFasteningElement?.type || type || mainFrame?.fasteningElement?.type || '');
      setFasteningElementAmount(getValidParam(prevFasteningElement?.amount, amount, mainFrame?.fasteningElement?.amount, 0));
      setFasteningElementItems(getValidItems(prevFasteningElement?.items, items, mainFrame?.fasteningElement?.items));
      setFasteningElementDrillingScheme(getValidParamV2(prevFasteningElement?.drillingScheme, drillingScheme, mainFrame?.fasteningElement?.drillingScheme, ''));
      setLiftingMechanismType(getValidParam(prevFasteningElement?.liftingMechanismType, lmType, mainFrame?.fasteningElement?.liftingMechanismType, ''));
      setStandardServoDriveType(getValidParam(prevFasteningElement?.standardServoDriveType, standardType, mainFrame?.fasteningElement?.standardServoDriveType, ''));
      setIsAddedBottomModule(getValidParamV2(prevFasteningElement?.withBottomModule, withBottomModule, mainFrame?.fasteningElement?.withBottomModule, false));
      setLiftingMechanismBottomType(getValidParam(prevFasteningElement?.liftingMechanismBottomType, lmBottomType, mainFrame?.fasteningElement?.liftingMechanismBottomType, ''));
      setIsUseCurtain(getValidParam(prevFasteningElement?.useCurtain, useCurtain, mainFrame?.fasteningElement?.useCurtain, false));
      setFasteningElementHingeType(getValidParam(prevFasteningElement?.hingeType, hingeType, mainFrame?.fasteningElement?.hingeType, ''));
      setPrevFasteningElementItems(getValidItems(prevFasteningElement?.items, items, mainFrame?.fasteningElement?.items));
      dispatch(FasteningElementAction.setUserChangedCoords(prevFasteningElement?.isUserChangedCoords || mainFrame?.fasteningElement?.isUserChangedCoords || false));
      return;
    }

    setIndent(intendByHingeType[type] || intendByHingeType[doors[activeDoor - 1]?.fasteningElement?.type] || '');
    setFrameProfile(doors[activeDoor - 1]?.frameProfile?.value || '');
    setFrameHeight(doors[activeDoor - 1]?.frameOpeningHeight?.value || null);
    setFrameWidth(doors[activeDoor - 1]?.frameOpeningWidth?.value || null);
    setFasteningElementType(prevFasteningElement?.type || type || doors[activeDoor - 1]?.fasteningElement?.type || '');
    setFasteningElementAmount(getValidParam(prevFasteningElement?.amount, amount, doors[activeDoor - 1]?.fasteningElement?.amount, 0));
    setFasteningElementItems(getValidItems(prevFasteningElement?.items, items, doors[activeDoor - 1]?.fasteningElement?.items));
    setFasteningElementDrillingScheme(getValidParamV2(prevFasteningElement?.drillingScheme, drillingScheme, doors[activeDoor - 1]?.fasteningElement?.drillingScheme, ''));
    setLiftingMechanismType(getValidParam(prevFasteningElement?.liftingMechanismType, lmType, doors[activeDoor - 1]?.fasteningElement?.liftingMechanismType, ''));
    setStandardServoDriveType(getValidParam(prevFasteningElement?.standardServoDriveType, standardType, doors[activeDoor - 1]?.fasteningElement?.standardServoDriveType, ''));
    setIsAddedBottomModule(getValidParamV2(prevFasteningElement?.withBottomModule, withBottomModule, doors[activeDoor - 1]?.fasteningElement?.withBottomModule, false));
    setLiftingMechanismBottomType(getValidParam(prevFasteningElement?.liftingMechanismBottomType, lmBottomType, doors[activeDoor - 1]?.fasteningElement?.liftingMechanismBottomType, ''));
    setIsUseCurtain(getValidParam(prevFasteningElement?.useCurtain, useCurtain, doors[activeDoor - 1]?.fasteningElement?.useCurtain, false));
    setFasteningElementHingeType(getValidParam(prevFasteningElement?.hingeType, hingeType, doors[activeDoor - 1]?.fasteningElement?.hingeType, ''));
    setPrevFasteningElementItems(getValidItems(prevFasteningElement?.items, items, doors[activeDoor - 1]?.fasteningElement?.items));
    dispatch(FasteningElementAction.setUserChangedCoords(prevFasteningElement?.isUserChangedCoords || doors[activeDoor - 1]?.fasteningElement?.isUserChangedCoords || false));
  }, [activeDoor, mainFrame, doors, !isWarningHeightModalOpen]);

  useEffect(() => {
    if (warningFrameSizeOptions?.warningMessage) {
      dispatch(FasteningElementAction.toggleWarningHeightModal(true));
    }
  }, [warningFrameSizeOptions?.warningMessage]);

  useEffect(() => {
    if (!isWarningHeightModalOpen) {
      setIsOpenWarningModal(false);
    }
  }, [isWarningHeightModalOpen]);

  useEffect(() => {
    const { drillingSchemes: scheme } = fasteningElementsOptions[frameProfile?.replace('-N', '')] || {};
    const drillingSchemesToSet = scheme?.map((s) => {
      if (s.value === 'frez_d35_45_9' || s.value === 'frez_d35' || s.value === 'hinge-ADS') return ({ ...s, label: t(`fasteningElement.${s.value}`) });
      return s;
    });
    setDrillingSchemes(drillingSchemesToSet);
  }, [frameProfile]);

  useEffect(() => {
    if (!frameProfile || !type) return;

    const hingeTypesToSet = frameProfiles?.find(({ articleCode }) => articleCode === 'P-31')?.hinges[type]
      ?.map((hinge) => ({ value: hinge.articleCode, label: hinge[labelKey] }));
    setHingeTypes(hingeTypesToSet);
  }, [frameProfile, type]);

  useEffect(() => {
    if (fasteningElementType === 'lifting-mechanism') {
      const liftingMechanismsToSet = liftingMechanismTypes.filter((l) => l.value !== 'aventos_hf_bottom').map((l) => {
        if (l.value === 'aventos_hf_top') return ({ ...l, label: t('fasteningElement.aventos_hf_top') });
        return l;
      });
      const liftingMechanismsBottomToSet = liftingMechanismTypes.filter((l) => l.value === 'aventos_hf_bottom').map((l) => ({ ...l, label: t('fasteningElement.aventos_hf_bottom') }));

      setLiftingMechanismsBottom(liftingMechanismsBottomToSet);
      setLiftingMechanisms(liftingMechanismsToSet);
      setStandardsServoDrive(standardServoDriveOptions);
    }
    dispatch(FasteningElementAction.setType(fasteningElementType));
  }, [fasteningElementType]);

  useEffect(() => {
    const firstPairDefaultValue = (liftingMechanismType === 'aventos_hf_top' && indentFromEdge)
      || (liftingMechanismType === 'aventos_hk_top' && 80)
      || (liftingMechanismType === 'aventos_hk_s' && 70)
      || (liftingMechanismType === 'aventos_hl' && standardServoDriveDistance[standardServoDriveType]);

    const secondPairDefaultValue = frameWidth - indentFromEdge;

    const min = (liftingMechanismType === 'aventos_hf_top' && indentFromEdge)
      || (liftingMechanismType === 'aventos_hk_top' && 63)
      || (liftingMechanismType === 'aventos_hk_s' && 68)
      || null;

    const max = (liftingMechanismType === 'aventos_hf_top' && frameWidth - indentFromEdge)
      || (liftingMechanismType === 'aventos_hk_top' && 88)
      || (liftingMechanismType === 'aventos_hk_s' && 78)
      || null;

    setLiftingMechanismValidationParam({
      firstPairDefaultValue,
      secondPairDefaultValue,
      min,
      max,
    });
  }, [liftingMechanismType, standardServoDriveType, frameHeight, frameWidth]);

  useEffect(() => {
    if (hasData || !_.isEmpty(prevFasteningElement)) return;

    if (liftingMechanismType && !isAddedBottomModule) {
      const pairAmount = liftingMechanismType === 'aventos_hf_top' ? 2 : 1;
      setFasteningElementAmount(pairAmount);
      setFasteningElementItems(setLiftingMechanismItems(liftingMechanismType));
      setPrevFasteningElementItems(setLiftingMechanismItems(liftingMechanismType));
    }
  }, [liftingMechanismValidationParam, isAddedBottomModule, hasData]);

  useEffect(() => {
    if (hasData) return;

    setResetBottomModule(false);

    if (liftingMechanismType === 'aventos_hl' && !standardServoDriveType) {
      setStandardServoDriveType(standardsServoDrive?.[0]?.value);
    }
    if (liftingMechanismType !== 'aventos_hl') {
      setStandardServoDriveType('');
    }
    if (liftingMechanismType !== 'aventos_hf_top') {
      setLiftingMechanismBottomType('');
      setIsAddedBottomModule(false);
    }
  }, [liftingMechanismType, liftingMechanismValidationParam, hasData]);

  useEffect(() => {
    if (isAddedBottomModule && !liftingMechanismBottomType) {
      setLiftingMechanismBottomType(liftingMechanismsBottom[0]?.value);
    }

    dispatch(FasteningElementAction.setWithBottomModule(isAddedBottomModule));
  }, [isAddedBottomModule]);

  useEffect(() => {
    dispatch(FasteningElementAction.setAmount(fasteningElementAmount));
  }, [fasteningElementAmount]);

  useEffect(() => {
    dispatch(FasteningElementAction.setItems(fasteningElementItems));
  }, [fasteningElementItems]);

  useEffect(() => {
    dispatch(FasteningElementAction.setDrillingScheme(fasteningElementDrillingScheme));
  }, [fasteningElementDrillingScheme]);

  useEffect(() => {
    dispatch(FasteningElementAction.setUseCurtain(isUseCurtain));
  }, [isUseCurtain]);

  useEffect(() => {
    dispatch(FasteningElementAction.setHingeType(fasteningElementHingeType));
  }, [fasteningElementHingeType]);

  useEffect(() => {
    dispatch(FasteningElementAction.setLiftingMechanismType(liftingMechanismType));
  }, [liftingMechanismType]);

  useEffect(() => {
    dispatch(FasteningElementAction.setLiftingMechanismBottomType(liftingMechanismBottomType));
  }, [liftingMechanismBottomType]);

  useEffect(() => {
    dispatch(FasteningElementAction.setStandardServoDriveType(standardServoDriveType));
  }, [standardServoDriveType]);

  useEffect(() => {
    if (hasData && ((prevLiftingMechanismType && prevLiftingMechanismType !== liftingMechanismType)
      || (prevStandardServoDriveType && prevStandardServoDriveType !== standardServoDriveType)
      || (prevFasteningElementType && prevFasteningElementType !== fasteningElementType))) {
      setHasData(false);
    }

    if (((prevLiftingMechanismType && prevLiftingMechanismType !== 'aventos_hf_top' && liftingMechanismType === 'aventos_hf_top')
      && (!prevIsWarningHeightModalOpen && !isOpenWarningModal))
        || (prevFasteningElementType && prevFasteningElementType !== 'lifting-mechanism' && fasteningElementType === 'lifting-mechanism')) {
      setIsAddedBottomModule(false);
    }
  }, [fasteningElementType, liftingMechanismType, standardServoDriveType, hasData]);

  const setLiftingMechanismItems = (lfType) => {
    const createLmItems = (itemsAmount, axis, params) => {
      const { firstPairDefaultValue, secondPairDefaultValue } = params;
      return Array(itemsAmount).fill().map((item, index) => {
        const coordsToSet = axis === 'x'
          ? {
            x: index < 2 ? firstPairDefaultValue : secondPairDefaultValue,
            y: 0,
          }
          : {
            x: 0,
            y: index < 2 ? firstPairDefaultValue : secondPairDefaultValue,
          };

        return {
          sequenceNumber: index + 1,
          coords: coordsToSet,
        };
      });
    };

    const lmAmount = lfType === 'aventos_hf_top' ? 4 : 2;
    const LmAxis = lfType === 'aventos_hf_top' ? 'x' : 'y';

    return createLmItems(lmAmount, LmAxis, liftingMechanismValidationParam);
  };

  const addBottomModule = () => {
    const firstPairCoords = fasteningElementItems[1]?.coords;
    const secondPairCoords = fasteningElementItems[3]?.coords;

    const itemsToSet = fasteningElementItems.concat([
      {
        sequenceNumber: 5,
        coords: { ...firstPairCoords },
      },
      {
        sequenceNumber: 6,
        coords: { ...secondPairCoords },
      },
    ]);

    setFasteningElementItems(itemsToSet);
    dispatch(FasteningElementAction.setWithBottomModule(true));
    dispatch(FasteningElementAction.setItems(itemsToSet));
    dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: frameHeight * 2 }));
  };

  const getRanges = (number, axis, isDouble = false, bottomModule = false) => {
    const arrayForRange = ((isDouble && bottomModule) && fasteningElementItems.slice(0, 4).filter((item, index) => index % 2))
      || (isDouble && fasteningElementItems.filter((item, index) => index % 2))
      || fasteningElementItems;
    const ranges = arrayForRange.map(({ sequenceNumber, coords }) => ({ sequenceNumber, min: (coords[axis] - 44) <= 85 ? 0 : coords[axis] - 44, max: coords[axis] + 44 }))
      .filter(({ sequenceNumber }) => sequenceNumber !== number);
    return ranges;
  };

  const divideCoordsIntoEqualParts = (hingeAmount) => {
    const spacingBetweenCoords = (frameHeight - indentFromEdge * 2) / (hingeAmount - 1);

    const coordsToSet = Array(hingeAmount).fill().map((item, index) => ({
      sequenceNumber: index + 1,
      coords: {
        x: indent,
        y: _.round(indentFromEdge + (spacingBetweenCoords * index)),
      },
    }));

    return coordsToSet;
  };

  const onFasteningElementAmountChange = (value) => {
    const maxAmount = Math.floor((frameHeight - (indentFromEdge * 2)) / distanceBetweenCoords) + 1;
    const itemsLength = fasteningElementItems?.length;
    const indentFromStartInHeight = indentFromEdge;
    const indentFromEndInHeight = (Number(frameHeight) - indentFromEdge);
    let isAvailableCoordToSet = true;

    if (maxAmount <= 0 && fasteningElementItems?.length === 0 && value > 0) {
      setWarningFrameSizeOption({ warningMessage: t('warningInsufficientHeightModal.title-height', { distance: 170 }), actionType: 'increase' });
      return;
    }

    const getAvailableCoordToSet = (coord) => {
      const isValidCoord = !getRanges(null, 'y').some(({ min, max }) => (coord >= min && coord <= max))
        && coord >= indentFromStartInHeight && coord <= indentFromEndInHeight;

      if (isValidCoord) return coord;

      const sortedRange = getRanges(null, 'y').sort((a, b) => a.min - b.min);

      const getAvailableCoord = (array) => {
        const range = [];
        const rangeWithMinMaxValue = [];
        array.forEach(({ min, max }, index) => {
          if (min > indentFromStartInHeight && min < indentFromEndInHeight && index === 0) range.push(indentFromStartInHeight, min - 1);
          if (min > indentFromStartInHeight && min < indentFromEndInHeight && index > 0) range.push(min - 1);
          if (max < indentFromEndInHeight) range.push(max + 1);
        });

        if (range?.length % 2 !== 0) range.push(indentFromEndInHeight);

        for (let i = 0; i < range.length; i += 2) {
          rangeWithMinMaxValue.push({
            min: range.slice(i, i + 2)[0],
            max: range.slice(i, i + 2)[1],
          });
        }

        const validRangeWithMinMaxValue = rangeWithMinMaxValue.filter(({ min, max }) => max - min >= 0).sort((a, b) => a.min - b.min);

        return validRangeWithMinMaxValue[0]?.min;
      };

      return getAvailableCoord(sortedRange);
    };

    if ((value < 0 || value > maxAmount) && (fasteningElementAmount === 0)) return;

    if (fasteningElementAmount < value) {
      if (value === 1) {
        const fasteningElementItemsToSet = [...fasteningElementItems, {
          sequenceNumber: value,
          coords: {
            x: indent,
            y: indentFromEdge,
          },
        }];
        setFasteningElementItems(fasteningElementItemsToSet);
        setPrevFasteningElementItems(fasteningElementItemsToSet);
      }

      if (!isUserChangedCoords && value > 1) {
        if (value > maxAmount) return;

        setFasteningElementItems(divideCoordsIntoEqualParts(value));
        setPrevFasteningElementItems(divideCoordsIntoEqualParts(value));
      }


      if (isUserChangedCoords && value > 1) {
        const { coords: { y } } = fasteningElementItems[value - 2];
        isAvailableCoordToSet = Boolean(getAvailableCoordToSet(y + distanceBetweenCoords));

        if (!isAvailableCoordToSet) return;

        const fasteningElementItemsToSet = [...fasteningElementItems, {
          sequenceNumber: value,
          coords: {
            x: indent,
            y: getAvailableCoordToSet(y + distanceBetweenCoords),
          },
        }];
        setFasteningElementItems(fasteningElementItemsToSet);
        setPrevFasteningElementItems(fasteningElementItemsToSet);
      }
    }

    if (isUserChangedCoords && fasteningElementAmount > value) {
      setFasteningElementItems(fasteningElementItems.slice(0, itemsLength - 1));
    }

    if (!isUserChangedCoords && fasteningElementAmount > value) {
      setFasteningElementItems(divideCoordsIntoEqualParts(value));
    }

    if (fasteningElementAmount > value && value === 0) {
      dispatch(FasteningElementAction.setUserChangedCoords(false));
    }

    if (isAvailableCoordToSet) setFasteningElementAmount(value);
  };


  const getItemsToUpdate = (itemValue, number, axis, isDouble = false, bottomModule = false) => fasteningElementItems.map((item) => {
    if ((isDouble && bottomModule && number === 2 && item.sequenceNumber === 5)
      || (isDouble && bottomModule && number === 4 && item.sequenceNumber === 6)
      || (isDouble && (item.sequenceNumber === number || item.sequenceNumber === number - 1))
      || (!isDouble && item.sequenceNumber === number)) {
      return { ...item, coords: { ...item.coords, [axis]: Number(itemValue) } };
    }
    return item;
  });


  const handlesHolesCoords = (value, sequenceNumber, axis) => {
    if (value < 0) return;

    if (liftingMechanismType === 'aventos_hf_top' && value > (Number(frameWidth) - indentFromEdge)) return;

    if (liftingMechanismType === 'aventos_hk_top' && value > 88) return;

    if (liftingMechanismType === 'aventos_hk_s' && value > 78) return;

    if (axis === 'y' && value > (Number(frameHeight) - indentFromEdge)) return;

    if (liftingMechanismType) return setFasteningElementItems(getItemsToUpdate(value, sequenceNumber, axis, true, isAddedBottomModule));

    setFasteningElementItems(getItemsToUpdate(value, sequenceNumber, axis));
  };


  const removeFieldInvalidFromCoords = (number, coordX, coordY) => {
    const itemsToUpdate = fasteningElementItems.map((item) => (item.sequenceNumber === number
      ? { ..._.omit(item, ['isInvalid']), coords: { x: coordX, y: coordY } }
      : item));

    setFasteningElementItems(itemsToUpdate);
  };


  const sanitizeHolesCoords = (value, sequenceNumber, axis) => {
    const { min: minValue } = liftingMechanismValidationParam;
    const inRange = getRanges(sequenceNumber, axis, !!liftingMechanismType, isAddedBottomModule).some(({ min, max }) => (value >= min && value <= max));

    if (liftingMechanismType && value < minValue) {
      value = minValue;
      setFasteningElementItems(getItemsToUpdate(value, sequenceNumber, axis, true, isAddedBottomModule));
    }

    if (value < indentFromEdge && !liftingMechanismType) {
      value = indentFromEdge;
      setFasteningElementItems(getItemsToUpdate(value, sequenceNumber, axis));
    }

    if (inRange) return dispatch(FasteningElementAction.toggleWarningDistanceModal(true));

    setPrevFasteningElementItems(getItemsToUpdate(value, sequenceNumber, axis, !!liftingMechanismType, !!isAddedBottomModule));
  };


  const clearParams = () => {
    setFasteningElementAmount(0);
    setFasteningElementItems([]);
    setPrevFasteningElementItems([]);
    setFasteningElementDrillingScheme('');
    setIsUseCurtain(false);
    setFasteningElementHingeType('');
    setLiftingMechanismType('');
    setLiftingMechanismBottomType('');
    setStandardServoDriveType('');
    setIsAddedBottomModule(false);
    setWarningFrameSizeOption({});
    setPrevFasteningElement({});
  };

  const checkIsValidHeightWidthForAventos = (aventosType) => {
    const aventos = {
      aventos_hf_top: 'Aventos HF',
      aventos_hk_top: 'Aventos HK Top',
      aventos_hk_s: 'Aventos HK S',
      aventos_hl: 'Aventos HL',
    };

    let prevFasteningElementValues = {};

    if (!_.isEmpty(fasteningElementItems)) {
      prevFasteningElementValues = {
        type: fasteningElementType,
        amount: fasteningElementAmount,
        items: fasteningElementItems,
        useCurtain: isUseCurtain,
        hingeType: fasteningElementHingeType,
        drillingScheme: fasteningElementDrillingScheme,
        liftingMechanismType,
        liftingMechanismBottomType,
        standardServoDriveType,
        withBottomModule: isAddedBottomModule,
      };
    }

    const btnAction = {
      change: 'submit-change',
      increase: 'submit-increase',
      decrease: 'submit-decrease',
    };

    if (aventosType !== 'aventos_hf_top' && Number(frameHeight) < 205 && Number(frameWidth) < 215) {
      setWarningFrameSizeOption({
        actionType: 'increase',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-width-increase', { aventosType: aventos[aventosType], distanceH: 205, distanceW: 215 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 205 }));
          dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 215 }));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType !== 'aventos_hf_top' && Number(frameHeight) > 500 && Number(frameWidth) > 650) {
      setWarningFrameSizeOption({
        actionType: 'decrease',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-width-decrease', { aventosType: aventos[aventosType], distanceH: 500, distanceW: 650 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 500 }));
          dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 650 }));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType !== 'aventos_hf_top' && Number(frameHeight) > 500 && Number(frameWidth) < 215) {
      setWarningFrameSizeOption({
        actionType: 'change',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-decrease-width-increase', { aventosType: aventos[aventosType], distanceH: 500, distanceW: 215 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 500 }));
          dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 215 }));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType !== 'aventos_hf_top' && Number(frameHeight) < 205 && Number(frameWidth) > 650) {
      setWarningFrameSizeOption({
        actionType: 'change',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-increase-width-decrease', { aventosType: aventos[aventosType], distanceH: 205, distanceW: 650 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 205 }));
          dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 650 }));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType !== 'aventos_hf_top' && Number(frameHeight) < 205) {
      setWarningFrameSizeOption({
        actionType: 'increase',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-increase', { aventosType: aventos[aventosType], distance: 205 }),
        onClick: () => dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 205 })),
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType !== 'aventos_hf_top' && Number(frameHeight) > 500) {
      setWarningFrameSizeOption({
        actionType: 'decrease',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-decrease', { aventosType: aventos[aventosType], distance: 500 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 500 }));
          dispatch(DoorsActions.setReinforcingProfileAmount(activeDoor, 0));
          dispatch(DoorsActions.updateReinforcingProfileItems(activeDoor, []));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }

    if (aventosType === 'aventos_hf_top' && Number(frameHeight) < 240 && Number(frameWidth) < 215) {
      setWarningFrameSizeOption({
        actionType: 'increase',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-width-increase', { aventosType: aventos[aventosType], distanceH: 240, distanceW: 215 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 240 }));
          dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 215 }));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType === 'aventos_hf_top' && Number(frameHeight) > 520 && Number(frameWidth) > 650) {
      setWarningFrameSizeOption({
        actionType: 'decrease',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-width-decrease', { aventosType: aventos[aventosType], distanceH: 520, distanceW: 650 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 520 }));
          dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 650 }));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType === 'aventos_hf_top' && Number(frameHeight) > 520 && Number(frameWidth) < 215) {
      setWarningFrameSizeOption({
        actionType: 'change',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-decrease-width-increase', { aventosType: aventos[aventosType], distanceH: 520, distanceW: 215 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 520 }));
          dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 215 }));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType === 'aventos_hf_top' && Number(frameHeight) < 240 && Number(frameWidth) > 650) {
      setWarningFrameSizeOption({
        actionType: 'change',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-increase-width-decrease', { aventosType: aventos[aventosType], distanceH: 240, distanceW: 650 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 240 }));
          dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 650 }));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType === 'aventos_hf_top' && Number(frameHeight) < 240) {
      setWarningFrameSizeOption({
        actionType: 'increase',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-increase', { aventosType: aventos[aventosType], distance: 240 }),
        onClick: () => dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 240 })),
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }
    if (aventosType === 'aventos_hf_top' && Number(frameHeight) > 520) {
      setWarningFrameSizeOption({
        actionType: 'decrease',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-height-decrease', { aventosType: aventos[aventosType], distance: 520 }),
        onClick: () => {
          dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 520 }));
          dispatch(DoorsActions.setReinforcingProfileAmount(activeDoor, 0));
          dispatch(DoorsActions.updateReinforcingProfileItems(activeDoor, []));
        },
      });
      setPrevFasteningElement(prevFasteningElementValues);
      return;
    }

    if (aventosType && Number(frameWidth) < 215) {
      setWarningFrameSizeOption({
        actionType: 'increase',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-width-increase', { aventosType: aventos[aventosType], distance: 215 }),
        onClick: () => dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 215 })),
      });
      setPrevFasteningElement(prevFasteningElementValues);
    }

    if (aventosType && Number(frameWidth) > 650) {
      setWarningFrameSizeOption({
        actionType: 'decrease',
        warningMessage: t('warningInsufficientHeightModal.title-aventos-width-decrease', { aventosType: aventos[aventosType], distance: 650 }),
        onClick: () => dispatch(DoorsActions.setFrameOpeningWidth(activeDoor, { value: 650 })),
      });
      setPrevFasteningElement(prevFasteningElementValues);
    }
  };


  const onWarningDistanceClose = () => {
    setFasteningElementItems(prevFasteningElementItems);
    dispatch(FasteningElementAction.toggleWarningDistanceModal(false));
  };

  const onWarningAventosSizeSubmit = () => {
    setResetBottomModule(true);
    setPrevFasteningElement({});
    setWarningFrameSizeOption({});
    warningFrameSizeOptions.onClick();
    dispatch(DoorsActions.setMillingForReinforcingProfile(activeDoor, 0));
    dispatch(DoorsActions.setStrainerForReinforcingProfile(activeDoor, 0));
    dispatch(DoorsActions.setReinforcingProfileAmount(activeDoor, 0));
    dispatch(DoorsActions.updateReinforcingProfileItems(activeDoor, []));
    dispatch(DoorsActions.setGuaranteeAgreement(activeDoor, {
      needToUseReinforcingProfile: false,
      agreementNotToUseReinforcingProfile: false,
    }));
    dispatch(DoorsActions.setFrameHeightGuaranteeAgreement(activeDoor, {
      heightLimitExceeded: false,
      agreement: false,
    }));
    dispatch(DoorsActions.setFrameWidthGuaranteeAgreement(activeDoor, {
      widthLimitExceeded: false,
      agreement: false,
    }));
    dispatch(FasteningElementAction.setType(fasteningElementType));
    dispatch(FasteningElementAction.setLiftingMechanismType(liftingMechanismType));
    dispatch(FasteningElementAction.toggleWarningHeightModal(false));
  };

  const onWarningSizeSubmit = () => {
    setWarningFrameSizeOption({});
    dispatch(FasteningElementAction.setType(fasteningElementType));
    dispatch(DoorsActions.setFrameOpeningHeight(activeDoor, { value: 170 }));
    dispatch(FasteningElementAction.toggleWarningHeightModal(false));
  };

  const onWarningAventosSizeClose = () => {
    setIsOpenWarningModal(true);
    setWarningFrameSizeOption({});
    dispatch(FasteningElementAction.setItems([]));
    dispatch(FasteningElementAction.setLiftingMechanismType(''));
    dispatch(FasteningElementAction.toggleWarningHeightModal(false));
  };

  const onWarningSizeClose = () => {
    setWarningFrameSizeOption({});
    dispatch(FasteningElementAction.toggleWarningHeightModal(false));
  };

  const triggerIcon = {
    checked: '/src/client/assets/icons/fillingMaterials/trigger-checked.svg',
    unchecked: '/src/client/assets/icons/fillingMaterials/trigger-unchecked.svg',
  };

  const triggersOption = {
    liftingMechanism: {
      feType: 'lifting-mechanism',
      icon: 'src/client/assets/icons/loops/lifting-mechanism.svg',
    },
    overlayHinge: {
      feType: 'overlay-hinge',
      icon: 'src/client/assets/icons/loops/overlay-loop.svg',
    },
    halfOverlapHinge: {
      feType: 'half-overlap-hinge',
      icon: 'src/client/assets/icons/loops/half-overlap-loop.svg',
    },
    innerHinge: {
      feType: 'inner-hinge',
      icon: 'src/client/assets/icons/loops/inner-loop.svg',
    },
    parallelHinge: {
      feType: 'parallel-hinge',
      icon: 'src/client/assets/icons/loops/parallel-loop.svg',
    },
  };

  const setTriggerWrapperClass = (feType, feSelectedType) => clsx(
    'trigger-wrapper',
    feType === feSelectedType && 'open',
  );

  const getTrigger = (getTriggerClassFn, feType, triggerOption) => {
    const triggerClass = getTriggerClassFn(feType, triggerOption.feType);
    return (
      <div className={triggerClass}>
        <ReactSVG
          className="trigger-check"
          wrapper="span"
          src={feType === triggerOption.feType ? triggerIcon.checked : triggerIcon.unchecked}
        />
        <ReactSVG
          className="trigger-image"
          wrapper="span"
          src={triggerOption.icon}
        />
        <p className="trigger-text">{t(`fasteningElement.${triggerOption.feType}`)}</p>
      </div>
    );
  };


  const renderHolesCoords = (itemsToRender, isBottomModule = false) => {
    const indentFromStartInHeight = (liftingMechanismType && liftingMechanismType !== 'aventos_hf_top')
      ? liftingMechanismValidationParam?.min
      : indentFromEdge;
    const indentFromEndInHeight = (liftingMechanismType && liftingMechanismType !== 'aventos_hf_top')
      ? liftingMechanismValidationParam?.max
      : (Number(frameHeight) - indentFromEdge);

    const axisYRange = t('fasteningElement.range', {
      min: indentFromStartInHeight,
      max: indentFromEndInHeight,
    });

    const axisXRange = t('fasteningElement.range', {
      min: liftingMechanismValidationParam?.min,
      max: liftingMechanismValidationParam?.max,
    });

    const heightDistance = indentFromEndInHeight - indentFromStartInHeight;

    const isDisabledInput = (axis, isInvalid, sequenceNumber) => Boolean((axis === 'x' && !liftingMechanismType && indent)
      || (axis === 'y' && !liftingMechanismType && isInvalid && heightDistance < 0)
      || (axis === 'x' && liftingMechanismType !== 'aventos_hf_top')
      || (axis === 'y' && liftingMechanismType === 'aventos_hf_top')
      || (axis === 'y' && standardServoDriveType)
      || (sequenceNumber === 5 && liftingMechanismType === 'aventos_hf_top')
      || (sequenceNumber === 6 && liftingMechanismType === 'aventos_hf_top'));

    const isInvalidDisabledInput = (axis, isInvalid, sequenceNumber) => {
      if (axis === 'y' && isInvalid && heightDistance < 0) return 'invalid-disabled';
      if (axis === 'y' && isInvalid && heightDistance >= 0) return 'invalid';
      if ((axis === 'x' && indent)
        || (axis === 'x' && liftingMechanismType !== 'aventos_hf_top')
        || (axis === 'y' && liftingMechanismType === 'aventos_hf_top')
        || (axis === 'y' && standardServoDriveType)
        || (sequenceNumber === 5 && liftingMechanismType === 'aventos_hf_top')
        || (sequenceNumber === 6 && liftingMechanismType === 'aventos_hf_top')) return 'disabled';
      return '';
    };

    const getCoordsLabel = (sequenceNumber) => {
      if (fasteningElementType !== 'lifting-mechanism' || isBottomModule) return t('fasteningElement.holes-coords', { holeNumber: sequenceNumber });
      if (fasteningElementType === 'lifting-mechanism' && !isBottomModule) return t('fasteningElement.hole-pair-coords', { pairNumber: sequenceNumber });
    };

    return (
      <div className="hinge-coords">
        {itemsToRender?.map(({ sequenceNumber, coords: { x, y }, isInvalid }, index) => (
          <div key={`coords-for-hole-${sequenceNumber}`} className="hinge-coords-wrapper">
            <div className="hinge-coords-title">
              {getCoordsLabel(index + 1)}
            </div>
            <div className="hinge-coords-items">
              <div className="hinge-coords-item">
                <div className="hinge-coords-item-wrapper">
                  <div className="hinge-coords-item-symbol">X</div>
                  <div className={clsx('hinge-coords-input-wrapper', isInvalidDisabledInput('x', isInvalid, sequenceNumber))}>
                    <Input
                      inputRef={inputRefX}
                      className="small"
                      type="number"
                      placeholder="0"
                      direction="rtl"
                      value={x?.toString()}
                      onFocus={() => dispatch(DoorsActions.setFrameActiveInput({ name: 'fasteningElement', number: sequenceNumber }))}
                      onChange={(e) => {
                        handlesHolesCoords(e.target.value, sequenceNumber, 'x');
                        dispatch(FasteningElementAction.setUserChangedCoords(true));
                      }}
                      onBlur={(e) => {
                        sanitizeHolesCoords(e.target.value, sequenceNumber, 'x');
                        dispatch(DoorsActions.clearFrameActiveInput());
                      }}
                      onKeyDown={(e) => {
                        blockInvalidChar(e, invalidChars);
                        if (e.keyCode === 13) {
                          inputRefX.current.blur();
                          inputRefY.current.focus();
                        }
                      }}
                      key={`fasteningElementItemX-${sequenceNumber}`}
                      name={`fasteningElementItemX-${sequenceNumber}`}
                      isDisabled={isDisabledInput('x', isInvalid, sequenceNumber)}
                    />

                    {(liftingMechanismType === 'aventos_hf_top') && (
                      <div className="hinge-coords-input-range">
                        {axisXRange}
                      </div>
                    )}

                    <div className="hinge-coords-input-tooltip">
                      {t('fasteningElement.tooltip')}
                      <span />
                    </div>
                    <div className="hinge-coords-input-tooltip2">
                      {t('fasteningElement.tooltip-height')}
                      <span />
                    </div>

                  </div>
                </div>
              </div>
              <div className="hinge-coords-item">
                <div className="hinge-coords-item-wrapper">
                  <div className="hinge-coords-item-symbol">Y</div>
                  <div className={clsx('hinge-coords-input-wrapper', isInvalidDisabledInput('y', isInvalid, sequenceNumber))}>
                    <Input
                      inputRef={inputRefY}
                      className="small"
                      type="number"
                      placeholder="0"
                      direction="rtl"
                      value={y?.toString()}
                      onFocus={() => {
                        removeFieldInvalidFromCoords(sequenceNumber, x, y);
                        dispatch(DoorsActions.setFrameActiveInput({ name: 'fasteningElement', number: sequenceNumber }));
                      }}
                      onChange={(e) => {
                        handlesHolesCoords(e.target.value, sequenceNumber, 'y');
                        dispatch(FasteningElementAction.setUserChangedCoords(true));
                      }}
                      onBlur={(e) => {
                        sanitizeHolesCoords(e.target.value, sequenceNumber, 'y');
                        dispatch(DoorsActions.clearFrameActiveInput());
                      }}
                      onKeyDown={(e) => {
                        blockInvalidChar(e, invalidChars);
                        if (e.keyCode === 13) {
                          inputRefY.current.blur();
                        }
                      }}
                      key={`fasteningElementItemY-${sequenceNumber}`}
                      name={`fasteningElementItemY-${sequenceNumber}`}
                      isDisabled={isDisabledInput('y', isInvalid, sequenceNumber)}
                    />

                    {((frameHeight >= indentFromEdge * 2) && liftingMechanismType !== 'aventos_hf_top' && !standardServoDriveType) && (
                      <div className="hinge-coords-input-range">
                        {axisYRange}
                      </div>
                    )}

                    <div className="hinge-coords-input-tooltip">
                      {t('fasteningElement.tooltip')}
                      <span />
                    </div>
                    <div className="hinge-coords-input-tooltip2">
                      {t('fasteningElement.tooltip-height')}
                      <span />
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>
    );
  };


  const renderDrillingSchemes = () => (
    <>
      <div className="hinge-item-group">
        <Label
          value={t('fasteningElement.drillScheme')}
          infoTagValue={t('fasteningElement.drillScheme')}
          withInfoTag
        />

        <Button
          value={t('fasteningElement.details')}
          onClick={() => dispatch(DoorsActions.toggleHingeDetailsModal(true))}
          type="text-blue"
          className="hinge-details"
        />
      </div>

      <div className={clsx('hinge-dropdown', fasteningElementType !== 'lifting-mechanism' && 'hasMargin')}>
        <Dropdown
          placeholder={t('fasteningElement.choose')}
          isClearable={false}
          options={drillingSchemes?.length
            ? drillingSchemes
            : []}
          onChange={(selectedOption) => {
            if (!selectedOption?.value) return;
            setFasteningElementDrillingScheme(selectedOption?.value);
          }}
          value={drillingSchemes?.find((item) => item.value === fasteningElementDrillingScheme)}
        />
      </div>
    </>
  );


  const collapsibleLiftingMechanismInner = () => {
    const coordsToRender = fasteningElementItems?.slice(0, 4)?.filter((item, index) => index % 2);
    const bottomModuleCoordsToRender = fasteningElementItems?.slice(4);

    const renderStandardsServoDrive = () => (
      <>
        <div className="hinge-item-group">
          <Label
            value={t('fasteningElement.standard')}
            infoTagValue={t('fasteningElement.standard')}
            withInfoTag
          />
        </div>

        <div className="hinge-dropdown hasMargin">
          <Dropdown
            isClearable={false}
            options={standardsServoDrive?.length
              ? standardsServoDrive
              : []}
            onChange={(selectedOption) => {
              if (!selectedOption?.value) return;
              setStandardServoDriveType(selectedOption?.value);
            }}
            value={standardsServoDrive?.find((item) => item.value === standardServoDriveType)}
          />

        </div>
      </>
    );

    const renderLiftingMechanismsBottom = () => (
      <>
        <div className="hinge-item-group cut">
          <Label
            value={t('fasteningElement.lifting-mechanism-type')}
            infoTagValue={t('fasteningElement.lifting-mechanism-type')}
            withInfoTag
          />

          <a
            className="hinge-details"
            href="https://www.blum.com/kz/ru/products/"
            target="_blank"
            rel="noreferrer"
          >
            {t('fasteningElement.details')}
          </a>
        </div>

        <div className="hinge-dropdown hasMargin">
          <Dropdown
            placeholder={t('fasteningElement.choose')}
            isClearable={false}
            options={liftingMechanismsBottom?.length
              ? liftingMechanismsBottom
              : []}
            onChange={(selectedOption) => {
              if (!selectedOption?.value) return;
              setLiftingMechanismBottomType(selectedOption?.value);
            }}
            value={liftingMechanismsBottom?.find((item) => item.value === liftingMechanismBottomType)}
          />
        </div>
      </>
    );

    return (
      <div className="hinge-inner">

        <div className="hinge-item-group cut">
          <Label
            value={t('fasteningElement.lifting-mechanism-type')}
            infoTagValue={t('fasteningElement.lifting-mechanism-type')}
            withInfoTag
          />

          <a
            className="hinge-details"
            href="https://www.blum.com/kz/ru/products/"
            target="_blank"
            rel="noreferrer"
          >
            {t('fasteningElement.details')}
          </a>
        </div>

        <div className={clsx('hinge-dropdown', liftingMechanismType && 'hasMargin')}>
          <Dropdown
            placeholder={t('fasteningElement.choose')}
            isClearable={false}
            options={liftingMechanisms?.length
              ? liftingMechanisms
              : []}
            onChange={(selectedOption) => {
              if (!selectedOption?.value) return;
              if (!prevIsWarningHeightModalOpen && !isOpenWarningModal) {
                setPrevFasteningElement({});
              }
              setLiftingMechanismType(selectedOption?.value);
              checkIsValidHeightWidthForAventos(selectedOption?.value);
            }}
            value={liftingMechanisms?.find((item) => item.value === liftingMechanismType)}
          />
        </div>

        {(liftingMechanismType === 'aventos_hl' && !isWarningHeightModalOpen) && renderStandardsServoDrive()}

        {(liftingMechanismType && !isWarningHeightModalOpen)
        && (
          <>
            <div className={clsx('hinge-counter', fasteningElementAmount > 0 && 'extended')}>
              <span>{t('fasteningElement.hole-pairs-amount')}</span>
              <PlusMinusControl
                amount={fasteningElementAmount || 0}
                name="fasteningElementAmount"
              />
            </div>

            {renderHolesCoords(coordsToRender)}

            {liftingMechanismType === 'aventos_hf_top'
            && (
              <button
                type="button"
                className={clsx('hinge-button', isAddedBottomModule && 'active')}
                onClick={() => {
                  addBottomModule();
                  setIsAddedBottomModule(true);
                }}
                disabled={isAddedBottomModule}
              >
                {t('fasteningElement.add-bottom-module')}
              </button>
            )}

            {(liftingMechanismType === 'aventos_hf_top' && isAddedBottomModule)
              && (
                <>
                  {renderLiftingMechanismsBottom()}
                  {renderHolesCoords(bottomModuleCoordsToRender, true)}
                </>
              )}

            {renderDrillingSchemes()}
          </>
        )}
      </div>
    );
  };


  const collapsibleInner = () => (
    <div className="hinge-inner">
      <div className="hinge-hint">
        <ReactSVG
          wrapper="div"
          className="icon"
          src="/src/client/assets/icons/icon-warning.svg"
        />
        {t('fasteningElement.hint')}
      </div>

      <div className={clsx('hinge-counter', fasteningElementAmount > 0 && 'extended')}>
        <span>{t('fasteningElement.countHoles')}</span>
        <PlusMinusControl
          amount={fasteningElementAmount || 0}
          name="fasteningElementAmount"
          setAmount={onFasteningElementAmountChange}
        />
      </div>

      {renderHolesCoords(fasteningElementItems)}

      {fasteningElementAmount >= 2
        && (
          <button
            type="button"
            className="hinge-button"
            onClick={() => {
              const coords = divideCoordsIntoEqualParts(fasteningElementAmount);

              setFasteningElementItems(coords);
              setPrevFasteningElementItems(coords);
              dispatch(FasteningElementAction.setUserChangedCoords(false));
            }}
          >
            {t('fasteningElement.divide-coords')}
          </button>
        )}

      {fasteningElementAmount ? (
        <div>
          {renderDrillingSchemes()}

          {hingeTypes?.length > 0
            && (
              <>
                <Checkbox
                  isChecked={isUseCurtain}
                  label={t('fasteningElement.add-curtains')}
                  onChange={() => {
                    if (!isUseCurtain) setFasteningElementHingeType('');
                    setIsUseCurtain(!isUseCurtain);
                  }}
                  key="add-curtains"
                  name="add-curtains"
                />

                {isUseCurtain && (
                  <>
                    <div className="hinge-item-group">
                      <Label
                        value={t('fasteningElement.hinge-select')}
                      />
                    </div>

                    <div className="hinge-dropdown">
                      <Dropdown
                        isClearable={false}
                        options={hingeTypes?.length
                          ? hingeTypes
                          : []}
                        onChange={(selectedOption) => {
                          if (!selectedOption?.value) return;
                          setFasteningElementHingeType(selectedOption?.value);
                        }}
                        value={hingeTypes?.find((item) => item.value === fasteningElementHingeType)}
                      />
                    </div>
                  </>
                )}
              </>
            )}
        </div>
      ) : null}

    </div>
  );

  return (
    <div className="fastening-element">
      <div className="fastening-element-title">
        {t('stickyMenu.desktop.fastening-element')}
      </div>
      <button
        className="fastening-element-close modal-close-button"
        type="button"
        onClick={() => {
          const currentDoor = activeDoor ? doors[activeDoor - 1] : mainFrame;
          dispatch(FasteningElementAction.toggleFasteningElementModal(false));
          setPrevFasteningElementParams(prevFE, currentDoor, activeDoor, isFasteningElementOpen, dispatch);
          dispatch(FasteningElementAction.resetFasteningElement());
        }}
      >
        <div className="modal-close-button-cross" />
      </button>

      {frameProfile?.replace('-N', '') === 'P-32' && (
        <Collapsible
          trigger={getTrigger(setTriggerWrapperClass, fasteningElementType, triggersOption.liftingMechanism)}
          open={fasteningElementType === 'lifting-mechanism'}
          disabled={fasteningElementType !== 'lifting-mechanism'}
          overflowWhenOpen="visible"
          onTriggerOpening={() => {
            setFasteningElementType('lifting-mechanism');
            clearParams();
          }}
          onTriggerClosing={() => { setFasteningElementType(''); clearParams(); }}
        >
          {collapsibleLiftingMechanismInner()}
        </Collapsible>
      )}

      <Collapsible
        trigger={getTrigger(setTriggerWrapperClass, fasteningElementType, triggersOption.overlayHinge)}
        open={fasteningElementType === 'overlay-hinge'}
        disabled={fasteningElementType !== 'overlay-hinge'}
        overflowWhenOpen="visible"
        onTriggerOpening={() => {
          setFasteningElementType('overlay-hinge');
          setIndent(intendByHingeType['overlay-hinge']);
          clearParams();
        }}
        onTriggerClosing={() => { setFasteningElementType(''); clearParams(); }}
      >
        {collapsibleInner()}
      </Collapsible>

      <Collapsible
        trigger={getTrigger(setTriggerWrapperClass, fasteningElementType, triggersOption.halfOverlapHinge)}
        open={fasteningElementType === 'half-overlap-hinge'}
        disabled={fasteningElementType !== 'half-overlap-hinge'}
        overflowWhenOpen="visible"
        onTriggerOpening={() => {
          setFasteningElementType('half-overlap-hinge');
          setIndent(intendByHingeType['half-overlap-hinge']);
          clearParams();
        }}
        onTriggerClosing={() => { setFasteningElementType(''); clearParams(); }}
      >
        {collapsibleInner()}
      </Collapsible>

      <Collapsible
        trigger={getTrigger(setTriggerWrapperClass, fasteningElementType, triggersOption.innerHinge)}
        open={fasteningElementType === 'inner-hinge'}
        disabled={fasteningElementType !== 'inner-hinge'}
        overflowWhenOpen="visible"
        onTriggerOpening={() => {
          setFasteningElementType('inner-hinge');
          setIndent(intendByHingeType['inner-hinge']);
          clearParams();
        }}
        onTriggerClosing={() => { setFasteningElementType(''); clearParams(); }}
      >
        {collapsibleInner()}
      </Collapsible>

      {frameProfile?.replace('-N', '') !== 'P-32'
        && (
          <Collapsible
            trigger={getTrigger(setTriggerWrapperClass, fasteningElementType, triggersOption.parallelHinge)}
            open={fasteningElementType === 'parallel-hinge'}
            disabled={fasteningElementType !== 'parallel-hinge'}
            overflowWhenOpen="visible"
            onTriggerOpening={() => {
              setFasteningElementType('parallel-hinge');
              setIndent(intendByHingeType['parallel-hinge']);
              clearParams();
            }}
            onTriggerClosing={() => { setFasteningElementType(''); clearParams(); }}
          >
            {collapsibleInner()}
          </Collapsible>
        )}

      <Button
        value={t('fasteningElement.clear')}
        onClick={() => { clearParams(); setFasteningElementType(''); }}
        type="outlined-white"
        className="clearBtn"
      />

      <HingeDetailsModal
        isOpen={isOpenHingeDetailsModal}
        onCloseModal={() => {
          dispatch(DoorsActions.toggleHingeDetailsModal(false));
        }}
      />

      <WarningDistanceModal
        isOpen={isWarningDistanceModalOpen}
        distance={distanceBetweenCoords}
        onCloseModal={onWarningDistanceClose}
        onSubmit={onWarningDistanceClose}
      />
      <WarningInsufficientHeightModal
        isOpen={isWarningHeightModalOpen}
        actionType={warningFrameSizeOptions?.actionType}
        content={warningFrameSizeOptions?.warningMessage}
        onCloseModal={fasteningElementType !== 'lifting-mechanism' ? onWarningSizeClose : onWarningAventosSizeClose}
        onSubmit={fasteningElementType !== 'lifting-mechanism' ? onWarningSizeSubmit : onWarningAventosSizeSubmit}
      />
    </div>
  );
};

export default FasteningElement;
