import _ from 'lodash';
import { createActions } from 'reduxsauce';

import API from '../../api';

import AuthService from '../../services/authService';

import { calculateOrder, calculateFrameOrder } from '../../helpers/priceHelper.mjs';
import { defaultRegion, defaultPackageName } from '../../helpers/constants';
import { defaultPackagesByRegion } from '../../../server/helpers/constants.mjs';

import ConfigActions from './config';
import DoorsActions from './doorsAndSections';
import SystemsActions from './systems';
import MySavedOrdersActions from './mySavedOrders';


const { Types, Creators } = createActions({
  calculateOrderSuccess: ['specification'],
  calculateOrderFailure: ['errorMessage'],
  calculateOrderRequest: () => async (dispatch, state) => {
    try {
      const currentState = state();
      const { primaryRegion: manageeRegion, packageName: manageePackage, phone } = currentState.clientList?.manageeInfo;
      const currentManageePackage = (currentState.profile?.role === 'manager' && !manageePackage) && (await API.user.profile.getPackage(phone))?.data?.packageName;

      const packageNameToUse = currentState.profile?.role === 'manager' && !_.isEmpty(currentState.clientList?.manageeInfo)
        ? manageePackage || currentManageePackage
        : currentState.profile?.packageName;
      const hasRegionDefaultPackage = defaultPackagesByRegion
        .find((p) => p.packageName === packageNameToUse);
      const packageName = hasRegionDefaultPackage?.packageName ? defaultPackageName : packageNameToUse;

      let priceList = null;

      if (currentState.profile?.role === 'manager') {
        const primaryRegion = (currentState.profile?.role === 'manager' && !_.isEmpty(currentState.clientList?.manageeInfo)) && (manageeRegion || defaultRegion);

        priceList = (await API.priceList.get(primaryRegion, packageName))?.data?.priceList;
      }

      const currentSystem = currentState.systems?.currentSystem;
      const isSystemConctantsMatched = currentState?.config?.systemConctants?.length
        && currentState?.config?.systemConctants[0]?.systemType === currentSystem;

      if (!isSystemConctantsMatched
        || !currentState?.doorsAndSections?.main?.sideProfile?.value
        || !currentState?.priceList?.priceList?.length) return;

      const currentStateToSet = {
        ...currentState,
        ...(currentState.profile?.role === 'manager' ? { priceList: { priceList } } : {}),
      };

      const orderPriceAndSpecification = calculateOrder(currentStateToSet, packageName);
      const { specification } = orderPriceAndSpecification;

      dispatch(Creators.calculateOrderSuccess(specification));
    } catch (error) {
      console.log(error.message || error.reason);
    }
  },

  calculateFrameOrderSuccess: ['specification'],
  calculateFrameOrderFailure: ['errorMessage'],
  calculateFrameOrderRequest: () => async (dispatch, state) => {
    try {
      const currentState = state();
      const { primaryRegion: manageeRegion, packageName: manageePackage, phone } = currentState.clientList?.manageeInfo;
      const currentManageePackage = (currentState.profile?.role === 'manager' && !manageePackage) && (await API.user.profile.getPackage(phone))?.data?.packageName;

      const packageNameToUse = currentState.profile?.role === 'manager' && !_.isEmpty(currentState.clientList?.manageeInfo)
        ? manageePackage || currentManageePackage
        : currentState.profile?.packageName;
      const hasRegionDefaultPackage = defaultPackagesByRegion
        .find((p) => p.packageName === packageNameToUse);
      const packageName = hasRegionDefaultPackage?.packageName ? defaultPackageName : packageNameToUse;

      if (!currentState?.priceList?.priceList?.length) return;

      let priceList = null;

      if (currentState.profile?.role === 'manager') {
        const primaryRegion = (currentState.profile?.role === 'manager' && !_.isEmpty(currentState.clientList?.manageeInfo)) && (manageeRegion || defaultRegion);

        priceList = (await API.priceList.get(primaryRegion, packageName))?.data?.priceList;
      }

      const currentStateToSet = {
        ...currentState,
        ...(currentState.profile?.role === 'manager' ? { priceList: { priceList } } : {}),
      };

      const orderPriceAndSpecification = calculateFrameOrder(currentStateToSet, packageName);

      dispatch(Creators.calculateFrameOrderSuccess(orderPriceAndSpecification));
    } catch (error) {
      console.log(error.message || error.reason);
    }
  },


  putOrderIntoWorkSuccess: ['orderId', 'orderNumber'],
  putOrderIntoWorkFailure: ['errorMessage'],
  putOrderIntoWorkRequest: (id, data, recalculatedOrder) => async (dispatch) => {
    try {
      const userId = AuthService.getUserId();

      if (!userId) {
        dispatch(Creators.putOrderIntoWorkFailure('USER_NOT_FOUND'));
        return;
      }

      let dataToUpdate = {
        status: 'in-processing',
        delivery: data,
      };

      if (!_.isEmpty(recalculatedOrder)) {
        dataToUpdate = {
          ...dataToUpdate,
          ...{
            items: recalculatedOrder.items,
            retailTotalPrice: recalculatedOrder.retailTotalPrice,
            totalPrice: recalculatedOrder.totalPrice,
          },
        };
      }

      const response = await API.orders.putIntoWork(id, userId, dataToUpdate);
      const errorMsg = !response.ok ? (response.data?.message || response.problem) : '';

      const { orderId, orderNumber } = response;

      if (errorMsg) {
        dispatch(Creators.putOrderIntoWorkFailure(errorMsg));
        return;
      }

      if (!orderId) {
        dispatch(Creators.putOrderIntoWorkFailure('PUT_INTO_WORK_FAILURE'));
        return;
      }

      dispatch(Creators.putOrderIntoWorkSuccess(orderId, orderNumber));
    } catch (error) {
      dispatch(Creators.putOrderIntoWorkFailure(error.message || error.reason));
    }
  },


  updateOrderTitleSuccess: ['orderId', 'orderNumber'],
  updateOrderTitleFailure: ['errorMessage'],
  updateOrderTitleRequest: (id, title) => async (dispatch) => {
    try {
      const userId = AuthService.getUserId();

      if (!userId) {
        dispatch(Creators.updateOrderTitleFailure('USER_NOT_FOUND'));
        return;
      }

      const response = await API.orders.updateTitle(id, userId, title);

      const errorMsg = !response.ok
        ? (response.data?.message || response.problem)
        : '';

      const { orderId, orderNumber } = response;

      if (errorMsg) {
        dispatch(Creators.updateOrderTitleFailure(errorMsg));
        return;
      }

      if (!orderId) {
        dispatch(Creators.updateOrderTitleFailure('ERROR_UPDATING_ORDER'));
        return;
      }

      dispatch(Creators.updateOrderTitleSuccess(orderId, orderNumber));
    } catch (error) {
      dispatch(Creators.updateOrderTitleFailure(error.message || error.reason));
    }
  },


  updateOrderPriceByDealerSuccess: ['orderId', 'orderNumber'],
  updateOrderPriceByDealerFailure: ['errorMessage'],
  updateOrderPriceByDealerRequest: (id, price) => async (dispatch) => {
    try {
      const userId = AuthService.getUserId();

      if (!userId) {
        dispatch(Creators.updateOrderTitleFailure('USER_NOT_FOUND'));
        return;
      }

      const response = await API.orders.updatePriceByDealer(id, price);

      const errorMsg = response?.error?.message;

      const { orderId, orderNumber } = response;

      if (errorMsg) {
        dispatch(Creators.updateOrderPriceByDealerFailure(errorMsg));
        return;
      }

      if (!orderId) {
        dispatch(Creators.updateOrderPriceByDealerFailure('ERROR_UPDATING_ORDER_PRICE'));
        return;
      }

      dispatch(Creators.updateOrderPriceByDealerSuccess(orderId, orderNumber));
    } catch (error) {
      dispatch(Creators.updateOrderPriceByDealerFailure(error.message || error.reason));
    }
  },


  saveOrderSuccess: ['orderId', 'orderNumber'],
  saveOrderFailure: ['errorMessage'],
  saveOrderRequest: () => async (dispatch, state) => {
    try {
      const currentState = state();
      const userId = AuthService.getUserId();
      const { currentSystem } = currentState.systems;
      const {
        currentOrderId,
        specification,
        frameSpecification,
        region,
        title,
      } = currentState.order;
      const manageeInfo = currentState.clientList?.manageeInfo;
      const pathArray = window.location.pathname.split('/');
      const orderIdInPath = pathArray[2] !== 'edit' ? pathArray[2] : '';
      const orderIdToUpdate = currentOrderId || orderIdInPath;

      const doorsSnippet = _.pick(
        currentState.doorsAndSections,
        currentSystem === 'frame-facades'
          ? ['main', 'mainFrame', 'doors']
          : ['minDoorsAmount', 'maxDoorsAmount', 'minSectionsAmount', 'maxSectionsAmount', 'main', 'doors'],
      );

      if (!userId) {
        dispatch(Creators.saveOrderFailure('USER_NOT_FOUND'));
        return;
      }

      if ((_.isEmpty(specification) && currentSystem !== 'frame-facades')
      || (_.isEmpty(frameSpecification) && currentSystem === 'frame-facades')) {
        dispatch(Creators.saveOrderFailure('Specification is empty'));
        return;
      }

      const dataToUpdate = {
        ...(currentSystem === 'frame-facades'
          ? {
            ..._.omit(frameSpecification, ['specificationByFrame', 'specification', 'missingItems']),
            items: frameSpecification.specification,
            itemsByFrame: frameSpecification.specificationByFrame.filter((f) => f.frameNumber !== 0)
              .map((f) => ({ ..._.omit(f, ['specification']), items: f.specification.items })),
          }
          : { ..._.omit(specification, ['missingItems']) }),
        ...{
          status: 'new',
          doorsSnippet,
          title,
        },
      };


      // Check for empty items
      const { priceList } = currentState.priceList;

      const getOrderToCreate = () => {
        const missingItems = currentSystem === 'frame-facades'
          ? _.uniq(frameSpecification.specification.filter((item) => !item.labelUk).map((item) => item.articleCode))
          : specification.items.filter((item) => !item.labelUk).map((item) => item.articleCode);

        const debugInfo = {
          missingItems,
          priceListLength: priceList.length,
          priceListRelevantRecords: priceList.filter((m) => missingItems.includes(m.articleCode)),
        };

        return {
          ...(currentSystem === 'frame-facades'
            ? {
              ..._.omit(frameSpecification, ['specificationByFrame', 'specification']),
              items: frameSpecification.specification,
              itemsByFrame: frameSpecification.specificationByFrame.filter((f) => f.frameNumber !== 0)
                .map((f) => ({ ..._.omit(f, ['specification']), items: f.specification.items })),
            }
            : specification),
          ...{
            status: 'new',
            title,
          },
          debugInfo,
        };
      };

      const orderToCreate = getOrderToCreate();

      const response = orderIdToUpdate
        ? await API.orders.update(orderIdToUpdate, userId, dataToUpdate, region, doorsSnippet, currentSystem)
        : await API.orders.create(userId, orderToCreate, region, doorsSnippet, currentSystem, manageeInfo);

      const errorMsg = !response.ok
        ? (response.data?.message || response.problem)
        : '';

      const { orderId, orderNumber } = response;

      if (errorMsg) {
        dispatch(Creators.saveOrderFailure(errorMsg));
        return;
      }

      if (!orderId) {
        dispatch(Creators.saveOrderFailure('ERROR_UPDATING_ORDER'));
        return;
      }

      dispatch(Creators.saveOrderSuccess(orderId, orderNumber));
      dispatch(MySavedOrdersActions.getMySavedOrdersRequest());
    } catch (error) {
      dispatch(Creators.saveOrderFailure(error.message || error.reason));
    }
  },


  deleteOrderSuccess: ['orderId', 'orderNumber'],
  deleteOrderFailure: ['errorMessage'],
  deleteOrderRequest: (id) => async (dispatch) => {
    try {
      const userId = AuthService.getUserId();

      if (!userId) {
        dispatch(Creators.deleteOrderFailure('USER_NOT_FOUND'));
        return;
      }

      const response = await API.orders.delete(id);

      const errorMsg = !response.ok
        ? (response.data?.message || response.problem)
        : '';

      const { orderId, orderNumber } = response;

      if (errorMsg) {
        dispatch(Creators.deleteOrderFailure(errorMsg));
        return;
      }

      if (!orderId) {
        dispatch(Creators.deleteOrderFailure('ERROR_REMOVING_ORDER'));
        return;
      }

      dispatch(Creators.deleteOrderSuccess(orderId, orderNumber));
    } catch (error) {
      dispatch(Creators.deleteOrderFailure(error.message || error.reason));
    }
  },


  copyOrderSuccess: ['orderId', 'orderNumber'],
  copyOrderClearSuccess: [],
  copyOrderFailure: ['errorMessage'],
  copyOrderRequest: (id, title) => async (dispatch) => {
    try {
      const userId = AuthService.getUserId();

      if (!userId) {
        dispatch(Creators.copyOrderFailure('USER_NOT_FOUND'));
        return;
      }

      const response = await API.orders.copy(userId, id, title);

      const errorMsg = !response.ok
        ? (response.data?.message || response.problem)
        : '';

      const { orderId, orderNumber } = response;

      if (errorMsg) {
        dispatch(Creators.copyOrderFailure(errorMsg));
        return;
      }

      if (!orderId) {
        dispatch(Creators.copyOrderFailure('COPY_ORDER_FAILURE'));
        return;
      }

      dispatch(Creators.copyOrderSuccess(orderId, orderNumber));
    } catch (error) {
      dispatch(Creators.copyOrderFailure(error.message || error.reason));
    }
  },


  toggleShouldShowOrderPage: ['shouldOpen'],

  resetCurrentOrderData: [null],

  showErrorMessage: ['errorMessage'],


  fetchOrderDataSuccess: ['orderId', 'orderNumber', 'title'],
  fetchOrderDataFailure: ['errorMessage'],
  fetchOrderDataRequest: (orderID) => async (dispatch, state) => {
    try {
      const currentState = state();
      const { doorsAndSections, order } = currentState;
      const pathArray = window.location.pathname.split('/');
      const orderId = orderID || pathArray[2];
      const isEditPage = pathArray[3] === 'edit';
      const userId = AuthService.getUserId();

      if (!userId || !orderId) return;

      const response = await API.orders.get(orderId);
      const {
        ok,
        problem,
        data: {
          order: orderDB,
          message,
        },
      } = response || {};

      const {
        orderNumber = '',
        title = '',
        user,
        status,
        systemType,
      } = orderDB;

      let {
        doorsSnippet,
        description,
        items,
        itemsByFrame,
        totalPrice,
        totalPriceUAH,
        retailTotalPrice,
        retailTotalPriceUAH,
        userPrice,
        userPriceUAH,
      } = orderDB;

      const errorMsg = !ok ? (message || problem) : '';
      if (errorMsg) {
        dispatch(Creators.fetchOrderDataFailure(errorMsg));
        return;
      }

      if (isEditPage && !_.isEmpty(order.currentOrderId)) {
        const {
          specification,
        } = order;
        description = specification.description;
        items = specification.items;
        itemsByFrame = specification.itemsByFrame;
        totalPrice = specification.totalPrice;
        totalPriceUAH = specification.totalPriceUAH;
        retailTotalPrice = specification.retailTotalPrice;
        retailTotalPriceUAH = specification.retailTotalPriceUAH;
        userPrice = specification.userPrice;
        userPriceUAH = specification.userPriceUAH;

        doorsSnippet = _.pick(
          doorsAndSections,
          systemType === 'frame-facades'
            ? ['main', 'mainFrame', 'doors']
            : ['minDoorsAmount', 'maxDoorsAmount', 'minSectionsAmount', 'maxSectionsAmount', 'main', 'doors'],
        );
      }

      dispatch(SystemsActions.setCurrentSystem(systemType));
      dispatch(Creators.fetchOrderDataSuccess(orderId, orderNumber, title));
      dispatch(DoorsActions.setOrderBySnippet(doorsSnippet));
      dispatch(ConfigActions.getConfigRequest(systemType));
      if (systemType === 'frame-facades') {
        dispatch(Creators.setFrameSpecificationBySnippet({
          items,
          itemsByFrame,
          totalPrice,
          totalPriceUAH,
          user,
          status,
          retailTotalPrice,
          retailTotalPriceUAH,
          userPrice,
          userPriceUAH,
        }));
      } else {
        dispatch(Creators.setSpecificationBySnippet({
          description,
          items,
          totalPrice,
          totalPriceUAH,
          user,
          status,
          retailTotalPrice,
          retailTotalPriceUAH,
          userPrice,
          userPriceUAH,
        }));
      }
    } catch (error) {
      dispatch(Creators.fetchOrderDataFailure(error.message || error.reason));
    }
  },


  fetchOrderModalDataSuccess: [
    'orderID',
    'orderOrigin',
    'orderRecalculated',
    'isPackageChanged',
    'isTotalPriceChanged',
    'isDealerPriceChanged',
    'isMissingItems',
    'isDealeeOrder',
  ],
  fetchOrderModalDataFailure: ['errorMessage'],
  fetchOrderModalDataRequest: (orderID) => async (dispatch, state) => {
    try {
      const currentState = state();
      const userId = AuthService.getUserId();
      if (!userId || !orderID) return;

      const response = await API.orders.getOrderModalData(orderID);
      const {
        ok,
        problem,
        data: { isPackageChanged = false, message = '' },
      } = response || {};
      const orderOrigin = response?.data?.order || {};

      const errorMsg = !ok ? (message || problem) : '';
      if (errorMsg) {
        dispatch(Creators.fetchOrderModalDataFailure(errorMsg));
        return;
      }

      const configResponse = await API.config.get(orderOrigin?.systemType);
      const error = configResponse?.data?.error || {};
      const config = configResponse?.data?.config || [];
      const errorMessage = !configResponse?.ok
        ? (error?.message || configResponse?.data?.message || problem)
        : '';
      if (errorMessage) {
        dispatch(Creators.fetchOrderModalDataFailure(errorMsg));
        return;
      }

      let currentPackageName = currentState.profile?.packageName;
      let manageePriceList = null;

      if (currentState.profile?.role === 'manager') {
        const { phone, primaryRegion } = currentState.clientList.manageeInfo;
        currentPackageName = (await API.user.profile.getPackage(phone))?.data?.packageName || currentPackageName;
        manageePriceList = (await API.priceList.get(primaryRegion, currentPackageName))?.data?.priceList;
      }

      const hasRegionDefaultPackage = defaultPackagesByRegion
        .find((p) => p.packageName === currentPackageName);
      const packageName = hasRegionDefaultPackage?.packageName ? defaultPackageName : currentPackageName;

      const stateData = {
        ...currentState,
        ...{
          config,
          systems: {
            ...currentState.systems,
            ...{ currentSystem: orderOrigin?.systemType },
          },
          priceList: {
            ...(currentState.profile?.role === 'manager'
              ? { priceList: manageePriceList }
              : currentState.priceList),
            ...{ specification: {
              user: orderOrigin?.user,
              description: orderOrigin?.description,
              items: orderOrigin?.items,
              totalPrice: orderOrigin?.totalPrice,
              retailTotalPrice: orderOrigin?.retailTotalPrice,
            } },
          },
          doorsAndSections: orderOrigin?.doorsSnippet,
        },
      };

      // Recalculate Order before put it into work
      const orderPriceAndSpecification = orderOrigin?.systemType === 'frame-facades'
        ? calculateFrameOrder(stateData, packageName)
        : calculateOrder(stateData, packageName);
      const orderRecalc = orderOrigin?.systemType === 'frame-facades'
        ? {
          ..._.omit(orderPriceAndSpecification, ['specificationByFrame', 'specification']),
          items: orderPriceAndSpecification.specification,
        }
        : orderPriceAndSpecification?.specification;

      const isDealeeOrder = orderOrigin.user?.dealerId === userId;

      const isTotalPriceChanged = isDealeeOrder ? false : orderRecalc?.totalPrice !== orderOrigin?.totalPrice;
      const isDealerPriceChanged = isDealeeOrder ? orderRecalc?.totalPrice !== orderOrigin?.totalPrice : false;
      const missingItems = !_.isEmpty(orderRecalc.missingItems)
        ? orderOrigin.items
          .filter(({ articleCode }) => orderRecalc.missingItems.find(
            ({ articleCode: recalcArticleCode }) => articleCode === recalcArticleCode,
          ))
          .map((i) => ({
            articleCode: i.articleCode,
            item: i.item,
            ru: i.labelRu,
            uk: i.labelUk,
            en: i.labelEn,
            pl: i.labelPl,
          }))
        : [];
      const isMissingItems = !_.isEmpty(missingItems);

      dispatch(
        Creators.fetchOrderModalDataSuccess(
          orderID,
          orderOrigin,
          { ..._.omit(orderRecalc, ['missingItems']), ...(isMissingItems ? { missingItems } : {}) },
          isPackageChanged,
          isTotalPriceChanged,
          isDealerPriceChanged,
          isMissingItems,
          isDealeeOrder,
        ),
      );
    } catch (error) {
      dispatch(Creators.fetchOrderModalDataFailure(error.message || error.reason));
    }
  },


  setSpecificationBySnippet: ['specification'],

  setFrameSpecificationBySnippet: ['frameSpecification'],

  resetSpecification: null,

  toggleOrderTitleModal: ['isOpen'],

  updateOrderTitle: ['title'],
});

export const OrderTypes = Types;
export default Creators;
