import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle, navigate } from 'hookrouter';
import { useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';

import AuthService from '../../services/authService';

import { isValidMobilePhone } from '../../helpers/validation';
import { sanitizeValueToNumberStringLike } from '../../helpers/sanitizer';
import notificationPropTypes from '../../helpers/propTypes/notificationPropTypes';
import { countriesList } from '../../../server/helpers/constants.mjs';

import SignupFormActions from '../../redux/actions/signupForm';
import ProfileActions from '../../redux/actions/profile';

import Label from '../../components/Label';
import Button from '../../components/Button';
import Main from '../layout/Main';

import API from '../../api';
import LandingText from '../layout/LandingText';
import BackArrowBtn from '../../components/BackArrowBtn';
import InputPhone from '../../components/InputPhone';


const SignupStart = ({ toastManager }) => {
  const { i18n, t } = useTranslation(['components']);
  const dispatch = useDispatch();

  useTitle(t('routes.sign-up'));

  const { phone } = useSelector(({ signupForm }) => signupForm);

  const [hasErrors, setHasErrors] = useState(false);
  const [notificationId, setNotificationId] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(countriesList[0]);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    dispatch(SignupFormActions.resetForm());
  }, []);

  useEffect(() => {
    setPhoneNumber('');
    dispatch(SignupFormActions.updateField({
      name: 'phone',
      value: selectedCountry.code,
    }));
  }, [selectedCountry]);

  useEffect(() => {
    dispatch(SignupFormActions.updateLanguage({
      language: i18n.language,
    }));
  }, [i18n.language]);

  const showToast = (message, appearance) => {
    const errorContent = <div className="toast-notification">{message}</div>;

    if (notificationId) toastManager.remove(notificationId);

    toastManager.add(errorContent, {
      appearance,
      autoDismiss: true,
    }, (id) => { setNotificationId(id); });
  };

  const updatePhone = ({ target: { name, value } }) => {
    setPhoneNumber(sanitizeValueToNumberStringLike(value));
    const fullNumber = `${selectedCountry.code}${phoneNumber}`;

    if (phone?.value === fullNumber) return;

    dispatch(SignupFormActions.updateField({
      name,
      value: fullNumber,
    }));
  };

  const validatePhone = ({ target: { name, value } }) => {
    setPhoneNumber(sanitizeValueToNumberStringLike(value));
    const fullNumber = `${selectedCountry.code}${phoneNumber}`;
    const isValid = isValidMobilePhone(fullNumber)
      && fullNumber.slice(1).length === selectedCountry.numberOfDigits;
    const error = isValid ? '' : t('errorMessages.incorrect-number');

    setHasErrors(!_.isEmpty(error));
    dispatch(SignupFormActions.updateField({
      name,
      value: fullNumber,
      error,
    }));
  };

  return (
    <div className="sign-up-wrap">
      <LandingText hasOverlay />
      <Main
        className="sign-up"
        hasFooter={false}
        classNameHeader="gray"
      >
        <div className="title-wrapper">
          <div className="title">
            <BackArrowBtn />
            {t('signUp.title')}
          </div>
        </div>
        <div className="enter-link">
          <span className="label">{t('signUp.already-have-account')}</span>
          &nbsp; &nbsp;
          <Button
            value={t('signUp.to-sign-in')}
            onClick={() => navigate('/sign-in')}
          />
        </div>
        <div className="content-wrapper">
          <div className="content-wrapper-inner">
            <div className="content-wrapper-top-section">
              <Label value={t('signUp.phone')} />
              <InputPhone
                value={phoneNumber}
                error={phone.error}
                selectedCountry={selectedCountry}
                countries={countriesList}
                onChange={updatePhone}
                onBlur={validatePhone}
                setSelectedCountry={setSelectedCountry}
                name="phone"
              />
            </div>

            <div className="content-wrapper-bottom-section">
              <Button
                value={t('signUp.proceed')}
                type="rounded"
                onClick={async (e) => {
                  e.preventDefault();

                  if (hasErrors || !phone?.value) return;

                  const res = await API.user.signUpBy(phone.value);
                  const { error, jwtToken, status } = res.data;

                  if (error?.message) {
                    const msg = res.data?.error?.message;
                    showToast(msg ? t(`errorMessagesHelper.${msg}`) : t('errorMessages.something-went-wrong'), 'error');
                    return;
                  }

                  if (status === 200) dispatch(ProfileActions.getUserProfileSuccess(res.data?.profile));

                  if (jwtToken) {
                    AuthService.setToken(res.data.jwtToken);
                    navigate('/sign-up');
                  }

                  const time = new Date().getTime() + 30000;
                  localStorage.setItem('signupSmsBlockedUntill', time);
                }}
                isDisabled={hasErrors || !phone?.value || phoneNumber.length < 9}
              />

              <div className="sm-bottom-text">
                <span>{t('signUp.by-clicking-you-accept-the-terms')}</span>
                <a
                  href="/user-agreement"
                  className="button text-blue"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('signUp.of-user-agreement')}

                </a>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  );
};

SignupStart.propTypes = {
  toastManager: PropTypes.shape(notificationPropTypes).isRequired,
};

export default withToastManager(SignupStart);
