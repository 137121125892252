import { createActions } from 'reduxsauce';

import API from '../../api';

const { Types, Creators } = createActions({
  getClientsSuccess: ['clientList'],
  getClientsFailure: ['errorMessage'],
  getClientsRequest: (role) => async (dispatch) => {
    try {
      const response = await API.user.getClients(role);
      const { ok, problem, data } = response || {};
      const error = data?.error || {};

      const clientList = response?.data?.usersList || [];

      const errorMessage = !ok
        ? error?.message || data?.message || problem
        : '';

      if (errorMessage) {
        dispatch(Creators.getClientsFailure(errorMessage));
        return;
      }

      dispatch(Creators.getClientsSuccess(clientList));
    } catch (error) {
      dispatch(Creators.getClientsFailure(error.message || error.reason));
    }
  },

  setManageeInfo: ['manageeInfo'],
  resetManageeInfo: [null],

  toggleChangePackageModal: ['isOpen'],
  toggleCreateOrderModal: ['isOpen'],
});

export const ClientListTypes = Types;
export default Creators;
