import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'hookrouter';
import clsx from 'clsx';

const BackArrowBtn = ({ className }) => {
  const handleNavigate = () => {
    navigate('/');
  };
  return (
    <button
      type="button"
      onClick={() => handleNavigate()}
      className={clsx('backArrowBtn', className)}
    >
      <img
        src="src/client/assets/icons/header/arrow-back.svg"
        alt="Back"
        width="17.95"
        height="18"
      />
    </button>
  );
};

BackArrowBtn.propTypes = {
  className: PropTypes.string,
};

BackArrowBtn.defaultProps = {
  className: '',
};

export default BackArrowBtn;
