import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const Tabs = ({ tabs, activeTab, setActiveTab, className }) => {
  const tabsClassNames = clsx('tabs', className);

  return (

    <div className={tabsClassNames}>
      {tabs && tabs.map(({ title, id }) => {
        const tabClassName = clsx('tabs-item', activeTab === id && 'active');
        return (
          <button
            key={id}
            id={id}
            className={tabClassName}
            type="button"
            onClick={() => setActiveTab(id)}
          >
            {title}
          </button>
        );
      })}
    </div>

  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Tabs.defaultProps = {
  className: '',
};

export default Tabs;
