import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

import DoorsActions from '../../../redux/actions/doorsAndSections';

import { onMirrorVertical, onMirrorHorizontal } from '../../../helpers/frameHelper';

import { renderViewSwitcher } from '../../SimplifiedFrameConfiguration/FrameFacadesList';
import Tooltip from '../../Tooltip';


const TabHeader = ({ doorNumber }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isOpenCopy, setIsOpenCopy] = useState(false);

  const {
    activeDoor,
    minDoorsAmount,
    main: {
      doorsAmount,
    },
    doors,
    frameConfigurationView,
  } = useSelector(({ doorsAndSections }) => doorsAndSections);

  const { currentSystem } = useSelector(({ systems }) => systems);

  const deleteDoor = (e) => {
    e.preventDefault();

    const doorsAmountToChange = doorsAmount.value - 1;
    if (doorsAmountToChange < minDoorsAmount) return;

    dispatch(DoorsActions.toggleDeleteDoorModal(true));
  };

  const copyDoor = (e) => {
    e.preventDefault();

    dispatch(DoorsActions.toggleCopyDoorModal(true));
  };

  const onSwitcherClick = (value) => {
    dispatch(DoorsActions.toggleFrameConfigurationView(value));
  };

  const onMirrorHorizontalClick = (sequenceNumber, frame) => {
    const frameToUpdate = onMirrorHorizontal(frame);

    dispatch(DoorsActions.invertFrame(true));
    dispatch(DoorsActions.setInvertedFrame(sequenceNumber, frameToUpdate));
  };

  const onMirrorVerticalClick = (sequenceNumber, frame) => {
    const frameToUpdate = onMirrorVertical(frame);

    dispatch(DoorsActions.invertFrame(true));
    dispatch(DoorsActions.setInvertedFrame(sequenceNumber, frameToUpdate));
  };

  const onCopyFrame = (copyType, frame) => {
    let frameToUpdate = frame;

    if (copyType === 'vertical') {
      frameToUpdate = onMirrorVertical(frame);
    }

    if (copyType === 'horizontal') {
      frameToUpdate = onMirrorHorizontal(frame);
    }

    dispatch(DoorsActions.invertFrame(true));
    dispatch(DoorsActions.addFrame({
      ...frameToUpdate,
      sequenceNumber: doors?.length + 1,
      groupName: { value: `${frameToUpdate?.groupName?.value} ${t('stickyMenu.door.copy-label')}` },
    }));
  };

  const renderFrameFacadesHeader = () => {
    const name = doors.find(({ sequenceNumber }) => sequenceNumber === doorNumber)?.groupName?.value;

    if (doorNumber > 0 && name) return (<span>{name}</span>);

    if (doorNumber > 0) {
      return (<span>{t('stickyMenu.bottomNavPanel.group-n', { number: doorNumber })}</span>);
    }

    return (<span>{t('stickyMenu.desktop.set-params')}</span>);
  };

  const renderOtherSystemsHeader = () => {
    if (doorNumber === 0) return (<span>{t('stickyMenu.desktop.main-params')}</span>);

    return (<span>{t('stickyMenu.bottomNavPanel.door-n', { number: doorNumber })}</span>);
  };

  const renderActionButtons = () => {
    const fasteningElementType = doors[activeDoor - 1]?.fasteningElement?.type;

    const copyItems = [
      {
        value: t('stickyMenu.door.copy'),
        id: 'copy',
      },
      {
        value: t('stickyMenu.door.copy-vertical'),
        id: 'vertical',
      },
      {
        value: t('stickyMenu.door.copy-horizontal'),
        id: 'horizontal',
      },
    ];

    if (doorNumber === 0 && currentSystem === 'frame-facades') {
      return (
        <div className="tab-content--view-switcher">
          {renderViewSwitcher(frameConfigurationView, onSwitcherClick)}
        </div>
      );
    }

    if (doorNumber !== 0 && currentSystem === 'frame-facades') {
      return (
        <div className="tab-content--group-buttons">
          {fasteningElementType !== 'lifting-mechanism'
            && (
              <>
                <Tooltip className="mirror" content={t('tooltips.reflect-vertical')} direction="bottom">
                  <button
                    type="button"
                  >
                    <ReactSVG
                      wrapper="span"
                      src="/src/client/assets/icons/mirror-v.svg"
                      onClick={() => onMirrorVerticalClick(doorNumber, doors[doorNumber - 1])}
                    />
                  </button>
                </Tooltip>

                <Tooltip className="mirror" content={t('tooltips.reflect-horizontal')} direction="bottom">
                  <button
                    type="button"
                  >
                    <ReactSVG
                      wrapper="span"
                      src="/src/client/assets/icons/mirror-h.svg"
                      onClick={() => onMirrorHorizontalClick(doorNumber, doors[doorNumber - 1])}
                    />
                  </button>
                </Tooltip>

              </>
            )}

          <Tooltip className="delete" content={t('tooltips.delete')} direction="bottom">
            <button
              type="button"
              onClick={() => {
                dispatch(DoorsActions.setActiveDoor(doorNumber - 1));
                dispatch(DoorsActions.deleteFrame(doorNumber));
              }}
            >
              <ReactSVG
                wrapper="span"
                src="/src/client/assets/icons/trash.svg"
              />
            </button>
          </Tooltip>


          <div className="copy-wrapper">
            <Tooltip className="copy" content={t('tooltips.copy')} direction="bottom">
              <button
                type="button"
                onClick={() => {
                  if (fasteningElementType === 'lifting-mechanism') {
                    onCopyFrame('copy', doors[doorNumber - 1]);
                  }
                  setIsOpenCopy(!isOpenCopy);
                }}
              >
                <ReactSVG
                  wrapper="span"
                  className="icon"
                  src="/src/client/assets/icons/copy.svg"
                />
                {fasteningElementType !== 'lifting-mechanism'
                  && (
                    <ReactSVG
                      wrapper="span"
                      className="triangle"
                      src="/src/client/assets/icons/triangle.svg"
                    />
                  )}
              </button>
            </Tooltip>
            {fasteningElementType !== 'lifting-mechanism'
              && (
                <div className={clsx('copy-list', isOpenCopy && 'open')}>
                  {copyItems.map((item) => (
                    <span
                      key={item.id}
                      role="button"
                      tabIndex="0"
                      className="copy-item"
                      onClick={() => {
                        onCopyFrame(item.id, doors[doorNumber - 1]);
                        setIsOpenCopy(false);
                      }}
                    >
                      {item.value}
                    </span>
                  ))}
                </div>
              )}
          </div>

          <div className="view-switcher">
            {renderViewSwitcher(frameConfigurationView, onSwitcherClick)}
          </div>
        </div>
      );
    }

    if (!doorNumber) return null;

    return (
      <div className="tab-content--action-buttons">
        <button
          type="button"
          className="rectangle"
          onClick={copyDoor}
          disabled={doorsAmount.value < 2}
        >
          <ReactSVG
            wrapper="span"
            src="/src/client/assets/icons/copy.svg"
          />
          <span className="button-label">
            &nbsp;
            {t('stickyMenu.door.copy')}
          </span>
        </button>
        <button
          type="button"
          className="circle"
          onClick={deleteDoor}
          disabled={doorsAmount.value <= minDoorsAmount}
        >
          <ReactSVG
            wrapper="span"
            src="/src/client/assets/icons/trash.svg"
          />
        </button>
      </div>
    );
  };

  return (
    <div className="tab-content--title-wrapper">
      <div className="tab-content--title">
        {currentSystem === 'frame-facades'
          ? renderFrameFacadesHeader()
          : renderOtherSystemsHeader()}
      </div>
      {renderActionButtons()}
    </div>
  );
};

TabHeader.propTypes = {
  doorNumber: PropTypes.number.isRequired,
};

export default TabHeader;
